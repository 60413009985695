/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { BookedWith } from './BookedWith';
import { BookedWithFromJSON, BookedWithFromJSONTyped, BookedWithToJSON } from './BookedWith';
import type { BookingSummaryHirer } from './BookingSummaryHirer';
import { BookingSummaryHirerFromJSON, BookingSummaryHirerFromJSONTyped, BookingSummaryHirerToJSON } from './BookingSummaryHirer';

/**
 *
 * @export
 * @interface BookingSummary
 */
export interface BookingSummary {
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    ref: string;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    bookingNo: string;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    reservationId: string;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    voucherRef: string;
    /**
     *
     * @type {Date}
     * @memberof BookingSummary
     */
    created: Date;
    /**
     *
     * @type {Date}
     * @memberof BookingSummary
     */
    updated: Date;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    status: BookingSummaryStatusEnum;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    availability: BookingSummaryAvailabilityEnum;
    /**
     *
     * @type {BookingSummaryHirer}
     * @memberof BookingSummary
     */
    hirer: BookingSummaryHirer;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    pickUpSiteCode?: string;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    pickUpSiteName?: string;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    dropOffSiteCode?: string;
    /**
     *
     * @type {string}
     * @memberof BookingSummary
     */
    dropOffSiteName?: string;
    /**
     *
     * @type {number}
     * @memberof BookingSummary
     */
    rentalDays?: number;
    /**
     *
     * @type {Date}
     * @memberof BookingSummary
     */
    pickUpDate?: Date;
    /**
     *
     * @type {Date}
     * @memberof BookingSummary
     */
    dropOffDate?: Date;
    /**
     *
     * @type {boolean}
     * @memberof BookingSummary
     */
    disableCheckIn?: boolean;
    /**
     *
     * @type {BookedWith}
     * @memberof BookingSummary
     */
    bookedWith?: BookedWith;
}

/**
 * @export
 */
export const BookingSummaryStatusEnum = {
    Quote: 'quote',
    Confirmed: 'confirmed',
    Cancelled: 'cancelled',
    OnRoad: 'on-road',
    Returned: 'returned',
} as const;
export type BookingSummaryStatusEnum = (typeof BookingSummaryStatusEnum)[keyof typeof BookingSummaryStatusEnum];

/**
 * @export
 */
export const BookingSummaryAvailabilityEnum = {
    Freesell: 'freesell',
    Onrequest: 'onrequest',
    Stopsell: 'stopsell',
} as const;
export type BookingSummaryAvailabilityEnum = (typeof BookingSummaryAvailabilityEnum)[keyof typeof BookingSummaryAvailabilityEnum];

/**
 * Check if a given object implements the BookingSummary interface.
 */
export function instanceOfBookingSummary(value: object): value is BookingSummary {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('ref' in value) || value['ref'] === undefined) return false;
    if (!('bookingNo' in value) || value['bookingNo'] === undefined) return false;
    if (!('reservationId' in value) || value['reservationId'] === undefined) return false;
    if (!('voucherRef' in value) || value['voucherRef'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('updated' in value) || value['updated'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('availability' in value) || value['availability'] === undefined) return false;
    if (!('hirer' in value) || value['hirer'] === undefined) return false;
    return true;
}

export function BookingSummaryFromJSON(json: any): BookingSummary {
    return BookingSummaryFromJSONTyped(json, false);
}

export function BookingSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingSummary {
    if (json == null) {
        return json;
    }
    return {
        id: json['id'],
        ref: json['ref'],
        bookingNo: json['bookingNo'],
        reservationId: json['reservationId'],
        voucherRef: json['voucherRef'],
        created: stringToDate(json['created']),
        updated: stringToDate(json['updated']),
        status: json['status'],
        availability: json['availability'],
        hirer: BookingSummaryHirerFromJSON(json['hirer']),
        pickUpSiteCode: json['pickUpSiteCode'] == null ? undefined : json['pickUpSiteCode'],
        pickUpSiteName: json['pickUpSiteName'] == null ? undefined : json['pickUpSiteName'],
        dropOffSiteCode: json['dropOffSiteCode'] == null ? undefined : json['dropOffSiteCode'],
        dropOffSiteName: json['dropOffSiteName'] == null ? undefined : json['dropOffSiteName'],
        rentalDays: json['rentalDays'] == null ? undefined : json['rentalDays'],
        pickUpDate: json['pickUpDate'] == null ? undefined : stringToDate(json['pickUpDate']),
        dropOffDate: json['dropOffDate'] == null ? undefined : stringToDate(json['dropOffDate']),
        disableCheckIn: json['disableCheckIn'] == null ? undefined : json['disableCheckIn'],
        bookedWith: json['bookedWith'] == null ? undefined : BookedWithFromJSON(json['bookedWith']),
    };
}

export function BookingSummaryToJSON(value?: BookingSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        id: value['id'],
        ref: value['ref'],
        bookingNo: value['bookingNo'],
        reservationId: value['reservationId'],
        voucherRef: value['voucherRef'],
        created: dateToString(value['created']),
        updated: dateToString(value['updated']),
        status: value['status'],
        availability: value['availability'],
        hirer: BookingSummaryHirerToJSON(value['hirer']),
        pickUpSiteCode: value['pickUpSiteCode'],
        pickUpSiteName: value['pickUpSiteName'],
        dropOffSiteCode: value['dropOffSiteCode'],
        dropOffSiteName: value['dropOffSiteName'],
        rentalDays: value['rentalDays'],
        pickUpDate: value['pickUpDate'] == null ? undefined : dateToString(value['pickUpDate']),
        dropOffDate: value['dropOffDate'] == null ? undefined : dateToString(value['dropOffDate']),
        disableCheckIn: value['disableCheckIn'],
        bookedWith: BookedWithToJSON(value['bookedWith']),
    };
}
