import React, { useEffect } from 'react';
import { FleetTypeSlug } from '@jucy/rentals-common';
import { Avatar, FormControlLabel, FormControlLabelProps, Radio, RadioProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FleetType } from '../../../services';
import { FleetTypeRadioGroupButton } from './FleetTypeRadioGroupButton';

export interface FleetTypeFormControlLabelProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
    fleetType: FleetType;
    radioProps?: RadioProps;
    required?: boolean;
    thumbnailSize?: SxProps<Theme>;
    error?: boolean;
}

export interface FleetTypeFormControlLabelProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
    fleetType: FleetType;
    radioProps?: RadioProps;
    required?: boolean;
    thumbnailSize?: SxProps<Theme>;
    error?: boolean;
}

const resolveFleetTypeName = (fleetType: FleetType) => {
    if (fleetType.slug === FleetTypeSlug.motorhome) {
        return 'Star RV';
    }
    return fleetType.name;
};

const throttle = (f: ResizeObserverCallback, delay: number): ResizeObserverCallback => {
    let timer = 0;
    return (...args) => {
        window.clearTimeout(timer);
        timer = window.setTimeout(() => f.apply(this, args), delay);
    };
};

const showHideThumbObserver = (parent: HTMLElement, child: HTMLElement) => {
    const resizeObserver = new ResizeObserver(
        throttle(() => {
            showHideThumb(parent, child);
        }, 120)
    );
    resizeObserver.observe(document.body);
    resizeObserver.observe(parent);
    showHideThumb(parent, child);
    return resizeObserver;
};

const showHideThumb = (parent: HTMLElement, child: HTMLElement) => {
    const labelStyle = window.getComputedStyle(parent);
    const labelPaddingLeft = parseInt(labelStyle.getPropertyValue('padding-left'));
    child.style.width = 'auto';
    if (child.offsetLeft < labelPaddingLeft) {
        child.style.width = '0';
    } else {
        child.style.width = 'auto';
    }
};

export const FleetTypeFormControlLabel = React.forwardRef<HTMLButtonElement, FleetTypeFormControlLabelProps>(
    ({ error, name, fleetType, required, radioProps, disabled, thumbnailSize, ...props }, ref) => {
        const value = fleetType.slug;
        const active = props.value === value;
        const labelRef = React.useRef<HTMLLabelElement>(null);
        const avatarRef = React.useRef<HTMLDivElement>(null);
        useEffect(() => {
            let resizeObserver: ResizeObserver;
            if (labelRef.current && avatarRef.current) {
                resizeObserver = showHideThumbObserver(labelRef.current, avatarRef.current);
            }
            return () => {
                if (resizeObserver) {
                    resizeObserver.unobserve(document.body);
                }
            };
        }, [labelRef, avatarRef]);
        return (
            <FleetTypeRadioGroupButton sx={{ flex: { xs: 1, sm: 'unset' } }} className="fleet-type-radio" disabled={disabled}>
                <FormControlLabel
                    {...props}
                    ref={labelRef}
                    labelPlacement="start"
                    value={value}
                    disabled={disabled}
                    control={
                        <>
                            <Avatar
                                ref={avatarRef}
                                variant="square"
                                alt={fleetType.name}
                                className="fleet-type-thumb"
                                src={fleetType.thumbnail.src}
                                sx={{
                                    opacity: disabled ? 0.5 : undefined,
                                    order: 1,
                                    backgroundColor: 'none',
                                    ...thumbnailSize,
                                }}
                            />
                            <Radio ref={ref} sx={{ padding: 0, '&:hover': { background: 'none' } }} name={name} value={value} disabled={disabled} disableRipple={true} {...radioProps} />
                        </>
                    }
                    label={resolveFleetTypeName(fleetType)}
                    sx={{
                        p: { xs: '4px 9px', sm: '8px 12px' },
                        gap: 1,
                        border: active ? 2 : 1,
                        m: active ? 0 : '1px',
                        borderRadius: 1,
                        borderColor: error ? 'warning.main' : active ? 'primary.main' : 'rgba(0, 0, 0, 0.23)',
                        flex: { xs: 1, sm: 'unset' },
                        textAlign: { xs: 'left', sm: 'center' },
                        background: '#fff',
                        '& .MuiFormControlLabel-label': {
                            flex: 1,
                            fontSize: '18px',
                            color: error ? 'warning.main' : 'rgb(17, 17, 17)',
                        },
                    }}
                />
            </FleetTypeRadioGroupButton>
        );
    }
);
FleetTypeFormControlLabel.displayName = 'FleetTypeFormControlLabel';
