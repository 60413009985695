'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { useFormContext } from '@jucy-ui/forms';
import { StackProps } from '@mui/material';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { useSearchFormStore } from '../hooks';
import { jucySearchFormClasses } from '../lib';
import { SearchFormValues } from '../types';

export interface SearchFormDateTimeLocationContainerProps extends Omit<StackProps, 'position'> {
    position?: 'start' | 'end';
}

export const SearchFormDateTimeLocationContainer: React.FC<SearchFormDateTimeLocationContainerProps> = ({
    direction = 'column',
    useFlexGap = true,
    width = '100%',
    children,
    sx,
    position,
    ...props
}) => {
    const { getFieldState } = useFormContext<SearchFormValues>();
    return (
        <Stack
            sx={sx}
            {...props}
            direction={direction}
            useFlexGap={useFlexGap}
            width={width}
        >
            {children}
        </Stack>
    );
};
