'use client';
import React from 'react';
import { SlotComponentProps, useSlotProps } from '@mui/base';
import DoneIcon from '@mui/icons-material/Done';
import { ListItemIconProps, ListItemProps, ListItemTextProps, styled } from '@mui/material';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useThemeProps } from '@mui/material/styles';

export interface FeatureListSlots {
    root?: React.ElementType;
    listItem?: React.ElementType;
    listItemIcon?: React.ElementType;
    listItemText?: React.ElementType;
}

export interface FeatureListOwnerState {}

export interface FeatureListProps extends ListProps {
    items: string[];
    max?: number;
    className?: string;
    slots?: FeatureListSlots;
    slotProps?: {
        root: SlotComponentProps<typeof List, object, ListProps>;
        listItem: SlotComponentProps<typeof ListItem, object, ListItemProps>;
        listItemIcon: SlotComponentProps<typeof ListItemIcon, object, ListItemIconProps>;
        listItemText: SlotComponentProps<typeof ListItemText, object, ListItemTextProps>;
    };
}

const FeatureListRoot = styled(List, {
    name: 'JucyFeatureList',
    slot: 'root',
    skipSx: false,
})<{ ownerState?: FeatureListOwnerState }>(() => ({
    fontWeight: 400,
}));
const FeatureListItem = styled(ListItem, {
    name: 'JucyFeatureList',
    slot: 'item',
    skipSx: false,
})<{ ownerState?: FeatureListOwnerState }>(() => ({
    padding: 0,
}));
const FeatureListItemIcon = styled(ListItemIcon, {
    name: 'JucyFeatureList',
    slot: 'itemIcon',
})<{ ownerState?: FeatureListOwnerState }>(() => ({
    minWidth: 31,
}));
const FeatureListItemText = styled(ListItemText, {
    name: 'JucyFeatureList',
    slot: 'itemText',
})<{ ownerState?: FeatureListOwnerState }>(() => ({
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexDirection: 'row',
}));
export const FeatureList = React.forwardRef<HTMLUListElement, FeatureListProps>((inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: 'JucyFeatureList' });
    const { slotProps, className, items, max, ...other } = props;
    const ownerState = {};
    const RootSlot = props.slots?.root || FeatureListRoot;
    const ListItemSlot = props.slots?.listItem || FeatureListItem;
    const ListItemIconSlot = props.slots?.listItemIcon || FeatureListItemIcon;
    const ListItemTextSlot = props.slots?.listItemText || FeatureListItemText;
    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            ref,
        },
        ownerState,
        className: className,
    });
    const listItemProps = useSlotProps({
        elementType: FeatureListItem,
        externalSlotProps: slotProps?.listItem,
        ownerState,
    });
    const listItemIconProps = useSlotProps({
        elementType: FeatureListItemIcon,
        externalSlotProps: slotProps?.listItemIcon,
        ownerState,
    });
    const listItemTextProps = useSlotProps({
        elementType: FeatureListItemText,
        externalSlotProps: slotProps?.listItemText,
        additionalProps: {
            disableTypography: true,
        },
        ownerState,
    });
    return (
        <RootSlot disablePadding={true} dense={true} {...rootProps}>
            {items.slice(0, max || 4).map((feature) => (
                <ListItemSlot disablePadding={true} key={`features-${feature}`} {...listItemProps}>
                    <ListItemIconSlot {...listItemIconProps}>
                        <DoneIcon fontWeight="bold" color="secondary" />
                    </ListItemIconSlot>
                    <ListItemTextSlot title={feature} primary={feature} {...listItemTextProps} />
                </ListItemSlot>
            ))}
        </RootSlot>
    );
});
FeatureList.displayName = 'FeatureList';
