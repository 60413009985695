import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    AccordionProps,
    AccordionSummaryProps,
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionDetailsProps as MuiAccordionDetailsProps,
    AccordionSummary as MuiAccordionSummary,
    styled,
} from '@mui/material';
import { mergeSx } from '#/lib/mergeSx';

export const JucyMuiAccordion = styled(({ children, ...props }: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props}>
        {children}
    </MuiAccordion>
))(() => ({
    padding: 0,
    '&:before': {
        display: 'none',
    },
}));

export const JucyMuiAccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={
            <>
                <AddIcon fontWeight={700} className="expand" />
                <RemoveIcon fontWeight={700} className="collapsed" />
            </>
        }
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: theme.palette.text.primary,
        transform: 'rotate(-180deg)',
        '& .expand': {
            display: 'block',
        },
        '& .collapsed': {
            display: 'none',
        },
        '&.Mui-expanded': {
            transform: 'rotate(0)',
            '& .expand': {
                display: 'none',
            },
            '& .collapsed': {
                display: 'block',
            },
        },
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    padding: 0,
}));

export const JucyMuiAccordionDetails: React.FC<MuiAccordionDetailsProps> = ({ sx, ...props }) => (
    <MuiAccordionDetails
        sx={mergeSx(
            (theme) => ({
                padding: {
                    xs: theme.spacing(1, 0, 2, 1),
                    sm: theme.spacing(1, 0, 2, 4),
                },
            }),
            sx
        )}
        {...props}
    />
);
