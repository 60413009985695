import { getBranchSupportsFleetType } from '../lib';
import { SearchFormData } from '../types';

export const validateFleetType = ({ pickUpBranch, dropOffBranch, fleetType }: Partial<SearchFormData>): string | undefined => {
    if (!fleetType) {
        return 'Please select a vehicle type';
    }

    if (pickUpBranch && !getBranchSupportsFleetType({ branch: pickUpBranch, fleetType })) {
        return 'Pick up branch does not support this vehicle type';
    }

    if (dropOffBranch && !getBranchSupportsFleetType({ branch: dropOffBranch, fleetType })) {
        return 'Drop off branch does not support this vehicle type';
    }
};
