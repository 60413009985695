'use client';
import React from 'react';
import { PaperProps, PopperPlacementType } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { pickersLayoutClasses } from '@mui/x-date-pickers-pro';
import { pickersArrowSwitcherClasses} from '@mui/x-date-pickers/internals';
import type {PickerPopperProps, PickersPopperSlotProps} from '@mui/x-date-pickers/internals';
import { SearchFormDatePickerFooter } from './SearchFormDatePickerFooter';

interface PickersPopperOwnerState extends PickerPopperProps {
    placement: PopperPlacementType;
}

const PickersPopperPaper = styled(Paper, {
    name: 'MuiPickersPopper',
    slot: 'Paper',
    overridesResolver: (_, styles) => styles.paper,
})<{
    ownerState: PickersPopperOwnerState;
}>(({ ownerState }) => ({
    outline: 0,
    paddingBottom: 0,
    transformOrigin: 'top center',
    ...(ownerState.placement.includes('top') && {
        transformOrigin: 'bottom center',
    }),
    [`& .${pickersLayoutClasses.toolbar}`]: {
        paddingBottom: 0,
    },
    [`& .${pickersArrowSwitcherClasses.root}`]: {
        paddingTop: 1,
    },
}));
type DesktopPaperProps = PickersPopperSlotProps['desktopPaper'] & PaperProps;

export const SearchFormDatePickerPaperDesktop = React.forwardRef<HTMLDivElement, DesktopPaperProps>(({ children, ...props }, ref) => (
    <PickersPopperPaper ref={ref} {...(props as DesktopPaperProps & { ownerState: PickersPopperOwnerState })}>
        {children}
        <SearchFormDatePickerFooter />
    </PickersPopperPaper>
));
SearchFormDatePickerPaperDesktop.displayName = 'SearchFormDatePickerPaperDesktop';
