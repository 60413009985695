import { CountryData } from '@jucy-ui/common';

export const filterSuggestedPhoneCountryOptions = (options: CountryData[]) =>
    options
        .filter((c) => c.suggested)
        .sort((a, b) => {
            if (a.code === 'NZ' && b.code === 'AU') {
                return -1;
            }
            if (a.code === 'AU' && b.code === 'NZ') {
                return 1;
            }
            if (a.code === 'NZ' || a.code === 'AU') {
                return -1;
            }
            return 1;
        });
