'use client';
import {RangePosition} from '@mui/x-date-pickers-pro';
import React from 'react';
import { DISPLAY_TIME_FORMAT_SHORT } from '@jucy-ui/common';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { format } from 'date-fns';
import { useGetServiceHours, useSearchFormFieldValues } from '../hooks';
import { searchFormValuesToData } from '../lib';

export type SearchFromTimeSelectMenuHeaderProps = { position: RangePosition };
export const SearchFromTimeSelectMenuHeader = React.forwardRef<HTMLDivElement, SearchFromTimeSelectMenuHeaderProps>(({ position }, ref) => {
    const { pickUpBranch, fleetType, dropOffBranch } = searchFormValuesToData(useSearchFormFieldValues());
    const { pickUpBranchServiceHours, dropOffBranchServiceHours, isAfterHoursDropOffAvailable, isAfterHoursPickUpAvailable } = useGetServiceHours();
    const branch = position === 'start' ? pickUpBranch : dropOffBranch;
    const afterHoursAvailable = position === 'start' ? isAfterHoursPickUpAvailable : isAfterHoursDropOffAvailable;
    const serviceHours = position === 'start' ? pickUpBranchServiceHours : dropOffBranchServiceHours;
    if (!fleetType) {
        return null;
    }
    return (
        <>
            <Box ref={ref} component="small" sx={{ px: 2, py: 1, display: 'block' }}>
                {branch?.name}
                <br /> {fleetType.name} hours:
                <br />
                {serviceHours ? (
                    <>
                        {format(serviceHours.open, DISPLAY_TIME_FORMAT_SHORT)} to {format(serviceHours.close, DISPLAY_TIME_FORMAT_SHORT)} <br />
                    </>
                ) : null}
                {afterHoursAvailable ? 'After hours available' : null}
            </Box>
            <Divider />
        </>
    );
});
SearchFromTimeSelectMenuHeader.displayName = 'SearchFromTimeSelectMenuHeader';
