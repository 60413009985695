import React from 'react';
import { RentalExcessReduction } from '@jucy/rentals-api-client';
import clsx from 'clsx';
import { getRecommendations } from '../../ExcessMapper';

interface BannerProps {
    item: Required<RentalExcessReduction>;
    variant?: 'recommended' | 'popular';
    className?: string;
}

const Banner: React.FC<BannerProps> = ({ item, className, variant = 'recommended' }) => {
    const recommendations = getRecommendations(item);
    const classNames = clsx(className, recommendations);
    if (variant === 'recommended' && recommendations.includes('recommended')) {
        return (
            <div className={clsx(classNames)}>
                <i className="fa fa-star" aria-hidden="true" /> Recommended
            </div>
        );
    } else if (variant !== 'recommended' && recommendations.includes('popular')) {
        return (
            <div className={clsx(classNames)}>
                <i className="fa fa-star" aria-hidden="true" /> Most popular
            </div>
        );
    } else if (variant !== 'recommended' && recommendations.includes('value')) {
        return (
            <div className={clsx(classNames)}>
                <i className="fa fa-dollar-sign" aria-hidden="true" /> Most value
            </div>
        );
    }
    return null;
};
export default Banner;
