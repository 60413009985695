'use client';
import { useMemo } from 'react';
import {
    FieldValues,
    UseFormProps as UseReactHookFormProps,
    UseFormReturn as UseReactHookFormReturn,
    useForm as useReactHookForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Simplify } from 'type-fest';
import { FormMeta, type FormValidationSchema } from '../lib';
import { mapSchemaObjectDescription } from '../utils';

export type UseFormProps<SchemaShape extends FieldValues> = Simplify<
    UseReactHookFormProps<SchemaShape> & {
        validationSchema: FormValidationSchema<SchemaShape>;
    }
>;

export type UseFormReturn<SchemaShape extends FieldValues> = Simplify<
    UseReactHookFormReturn<SchemaShape> & {
        validationSchema: FormValidationSchema<SchemaShape>;
        formMeta: FormMeta<SchemaShape>;
    }
>;
export const useForm = <SchemaShape extends FieldValues>({ validationSchema, ...props }: UseFormProps<SchemaShape>): UseFormReturn<SchemaShape> => {
    const resolver = yupResolver(validationSchema);
    const result = useReactHookForm({
        ...props,
        defaultValues: props.defaultValues || validationSchema.getDefault(),
        shouldUseNativeValidation: props.shouldUseNativeValidation === undefined ? false : props.shouldUseNativeValidation,
        shouldFocusError: props.shouldFocusError === undefined ? false : props.shouldFocusError,
        resolver: resolver,
    });
    return useMemo(
        () => ({
            ...result,
            formMeta: mapSchemaObjectDescription<SchemaShape>(validationSchema.describe()),
            validationSchema,
        }),
        [result, validationSchema]
    );
};
