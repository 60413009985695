import React, { useCallback } from 'react';
import { Dialog, DialogProps, IconButton, ModalProps, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    modal: {
        '&:first-child': {
            paddingTop: 0,
        },
    },
    frame: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            height: 506,
            overflow: 'hidden',
        },
    },
}));

const ModalDialog: React.FC<DialogProps> = ({ open, onClose, className, children, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const toggleModel: ModalProps['onClose'] = useCallback(
        (e: object, r: 'backdropClick' | 'escapeKeyDown') => {
            if (onClose) {
                onClose(e, r);
            }
        },
        [onClose]
    );
    return (
        <Dialog open={open} onClose={toggleModel} fullWidth={true} fullScreen={fullScreen} className={clsx(classes.modal, className)} {...props}>
            <IconButton
                color="primary"
                sx={{
                    position: 'absolute',
                    top: theme.spacing(1),
                    right: theme.spacing(1),
                    height: 40,
                    width: 40,
                    zIndex: 1,
                }}
                aria-label="Close"
                onClick={(e) => toggleModel(e, 'backdropClick')}
                size="large"
            >
                <span aria-hidden="true">×</span>
            </IconButton>
            {children}
        </Dialog>
    );
};

export default ModalDialog;
