import { Modifier } from 'react-day-picker/types/Modifiers';
import { DefaultTime, Site as RentalApiSite, SiteAddress1, SiteDefaultTimes, SiteServiceHours, SiteSiteSettings, SitesSettingsServiceHours } from '@jucy/rentals-api-client';
import { Type, plainToInstance } from 'class-transformer';
import { addDays, addHours, addYears, endOfYear, getISODay, isAfter, isBefore, isToday, isWithinInterval, roundToNearestMinutes, startOfDay } from 'date-fns';
import { PartialDeep } from 'type-fest';
import { getEnWeekDay, isValidDate, setTime, setTimeFromString } from '../../lib/dates';
import { AfterHours } from './AfterHours';
import { FleetType } from './FleetType';
import { ServiceHours } from './ServiceHours';
import { SiteHoliday } from './SiteHoliday';
import { SiteSettings } from './SiteSettings';

const getAbsoluteMaxDate = (): Date => endOfYear(addYears(new Date(), 2));

export class Site implements RentalApiSite {
    static absoluteMaxDate = getAbsoluteMaxDate();
    geoLocation?: RentalApiSite['geoLocation'];
    domesticPickUp?: boolean;
    internationalPickUp?: boolean;
    address1?: SiteAddress1;
    id?: string;
    name?: string;
    siteCode?: string;
    country?: string;
    countryCode?: string;
    minAges?: string[];
    siteSettings?: SiteSiteSettings[];
    @Type(() => SiteHoliday)
    holidays?: SiteHoliday[];
    defaultTimes?: SiteDefaultTimes;
    businessUnit?: string;
    @Type(() => SiteSettings)
    settings?: SiteSettings;
    // @Type(() => SiteHoliday)
    // combinedHolidays: SiteHoliday[];

    constructor(props: Partial<Site>) {
        Object.assign(this, props);
    }

    get combinedHolidays(): SiteHoliday[] {
        const seenHolidays = new Set<string>();
        return [...(this.holidays || []), ...(this.settings?.holidays || [])].filter((h) => {
            const key = `${h.start?.toString()}${h.end?.toString()}`;
            if (seenHolidays.has(key)) {
                return false;
            }
            seenHolidays.add(key);
            return true;
        });
    }

    get blockedDates(): Modifier[] {
        return this.combinedHolidays.reduce((res, h) => {
            const modifier = h.asModifier();
            if (modifier) {
                res.push(modifier);
            }
            return res;
        }, [] as Modifier[]);
    }

    get fleetTypeCodes(): string[] {
        return this.settings?.fleetTypeSettings.filter((s) => s.fleetTypeSlug).map((s) => s.fleetTypeSlug as string) || [];
    }

    // getMessages({ date, fleetType }) {
    //     const dayOfWeek = parseInt(date.day());
    //     const fleetTypeSettings = this.settings.fleetTypeSettings.find((f) => f.fleetTypeSlug === fleetType.slug);
    //     const messageFilter = (m) => dayOfWeek >= m.startDow && dayOfWeek <= m.endDow && date.isTimeBetween(m.startTime, m.endTime);
    //     return {
    //         pickUp: fleetTypeSettings.pickupServiceHoursMessages.filter(messageFilter).map((m) => m.message),
    //         dropOff: fleetTypeSettings.dropOffServiceHoursMessages.filter(messageFilter).map((m) => m.message),
    //     };
    // }

    static fromPlain(props: PartialDeep<Site>): Site {
        return plainToInstance(Site, props);
    }

    getAfterHours({ fleetType }: { fleetType: FleetType }): AfterHours {
        const fleetTypeSettings = this.settings?.fleetTypeSettings.find((f) => f.fleetTypeSlug === fleetType.slug);
        return {
            afterHoursPickUp: Boolean(fleetTypeSettings?.afterHoursPickUp),
            afterHoursDropOff: Boolean(fleetTypeSettings?.afterHoursDropOff),
        };
    }

    getServiceHours({ date, fleetType }: { date: Date; fleetType: FleetType }): ServiceHours {
        const weekdayIso = getISODay(date);
        const weekdayName = getEnWeekDay(date).toLowerCase();
        const siteSettings = this.siteSettings?.find((s) => s.fleetTypeId === fleetType.id);
        const officeHours = siteSettings?.serviceHours?.[weekdayName as keyof SiteServiceHours];
        const fleetTypeSettings = this.settings?.fleetTypeSettings.find((f) => f.fleetTypeSlug === fleetType.slug);
        const settingServiceHours = fleetTypeSettings?.serviceHours?.find((h) => h.dow === weekdayIso);
        const defaultServiceHours = {
            start: setTimeFromString(date, settingServiceHours?.open || '00:00'),
            end: setTimeFromString(date, settingServiceHours?.close || '00:00'),
        };
        const pickUpServiceHours = fleetTypeSettings?.pickUpServiceHours?.find((h) => h.dow === weekdayIso) as Required<SitesSettingsServiceHours>;
        const dropOffServiceHours = fleetTypeSettings?.dropOffServiceHours?.find((h) => h.dow === weekdayIso) as Required<SitesSettingsServiceHours>;
        return {
            ...this.getAfterHours({ fleetType }),
            officeHours: {
                start: setTimeFromString(date, officeHours?.open || '00:00'),
                end: setTimeFromString(date, officeHours?.close || '00:00'),
            },
            pickUp: !pickUpServiceHours
                ? defaultServiceHours
                : {
                      start: setTimeFromString(date, pickUpServiceHours.open),
                      end: setTimeFromString(date, pickUpServiceHours.close),
                  },
            dropOff: !dropOffServiceHours
                ? defaultServiceHours
                : {
                      start: setTimeFromString(date, dropOffServiceHours.open),
                      end: setTimeFromString(date, dropOffServiceHours.close),
                  },
        };
    }

    getMinPickUpDateTime({ date, fleetType }: { date: Date; fleetType: FleetType }): Date | undefined {
        const serviceHours = this.getServiceHours({ date, fleetType });
        let result = serviceHours.pickUp.start; //Set min pick up time to be the service start time.
        if (serviceHours?.afterHoursPickUp) {
            // Only find the min pick up time if out of hours pick ups are unavailable
            if (isToday(date)) {
                // If booking is for today
                const now = new Date();
                if (isWithinInterval(now, serviceHours.pickUp)) {
                    // If current time is between service hours
                    //Min pick up should be now + fleet lead time, rounded up to the next 30 minutes
                    result = roundToNearestMinutes(startOfDay(addHours(now, fleetType.leadTimeHours | 0.5)), {
                        nearestTo: 30,
                        roundingMethod: 'ceil',
                    });
                    if (isAfter(result, serviceHours.pickUp.end)) {
                        // If rounding takes us outside of pick up hours, set min as tomorrow's service hours start
                        result = this.getServiceHours({
                            date: addDays(date, 1),
                            fleetType,
                        }).pickUp.start;
                    }
                } else {
                    // If current time is outside of office hours
                    if (isAfter(now, serviceHours.pickUp.end)) {
                        // If it's past the office close time, min time will be tomorrow's service hours start
                        result = this.getServiceHours({
                            date: addDays(date, 1),
                            fleetType,
                        }).pickUp.start;
                    } else {
                        // If current time is before open, min time will be today's service hours start
                        result = serviceHours.pickUp.start;
                    }
                }
            } else {
                // If out of hours is available, and it's not today
                result = startOfDay(date);
            }
        } else if (isToday(date)) {
            // If today and out of hours is available, min pick up should be now + fleet lead time, rounded up to the next 30 minutes
            result = roundToNearestMinutes(startOfDay(addHours(new Date(), fleetType.leadTimeHours | 0.5)), {
                nearestTo: 30,
                roundingMethod: 'ceil',
            });
        }
        return result;
    }

    getMaxPickUpDateTime({ date, fleetType }: { date: Date; fleetType: FleetType }) {
        const serviceHours = this.getServiceHours({ date, fleetType });
        if (!serviceHours.afterHoursPickUp) {
            // Only find the max pick up time if out of hours pick up is unavailable
            return setTime(date, serviceHours.pickUp.end); // Set max pick up time to be the service hours end
        }
        return setTime(date, Site.absoluteMaxDate);
    }

    getMinDropOffDateTime({ date, fleetType }: { date: Date; fleetType: FleetType }): Date | undefined {
        const serviceHours = this.getServiceHours({ date, fleetType });
        let result;
        if (!serviceHours.afterHoursDropOff) {
            // Only find the min drop off if out of hours drop off is unavailable
            result = serviceHours.dropOff.start; //Set min drop off to be service hours start
            if (isToday(date)) {
                // If booking for today
                const now = new Date();
                if (isWithinInterval(now, serviceHours.dropOff)) {
                    // If current time is between office hours
                    result = roundToNearestMinutes(now, {
                        nearestTo: 30,
                        roundingMethod: 'ceil',
                    }); //round up to the next 30 minute interval
                    if (isAfter(result, serviceHours.dropOff.end)) {
                        // If rounding takes us outside of drop off hours, set min service hours end
                        result = serviceHours.dropOff.end;
                    }
                } else {
                    // If current time is outside of office hours
                    if (isAfter(now, serviceHours.dropOff.end)) {
                        //If it's past the office close time, set min as tomorrow's service hours start
                        result = this.getServiceHours({
                            date: addDays(date, 1),
                            fleetType,
                        }).dropOff.start;
                    } else {
                        // If current time is before open, set min as today's service hours start
                        result = serviceHours.dropOff.start;
                    }
                }
            }
        }
        return result;
    }

    getMaxDropOffDateTime({ date, fleetType }: { date: Date; fleetType: FleetType }) {
        const serviceHours = this.getServiceHours({ date, fleetType });
        if (!serviceHours.afterHoursPickUp) {
            // Only find the max pick up time if out of hours pick up is unavailable
            return setTime(date, serviceHours.pickUp.end); // Set max pick up time to be the service hours end
        }
        return setTime(date, Site.absoluteMaxDate);
    }

    findHolidayByDate(date: Date) {
        return (isValidDate(date) && this.combinedHolidays.find((holiday) => holiday?.isForDate(date))) || null;
    }

    isHoliday(date: Date): boolean {
        return Boolean(this.findHolidayByDate(date));
    }

    isFleetTypeAvailable(fleetType: FleetType): boolean {
        return this.siteSettings?.some(({ fleetTypeId }) => fleetTypeId?.toLowerCase() === fleetType?.id) || true;
    }

    getDefaultTimeForDate(date: Date): DefaultTime {
        const weekday = getEnWeekDay(date)?.toLowerCase() as keyof SiteDefaultTimes;
        return (
            this.defaultTimes?.[weekday] || {
                pickUp: '10:00',
                dropOff: '10:00',
            }
        );
    }

    isBeforeMinPickUp({ date, fleetType }: { date?: Date; fleetType?: FleetType } = {}) {
        const minDate =
            isValidDate(date) &&
            fleetType?.id &&
            this?.getMinPickUpDateTime({
                date: date,
                fleetType: fleetType as FleetType,
            });
        return minDate ? isBefore(date, minDate) : false;
    }

    isAfterMaxPickUp({ date, fleetType }: { date?: Date; fleetType?: FleetType } = {}) {
        const maxDate =
            isValidDate(date) &&
            fleetType?.id &&
            this?.getMaxPickUpDateTime({
                date: date,
                fleetType: fleetType as FleetType,
            });
        return maxDate ? isAfter(date, maxDate) : false;
    }
}
