import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import FleetCategoryAvailability from '../../../../lib/api/model/FleetCategoryAvailability';
import CurrencyStore from '../../../../store/CurrencyStore';
import FleetCategoryTeaser from '../Teaser/Teaser';

export interface AvailabilityGridRowProps {
    fleetCategory: FleetCategoryAvailability;
    onSelect?: (fleetCategory: FleetCategoryAvailability) => void;
    isActive?: boolean;
    isLoading?: boolean;
    isHighlighted?: boolean;
    className?: string;
    action?: 'create' | 'edit';
}

const AvailabilityGridRow: React.FC<AvailabilityGridRowProps> = observer(({ isLoading, isActive, fleetCategory, onSelect, isHighlighted, className, action }) => {
    const onFleetCategorySelected = useCallback(() => {
        onSelect?.(fleetCategory);
    }, [fleetCategory, onSelect]);
    return (
        <FleetCategoryTeaser
            className={className}
            isActive={isActive}
            isHighlighted={isHighlighted}
            fleetCategory={fleetCategory}
            isLoading={isLoading}
            currencyCode={CurrencyStore.selectedCurrencyCode || fleetCategory.currencyCode}
            onClick={onFleetCategorySelected}
            action={action}
        />
    );
});
AvailabilityGridRow.displayName = 'AvailabilityGridRow';
export default AvailabilityGridRow;
