import React from 'react';
import { useExcessReductionFormContext } from '#/components/Forms/ExcessReductionForm/ExcessReductionForm';
import { RentalExcessReduction } from '@jucy/rentals-api-client';
import clsx from 'clsx';
import { getExcessReductionBonus } from '../../ExcessMapper';

interface BonusItemsProps {
    item: Required<RentalExcessReduction>;
    className?: string;
}

const BonusItems: React.FC<BonusItemsProps> = ({ item, className }) => {
    const excessReductionFormContext = useExcessReductionFormContext();
    const bonus = excessReductionFormContext?.fleetTypeSlug ? getExcessReductionBonus(item, excessReductionFormContext) : null;
    return <div className={clsx('', className)}>{bonus ? bonus : null}</div>;
};
export default BonusItems;
