import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { URLSearchParamsInit, createSearchParams } from 'react-router-dom';
import { useRouteNavigate, useSearchParams } from '#/hooks';
import { useAuth } from '#/hooks/useAuth';
import { jucyAttribution } from '#/lib/SalesMonitoring/jucyAttribution';
import { getBodyTopOffset } from '#/lib/getBodyTopOffset';
import { isStarFleetType } from '#/lib/isStarFleetType';
import { mergeSearchParams } from '#/lib/mergeSearchParams';
import { Box } from '@mui/material';
import config from '../../../config';
import FleetCategoryAvailability from '../../../lib/api/model/FleetCategoryAvailability';
import appStateStore from '../../../store/AppStateStore';
import appState from '../../../store/AppStateStore';
import AvailabilityStore from '../../../store/AvailabilityStore';
import FleetTypesStore from '../../../store/FleetTypesStore';
import RentalTripSearchStore from '../../../store/RentalTripSearchStore';
import ReservationStore from '../../../store/ReservationStore';
import RouteRegistry from '../../../store/RouteRegistry';
import { JucyErrorAlert } from '../../Alert/JucyErrorAlert';
import OfflineNotice from '../../Alert/OfflineNotice';
import { DirectAvailabilityGrid } from '../../FleetCategory';
import DirectTripSearchForm from './DirectTripSearchForm';

interface DirectTripSearchAndResultsProps {
    searchFormTitle?: string;
    classNames?: {
        searchFromContainer?: string;
        searchFrom?: string;
    };
}

const DirectTripSearchAndResults: React.FC<DirectTripSearchAndResultsProps> = observer(({ searchFormTitle, classNames }) => {
    const [searchParams] = useSearchParams();
    const activeParams = useParams();
    const availabilityGridRef = useRef<HTMLDivElement>(null);
    const navigate = useRouteNavigate();
    const { user } = useAuth();
    const onAfterSearch = useCallback(() => {
        setTimeout(() => {
            if (availabilityGridRef.current) {
                window.scrollTo({
                    top: availabilityGridRef.current.getBoundingClientRect().top - getBodyTopOffset(),
                    behavior: 'smooth',
                });
            }
        }, 40);
    }, []);

    const onFleetCategorySelected = useCallback(
        async (fleetCategory: FleetCategoryAvailability) => {
            window.dataLayer?.push({
                event: 'click',
                eventAction: 'fleet-category-selected',
                eventCategory: 'statistics',
                eventLabel: fleetCategory.Name,
                eventValue: undefined,
            });
            ReservationStore.reset();
            AvailabilityStore.setSelectedFleetCategory(fleetCategory);
            const tripInfo = AvailabilityStore.asTripInfo();
            const quote = await ReservationStore.createQuote({ tripInfo });
            if (quote) {
                const routeParams = {
                    ...activeParams,
                    activeTab: 'excess-reduction',
                    fleetType: tripInfo.fleetCategory?.fleetType?.slug,
                    reservationReference: quote.ReservationReference,
                };
                if (isStarFleetType(fleetCategory) && appState.brand === 'jucy' && appStateStore.mode === 'direct' && !user?.isAuthenticated) {
                    const path = `obe/${RouteRegistry.getPath('direct-edit-quote', routeParams).replace(/^\//, '')}`;
                    const redirectUrl = new URL(path, config.baseUrl.star);
                    const attributionParams = jucyAttribution.getForwardUrlSearchParams();
                    redirectUrl.search = mergeSearchParams(redirectUrl.searchParams, searchParams as URLSearchParamsInit, attributionParams, {
                        utm_source: 'jucy-obe',
                    }).toString();
                    window.location.href = redirectUrl.toString();
                } else {
                    navigate('direct-edit-quote', {
                        params: routeParams,
                        search: createSearchParams(searchParams as Record<string, string>).toString(),
                    });
                }
            }
        },
        [activeParams, searchParams, navigate, user]
    );

    const [initialValues] = useState(() =>
        RentalTripSearchStore.getInitialValues({
            defaults: {
                driverAge: '21',
                fleetType: FleetTypesStore.getActiveFleetType() || FleetTypesStore.getFleetTypeBySlug(activeParams.fleetType) || undefined,
            },
            queryString: searchParams as Record<string, string>,
            reservation: ReservationStore.activeQuote,
        })
    );

    useEffect(() => {
        RentalTripSearchStore.setValues(initialValues);
    }, [initialValues]);
    return (
        <>
            <OfflineNotice />
            <div className={classNames?.searchFromContainer}>
                {searchFormTitle ? (
                    <div className="card-title pt-4 px-4">
                        <h1 className="card-title h2 mb-0">{searchFormTitle}</h1>
                    </div>
                ) : null}
                <Box className="card-body pt-3">
                    <DirectTripSearchForm
                        onAfterSubmit={onAfterSearch}
                        onSelectRouteDetails={{
                            routeId: 'direct-create-quote',
                        }}
                        className={classNames?.searchFrom}
                    />
                </Box>
            </div>
            {AvailabilityStore.state === 'error' ? <JucyErrorAlert title="Search error" message={AvailabilityStore.message} className="animate__animated animate__fadeIn" /> : null}
            {ReservationStore.state === 'error' ? (
                <JucyErrorAlert title="Error loading reservation" message={ReservationStore.message} className="animate__animated animate__fadeIn" />
            ) : null}
            <div ref={availabilityGridRef}>
                {AvailabilityStore.hasTripInfo &&
                AvailabilityStore.state !== 'error' &&
                ReservationStore.state !== 'error' &&
                AvailabilityStore.selectedDeal?.applicableProducts?.length !== 1 ? (
                    <DirectAvailabilityGrid
                        highlightedCode={searchParams.fleetCategory}
                        activeCode={AvailabilityStore.selectedFleetCategory?.CategoryCode}
                        loading={AvailabilityStore.state === 'loading'}
                        fleetCategories={AvailabilityStore.availableFleetCategories}
                        onSelect={onFleetCategorySelected}
                    />
                ) : null}
            </div>
        </>
    );
});
DirectTripSearchAndResults.displayName = 'DirectTripSearchAndResults';

export default DirectTripSearchAndResults;
