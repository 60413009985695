import { isValidDate } from '@jucy-ui/common';
import { isBefore } from 'date-fns';
import { getIsDateHolidayForBranch } from '../lib';
import { SearchFormData } from '../types';

export const validateDropOffDate = ({ dropOffBranch, pickUpDate, dropOffDate }: Partial<SearchFormData>): string | undefined => {
    if (!isValidDate(dropOffDate)) {
        return 'Please select valid pick up date';
    }

    if (pickUpDate && isBefore(dropOffDate, pickUpDate)) {
        return 'Drop off date must be after pick up date';
    }

    if (dropOffBranch && getIsDateHolidayForBranch({ branch: dropOffBranch, date: dropOffDate })) {
        return 'Drop off branch is closed on this date';
    }
};
