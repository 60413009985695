/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { DealConditionsBlackoutDates } from './DealConditionsBlackoutDates';
import {
    DealConditionsBlackoutDatesFromJSON,
    DealConditionsBlackoutDatesFromJSONTyped,
    DealConditionsBlackoutDatesToJSON,
} from './DealConditionsBlackoutDates';
import type { DealConditionsEndDateBetween } from './DealConditionsEndDateBetween';
import {
    DealConditionsEndDateBetweenFromJSON,
    DealConditionsEndDateBetweenFromJSONTyped,
    DealConditionsEndDateBetweenToJSON,
} from './DealConditionsEndDateBetween';
import type { DealConditionsStartDateBetween } from './DealConditionsStartDateBetween';
import {
    DealConditionsStartDateBetweenFromJSON,
    DealConditionsStartDateBetweenFromJSONTyped,
    DealConditionsStartDateBetweenToJSON,
} from './DealConditionsStartDateBetween';

/**
 * Optional conditions that must be satisfied for the deal to be applicable to an order. Not all conditions are applicable to all deal types.
 *
 * @export
 * @interface DealConditions
 */
export interface DealConditions {
    /**
     * Products that one of which must be included in the order.
     * @type {Array<string>}
     * @memberof DealConditions
     */
    productCodeOneOf?: Array<string>;
    /**
     * Max days product can be booked for.
     * @type {number}
     * @memberof DealConditions
     */
    maxHireDays?: number;
    /**
     * Minimum days product can be booked for.
     * @type {number}
     * @memberof DealConditions
     */
    minHireDays?: number;
    /**
     *
     * @type {DealConditionsStartDateBetween}
     * @memberof DealConditions
     */
    startDateBetween?: DealConditionsStartDateBetween;
    /**
     *
     * @type {DealConditionsEndDateBetween}
     * @memberof DealConditions
     */
    endDateBetween?: DealConditionsEndDateBetween;
    /**
     * Earliest booking start date.
     * @type {Date}
     * @memberof DealConditions
     */
    startDateOnOrAfter?: Date;
    /**
     * Latest booking end date.
     * @type {Date}
     * @memberof DealConditions
     */
    endDateOnOrBefore?: Date;
    /**
     * Booking start site code.
     * @type {string}
     * @memberof DealConditions
     */
    startLocationAt?: string;
    /**
     * Booking end site code.
     * @type {string}
     * @memberof DealConditions
     */
    endLocationAt?: string;
    /**
     * Date ranges which start and/or end dates must not fall within.
     * @type {Array<DealConditionsBlackoutDates>}
     * @memberof DealConditions
     */
    blackoutDates?: Array<DealConditionsBlackoutDates>;
}

/**
 * Check if a given object implements the DealConditions interface.
 */
export function instanceOfDealConditions(value: object): value is DealConditions {
    return true;
}

export function DealConditionsFromJSON(json: any): DealConditions {
    return DealConditionsFromJSONTyped(json, false);
}

export function DealConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealConditions {
    if (json == null) {
        return json;
    }
    return {
        productCodeOneOf: json['productCodeOneOf'] == null ? undefined : json['productCodeOneOf'],
        maxHireDays: json['maxHireDays'] == null ? undefined : json['maxHireDays'],
        minHireDays: json['minHireDays'] == null ? undefined : json['minHireDays'],
        startDateBetween: json['startDateBetween'] == null ? undefined : DealConditionsStartDateBetweenFromJSON(json['startDateBetween']),
        endDateBetween: json['endDateBetween'] == null ? undefined : DealConditionsEndDateBetweenFromJSON(json['endDateBetween']),
        startDateOnOrAfter: json['startDateOnOrAfter'] == null ? undefined : stringToDate(json['startDateOnOrAfter']),
        endDateOnOrBefore: json['endDateOnOrBefore'] == null ? undefined : stringToDate(json['endDateOnOrBefore']),
        startLocationAt: json['startLocationAt'] == null ? undefined : json['startLocationAt'],
        endLocationAt: json['endLocationAt'] == null ? undefined : json['endLocationAt'],
        blackoutDates: json['blackoutDates'] == null ? undefined : (json['blackoutDates'] as Array<any>).map(DealConditionsBlackoutDatesFromJSON),
    };
}

export function DealConditionsToJSON(value?: DealConditions | null): any {
    if (value == null) {
        return value;
    }
    return {
        productCodeOneOf: value['productCodeOneOf'],
        maxHireDays: value['maxHireDays'],
        minHireDays: value['minHireDays'],
        startDateBetween: DealConditionsStartDateBetweenToJSON(value['startDateBetween']),
        endDateBetween: DealConditionsEndDateBetweenToJSON(value['endDateBetween']),
        startDateOnOrAfter: value['startDateOnOrAfter'] == null ? undefined : dateToString(value['startDateOnOrAfter']),
        endDateOnOrBefore: value['endDateOnOrBefore'] == null ? undefined : dateToString(value['endDateOnOrBefore']),
        startLocationAt: value['startLocationAt'],
        endLocationAt: value['endLocationAt'],
        blackoutDates: value['blackoutDates'] == null ? undefined : (value['blackoutDates'] as Array<any>).map(DealConditionsBlackoutDatesToJSON),
    };
}
