'use client';
import React, { forwardRef } from 'react';
import { StackProps } from '@mui/material';
import Stack from '@mui/material/Stack';

export interface SearchFormFieldStackProps extends StackProps {
    renderer: (props: { startField: React.ReactNode; endField: React.ReactNode }) => React.ReactNode;
}

const TripFieldRootComponent = forwardRef<HTMLDivElement, SearchFormFieldStackProps>(
    ({ children, renderer, ...props }, ref) => {
        if (Array.isArray(children) && typeof renderer === 'function') {
            const startField = children[0] as React.ReactElement;
            const endField = children[2] as React.ReactElement;
            return (
                <Stack data-thing="TripFieldRootComponent" ref={ref} {...props} sx={{ position: 'relative' }}>
                    {renderer({ startField, endField })}
                </Stack>
            );
        }
        return (
            <Stack ref={ref} {...props}>
                {children}
            </Stack>
        );
    }
);
TripFieldRootComponent.displayName = 'TripFieldRoot';
export const TripFieldRoot = React.memo(TripFieldRootComponent);
