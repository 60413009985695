import { asError } from '@jucy-ui/common';
import { SearchFormValues, SearchFormVariants } from '@jucy-ui/components/SearchForm';
import { UseFormReturn } from '@jucy-ui/forms';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from '../../../hooks';
import { useDirectSubmitSearchValues } from '../../../hooks/useDirectSumbitSearchValues';
import fleetTypesStore from '../../../store/FleetTypesStore';
import rentalTripSearchStore from '../../../store/RentalTripSearchStore';
import reservationStore from '../../../store/ReservationStore';
import { SearchForm, validateSearchValues } from './SearchForm';
import { PartialSearchFormValues } from './SearchFormValues';
import { useHandleFleetTypeChanged } from './useHandleFleetTypeChanged';

export interface DirectTripSearchFormProps {
    className?: string;
    layout?: SearchFormVariants;
    onSelectRouteDetails: {
        routeId: string;
        params?: Record<string, string>;
    };
    onAfterSubmit?: (values: PartialSearchFormValues, form: UseFormReturn<SearchFormValues>) => void | Promise<void>;
}

const DirectTripSearchForm: React.FC<DirectTripSearchFormProps> = observer(({ className, onSelectRouteDetails, onAfterSubmit, layout }) => {
    const { reservationReference } = useParams();
    const [searchParams] = useSearchParams();
    const routeParams = useParams();
    const onSubmitMutation = useDirectSubmitSearchValues();
    const onSubmit = useCallback(
        async (values: PartialSearchFormValues, form: UseFormReturn<SearchFormValues>) => {
            form.clearErrors();
            runInAction(() => {
                rentalTripSearchStore.error = undefined;
                reservationStore.error = undefined;
                rentalTripSearchStore.state = 'done';
                reservationStore.state = 'done';
            });
            rentalTripSearchStore.setValues(values);
            if (!validateSearchValues(values)) {
                form.setError('root.submit-error', { message: 'Search request failed' });
                return;
            }
            try {
                await onSubmitMutation.mutateAsync({
                    values,
                    resultsRoute: {
                        routeId: onSelectRouteDetails.routeId,
                        params: {
                            ...onSelectRouteDetails.params,
                            ...(reservationReference ? { reservationReference } : undefined),
                        },
                    },
                });
                onAfterSubmit?.(values, form);
            } catch (e) {
                form.setError('root.submit-error', { message: asError(e).message || 'Search request failed' });
            }
        },
        [onAfterSubmit, onSelectRouteDetails.params, onSelectRouteDetails.routeId, reservationReference, onSubmitMutation]
    );

    const [initialValues] = useState(() =>
        rentalTripSearchStore.getInitialValues({
            defaults: {
                driverAge: '21',
                fleetType: fleetTypesStore.getActiveFleetType() || fleetTypesStore.getFleetTypeBySlug(routeParams.fleetType) || undefined,
            },
            queryString: searchParams as Record<string, string>,
            reservation: reservationStore.activeQuote,
        })
    );

    const handleFleetTypeChanged = useHandleFleetTypeChanged();

    return <SearchForm initialValues={initialValues} className={className} onSubmit={onSubmit} onFleetTypeChanged={handleFleetTypeChanged} layout={layout} />;
});
DirectTripSearchForm.displayName = 'DirectTripSearchForm';

export default DirectTripSearchForm;
