import { computed, makeAutoObservable, observable } from 'mobx';
import Address from './Address';

export default class HirerDetails {
    @observable Id = '';
    @observable FirstName = '';
    @observable LastName = '';
    @observable MobileNumber = '';
    @observable HomeAddress = new Address();
    @observable NumberOfPeople = 1;
    @observable Email = '';
    @observable DateOfBirth = '';
    @observable DriversLicenceExpiry = '';
    @observable DriversLicenceCountry = '';
    @observable DriversLicenceNumber = '';
    @observable LucyClubNumber = '';
    @observable ArrivalFlightNumber = '';
    @observable DepartureFlightNumber = '';
    @observable OptIn = false;

    constructor(props?: Partial<HirerDetails>) {
        makeAutoObservable(this);
        Object.assign(this, props);
    }

    @computed get Name() {
        return `${this.FirstName} ${this.LastName}`.trim();
    }

    static fromApi(apiHirerDetails?: Partial<HirerDetails>) {
        let data = {};
        if (apiHirerDetails) {
            const { HomeAddress, ...details } = apiHirerDetails;
            data = {
                ...details,
                HomeAddress: Address.fromApi(HomeAddress),
            };
        }
        return new HirerDetails(data);
    }
}
