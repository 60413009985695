'use client';
import React from 'react';
import { SlotComponentProps, useSlotProps } from '@mui/base';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useThemeProps } from '@mui/material/styles';
import { FleetCategoryTeaserCardOwnerState } from '../types';
import { FleetCategoryTeaserCardActionButton } from './FleetCategoryTeaserCardActionButton';
import { FleetCategoryTeaserCardPrice } from './FleetCategoryTeaserCardPrice';

export interface FleetCategoryTeaserCardActionsSlots {
    root?: typeof Box;
    header?: typeof Box;
    content?: typeof Box;
    footer?: React.ElementType;
}

export interface FleetCategoryTeaserCardActionsProps {
    ownerState: FleetCategoryTeaserCardOwnerState;
    slots?: FleetCategoryTeaserCardActionsSlots;
    className?: string;
    slotProps?: {
        root: SlotComponentProps<typeof Box, object, FleetCategoryTeaserCardOwnerState>;
        header: SlotComponentProps<typeof Box, object, FleetCategoryTeaserCardOwnerState>;
        content: SlotComponentProps<typeof Box, object, FleetCategoryTeaserCardOwnerState>;
        footer: SlotComponentProps<typeof Box, object, FleetCategoryTeaserCardOwnerState>;
    };
}

const FleetCategoryTeaserCardActionsRoot = styled(Box, {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'actions',
})<{ ownerState?: FleetCategoryTeaserCardOwnerState }>(() => ({
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1',
}));
const Header = styled(Box, {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'header',
})<{ ownerState?: FleetCategoryTeaserCardOwnerState }>(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));
const Content = styled(Box, {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'content',
})<{ ownerState?: FleetCategoryTeaserCardOwnerState }>(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));
export const Footer = styled(Box, {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'footer',
})<{ ownerState?: FleetCategoryTeaserCardOwnerState }>(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));
export const FleetCategoryTeaserCardActionsPanel = React.forwardRef<HTMLDivElement, FleetCategoryTeaserCardActionsProps>((inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: 'FleetCategoryTeaserCardActionss' });
    const { slotProps, className, ownerState, ...other } = props;
    const { isBookable, action, isOnRequest } = ownerState;
    const RootSlot = props.slots?.root || FleetCategoryTeaserCardActionsRoot;
    const HeaderSlot = props.slots?.header || Header;
    const ContentSlot = props.slots?.content || Content;
    const FooterSlot = props.slots?.footer || Footer;
    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            ref,
        },
        ownerState,
        className: className,
    });
    const headerProps = useSlotProps({
        elementType: HeaderSlot,
        externalSlotProps: slotProps?.header,
        ownerState,
    });
    const contentProps = useSlotProps({
        elementType: ContentSlot,
        externalSlotProps: slotProps?.content,
        ownerState,
    });
    const footerProps = useSlotProps({
        elementType: FooterSlot,
        externalSlotProps: slotProps?.footer,
        ownerState,
    });
    return (
        <RootSlot {...rootProps}>
            <HeaderSlot {...headerProps}>{isBookable ? <FleetCategoryTeaserCardPrice ownerState={ownerState} /> : null}</HeaderSlot>
            <ContentSlot {...contentProps} />
            <FooterSlot {...footerProps}>
                {action !== 'edit' || !isOnRequest ? <FleetCategoryTeaserCardActionButton ownerState={ownerState} /> : null}
            </FooterSlot>
        </RootSlot>
    );
});
FleetCategoryTeaserCardActionsPanel.displayName = 'FleetCategoryTeaserCardActionsPanel';
