/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';

/**
 *
 * @export
 * @interface InfringementNote
 */
export interface InfringementNote {
    /**
     *
     * @type {string}
     * @memberof InfringementNote
     */
    accountKey: string;
    /**
     *
     * @type {string}
     * @memberof InfringementNote
     */
    reservationNumber: string;
    /**
     *
     * @type {string}
     * @memberof InfringementNote
     */
    noticeIssuer: string;
    /**
     *
     * @type {string}
     * @memberof InfringementNote
     */
    noticeNumber: string;
    /**
     *
     * @type {Date}
     * @memberof InfringementNote
     */
    declatationDispatchDate: Date;
    /**
     *
     * @type {Date}
     * @memberof InfringementNote
     */
    noticeDateTime: Date;
    /**
     *
     * @type {string}
     * @memberof InfringementNote
     */
    noticeUrl?: string;
    /**
     *
     * @type {string}
     * @memberof InfringementNote
     */
    statuatoryDeclarationUrl?: string;
}

/**
 * Check if a given object implements the InfringementNote interface.
 */
export function instanceOfInfringementNote(value: object): value is InfringementNote {
    if (!('accountKey' in value) || value['accountKey'] === undefined) return false;
    if (!('reservationNumber' in value) || value['reservationNumber'] === undefined) return false;
    if (!('noticeIssuer' in value) || value['noticeIssuer'] === undefined) return false;
    if (!('noticeNumber' in value) || value['noticeNumber'] === undefined) return false;
    if (!('declatationDispatchDate' in value) || value['declatationDispatchDate'] === undefined) return false;
    if (!('noticeDateTime' in value) || value['noticeDateTime'] === undefined) return false;
    return true;
}

export function InfringementNoteFromJSON(json: any): InfringementNote {
    return InfringementNoteFromJSONTyped(json, false);
}

export function InfringementNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfringementNote {
    if (json == null) {
        return json;
    }
    return {
        accountKey: json['accountKey'],
        reservationNumber: json['reservationNumber'],
        noticeIssuer: json['noticeIssuer'],
        noticeNumber: json['noticeNumber'],
        declatationDispatchDate: stringToDate(json['declatationDispatchDate']),
        noticeDateTime: stringToDate(json['noticeDateTime']),
        noticeUrl: json['noticeUrl'] == null ? undefined : json['noticeUrl'],
        statuatoryDeclarationUrl: json['statuatoryDeclarationUrl'] == null ? undefined : json['statuatoryDeclarationUrl'],
    };
}

export function InfringementNoteToJSON(value?: InfringementNote | null): any {
    if (value == null) {
        return value;
    }
    return {
        accountKey: value['accountKey'],
        reservationNumber: value['reservationNumber'],
        noticeIssuer: value['noticeIssuer'],
        noticeNumber: value['noticeNumber'],
        declatationDispatchDate: dateToString(value['declatationDispatchDate']),
        noticeDateTime: dateToString(value['noticeDateTime']),
        noticeUrl: value['noticeUrl'],
        statuatoryDeclarationUrl: value['statuatoryDeclarationUrl'],
    };
}
