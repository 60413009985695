'use client';
import React from 'react';

interface CardTextProps {
    className?: string;
    color?: 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose';
    children?: React.ReactNode;
}

export const CardText: React.FC<CardTextProps> = (props: CardTextProps) => {
    const { className, children, color, ...rest } = props;
    return (
        <div className={className} {...rest}>
            {children}
        </div>
    );
};
