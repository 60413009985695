import ErrorReporter from '#/lib/ErrorReporter';
import config from '../../../config';
import { ChatbotSourceProvider } from './ChatbotSourceProvider';
import { getCommissionFactory } from './CommissionFactory';
import { CommissionJunction } from './CommissionJunction';
import { GAEcommerce } from './GAEcommerce';
import { Kayak } from './Kayak';
import { SalesMonitorProvider } from './SalesMonitorProvider';
import { Tradedoubler } from './tradedoubler';
import { DataDogSalesMonitorProvider } from '#/lib/SalesMonitoring/Providers/DataDogSalesMonitorProvider';

let initialized = false;
export const initSalesMonitoring = (): void => {
    if (initialized) return;
    initialized = true;
    for (const provider of providers) {
        try {
            if (provider?.init) {
                provider.init();
            }
        } catch (e) {
            ErrorReporter.captureError(e);
        }
    }
};

const providers: SalesMonitorProvider[] =
    typeof window !== 'undefined'
        ? ([
              new GAEcommerce(),
              config.commissionFactoryId || config.starCommissionFactoryId ? getCommissionFactory() : undefined,
              config.commissionJunctionEnterpriseId ? new CommissionJunction(config.commissionJunctionEnterpriseId as string, config.commissionJunctionTagId as string) : undefined,
              config.tradedoublerOrganizationId ? new Tradedoubler(config.tradedoublerOrganizationId) : undefined,
              new ChatbotSourceProvider(),
              new Kayak(),
              new DataDogSalesMonitorProvider(),
          ].filter(Boolean) as SalesMonitorProvider[])
        : [];

export default providers;
