import * as React from 'react';
import classNames from 'classnames';
import { Field as FormikField } from 'formik';

const FormField = ({ className, fieldClassName, ...props }) => {
    const Tag = <FormikField className={fieldClassName} {...props} />;
    return props.floatLabel ? <div className={classNames('form-label-group', className)}>{Tag}</div> : Tag;
};

export default FormField;
