'use client';
import { styled } from '@mui/material/styles';

export const ComparisonTableContainer = styled('div', {
    name: 'JucyComparisonTableContainer',
    slot: 'Root',
})({
    overflow: 'auto',
    scrollSnapType: 'both mandatory',
    lineHeight: 1,
});
