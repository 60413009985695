import { SiteServiceHours, SiteSettings, getEnWeekDay, setTimeFromString } from '@jucy-ui/common';

export const getServiceHours = ({ date, siteSettings }: { date?: Date | null; siteSettings?: SiteSettings }) => {
    const dayOfWeek = date && (getEnWeekDay(date)?.toLowerCase() as keyof SiteServiceHours);
    const serviceHours = dayOfWeek && siteSettings?.serviceHours[dayOfWeek];
    if (!serviceHours || siteSettings.isAfterHoursPickUpAvailable || !serviceHours.open || !serviceHours.close) {
        return undefined;
    }
    return {
        open: setTimeFromString(date, serviceHours.open),
        close: setTimeFromString(date, serviceHours.close),
    };
};
