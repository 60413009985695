import { observer } from 'mobx-react-lite';
import React from 'react';
import { Money } from '@jucy/rentals-api-client';
import CurrencyStore from '../../store/CurrencyStore';

export type Price = {
    currencyCode?: string;
    value?: number | string;
    Value?: number;
};

export const asMoney = (value: Price | number | string, code = 'NZD'): Required<Money> => {
    const result = {
        currencyCode: code,
        value: 0,
    };
    if (typeof value === 'object') {
        result.currencyCode = value?.currencyCode || (value as { CurrencyCode: string })?.CurrencyCode || code || 'NZD';
        if (!isNaN(value?.value as number)) {
            result.value = Number(value.value);
        } else if (!isNaN(value?.Value as number)) {
            result.value = Number(value.Value);
        } else {
            result.value = 0;
        }
    } else {
        result.value = Number(value);
    }
    return result;
};

export const getCurrencyFormatter = (input: Required<Money>) =>
    new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: input.currencyCode,
    });

export const formatCurrency = (input: Price | number | string, currencyCode?: string | null): string => {
    const money = CurrencyStore.convertCurrency(asMoney(input, currencyCode || 'NZD'));
    const formatter = getCurrencyFormatter(money);
    return formatter.format(money.value).replace(/^(-?)[A-Z]+/, '$1');
};

export const getCurrencyComponents = (input: Price | number | string): Intl.NumberFormatPart[] => {
    const money = asMoney(input);
    const formatter = getCurrencyFormatter(money);
    return formatter.formatToParts(money.value);
};

const CurrencyFormatter: React.FC<{ price: Price | number | string; prefixCurrencyCode?: boolean }> = observer(({ price, prefixCurrencyCode }) => {
    const money = asMoney(price);
    const currencyCode = CurrencyStore.selectedCurrencyCode || money.currencyCode;
    return (
        <>
            {prefixCurrencyCode ? currencyCode : null} {formatCurrency(money)}
        </>
    );
});

export default CurrencyFormatter;
