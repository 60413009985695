import { FleetTypeSlug } from '@jucy/rentals-common';
import { differenceInHours } from 'date-fns';
import { FleetType } from '../services';

const calculateHireDays = ({ start, end, fleetType }: { start: Date; end: Date; fleetType?: FleetType }): number => {
    const hireDays = differenceInHours(end, start) + 1;
    return fleetType?.slug === FleetTypeSlug.campervan ? Math.ceil(hireDays / 24) : Math.floor(hireDays / 24);
};

export default calculateHireDays;
