import React, { useCallback } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import clsx from 'clsx';
import { useAlternateDateSlideContext } from './AlternateDateSlideContext';

const AlternateDatesSlideToolTip: React.FC<Omit<TooltipProps, 'title' | 'onClose' | 'onOpen'>> = ({ placement, className, children, ...props }) => {
    const { isAnimating, tooltip, isLoading } = useAlternateDateSlideContext();
    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
    const handleTooltipClose = useCallback(() => {
        setIsTooltipOpen(false);
    }, []);
    const handleTooltipOpen = useCallback(() => {
        setIsTooltipOpen(Boolean(!isAnimating && !isLoading));
    }, [isAnimating, isLoading]);

    return (
        <Tooltip
            className={clsx(className, 'slider-tooltips')}
            data-testid="slider-tooltips"
            open={isTooltipOpen}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            placement={placement || 'top'}
            title={tooltip}
            {...props}
        >
            {children}
        </Tooltip>
    );
};
export default AlternateDatesSlideToolTip;
