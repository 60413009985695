import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import type { CatalogItem } from '../services';
import { getProductCatalogItem, productCatalogQueryKeys } from '../services';

type UseGetFleetTypesOptions = UseQueryOptions<CatalogItem, unknown, CatalogItem, string[]> & { fleetCategoryCode: string };

export const useGetProductCatalogItem = ({ fleetCategoryCode, ...options }: Omit<UseGetFleetTypesOptions, 'queryKey' | 'queryFn'>) =>
    useQuery({
        queryKey: productCatalogQueryKeys.item(fleetCategoryCode),
        queryFn: () => getProductCatalogItem(fleetCategoryCode) as Promise<CatalogItem>,
        ...options,
    });
