import { addMinutes, endOfDay, isBefore, isSameMinute, startOfDay } from 'date-fns';
import { getNow } from './getNow';
import { setTime } from './setTime';

export const generateTimeRange = ({ step, startTime, endTime }: { step: number; startTime?: Date; endTime?: Date }) => {
    const now = getNow();
    let start = startTime ? setTime(getNow(), startTime) : startOfDay(now);
    const end = endTime ? setTime(getNow(), endTime) : endOfDay(now);
    const times = [];
    while (isSameMinute(start, end) || isBefore(start, end)) {
        times.push(start);
        start = addMinutes(start, step);
    }
    return times;
};
