export const waitForElement = (selector: string) =>
    new Promise<HTMLElement | null>((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector<HTMLElement>(selector));
        }

        const observer = new MutationObserver(() => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                resolve(document.querySelector<HTMLElement>(selector));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    });
