'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

export interface DiffProps {
    oldValue?: string | null;
    newValue?: string | null;
    modifiedStyle?: SxProps<Theme>;
}

export const Diff: React.FC<DiffProps> = ({ oldValue, newValue, modifiedStyle }) => {
    const changed = oldValue && newValue ? oldValue !== newValue : false;
    if (!changed) {
        return oldValue;
    }
    return (
        <>
            <Box component="span" sx={mergeSx({ textDecoration: 'line-through' }, modifiedStyle)}>
                {oldValue}
            </Box>
            &nbsp;
            {newValue}
        </>
    );
};
