/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
    ConfirmQuoteResponse,
    ConfirmReservationResponse,
    CreateQuoteResponse,
    IdConfirmBody,
    ListReservationsResponse,
    QuoteWithProductOptions,
    ReservationPricingEnquiryResponse,
    SecondaryProductMap,
    UpdateQuoteResponse,
} from '../models/index';
import {
    ConfirmQuoteResponseFromJSON,
    ConfirmQuoteResponseToJSON,
    ConfirmReservationResponseFromJSON,
    ConfirmReservationResponseToJSON,
    CreateQuoteResponseFromJSON,
    CreateQuoteResponseToJSON,
    IdConfirmBodyFromJSON,
    IdConfirmBodyToJSON,
    ListReservationsResponseFromJSON,
    ListReservationsResponseToJSON,
    QuoteWithProductOptionsFromJSON,
    QuoteWithProductOptionsToJSON,
    ReservationPricingEnquiryResponseFromJSON,
    ReservationPricingEnquiryResponseToJSON,
    SecondaryProductMapFromJSON,
    SecondaryProductMapToJSON,
    UpdateQuoteResponseFromJSON,
    UpdateQuoteResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface CancelReservationRequest {
    accountKey: string;
    reservationNumber: string;
    onlineLogin?: string;
}

export interface ConfirmQuoteRequest {
    accountKey: string;
    quoteId: string;
    country?: string;
    dateOfBirth?: Date;
    licenseNumber?: string;
    licenseCountry?: string;
    licenseExpiry?: Date;
    arrivalFlight?: string;
    agentVoucherReference?: string;
    deposit?: boolean;
    marketingAllowed?: boolean;
}

export interface ConfirmReservationRequest {
    accountId: string;
    id: string;
    idConfirmBody?: IdConfirmBody;
}

export interface CreateQuoteRequest {
    accountKey: string;
    firstName: string;
    lastName: string;
    email: string;
    categoryCode: string;
    pickUpLocationCode: string;
    dropOffLocationCode: string;
    pickUpDateTime: Date;
    dropOffDateTime: Date;
    driverAge?: string;
    mobilePhone?: string;
    campaignCode?: string;
    flightNumber?: string;
    languageCode?: string;
    marketingAllowed?: boolean;
    mergeSurchargesFees?: CreateQuoteMergeSurchargesFeesEnum;
    agentName?: string;
    numberOfPeople?: number;
}

export interface GetReservationByRefRequest {
    accountId: string;
    ref: string;
    lastName?: string;
}

export interface GetReservationPricingByRefRequest {
    accountId: string;
    ref: string;
    secondaryProductMap?: Array<SecondaryProductMap>;
}

export interface ListReservationsRequest {
    accountId: string;
    query?: string;
    page?: number;
    itemsPerPage?: number;
    sort?: string;
}

export interface UpdateQuoteRequest {
    accountKey: string;
    quoteId: string;
    secondaryProductsAndInsuranceMap: string;
    agentName?: string;
    languageCode?: string;
    numberOfPeople?: number;
}

/**
 *
 */
export class ReservationsApi extends runtime.BaseAPI {
    /**
     * Cancel a reservation
     */
    async cancelReservationRaw(
        requestParameters: CancelReservationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accountKey'] == null) {
            throw new runtime.RequiredError('accountKey', 'Required parameter "accountKey" was null or undefined when calling cancelReservation().');
        }

        if (requestParameters['reservationNumber'] == null) {
            throw new runtime.RequiredError(
                'reservationNumber',
                'Required parameter "reservationNumber" was null or undefined when calling cancelReservation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['reservationNumber'] != null) {
            queryParameters['reservationNumber'] = requestParameters['reservationNumber'];
        }

        if (requestParameters['onlineLogin'] != null) {
            queryParameters['onlineLogin'] = requestParameters['onlineLogin'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/Account/{accountKey}/1.0/CancelReservation`.replace(
                    `{${'accountKey'}}`,
                    encodeURIComponent(String(requestParameters['accountKey']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel a reservation
     */
    async cancelReservation(
        accountKey: string,
        reservationNumber: string,
        onlineLogin?: string,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.cancelReservationRaw({ accountKey: accountKey, reservationNumber: reservationNumber, onlineLogin: onlineLogin }, initOverrides);
    }

    /**
     * Confirming a quote coverts the quote to a reservation
     * Confirm a quote
     */
    async confirmQuoteRaw(
        requestParameters: ConfirmQuoteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<ConfirmQuoteResponse>> {
        if (requestParameters['accountKey'] == null) {
            throw new runtime.RequiredError('accountKey', 'Required parameter "accountKey" was null or undefined when calling confirmQuote().');
        }

        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError('quoteId', 'Required parameter "quoteId" was null or undefined when calling confirmQuote().');
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        if (requestParameters['country'] != null) {
            queryParameters['country'] = requestParameters['country'];
        }

        if (requestParameters['dateOfBirth'] != null) {
            queryParameters['dateOfBirth'] = (requestParameters['dateOfBirth'] as any).toISOString().substring(0, 10);
        }

        if (requestParameters['licenseNumber'] != null) {
            queryParameters['licenseNumber'] = requestParameters['licenseNumber'];
        }

        if (requestParameters['licenseCountry'] != null) {
            queryParameters['licenseCountry'] = requestParameters['licenseCountry'];
        }

        if (requestParameters['licenseExpiry'] != null) {
            queryParameters['licenseExpiry'] = (requestParameters['licenseExpiry'] as any).toISOString().substring(0, 10);
        }

        if (requestParameters['arrivalFlight'] != null) {
            queryParameters['arrivalFlight'] = requestParameters['arrivalFlight'];
        }

        if (requestParameters['agentVoucherReference'] != null) {
            queryParameters['agentVoucherReference'] = requestParameters['agentVoucherReference'];
        }

        if (requestParameters['deposit'] != null) {
            queryParameters['deposit'] = requestParameters['deposit'];
        }

        if (requestParameters['marketingAllowed'] != null) {
            queryParameters['marketingAllowed'] = requestParameters['marketingAllowed'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/Account/{accountKey}/1.0/ConfirmQuote`.replace(
                    `{${'accountKey'}}`,
                    encodeURIComponent(String(requestParameters['accountKey']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmQuoteResponseFromJSON(jsonValue));
    }

    /**
     * Confirming a quote coverts the quote to a reservation
     * Confirm a quote
     */
    async confirmQuote(
        accountKey: string,
        quoteId: string,
        country?: string,
        dateOfBirth?: Date,
        licenseNumber?: string,
        licenseCountry?: string,
        licenseExpiry?: Date,
        arrivalFlight?: string,
        agentVoucherReference?: string,
        deposit?: boolean,
        marketingAllowed?: boolean,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<ConfirmQuoteResponse> {
        const response = await this.confirmQuoteRaw(
            {
                accountKey: accountKey,
                quoteId: quoteId,
                country: country,
                dateOfBirth: dateOfBirth,
                licenseNumber: licenseNumber,
                licenseCountry: licenseCountry,
                licenseExpiry: licenseExpiry,
                arrivalFlight: arrivalFlight,
                agentVoucherReference: agentVoucherReference,
                deposit: deposit,
                marketingAllowed: marketingAllowed,
            },
            initOverrides
        );
        return await response.value();
    }

    /**
     * Confirm reservation
     */
    async confirmReservationRaw(
        requestParameters: ConfirmReservationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<ConfirmReservationResponse>> {
        if (requestParameters['accountId'] == null) {
            throw new runtime.RequiredError('accountId', 'Required parameter "accountId" was null or undefined when calling confirmReservation().');
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling confirmReservation().');
        }

        const queryParameters: any = {};

        if (requestParameters['accountId'] != null) {
            queryParameters['accountId'] = requestParameters['accountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/rental-reservations/{id}/confirm`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: IdConfirmBodyToJSON(requestParameters['idConfirmBody']),
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmReservationResponseFromJSON(jsonValue));
    }

    /**
     * Confirm reservation
     */
    async confirmReservation(
        accountId: string,
        id: string,
        idConfirmBody?: IdConfirmBody,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<ConfirmReservationResponse> {
        const response = await this.confirmReservationRaw({ accountId: accountId, id: id, idConfirmBody: idConfirmBody }, initOverrides);
        return await response.value();
    }

    /**
     * Creates a quote for the given fleet category, dates and locations. Replies with additional items and products that can be added to the quote
     * Create a quote
     */
    async createQuoteRaw(
        requestParameters: CreateQuoteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<CreateQuoteResponse>> {
        if (requestParameters['accountKey'] == null) {
            throw new runtime.RequiredError('accountKey', 'Required parameter "accountKey" was null or undefined when calling createQuote().');
        }

        if (requestParameters['firstName'] == null) {
            throw new runtime.RequiredError('firstName', 'Required parameter "firstName" was null or undefined when calling createQuote().');
        }

        if (requestParameters['lastName'] == null) {
            throw new runtime.RequiredError('lastName', 'Required parameter "lastName" was null or undefined when calling createQuote().');
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError('email', 'Required parameter "email" was null or undefined when calling createQuote().');
        }

        if (requestParameters['categoryCode'] == null) {
            throw new runtime.RequiredError('categoryCode', 'Required parameter "categoryCode" was null or undefined when calling createQuote().');
        }

        if (requestParameters['pickUpLocationCode'] == null) {
            throw new runtime.RequiredError(
                'pickUpLocationCode',
                'Required parameter "pickUpLocationCode" was null or undefined when calling createQuote().'
            );
        }

        if (requestParameters['dropOffLocationCode'] == null) {
            throw new runtime.RequiredError(
                'dropOffLocationCode',
                'Required parameter "dropOffLocationCode" was null or undefined when calling createQuote().'
            );
        }

        if (requestParameters['pickUpDateTime'] == null) {
            throw new runtime.RequiredError(
                'pickUpDateTime',
                'Required parameter "pickUpDateTime" was null or undefined when calling createQuote().'
            );
        }

        if (requestParameters['dropOffDateTime'] == null) {
            throw new runtime.RequiredError(
                'dropOffDateTime',
                'Required parameter "dropOffDateTime" was null or undefined when calling createQuote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['firstName'] != null) {
            queryParameters['firstName'] = requestParameters['firstName'];
        }

        if (requestParameters['lastName'] != null) {
            queryParameters['lastName'] = requestParameters['lastName'];
        }

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['categoryCode'] != null) {
            queryParameters['categoryCode'] = requestParameters['categoryCode'];
        }

        if (requestParameters['pickUpLocationCode'] != null) {
            queryParameters['pickUpLocationCode'] = requestParameters['pickUpLocationCode'];
        }

        if (requestParameters['dropOffLocationCode'] != null) {
            queryParameters['dropOffLocationCode'] = requestParameters['dropOffLocationCode'];
        }

        if (requestParameters['pickUpDateTime'] != null) {
            queryParameters['pickUpDateTime'] = (requestParameters['pickUpDateTime'] as any).toISOString();
        }

        if (requestParameters['dropOffDateTime'] != null) {
            queryParameters['dropOffDateTime'] = (requestParameters['dropOffDateTime'] as any).toISOString();
        }

        if (requestParameters['driverAge'] != null) {
            queryParameters['driverAge'] = requestParameters['driverAge'];
        }

        if (requestParameters['mobilePhone'] != null) {
            queryParameters['mobilePhone'] = requestParameters['mobilePhone'];
        }

        if (requestParameters['campaignCode'] != null) {
            queryParameters['campaignCode'] = requestParameters['campaignCode'];
        }

        if (requestParameters['flightNumber'] != null) {
            queryParameters['flightNumber'] = requestParameters['flightNumber'];
        }

        if (requestParameters['languageCode'] != null) {
            queryParameters['languageCode'] = requestParameters['languageCode'];
        }

        if (requestParameters['marketingAllowed'] != null) {
            queryParameters['marketingAllowed'] = requestParameters['marketingAllowed'];
        }

        if (requestParameters['mergeSurchargesFees'] != null) {
            queryParameters['mergeSurchargesFees'] = requestParameters['mergeSurchargesFees'];
        }

        if (requestParameters['agentName'] != null) {
            queryParameters['agentName'] = requestParameters['agentName'];
        }

        if (requestParameters['numberOfPeople'] != null) {
            queryParameters['numberOfPeople'] = requestParameters['numberOfPeople'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/Account/{accountKey}/2.0/CreateQuote`.replace(
                    `{${'accountKey'}}`,
                    encodeURIComponent(String(requestParameters['accountKey']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateQuoteResponseFromJSON(jsonValue));
    }

    /**
     * Creates a quote for the given fleet category, dates and locations. Replies with additional items and products that can be added to the quote
     * Create a quote
     */
    async createQuote(
        accountKey: string,
        firstName: string,
        lastName: string,
        email: string,
        categoryCode: string,
        pickUpLocationCode: string,
        dropOffLocationCode: string,
        pickUpDateTime: Date,
        dropOffDateTime: Date,
        driverAge?: string,
        mobilePhone?: string,
        campaignCode?: string,
        flightNumber?: string,
        languageCode?: string,
        marketingAllowed?: boolean,
        mergeSurchargesFees?: CreateQuoteMergeSurchargesFeesEnum,
        agentName?: string,
        numberOfPeople?: number,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<CreateQuoteResponse> {
        const response = await this.createQuoteRaw(
            {
                accountKey: accountKey,
                firstName: firstName,
                lastName: lastName,
                email: email,
                categoryCode: categoryCode,
                pickUpLocationCode: pickUpLocationCode,
                dropOffLocationCode: dropOffLocationCode,
                pickUpDateTime: pickUpDateTime,
                dropOffDateTime: dropOffDateTime,
                driverAge: driverAge,
                mobilePhone: mobilePhone,
                campaignCode: campaignCode,
                flightNumber: flightNumber,
                languageCode: languageCode,
                marketingAllowed: marketingAllowed,
                mergeSurchargesFees: mergeSurchargesFees,
                agentName: agentName,
                numberOfPeople: numberOfPeople,
            },
            initOverrides
        );
        return await response.value();
    }

    /**
     * Get reservation by ref
     */
    async getReservationByRefRaw(
        requestParameters: GetReservationByRefRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<QuoteWithProductOptions>> {
        if (requestParameters['accountId'] == null) {
            throw new runtime.RequiredError('accountId', 'Required parameter "accountId" was null or undefined when calling getReservationByRef().');
        }

        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling getReservationByRef().');
        }

        const queryParameters: any = {};

        if (requestParameters['accountId'] != null) {
            queryParameters['accountId'] = requestParameters['accountId'];
        }

        if (requestParameters['lastName'] != null) {
            queryParameters['lastName'] = requestParameters['lastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/rental-reservations/ref/{ref}`.replace(`{${'ref'}}`, encodeURIComponent(String(requestParameters['ref']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => QuoteWithProductOptionsFromJSON(jsonValue));
    }

    /**
     * Get reservation by ref
     */
    async getReservationByRef(
        accountId: string,
        ref: string,
        lastName?: string,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<QuoteWithProductOptions> {
        const response = await this.getReservationByRefRaw({ accountId: accountId, ref: ref, lastName: lastName }, initOverrides);
        return await response.value();
    }

    /**
     * Get pricing for adding requested secondary products to a reservaton
     * Get reservation pricing
     */
    async getReservationPricingByRefRaw(
        requestParameters: GetReservationPricingByRefRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<ReservationPricingEnquiryResponse>> {
        if (requestParameters['accountId'] == null) {
            throw new runtime.RequiredError(
                'accountId',
                'Required parameter "accountId" was null or undefined when calling getReservationPricingByRef().'
            );
        }

        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling getReservationPricingByRef().');
        }

        const queryParameters: any = {};

        if (requestParameters['accountId'] != null) {
            queryParameters['accountId'] = requestParameters['accountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/rental-reservations/ref/{ref}/pricing-enquiries`.replace(
                    `{${'ref'}}`,
                    encodeURIComponent(String(requestParameters['ref']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['secondaryProductMap']!.map(SecondaryProductMapToJSON),
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationPricingEnquiryResponseFromJSON(jsonValue));
    }

    /**
     * Get pricing for adding requested secondary products to a reservaton
     * Get reservation pricing
     */
    async getReservationPricingByRef(
        accountId: string,
        ref: string,
        secondaryProductMap?: Array<SecondaryProductMap>,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<ReservationPricingEnquiryResponse> {
        const response = await this.getReservationPricingByRefRaw(
            { accountId: accountId, ref: ref, secondaryProductMap: secondaryProductMap },
            initOverrides
        );
        return await response.value();
    }

    /**
     * List reservations
     */
    async listReservationsRaw(
        requestParameters: ListReservationsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<ListReservationsResponse>> {
        if (requestParameters['accountId'] == null) {
            throw new runtime.RequiredError('accountId', 'Required parameter "accountId" was null or undefined when calling listReservations().');
        }

        const queryParameters: any = {};

        if (requestParameters['accountId'] != null) {
            queryParameters['accountId'] = requestParameters['accountId'];
        }

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['itemsPerPage'] != null) {
            queryParameters['itemsPerPage'] = requestParameters['itemsPerPage'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/rental-reservations`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => ListReservationsResponseFromJSON(jsonValue));
    }

    /**
     * List reservations
     */
    async listReservations(
        accountId: string,
        query?: string,
        page?: number,
        itemsPerPage?: number,
        sort?: string,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<ListReservationsResponse> {
        const response = await this.listReservationsRaw(
            { accountId: accountId, query: query, page: page, itemsPerPage: itemsPerPage, sort: sort },
            initOverrides
        );
        return await response.value();
    }

    /**
     * Adding optional extras and insurance to a quote.
     * Add additional products to a quote
     */
    async updateQuoteRaw(
        requestParameters: UpdateQuoteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<UpdateQuoteResponse>> {
        if (requestParameters['accountKey'] == null) {
            throw new runtime.RequiredError('accountKey', 'Required parameter "accountKey" was null or undefined when calling updateQuote().');
        }

        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError('quoteId', 'Required parameter "quoteId" was null or undefined when calling updateQuote().');
        }

        if (requestParameters['secondaryProductsAndInsuranceMap'] == null) {
            throw new runtime.RequiredError(
                'secondaryProductsAndInsuranceMap',
                'Required parameter "secondaryProductsAndInsuranceMap" was null or undefined when calling updateQuote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        if (requestParameters['secondaryProductsAndInsuranceMap'] != null) {
            queryParameters['secondaryProductsAndInsuranceMap'] = requestParameters['secondaryProductsAndInsuranceMap'];
        }

        if (requestParameters['agentName'] != null) {
            queryParameters['agentName'] = requestParameters['agentName'];
        }

        if (requestParameters['languageCode'] != null) {
            queryParameters['languageCode'] = requestParameters['languageCode'];
        }

        if (requestParameters['numberOfPeople'] != null) {
            queryParameters['numberOfPeople'] = requestParameters['numberOfPeople'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/Account/{accountKey}/2.0/UpdateQuote`.replace(
                    `{${'accountKey'}}`,
                    encodeURIComponent(String(requestParameters['accountKey']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateQuoteResponseFromJSON(jsonValue));
    }

    /**
     * Adding optional extras and insurance to a quote.
     * Add additional products to a quote
     */
    async updateQuote(
        accountKey: string,
        quoteId: string,
        secondaryProductsAndInsuranceMap: string,
        agentName?: string,
        languageCode?: string,
        numberOfPeople?: number,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<UpdateQuoteResponse> {
        const response = await this.updateQuoteRaw(
            {
                accountKey: accountKey,
                quoteId: quoteId,
                secondaryProductsAndInsuranceMap: secondaryProductsAndInsuranceMap,
                agentName: agentName,
                languageCode: languageCode,
                numberOfPeople: numberOfPeople,
            },
            initOverrides
        );
        return await response.value();
    }
}

/**
 * @export
 */
export const CreateQuoteMergeSurchargesFeesEnum = {
    True: 'true',
    False: 'false',
} as const;
export type CreateQuoteMergeSurchargesFeesEnum = (typeof CreateQuoteMergeSurchargesFeesEnum)[keyof typeof CreateQuoteMergeSurchargesFeesEnum];
