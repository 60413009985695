/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { OfficeTime } from './OfficeTime';
import { OfficeTimeFromJSON, OfficeTimeFromJSONTyped, OfficeTimeToJSON } from './OfficeTime';

/**
 * Opening and Closing times for the give fleet type at the given
 * location
 * @export
 * @interface SiteServiceHours
 */
export interface SiteServiceHours {
    /**
     *
     * @type {OfficeTime}
     * @memberof SiteServiceHours
     */
    monday?: OfficeTime;
    /**
     *
     * @type {OfficeTime}
     * @memberof SiteServiceHours
     */
    tuesday?: OfficeTime;
    /**
     *
     * @type {OfficeTime}
     * @memberof SiteServiceHours
     */
    wednesday?: OfficeTime;
    /**
     *
     * @type {OfficeTime}
     * @memberof SiteServiceHours
     */
    thursday?: OfficeTime;
    /**
     *
     * @type {OfficeTime}
     * @memberof SiteServiceHours
     */
    friday?: OfficeTime;
    /**
     *
     * @type {OfficeTime}
     * @memberof SiteServiceHours
     */
    saturday?: OfficeTime;
    /**
     *
     * @type {OfficeTime}
     * @memberof SiteServiceHours
     */
    sunday?: OfficeTime;
}

/**
 * Check if a given object implements the SiteServiceHours interface.
 */
export function instanceOfSiteServiceHours(value: object): value is SiteServiceHours {
    return true;
}

export function SiteServiceHoursFromJSON(json: any): SiteServiceHours {
    return SiteServiceHoursFromJSONTyped(json, false);
}

export function SiteServiceHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteServiceHours {
    if (json == null) {
        return json;
    }
    return {
        monday: json['Monday'] == null ? undefined : OfficeTimeFromJSON(json['Monday']),
        tuesday: json['Tuesday'] == null ? undefined : OfficeTimeFromJSON(json['Tuesday']),
        wednesday: json['Wednesday'] == null ? undefined : OfficeTimeFromJSON(json['Wednesday']),
        thursday: json['Thursday'] == null ? undefined : OfficeTimeFromJSON(json['Thursday']),
        friday: json['Friday'] == null ? undefined : OfficeTimeFromJSON(json['Friday']),
        saturday: json['Saturday'] == null ? undefined : OfficeTimeFromJSON(json['Saturday']),
        sunday: json['Sunday'] == null ? undefined : OfficeTimeFromJSON(json['Sunday']),
    };
}

export function SiteServiceHoursToJSON(value?: SiteServiceHours | null): any {
    if (value == null) {
        return value;
    }
    return {
        Monday: OfficeTimeToJSON(value['monday']),
        Tuesday: OfficeTimeToJSON(value['tuesday']),
        Wednesday: OfficeTimeToJSON(value['wednesday']),
        Thursday: OfficeTimeToJSON(value['thursday']),
        Friday: OfficeTimeToJSON(value['friday']),
        Saturday: OfficeTimeToJSON(value['saturday']),
        Sunday: OfficeTimeToJSON(value['sunday']),
    };
}
