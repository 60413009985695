import LanierApi, { Configuration, ConfigurationParameters, InitOverrideFunction, RequestOpts } from '@jucy/rentals-api-client';
import config from '../config';
import { isFleetTypeEnabled } from '../lib/isFleetTypeEnabled';
import { cache } from './cache';
import { CurrencyRates, FleetType, JucyRentalsApiStatus, ListDealsResponse, Region, SearchFormData, Site, SiteSettingResponse } from './models';

export interface JucyRentalsApiClientConfigParameters extends ConfigurationParameters {
    countryCode: string;
}

export class JucyRentalsApiClientConfig extends Configuration {
    constructor(configuration?: JucyRentalsApiClientConfigParameters) {
        super(configuration);
        this._countryCode = configuration?.countryCode || 'nz';
    }

    private _countryCode: string;

    get countryCode() {
        return this._countryCode;
    }

    set countryCode(countryCode: string) {
        this._countryCode = countryCode;
    }
}

export class JucyRentalsApiClient extends LanierApi {
    cache: Record<string, unknown> = {};
    config: JucyRentalsApiClientConfig;

    constructor(config: JucyRentalsApiClientConfigParameters) {
        super(config);
        this.config = new JucyRentalsApiClientConfig(config);
    }

    async status(): Promise<JucyRentalsApiStatus> {
        return this.config?.fetchApi?.(new URL('/api/status', this.config.basePath).toString()).then((r) => r.json());
    }

    async fetchSites(countryCode: string): Promise<Site[]> {
        return cache.get(`${countryCode}-sites`, () => this.sites.getSites(countryCode).then((r) => r?.data || [])).then((r) => r?.map((s) => Site.fromPlain(s as unknown as Site)) || []);
    }

    async fetchSiteSettings(): Promise<SiteSettingResponse> {
        return cache
            .get('site-settings', () => this.sites.getRentalSitesSettings())
            .then((r) =>
                SiteSettingResponse.fromPlain(
                    (r || {
                        hirePeriods: [],
                        sites: [],
                    }) as unknown as SiteSettingResponse
                )
            );
    }

    async fetchFleetTypes(): Promise<FleetType[]> {
        return this.availability
            .getAllFleetTypes()
            .then((r) => r?.data || [])
            .then((r) => r.map((apiFleetType) => FleetType.fromPlain(apiFleetType as FleetType)))
            .then((r) => r.filter(isFleetTypeEnabled).sort((a, b) => a.sortOrder - b.sortOrder));
    }

    async getSites(): Promise<Site[]> {
        return this.fetchSearchFormData().then((r) => r.regions?.reduce((result, sites) => [...result, ...sites.sites], [] as Site[]) || []);
    }

    async getSite(siteCode: string): Promise<Site | undefined> {
        return this.getSites().then((sites) => sites.find((s) => s.siteCode === siteCode));
    }

    async getFleetType(slug: string): Promise<FleetType | undefined> {
        return this.fetchFleetTypes().then((fleetTypes) => fleetTypes.find((fleetType) => fleetType.slug === slug));
    }

    async fetchSearchFormData(): Promise<SearchFormData> {
        try {
            if (!this.cache['fetchSearchFormData']) {
                this.cache['fetchSearchFormData'] = await cache
                    .get('search-form-data', async () => {
                        const apiStatus = await this.status();
                        const requests: [Promise<FleetType[]>, Promise<SiteSettingResponse>, Promise<Site[]>, Promise<Site[]>] = [
                            this.fetchFleetTypes(),
                            this.fetchSiteSettings(),
                            Promise.resolve([] as Site[]),
                            Promise.resolve([] as Site[]),
                        ];
                        if (apiStatus.obe?.au) {
                            requests[2] = this.fetchSites('au');
                        }
                        if (apiStatus.obe?.nz) {
                            requests[3] = this.fetchSites('nz');
                        }

                        const [fleetTypes, siteSettings, auSites, nzSites] = await Promise.all(requests);
                        const regions: Region[] = [
                            {
                                country: 'New Zealand',
                                countryCode: 'nz',
                                sites: nzSites,
                            },
                            {
                                country: 'Australia',
                                countryCode: 'au',
                                sites: auSites,
                            },
                        ];
                        return {
                            regions: regions
                                .map((r) => ({
                                    ...r,
                                    sites: r.sites.map((site: Site) => {
                                        site.settings = siteSettings.sites.find((siteSettings) => siteSettings.siteCode === site.siteCode);
                                        return site;
                                    }),
                                }))
                                .sort((a) => (a.countryCode === this.config.countryCode ? -1 : 0)),
                            fleetTypes: fleetTypes,
                            hirePeriods: siteSettings.hirePeriods,
                            apiStatus,
                        };
                    })
                    .then((d) => SearchFormData.fromPlain(d ? d : { apiStatus: { obe: { au: false, nz: false } } }))
                    .catch((err) => {
                        console.error(err);
                        return SearchFormData.fromPlain({ apiStatus: { obe: { au: false, nz: false } } });
                    });
            }
            return this.cache['fetchSearchFormData'] as SearchFormData;
        } catch (e) {
            return { apiStatus: { obe: { au: false, nz: false } } };
        }
    }

    async getDeals(accountId: string, tags?: Set<string>, region?: string, code?: string, type?: string): Promise<ListDealsResponse> {
        return (await this.deals.listDeals(accountId, tags && (Array.from(tags) as unknown as Set<string>), region, code, type)) as Promise<ListDealsResponse>;
    }

    async getCurrencyRates(): Promise<CurrencyRates> {
        if (!this.cache['getCurrencyRates']) {
            this.cache['getCurrencyRates'] = await cache.get('getCurrencyRates', async () =>
                this.reservations.fetchApi(new URL('/api/v3/data/get-currency-rate', this.config.basePath).toString(), {}).then((r) => r.json())
            );
        }
        return this.cache['getCurrencyRates'] as CurrencyRates;
    }

    async get<T>(context: Partial<RequestOpts> & { path: string }, options?: RequestInit | InitOverrideFunction): Promise<T> {
        const response = await this.reservations.request(
            {
                ...context,
                method: 'GET',
                headers: context.headers || {},
            },
            options
        );
        return response.json();
    }
}

const jucyRentalsApiClient = new JucyRentalsApiClient({
    basePath: config.apiBaseUrl?.replace(/\/$/, '') || '',
    countryCode: 'nz',
});
export default jucyRentalsApiClient;
