import { getFleetTypes } from '../api';
import { FleetCategory, FleetType, V2FleetCategory } from '../models';

export const mapLegacyFleetCategory = async (legacyFleetCategory: V2FleetCategory): Promise<FleetCategory> => {
    const fleetTypes = await getFleetTypes();
    const fleetType = fleetTypes.find((fleetType) => fleetType.id === legacyFleetCategory.type) as FleetType;

    return {
        name: legacyFleetCategory.name,
        description: legacyFleetCategory.description,
        code: legacyFleetCategory.categoryCode,
        categoryCode: legacyFleetCategory.categoryCode,
        campaignCode: legacyFleetCategory.campaignCode,
        campaignId: legacyFleetCategory.campaignId,
        id: legacyFleetCategory.id,
        type: legacyFleetCategory.type,
        rentalDays: legacyFleetCategory.rentalDays,
        dealText: legacyFleetCategory.dealText,
        fleetTypeCode: fleetType.slug,
        availabilityMessage: '',
        dailyRate: legacyFleetCategory.dailyRate,
        total: legacyFleetCategory.total,
        discountPercentageApplied: legacyFleetCategory.discountPercentageApplied,
        mandatoryFees: legacyFleetCategory.mandatoryFees ? (legacyFleetCategory.mandatoryFees as FleetCategory['mandatoryFees']) : undefined,
        minimumHirePeriod: legacyFleetCategory.minimumHirePeriod ? Number(legacyFleetCategory.minimumHirePeriod) : undefined,
        isAfterHourPickUp: legacyFleetCategory.isAfterHourPickUp,
        availability: legacyFleetCategory.availability,
    };
};
