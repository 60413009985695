import { Breakpoint, ThemeAppBarOptions, ThemeFooterBarOptions, ThemeOptions } from '@mui/material';
import { ThemeColorOptions, ThemeGeneralOptions, ThemeSideBarOptions } from '@mui/material/styles';
import { mapBreakPoints } from '../utils/mapBreakPoints';

const appBarSettingsBorderTop = 7;
const appBarSettingsHeights: Record<Breakpoint, number> = {
    xs: 59,
    sm: 67,
    md: 75,
    lg: 75,
    xl: 75,
    xxl: 75,
};
export const defaultAppBarSettings: ThemeAppBarOptions = {
    borderTop: appBarSettingsBorderTop,
    heights: appBarSettingsHeights,
    spacer: (padding = 8) => mapBreakPoints(defaultAppBarSettings.heights, (height) => `${height + padding}px`),
} as unknown as ThemeAppBarOptions;
export const defaultFooterBarOptions: ThemeFooterBarOptions = {
    height: 55,
};

export const defaultSideBarOptions: Pick<ThemeSideBarOptions, 'width'> = {
    width: 290,
};

export const defaultGeneralSettings: ThemeGeneralOptions = {
    reactFrameworkColor: '#00D8FF',
    borderRadiusSm: '6px',
    borderRadius: '10px',
    borderRadiusLg: '12px',
    borderRadiusXl: '16px',
    outlinedInputBorderColor: '#c4c4c4',
    outlinedInputBorderColorHover: '#9099a9',
};

export const createDefaultThemeSettings = (colors: ThemeColorOptions): ThemeOptions['settings'] => ({
    appBar: {
        ...defaultAppBarSettings,
        background: colors.alpha.white[100],
        boxShadow: colors.shadows.cardSm,
        textColor: colors.secondary.main,
    },
    sidebar: {
        ...defaultSideBarOptions,
        background: colors.layout.sidebar.background,
        textColor: colors.layout.sidebar.textColor,
        dividerBg: colors.layout.sidebar.dividerBg,
        menuItemColor: colors.layout.sidebar.menuItemColor,
        menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
        menuItemBg: colors.layout.sidebar.menuItemBg,
        menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
        menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
        menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
        menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
        boxShadow: '2px 0 3px rgba(159, 162, 191, .18), 1px 0 1px rgba(159, 162, 191, 0.32)',
    },
    general: defaultGeneralSettings,
    dark: typeof process !== 'undefined' && process.env.THEME_DARK === 'true',
    footer: defaultFooterBarOptions,
});
