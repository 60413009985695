/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { Deal, ListDealsResponse } from '../models/index';
import { DealFromJSON, DealToJSON, ListDealsResponseFromJSON, ListDealsResponseToJSON } from '../models/index';
import * as runtime from '../runtime';

export interface GetDealByIdRequest {
    accountId: string;
    id: string;
}

export interface ListDealsRequest {
    accountId: string;
    tags?: Set<string>;
    region?: string;
    code?: string;
    type?: string;
}

/**
 *
 */
export class DealsApi extends runtime.BaseAPI {
    /**
     * Get deal by id
     */
    async getDealByIdRaw(
        requestParameters: GetDealByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Deal>> {
        if (requestParameters['accountId'] == null) {
            throw new runtime.RequiredError('accountId', 'Required parameter "accountId" was null or undefined when calling getDealById().');
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getDealById().');
        }

        const queryParameters: any = {};

        if (requestParameters['accountId'] != null) {
            queryParameters['accountId'] = requestParameters['accountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/deals/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => DealFromJSON(jsonValue));
    }

    /**
     * Get deal by id
     */
    async getDealById(accountId: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Deal> {
        const response = await this.getDealByIdRaw({ accountId: accountId, id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Find active deals.
     * List deals
     */
    async listDealsRaw(
        requestParameters: ListDealsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<ListDealsResponse>> {
        if (requestParameters['accountId'] == null) {
            throw new runtime.RequiredError('accountId', 'Required parameter "accountId" was null or undefined when calling listDeals().');
        }

        const queryParameters: any = {};

        if (requestParameters['accountId'] != null) {
            queryParameters['accountId'] = requestParameters['accountId'];
        }

        if (requestParameters['tags'] != null) {
            queryParameters['tags'] = requestParameters['tags'];
        }

        if (requestParameters['region'] != null) {
            queryParameters['region'] = requestParameters['region'];
        }

        if (requestParameters['code'] != null) {
            queryParameters['code'] = requestParameters['code'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/deals`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDealsResponseFromJSON(jsonValue));
    }

    /**
     * Find active deals.
     * List deals
     */
    async listDeals(
        accountId: string,
        tags?: Set<string>,
        region?: string,
        code?: string,
        type?: string,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<ListDealsResponse> {
        const response = await this.listDealsRaw({ accountId: accountId, tags: tags, region: region, code: code, type: type }, initOverrides);
        return await response.value();
    }
}
