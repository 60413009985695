import Image from 'next/image';
import * as Afterpay from '../../../lib/Afterpay';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import AfterpayLightbox from '../../PaymentProviders/Afterpay/AfterpayLightbox';
import { PaymentOption } from '../lib';
import GetPaymentMethodFn from './GetPaymentMethodFn';

const getAfterpayPaymentOption: GetPaymentMethodFn = ({ summary, options }): PaymentOption => ({
    id: 'afterpay',
    name: <Image src={Afterpay.logo} alt="Afterpay logo" width={118} height={41} />,
    total: { value: summary.totalPrice?.value, currencyCode: summary.totalPrice?.currencyCode },
    subTotal: { value: summary.totalPrice?.value, currencyCode: summary.totalPrice?.currencyCode },
    amountInfo: <>4 weekly interest-free payments from</>,
    amount: <CurrencyFormatter price={Afterpay.weeklyPayments(summary.totalPrice)} prefixCurrencyCode={true} />,
    modalBody: <AfterpayLightbox countryCode={summary.pickUpLocation?.CountryCode} />,
    gateway: 'afterpay',
    isRedirect: true,
    available: ({ paymentConfig, summary }) => Afterpay.enabled(summary) && Afterpay.available(summary.totalPrice, paymentConfig),
    ...options,
});
export default getAfterpayPaymentOption;
