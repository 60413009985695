import { useJitsu as useJitsuOg } from '@jitsu/nextjs';
import { JitsuClient } from '@jitsu/sdk-js';
import { jitsuClient } from '../components/providers';

const asyncNoop = async () => undefined;
const jitsuMock: JitsuClient = {
    _send3p: asyncNoop,
    track: asyncNoop,
    rawTrack: asyncNoop,
    id: asyncNoop,
    init: asyncNoop,
    interceptAnalytics: asyncNoop,
    set: asyncNoop,
    unset: asyncNoop,
};

const useJitsuMock = (): ReturnType<typeof useJitsuOg> => ({
    ...jitsuMock,
    trackPageView: asyncNoop,
});
export const useJitsu = jitsuClient ? useJitsuOg : useJitsuMock;
