import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import JucyAPI from '../../../../lib/JucyAPI.js';
import AppStateStore from '../../../../store/AppStateStore';
import TranslationStore from '../../../../store/TranslationStore';
import { BookingCart } from '../../../../types/BookingCart';
import PhoneNumberInput from '../../../form/fields/PhoneNumberInput';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
const AGENT_EMAIL_WARNING_MESSAGE = "This is an agent's email address. The email address should be unique for each customer";

const validEmail = async (value: string | undefined) => {
    if (AppStateStore.mode !== 'agent') {
        return true;
    }

    if (!value || AppStateStore.user.email === value) {
        return false;
    }

    try {
        const response = await JucyAPI.isAgentEmail(value);
        return !response;
    } catch (error) {
        // If there is an error with the isAgentEmail request don't show
        // a validation error
        return true;
    }
};

const AgentEmailField = ({ isSubmitting }: { isSubmitting: boolean }) => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const [emailWarning, setEmailWarning] = useState<string | undefined>(undefined);
    return (
        <Field
            component={TextField}
            fullWidth
            size="small"
            label={t('hirer_details.group.email')}
            type="email"
            name="email"
            inputProps={{
                'data-cy': 'email',
            }}
            color={emailWarning ? 'warning' : undefined}
            helperText={
                emailWarning ?? (
                    <>
                        <WarningAmberIcon /> IMPORTANT: This MUST be the customer's email address. This is what will link the booking to the customer for their self check-in
                    </>
                )
            }
            disabled={isSubmitting}
            onBlur={async (e: React.FocusEvent<HTMLInputElement>) => {
                const isValidEmail = await validEmail(e.target.value);
                setEmailWarning(isValidEmail ? undefined : AGENT_EMAIL_WARNING_MESSAGE);
            }}
            sx={
                emailWarning
                    ? {
                          '& .MuiFormHelperText-root': {
                              color: 'warning.main',
                          },
                      }
                    : undefined
            }
        />
    );
};

export const CommonHirerDetailsFormFields: React.FC<{ summary: BookingCart; mode?: 'direct' | 'agent' }> = ({ summary, mode }) => {
    const { isSubmitting, initialValues } = useFormikContext<{ mobileNumber: string }>();
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const hasPhoneNumber = Boolean(initialValues.mobileNumber.replace(/\D/g, '').trim());
    return (
        <>
            <Grid item xs={12} md={6}>
                <Field
                    component={TextField}
                    fullWidth
                    size="small"
                    label={t('hirer_details.group.first_name')}
                    type="text"
                    name="firstName"
                    inputProps={{
                        'data-cy': 'first-name',
                    }}
                    disabled={isSubmitting}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Field
                    component={TextField}
                    fullWidth
                    size="small"
                    label={t('hirer_details.group.last_name')}
                    type="text"
                    name="lastName"
                    inputProps={{
                        'data-cy': 'last-name',
                    }}
                    disabled={isSubmitting}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {mode === 'agent' ? (
                    <AgentEmailField isSubmitting={isSubmitting} />
                ) : (
                    <Field
                        component={TextField}
                        fullWidth
                        size="small"
                        label={t('hirer_details.group.email')}
                        type="email"
                        name="email"
                        inputProps={{
                            'data-cy': 'email',
                        }}
                        disabled={isSubmitting}
                    />
                )}
            </Grid>
            <Grid item xs={12} md={6}>
                <PhoneNumberInput
                    label="Mobile Number"
                    size="medium"
                    name="mobileNumber"
                    inputProps={{
                        'data-cy': 'mobile-number',
                    }}
                    phoneInputProps={{
                        country: hasPhoneNumber ? undefined : summary?.pickUpLocation?.CountryCode || 'nz',
                    }}
                    placeholder="+64 21123456"
                    disabled={isSubmitting}
                />
            </Grid>
        </>
    );
};
