import { useEffect, useState } from 'react';
import ga from '../lib/ga';

const useGetGaTrackingId = (): string => {
    const [trackingId, setTrackingId] = useState('');
    useEffect(() => {
        let mounted = true;
        (async () => {
            const id = await ga.getLinkerParam();
            if (mounted && id) {
                setTrackingId(id);
            }
        })();
        return () => {
            mounted = false;
        };
    });
    return trackingId;
};

export default useGetGaTrackingId;
