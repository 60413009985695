import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, URLSearchParamsInit } from 'react-router-dom';
import { FleetTypeSlug } from '@jucy/rentals-common';
import { Stack } from '@mui/material';
import useIsMounted from '../../../hooks/useIsMounted';
import Product from '../../../lib/api/model/Product';
import ProductGroup from '../../../lib/api/model/ProductGroup';
import TranslationStore from '../../../store/TranslationStore';
import { JucyButton } from '../../Button/Button';
import EmailQuoteButton from '../../EmailQuote/EmailQuoteButton';
import { PageTitle } from '../../Typograpgy/Title';
import { ExtrasGroup } from './components';
import { ObeProductLine } from '#/types/ObeProductLine';

export interface ExtrasFormProps {
    className?: string;
    onChange: (item: Product, delta: number) => void | Promise<void>;
    extraItems: ProductGroup<Product>[];
    selectedItems: Product[];
    bundledItems?: ObeProductLine[];
    nextLink?: string;
    isSelecting?: boolean;
    readonly?: boolean;
    displayEmailQuote?: boolean;
    fleetTypeSlug?: FleetTypeSlug;
    onSelectRouteDetails?: {
        routeId?: string;
        params?: Record<string, string>;
        qs?: URLSearchParamsInit;
    };
    activeProductCode?: string;
}

const ExtrasForm: React.FC<ExtrasFormProps> = observer(
    ({ displayEmailQuote, activeProductCode, isSelecting: passedSelecting, selectedItems, bundledItems, nextLink, readonly, onChange, extraItems }) => {
        const [isSelecting, setIsSelecting] = useState(passedSelecting);
        const { t } = useTranslation(TranslationStore.currentNamespace);
        const isMounted = useIsMounted();
        const handleOnChange = useCallback(
            async (item: Product, delta: number) => {
                setIsSelecting(true);
                await onChange(item, delta);
                if (isMounted()) {
                    setIsSelecting(false);
                }
            },
            [isMounted, onChange]
        );

        useEffect(() => setIsSelecting(passedSelecting), [passedSelecting]);

        return (
            <div>
                <PageTitle sx={{ py: { xs: 1, md: 3 } }} data-cy="page-title">
                    {t('extras_page.group.add_extras')}
                </PageTitle>
                {extraItems
                    .filter((g) => g.Items.filter((i) => i.ProductCode).length)
                    .map((productGroup, i) => (
                        <ExtrasGroup
                            isOpen={i < 1}
                            isSelecting={isSelecting || passedSelecting}
                            readonly={readonly}
                            key={productGroup.WebCategory}
                            selectedItems={selectedItems}
                            activeCode={activeProductCode}
                            productGroup={productGroup}
                            onChange={handleOnChange}
                            bundledItems={bundledItems}
                        />
                    ))}

                <Stack mb={2} py={1} direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                    {displayEmailQuote ? <EmailQuoteButton data-cy="excess-reduction-email-quote-btn" /> : null}
                    {!readonly && nextLink ? (
                        <JucyButton sx={{ mr: 2 }} data-cy="extras-continue-btn" variant="contained" component={Link} disabled={isSelecting || passedSelecting} to={nextLink}>
                            {t('extras_page.group.continue')}
                        </JucyButton>
                    ) : null}
                </Stack>
            </div>
        );
    }
);
ExtrasForm.displayName = 'ExtrasForm';

export default ExtrasForm;
