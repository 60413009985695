import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link, URLSearchParamsInit } from 'react-router-dom';
import { RentalExcessReduction, RentalExcessReductionFromJSON } from '@jucy/rentals-api-client';
import { FleetTypeSlug, JucyCountryCode } from '@jucy/rentals-common';
import { Box, Grid, Stack } from '@mui/material';
import classNames from 'classnames';
import useIsMounted from '../../../hooks/useIsMounted';
import InsuranceProduct from '../../../lib/api/model/InsuranceProduct';
import AccountKeyStore from '../../../store/AccountKeyStore';
import AppStateStore from '../../../store/AppStateStore';
import TranslationStore from '../../../store/TranslationStore';
import UpsellExcessReductionModal from '../../Agent/UpsellExcessReductionModal';
import { JucyButton, JucyMuiTextLink } from '../../Button/Button';
import EmailQuoteButton from '../../EmailQuote/EmailQuoteButton';
import { PageTitle } from '../../Typograpgy/Title';
import { getExcessReductionSortOrder } from './ExcessMapper';
import ExcessReductionVerticalCard from './components/ExcessReductionVerticalCard';

export type ExcessReductionFormContextValues = {
    excessReductionOptions: InsuranceProduct[];
    onSelect: (code: string, suppressUpsell?: boolean) => void | Promise<void>;
    nextLink?: string;
    activeCode?: string;
    readonly?: boolean;
    countryCode?: JucyCountryCode;
    fleetTypeSlug: FleetTypeSlug;
    fleetCategoryCode: string;
    isSelecting: boolean;
    displayEmailQuote?: boolean;
    onSelectRouteDetails?: {
        routeId?: string;
        params?: Record<string, string>;
        qs?: URLSearchParamsInit;
    };
};

export const ExcessReductionFormContext = React.createContext<ExcessReductionFormContextValues | null>(null);

export const ExcessReductionFormContextProvider = ExcessReductionFormContext.Provider;

export const useExcessReductionFormContext = () => {
    const context = React.useContext(ExcessReductionFormContext);
    if (!context) {
        throw new Error('useExcessReductionFormContext must be used within a ExcessReductionFormContextProvider');
    }
    return context;
};
export interface ExcessReductionFormProps extends Omit<ExcessReductionFormContextValues, 'onSelect' | 'isSelecting'> {
    className?: string;
    onSelect?: (excessOption: InsuranceProduct) => void | Promise<void>;
}

const ExcessReductionForm: React.FC<ExcessReductionFormProps> = observer(
    ({ fleetTypeSlug, displayEmailQuote, countryCode, fleetCategoryCode, excessReductionOptions, nextLink, activeCode: passedActiveCode, readonly, onSelect, className }) => {
        const [activeCode, setActiveCode] = useState(passedActiveCode);
        const [isSelecting, setIsSelecting] = useState(false);
        const { t } = useTranslation(TranslationStore.currentNamespace);
        const bondTerms = `${fleetTypeSlug === 'motorhome' ? '\\*\\*' : '\\*'} ${t('excess_reduction_page.group.bond_terms')}`;
        const excessTerms = t('excess_reduction_page.group.excess_terms');
        const isMounted = useIsMounted();
        const handleOnSelect = useCallback(
            (code: string, suppressUpsell = false) => {
                (async () => {
                    if (code !== activeCode) {
                        if (code.startsWith('RT') && !suppressUpsell) {
                            //if agent selects RT then show the upsell popup
                            AppStateStore.toggleExcessReductionModal();
                        } else {
                            const selectedItem = excessReductionOptions.find((i) => i.ProductCode === code);
                            setActiveCode(code);
                            setIsSelecting(true);
                            if (selectedItem) {
                                await onSelect?.(selectedItem);
                            }
                            if (isMounted()) {
                                setIsSelecting(false);
                            }
                        }
                    }
                })();
            },
            [activeCode, excessReductionOptions, isMounted, onSelect]
        );

        return (
            <ExcessReductionFormContextProvider
                value={{ excessReductionOptions, isSelecting, nextLink, countryCode, activeCode, readonly, fleetTypeSlug, onSelect: handleOnSelect, displayEmailQuote, fleetCategoryCode }}
            >
                <div
                    className={classNames('excess-reduction-selector', className, {
                        loading: isSelecting,
                        'read-only': readonly,
                    })}
                >
                    <PageTitle sx={{ py: { xs: 1, md: 3 } }} data-cy="page-title">
                        Select Excess Reduction
                    </PageTitle>
                    <Grid container direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} sx={{ paddingTop: { md: 3.75 } }}>
                        {excessReductionOptions &&
                            excessReductionOptions
                                .filter((i) => i.ProductCode)
                                .map((i) => RentalExcessReductionFromJSON(i) as Required<RentalExcessReduction>)
                                .sort((a, b) => getExcessReductionSortOrder(b) - getExcessReductionSortOrder(a))
                                .map((item) => <ExcessReductionVerticalCard key={item.productCode} item={item} />)}
                    </Grid>
                    <Stack mb={1} mt={1} py={1} direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                        {displayEmailQuote ? <EmailQuoteButton data-cy="excess-reduction-email-quote-btn" /> : null}
                        {!readonly && nextLink ? (
                            <JucyButton sx={{ mr: 2 }} data-cy="excess-reduction-continue-btn" variant="contained" component={Link} disabled={isSelecting || !activeCode} to={nextLink}>
                                {t('extras_page.group.continue')}
                            </JucyButton>
                        ) : null}
                    </Stack>
                    {!AccountKeyStore.isAgent ? (
                        <>
                            {fleetTypeSlug === 'motorhome' && excessTerms ? (
                                <Box className="small" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                    <ReactMarkdown
                                        components={{
                                            a: (props) => (
                                                <JucyMuiTextLink href={props.href} sx={{ padding: 0 }} target="_blank" rel="noopener noreferrer" external={true}>
                                                    {props.children}
                                                </JucyMuiTextLink>
                                            ),
                                        }}
                                    >
                                        {excessTerms}
                                    </ReactMarkdown>
                                </Box>
                            ) : null}
                            {bondTerms ? (
                                <Box className="small" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                    <ReactMarkdown
                                        components={{
                                            a: (props) => (
                                                <JucyMuiTextLink href={props.href} sx={{ padding: 0 }} target="_blank" rel="noopener noreferrer" external={true}>
                                                    {props.children}
                                                </JucyMuiTextLink>
                                            ),
                                        }}
                                    >
                                        {bondTerms}
                                    </ReactMarkdown>
                                </Box>
                            ) : null}
                            <Box sx={{ pt: { xs: 1 }, textAlign: { xs: 'center', md: 'left' } }}>
                                <ReactMarkdown
                                    components={{
                                        a: (props) => (
                                            <JucyMuiTextLink href={props.href} sx={{ padding: 0 }} target="_blank" rel="noopener noreferrer" external={true}>
                                                {props.children}
                                            </JucyMuiTextLink>
                                        ),
                                    }}
                                >
                                    {t('excess_reduction_page.group.terms_text_1')}
                                </ReactMarkdown>
                            </Box>
                        </>
                    ) : null}

                    <UpsellExcessReductionModal setSelectedItem={handleOnSelect} excessReductionOptions={excessReductionOptions} />
                </div>
            </ExcessReductionFormContextProvider>
        );
    }
);
ExcessReductionForm.displayName = 'ExcessReductionForm';

export default ExcessReductionForm;
