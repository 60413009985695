/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { V2Money } from './V2Money';
import { V2MoneyFromJSON, V2MoneyFromJSONTyped, V2MoneyToJSON } from './V2Money';

/**
 *
 * @export
 * @interface PricingResponseProductPrices
 */
export interface PricingResponseProductPrices {
    /**
     * Product unique identifier
     * @type {string}
     * @memberof PricingResponseProductPrices
     */
    id?: string;
    /**
     * Product unique short code
     * @type {string}
     * @memberof PricingResponseProductPrices
     */
    code?: string;
    /**
     * Product display name
     * @type {string}
     * @memberof PricingResponseProductPrices
     */
    name?: string;
    /**
     * Product category code
     * @type {string}
     * @memberof PricingResponseProductPrices
     */
    category?: string;
    /**
     * Indicates if this item is mandatory
     * @type {boolean}
     * @memberof PricingResponseProductPrices
     */
    mandatory?: boolean;
    /**
     * Product long description
     * @type {string}
     * @memberof PricingResponseProductPrices
     */
    description?: string;
    /**
     *
     * @type {V2Money}
     * @memberof PricingResponseProductPrices
     */
    price?: V2Money;
    /**
     * Charge type for product<ul><li>Fixed - Single price per product
     * for duration of hire</li><li>Daily - Total price will be unit price *
     * quantity * hire days</li></ul>
     * @type {string}
     * @memberof PricingResponseProductPrices
     */
    type?: PricingResponseProductPricesTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof PricingResponseProductPrices
     */
    allowMultiple?: boolean;
    /**
     *
     * @type {number}
     * @memberof PricingResponseProductPrices
     */
    rentalDays?: number;
    /**
     *
     * @type {number}
     * @memberof PricingResponseProductPrices
     */
    numberOfDaysCalculated?: number;
    /**
     *
     * @type {V2Money}
     * @memberof PricingResponseProductPrices
     */
    maxPrice?: V2Money;
    /**
     *
     * @type {V2Money}
     * @memberof PricingResponseProductPrices
     */
    total?: V2Money;
    /**
     *
     * @type {boolean}
     * @memberof PricingResponseProductPrices
     */
    isExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof PricingResponseProductPrices
     */
    sortOrder?: number;
}

/**
 * @export
 */
export const PricingResponseProductPricesTypeEnum = {
    Fixed: 'fixed',
    Daily: 'daily',
    Percentage: 'percentage',
} as const;
export type PricingResponseProductPricesTypeEnum = (typeof PricingResponseProductPricesTypeEnum)[keyof typeof PricingResponseProductPricesTypeEnum];

/**
 * Check if a given object implements the PricingResponseProductPrices interface.
 */
export function instanceOfPricingResponseProductPrices(value: object): value is PricingResponseProductPrices {
    return true;
}

export function PricingResponseProductPricesFromJSON(json: any): PricingResponseProductPrices {
    return PricingResponseProductPricesFromJSONTyped(json, false);
}

export function PricingResponseProductPricesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingResponseProductPrices {
    if (json == null) {
        return json;
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        code: json['code'] == null ? undefined : json['code'],
        name: json['name'] == null ? undefined : json['name'],
        category: json['category'] == null ? undefined : json['category'],
        mandatory: json['mandatory'] == null ? undefined : json['mandatory'],
        description: json['description'] == null ? undefined : json['description'],
        price: json['price'] == null ? undefined : V2MoneyFromJSON(json['price']),
        type: json['type'] == null ? undefined : json['type'],
        allowMultiple: json['allowMultiple'] == null ? undefined : json['allowMultiple'],
        rentalDays: json['rentalDays'] == null ? undefined : json['rentalDays'],
        numberOfDaysCalculated: json['numberOfDaysCalculated'] == null ? undefined : json['numberOfDaysCalculated'],
        maxPrice: json['maxPrice'] == null ? undefined : V2MoneyFromJSON(json['maxPrice']),
        total: json['total'] == null ? undefined : V2MoneyFromJSON(json['total']),
        isExcluded: json['isExcluded'] == null ? undefined : json['isExcluded'],
        sortOrder: json['sortOrder'] == null ? undefined : json['sortOrder'],
    };
}

export function PricingResponseProductPricesToJSON(value?: PricingResponseProductPrices | null): any {
    if (value == null) {
        return value;
    }
    return {
        id: value['id'],
        code: value['code'],
        name: value['name'],
        category: value['category'],
        mandatory: value['mandatory'],
        description: value['description'],
        price: V2MoneyToJSON(value['price']),
        type: value['type'],
        allowMultiple: value['allowMultiple'],
        rentalDays: value['rentalDays'],
        numberOfDaysCalculated: value['numberOfDaysCalculated'],
        maxPrice: V2MoneyToJSON(value['maxPrice']),
        total: V2MoneyToJSON(value['total']),
        isExcluded: value['isExcluded'],
        sortOrder: value['sortOrder'],
    };
}
