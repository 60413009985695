'use client';
import React from 'react';
import { styled } from '@mui/material';

const CountryFlagOrCodeRoot = styled('span', {
    name: 'JucyCountryFlagOrCode',
    slot: 'Root',
})({
    fontSize: 18,
});
const getCountyFlagEmoji = (code: string) =>
    typeof String.fromCodePoint !== 'undefined'
        ? code.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : undefined;

export interface CountryFlagOrCodeProps extends React.ComponentProps<typeof CountryFlagOrCodeRoot> {
    code: string;
}

export const CountryFlagOrCode: React.FC<CountryFlagOrCodeProps> = ({ code, ...props }) => {
    const emoji = getCountyFlagEmoji(code);
    return <CountryFlagOrCodeRoot {...props}>{emoji ? emoji : code}</CountryFlagOrCodeRoot>;
};
