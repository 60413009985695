import { useAccountInfo } from './useAccountInfo';
import useGetCMSPage, { UseGetCMSPageOptions, UseGetCMSPageResponse } from './useGetCMSPage';

export interface UseGetAgentCMSContentOptions extends UseGetCMSPageOptions {
    accountKey: string;
}

const useGetAgentCMSPage = ({ accountKey, path, ...options }: UseGetAgentCMSContentOptions): UseGetCMSPageResponse => {
    const enabled = options?.enabled !== undefined ? options.enabled : true;
    const agentData = useAccountInfo(accountKey);
    const rateType = agentData?.accountInfo?.settings?.au?.rateType;

    return useGetCMSPage({
        path: `/partner/${rateType}/${path}`,
        ...options,
        enabled: Boolean(enabled && agentData.accountInfo),
    });
};

export default useGetAgentCMSPage;
