import React, { ReactNode, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import useIsMounted from '../../../../hooks/useIsMounted';
import FleetCategoryAvailability from '../../../../lib/api/model/FleetCategoryAvailability';
import FleetCategoryTeaserPlaceHolder from '../Teaser/components/TeaserPlaceHolder';
import AvailabilityGridRows from './AvailabilityGridRows';
import { FleetTypeSlug } from '@jucy/rentals-common';

const PlaceHolder: React.FC<Partial<AvailabilityGridProps>> = ({ title, caption }) => (
    <Box mt={2}>
        {title ? (
            <>
                <div className="fw-bold h6">{title}</div>
                <hr />
            </>
        ) : null}
        {caption ? <Typography mb={2}>&nbsp;</Typography> : null}
        <FleetCategoryTeaserPlaceHolder />
        <FleetCategoryTeaserPlaceHolder />
        <FleetCategoryTeaserPlaceHolder />
        <FleetCategoryTeaserPlaceHolder />
    </Box>
);

export interface AvailabilityGridProps {
    className?: string;
    onSelect?: (fleetCategory: FleetCategoryAvailability) => void | Promise<void>;
    fleetCategories: FleetCategoryAvailability[];
    loading?: boolean;
    highlightedCode?: string;
    activeCode?: string;
    title?: ReactNode;
    caption?: ReactNode;
    moreOptionsTitle?: ReactNode;
    moreOptionsCaption?: ReactNode;
    action?: 'create' | 'edit';
}

const AvailabilityGrid = React.forwardRef<HTMLDivElement, AvailabilityGridProps>(
    ({ fleetCategories, onSelect, activeCode: passedActiveCode, loading, highlightedCode, title, caption, moreOptionsTitle, moreOptionsCaption, action }, ref) => {
        const [activeCode, setActiveCode] = useState(passedActiveCode);
        const [isSelecting, setIsSelecting] = useState(false);
        const fleetType = fleetCategories?.[0]?.fleetType?.slug;
        const isMounted = useIsMounted();
        const handleOnSelect = useCallback(
            (fleetCategory: FleetCategoryAvailability) => {
                setActiveCode(fleetCategory?.CategoryCode);
                setIsSelecting(true);
                (async () => {
                    await onSelect?.(fleetCategory);
                    if (isMounted()) {
                        setIsSelecting(false);
                    }
                })();
            },
            [isMounted, onSelect]
        );
        if (loading) {
            return <PlaceHolder title={title} caption={caption} />;
        }

        return (
            <div data-cy="availability-grid" ref={ref}>
                <AvailabilityGridRows
                    availableFleetCategories={fleetCategories}
                    highlightedCode={highlightedCode}
                    activeCode={activeCode}
                    selecting={isSelecting}
                    onSelect={handleOnSelect}
                    title={title}
                    caption={caption}
                    moreOptionsTitle={moreOptionsTitle}
                    moreOptionsCaption={moreOptionsCaption}
                    action={action}
                />
                {fleetType === FleetTypeSlug.motorhome ? (
                    <>
                        <Typography variant="caption" color="text.secondary" component="small" sx={{ display: 'block' }}>
                            <sup>1</sup> Chassis / Vehicle type allocated subject to availability upon pick-up
                        </Typography>
                        <Typography variant="caption" color="text.secondary" component="small" sx={{ display: 'block' }}>
                            <sup>2</sup> Vehicle decals vary
                        </Typography>
                    </>
                ) : null}
            </div>
        );
    }
);
AvailabilityGrid.displayName = 'AvailabilityGrid';
export default AvailabilityGrid;
