import { ProductLine } from '@jucy/rentals-api-client/rentals-api-v3';

export const getLineValue = (line: ProductLine, bookingTotal = 0, rateTotal = 0): number => {
    let itemValue;
    if (line.productType == 'Daily') {
        itemValue = line.rentalDays * line.price.value;
    } else if (line.productType == 'Percentage') {
        if (line.isPercentageOnTotalCost) {
            itemValue = bookingTotal * (line.price.value / 100);
        } else {
            itemValue = rateTotal * (line.price.value / 100);
        }
    } else {
        itemValue = line.price.value;
    }

    return line.maxPrice?.value && line.maxPrice?.value > 0 ? Math.min(itemValue, line.maxPrice.value) : itemValue;
};

export const getTotalLineValue = (line: ProductLine, bookingTotal = 0, rateTotal = 0): number => {
    const itemValue = getLineValue(line, bookingTotal, rateTotal);
    return itemValue * line.qty;
};
