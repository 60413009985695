import { AlternateDatesSearchRequest, AvailabilitySearchResponse, FleetTypeCode } from '@jucy/rentals-api-client/rentals-api-v3';
import { addDays, differenceInDays, formatISO9075, isSameDay } from 'date-fns';
import { makeAutoObservable, reaction } from 'mobx';
import PQueue from 'p-queue';
import ErrorReporter from '../lib/ErrorReporter';
import { UserMode } from '../types/UserMode';
import accountKeyStore from './AccountKeyStore';
import type { GetAvailabilityRequest } from './ReverseTripStore';

const generateDateRange = ({ from, to }: { from: Date; to: Date }) => {
    const result = [];
    let current = from;
    while (current <= to) {
        result.push(current);
        current = addDays(current, 1);
    }
    return result;
};

const sortResults = (results: AvailabilitySearchResult[]) =>
    results
        .reduce((acc, curr) => {
            if (acc.some((a) => isSameDay(a.pickUpDate, curr.pickUpDate))) {
                return acc;
            }
            return [...acc, curr];
        }, [] as AvailabilitySearchResult[])
        .sort((a, b) => a.pickUpDate.getTime() - b.pickUpDate.getTime());

export interface AvailabilitySearchResult extends AvailabilitySearchResponse {
    placeholder?: boolean;
}

class AlternateDatesStore {
    trip: Partial<GetAvailabilityRequest> = {};
    results: AvailabilitySearchResult[] = [];
    userMode: UserMode = 'direct';
    state: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    message = '';
    error?: Error = undefined;
    queueState: { date: Date; direction: 'next' | 'prev'; loaded?: boolean }[] = [];
    queue: PQueue = new PQueue({ concurrency: 2 });

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.state,
            (data) => {
                if (data === 'error') {
                    ErrorReporter.reportError({
                        error: this.error,
                        tags: { store: 'availability' },
                    });
                } else {
                    this.error = undefined;
                    this.message = '';
                }
            }
        );
    }

    get searchRequest() {
        return {
            pickUpLocation: this.trip.pickUpLocation as string,
            dropOffLocation: this.trip.dropOffLocation as string,
            pickUpDate: formatISO9075(this.trip.pickUpDate as Date),
            dropOffDate: formatISO9075(this.trip.dropOffDate as Date),
            fleetTypeCode: this.trip.fleetType as FleetTypeCode,
            days: 5,
            campaignCode: this.trip.campaignCode,
            driverAge: this.trip.driverAge,
        };
    }

    setTrip(trip: GetAvailabilityRequest) {
        this.reset();
        this.state = 'loading';
        this.trip = trip;
        return this.loadResults(this.searchRequest);
    }

    reset() {
        this.trip = {};
        this.state = 'pending';
        this.results = [];
    }

    get hireDays() {
        return differenceInDays(this.trip.dropOffDate as Date, this.trip.pickUpDate as Date);
    }
    async loadPage(date: Date) {
        if (this.queueState.find((q) => isSameDay(q.date, date) && q.direction === 'next')) {
            return;
        }
        this.queueState.push({ date, direction: 'next' });
        const dropOffDate = addDays(date, this.hireDays);
        const placeHolders = generateDateRange({
            from: date,
            to: addDays(date, 10),
        }).map((pickUpDate) => ({
            ...this.results[0],
            pickUpDate,
            dropOffDate: addDays(pickUpDate, this.hireDays),
            placeholder: true,
        }));
        this.results = sortResults([...this.results, ...placeHolders]);
        await this.queue.add(() =>
            this.loadResults({
                ...this.searchRequest,
                pickUpDate: formatISO9075(date as Date),
                dropOffDate: formatISO9075(dropOffDate as Date),
            })
        );
    }

    async loadResults(request: AlternateDatesSearchRequest) {
        this.state = 'loading';
        const results = await accountKeyStore.apiClient.searchAlternateDates(request);
        this.results = sortResults([...this.results.filter((r) => !r.placeholder), ...results]);
        this.state = 'done';
        return results;
    }
}

export const alternateDatesStore = new AlternateDatesStore();
