import React from 'react';
import SlideBubble, { SlideBubbleProps } from '../../../SlideBubble';
import { useAlternateDateSlideContext } from './AlternateDateSlideContext';
import AlternateDatesSlideToolTip from './AlternateDatesSlideToolTip';

const AlternateDatesSlideButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, SlideBubbleProps>(({ className, children, ...props }, ref) => {
    const { continueLink, showMarker, isBookable, isInteractive, handleClicked } = useAlternateDateSlideContext();
    return (
        <AlternateDatesSlideToolTip>
            <SlideBubble
                {...props}
                ref={ref}
                href={continueLink}
                disableRipple={!isBookable}
                showMarker={showMarker}
                onClick={isBookable ? handleClicked : undefined}
                sx={(t) => ({
                    color: 'text.secondary',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: t.transitions.create(['color', 'transform']),
                    svg: {
                        transition: t.transitions.create(['filter']),
                        fill: '#ede9e7ff',
                    },
                    'div:not(:disabled)&': {
                        cursor: isBookable ? 'pointer' : 'unset',
                    },
                    '&: hover': {
                        textDecoration: 'none',
                    },
                    ...(isInteractive && isBookable
                        ? {
                              '&:hover svg': {
                                  filter: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.2))',
                              },
                          }
                        : undefined),
                    ...(showMarker ? { transform: 'scale(1.2)' } : undefined),
                })}
            >
                {children}
            </SlideBubble>
        </AlternateDatesSlideToolTip>
    );
});

export default AlternateDatesSlideButton;
