import { MandatoryProduct } from '@jucy/rentals-api-client/rentals-api-v3';
import { computed, makeAutoObservable } from 'mobx';
import Money from './Money';

export default class FleetCategoryMandatoryFee {
    name = '';
    price = new Money();

    constructor(props: Partial<FleetCategoryMandatoryFee>) {
        makeAutoObservable(this);
        Object.assign(this, props);
    }

    @computed get Name() {
        return this.name;
    }

    @computed get Price() {
        return this.price;
    }

    static fromApi({ Name, Price }: { Name: string; Price: { CurrencyCode?: string; currencyCode?: string; Value?: number; value?: number } }) {
        return new FleetCategoryMandatoryFee({ name: Name, price: Money.fromApi(Price) });
    }

    static fromV3MandatoryFeeApi(fee: MandatoryProduct) {
        return new FleetCategoryMandatoryFee({ name: fee.name, price: Money.fromApi(fee.price) });
    }
}
