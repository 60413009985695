'use client';
import { batch } from '@preact/signals-react';
import { useQueries } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import {
    branchQueryKeys,
    fleetTypeQueryKeys,
    getFleetTypesWithPreloadedImages,
    getHirePeriods,
    getSites,
    hirePeriodQueryKeys,
    mapSitesToRegion,
} from '@jucy-ui/common';
import { searchFormRepoStore } from './searchFormRepoStore';

export const SearchFormDataProvider = ({ children }: { children: React.ReactNode }) => {
    const [
        { data: hirePeriods, error: HirePeriodsLoadError },
        { data: fleetTypes, error: FleetTypesLoadError },
        { data: branches, error: BranchesLoadError },
    ] = useQueries({
        queries: [
            { queryKey: hirePeriodQueryKeys.list(), queryFn: getHirePeriods, staleTime: Infinity },
            { queryKey: fleetTypeQueryKeys.list(), queryFn: getFleetTypesWithPreloadedImages, staleTime: Infinity },
            { queryKey: branchQueryKeys.list(), queryFn: getSites },
        ],
    });
    useEffect(() => {
        if (hirePeriods) {
            searchFormRepoStore.hirePeriods = hirePeriods;
        }
    }, [hirePeriods]);
    useEffect(() => {
        if (fleetTypes) {
            searchFormRepoStore.fleetTypes = fleetTypes;
        }
    }, [fleetTypes]);
    useEffect(() => {
        if (branches) {
            batch(() => {
                searchFormRepoStore.branches = branches;
                searchFormRepoStore.regions = mapSitesToRegion({ sites: branches });
            });
        }
    }, [branches]);

    if (HirePeriodsLoadError) {
        throw HirePeriodsLoadError;
    }
    if (FleetTypesLoadError) {
        throw FleetTypesLoadError;
    }
    if (BranchesLoadError) {
        throw BranchesLoadError;
    }
    return children;
};
