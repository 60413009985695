'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { ZoomSpinner } from '../../../../ZoomSpinner';
import { FleetCategoryTeaserCardOwnerState } from '../types';

export type FleetCategoryTeaserCardActionButtonProps<RootComponent extends React.ElementType = ButtonTypeMap['defaultComponent']> =
    ButtonProps<RootComponent> & {
        ownerState: FleetCategoryTeaserCardOwnerState;
        component?: RootComponent;
    };
export const FleetCategoryTeaserCardActionButton = React.forwardRef<HTMLButtonElement, FleetCategoryTeaserCardActionButtonProps>((inProps, ref) => {
    const { ownerState, sx, ...props } = inProps;
    const { isOnRequest, onSelect, isBookable, isLoading, fleetCategory, isActive } = ownerState;
    return (
        <Button
            {...props}
            ref={ref}
            variant="contained"
            color={isOnRequest ? 'light' : 'primary'}
            disabled={!isBookable || isLoading}
            onClick={props.onClick ? props.onClick : () => onSelect?.(fleetCategory)}
            data-cy="select-button"
            sx={mergeSx(
                (theme) => ({
                    width: {
                        xs: '100%',
                        sm: 'auto',
                    },
                    mt: {
                        xs: 1,
                    },
                    background: isOnRequest ? theme.palette.grey[100] : undefined,
                    color: isOnRequest ? theme.palette.primary.main : undefined,
                }),
                sx
            )}
        >
            {<BookButtonText {...inProps.ownerState} />}
            <ZoomSpinner show={Boolean(isLoading && isActive)} />
        </Button>
    );
}) ;
FleetCategoryTeaserCardActionButton.displayName = 'FleetCategoryTeaserCardActionButton';

const BookButtonText: React.FC<FleetCategoryTeaserCardOwnerState> = ({ fleetCategory, isActive }): React.ReactNode | null => {
    if (fleetCategory.availability === 'FreeSell') {
        return isActive ? 'Selected' : 'Select';
    }
    if (fleetCategory.availability === 'OnRequest') {
        return 'Request';
    }
    if (fleetCategory.minimumHirePeriod) {
        return `${fleetCategory.minimumHirePeriod} days min. hire`;
    }
    if (fleetCategory.availability === 'StopSell') {
        return 'Sold Out';
    }
    return '';
};
