import { observer } from 'mobx-react-lite';
import React from 'react';
import EmailQuoteButton from '../../EmailQuote/EmailQuoteButton';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';

const SummaryActions: React.FC = observer(() => {
    const context = useBookingSummaryContext();

    return context.summary?.canEmailQuote ? (
        <BookingSummaryGroupItem sx={{ py: 0 }}>
            <EmailQuoteButton
                data-cy="summary-email-quote-btn"
                sx={(t) => ({
                    fontFamily: t.typography.fontFamily,
                    fontStyle: 'normal',
                    px: 0.5,
                    py: 0,
                    ml: -0.5,
                })}
            />
        </BookingSummaryGroupItem>
    ) : null;
});
SummaryActions.displayName = 'SummaryActions';

export default SummaryActions;
