'use client';
import React from 'react';
import { cn } from '@jucy-ui/common';
import Icon, { IconProps } from '@mui/material/Icon';

export type JucyIcon =
    | 'wifi'
    | 'walk'
    | 'usb_charging'
    | 'tv'
    | 'travel'
    | 'transmission'
    | 'terminal'
    | 'stressfree'
    | 'storage_net'
    | 'snowchains'
    | 'snooze_48px-3v'
    | 'snooze'
    | 'snooze-2v'
    | 'smartphone'
    | 'sleeps'
    | 'skootwifi'
    | 'shower'
    | 'sendlink'
    | 'self-contained'
    | 'secure_parking'
    | 'seats'
    | 'roof-racks'
    | 'risktaker'
    | 'refund'
    | 'privacy_blind'
    | 'plane'
    | 'partialpayment'
    | 'motorhome'
    | 'manualpayment'
    | 'luggage'
    | 'lounge'
    | 'logo_yelp'
    | 'locker'
    | 'laundry'
    | 'kitchen_facilities'
    | 'images'
    | 'hour_reception'
    | 'gps'
    | 'fullpayment'
    | 'fan'
    | 'engine'
    | 'electric_socket'
    | 'dps'
    | 'depositpayment'
    | 'cruise'
    | 'coffee'
    | 'coach'
    | 'clock'
    | 'chanceit'
    | 'cardonfile'
    | 'cardoneoff'
    | 'campingtable'
    | 'car'
    | 'campingchair'
    | 'campervan'
    | 'calendar'
    | 'campa'
    | 'boosterseat'
    | 'bed'
    | 'battery'
    | 'babyseat'
    | 'arrow_dotted_down'
    | 'addcard';

export interface JucyIconProps extends IconProps {
    icon: JucyIcon;
}

export const JucyIcon: React.FC<JucyIconProps> = ({ icon, className, ...props }) => (
    <Icon baseClassName="jucy-icon" className={cn(icon, className)} {...props} />
);
