export const parseNestedSearchParams = <ParamsOrKey extends Record<string, string | Record<string, string>>>(params: Partial<Record<string, unknown>>): ParamsOrKey =>
    Object.entries(params).reduce((res, [key, value]) => {
        const subStart = key.indexOf('[');
        const subEnd = key.indexOf(']');
        if (subStart !== -1 && subEnd !== -1) {
            const prop = key.substring(0, subStart);
            const childProp = key.substring(subStart + 1, subEnd);
            res[prop] = {
                ...(res[prop] as Record<string, string>),
                [childProp]: value,
            };
            return res;
        }
        res[key] = value;
        return res;
    }, {} as Record<string, unknown>) as ParamsOrKey;
