import * as React from 'react';
import { FormHelperText } from '@mui/material';
import classNames from 'classnames';
import FormField from './FormField';

const ReactstrapFormikInput = ({ field, form: { touched, errors }, label, placeholder, floatLabel, className, ...props }) => {
    const invalid = Boolean(errors[field.name] && touched[field.name]);
    const labelComponent = label && <label htmlFor={props.id}>{label}</label>;
    return (
        <>
            {!floatLabel && labelComponent && labelComponent}
            <input
                // invalid={invalid}
                {...field}
                {...props}
                className={classNames(className, 'form-control', { 'has-value': props.value })}
                placeholder={floatLabel && !placeholder ? label : placeholder}
            />
            {floatLabel && labelComponent && labelComponent}
            {invalid ? <FormHelperText error={true}>{errors[field.name]}</FormHelperText> : null}
        </>
    );
};

const Input = (props) => <FormField {...props} component={ReactstrapFormikInput} />;

export default Input;
