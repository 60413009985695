'use client';
import React from 'react';
import ReactImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';
import { mergeSx } from '@jucy-ui/appearance';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { JucyNavButton } from '../JucySlider/components';
import { generateGalleryStyles } from './lib';

export interface JucyImageGalleryProps extends ReactImageGalleryProps {
    sx?: SxProps<Theme>;
}

export const JucyImageGalleryButton = styled(JucyNavButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    zIndex: '4',
    color: 'white',
    background: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
        color: theme.palette.primary.main,
        transform: 'scale(1.1)',
    },
    '&:hover, &:focus': {
        background: 'transparent',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
    },
    '& svg': {
        filter: 'drop-shadow(0px 8px 4px rgba(0,0,0,0.6))',
    },
}));
export const JucyImageGallery: React.FC<JucyImageGalleryProps> = ({ sx, ...props }) => (
    <Box sx={mergeSx(generateGalleryStyles, sx)}>
        <ReactImageGallery
            thumbnailPosition="right"
            infinite={false}
            showPlayButton={true}
            lazyLoad={true}
            renderRightNav={(onClick, disabled) => (
                <JucyImageGalleryButton
                    size={70}
                    sx={{
                        right: 8,
                        transform: 'translateY(-50%)',
                        '&:hover': {
                            transform: 'translateY(-50%) scale(1.1)',
                        },
                    }}
                    variant="next"
                    onClick={onClick}
                    disabled={disabled}
                />
            )}
            renderLeftNav={(onClick, disabled) => (
                <JucyImageGalleryButton
                    size={70}
                    sx={{
                        left: 8,
                        transform: 'translateY(-50%)',
                        '&:hover': {
                            transform: 'translateY(-50%) scale(1.1)',
                        },
                    }}
                    variant="prev"
                    onClick={onClick}
                    disabled={disabled}
                />
            )}
            renderFullscreenButton={(onClick, isFullscreen) => (
                <JucyImageGalleryButton
                    size={70}
                    sx={{
                        bottom: 8,
                        right: 8,
                        top: 'auto',
                    }}
                    onClick={onClick}
                >
                    {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </JucyImageGalleryButton>
            )}
            {...props}
        />
    </Box>
);
