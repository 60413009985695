import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TripDetails, TripInfoRequestValues, getTripInfo } from '@jucy-ui/common';
import { ExtractQueryKey, UseMutationParams, UseQueryParams } from '@jucy-ui/common/lib/ReactQueryUtils';
import { Maybe } from 'yup';
import { searchFormValidationSchema, searchUidFromFormValues } from '../SearchForm';
import { searchUidToFormValues } from '../SearchForm/lib/searchUidToFormValues';

type UseGetTripInfoParams = UseQueryParams<Maybe<Partial<TripInfoRequestValues>>, TripDetails | null, ReturnType<typeof tripInfoQueryKeys.get>>;

export const useGetTripInfo = ({ request, ...options }: UseGetTripInfoParams) =>
    useQuery({
        queryKey: tripInfoQueryKeys.get(request),
        queryFn: getTripInfoQueryFn,
        ...options,
    });

export const getTripInfoQueryFn = async ({ queryKey: [, searchUid] }: { queryKey: ExtractQueryKey<UseGetTripInfoParams> }) => {
    const request = searchFormValidationSchema.cast(searchUidToFormValues(searchUid)) as TripInfoRequestValues;
    await searchFormValidationSchema.validate(request);
    if (!request.fleetCategory) {
        throw new Error('No fleet category provided');
    }
    return await getTripInfo(request);
};

type UseGetTripInfoMutationParams = UseMutationParams<UseGetTripInfoParams>;

export const useGetTripInfoMutation = (options?: UseGetTripInfoMutationParams) => {
    const queryClient = useQueryClient();
    return useMutation({
        ...options,
        mutationKey: tripInfoQueryKeys.all,
        mutationFn: async (request) => {
            if (!request?.pickUpBranch || !request.dropOffBranch) {
                return null;
            }
            const queryKey = tripInfoQueryKeys.get(request);
            await queryClient.cancelQueries({ queryKey });
            const value = await getTripInfoQueryFn({ queryKey });
            queryClient.setQueryData(queryKey, value);
            return value;
        },
    });
};

export const tripInfoQueryKeys = {
    all: ['trip-info'],
    get: (request: Maybe<Partial<TripInfoRequestValues>>) => [...tripInfoQueryKeys.all, searchUidFromFormValues(request)],
};
