'use client';
import React from 'react';
import { IconProps } from 'react-toastify';
import { ErrorOutlined, InfoOutlined, SuccessOutlined, Warning } from '../svg-icons';

export const JucyToastIcon: React.FC<IconProps> = (props) => {
    switch (props.type) {
        case 'error':
            return <ErrorOutlined color="error" />;
        case 'info':
            return <InfoOutlined color="info" />;
        case 'warning':
            return <Warning color="warning" />;
        case 'success':
            return <SuccessOutlined color="success" />;
        default:
            return <InfoOutlined color="info" />;
    }
};
