import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExcessReductionFeature } from '#/lib/api/model/ExtendedFleetCategory';
import { Box, DialogContent, DialogTitle } from '@mui/material';
import chunk from 'lodash/chunk';
import FleetCategoryAvailability from '../../../../../lib/api/model/FleetCategoryAvailability';
import TranslationStore from '../../../../../store/TranslationStore';
import ModalDialog from '../../../../ModalDialog';
import { JucyMuiTab, JucyMuiTabPanel, JucyMuiTabs } from '../../../../Tabs';
import { PageTitle } from '../../../../Typograpgy/Title';
import { useTeaserContext } from '../TeaserContext';
import FeatureIcon from './FeatureIcon';
import FleetCategoryImageGallery from './FleetCategoryImageGallery';

const tabProps = {
    a11yName: 'fleet-category',
    p: 1,
};
const useGetFleetCategoryCmsPage = (fleetCategory: FleetCategoryAvailability) => {
    const { i18n } = useTranslation(TranslationStore.currentNamespace);

    return useMemo(() => {
        const pages = (fleetCategory.product.extended && fleetCategory.product.extended.pages) || [];
        let page = pages.find((p) => p.locale === i18n.language);
        if (!page) {
            page = pages.find((p) => p.locale.includes('en'));
        }
        return page || null;
    }, [i18n.language, fleetCategory.product]);
};

const FleetCategoryDetailsDialog: React.FC = () => {
    const { fleetCategory, toggleDetailsModal, detailsModalOpen } = useTeaserContext();
    const [activeTab, setActiveTab] = useState('details');
    const toggle = useCallback((_event: React.SyntheticEvent, newValue: string) => setActiveTab(newValue), []);
    const cmsPage = useGetFleetCategoryCmsPage(fleetCategory);
    return (
        <ModalDialog open={detailsModalOpen} onClose={toggleDetailsModal} maxWidth="md">
            <DialogTitle component="div">
                <PageTitle>{cmsPage?.title || fleetCategory.product.name}</PageTitle>
                <JucyMuiTabs value={activeTab} onChange={toggle}>
                    <JucyMuiTab label="Details" value="details" a11yName="fleet-category" />
                    <JucyMuiTab label="Features &amp; Extras" value="features" a11yName="fleet-category" />
                    <JucyMuiTab label="Specs &amp; plans" value="specs" a11yName="fleet-category" />
                    <JucyMuiTab label="Excess Reduction" value="excess" a11yName="fleet-category" />
                </JucyMuiTabs>
            </DialogTitle>
            <DialogContent>
                <JucyMuiTabPanel activeValue={activeTab} value="details" {...tabProps}>
                    <FleetCategoryImageGallery />
                    <div className="pt-2" dangerouslySetInnerHTML={{ __html: fleetCategory.product.extended?.description || '' }} />
                    {fleetCategory.product.extended?.keyFeatures ? (
                        <>
                            <h5 className="fw-bold h5 mt-2">Key Features</h5>
                            <div className="row icon-block">
                                {fleetCategory.product.extended.keyFeatures.map((feature, i) => (
                                    <div key={`feature-${i}`} className="col-6 col-sm-4 d-flex align-items-center">
                                        <FeatureIcon feature={feature} />
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : null}
                    {fleetCategory.product.extended?.includedWith ? (
                        <>
                            <h5 className="fw-bold h5 mt-4">Included with the vehicle</h5>
                            <div className="row check-list ">
                                {fleetCategory.product.extended.includedWith.map((feature, i) => (
                                    <div key={`feature-${i}`} className="col-6 col-sm-4 d-flex">
                                        <FeatureIcon feature={feature} />
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : null}
                </JucyMuiTabPanel>
                <JucyMuiTabPanel activeValue={activeTab} value="features" {...tabProps}>
                    <div className="pt-2" dangerouslySetInnerHTML={{ __html: fleetCategory.product.extended?.featuresDescription || '' }} />
                    {fleetCategory.product.extended?.features
                        ? fleetCategory.product.extended.features.map((feature) => (
                              <div key={feature.title}>
                                  <h5 className="fw-bold h5 mt-4">{feature.title}</h5>
                                  {feature.content ? <div className="pt-2" dangerouslySetInnerHTML={{ __html: feature.content }} /> : null}
                                  {feature.items ? (
                                      <div className="row">
                                          {chunk(feature.items, feature.items.length / 2 + 1).map((featureChunk, i) => (
                                              <div key={`feature-${i}`} className="col-12 col-sm-6 d-flex">
                                                  <ul className="fa-ul ms-4">
                                                      {featureChunk.map((feature, j) => (
                                                          <li key={`features-${i}-${j}`}>
                                                              <span className="fa-li">
                                                                  <i className="fas fa-check text-green" />
                                                              </span>{' '}
                                                              {feature}
                                                          </li>
                                                      ))}
                                                  </ul>
                                              </div>
                                          ))}
                                      </div>
                                  ) : null}
                              </div>
                          ))
                        : null}
                </JucyMuiTabPanel>
                <JucyMuiTabPanel activeValue={activeTab} value="specs" {...tabProps}>
                    {fleetCategory.product.extended?.specificationsDescription ? (
                        <div
                            className="pt-2"
                            dangerouslySetInnerHTML={{
                                __html: fleetCategory.product.extended.specificationsDescription,
                            }}
                        />
                    ) : null}
                    <div className="row">
                        <div className="col-sm-6">
                            <table className="table table-sm table-borderless">
                                <tbody>
                                    {fleetCategory.product.extended?.specifications
                                        ? Object.entries(fleetCategory.product.extended.specifications).map(([key, value]) => (
                                              <tr key={key}>
                                                  <td className="fw-bold border-bottom">{key}</td>
                                                  <td className="border-bottom">{value}</td>
                                              </tr>
                                          ))
                                        : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </JucyMuiTabPanel>
                <JucyMuiTabPanel activeValue={activeTab} value="excess" {...tabProps}>
                    <ExcessReductionComparisonTable features={fleetCategory.product.extended?.excessReductionFeatures} />
                    <Box
                        dangerouslySetInnerHTML={{
                            __html: fleetCategory.product.extended?.excessReductionDescription || '',
                        }}
                    />
                </JucyMuiTabPanel>
            </DialogContent>
        </ModalDialog>
    );
};

const ExcessReductionComparisonFeature: React.FC<{
    feature: ExcessReductionFeature;
    product: 'basic' | 'standard' | 'full';
}> = ({ feature, product }) => {
    const value = feature[product];

    if (feature.type === 'text') {
        return value;
    }

    if (value) {
        return <i className="fas fa-check text-green"></i>;
    }

    return <i className="fas fa-times text-red"></i>;
};
const ExcessReductionComparisonTable: React.FC<{ features?: ExcessReductionFeature[] }> = ({ features }) => {
    if (!features || features.length === 0) {
        return null;
    }
    return (
        <div className="excess-specs pt-2">
            <div role="region" className="excess-table-wrap">
                <table role="table">
                    <thead role="rowgroup">
                        <tr role="row">
                            <th role="columnheader" className="pin"></th>
                            <th role="columnheader" className="product-label">
                                Risk Taker
                            </th>
                            <th role="columnheader" className="product-label">
                                Stress Free
                            </th>
                            <th role="columnheader" className="product-label">
                                Stress Free Plus
                            </th>
                        </tr>
                    </thead>
                    <tbody role="rowgroup">
                        {features.map((feature) => (
                            <tr key={feature.name} role="row">
                                <th role="cell" className="spec-label" dangerouslySetInnerHTML={{ __html: feature.name || '' }}></th>
                                <td role="cell" className="spec-value">
                                    <ExcessReductionComparisonFeature feature={feature} product="basic" />
                                </td>
                                <td role="cell" className="spec-value">
                                    <ExcessReductionComparisonFeature feature={feature} product="standard" />
                                </td>
                                <td role="cell" className="spec-value">
                                    <ExcessReductionComparisonFeature feature={feature} product="full" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FleetCategoryDetailsDialog;
