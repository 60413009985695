import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Grid, GridProps, useMediaQuery, useTheme } from '@mui/material';
import BookingSummary from '../components/BookingSummary/BookingSummary';
import OrderSummaryFooter from '../components/Footer/OrderSummaryFooter';
import AppStateStore from '../store/AppStateStore';
import { BookingCart } from '../types/BookingCart';
import { PageContainer } from './PageContainer';

export interface SummaryColumnLayoutProps extends GridProps {
    showRight?: boolean;
    routeId?: string;
    summary?: BookingCart;
}

const SummaryColumnLayout: React.FC<SummaryColumnLayoutProps> = observer(({ children, showRight = true, routeId, summary, ...props }) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    const summaryRef = useRef<HTMLDivElement>(null);
    const showTripSummary = AppStateStore.orderSummaryFooter.showTripSummary;
    useEffect(() => {
        if (showTripSummary && summaryRef.current) {
            summaryRef.current.scroll({ top: 0 });
        }
    }, [showTripSummary]);
    return (
        <PageContainer>
            <Grid container spacing={0} {...props} sx={{ maxWidth: '100%' }}>
                <Grid item xs={12} md={8} sx={{ maxWidth: '100%' }}>
                    {children}
                </Grid>
                {summary?.hasTripInfo && showRight ? (
                    <Grid
                        component="aside"
                        className="summary-column"
                        item
                        position="relative"
                        sm={12}
                        md={4}
                        ref={summaryRef}
                        sx={(t) => ({
                            ...(isSm
                                ? {
                                      background: t.palette.grey['50'],
                                      transform: AppStateStore.orderSummaryFooter.showTripSummary ? 'translate3d(0, 0, 0)' : 'translate3d(0, 100%, 0)',
                                      position: 'fixed',
                                      top: theme.settings?.appBar.spacer() || 0,
                                      transition: theme.transitions.create('transform', {
                                          easing: theme.transitions.easing.easeInOut,
                                      }),
                                      width: '100%',
                                      zIndex: 1,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      pt: 2,
                                      pb: { xs: `${theme.settings?.footer.height}px`, md: 0 },
                                  }
                                : {
                                      position: 'relative',
                                      pl: 6,
                                      '&::after': {
                                          content: '""',
                                          display: 'block',
                                          width: '300%',
                                          position: 'absolute',
                                          top: -100,
                                          left: t.spacing(2),
                                          bottom: 0,
                                          background: t.palette.grey['50'],
                                          zIndex: -1,
                                      },
                                  }),
                        })}
                    >
                        <BookingSummary routeId={routeId} summary={summary} />
                    </Grid>
                ) : null}
                <OrderSummaryFooter summary={summary} />
            </Grid>
        </PageContainer>
    );
});
SummaryColumnLayout.displayName = 'SummaryColumnLayout';
export default SummaryColumnLayout;
