import { URLSearchParamsInit, createSearchParams } from 'react-router-dom';
import { Booking } from '@jucy/rentals-api-client/rentals-api-v3';
import { addHours, addYears, differenceInDays, endOfYear, format, isAfter, isBefore, isWithinInterval, max, roundToNearestMinutes } from 'date-fns';
import { FormikContextType } from 'formik';
import i18next from 'i18next';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { action, autorun, makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { Writable } from 'type-fest';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';
import { SearchFormValues } from '../components/Forms/TripSearchForm/SearchFormValues';
import ErrorReporter from '../lib/ErrorReporter';
import { filterLocationHours, filterMessagesForDate, filterSites, getProhibitedSiteCodes, isDayHoliday } from '../lib/RentalRulesHelper';
import { TripInfo } from '../lib/TripInfo';
import { Deal } from '../lib/api/model/Deal';
import Quote from '../lib/api/model/Quote';
import { Holiday, RentalSiteSettings } from '../lib/api/model/RentalSiteSettings';
import { Site } from '../lib/api/model/Site';
import calculateHireDays from '../lib/calculateHireDays';
import { isValidDate, setTime, setTimeFromString } from '../lib/dates';
import { queryStringToSearchFormValues } from '../lib/queryStringToSearchFormValues';
import { quoteToValues } from '../lib/quoteToValues';
import { stripEmptyProps } from '../lib/stripEmptyProps';
import { toSearchUid } from '../lib/toSearchUid';
import { FleetType } from '../services';
import AccountDetailsStore from './AccountDetailsStore';
import AvailabilityStore from './AvailabilityStore';
import currencyStore from './CurrencyStore';
import DealStore from './DealStore';
import { defaultDriverAge, defaultDropOffDate, defaultPickUpDate, defaultSite } from './DefaultsStore.js';
import FleetTypesStore from './FleetTypesStore';
import SitesStore from './SitesStore';

const toSimpleValues = (o: Partial<SearchFormValues>) => {
    const pickUpDate = o.pickUpDate && o.pickUpTime ? setTime(o.pickUpDate, o.pickUpTime) : o.pickUpDate;
    const dropOffDate = o.dropOffDate && o.dropOffTime ? setTime(o.dropOffDate, o.dropOffTime) : o.dropOffTime;
    const pickUpTime = o.pickUpDate && o.pickUpTime ? setTime(o.pickUpDate, o.pickUpTime) : o.pickUpTime;
    const dropOffTime = o.dropOffDate && o.dropOffTime ? setTime(o.dropOffDate, o.dropOffTime) : o.dropOffTime;
    return {
        fleetType: o.fleetType ? o.fleetType.id : null,
        pickUpLocation: o.pickUpLocation ? o.pickUpLocation.SiteCode : null,
        pickUpDate: pickUpDate ? pickUpDate.getTime() : null,
        pickUpTime: pickUpTime ? pickUpTime.getTime() : null,
        dropOffLocation: o.dropOffLocation ? o.dropOffLocation.SiteCode : null,
        dropOffDate: dropOffDate ? dropOffDate.getTime() : null,
        dropOffTime: dropOffTime ? dropOffTime.getTime() : null,
        driverAge: o.driverAge || '',
        promoCode: o.promoCode || '',
    };
};
export type ServiceHours = {
    start: Date;
    end: Date;
    allowAfterHours?: boolean | null;
    open?: Date | null;
    close?: Date | null;
};

export class RentalTripSearchStore implements Partial<SearchFormValues> {
    state: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    action?: 'create' | 'edit' = 'create';
    message = '';
    error?: Error;
    hireDays = '';
    fleetType?: FleetType = undefined;
    fleetCategory = '';
    pickUpLocation?: Site = defaultSite || undefined;
    pickUpDate?: Date = defaultPickUpDate || undefined;
    pickUpTime?: Date = defaultPickUpDate || undefined;
    dropOffLocation?: Site = defaultSite || undefined;
    dropOffDate?: Date = defaultDropOffDate || undefined;
    dropOffTime?: Date = defaultDropOffDate || undefined;
    driverAge: string = defaultDriverAge;
    promoCode? = '';
    minDays = null;
    maxDays = null;
    selectedDeal?: Deal = undefined;

    formContext?: FormikContextType<SearchFormValues>;

    constructor() {
        makeAutoObservable(this, { formContext: false });
        reaction(
            () => this.minDate?.getTime(),
            action((minDateTimeStamp) => {
                if (minDateTimeStamp) {
                    const { pickUpDateTime } = this;
                    const minDate = new Date(minDateTimeStamp);
                    if (pickUpDateTime && isBefore(pickUpDateTime, minDate)) {
                        this.pickUpDate = minDate;
                        this.pickUpTime = minDate;
                    }
                }
            })
        );
        reaction(
            () => this.selectedDeal,
            action((deal: Deal | undefined) => {
                if (deal) {
                    if (deal.fleetType) {
                        this.fleetType = deal.fleetType;
                    }
                    if (deal.pickUpLocations?.length === 1) {
                        this.pickUpLocation = deal.pickUpLocations[0];
                    }
                    if (deal.dropOffLocations?.length === 1) {
                        this.dropOffLocation = deal.dropOffLocations[0];
                    }
                    this.promoCode = deal.code;
                }
            })
        );

        reaction(
            () => this.state,
            (data) => {
                if (data === 'error') {
                    ErrorReporter.reportError({
                        error: this.error,
                        tags: { store: 'rental-trip-search' },
                    });
                } else {
                    this.error = undefined;
                    this.message = '';
                }
            }
        );

        let prevCountry = 'nz';

        autorun(() => {
            if (this.pickUpLocation && this.pickUpLocation.Country !== prevCountry) {
                if (this.pickUpLocation.CountryCode === 'au' && currencyStore.selectedCurrencyCode === 'NZD') {
                    currencyStore.setSelectedCode('AUD');
                }
                if (this.pickUpLocation.CountryCode === 'nz' && currencyStore.selectedCurrencyCode === 'AUD') {
                    currencyStore.setSelectedCode('NZD');
                }
                prevCountry = this.pickUpLocation.Country;
            }
        });
    }

    get searchValidationSchema(): ObjectSchema<SearchFormValues> {
        return Yup.object().shape({
            fleetType: Yup.object().required(i18next.t('trip_search_messages.group.select_vehicle_type') as string),
            pickUpLocation: Yup.object()
                .nullable()
                .required(i18next.t('trip_search_messages.group.select_pickup_location') as string),
            pickUpDate: Yup.date()
                .nullable()
                .required(i18next.t('trip_search_messages.group.select_pickup_date') as string)
                .test('pickUpDate', 'Pick up date is not valid', (date) => isValidDate(date))
                .test(
                    'pickUpDate',
                    i18next.t('trip_search_messages.group.branch_closed_pickup') as string,
                    (date) =>
                        isValidDate(date) &&
                        !this.isDayBlocked({
                            date: date as Date,
                            focusedInput: 'startDate',
                        })
                )
                .test(
                    'pickUpDate',
                    () => `${i18next.t('trip_search_messages.group.pickup_minimum')} ${format(this.minDate, 'd MMM yyyy HH:mm')}`,
                    (date, { parent }) => isValidDate(date) && isValidDate(parent.pickUpTime) && isAfter(setTime(date as Date, this.minDate), this.minDate)
                ),
            pickUpTime: Yup.date()
                .nullable()
                .required(i18next.t('trip_search_messages.group.select_pickup_time') as string)
                .test('pickUpTime', 'Pick up time is not valid', (date) => isValidDate(date))
                .test('pickUpTime', i18next.t('trip_search_messages.group.after_hours_message') as string, (date) => isValidDate(date) && this.isPickUpTimeValid(date)),
            dropOffLocation: Yup.object<Site>()
                .nullable()
                .required(i18next.t('trip_search_messages.group.dropoff_location') as string)
                .test(
                    'dropOffLocation',
                    i18next.t('trip_search_messages.group.dropoff_same_country_as_pickup') as string,
                    (dropOffLocation, { parent }) => parent.pickUpLocation?.Country === (dropOffLocation as Site)?.Country
                ),
            dropOffDate: Yup.date()
                .nullable()
                .required(i18next.t('trip_search_messages.group.select_droppff_date') as string)
                .test('dropOffDate', 'Drop off date is not valid', (date) => isValidDate(date))
                .test(
                    'dropOffDate',
                    i18next.t('trip_search_messages.group.branch_closed_dropoff') as string,
                    (date) =>
                        isValidDate(date) &&
                        !this.isDayBlocked({
                            date: date as Date,
                            focusedInput: 'endDate',
                        })
                )
                .test('dropOffDate', `${this.minHireDays} ${i18next.t('trip_search_messages.group.rental_over_this_period')}`, (dropOffDate, { parent }) =>
                    isValidDate(parent.pickUpDate) && isValidDate(dropOffDate) ? !this.belowMinHire(parent.pickUpDate, dropOffDate as Date) : true
                )
                .test('dropOffDate', `${this.maxHireDays} max hire days exceeded`, (dropOffDate, { parent }) =>
                    isValidDate(dropOffDate) ? !this.exceedsMaxHire(parent.pickUpDate, dropOffDate as Date) : true
                )
                .test('dropOffDate', i18next.t('trip_search_messages.group.dropoff_before_pickup') as string, (dropOffDate, { parent }) => {
                    if (isValidDate(dropOffDate) && isValidDate(parent.pickUpTime) && isValidDate(parent.pickUpDate) && isValidDate(parent.dropOffTime)) {
                        return isBefore(parent.pickUpDate, setTime(dropOffDate, parent.dropOffTime));
                    }
                    if (isValidDate(parent.pickUpDate) && isValidDate(dropOffDate)) {
                        return isBefore(parent.pickUpDate, dropOffDate);
                    }
                    return true;
                }),
            dropOffTime: Yup.date()
                .nullable()
                .required(i18next.t('trip_search_messages.group.select_dropoff_time') as string)
                .test('dropOffTime', 'Pick off ime is not valid', (date) => isValidDate(date))
                .test('dropOffTime', i18next.t('trip_search_messages.group.after_hours_dropoffs_not_available') as string, (date) => isValidDate(date) && this.isDropOffTimeValid(date)),
            driverAge: Yup.string()
                .ensure()
                .required(i18next.t('trip_search_messages.group.select_driver_age') as string),
            promoCode: Yup.string().optional().nullable(),
            fleetCategory: Yup.string().optional().nullable(),
        }) as unknown as ObjectSchema<SearchFormValues>;
    }

    get isRelocation() {
        return this.selectedDeal && this.selectedDeal.type === 'vehicle-relocation';
    }

    get canChooseFleet() {
        return !this.selectedDeal || !this.selectedDeal.isRelocation;
    }

    get prohibitedPickUpSiteCodes(): string[] {
        if (!this.pickUpLocationSettings) {
            return [];
        }
        return getProhibitedSiteCodes('pickUp', this.pickUpLocationSettings);
    }

    get prohibitedDropOffSiteCodes(): string[] {
        if (!this.dropOffLocationSettings) {
            return [];
        }
        return getProhibitedSiteCodes('dropOff', this.dropOffLocationSettings);
    }

    get fleetTypeId() {
        return this.fleetType && this.fleetType.id;
    }

    get fleetTypeSlug() {
        return this.fleetType && this.fleetType.slug;
    }

    get pickUpDateTime() {
        return this.pickUpDate && this.pickUpTime && setTime(this.pickUpDate, this.pickUpTime);
    }

    get dropOffDateTime() {
        return this.dropOffDate && this.dropOffTime && setTime(this.dropOffDate, this.dropOffTime);
    }

    get pickUpLocationSettings(): RentalSiteSettings | undefined {
        return this.pickUpLocation && SitesStore.getSettingsForSite(this.pickUpLocation);
    }

    get pickUpLocationHours() {
        return this.locationHours && this.locationHours.pickUp;
    }

    get dropOffLocationSettings(): RentalSiteSettings | undefined {
        return this.dropOffLocation && SitesStore.getSettingsForSite(this.dropOffLocation);
    }

    get dropOffLocationHours() {
        return this.locationHours && this.locationHours.dropOff;
    }

    get officeHours() {
        const { fleetType, pickUpLocation, dropOffLocation } = this;
        const pickUpDate = this.pickUpDate || new Date();
        const dropOffDate = this.dropOffDate || new Date();
        const pickupOfficeHours = pickUpLocation?.getSettingsForFleetType(fleetType)?.getServiceHoursForDate(pickUpDate);
        const dropOffOfficeHours = dropOffLocation?.getSettingsForFleetType(fleetType)?.getServiceHoursForDate(dropOffDate);
        return {
            pickUp: {
                open: pickupOfficeHours && setTimeFromString(pickUpDate, pickupOfficeHours.Open),
                close: pickupOfficeHours && setTimeFromString(pickUpDate, pickupOfficeHours.Close),
            },
            dropOff: {
                open: dropOffOfficeHours && setTimeFromString(pickUpDate, dropOffOfficeHours.Open),
                close: dropOffOfficeHours && setTimeFromString(pickUpDate, dropOffOfficeHours.Close),
            },
        };
    }

    get locationHours(): { pickUp: ServiceHours; dropOff: ServiceHours } | null {
        const { pickUpLocationSettings, dropOffLocationSettings, fleetTypeSlug, officeHours, leadTime } = this;
        const pickUpDate = this.pickUpDate || new Date();
        const dropOffDate = this.dropOffDate || new Date();
        const serviceHours =
            fleetTypeSlug &&
            pickUpLocationSettings &&
            dropOffLocationSettings &&
            filterLocationHours({
                pickUpLocationSettings,
                dropOffLocationSettings,
                fleetTypeSlug,
                pickUpDate,
                dropOffDate,
                leadTime,
            });
        return serviceHours
            ? {
                  pickUp: {
                      ...serviceHours.pickUp,
                      ...officeHours.pickUp,
                  },
                  dropOff: {
                      ...serviceHours.dropOff,
                      ...officeHours.dropOff,
                  },
              }
            : null;
    }

    get minDate() {
        const leadStart = addHours(new Date(), this.leadTime ? this.leadTime : 0);
        const minPickUp = isValidDate(this.selectedDeal?.earliestStartDate) ? max([this.selectedDeal?.earliestStartDate as Date, leadStart]) : leadStart;
        //round up to the next 30 minute interval
        return roundToNearestMinutes(minPickUp, {
            nearestTo: 30,
            roundingMethod: 'ceil',
        });
    }

    get maxDate() {
        if (isValidDate(this.selectedDeal?.latestEndDate)) {
            return this.selectedDeal?.latestEndDate as Date;
        }
        return endOfYear(addYears(new Date(), 2));
    }

    get maxHireDays() {
        return this.selectedDeal && this.selectedDeal.maxHireDays;
    }

    get pickUpLocations() {
        const prohibitedSiteCodes = this.prohibitedPickUpSiteCodes;
        const fleetTypeId = this.fleetTypeId;
        const sites = SitesStore.sites;
        const pickUpLocation = this.pickUpLocation;
        const country = this.action === 'edit' ? pickUpLocation?.Country : undefined;

        if (this.selectedDeal?.pickUpLocations) {
            return this.selectedDeal.pickUpLocations;
        }
        return fleetTypeId
            ? filterSites({
                  sites,
                  fleetTypeId,
                  prohibitedSiteCodes,
                  country,
              })
            : [];
    }

    get dropOffLocations() {
        const prohibitedSiteCodes = this.prohibitedPickUpSiteCodes;
        const fleetTypeId = this.fleetTypeId;
        const sites = SitesStore.sites;
        const pickUpLocation = this.pickUpLocation;
        const country = pickUpLocation?.Country;

        if (this.selectedDeal?.dropOffLocations) {
            return this.selectedDeal.dropOffLocations;
        }
        return fleetTypeId
            ? filterSites({
                  sites,
                  fleetTypeId,
                  prohibitedSiteCodes,
                  country,
              })
            : [];
    }

    get leadTime() {
        return AccountDetailsStore?.getFleetTypeLeadTime(this.fleetType) || 2;
    }

    get minHireDays() {
        return 1;
    }

    get warnings() {
        const { pickUpLocationSettings, dropOffLocationSettings, fleetTypeSlug, pickUpDateTime, dropOffDateTime } = this;
        const pickUpFleetTypeSettings = toJS(pickUpLocationSettings && pickUpLocationSettings.fleetTypeSettings.find((s) => s.fleetTypeSlug === fleetTypeSlug));
        const dropOffFleetTypeSettings = toJS(dropOffLocationSettings && dropOffLocationSettings.fleetTypeSettings.find((s) => s.fleetTypeSlug === fleetTypeSlug));
        const result: string[] = [];
        if (pickUpFleetTypeSettings && pickUpDateTime) {
            filterMessagesForDate(pickUpDateTime, pickUpFleetTypeSettings.pickupServiceHoursMessages).forEach((m) => result.push(m.message));
        }
        if (dropOffFleetTypeSettings && dropOffDateTime) {
            filterMessagesForDate(dropOffDateTime, dropOffFleetTypeSettings.dropOffServiceHoursMessages).forEach((m) => result.push(m.message));
        }
        return result;
    }

    get driverAges(): string[] {
        const driverAges = this?.pickUpLocation?.MinAges || [];
        const fleetTypeMinAge = this.fleetType?.youngestDriver || 0;
        return fleetTypeMinAge ? driverAges.filter((age) => Number(age) >= fleetTypeMinAge) : driverAges;
    }

    get formFieldValues(): Partial<SearchFormValues> {
        return pick(toJS(this), this.formFields);
    }

    get formFields(): (keyof SearchFormValues)[] {
        return Object.keys(this.searchValidationSchema.fields) as (keyof SearchFormValues)[];
    }

    get asQueryString() {
        return this.convertValuesToQueryString(this);
    }

    get isValid() {
        return this.searchValidationSchema.isValidSync(this);
    }

    get hasTripInfo() {
        return Boolean(this.pickUpLocation && this.pickUpDate && this.pickUpTime && this.dropOffLocation && this.dropOffDate && this.dropOffTime);
    }

    setValues = (values: Partial<RentalTripSearchStore>) => {
        runInAction(() => {
            for (const [name, value] of Object.entries(values)) {
                const self = this as Writable<RentalTripSearchStore>;
                const prop = name as keyof Writable<RentalTripSearchStore>;
                const currentValue = self[prop];
                if (currentValue !== value) {
                    self[prop] = value as never;
                }
            }
        });
    };

    setValuesFromBooking = (booking: Booking) => {
        const pickUpLocation = SitesStore.getSiteByCode(booking.pickUpLocation);
        const dropOffLocation = SitesStore.getSiteByCode(booking.dropOffLocation);
        this.dropOffDate = booking?.dropOffDate;
        this.dropOffLocation = dropOffLocation;
        this.dropOffTime = booking?.dropOffDate;
        this.fleetType = FleetTypesStore.getFleetTypeBySlug(booking?.fleetCategory.fleetTypeCode) || undefined;
        this.fleetCategory = booking.fleetCategory.code;
        this.pickUpDate = booking.pickUpDate ? booking?.pickUpDate : undefined;
        this.pickUpLocation = pickUpLocation;
        this.pickUpTime = booking.pickUpDate;
        this.promoCode = booking.fleetCategory.campaignCode;
    };

    init({
        dealId,
        deal: passedDeal,
        fleetTypeSlug,
        pickUpLocation,
        pickUpDate,
        pickUpTime,
        dropOffLocation,
        dropOffDate,
        dropOffTime,
        driverAge,
        promoCode,
        action: actionType,
    }: {
        dealId?: string;
        deal?: Deal;
        fleetType?: FleetType;
        fleetCategory?: string;
        pickUpLocation?: Site;
        pickUpDate?: Date;
        pickUpTime?: Date;
        dropOffLocation?: Site;
        dropOffDate?: Date;
        dropOffTime?: Date;
        driverAge?: string;
        fleetTypeSlug?: string;
        promoCode?: string;
        action?: RentalTripSearchStore['action'];
    } = {}) {
        this.state = 'pending';
        this.hireDays = '';
        this.action = actionType || this.action;
        this.pickUpLocation = pickUpLocation || defaultSite || undefined;
        this.pickUpDate = pickUpDate || defaultPickUpDate || undefined;
        this.pickUpTime = pickUpTime || defaultPickUpDate || undefined;
        this.dropOffLocation = dropOffLocation || defaultSite || undefined;
        this.dropOffDate = dropOffDate || defaultDropOffDate || undefined;
        this.dropOffTime = dropOffTime || defaultDropOffDate || undefined;
        this.driverAge = driverAge || defaultDriverAge;
        this.promoCode = promoCode || undefined;
        if (fleetTypeSlug) {
            this.setFleetType(fleetTypeSlug);
        }
        this.selectedDeal = passedDeal || undefined;

        if (dealId) {
            DealStore.fetchDeal(dealId)
                .then(
                    action((deal) => {
                        this.selectedDeal = deal;
                        this.done();
                    })
                )
                .catch(
                    action((e) => {
                        this.state = 'error';
                        this.error = e;
                        this.message = 'Failed to load deal';
                    })
                );
        } else {
            this.done();
        }
    }

    setFleetType(slug?: string) {
        const fleetType = slug && FleetTypesStore.getFleetTypeBySlug(slug);
        if (!fleetType && FleetTypesStore.fleetTypes.length) {
            this.state = 'error';
            this.message = `Invalid fleet type "${slug}"`;
        }
        this.fleetType = fleetType || undefined;
    }

    done() {
        // don't clear error state
        if (this.state === 'pending') {
            this.state = 'done';
            this.message = '';
        }
    }

    getInitialValues({
        queryString,
        reservation,
        defaults,
    }: { queryString?: URLSearchParamsInit; reservation?: Quote; defaults?: Partial<SearchFormValues> } = {}): Partial<SearchFormValues> {
        const reservationValues = reservation && quoteToValues(reservation);
        const queryStringValues = queryString && this.convertValuesFromQueryString(queryString);

        const mergedValues = {
            ...stripEmptyProps(defaults),
            ...stripEmptyProps(this.formFieldValues),
            ...stripEmptyProps(reservationValues),
            ...stripEmptyProps(queryStringValues),
        };

        return {
            ...mergedValues,
            dropOffLocation: mergedValues?.pickUpLocation?.Country === mergedValues?.dropOffLocation?.Country ? mergedValues.dropOffLocation : undefined,
            togglePromoCode: Boolean(this.selectedDeal?.code),
        };
    }

    isDayOutOfRange({ date, focusedInput }: { date?: Date; focusedInput?: 'startDate' | 'endDate' }) {
        if (!date || !focusedInput || this.minDate > this.maxDate) {
            return false;
        }
        return Boolean(
            !isWithinInterval(date, {
                start: this.minDate,
                end: this.maxDate,
            }) ||
                (focusedInput === 'endDate' && this.isAfterMaxHire(date))
        );
    }

    isAfterMaxHire(date: Date) {
        return this.pickUpDate && this.exceedsMaxHire(this.pickUpDate, date);
    }

    belowMinHire(start: Date, end: Date) {
        return Boolean(this.minHireDays && !(differenceInDays(end, start) + 1 >= this.minHireDays));
    }

    exceedsMaxHire(start: Date, end: Date) {
        const hireDays = calculateHireDays({ start, end, fleetType: this.fleetType });
        return this.maxHireDays ? hireDays > this.maxHireDays : false;
    }

    findHoliday = ({ date, focusedInput }: { date: Date; focusedInput: 'startDate' | 'endDate' }) => {
        let holidays: Holiday[] = [];
        if (focusedInput === 'startDate') {
            holidays = this.pickUpLocationSettings?.holidays || [];
        } else if (focusedInput === 'endDate') {
            holidays = this.dropOffLocationSettings?.holidays || [];
        }
        return isDayHoliday(date, holidays);
    };

    setValuesFromForm = ({ values }: { values: Partial<SearchFormValues> }) => {
        if (values && this.formFieldValues && !isEqual(toSimpleValues(values), toSimpleValues(this.formFieldValues))) {
            this.setValues(
                this.formFields.reduce((res, prop) => {
                    if (values[prop]) {
                        res[prop] = values[prop] as never;
                    }
                    return res;
                }, {} as Partial<SearchFormValues>)
            );
        }
    };

    setValuesFromQueryString(queryString: URLSearchParamsInit): void {
        const values = this.convertValuesFromQueryString(queryString);
        if (values) {
            this.setValuesFromForm({ values });
        }
    }

    isDayBlocked({ date, focusedInput }: { date?: Date; focusedInput?: 'startDate' | 'endDate' }) {
        if (!date || !focusedInput) {
            return false;
        }
        return this.findHoliday({ date, focusedInput }) !== null || this.isBlackoutDay(date);
    }

    isBlackoutDay(date: Date) {
        return Boolean(
            this.selectedDeal &&
                this.selectedDeal.blackoutRanges &&
                this.selectedDeal.blackoutRanges.find(
                    (range) =>
                        !range.from ||
                        !range.to ||
                        isWithinInterval(date, {
                            start: range.from,
                            end: range.to,
                        })
                )
        );
    }

    isPickUpTimeValid = (date: Date) => {
        if (!isValidDate(date)) {
            return false;
        }
        if (!this.pickUpLocationHours?.start || this.pickUpLocationHours?.end) {
            return true;
        }
        return this.isTimeInHours(date, this.pickUpLocationHours);
    };

    isDropOffTimeValid = (date: Date) => {
        if (!isValidDate(date)) {
            return false;
        }
        if (!this.dropOffLocationHours) {
            return true;
        }
        return this.isTimeInHours(date, this.dropOffLocationHours);
    };

    isTimeInHours = (date: Date, hours: ServiceHours) =>
        date &&
        (hours.allowAfterHours ||
            isWithinInterval(date, {
                start: setTime(date, hours.start),
                end: setTime(date, hours.end),
            }));

    convertValuesFromQueryString(query?: URLSearchParamsInit): Omit<SearchFormValues, 'fleetType'> | null {
        return queryStringToSearchFormValues(query);
    }

    validate(values: Partial<SearchFormValues>): boolean {
        return this.searchValidationSchema.isValidSync(values);
    }

    compareToQuote(quote: Quote) {
        const quoteVales = { ...quoteToValues(quote), driverAge: this.driverAge, promoCode: this.promoCode };
        return isEqual(toSimpleValues(quoteVales), toSimpleValues(this));
    }

    setValuesFromQuote(quote: Quote) {
        if (quote) {
            this.setValues(quoteToValues(quote));
        }
    }

    convertValuesToQueryString(values: Partial<SearchFormValues>): string | null {
        if (values) {
            const convertedValues: Partial<SearchFormValues> = this.formFields.reduce(
                (res, prop) => {
                    if (prop !== 'fleetType') {
                        const value = values[prop];
                        if (value) {
                            if (prop.endsWith('Location') && Site.isSite(value)) {
                                res[prop] = value.SiteCode as never;
                            } else if (prop.endsWith('Date') && isValidDate(value)) {
                                res[prop] = format(value, 'yyyy-MM-dd') as never;
                            } else if (prop.endsWith('Time') && isValidDate(value)) {
                                res[prop] = format(value, 'HHmm') as never;
                            } else {
                                res[prop] = value as never;
                            }
                        }
                    }
                    return res;
                },

                {} as Partial<SearchFormValues>
            );
            return createSearchParams(convertedValues as Record<string, string>).toString();
        }
        return null;
    }

    async performSearch() {
        let result = null;
        if (this.isValid) {
            this.updateAvailabilityStore();
            result = await AvailabilityStore.getAvailability();
        }
        return result;
    }

    async performSearchV3(reservationReference?: string) {
        let result = null;
        if (this.isValid) {
            this.updateAvailabilityStore();
            result = await AvailabilityStore.getAvailabilityV3(reservationReference);
        }
        return result;
    }

    updateAvailabilityStore() {
        AvailabilityStore.setValues({
            pickUpLocation: this.pickUpLocation,
            dropOffLocation: this.dropOffLocation,
            pickUpDate: this.pickUpDate,
            pickUpTime: this.pickUpTime,
            dropOffDate: this.dropOffDate,
            dropOffTime: this.dropOffTime,
            driverAge: this.driverAge,
            fleetType: this.fleetType,
            promoCode: this.promoCode,
            selectedDeal: this.selectedDeal,
        });
    }

    /**
     * @deprecated Use {@link RentalTripSearchStore.init} instead.
     */
    reset() {
        this.init();
    }

    asSearchUid(): string {
        return toSearchUid({
            pickUpLocation: this.pickUpLocation?.SiteCode,
            dropOffLocation: this.dropOffLocation?.SiteCode,
            pickUpDate: this.pickUpDateTime?.toISOString(),
            dropOffDate: this.dropOffDateTime?.toISOString(),
            fleetCategory: this.fleetCategory,
            driverAge: this.driverAge ? Number(this.driverAge) : undefined,
            campaignCode: this.promoCode,
        });
    }

    get tripInfo(): TripInfo {
        return {
            pickUpLocation: this.pickUpLocation,
            dropOffLocation: this.dropOffLocation,
            pickUpDate: this.pickUpDateTime,
            dropOffDate: this.dropOffDateTime,
            driverAge: this.driverAge,
            campaignCode: this.promoCode,
        };
    }
}

export default new RentalTripSearchStore();
