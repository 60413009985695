'use client';

import React, { useMemo } from 'react';
import { GalleryItem, GalleryItemType } from '@jucy-ui/common';
import { styled } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { AspectRatio } from '../../../../AspectRatio';
import { JucySlider } from '../../../../JucySlider';
import { FLEET_CAT_IMG_RATIO } from '../lib';
import { FleetCategoryTeaserCardOwnerState } from '../types';


export const FleetCategoryTeaserCarousel: React.FC<{ ownerState: FleetCategoryTeaserCardOwnerState }> = ({ ownerState }) => {
    const { catalogItem, fleetCategory } = ownerState;
    const items: GalleryItem[] = useMemo(
        () =>
            [
                {
                    originalAlt: catalogItem.name || fleetCategory.name,
                    original: catalogItem.scenicImage || '',
                    thumbnail: catalogItem.scenicImage || '',
                    type: 'Image' as GalleryItemType,
                    carousel: true,
                },
                ...catalogItem.gallery.filter(
                    (item) => item.type.toLowerCase() === 'image' && item.carousel && item.original && catalogItem.scenicImage !== item.original
                ),
            ].slice(0, 5),
        [catalogItem.gallery, catalogItem.name, catalogItem.scenicImage,fleetCategory.name]
    );
    return (
        <TeaserCarouselRoot ownerState={ownerState}>
            {items.length > 1 ? (
                <JucySlider
                    showDots={true}
                    showNav={true}
                    config={{all: {slidesToShow: 1}}}
                    componentProps={{
                        nav: {
                            sx: {
                                zIndex: 1,
                            },
                        },
                    }}
                >
                    {items.map((item, i) => (
                        <AspectRatio ratio={FLEET_CAT_IMG_RATIO} key={`${item.original}-${i}`}>
                            <CardMedia
                                component="img"
                                sx={{ borderRadius: 1 }}
                                image={item.thumbnail || item.original}
                                alt={item.originalAlt || catalogItem.name || fleetCategory.name}
                            />
                        </AspectRatio>
                    ))}
                </JucySlider>
            ) : (
                <AspectRatio ratio={FLEET_CAT_IMG_RATIO} sx={{ mb: 1 }}>
                    <CardMedia component="img" sx={{ borderRadius: 1 }} image={catalogItem.scenicImage} alt={catalogItem.name} />
                </AspectRatio>
            )}
        </TeaserCarouselRoot>
    );
};
const TeaserCarouselRoot = styled('div', {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'carousel',
})<{ ownerState: FleetCategoryTeaserCardOwnerState }>(() => ({}));
