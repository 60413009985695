import { useRouter } from 'next/router';
import { Brand, stringToBrand } from '../lib/brand';

export const useTryFindBrand = (currentValue: unknown): Brand | undefined => {
    const router = useRouter();
    const brandFromCurrentValue = stringToBrand(currentValue);
    if (brandFromCurrentValue) {
        return brandFromCurrentValue;
    }
    const brandFromHtml = typeof window !== 'undefined' ? stringToBrand(window.document.querySelector('html')?.dataset.brand) : null;
    if (brandFromHtml) {
        return brandFromHtml;
    }
    const firstPath = router?.asPath?.split('/')[1] || router?.pathname?.split('/')[1];
    const brandFromRouter = stringToBrand(firstPath);
    if (brandFromRouter) {
        return brandFromRouter;
    }
    return;
};
