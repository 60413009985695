import { useEffect } from 'react';
import BlazeSlider from 'blaze-slider';

export const useWatchSlideHeight = ({ slider, adaptiveHeight }: { slider?: BlazeSlider; adaptiveHeight?: boolean }) => {
    useEffect(() => {
        let unsubscribe = () => true;
        if (slider && adaptiveHeight) {
            unsubscribe = slider.onSlide(() => {
                resizeToCurrentSlide(slider);
            });
        }
        return () => {
            unsubscribe();
        };
    }, [adaptiveHeight, slider]);
};

const resizeToCurrentSlide = (slider: BlazeSlider) => {
    const container = slider.el;
    const heightContainer = container?.querySelector('.track-nav-container') as HTMLElement;
    const track = container?.querySelector('.blaze-track') as HTMLElement;
    const slide = track?.children?.[0] as HTMLElement;
    if (track && heightContainer && slide) {
        heightContainer.style.height = `${slide.clientHeight}px`;
        track.style.height = `${slide.clientHeight}px`;
    }
};
