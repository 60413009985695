'use client';
import React from 'react';
import { getCurrencyComponents } from '@jucy-ui/common';
import { CurrencyValue } from '@jucy-ui/common/services/models/CurrencyValue';
import { TypographyProps } from '@mui/material';
import Typography from '@mui/material/Typography';

export type CurrencyComponentsProps = TypographyProps & {
    value: CurrencyValue;
    showCurrencyCode?: boolean;
    unit?: string;
};
export const CurrencyComponents: React.FC<CurrencyComponentsProps> = ({ value, showCurrencyCode, unit, ...props }) => {
    const valueComponents = getCurrencyComponents(value);
    return (
        <Typography {...props}>
            {showCurrencyCode ? `${value.currencyCode} ` : null}
            {valueComponents.map(({ type, value }, i) => {
                switch (type) {
                    case 'currency':
                        return value.replace(/^(-?)[A-Z]+/, '$1');
                    case 'decimal':
                    case 'fraction':
                        return (
                            <Typography key={`${value}-${i}`} component="span" fontSize="small" fontWeight="700">
                                {value}
                            </Typography>
                        );
                    default:
                        return value;
                }
            })}
            {unit ? (
                <Typography component="span" fontSize="smaller" fontWeight="700">
                    /{unit}
                </Typography>
            ) : null}
        </Typography>
    );
};
