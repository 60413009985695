'use client';
import { RefObject, useEffect, useState } from 'react';

export const useIsAutoFilled = <Instance extends HTMLElement>(ref: RefObject<Instance | undefined>) => {
    const [isAutoFilled, setIsAutoFilled] = useState(false);
    useEffect(() => {
        const element = ref.current;
        if (!element) {
            return;
        }

        const handleAutocomplete = (e: AnimationEvent) => {
            if (e.animationName === 'mui-auto-fill') {
                element.dataset.isAutoFilled = 'true';
                setIsAutoFilled(true);
            }
            if (e.animationName === 'mui-auto-fill-cancel') {
                element.dataset.isAutoFilled = 'false';
                setIsAutoFilled(false);
            }
        };
        if (element.dataset.hasCountrySelectAutocomplete !== 'true') {
            element.addEventListener('animationstart', handleAutocomplete);
            element.dataset.hasCountrySelectAutocomplete = 'true';
        }
        return () => {
            element.removeEventListener('animationstart', handleAutocomplete);
            element.dataset.hasCountrySelectAutocomplete = 'false';
        };
    }, [ref]);

    return isAutoFilled;
};
