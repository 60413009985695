'use client';
import React, { type SyntheticEvent, useEffect, useRef, useState } from 'react';
import { CountryData, augmentEventValue, createSyntheticEvent, useGetCountries } from '@jucy-ui/common';
import { useForkRef } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { TextFieldVariants } from '@mui/material/TextField/TextField';
import { parseDigit } from 'input-format';
import { AsYouType, CountryCode, PhoneNumber } from 'libphonenumber-js';
import { escapeRegExp } from 'lodash';
import { FormatInput, FormatInputProps } from '../../../FormatInput';
import { LoadingContainer } from '../../../LoadingContainer';
import { PhoneNumberInputProvider } from './PhoneInputContext';
import { PhoneNumberInputComponent } from './PhoneNumberInputComponent';

export type PhoneNumberInputProps<Variant extends TextFieldVariants = TextFieldVariants> = Omit<
    TextFieldProps<Variant>,
    'onChange' | 'ref' | 'value'
> &
    Omit<FormatInputProps, 'onChange' | 'parse' | 'format' | 'inputComponent' | 'value'> & {
        onChange?: (
            event: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<HTMLInputElement, Event>,
            data?: { value?: string; country?: CountryData; phoneNumber?: PhoneNumber }
        ) => void;
        defaultCountryCode?: string;
        value?: string;
    };

const getTextTemplate = (value?: string, country?: CountryData | null) => {
    let text;
    let template;
    try {
        const asYouType = getAsYouType(value, country);
        asYouType.reset();
        const inputResult = asYouType.input(value || '');
        text = asYouType.getNumber()?.formatInternational({ v2: true }) || inputResult;
        template = asYouType.getTemplate();
    } catch (error) {
        if (error instanceof Error && error.message.indexOf('Unknown country') === 0) {
            text = value;
            template = value?.replace(/./g, 'x');
        } else {
            throw error;
        }
    }
    return { text: text || '', template: template || '' };
};

const getAsYouType = (value?: string, country?: CountryData | null) => {
    const asYouType = new AsYouType(country?.code as CountryCode);
    asYouType.input(value || '');
    return asYouType;
};

export const PhoneNumberInput = React.forwardRef<HTMLInputElement, PhoneNumberInputProps>((props: PhoneNumberInputProps, ref) => {
    const { data: countries, isLoading } = useGetCountries();
    return (
        <LoadingContainer isLoading={isLoading}>
            {countries?.length ? <PhoneNumberComponent ref={ref} countries={countries} {...props} /> : null}
        </LoadingContainer>
    );
});
PhoneNumberInput.displayName = 'PhoneNumberInput';

export const PhoneNumberComponent = React.forwardRef<HTMLInputElement, PhoneNumberInputProps & { countries: CountryData[] }>(
    ({ onChange, defaultCountryCode, value: passedValued, countries, ...props }: PhoneNumberInputProps & { countries: CountryData[] }, ref) => {
        const [selectedCountry, setSelectedCountry] = useState<CountryData | null>(null);
        const [value, setValue] = useState(passedValued);
        const internalRef = useRef<HTMLInputElement>();

        const guessCountryForInput = (inputValue: string) => {
            const stripped = inputValue.replace(/^\+/, '').replace(/^00/, '') || '';
            return countries.find((c) => {
                const len = c.phonePrefix.length;
                return stripped.substring(0, len) === c.phonePrefix;
            });
        };
        const triggerOnChange = (inputValue?: string, country?: CountryData | null) => {
            const asYouType = getAsYouType(inputValue, country);
            const formatted = asYouType.getNumber()?.formatInternational({ v2: true }) || inputValue || '';
            const event = augmentEventValue(createSyntheticEvent(new Event('change', { bubbles: true })), formatted);
            onChange?.(event as SyntheticEvent<HTMLInputElement, Event>, {
                value: formatted,
                country: country || undefined,
                phoneNumber: asYouType.getNumber() || undefined,
            });
        };
        const handleSelectedCountryChange = (country: CountryData | null) => {
            const newPrefix = country?.phonePrefix ? `+${country.phonePrefix}` : '';
            const oldPrefix = selectedCountry?.phonePrefix ? `+${selectedCountry.phonePrefix}` : '';
            let inputValue = value;
            if (newPrefix && oldPrefix) {
                const oldPrefixRegExp = new RegExp(`^${escapeRegExp(oldPrefix)}`);
                inputValue = value?.replace(oldPrefixRegExp, newPrefix);
                setValue(inputValue);
            }
            setSelectedCountry(country);
            triggerOnChange(inputValue, country);
        };
        const handleInputValueChange = (inputValue: string) => {
            const newCountry = guessCountryForInput(inputValue);
            if (newCountry && (!selectedCountry || selectedCountry.code !== newCountry.code)) {
                setSelectedCountry(newCountry);
            }
            setValue(inputValue);
            triggerOnChange(inputValue, newCountry || selectedCountry);
        };
        const [isDefaultCountrySet, setIsDefaultCountrySet] = useState(false);
        useEffect(() => {
            if (!defaultCountryCode || isDefaultCountrySet || selectedCountry !== null) {
                return;
            }
            const defaultCountry = countries.find((c) => c.code.toLowerCase() === defaultCountryCode.toLowerCase());
            if (defaultCountry) {
                setSelectedCountry(defaultCountry);
            }
            setIsDefaultCountrySet(true);
        }, [countries, defaultCountryCode, isDefaultCountrySet, selectedCountry]);

        return (
            <PhoneNumberInputProvider selectedCountry={selectedCountry} setSelectedCountry={handleSelectedCountryChange}>
                <FormatInput
                    ref={useForkRef(ref, internalRef)}
                    {...props}
                    inputComponent={PhoneNumberInputComponent as FormatInputProps['inputComponent']}
                    value={value}
                    onChange={(event) => {
                        handleInputValueChange((typeof event === 'string' ? event : event?.target.value) || '');
                    }}
                    parse={(character, value) => {
                        // Leading plus is allowed
                        if (character === '+') {
                            if (!value) {
                                return character;
                            }
                        }
                        return parseDigit(character);
                    }}
                    format={(value) => getTextTemplate(value, selectedCountry)}
                />
            </PhoneNumberInputProvider>
        );
    }
);
PhoneNumberComponent.displayName = 'PhoneNumberComponent';
