'use client';
import { batch } from '@preact/signals-react';
import { deepSignal } from 'deepsignal/react';
import React, { useEffect, useRef } from 'react';
import { FormSchemaProvideProps, FormSchemaProvider } from '@jucy-ui/forms';
import { SearchFormOptions, SearchFormStore, SearchFormValues, searchFormKeys } from '../types';
import { SearchFormContext } from './SearchFormContext';
import { SearchFormDataProvider } from './SearchFormDataProvider';

export const SearchFormProvider = ({
    children,
    options,
    ...props
}: FormSchemaProvideProps<SearchFormValues> & {
    children: React.ReactNode;
    options?: SearchFormOptions;
    error?: { message?: React.ReactNode; title?: React.ReactNode };
}) => {
    const storeRef = useRef(
        deepSignal<SearchFormStore>({
            ...options,
            variant: options?.variant || 'large',
            values: {},
        })
    );
    useEffect(() => {
        const changed =
            options?.searchButtonLabel !== storeRef.current.searchButtonLabel ||
            options?.disabled !== storeRef.current.disabled ||
            options?.variant !== storeRef.current.variant ||
            options?.isSubmitting !== storeRef.current.isSubmitting ||
            searchFormKeys.some((key) => storeRef.current.hiddenFields?.[key] !== options?.hiddenFields?.[key]);
        if (changed) {
            batch(() => {
                storeRef.current.searchButtonLabel = options?.searchButtonLabel;
                storeRef.current.disabled = options?.disabled;
                storeRef.current.hiddenFields = options?.hiddenFields;
                storeRef.current.isSubmitting = options?.isSubmitting;
                storeRef.current.variant = options?.variant || 'large';
            });
        }
    }, [options]);
    return (
        <SearchFormDataProvider>
            <FormSchemaProvider {...props}>
                <SearchFormContext.Provider value={storeRef.current}>
                  {children}
                </SearchFormContext.Provider>
            </FormSchemaProvider>
        </SearchFormDataProvider>
    );
};
