import { Breakpoint, useTheme } from '@mui/material';
import { BlazeConfig } from 'blaze-slider';
import { JucySliderConfig } from './JucySliderConfig';

export const useMapConfigToBlazeConfig = (config?: JucySliderConfig) => {
    const theme = useTheme();
    return config
        ? Object.entries(config).reduce((res, [muiBreakpoint, value]) => {
              const blazeBreakpoint =
                  muiBreakpoint === 'all'
                      ? 'all'
                      : theme.breakpoints
                            .only(muiBreakpoint as Breakpoint)
                            .replace('@media ', '')
                            .trim();
              return {
                  ...res,
                  [blazeBreakpoint]: {
                      ...value,
                  },
              };
          }, {} as BlazeConfig)
        : undefined;
};
