import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { isValidDate } from './dates';

export const stripEmptyProps = <T>(value: T): Partial<T> =>
    omitBy(value as unknown as object, (v) =>
        typeof v === 'object' && (v as Date) instanceof Date ? !isValidDate(v) : Boolean(isUndefined(v) || isNull(v)) || isEmpty(v)
    ) as unknown as Partial<T>;
