'use client';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

export const JucyMuiTabs = styled(Tabs, { name: 'JucyTab', slot: 'root' })(({ theme }) => ({
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    '& .MuiTabs-indicator': {
        height: 4,
    },
}));
