'use client';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {jucySearchFormClasses} from '../../../SearchForm/lib/jucySearchFormClasses';

export const TripFieldsSeparatorArrow: React.FC<{ variant?: 'horizontal' | 'vertical' | 'wide' }> = () => (
    <ArrowForwardIcon
        className={jucySearchFormClasses.dateTimeArrow}
        sx={{
            color: 'rgba(0, 0, 0, 0.23)',
            fontSize: 22,
            position: 'absolute',
        }}
    />
);
