import 'reflect-metadata';
import { BookingSummaryFromJSON } from '@jucy/rentals-api-client/rentals-api';
import { plainToInstance } from 'class-transformer';
import superagent from 'superagent';
import config from '../config';
import AccountKeyStore from '../store/AccountKeyStore';
import { Deal } from './api/model/Deal';
import { getPaymentCallbackUrl } from './payments';

let accountKey = null;
class JucyAPI {
    responseTypes = {
        success: 'Success',
        error: 'Error',
        validationError: 'Validation Error',
        unexpectedError: 'Unexpected Error',
    };

    isErrorResponse(type) {
        const { error, validationError, unexpectedError } = this.responseTypes;
        return [error, validationError, unexpectedError].includes(type);
    }

    getQuoteProductPricing({ ref, priceRequest }) {
        const url = new URL(`/api/rental-reservations/ref/${ref}/pricing-enquiries`, config.apiBaseUrl);
        url.searchParams.append('accountId', this.getAccountKey());
        return superagent.post(url.href).send(priceRequest);
    }

    getAccountDetails() {
        const url = new URL(`/api/Account/${this.getAccountKey()}/1.0/GetAccountDetails`, config.apiBaseUrl);
        return superagent.post(url.href).then((res) => res.body);
    }

    getSites(country) {
        const url = new URL('api/Sites/1.0/GetSites', config.apiBaseUrl);
        url.searchParams.append('country', country);

        return superagent
            .get(url.href)
            .then((res) => res.body)
            .then((r) => ({
                ...r,
            }));
    }

    getSitesSettings() {
        const url = new URL('api/rental-sites/settings', config.apiBaseUrl);
        return superagent.get(url.href).then((res) => res.body);
    }

    getAvailability(data) {
        const url = new URL(`api/Account/${this.getAccountKey()}/2.0/GetFleetTypeCategories`, config.apiBaseUrl);
        Object.entries(data).forEach(([key, value]) => value && url.searchParams.append(key, value));
        return superagent.post(url.href);
    }

    confirmQuote(data) {
        const url = new URL(`api/Account/${this.getAccountKey()}/1.0/ConfirmQuote`, config.apiBaseUrl);
        Object.entries(data).forEach(([key, value]) => value && url.searchParams.append(key, value));
        return superagent.post(url.href).then((res) => res.body);
    }

    confirmReservation(quoteId, data) {
        const url = new URL(`api/rental-reservations/${quoteId}/confirm`, config.apiBaseUrl);
        url.searchParams.append('accountId', this.getAccountKey());

        return superagent.post(url.href, data).then((res) => res.body);
    }

    emailQuote(data) {
        const url = new URL(`api/Account/${this.getAccountKey()}/1.0/EmailQuote`, config.apiBaseUrl);
        Object.entries(data).forEach(([key, value]) => value && url.searchParams.append(key, value));

        return superagent.post(url.href).then((res) => res.body);
    }

    emailReservationReminder(data) {
        const url = new URL(`api/Account/${this.getAccountKey()}/1.0/EmailReservationReminder`, config.apiBaseUrl);
        Object.entries(data).forEach(([key, value]) => value && url.searchParams.append(key, value));

        return superagent.post(url.href).then((res) => res.body);
    }

    emailQuoteWeb(data) {
        const url = new URL(`api/Account/${this.getAccountKey()}/1.0/EmailQuoteWeb`, config.apiBaseUrl);
        Object.entries(data).forEach(([key, value]) => value && url.searchParams.append(key, value));
        return superagent.post(url.href).then((res) => res.body);
    }

    /**
     * @param data
     * @param {OBEUser} user
     * @returns {*}
     */
    createQuote(data, user) {
        const accountKey = user?.type === 'staff' && user?.agentUser?.accountKey ? user.agentUser.accountKey : this.getAccountKey();
        const url = new URL(`api/Account/${accountKey}/2.0/CreateQuote`, config.apiBaseUrl);
        Object.entries(data).forEach(([key, value]) => value && url.searchParams.append(key, value));
        const request = superagent.post(url.href);
        if (user?.token?.accessToken) {
            request.set('x-operator-access-token', user.token.accessToken);
        }
        return request.then((res) => res.body);
    }

    updateQuote(data) {
        const { secondaryProductsAndInsuranceMap, ...query } = data;
        const formattedQuery = {
            ...query,
            secondaryProductsAndInsuranceMap: JSON.stringify(secondaryProductsAndInsuranceMap || []),
        };
        const url = new URL(`api/Account/${this.getAccountKey()}/2.0/UpdateQuote`, config.apiBaseUrl);
        Object.entries(formattedQuery).forEach(([key, value]) => value && url.searchParams.append(key, value));
        return superagent.post(url.href).then((res) => res.body);
    }

    updateReservation(quoteId, data) {
        const url = new URL(`api/rental-reservations/${quoteId}/check-in`, config.apiBaseUrl);
        url.searchParams.append('accountId', this.getAccountKey());
        return superagent.post(url.href, data).then((res) => res.body);
    }

    resolvePaymentURL({ quote, isDeposit, trackingId, authOnly, gateway }) {
        const successParams = {
            ref: quote.ReservationReference,
            _ga: trackingId,
        };
        const parsedPaymentUrl = new URL(quote.PaymentURL, config.apiBaseUrl);
        parsedPaymentUrl.searchParams.set('returnUrl', getPaymentCallbackUrl({ ...successParams, success: 'true' }));
        parsedPaymentUrl.searchParams.set('failedUrl', getPaymentCallbackUrl({ ...successParams, success: 'false' }));
        parsedPaymentUrl.searchParams.set('deposit', isDeposit ? 'true' : 'false');
        parsedPaymentUrl.searchParams.set('authOnly', authOnly ? 'true' : 'false');
        if (gateway) {
            parsedPaymentUrl.searchParams.set('gateway', gateway);
        }

        return parsedPaymentUrl;
    }

    resolveReservationPaymentURL({ quote, trackingId }) {
        const successUrl = new URL('/obe/direct/booking-confirmed', window.location.origin);
        successUrl.searchParams.append('reservationReference', quote.ReservationReference);
        successUrl.searchParams.append('lastName', quote.HirerDetails.LastName);
        successUrl.searchParams.append('callback', 'true');
        successUrl.searchParams.append('_ga', trackingId);

        const url = new URL('/api/DPS/1.0/ProcessPurchase', config.apiBaseUrl);
        url.searchParams.append('_ga', quote.ReservationId);
        url.searchParams.append('returnUrl', successUrl.href);
        url.searchParams.append('failedUrl', this.buildPaymentFailureUrl().href);

        return url;
    }

    buildPaymentFailureUrl(tabPaymentPage = false) {
        const url = new URL('/obe/direct/payment-failure', config.apiBaseUrl);
        url.searchParams.append('tabPaymentPage', tabPaymentPage ? 'true' : 'false');
        return url.href;
    }

    getReservation(ref, lastName, country, resume, user) {
        const url = new URL(`api/rental-reservations/ref/${ref}`, config.apiBaseUrl);
        url.searchParams.append('accountId', this.getAccountKey());
        if (lastName) {
            url.searchParams.append('lastName', lastName);
        }
        if (country) {
            url.searchParams.append('country', country);
        }
        if (resume) {
            url.searchParams.append('resume', resume);
        }
        const request = superagent.get(url.href);
        if (user?.token?.accessToken) {
            request.set('x-operator-access-token', user.token.accessToken);
        }
        return request.then((res) => res.body);
    }

    getCurrencyRate() {
        const url = new URL('/api/v3/data/get-currency-rate', config.apiBaseUrl);
        return superagent.get(url.href).then((res) => res.body);
    }

    listReservations() {
        const url = new URL('api/rental-reservations', config.apiBaseUrl);
        url.searchParams.append('accountId', this.getAccountKey());
        return superagent
            .get(url.href)
            .then((res) => res.body)
            .then((response) => ({
                ...response,
                items: response.items.map((s) => BookingSummaryFromJSON(s)),
            }));
    }

    cancelReservation(data) {
        const url = new URL(`api/Account/${this.getAccountKey()}/1.0/CancelReservation`, config.apiBaseUrl);
        Object.entries(data).forEach(([key, value]) => value && url.searchParams.append(key, value));
        return superagent.post(url.href).then((res) => res.body);
    }

    getAccountKey() {
        if (!accountKey) {
            accountKey = AccountKeyStore.accountKey;
        }
        return accountKey || '';
    }

    setAccountKey(key) {
        accountKey = key;
    }

    getRentalCatalog({ extended } = {}) {
        const url = new URL('api/rental-catalog', config.apiBaseUrl);
        if (extended) {
            url.searchParams.append('extended', 'true');
        }
        return superagent.get(url.href).then((res) => res.body);
    }

    status() {
        const url = new URL('api/status', config.apiBaseUrl);
        return superagent.get(url.href).then((res) => res.body);
    }

    getDeal(id) {
        const url = new URL(`api/deals/${id}`, config.apiBaseUrl);
        url.searchParams.append('accountId', this.getAccountKey());
        return superagent
            .get(url.href)
            .then((res) => res.body)
            .then((d) => plainToInstance(Deal, d));
    }

    isAgentEmail(email) {
        const url = new URL(`/api/v3/agent/verify?email=${email}`, config.apiBaseUrl);
        return superagent.get(url.href).then((res) => res.body.isAgentEmail);
    }

    getToken(reservationRef, deposit) {
        const url = new URL(`/api/v3/payment/token?reservationRef=${reservationRef}&type=${deposit ? 'deposit' : 'full'}`, config.apiBaseUrl);
        return superagent.get(url.href).then((res) => res.body.token);
    }
}

// Exported as a singleton so we can implement things like
// global caching and request batching at some stage.
export default new JucyAPI();
