import { Expose, Transform, Type } from 'class-transformer';
import { parseISO } from 'date-fns';
import { Holiday } from './Holiday';

export class SiteHoliday extends Holiday {
    name = '';

    @Type(() => Date)
    @Transform(({ value }) => (value ? parseISO(value) : undefined))
    @Expose({ name: 'startDateTime' })
    declare start?: Date;

    @Type(() => Date)
    @Transform(({ value }) => (value ? parseISO(value) : undefined))
    @Expose({ name: 'endDateTime' })
    declare end?: Date;

    @Expose({ name: 'dOW' })
    declare dow?: number;
}
