import { Breakpoint } from '@mui/material';

export function mapBreakPoints<InType, OutType>(
    input: Record<Breakpoint, InType>,
    cb: (value: InType, breakPoint: Breakpoint) => OutType
): Record<Breakpoint, OutType>;
export function mapBreakPoints<InType, OutType>(input: undefined, cb: (value: InType, breakPoint: Breakpoint) => OutType): undefined;
// eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
export function mapBreakPoints<InType, OutType>(
    input: Record<Breakpoint, InType> | undefined,
    cb: (value: InType, breakPoint: Breakpoint) => OutType
) {
    return input
        ? Object.entries(input).reduce(
              (res, [breakPoint, value]) => ({
                  ...res,
                  [breakPoint as Breakpoint]: cb(value, breakPoint as Breakpoint),
              }),
              {} as Record<Breakpoint, OutType>
          )
        : undefined;
}
