import Image from 'next/image';
import * as Laybuy from '../../../lib/Laybuy';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import LaybuyLightbox from '../../PaymentProviders/Laybuy/LaybuyLightbox';
import { PaymentOption } from '../lib';
import GetPaymentMethodFn from './GetPaymentMethodFn';

const getLaybuyPaymentOption: GetPaymentMethodFn = ({ summary, options }): PaymentOption => ({
    id: 'laybuy',
    name: <Image src={Laybuy.logo} alt="Laybuy logo" width={118} height={27} />,
    amountInfo: <>6 weekly interest-free payments from</>,
    total: { value: summary.totalPrice?.value, currencyCode: summary.totalPrice?.currencyCode },
    subTotal: { value: summary.totalPrice?.value, currencyCode: summary.totalPrice?.currencyCode },
    amount: <CurrencyFormatter price={Laybuy.weeklyPayments(summary.totalPrice)} prefixCurrencyCode={true} />,
    modalBody: <LaybuyLightbox />,
    gateway: 'laybuy',
    isRedirect: true,
    available: ({ summary }) => Laybuy.enabled(summary),
    ...options,
});
export default getLaybuyPaymentOption;
