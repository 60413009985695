import { useBrand } from '#/store/BrandStore';

export const CancellationTermsLink = ({ countryCode }: { countryCode?: string }) => {
    const brandStore = useBrand();
    return (
        <>
            <span className="fa-li">
                <i className="fas fa-check text-green" />
            </span>{' '}
            <a href={brandStore.termsPageUrl({ countryCode })} target="_blank" rel="noopener noreferrer">
                Click here
            </a>{' '}
            for full cancellation policy
        </>
    );
};
