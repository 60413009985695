const round = (amount: number) => Math.round((amount + Number.EPSILON) * 100) / 100;

export class GSTCalculator {
    static addGST(net: number, salesTaxPercentage: number): number {
        return net && salesTaxPercentage ? round(net * (salesTaxPercentage / 100 + 1)) : net;
    }

    static removeGST(gross: number, salesTaxPercentage: number): number {
        return gross && salesTaxPercentage ? round(gross / (salesTaxPercentage / 100 + 1)) : 0;
    }

    static getGSTFromNet(net: number, salesTaxPercentage: number): number {
        return net && salesTaxPercentage ? round(net * (salesTaxPercentage / 100)) : net;
    }

    static getGSTFromGross(gross: number, salesTaxPercentage: number): number {
        return gross && salesTaxPercentage ? round(gross - gross / (1 + salesTaxPercentage / 100)) : gross;
    }
}
