import React from 'react';
import { Typography } from '@mui/material';
import dynamic from 'next/dynamic';
import { useTeaserContext } from '../TeaserContext';
import { FleetTypeSlug } from '@jucy/rentals-common';

const TeaserCarousel = dynamic(() => import('./TeaserCarousel'), { ssr: false, suspense: false });

const FleetCategoryDescription: React.FC = () => {
    const { fleetCategory, fleetType } = useTeaserContext();
    return (
        <>
            <TeaserCarousel />
            <Typography component="div" fontWeight="700" fontSize="18px">
                {fleetCategory.Name}
            </Typography>
            <Typography variant="caption" color="text.secondary" fontSize="14px" component="div">
                {fleetCategory.product.vehicleDescription}
            </Typography>
            {fleetType === FleetTypeSlug.motorhome ? (
                <Typography variant="caption" color="text.secondary" fontSize="9px" component="small">
                    *Vehicle decals vary
                </Typography>
            ) : null}
        </>
    );
};
export default FleetCategoryDescription;
