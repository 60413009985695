import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { JucyCountryCode } from '@jucy/rentals-common';
import { Grid } from '@mui/material';
import * as Afterpay from '../../../lib/Afterpay';
import * as Laybuy from '../../../lib/Laybuy';
import { Site } from '../../../lib/api/model/Site';
import paymentStore from '../../../store/PaymentStore';
import PayWithAfterpay from '../../PaymentProviders/Afterpay/PayWithAfterpay';
import PayWithLaybuy from '../../PaymentProviders/Laybuy/PayWithLaybuy';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';

const BuyNowPayLaterSummary: React.FC = observer(() => {
    const context = useBookingSummaryContext();
    const laybuyAvailable = useMemo(() => context.summary.fleetCategory && Laybuy.enabled(context.summary), [context.summary]);
    const appStorePaymentConfig = paymentStore.getConfig((context.summary.pickUpLocation as Site)?.CountryCode);
    const afterpayAvailable = useMemo(
        () => context.summary.fleetCategory && Afterpay.enabled(context.summary) && Afterpay.available(context.summary.totalPrice, appStorePaymentConfig),
        [context.summary, appStorePaymentConfig]
    );
    return (
        <BookingSummaryGroupItem sx={{ py: 2, px: 1 }}>
            {laybuyAvailable ? (
                <Grid container spacing={0} marginBottom={1}>
                    <Grid item xs={12}>
                        <PayWithLaybuy className="small mt-1" showWhatsThis={true} amount={context.summary.totalPrice} />
                    </Grid>
                </Grid>
            ) : null}
            {afterpayAvailable && context.summary.pickUpLocation ? (
                <Grid container spacing={0} marginBottom={1}>
                    <Grid item xs={12}>
                        <PayWithAfterpay
                            countryCode={context.summary.pickUpLocation.CountryCode as JucyCountryCode}
                            className="small mt-1"
                            showWhatsThis={true}
                            amount={context.summary.totalPrice}
                        />
                    </Grid>
                </Grid>
            ) : null}
        </BookingSummaryGroupItem>
    );
});
BuyNowPayLaterSummary.displayName = 'BuyNowPayLaterSummary';
export default BuyNowPayLaterSummary;
