'use client';
import React from 'react';
import { useFormState } from '@jucy-ui/forms';
import { AlertProps } from '@mui/material';
import Alert from '@mui/material/Alert';

export interface SearchFormErrorMessageProps extends Omit<AlertProps, 'severity' | 'children'> {}

export const SearchFormErrorMessage: React.FC<SearchFormErrorMessageProps> = ({ ...props }) => {
    const form = useFormState();
    const submitError = typeof form.errors.root?.submit?.message === 'string' ? form.errors.root.submit.message : undefined;
    const colour = form.errors.submit?.type === 'warning' ? 'warning' : 'error';
    return submitError ? (
        <Alert severity={colour} variant="outlined" {...props}>
            {submitError}
        </Alert>
    ) : null;
};
