import { ThemeOptions } from '@mui/material';
import { ThemeColorOptions } from '@mui/material/styles';
import { deepmerge } from 'deepmerge-ts';
import { jucyMuiThemeOptions, starMuiThemeOptions } from '../brandPalette';
import { createDefaultThemeOptions } from '../lib';
import { PartialDeep } from 'type-fest';

export const createThemeOptionsForBrand = (brand: 'star' | 'jucy' = 'jucy', themeOptions?: PartialDeep<ThemeOptions>): ThemeOptions => {
    const brandOptions = brand === 'jucy' ? jucyMuiThemeOptions : starMuiThemeOptions;
    return deepmerge(createDefaultThemeOptions(brandOptions.colors as ThemeColorOptions), brandOptions, themeOptions as ThemeOptions || {});
};
