'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { FormControlLabelProps, styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useRadioGroup } from '@mui/material/RadioGroup';
import { JucyRadioControlLabelButton } from '../../../JucyRadioControlLabelButton';
import type { PaymentType } from '../lib';
import { PaymentTypeRadioControl } from './PaymentTypeRadioControl';

interface PaymentTypeRadioControlLabelProps extends Omit<FormControlLabelProps, 'control' | 'value'> {
    paymentType: PaymentType;
    error?: boolean;
}

export const PaymentTypeRadioControlLabel: React.FC<PaymentTypeRadioControlLabelProps> = ({ paymentType, label, disabled, sx, error, ...props }) => {
    const radioGroup = useRadioGroup();
    const checked = radioGroup ? radioGroup.value === paymentType.id : false;
    return (
        <JucyPaymentTypeRadioControlLabelButton
            component="div"
            ownerState={{ checked: checked, disabled, error }}
            disabled={disabled}
            variant="outlined"
        >
            <FormControlLabel
                checked={checked}
                disableTypography={false}
                labelPlacement="end"
                className="payment-type-label"
                control={<PaymentTypeRadioControl disabled={disabled} paymentType={paymentType} />}
                value={paymentType.id}
                sx={mergeSx(
                    {
                        height: '100%',
                        width: '100%',
                        display: 'block',
                        marginLeft: 0,
                        marginRight: 0,
                    },
                    sx
                )}
                label={undefined}
                disabled={disabled}
                {...props}
            />
        </JucyPaymentTypeRadioControlLabelButton>
    );
};

const JucyPaymentTypeRadioControlLabelButton = styled(JucyRadioControlLabelButton, {
    name: 'JucyPaymentTypeRadioControlLabel',
    slot: 'Root',
})<{ ownerState?: { checked?: boolean; error?: boolean; disabled?: boolean } }>(({ theme }) => ({
    width: '100%',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    [theme.breakpoints.up('xs')]: {
        height: 120,
    },
    [theme.breakpoints.up('sm')]: {
        height: 120,
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '13px',
        height: 100,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '13px',
        height: 120,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '14px',
        height: 120,
    },
}));
