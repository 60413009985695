import 'reflect-metadata';
import { QueryClient } from '@tanstack/react-query';
import { jucyAttribution } from '#/lib/SalesMonitoring/jucyAttribution';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { configure } from 'mobx';
import './initDatadog';

jucyAttribution.init();
configure({ enforceActions: 'never', disableErrorBoundaries: false });

export const queryClient = new QueryClient();

export const msalInstance = new PublicClientApplication({
    auth: {
        clientId: typeof window === 'undefined' ? '' : window.jucy.config.azure?.clientId || '',
        authority: typeof window === 'undefined' ? '' : window.jucy.config.azure?.authority,
        redirectUri: typeof window === 'undefined' ? '' : window.jucy.config.azure?.redirectUri,
        navigateToLoginRequestUrl: true,
        postLogoutRedirectUri: typeof window === 'undefined' ? null : window.jucy.config.azure?.redirectUri,
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
});
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});
