import { TripInfoRequest } from '@jucy/rentals-api-client/rentals-api-v3';
import { format } from 'date-fns';
import { isValidDate } from './dates';

const formatDate = (date?: string) => {
    if (!date) {
        return undefined;
    }
    if (isValidDate(date)) {
        return format(date, 'yyyy-MM-ss');
    }
    return undefined;
};

const formatTime = (date?: string) => {
    if (!date) {
        return undefined;
    }
    if (isValidDate(date)) {
        return format(date, 'HH:mm');
    }
    return undefined;
};

export const toSearchUid = (tripInfo?: Partial<TripInfoRequest>): string =>
    [
        tripInfo?.pickUpLocation || '',
        tripInfo?.dropOffLocation || '',
        formatDate(tripInfo?.pickUpDate) || '',
        formatTime(tripInfo?.pickUpDate) || '',
        formatDate(tripInfo?.dropOffDate) || '',
        formatTime(tripInfo?.dropOffDate) || '',
        tripInfo?.fleetCategory || '',
        tripInfo?.driverAge || '',
        tripInfo?.campaignCode || '',
    ].join('|');

export const searchUidToTripInfoRequest = (searchUrl: string): TripInfoRequest => {
    const [pickUpLocation, dropOffLocation, pickUpDate, pickUpTime, dropOffDate, dropOffTime, fleetCategory, driverAge, campaignCode] = searchUrl.split('|');
    return {
        pickUpLocation,
        dropOffLocation,
        pickUpDate: `${pickUpDate} ${pickUpTime}`,
        dropOffDate: `${dropOffDate} ${dropOffTime}`,
        fleetCategory,
        driverAge: Number(driverAge),
        campaignCode,
    };
};
