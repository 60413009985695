'use client';
import React from 'react';
import Paper from '@mui/material/Paper';
import { PopoverPaper } from '@mui/material/Popover';
import styled from '@mui/material/styles/styled';
import { RangePosition } from '@mui/x-date-pickers-pro';
import { SearchFromTimeSelectMenuHeader } from './SearchFromTimeSelectMenuHeader';

export const MenuPaper = styled(PopoverPaper as typeof Paper, {
    name: 'MuiMenu',
    slot: 'Paper',
    overridesResolver: (props, styles) => styles.paper,
})({
    maxHeight: 'calc(100% - 96px)',
    WebkitOverflowScrolling: 'touch',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    overflowX: 'hidden',
});
export const MenuScroller = styled('div', {
    name: 'MenuScroller',
})({
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
});
export type SearchFromTimeSelectMenuPaperProps = React.ComponentProps<typeof MenuPaper> & { position: RangePosition };
export const SearchFromTimeSelectMenuPaper = React.forwardRef<HTMLDivElement, SearchFromTimeSelectMenuPaperProps>(
    ({ children, position, ...props }, ref) => (
        <MenuPaper ref={ref} {...props}>
            <SearchFromTimeSelectMenuHeader position={position} />
            <MenuScroller>{children}</MenuScroller>
        </MenuPaper>
    )
);
SearchFromTimeSelectMenuPaper.displayName = 'SearchFromTimeSelectMenuPaper';
