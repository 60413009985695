/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { Money } from './Money';
import { MoneyFromJSON, MoneyFromJSONTyped, MoneyToJSON } from './Money';

/**
 *
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     *
     * @type {Money}
     * @memberof Payment
     */
    amount: Money;
    /**
     *
     * @type {Date}
     * @memberof Payment
     */
    date: Date;
    /**
     *
     * @type {string}
     * @memberof Payment
     */
    type: string;
    /**
     *
     * @type {string}
     * @memberof Payment
     */
    merchantFeeName?: string;
    /**
     *
     * @type {Money}
     * @memberof Payment
     */
    merchantFeeAmount?: Money;
    /**
     *
     * @type {Money}
     * @memberof Payment
     */
    total?: Money;
}

/**
 * Check if a given object implements the Payment interface.
 */
export function instanceOfPayment(value: object): value is Payment {
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function PaymentFromJSON(json: any): Payment {
    return PaymentFromJSONTyped(json, false);
}

export function PaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payment {
    if (json == null) {
        return json;
    }
    return {
        amount: MoneyFromJSON(json['amount']),
        date: stringToDate(json['date']),
        type: json['type'],
        merchantFeeName: json['merchantFeeName'] == null ? undefined : json['merchantFeeName'],
        merchantFeeAmount: json['merchantFeeAmount'] == null ? undefined : MoneyFromJSON(json['merchantFeeAmount']),
        total: json['total'] == null ? undefined : MoneyFromJSON(json['total']),
    };
}

export function PaymentToJSON(value?: Payment | null): any {
    if (value == null) {
        return value;
    }
    return {
        amount: MoneyToJSON(value['amount']),
        date: dateToString(value['date'])?.substring(0, 10),
        type: value['type'],
        merchantFeeName: value['merchantFeeName'],
        merchantFeeAmount: MoneyToJSON(value['merchantFeeAmount']),
        total: MoneyToJSON(value['total']),
    };
}
