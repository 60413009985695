/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { LiteAdditionalDriver } from './LiteAdditionalDriver';
import { LiteAdditionalDriverFromJSON, LiteAdditionalDriverFromJSONTyped, LiteAdditionalDriverToJSON } from './LiteAdditionalDriver';
import type { LiteContact } from './LiteContact';
import { LiteContactFromJSON, LiteContactFromJSONTyped, LiteContactToJSON } from './LiteContact';

/**
 *
 * @export
 * @interface ReservationDetails
 */
export interface ReservationDetails {
    /**
     *
     * @type {string}
     * @memberof ReservationDetails
     */
    bookingNumber: string;
    /**
     *
     * @type {string}
     * @memberof ReservationDetails
     */
    pickUpLocation?: string;
    /**
     *
     * @type {string}
     * @memberof ReservationDetails
     */
    dropOffLocation?: string;
    /**
     *
     * @type {Date}
     * @memberof ReservationDetails
     */
    pickUpDateTime?: Date;
    /**
     *
     * @type {Date}
     * @memberof ReservationDetails
     */
    dropOffDateTime?: Date;
    /**
     *
     * @type {string}
     * @memberof ReservationDetails
     */
    categoryCode?: string;
    /**
     *
     * @type {string}
     * @memberof ReservationDetails
     */
    campaignCode?: string;
    /**
     *
     * @type {string}
     * @memberof ReservationDetails
     */
    secondaryProductsAndInsuranceMap?: string;
    /**
     *
     * @type {number}
     * @memberof ReservationDetails
     */
    numberTravelling?: number;
    /**
     *
     * @type {LiteContact}
     * @memberof ReservationDetails
     */
    mainContact?: LiteContact;
    /**
     *
     * @type {Array<LiteAdditionalDriver>}
     * @memberof ReservationDetails
     */
    additionalDrivers?: Array<LiteAdditionalDriver>;
    /**
     *
     * @type {string}
     * @memberof ReservationDetails
     */
    flightNumber?: string;
}

/**
 * Check if a given object implements the ReservationDetails interface.
 */
export function instanceOfReservationDetails(value: object): value is ReservationDetails {
    if (!('bookingNumber' in value) || value['bookingNumber'] === undefined) return false;
    return true;
}

export function ReservationDetailsFromJSON(json: any): ReservationDetails {
    return ReservationDetailsFromJSONTyped(json, false);
}

export function ReservationDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationDetails {
    if (json == null) {
        return json;
    }
    return {
        bookingNumber: json['BookingNumber'],
        pickUpLocation: json['PickUpLocation'] == null ? undefined : json['PickUpLocation'],
        dropOffLocation: json['DropOffLocation'] == null ? undefined : json['DropOffLocation'],
        pickUpDateTime: json['PickUpDateTime'] == null ? undefined : stringToDate(json['PickUpDateTime']),
        dropOffDateTime: json['DropOffDateTime'] == null ? undefined : stringToDate(json['DropOffDateTime']),
        categoryCode: json['CategoryCode'] == null ? undefined : json['CategoryCode'],
        campaignCode: json['CampaignCode'] == null ? undefined : json['CampaignCode'],
        secondaryProductsAndInsuranceMap: json['SecondaryProductsAndInsuranceMap'] == null ? undefined : json['SecondaryProductsAndInsuranceMap'],
        numberTravelling: json['NumberTravelling'] == null ? undefined : json['NumberTravelling'],
        mainContact: json['MainContact'] == null ? undefined : LiteContactFromJSON(json['MainContact']),
        additionalDrivers:
            json['AdditionalDrivers'] == null ? undefined : (json['AdditionalDrivers'] as Array<any>).map(LiteAdditionalDriverFromJSON),
        flightNumber: json['FlightNumber'] == null ? undefined : json['FlightNumber'],
    };
}

export function ReservationDetailsToJSON(value?: ReservationDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        BookingNumber: value['bookingNumber'],
        PickUpLocation: value['pickUpLocation'],
        DropOffLocation: value['dropOffLocation'],
        PickUpDateTime: value['pickUpDateTime'] == null ? undefined : dateToString(value['pickUpDateTime']),
        DropOffDateTime: value['dropOffDateTime'] == null ? undefined : dateToString(value['dropOffDateTime']),
        CategoryCode: value['categoryCode'],
        CampaignCode: value['campaignCode'],
        SecondaryProductsAndInsuranceMap: value['secondaryProductsAndInsuranceMap'],
        NumberTravelling: value['numberTravelling'],
        MainContact: LiteContactToJSON(value['mainContact']),
        AdditionalDrivers:
            value['additionalDrivers'] == null ? undefined : (value['additionalDrivers'] as Array<any>).map(LiteAdditionalDriverToJSON),
        FlightNumber: value['flightNumber'],
    };
}
