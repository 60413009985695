import React from 'react';
import { ThemeOptions } from '@mui/material';
import { runInAction } from 'mobx';
import config from '../config';
import { Brand, defaultBrand } from '../lib/brand';
import appState from './AppStateStore';
import { createThemeOptionsForBrand } from '@jucy-ui/appearance';
import { JucyCountryCode } from '@jucy/rentals-common';

export class BrandStore {
    brand: Brand = defaultBrand;

    constructor({ brand }: { brand?: Brand } = {}) {
        if (brand) {
            runInAction(() => {
                this.brand = brand;
                appState.brand = brand;
            });
        }
    }

    get name() {
        return this.brand.toUpperCase();
    }

    get websiteUrl() {
        return this.brand === 'star' ? config.starWebsiteBaseUrl : config.websiteBaseUrl;
    }

    privacyPageUrl({ countryCode = 'nz' }: { countryCode?: string | JucyCountryCode } = { countryCode: 'nz' }) {
        if (this.brand === 'star') {
            return new URL(`/${countryCode}/privacy-policy`, config.starWebsiteBaseUrl).href;
        }
        return new URL(`/${countryCode}/en/privacy`, config.websiteBaseUrl).href;
    }

    termsPageUrl({ countryCode = 'nz' }: { countryCode?: string | JucyCountryCode } = { countryCode: 'nz' }) {
        if (this.brand === 'star') {
            return new URL(`/${countryCode}/terms`, config.starWebsiteBaseUrl).href;
        }
        return new URL(`/${countryCode}/en/terms`, config.websiteBaseUrl).href;
    }
    contactPageUrl({ countryCode = 'nz' }: { countryCode?: string | JucyCountryCode } = { countryCode: 'nz' }) {
        if (this.brand === 'star') {
            return new URL(`/${countryCode}/contact`, config.starWebsiteBaseUrl).href;
        }
        return new URL(`/${countryCode}/en/about-us/help-and-contact`, config.websiteBaseUrl).href;
    }

    get checkInPageUrl() {
        return new URL('/self-service/pre-pick-up', appState.brand === 'jucy' ? config.dashboardUrl.jucy : config.dashboardUrl.star).href;
    }

    get logo() {
        return `/assets/images/${this.brand}/${this.brand}-logo.svg`;
    }

    get theme(): Required<ThemeOptions> {
        return createThemeOptionsForBrand(this.brand) as Required<ThemeOptions>;
    }

    get primaryColorName() {
        if (this.brand === 'star') {
            return 'sea-blue';
        }
        return 'green';
    }
}

export const BrandContext = React.createContext(new BrandStore());

export const useBrand = () => React.useContext(BrandContext);
