'use client';

import React, { useMemo } from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { FleetType, useGetFleetTypes } from '@jucy-ui/common';
import { BoxProps, RadioGroupProps } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import RadioGroup from '@mui/material/RadioGroup';
import { LoadingContainer } from '../../LoadingContainer';
import { FleetTypeRadioControlLabel, FleetTypeRadioSkeleton } from './components';
import { FleetTypeRadioGroupContextProvider, FleetTypeRadioGroupState, useFleetTypeThumbnailWatcher } from './lib';


export interface FleetTypeRadioGroupProps extends RadioGroupProps {
    shouldDisableFleetType?: (fleetType: FleetType) => boolean;
    direction?: FleetTypeRadioGroupState['direction'];
    labels?: Record<string, string>;
    helperText?: React.ReactNode;
    error?: boolean;
}

export const FleetTypeRadioGroup = React.forwardRef<HTMLInputElement, FleetTypeRadioGroupProps>(
    ({ direction = 'row', labels, shouldDisableFleetType, error, helperText, sx, ...props }, ref) => {
        const { data, isLoading } = useGetFleetTypes();
        const fleetTypes = data || [];
        const filteredFleetTypes = shouldDisableFleetType ? fleetTypes.filter((fleetType) => !shouldDisableFleetType(fleetType)) : fleetTypes;
        const watcherRef = useFleetTypeThumbnailWatcher();
        if (!isLoading && filteredFleetTypes.length === 1) {
            return null;
        }
        return (
            <FleetTypeRadioGroupContextProvider direction={direction}>
                <FormControl error={error} variant="standard" sx={sx} fullWidth={true}>
                    <RadioGroup row ref={ref} {...props}>
                        <LoadingContainer
                            isLoading={isLoading}
                            fallback={
                                <FleetTypeRadioGroupButtonContainer
                                    direction={direction}
                                    ref={watcherRef}
                                    data-loading={filteredFleetTypes.length ? 'false' : 'true'}
                                >
                                    <FleetTypeRadioSkeleton width="204px" />
                                    <FleetTypeRadioSkeleton width="145px" />
                                </FleetTypeRadioGroupButtonContainer>
                            }
                        >
                            <FleetTypeRadioGroupButtonContainer
                                direction={direction}
                                ref={watcherRef}
                                data-loading={filteredFleetTypes.length ? 'false' : 'true'}
                            >
                                {filteredFleetTypes
                                    .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
                                    .map((fleetType) => (
                                        <FleetTypeRadioControlLabel
                                            key={fleetType.id}
                                            error={error}
                                            fleetType={fleetType}
                                            label={labels?.[fleetType.slug] || fleetType.name}
                                        />
                                    ))}
                            </FleetTypeRadioGroupButtonContainer>
                        </LoadingContainer>
                        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
                    </RadioGroup>
                </FormControl>
            </FleetTypeRadioGroupContextProvider>
        );
    }
);
FleetTypeRadioGroup.displayName = 'FleetTypeRadioGroup';
const FleetTypeRadioGroupButtonContainer = React.forwardRef<HTMLDivElement, BoxProps & { direction?: FleetTypeRadioGroupState['direction'] }>(
    ({ children, direction, sx, ...props }, ref) => (
        <Box
            ref={ref}
            sx={useMemo(
                () =>
                    mergeSx(
                        (theme) => ({
                            display: 'flex',
                            gap: 1,
                            flexWrap: 'nowrap',
                            minHeight: direction === 'row' ? '60px' : '44px',
                            flexDirection: 'row',
                            width: '100%',
                            '& .fleet-type-thumb': {
                                py: 0.5,
                                '& img': {
                                    maxHeight: '40px',
                                },
                            },
                            [theme.breakpoints.down('sm').replace('@media', '@container')]: {
                                minHeight: '60px',
                                flexDirection: 'column',
                                '& .fleet-type-thumb': {
                                    py: 1,
                                    '& img': {
                                        maxHeight: '30px',
                                    },
                                },
                            },
                        }),
                        sx
                    ),
                [sx, direction]
            )}
            {...props}
        >
            {children}
        </Box>
    )
);
FleetTypeRadioGroupButtonContainer.displayName = 'FleetTypeRadioGroupButtonContainer';
