import config from '#/config';
import { AgentUser, OBEUser } from '#/types/OBEUser';
import { UserMode } from '#/types/UserMode';
import { autorun, makeAutoObservable, reaction, runInAction } from 'mobx';
import getJucyRentalsApiClient from '../lib/getJucyRentalsApiClient';
import jucyApi from '../lib/JucyAPI';
class AccountKeyStore {
    state = 'pending';

    user?: OBEUser = undefined;

    impersonatedAccount?: AgentUser = undefined;

    constructor() {
        makeAutoObservable(this);

        autorun(() => {
            if (this.user?.type === 'staff' && this.user?.agentUser?.accountKey !== this.impersonatedAccount?.accountKey) {
                this.user.agentUser = this.impersonatedAccount;
            }
        });

        reaction(
            () => this.accountKey,
            () => {
                if (this.accountKey !== jucyApi.getAccountKey()) {
                    jucyApi.setAccountKey(this.accountKey);
                }
            }
        );
    }

    get accountKey() {
        if (this.user?.type === 'staff' && this.impersonatedAccount?.accountKey) {
            return this.impersonatedAccount?.accountKey as string;
        }
        if (this.user?.type === 'staff' && this.user?.agentUser?.accountKey) {
            return this.user.agentUser.accountKey;
        }
        if (this.user?.type === 'agent') {
            return this.user.accountKey;
        }
        return config.webAccountKey;
    }

    get mode(): UserMode {
        return this.user?.type || 'direct';
    }

    get paymentEnabled() {
        return this.mode === 'direct';
    }

    get showCommission() {
        return this.mode === 'agent';
    }

    get showAgentCollects() {
        return this.mode === 'agent';
    }

    get showAgentBalanceDue() {
        return this.mode === 'agent';
    }

    get trackingEnabled() {
        return this.mode === 'direct';
    }

    get isAgent() {
        return this.mode === 'agent';
    }

    initialize(account?: OBEUser) {
        this.user = account;
    }

    get apiClient() {
        return getJucyRentalsApiClient({ user: this.user });
    }

    generateDefaultHirerDetailsForLocation() {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 6; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return {
            firstName: this.mode === 'agent' ? 'OBE' : 'Web',
            lastName: 'Enquiry',
            email: `${result}@jucyobe.com`,
        };
    }

    setImpersonation(key: string | undefined) {
        if (!key) {
            runInAction(() => {
                this.impersonatedAccount = undefined;
            });
            return;
        }

        getJucyRentalsApiClient({ accountKey: key })
            .getAgentUser(key)
            .then((impersonatedAccount) => {
                runInAction(() => {
                    this.impersonatedAccount = impersonatedAccount;
                });
            });
    }
}

export default new AccountKeyStore();
