let tracker: UniversalAnalytics.Tracker;
export const getUaTracker = (): Promise<UniversalAnalytics.Tracker | null> =>
    new Promise((resolve) => {
        if (typeof tracker !== 'undefined') {
            resolve(tracker);
        }
        if (typeof window === 'undefined') {
            resolve(null);
        }
        const maxTries = 4;
        let tries = 0;
        const tryResolveGa = () => {
            if (typeof window.ga === 'function') {
                window.ga(() => {
                    const trackers = window.ga.getAll();
                    if (trackers && trackers.length > 0) {
                        tracker = trackers[0];
                        resolve(tracker);
                    } else {
                        resolve(null);
                    }
                });
            } else {
                if (tries <= maxTries) {
                    tries++;
                    setTimeout(tryResolveGa, 500);
                } else {
                    resolve(null);
                }
            }
        };
        tryResolveGa();
    });

const ga = {
    getUaTracker: getUaTracker,
    getLinkerParam: async (): Promise<string | null> => {
        const ga = await getUaTracker();
        return ga?.get('linkerParam').replace('_ga=', '') || null;
    },
    getClientId: async (): Promise<string | null> => {
        const ga = await getUaTracker();
        return ga?.get('clientId') || null;
    },
};

export default ga;
