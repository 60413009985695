/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';

/**
 *
 * @export
 * @interface RentalDocumentUpload
 */
export interface RentalDocumentUpload {
    /**
     *
     * @type {number}
     * @memberof RentalDocumentUpload
     */
    documentLinkSetupId?: number;
    /**
     *
     * @type {string}
     * @memberof RentalDocumentUpload
     */
    url?: string;
    /**
     *
     * @type {number}
     * @memberof RentalDocumentUpload
     */
    customerId?: number;
    /**
     *
     * @type {number}
     * @memberof RentalDocumentUpload
     */
    vehicleId?: number;
    /**
     *
     * @type {string}
     * @memberof RentalDocumentUpload
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof RentalDocumentUpload
     */
    docType?: string;
    /**
     *
     * @type {string}
     * @memberof RentalDocumentUpload
     */
    workflowCode?: string;
    /**
     *
     * @type {string}
     * @memberof RentalDocumentUpload
     */
    originalName?: string;
    /**
     *
     * @type {string}
     * @memberof RentalDocumentUpload
     */
    storageProvider?: string;
    /**
     *
     * @type {object}
     * @memberof RentalDocumentUpload
     */
    resultsProvider?: object;
    /**
     *
     * @type {number}
     * @memberof RentalDocumentUpload
     */
    seqNo?: number;
}

/**
 * Check if a given object implements the RentalDocumentUpload interface.
 */
export function instanceOfRentalDocumentUpload(value: object): value is RentalDocumentUpload {
    return true;
}

export function RentalDocumentUploadFromJSON(json: any): RentalDocumentUpload {
    return RentalDocumentUploadFromJSONTyped(json, false);
}

export function RentalDocumentUploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): RentalDocumentUpload {
    if (json == null) {
        return json;
    }
    return {
        documentLinkSetupId: json['documentLinkSetupId'] == null ? undefined : json['documentLinkSetupId'],
        url: json['url'] == null ? undefined : json['url'],
        customerId: json['customerId'] == null ? undefined : json['customerId'],
        vehicleId: json['vehicleId'] == null ? undefined : json['vehicleId'],
        description: json['description'] == null ? undefined : json['description'],
        docType: json['docType'] == null ? undefined : json['docType'],
        workflowCode: json['workflowCode'] == null ? undefined : json['workflowCode'],
        originalName: json['originalName'] == null ? undefined : json['originalName'],
        storageProvider: json['storageProvider'] == null ? undefined : json['storageProvider'],
        resultsProvider: json['resultsProvider'] == null ? undefined : json['resultsProvider'],
        seqNo: json['seqNo'] == null ? undefined : json['seqNo'],
    };
}

export function RentalDocumentUploadToJSON(value?: RentalDocumentUpload | null): any {
    if (value == null) {
        return value;
    }
    return {
        documentLinkSetupId: value['documentLinkSetupId'],
        url: value['url'],
        customerId: value['customerId'],
        vehicleId: value['vehicleId'],
        description: value['description'],
        docType: value['docType'],
        workflowCode: value['workflowCode'],
        originalName: value['originalName'],
        storageProvider: value['storageProvider'],
        resultsProvider: value['resultsProvider'],
        seqNo: value['seqNo'],
    };
}
