import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyValue } from '@jucy/rentals-api-client/rentals-api-v3';
import { Box, Grid, GridProps, Typography } from '@mui/material';
import CurrencyStore from '../../../store/CurrencyStore';
import TranslationStore from '../../../store/TranslationStore';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';

const TotalsRow: React.FC<GridProps & { title: string; subtitle?: string | null; total: CurrencyValue }> = observer(({ title, subtitle, total, ...props }) => (
    <>
        <Grid item xs={6} lineHeight={1} {...props}>
            <Typography variant="subtitle1" lineHeight={0.8} textTransform="none" marginBottom={0}>
                {title}
            </Typography>
            {subtitle ? (
                <Typography variant="caption" lineHeight={0.8}>
                    {subtitle}
                </Typography>
            ) : null}
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="right" whiteSpace="nowrap" {...props}>
            <Typography data-cy="summary-total" variant="subtitle1" lineHeight={0.8} fontSize="18px" textTransform="none" marginBottom={0}>
                {CurrencyStore.selectedCurrencyCode || total.currencyCode || ''}
                <CurrencyFormatter price={total} />
            </Typography>
        </Grid>
    </>
));

const TotalsSummary: React.FC = observer(() => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const context = useBookingSummaryContext();
    const showAmendment =
        context.summary?.action == 'edit' && context.summary?.amendments?.totalPrice && context.summary?.amendments?.totalPrice?.value !== context.summary?.totalPrice?.value;
    return context.summary?.totalPrice?.value && context.summary.totalPrice.value > 0 ? (
        <BookingSummaryGroupItem sx={{ pb: 0 }} key="totals" className="totals-container">
            <Grid container spacing={0}>
                <Grid item xs={12} lineHeight={1}>
                    <Box component="hr" sx={{ mt: 0, mb: 2 }} />
                </Grid>
                {showAmendment && context.summary?.amendments ? (
                    <>
                        <TotalsRow title={t('trip_summary.group.total')} total={context.summary.totalPriceWithMerchantFee} mb={1} sx={{ textDecoration: 'line-through' }} />
                        <TotalsRow
                            title={t('trip_summary.group.total')}
                            subtitle={t('trip_summary.group.including_gst')}
                            total={context.summary.amendments.totalPriceWithMerchantFee as CurrencyValue}
                        />
                    </>
                ) : (
                    <TotalsRow title={t('trip_summary.group.total')} subtitle={t('trip_summary.group.including_gst')} total={context.summary.totalPriceWithMerchantFee} />
                )}
                {CurrencyStore.selectedCurrencyCode !== context.summary.totalPrice.currencyCode ? (
                    <Grid item xs={12}>
                        <Typography sx={{ listStyle: 'none', '& li::marker': { content: '" * "' }, pl: 2 }} component="ul" variant="caption" lineHeight={1} mt={1}>
                            <li>{t('trip_summary.group.currency_conversion', { CurrencyCode: context.summary.totalPrice.currencyCode })}</li>
                        </Typography>
                    </Grid>
                ) : null}
            </Grid>
        </BookingSummaryGroupItem>
    ) : null;
});
TotalsSummary.displayName = 'TotalsSummary';

export default TotalsSummary;
