import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Money } from '@jucy/rentals-api-client/rentals-api';
import { Box, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Image from 'next/image';
import * as Afterpay from '../../../../../lib/Afterpay';
import * as Laybuy from '../../../../../lib/Laybuy';
import appState from '../../../../../store/AppStateStore';
import currencyStore from '../../../../../store/CurrencyStore';
import paymentStore from '../../../../../store/PaymentStore';
import { BookingCart } from '../../../../../types/BookingCart';
import CurrencyFormatter from '../../../../CurrencyFormatter/CurrencyFormatter';
import { useTeaserContext } from '../TeaserContext';

const useStyles = makeStyles(() => ({
    logo: {
        display: 'block',
        margin: '8px auto',
    },
}));
const BuyNowPayLater: React.FC = observer(() => {
    const fleetCategory = useTeaserContext().fleetCategory.asV3FleetCategory();
    const classes = useStyles();
    const [price, setPrice] = useState(0);
    const countryCode = currencyStore.currencyCodeToCountryCode(fleetCategory.total.currencyCode);
    const laybuyAvailable = useMemo(() => Laybuy.enabled({ fleetCategory } as unknown as BookingCart), [fleetCategory]);
    const afterpayAvailable = useMemo(
        () => Afterpay.enabled({ fleetCategory } as unknown as BookingCart) && Afterpay.available(fleetCategory?.total, countryCode && paymentStore.paymentConfigs[countryCode]),
        [countryCode, fleetCategory]
    );
    useEffect(() => {
        const prices: Money[] = [];
        if (laybuyAvailable) {
            prices.push(Laybuy.weeklyPayments(fleetCategory.total));
        }
        if (afterpayAvailable) {
            prices.push(Afterpay.weeklyPayments(fleetCategory.total));
        }
        setPrice(
            prices.reduce((res, p) => {
                if (p.value && (res === 0 || p.value < res)) {
                    res = p.value;
                }
                return res;
            }, 0)
        );
    }, [fleetCategory, laybuyAvailable, afterpayAvailable]);

    if (price <= 0 || appState.isEditMode) {
        return null;
    }
    return (
        <>
            {' '}
            or weekly interest free payments starting from{' '}
            <strong>
                <CurrencyFormatter
                    price={{
                        value: price,
                        currencyCode: fleetCategory.total.currencyCode,
                    }}
                />
            </strong>{' '}
            with our buy now pay later options
            <Stack direction={{ xs: 'row', sm: 'column' }} justifyContent="center" alignItems="center">
                {laybuyAvailable ? (
                    <Box flex="1 1">
                        <Image src={Laybuy.logo} alt="Laybuy logo" className={classes.logo} width={94} height={21} />
                    </Box>
                ) : null}
                {afterpayAvailable ? (
                    <Box flex="1 1">
                        <Image src={Afterpay.logo} alt="Afterpay logo" className={classes.logo} width={94} height={33} />
                    </Box>
                ) : null}
            </Stack>
        </>
    );
});
BuyNowPayLater.displayName = 'BuyNowPayLater';
export default BuyNowPayLater;
