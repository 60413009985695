import { useAuth } from '#/hooks';
import { ObeProductLine } from '#/types/ObeProductLine';
import { AgentPaymentType, AgentRateType, JucyCountryCode } from '@jucy/rentals-common';
import { useAccountInfo } from '../../../hooks/useAccountInfo';
import { roundCurrency } from '../../../lib/roundCurrency';
import { BookingCart } from '../../../types/BookingCart';

export type AgentCommissionSettings = {
    commissionRate: number;
    commissionValue: number;
    agentCollects: number;
    customerBalanceDue: number;
    paymentType: AgentPaymentType | null;
    rateType: AgentRateType | null;
};
const initialAgentCommissionSettings: AgentCommissionSettings = {
    commissionRate: 0,
    commissionValue: 0,
    agentCollects: 0,
    customerBalanceDue: 0,
    paymentType: null,
    rateType: null,
};

const calculateNetSettings = (summary: BookingCart, settings: AgentCommissionSettings) => {
    settings.customerBalanceDue =
        summary.productLines.filter((i) => !(!i || i.isMandatory || ['InsuranceFee', 'OtherFee'].includes(i.category))).reduce((r, l) => r + (l?.total.value || 0), 0) || 0;
    settings.agentCollects = summary.totalPrice.value - settings.customerBalanceDue;
    if (settings.paymentType === AgentPaymentType.full) {
        settings.commissionValue = 0;
        //Agent Collects: Total booking cost
        settings.agentCollects = summary.totalPrice.value;
        //Customer to pay: $0
        settings.customerBalanceDue = 0;
    }
    return settings;
};

const isRateDiscount = (item: ObeProductLine) => {
    if (item.category === 'OtherFee' && item.description === 'Discount') {
        return true;
    }
    if (item.category === 'SecondaryProduct' && item.name.toLowerCase() === 'rate adjustment') {
        return true;
    }
    return false;
};

const calculateGrossSettings = (summary: BookingCart, settings: AgentCommissionSettings) => {
    settings.commissionRate = settings.commissionRate || summary.fleetCategory?.discountPercentageApplied || 0;
    settings.commissionValue = summary.agentCommission?.value || 0;
    settings.agentCollects = summary.agentCommission?.value || 0;
    const commissionableItemTotal = summary.productLines?.filter((i) => i.commissionable).reduce((r, l) => r + l.total.value, 0) || 0;
    const itemCommission = commissionableItemTotal ? roundCurrency(commissionableItemTotal * settings.commissionRate) : 0;
    const rateDiscounts = summary.mandatoryItems?.filter(isRateDiscount).reduce((r, l) => r + l.total.value, 0) || 0;
    const dailyRateTotal = summary.fleetCategory ? summary.fleetCategory.total.value + rateDiscounts : 0;
    const dailyRateCommission = roundCurrency(dailyRateTotal * settings.commissionRate);

    if (settings.paymentType === AgentPaymentType.commissionItems && settings.commissionRate > 0) {
        //Agent Collects: Daily rate + Stress Free/Stress Free Plus total
        settings.commissionValue = roundCurrency(itemCommission + dailyRateCommission);
        settings.agentCollects = roundCurrency(commissionableItemTotal + dailyRateTotal); //Customer to pay: Total Booking value - Agent Collects
        settings.customerBalanceDue = roundCurrency(summary.totalPrice.value - settings.agentCollects);
    } else if (settings.paymentType === AgentPaymentType.commissionItems && summary.agentCommission?.value === 0) {
        settings.agentCollects = summary.totalPrice.value;
        settings.customerBalanceDue = summary.totalPrice.value - settings.agentCollects;
    } else if (settings.paymentType === AgentPaymentType.commissionOnly) {
        // Agent Collects: Commission only
        settings.commissionValue = roundCurrency(itemCommission + dailyRateCommission);
        settings.agentCollects = settings.commissionValue;
        // Customer to pay: Total Booking value - Agent Collects/Commission
        settings.customerBalanceDue = roundCurrency(summary.totalPrice.value - settings.agentCollects + summary.payments?.reduce((acc, a) => acc + a.amount.value, 0));
    } else if (settings.paymentType === AgentPaymentType.full) {
        settings.commissionValue = roundCurrency(itemCommission + dailyRateCommission);
        //Agent Collects: Total booking cost
        settings.agentCollects = summary.totalPrice.value;
        //Customer to pay: $0
        settings.customerBalanceDue = 0;
    } else if (settings.paymentType === AgentPaymentType.zeroPayment) {
        //Agent Collects: $0
        settings.agentCollects = 0;
        //Customer to pay: Total Booking value
        settings.customerBalanceDue = summary.totalPrice.value;
    }
    return settings;
};

const useAgentCommissionSettings = (summary?: BookingCart): AgentCommissionSettings => {
    const { user } = useAuth();
    const { accountInfo } = useAccountInfo(user.type === 'agent' ? user.accountKey : undefined);
    const valid = Boolean(summary?.pickUpLocation?.CountryCode && summary?.totalPrice?.value);
    const countryCode = valid ? (summary?.pickUpLocation?.CountryCode as JucyCountryCode) : undefined;
    const accountRegionSettings = countryCode ? accountInfo?.settings?.[countryCode] : undefined;
    const settings = { ...initialAgentCommissionSettings };

    if (!valid || !accountRegionSettings || !summary) {
        return settings;
    }
    settings.paymentType = accountRegionSettings.paymentType as AgentPaymentType;
    settings.rateType = accountRegionSettings.rateType as AgentRateType;
    settings.commissionRate = accountRegionSettings.commission || 0;
    return settings?.rateType === AgentRateType.net ? calculateNetSettings(summary, settings) : calculateGrossSettings(summary, settings);
};
export default useAgentCommissionSettings;
