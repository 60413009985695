import { ExtendedFleetCategory } from './ExtendedFleetCategory';

export default class FleetCategory {
    name = '';
    description = '';
    code = '';
    id = '';
    typeId = '';
    seatCount = 0;
    sleepCount = 0;
    sleepDescription = '';
    luggageLarge = 0;
    luggageSmall = 0;
    image = '';
    vehicleDescription = '';
    highlightedFeatures: string[] = [];
    url = '';
    gallery = '';
    discountPercentageApplied = 0;
    extended?: ExtendedFleetCategory;

    constructor(props?: Partial<FleetCategory>) {
        Object.assign(this, props);
    }
}
