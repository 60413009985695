import isFetchError from './isFetchError';
import isResponseError from './isResponseError';

export const resolveError = async (e: unknown): Promise<{ error: Error | undefined; message: string }> => {
    const result = {
        error: e instanceof Error ? e : undefined,
        message: '',
    };
    if (isFetchError(e)) {
        try {
            const body = await e.response
                .clone()
                .json()
                .catch(() => undefined);
            result.message = body?.Message || body.message || e.message;
        } catch (_e) {
            result.message = e.message;
        }
    } else if (isResponseError(e)) {
        result.message = e.response?.body?.Message || e.response?.body?.message || e.message;
    }
    if (e instanceof Error && !result.message) {
        result.message = e.message;
    }
    return result;
};
