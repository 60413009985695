'use client';
import React from 'react';
import { Theme, TypeOptions } from 'react-toastify';
import IconButton from '@mui/material/IconButton';

export const JucyToastCloseIcon: React.FC<{
    closeToast: (e: React.MouseEvent<HTMLElement>) => void;
    type: TypeOptions;
    ariaLabel?: string;
    theme: Theme;
}> = (props) => (
    <IconButton
        onClick={props.closeToast}
        aria-label={props.ariaLabel}
        size="small"
        sx={{
            position: 'absolute',
            width: 32,
            height: 32,
            top: 0,
            right: 0,
            color: 'inherit',
        }}
    >
        <span aria-hidden="true">×</span>
    </IconButton>
);
