import React from 'react';
import { useIsEditEnabled } from '#/hooks/useIsEditEnabled';
import useEditRouteId from '../../hooks/useEditRouteId';
import { BookingCart } from '../../types/BookingCart';

export interface BookingSummaryProviderProps {
    summary: BookingCart;
    children?: React.ReactNode;
}

export interface BookingSummaryContext {
    summary: BookingCart;
    editRouteId?: string;
    isEditable: boolean;
}

export const BookingSummaryContext = React.createContext<BookingSummaryContext | null>(null);

export const BookingSummaryProvider: React.FC<BookingSummaryProviderProps> = ({ children, summary }) => {
    const editRouteId = useEditRouteId();
    const { editEnabled } = useIsEditEnabled();
    const isEditable = editEnabled || summary?.bookingStatus === 'quote';

    return <BookingSummaryContext.Provider value={{ summary, editRouteId, isEditable }}>{children}</BookingSummaryContext.Provider>;
};

export const useBookingSummaryContext = (): BookingSummaryContext => {
    const values = React.useContext(BookingSummaryContext);
    if (!values) {
        throw new Error('useBookingSummaryContext must be used within a BookingSummaryProvider.');
    }
    return values;
};
