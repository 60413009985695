import { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import FleetCategoryAvailability from '../lib/api/model/FleetCategoryAvailability';
import AvailabilityStore from '../store/AvailabilityStore';
import { useSearchParams } from './useSearchParams';

export const useActiveFleetCategory = () => {
    const [activeParams] = useSearchParams<{ fleetCategory: string }>();
    const [activeFleetCategory, setActiveFleetCategory] = useState<FleetCategoryAvailability | null>(null);
    useEffect(
        () =>
            autorun(() => {
                setActiveFleetCategory(activeParams.fleetCategory ? AvailabilityStore.availableFleetCategories.find((f) => f.CategoryCode === activeParams.fleetCategory) || null : null);
            }),
        [activeParams.fleetCategory]
    );
    return activeFleetCategory;
};
