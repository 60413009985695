// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="jucy-global.d.ts" />

globalThis.jucyConfig = globalThis.jucyConfig  || {
    rentalsApiBaseUrl: 'http://localhost:3002',
};

export * from './lib';
export * from './hooks';
export * from './services';
