import { getBranchSupportsFleetType } from '../lib';
import { SearchFormData } from '../types';

export const validateDropOffBranch = ({ pickUpBranch, dropOffBranch, fleetType }: Partial<SearchFormData>): string | undefined => {
    if (!dropOffBranch) {
        return 'Please select a drop off branch';
    }

    if (fleetType && !getBranchSupportsFleetType({ branch: dropOffBranch, fleetType })) {
        return 'Drop off branch does not support this vehicle type!';
    }

    if (pickUpBranch && dropOffBranch.countryCode !== pickUpBranch.countryCode) {
        return 'Pick up branch and drop off branch must be in the same country';
    }
};
