import * as React from 'react';
import { ReactNode } from 'react';
import { FormControl, FormControlProps, FormHelperText, FormHelperTextProps, FormLabelProps, InputLabel } from '@mui/material';

export interface FieldWrapperProps extends Partial<Omit<FormControlProps, 'onChange' | 'value'>> {
    name: string;
    label?: ReactNode;
    helperText?: ReactNode;
    formLabelProps?: Partial<FormLabelProps>;
    formHelperTextProps?: Partial<FormHelperTextProps>;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({ label, helperText, formLabelProps, formHelperTextProps, children, ...props }) => (
    <FormControl {...props}>
        {!!label && <InputLabel {...formLabelProps}>{label}</InputLabel>}
        {children}
        {helperText ? <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText> : null}
    </FormControl>
);
