'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { SxProps, Theme } from '@mui/material';
import { JucyNavButton } from './JucyNavButton';


export interface JucySliderNavButtonProps {
    sx?: SxProps<Theme>;
    variant?: 'prev' | 'next';
    size?: number;
}

export const JucySliderNavButton: React.FC<JucySliderNavButtonProps> = ({ variant, size, sx }) => {
    const label = variant === 'prev' ? 'previous' : 'next';
    const disabled = false;
    const buttonClassName = variant === 'prev' ? 'blaze-prev' : 'blaze-next';
    return (
        <>
            <JucyNavButton
                size={size}
                variant={variant}
                sx={mergeSx(
                    (theme) => ({
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        opacity: 1,
                        transition: theme.transitions.create(['boxShadow', 'opacity']),
                        ...(variant === 'prev'
                            ? {
                                  '.blaze-slider.start &, .blaze-slider.static &': {
                                      opacity: 0,
                                      pointerEvents: 'none',
                                  },
                              }
                            : {
                                  '.blaze-slider.end &, .blaze-slider.static &': {
                                      opacity: 0,
                                      pointerEvents: 'none',
                                  },
                              }),
                    }),
                    sx
                )}
                className={buttonClassName}
                disabled={disabled}
                aria-label={label}
                type="button"
            />
        </>
    );
};
