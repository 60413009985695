import { asError } from '@jucy-ui/common';
import { SearchForm as JucyUiSearchForm, SearchFormValues as JucyUiSearchFormValues, useSearchFormFieldValues } from '@jucy-ui/components/SearchForm';
import { UseFormReturn } from '@jucy-ui/forms';
import { isSameDay, isSameMinute } from 'date-fns';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useDeferredValue, useEffect, useRef } from 'react';
import fleetTypesStore from '../../../store/FleetTypesStore';
import rentalTripSearchStore, { RentalTripSearchStore } from '../../../store/RentalTripSearchStore';
import SitesStore from '../../../store/SitesStore';
import { SearchFormContainerProps } from './SearchFormContainerProps';
import { PartialSearchFormValues, SearchFormValues } from './SearchFormValues';

export const validateSearchValues = (values: PartialSearchFormValues | SearchFormValues | unknown): values is SearchFormValues =>
    Boolean(values && rentalTripSearchStore.searchValidationSchema.isValidSync(values));

export const SearchForm: React.FC<SearchFormContainerProps> = observer(({ children, sx, options, onFleetTypeChanged, layout, className, initialValues, onSubmit }) => {
    useEffect(() => {
        rentalTripSearchStore.setValues(initialValues);
    }, [initialValues]);

    const handleSubmit = useCallback(
        async (data: JucyUiSearchFormValues, form: UseFormReturn<JucyUiSearchFormValues>) => {
            const values: Partial<RentalTripSearchStore> = {
                fleetType: fleetTypesStore.fleetTypes.find((f) => f.slug === data.fleetType),
                fleetCategory: data.fleetCategory,
                pickUpLocation: SitesStore.sites.find((s) => s.SiteCode === data.pickUpBranch),
                pickUpDate: data.pickUpDate,
                pickUpTime: data.pickUpTime,
                dropOffLocation: SitesStore.sites.find((s) => s.SiteCode === data.dropOffBranch),
                dropOffDate: data.dropOffDate,
                dropOffTime: data.dropOffTime,
                driverAge: data.driverAge,
                promoCode: data.promoCode,
            };
            try {
                rentalTripSearchStore.searchValidationSchema.validateSync(values);
                await onSubmit(values, form);
            } catch (e) {
                const error = e ? asError(e) : undefined;
                form.setError('root.submit-error', { message: error?.message || 'Search request failed' });
            }
        },
        [onSubmit]
    );

    return (
        <JucyUiSearchForm
            className={className}
            mode="all"
            options={{
                ...options,
                variant: options?.variant || layout,
            }}
            sx={sx}
            defaultValues={{
                fleetType: initialValues?.fleetType?.slug,
                fleetCategory: initialValues?.fleetCategoryCode,
                pickUpBranch: initialValues?.pickUpLocation?.SiteCode,
                pickUpDate: initialValues?.pickUpDate,
                pickUpTime: initialValues?.pickUpTime,
                dropOffBranch: initialValues?.dropOffLocation?.SiteCode,
                dropOffDate: initialValues?.dropOffDate,
                dropOffTime: initialValues?.dropOffTime,
                driverAge: initialValues?.driverAge,
                promoCode: initialValues?.promoCode,
            }}
            onSubmit={handleSubmit}
        >
            {children}
            <SyncSearchForm onFleetTypeChanged={onFleetTypeChanged} />
        </JucyUiSearchForm>
    );
});

export const SyncSearchForm = ({ onFleetTypeChanged }: { onFleetTypeChanged: SearchFormContainerProps['onFleetTypeChanged'] }) => {
    const searchFromValues = useDeferredValue(useSearchFormFieldValues());
    const lastFleetType = useRef(searchFromValues.fleetType);
    useEffect(() => {
        if (onFleetTypeChanged && lastFleetType.current !== searchFromValues.fleetType) {
            lastFleetType.current = searchFromValues.fleetType;
            onFleetTypeChanged(fleetTypesStore.fleetTypes.find((f) => f.slug === searchFromValues.fleetType));
        }
        updateRentalTripSearchStore(searchFromValues);
    }, [onFleetTypeChanged, searchFromValues]);
    return null;
};

const updateRentalTripSearchStore = (searchFromValues: Partial<JucyUiSearchFormValues>) => {
    runInAction(() => {
        if (rentalTripSearchStore.fleetType?.slug !== searchFromValues.fleetType) {
            rentalTripSearchStore.fleetType = fleetTypesStore.fleetTypes.find((f) => f.slug === searchFromValues.fleetType);
        }
        if (searchFromValues.fleetCategory && rentalTripSearchStore.fleetCategory !== searchFromValues.fleetCategory) {
            rentalTripSearchStore.fleetCategory = searchFromValues.fleetCategory;
        }
        if (rentalTripSearchStore.pickUpLocation?.SiteCode !== searchFromValues.pickUpBranch) {
            rentalTripSearchStore.pickUpLocation = SitesStore.sites.find((s) => s.SiteCode === searchFromValues.pickUpBranch);
        }
        if (rentalTripSearchStore.pickUpDate && searchFromValues.pickUpDate && !isSameDay(rentalTripSearchStore.pickUpDate, searchFromValues.pickUpDate)) {
            rentalTripSearchStore.pickUpDate = searchFromValues.pickUpDate;
        }
        if (rentalTripSearchStore.pickUpTime && searchFromValues.pickUpTime && !isSameMinute(rentalTripSearchStore.pickUpTime, searchFromValues.pickUpTime)) {
            rentalTripSearchStore.pickUpTime = searchFromValues.pickUpTime;
        }
        if (rentalTripSearchStore.dropOffLocation?.SiteCode !== searchFromValues.dropOffBranch) {
            rentalTripSearchStore.dropOffLocation = SitesStore.sites.find((s) => s.SiteCode === searchFromValues.dropOffBranch);
        }
        if (rentalTripSearchStore.dropOffDate && searchFromValues.dropOffDate && !isSameDay(rentalTripSearchStore.dropOffDate, searchFromValues.dropOffDate)) {
            rentalTripSearchStore.dropOffDate = searchFromValues.dropOffDate;
        }
        if (rentalTripSearchStore.dropOffTime && searchFromValues.dropOffTime && !isSameMinute(rentalTripSearchStore.dropOffTime, searchFromValues.dropOffTime)) {
            rentalTripSearchStore.dropOffTime = searchFromValues.dropOffTime;
        }
        if (searchFromValues.driverAge && rentalTripSearchStore.driverAge !== searchFromValues.driverAge) {
            rentalTripSearchStore.driverAge = searchFromValues.driverAge;
        }
        if (rentalTripSearchStore.promoCode !== searchFromValues.promoCode) {
            rentalTripSearchStore.promoCode = searchFromValues.promoCode;
        }
    });
};

SearchForm.displayName = 'SearchForm';
