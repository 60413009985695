import React from 'react';
import ReactImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from 'react-image-gallery';
import Image from 'next/image';
import { GalleryItem } from '../../../../../lib/api/model/ExtendedFleetCategory';

const noOp = () => undefined;
const renderImageGalleryItem = (item: ReactImageGalleryItem) => {
    const type = (item as GalleryItem).type || '';
    return (
        <div className="image-gallery-image">
            {item.imageSet ? (
                <picture onError={noOp}>
                    {item.imageSet.map((source, index) => (
                        <source key={index} media={source.media} srcSet={source.srcSet} type={type} />
                    ))}
                    <Image className="item-image" alt={item.originalAlt || ''} src={item.original} width={593} height={395} />
                </picture>
            ) : ['Image 360', '360 image'].includes(type) ? (
                <div>
                    <iframe className="item-frame" src={item.original} allowFullScreen title={item.originalTitle} allow="vr" />
                </div>
            ) : (
                <Image className="item-image" src={item.original} alt={item.originalAlt || ''} sizes={item.sizes} title={item.originalTitle} onError={noOp} width={593} height={395} />
            )}

            {item.description && <span className="image-gallery-description">{item.description}</span>}
        </div>
    );
};

const ImageGallery: React.FC<ReactImageGalleryProps> = ({ items, ...props }) => (
    <ReactImageGallery items={items} thumbnailPosition="right" renderItem={renderImageGalleryItem} infinite={false} showPlayButton={false} lazyLoad={true} {...props} />
);

export default ImageGallery;
