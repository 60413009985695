import { FleetType, HirePeriod, Site } from '@jucy-ui/common';
import { isWithinInterval } from 'date-fns';

export const getMinHireDays = ({
    date,
    pickUpBranch,
    dropOffBranch,
    fleetType,
    hirePeriods,
}: {
    date?: Date;
    pickUpBranch?: Site;
    dropOffBranch?: Site;
    fleetType?: FleetType;
    hirePeriods?: HirePeriod[];
}) => {
    let result = fleetType?.minHireDays || 1;
    if (fleetType && date && hirePeriods) {
        const hirePeriod = hirePeriods.find((period) => {
            const fleetTypeValid = period.fleetTypeSlug === fleetType.slug;
            const pickUpValid = !period.pickUpSiteCode || !pickUpBranch?.siteCode || period.pickUpSiteCode === pickUpBranch.siteCode;
            const dropOffValid = !period.dropOffSiteCode || !dropOffBranch?.siteCode || period.dropOffSiteCode === dropOffBranch.siteCode;
            const dateValid = isWithinInterval(date, { start: period.startDate, end: period.endDate });
            return fleetTypeValid && pickUpValid && dropOffValid && dateValid;
        });
        if (hirePeriod?.minHireDays) {
            result = hirePeriod.minHireDays;
        }
    }
    return result;
};
