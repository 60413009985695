'use client';
import React, { ReactNode, useMemo } from 'react';
import type { CatalogItem, FleetCategory } from '@jucy-ui/common';
import { SlotComponentProps, useSlotProps } from '@mui/base';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useThemeProps } from '@mui/material/styles';
import { AvailabilityGridOwnerState } from './AvailabilityGrid';
import { AvailabilityGridRow } from './AvailabilityGridRow';

export interface AvailabilityGridRowsSlots {
    root?: typeof Box;
    row?: typeof AvailabilityGridRow;
}
export interface AvailabilityGridRowsProps {
    fleetCategories: FleetCategory[];
    productCatalog: CatalogItem[];
    highlightedCode?: string;
    className?: string;
    selecting?: boolean;
    activeCode?: string;
    onSelect?: (fleetCategory: FleetCategory) => void;
    moreOptionsTitle?: ReactNode;
    moreOptionsCaption?: ReactNode;
    action?: 'create' | 'edit';
    footer?: ReactNode;
    ownerState: AvailabilityGridOwnerState;
    slots?: AvailabilityGridRowsSlots;
    slotProps?: {
        root?: SlotComponentProps<'div', object, AvailabilityGridOwnerState>;
        row?: SlotComponentProps<typeof AvailabilityGridRow, object, AvailabilityGridOwnerState>;
    };
}

const AvailabilityGridRowsRoot = styled('div', {
    name: 'AvailabilityGridRowsRoot',
    slot: 'root',
})<{ ownerState?: AvailabilityGridOwnerState }>(() => ({
    marginTop: 2,
}));

export const AvailabilityGridRows = React.forwardRef<HTMLDivElement, AvailabilityGridRowsProps>((inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: 'AvailabilityGridRowsRoot' });
    const {
        onSelect,
        productCatalog,
        activeCode,
        selecting,
        footer,
        fleetCategories: availableFleetCategories,
        highlightedCode,
        moreOptionsTitle,
        moreOptionsCaption,
        action,
        slotProps,
        className,
        ownerState,
        ...other
    } = props;
    const fleetCategories = useMemo(() => {
        if (highlightedCode) {
            return availableFleetCategories.filter((f) => f.code !== highlightedCode);
        }
        return availableFleetCategories;
    }, [availableFleetCategories, highlightedCode]);
    const highlightedCategory = useMemo(() => {
        if (highlightedCode) {
            return availableFleetCategories.find((f) => f.code === highlightedCode) || null;
        }
        return null;
    }, [availableFleetCategories, highlightedCode]);

    const RootSlot = props.slots?.root || AvailabilityGridRowsRoot;
    const RowSlot = props.slots?.row || AvailabilityGridRow;
    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            ref,
        },
        ownerState,
        className: className,
    });
    const rowProps = useSlotProps({
        elementType: RowSlot,
        externalSlotProps: slotProps?.row,
        additionalProps: {
            isLoading: selecting,
            onSelect: onSelect,
            action: action,
        },
        ownerState,
    });

    return (
        <RootSlot {...rootProps}>
            {highlightedCategory && (
                <>
                    <RowSlot
                        {...rowProps}
                        fleetCategory={highlightedCategory}
                        catalogItem={productCatalog.find((c) => c.code === highlightedCategory.categoryCode)}
                        isActive={activeCode === highlightedCategory.categoryCode}
                        isHighlighted={true}
                    />
                    {moreOptionsTitle || moreOptionsCaption ? (
                        <Box mt={4}>
                            {moreOptionsTitle ? (
                                <>
                                    <div className="fw-bold h6">{moreOptionsTitle}</div>
                                    <hr />
                                </>
                            ) : null}
                            {moreOptionsCaption ? <Typography mb={2}>{moreOptionsCaption}</Typography> : null}
                        </Box>
                    ) : null}
                </>
            )}
            {fleetCategories.map((fleetCategory) => (
                <React.Fragment key={fleetCategory.categoryCode}>
                    <RowSlot
                        {...rowProps}
                        key={`${fleetCategory.categoryCode}`}
                        catalogItem={productCatalog.find((c) => c.code === fleetCategory.categoryCode)}
                        fleetCategory={fleetCategory}
                        isActive={activeCode === fleetCategory.code}
                        isHighlighted={!highlightedCategory && activeCode === fleetCategory.code}
                    />
                </React.Fragment>
            ))}
            {footer}
        </RootSlot>
    );
});
AvailabilityGridRows.displayName = 'AvailabilityGridRows';
