import { useCallback } from 'react';
import { getIsDateHolidayForBranch } from '../lib/getIsDateHolidayForBranch';
import { useSearchFormData } from './useSearchFormData';
import { useSearchFormFieldValue } from './useSearchFormFieldValue';

export const useShouldDisableDate = () => {
    const pickUpBranchCode = useSearchFormFieldValue('pickUpBranch');
    const dropOffBranchCode = useSearchFormFieldValue('dropOffBranch');
    const { getSite } = useSearchFormData();
    const pickUpBranch = typeof pickUpBranchCode === 'string' ? getSite(pickUpBranchCode) : undefined;
    const dropOffBranch = typeof dropOffBranchCode === 'string' ? getSite(dropOffBranchCode) : undefined;

    return useCallback(
        (date: Date, position: 'start' | 'end') => {
            if (position === 'start' && pickUpBranch) {
                return getIsDateHolidayForBranch({ branch: pickUpBranch, date });
            }
            if (position === 'end' && dropOffBranch) {
                return getIsDateHolidayForBranch({ branch: dropOffBranch, date });
            }

            return false;
        },
        [dropOffBranch, pickUpBranch]
    );
};
