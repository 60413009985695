import React from 'react';
import Script from 'next/script';

export type GTMScriptProps = {
    config?: {
        auth?: string;
        id?: string;
        preview?: string;
    };
};

export const GTMScript: React.FC<GTMScriptProps> = ({ config }) =>
    config?.id ? (
        <Script id="google-tag-manager" strategy="afterInteractive">{`
                                        (function(w, d, s, l, i) {
                                            w[l] = w[l] || [];
                                            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                                            var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                                            j.async = true;
                                            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=${config.auth || ''}&gtm_preview=${
                                                config.preview || ''
                                            }&gtm_cookies_win=x';
                                            f.parentNode.insertBefore(j, f);
                                        })(window, document, 'script', 'dataLayer', '${config.id}');
                                    `}</Script>
    ) : null;
