import React, { useMemo } from 'react';
import { Alert, AlertColor, AlertProps } from '@mui/material';
import { mergeSx } from '#/lib/mergeSx';

export type JucyAlertColors = AlertProps['color'] | 'primary' | 'secondary';

export interface JucyAlertProps extends Omit<AlertProps, 'color'> {
    color?: JucyAlertColors;
}

export const JucyAlert: React.FC<JucyAlertProps> = ({ color: propsColor, children, sx: propsSx, ...props }) => {
    const { sx, color } = useMemo(() => {
        if (['primary', 'secondary'].includes(propsColor as string)) {
            return {
                sx: {
                    color: `${propsColor}.main`,
                    borderColor: `${propsColor}.main`,
                    '& .MuiAlert-icon': {
                        color: `${propsColor}.main`,
                    },
                },
                color: undefined,
            };
        }
        return {
            sx: undefined,
            color: propsColor as AlertColor,
        };
    }, [propsColor]);

    return (
        <Alert sx={mergeSx(sx, propsSx)} color={color} {...props}>
            {children}
        </Alert>
    );
};
