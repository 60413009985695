'use client';

import React, { useEffect } from 'react';
import { useContainerQuery } from 'react-container-query';
import { cn } from '@jucy-ui/common';
import { unstable_composeClasses as composeClasses } from '@mui/base/composeClasses';
import { Theme, formHelperTextClasses, grid2Classes, useForkRef } from '@mui/material';
import Box from '@mui/material/Box';
import { formControlClasses } from '@mui/material/FormControl';
import { inputClasses } from '@mui/material/Input';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useSearchFormStore } from '../hooks';
import { getJucySearchFormUtilityClass, jucySearchFormClasses } from '../lib/jucySearchFormClasses';
import { SearchFormStore, SearchFormVariants } from '../types';

const useUtilityClasses = (variant: SearchFormVariants = 'large') => {
    const slots = {
        root: ['root', `${variant}`],
    };

    return composeClasses(slots, getJucySearchFormUtilityClass);
};

export type SearchFormFormComponentProps = Omit<React.FormHTMLAttributes<HTMLFormElement>, 'ref'> & { sx?: SxProps<Theme> };
export const SearchFormFormComponent = React.forwardRef<HTMLFormElement, SearchFormFormComponentProps>(
    ({ children, className, sx, ...props }, ref) => {
        const store = useSearchFormStore();
        const [containerRef, activeVariant] = useVariantByContainerSize(store.variant);
        const classes = useUtilityClasses(activeVariant);
        return (
            <Box sx={{ containerType: 'inline-size' }}>
                <SearchFormFormComponentRoot
                    sx={sx}
                    ownerState={store}
                    ref={useForkRef(ref, containerRef)}
                    className={cn(className, classes.root)}
                    {...props}
                >
                    {children}
                </SearchFormFormComponentRoot>
            </Box>
        );
    }
);
SearchFormFormComponent.displayName = 'SearchFormFormComponent';

export const SearchFormFormComponentRoot = styled('form', {
    name: 'JucySearchForm',
    label: 'JucySearchForm',
    shouldForwardProp: (prop) => prop !== 'ownerState' && prop !== 'action' && prop !== 'sx',
})<{ ownerState: SearchFormStore }>(({ theme }) => ({
    [`& .${jucySearchFormClasses.dateTimeArrow}`]: {
        left: '50%',
        transform: 'translateX(-50%)',
        top: '0.72em',
    },
    [theme.breakpoints.down('sm').replace('@media', '@container')]: {
        [`& .${jucySearchFormClasses.branchSelect}`]: {
            [`.${inputBaseClasses.root}.${outlinedInputClasses.root}:not(.${inputBaseClasses.error})`]: {
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
            },
            ['&:hover']: {
                position: 'relative',
                zIndex: 2,
            },
        },
        [`& .${jucySearchFormClasses.dateSelect}, & .${jucySearchFormClasses.timeSelect}`]: {
            [`.${inputBaseClasses.root}.${outlinedInputClasses.root}:not(.${inputClasses.error})`]: {
                marginTop: '-1px',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
            },
        },
        [`& .${jucySearchFormClasses.dateTimeContainer}`]: {
            flexDirection: 'column',
        },
        [`& .${jucySearchFormClasses.dateTimeArrow}`]: {
            transform: 'rotate(90deg)  translateY(50%) translateX(-50%)',
            left: '50%',
            top: '50%',
        },
        [`& .${jucySearchFormClasses.actionsContainer}`]: {
            marginTop: theme.spacing(3),
            '--Grid-columnSpacing': '8px',
            '--Grid-rowSpacing': '8px',
            [`& .${grid2Classes.root}`]: {
                width: 'calc(100% * 12 / var(--Grid-columns))',
            },
        },
        [`& .${jucySearchFormClasses.driveAgePromoCodeContainer}`]: {
            marginTop: theme.spacing(3),
            '--Grid-columnSpacing': '8px',
            '--Grid-rowSpacing': '8px',
            [`& .${grid2Classes.root}`]: {
                width: 'calc(100% * 12 / var(--Grid-columns))',
            },
        },
        ['& .JucyPromoToggle']: {
            // marginTop: theme.spacing(-1.5),
        },
    },

    [theme.breakpoints.up('sm').replace('@media', '@container')]: {
        [`& .${formHelperTextClasses.root}`]: {
            position: 'absolute',
            bottom: 0,
            top: 'calc(100% - 5px)',
            whiteSpace: 'nowrap',
        },
        [`& .${formControlClasses.root}`]: {
            marginBottom: theme.spacing(3),
        },
    },
}));

const query = {
    loading: {
        maxWidth: 0,
    },
    small: {
        maxWidth: 524,
    },
};

const useVariantByContainerSize = (passedVariant?: SearchFormVariants) => {
    const [{ small: isContainerSmall, loading: isLoading }, containerRef] = useContainerQuery(query, {});
    const [initialVariant, setInitialVariant] = React.useState(() => passedVariant);
    const [activeVariant, setActiveVariant] = React.useState(() => passedVariant);
    const store = useSearchFormStore();
    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (!initialVariant) {
            setInitialVariant(initialVariant);
        }
        if (isContainerSmall) {
            setActiveVariant('small');
        } else {
            setActiveVariant(initialVariant || 'large');
        }
    }, [isLoading, initialVariant, isContainerSmall]);
    store.variant = activeVariant;
    return [containerRef, activeVariant] as const;
};
