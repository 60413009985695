'use client';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';

export interface LoaderProps {
    color?: SvgIconProps['color'];
    size?: number;
    gap?: number;
}

export const LoaderDots = styled(Box, {
    shouldForwardProp: (prop) => !['color', 'sandColor', 'animate', 'size'].includes(prop as string),
    name: 'LoaderDots',
    slot: 'Root',
    overridesResolver: (props, styles) => [styles.root, props.color === 'primary' && styles.primary, props.color === 'secondary' && styles.secondary],
})<LoaderProps>(({ theme, color = 'primary', size = 17, gap = 5 }) => {
    const findColor = (color: SvgIconProps['color']) =>
        (theme.palette[color as keyof typeof theme.palette] as Partial<SimplePaletteColorOptions>).main ??
        (
            {
                action: theme.palette.action.active,
                disabled: theme.palette.action.disabled,
                inherit: undefined,
            } as unknown as SvgIconProps['color']
        )?.[color as keyof SvgIconProps['color']];
    const resolvedColor = findColor(color) || color;
    return {
        '--LoaderDots-bg-color': resolvedColor,
        position: 'relative',
        margin: `0 ${gap + size}px`,
        textIndent: '-9999em',
        animationDelay: '0.7s',
        '&, &:before, &:after': {
            animationName: 'loader-dots',
            display: 'inline-block',
            width: size,
            height: size,
            borderRadius: '50%',
            animationDuration: '1.75s',
            animationIterationCount: 'infinite',
            animationDirection: 'normal',
        },
        '&:before, &:after': {
            position: 'absolute',
            top: '0',
            content: '""',
        },
        '&:before': {
            left: `-${size + gap}px`,
            animationDelay: '0.35s',
        },
        '&:after': {
            left: `${size + gap}px`,
            animationDelay: '1.05s',
        },
        '@keyframes loader-dots': {
            '20%, 35%': {
                backgroundColor: 'var(--LoaderDots-bg-color)',
            },
        },
    };
});
