'use client';
import { ThemeOptions, alpha } from '@mui/material';
import { amber, blue } from '@mui/material/colors';
import createPalette from '@mui/material/styles/createPalette';
import { createThemeColors } from '../lib/createThemeColor';
import { jucyBrandPalette } from './jucy';

export const starBrandPalette = {
    cobaltBlue: {
        DEFAULT: '#0E2038',
        50: '#3374CB',
        100: '#2F6BBB',
        200: '#26589A',
        300: '#1E4579',
        400: '#163359',
        500: '#0E2038',
        600: '#03060B',
        700: '#000000',
        800: '#000000',
        900: '#000000',
        950: '#000000',
    },
    skyBlue: {
        DEFAULT: '#223A5C',
        50: '#6D93C9',
        100: '#5E88C3',
        200: '#4372B5',
        300: '#386098',
        400: '#2D4D7A',
        500: '#223A5C',
        600: '#132033',
        700: '#04060A',
        800: '#000000',
        900: '#000000',
        950: '#000000',
    },
    seaBlue: {
        DEFAULT: '#768D9B',
        50: '#E0E5E8',
        100: '#D4DCE0',
        200: '#BDC8CF',
        300: '#A5B4BD',
        400: '#8EA1AC',
        500: '#768D9B',
        600: '#5C717D',
        700: '#44535D',
        800: '#2C363D',
        900: '#15191C',
        950: '#090B0C',
    },
    sand: {
        DEFAULT: '#FBF6EF',
        50: '#FFFFFF',
        100: '#FFFFFF',
        200: '#FFFFFF',
        300: '#FFFFFF',
        400: '#FFFFFF',
        500: '#FBF6EF',
        600: '#F0DDC2',
        700: '#E5C495',
        800: '#D9AA68',
        900: '#CE913B',
        950: '#BE8330',
    },
    rock: {
        DEFAULT: '#C5D2DB',
        50: '#FFFFFF',
        100: '#FFFFFF',
        200: '#FFFFFF',
        300: '#F7F9FA',
        400: '#DEE6EB',
        500: '#C5D2DB',
        600: '#A2B7C6',
        700: '#809CB0',
        800: '#5F8199',
        900: '#496476',
        950: '#3F5565',
    },
    shell: {
        DEFAULT: '#E9ECF0',
        50: '#FFFFFF',
        100: '#FFFFFF',
        200: '#FFFFFF',
        300: '#FFFFFF',
        400: '#FFFFFF',
        500: '#E9ECF0',
        600: '#C8CFD9',
        700: '#A6B2C3',
        800: '#8596AC',
        900: '#657994',
        950: '#596B83',
    },
    charcoal: {
        DEFAULT: '#333333',
        50: '#8F8F8F',
        100: '#858585',
        200: '#707070',
        300: '#5C5C5C',
        400: '#474747',
        500: '#333333',
        600: '#171717',
        700: '#000000',
        800: '#000000',
        900: '#000000',
        950: '#000000',
    },
    white: '#ffffff',
    black: '#171717',
};

export const starMuiPalette = createPalette({
    primary: {
        light: starBrandPalette.seaBlue[500],
        main: starBrandPalette.skyBlue[500],
        dark: starBrandPalette.cobaltBlue[500],
        contrastText: starBrandPalette.white,
    },
    secondary: {
        light: starBrandPalette.sand[500],
        main: starBrandPalette.rock[500],
        dark: starBrandPalette.cobaltBlue[500],
        contrastText: starBrandPalette.white,
    },
    error: {
        light: jucyBrandPalette.orange[300],
        main: jucyBrandPalette.orange[500],
        dark: jucyBrandPalette.orange[600],
    },
    warning: {
        light: amber[300],
        main: amber[500],
        dark: amber[700],
    },
    info: {
        light: blue[300],
        main: blue[500],
        dark: blue[700],
    },
    success: {
        light: jucyBrandPalette.apple[300],
        main: jucyBrandPalette.apple[500],
        dark: jucyBrandPalette.apple[700],
    },
    charcoal: {
        light: starBrandPalette.charcoal[300],
        main: starBrandPalette.charcoal[500],
        dark: starBrandPalette.charcoal[700],
    },
    light: {
        light: '#ffffff',
        main: '#f7f9fa',
        dark: '#dfe7eb',
        contrastText: starBrandPalette.cobaltBlue[500],
    },
    action: {
        hover: alpha(starBrandPalette.rock[500], 0.1),
    },
    divider: '#9099a9',
    contrastThreshold: 3,
    tonalOffset: 0.2,
});

export const starMuiThemeOptions: ThemeOptions = {
    palette: starMuiPalette,
    colors: createThemeColors(starMuiPalette),
};
