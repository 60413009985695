import { observer } from 'mobx-react-lite';
import React from 'react';
import { RentalExcessReduction } from '@jucy/rentals-api-client';
import clsx from 'clsx';
import { formatCurrency } from '../../../../CurrencyFormatter/CurrencyFormatter';
import { getExcessReductionPriceDetails } from '../../ExcessMapper';

interface PriceDetailsListProps {
    item: Required<RentalExcessReduction>;
    className?: string;
}

const PriceDetailsList: React.FC<PriceDetailsListProps> = observer(({ item, className }) => {
    const priceDetails = getExcessReductionPriceDetails(item);
    return (
        <ul className={clsx(className)}>
            {priceDetails.map((p) => (
                <li key={`item-cost-${p.id}`} className="mb-0">
                    <div className="d-flex justify-content-between">
                        <div className="">{p.label}</div>
                        <div className="text-end" data-cy={p.id}>
                            {typeof p.value === 'number' ? formatCurrency({ value: p.value, currencyCode: p.currency }) : p.value}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
});
PriceDetailsList.displayName = 'PriceDetailsList';

export default PriceDetailsList;
