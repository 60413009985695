import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { loadImage } from '../lib';
import { FleetType, fleetTypeQueryKeys, getFleetTypes } from '../services';

type UseGetFleetTypesOptions = UseQueryOptions<FleetType[], Error, FleetType[], string[]>;

export const useGetFleetTypes = (options?: Omit<UseGetFleetTypesOptions, 'queryKey' | 'queryFn'>) =>
    useQuery({
        queryKey: fleetTypeQueryKeys.list(),
        queryFn: getFleetTypesWithPreloadedImages,
        ...options,
    });

export const getFleetTypesWithPreloadedImages = async () => {
    const fleetCategories = await getFleetTypes();
    await Promise.all(fleetCategories.map((fleetCategory) => fleetCategory.thumbnail && loadImage(fleetCategory.thumbnail))).catch((e) => {
        console.error('Failed to load fleet category images', e);
    });
    return fleetCategories;
};
