import Cookies from 'universal-cookie';
import { addDays } from 'date-fns';

export const cookies = new Cookies();

const isServer = typeof window === 'undefined';
const tld = isServer ? undefined : window.jucy.config.env === 'dev' ? 'localhost' : window.location.hostname.includes('jucy') ? '.jucy.com' : '.starrv.com';
const paramsToPreserve = [
    'cjevent',
    'cjdata',
    'cfclick',
    'utm_source',
    'utm_medium',
    'utm_content',
    'clientId',
    'gclid',
    'sessionId',
    '_gl',
    'tduid',
    'kayakclickid',
    'affiliateId',
    'fbclid',
];

const affiliateParams = [
    { param: 'cjevent', expireInDays: 30 },
    { param: 'cjdata', expireInDays: 30 },
    { param: 'cfclick', expireInDays: 30 },
    { param: 'tduid', expireInDays: 30 },
    { param: 'kayakclickid', expireInDays: 30 },
    { param: 'affiliateId', expireInDays: 30 },
];

const getAlternateAffiliateParams = (param: string) => {
    if (param.startsWith('cj')) {
        return affiliateParams.filter((p) => !p.param.startsWith('cj'));
    }
    if (param.startsWith('cf')) {
        return affiliateParams.filter((p) => !p.param.startsWith('cf'));
    }
    return affiliateParams.filter((p) => p.param !== param);
};
const getAffiliateParam = (param: string) => affiliateParams.find((p) => p.param === param);

const store = {
    persist: (value: StoredAttributionParam[]) => {
        cookies.set('jac', value, {
            domain: tld,
            expires: addDays(new Date(), 365),
            path: '/',
            secure: tld !== 'localhost',
            httpOnly: false,
            sameSite: false,
        });
    },
    set: (value: StoredAttributionParam) => {
        const data = store.getAll().filter((d) => d.param !== value.param);
        data.push(value);
        store.persist(data);
    },
    getAll: (): StoredAttributionParam[] => {
        try {
            const data = cookies.get<StoredAttributionParam[]>('jac') || [];
            return data
                .map((d) => ({
                    ...d,
                    expires: new Date(d.expires),
                }))
                .filter((d) => d.expires > new Date());
        } catch (e) {
            return [];
        }
    },
    get: (key: string): StoredAttributionParam | undefined => store.getAll().find((d) => d.param === key),
    removeItem: (key: string) => {
        const data = store.getAll().filter((d) => d.param !== key);
        store.persist(data);
    },
};

type StoredAttributionParam = {
    param: string;
    value: string;
    expires: Date;
};

class JucyAttribution {
    params = paramsToPreserve;

    init() {
        if (isServer) {
            return;
        }
        for (const [param, value] of this.getFromSearchParams()) {
            if (!value) {
                continue;
            }
            const affiliateParam = getAffiliateParam(param);
            if (affiliateParam) {
                const paramsToRemove = getAlternateAffiliateParams(affiliateParam.param);
                for (const toRemove of paramsToRemove) {
                    store.removeItem(toRemove.param);
                }
            }
            const expireInDays = affiliateParam?.expireInDays || 365;
            const expires = addDays(new Date(), expireInDays);
            store.set({ param, value, expires });
        }
    }

    getFromSearchParams() {
        const result = new Map<string, string>();
        if (isServer) {
            return result;
        }
        const url = new URL(window.location.href);
        for (const param of this.params) {
            for (const [key, value] of url.searchParams) {
                if (value && param.toLowerCase() === key.toLowerCase()) {
                    result.set(param, value);
                }
            }
        }
        return result;
    }

    getValue(param: string) {
        const result = store.get(param);
        return result?.value;
    }

    getFromStorage() {
        const result = new Map<string, string>();
        if (isServer) {
            return result;
        }
        for (const value of store.getAll()) {
            result.set(value.param, value.value);
        }

        return result;
    }

    getForwardUrlSearchParams() {
        if (isServer) {
            return new URLSearchParams();
        }
        const attributionParams = this.getFromStorage();
        const searchParams = new URLSearchParams(Object.fromEntries(attributionParams));
        const fbc = cookies.get('_fbc');
        const fbp = cookies.get('_fbp');
        if (fbc) {
            searchParams.set('_fbc', fbc);
        }
        if (fbp) {
            searchParams.set('_fbp', fbp);
        }
        return searchParams;
    }
}

export const jucyAttribution = new JucyAttribution();
