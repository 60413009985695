import { SetOptional, SetRequired } from 'type-fest';
import { BookingPaymentInfo, MerchantFee, ProductLine, type TripDetails } from '../models';

export const mapBookingItems = (trip: Partial<TripDetails & { payments?: BookingPaymentInfo[] }>) => {
    const itemCodes =
        trip.lines
            ?.filter((lineItem) => {
                if (lineItem.isMandatory) {
                    return false;
                }
                if (lineItem.qty <= 0) {
                    return false;
                }
                return true;
            })
            .map((line) => line.productCode)
            .filter(Boolean) ?? [];

    const excessProducts = trip.insuranceProducts?.items || [];
    const secondaryProducts = trip.secondaryProducts?.flatMap((i) => i.items) || [];
    const selectedExcess = excessProducts.find((item) => itemCodes.includes(item.productCode));
    const selectedExtras =
        trip.lines?.filter((lineItem) => {
            if (selectedExcess && selectedExcess.productCode === lineItem.productCode) {
                return false;
            }
            if (lineItem.isMandatory) {
                return false;
            }
            if (lineItem.qty <= 0) {
                return false;
            }
            return true;
        }) || [];
    const lineItems = trip.lines?.filter((item) => selectedExcess?.productCode !== item.productCode) || [];
    const mandatoryItems = trip.lines?.filter((item) => item.isMandatory) || [];

    const paymentsWitMerchantFees = trip.payments?.filter((payment) => payment.merchantFeeAmount?.value) as
        | Required<BookingPaymentInfo>[]
        | undefined;

    return {
        itemCodes,
        excessProducts,
        secondaryProducts,
        selectedExcess: selectedExcess ? mapProductToLine(selectedExcess) : undefined,
        selectedExtras,
        lineItems,
        mandatoryItems,
        merchantFees: paymentsWitMerchantFees?.map(mapPaymentToMerchantFee) || [],
    };
};

export const calculateTotal = (trip: Partial<TripDetails & { payments?: BookingPaymentInfo[] }>) => {
    const currencyCode = trip.fleetCategory?.total.currencyCode || '';
    const hireTotal = trip.fleetCategory?.total.value || 0;
    const extrasTotal = trip.lines?.reduce((acc, cur) => acc + cur.total.value, 0) || 0;
    // const mandatoryTotal = lines.mandatoryItems.reduce((acc, cur) => acc + cur.total.value, 0) || 0;
    // const merchantFeesTotal = lines.merchantFees.reduce((acc, cur) => acc + (cur.total.value || 0), 0) || 0;
    return {
        currencyCode,
        value: hireTotal + extrasTotal,
    };
};

export const mapPaymentToMerchantFee = (payment: SetRequired<BookingPaymentInfo, 'merchantFeeName' | 'merchantFeeAmount'>): MerchantFee => ({
    date: payment.date,
    type: payment.type,
    name: payment.merchantFeeName || 'Processing fee',
    total: payment.merchantFeeAmount,
});

export const mapProductToLine = (product: SetOptional<ProductLine, 'total' | 'qty'>): ProductLine => {
    const qty = product.qty || 1;

    return {
        ...product,
        qty,
        total: {
            ...product.price,
            value: getTotalLineValue({
                ...product,
                qty,
            }),
        },
    } as ProductLine;
};

export const getLineValue = (line: SetOptional<ProductLine, 'total' | 'qty'>, bookingTotal = 0, rateTotal = 0): number => {
    let itemValue;
    if (line.productType == 'Daily') {
        itemValue = line.rentalDays * line.price.value;
    } else if (line.productType == 'Percentage') {
        if (line.isPercentageOnTotalCost) {
            itemValue = bookingTotal * (line.price.value / 100);
        } else {
            itemValue = rateTotal * (line.price.value / 100);
        }
    } else {
        itemValue = line.price.value;
    }

    return line.maxPrice?.value && line.maxPrice.value > 0 ? Math.min(itemValue, line.maxPrice.value) : itemValue;
};

export const getTotalLineValue = (line: SetOptional<ProductLine, 'total' | 'qty'>, bookingTotal = 0, rateTotal = 0): number => {
    const itemValue = getLineValue(line, bookingTotal, rateTotal);
    const qty = line.qty || 1;
    return itemValue * qty;
};
