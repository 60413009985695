import { SitesSettingsDefaultTimes, SitesSettingsFleetTypeSettings, SitesSettingsProhibitedPickUpSites, SitesSettingsSites } from '@jucy/rentals-api-client';
import { Type, plainToInstance } from 'class-transformer';
import { SiteSettingsHoliday } from './SiteSettingsHoliday';

export class SiteSettings implements Omit<SitesSettingsSites, 'holidays'> {
    siteName = '';
    siteCode = '';
    fleetTypeSettings: SitesSettingsFleetTypeSettings[] = [];
    @Type(() => SiteSettingsHoliday)
    holidays: SiteSettingsHoliday[] = [];
    defaultTimes: SitesSettingsDefaultTimes[] = [];
    prohibitedPickupSites: SitesSettingsProhibitedPickUpSites[] = [];
    prohibitedDropOffSites: SitesSettingsProhibitedPickUpSites[] = [];

    constructor(props: Partial<SiteSettings>) {
        Object.assign(this, props);
    }

    static fromPlain(props: Partial<SiteSettings>): SiteSettings {
        return plainToInstance(SiteSettings, props);
    }
}
