import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TranslationStore from '../../../store/TranslationStore';
import { formatCurrency } from '../../CurrencyFormatter/CurrencyFormatter';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';
import BookingSummaryPriceRow from './BookingSummaryPriceRow';

const FleetCategorySummary: React.FC = observer(() => {
    const context = useBookingSummaryContext();
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const showAmendment =
        context.summary?.action == 'edit' &&
        context.summary?.amendments?.fleetCategory &&
        (context.summary?.amendments?.fleetCategory.categoryCode !== context.summary?.fleetCategory?.categoryCode ||
            context.summary?.amendments?.fleetCategory.rentalDays !== context.summary?.fleetCategory.rentalDays ||
            context.summary?.amendments?.fleetCategory.total.value !== context.summary?.fleetCategory.total.value);
    return context.summary?.fleetCategory ? (
        <BookingSummaryGroupItem
            titleSmall={true}
            title={t('trip_summary.group.vehicle')}
            className="animate__animated animate__fadeIn"
            isEditable={true}
            editRouteParams={{
                activeTab: context.summary.userMode === 'agent' ? 'results' : 'fleet-category',
            }}
        >
            <BookingSummaryPriceRow
                sx={{
                    textDecoration: showAmendment ? 'line-through' : undefined,
                }}
                data-cy="fleet-category-summary"
                title={context.summary.fleetCategory.name}
                subtitle={`${formatCurrency(context.summary.fleetCategory.dailyRate.value, context.summary.fleetCategory.dailyRate.currencyCode)}/day x ${context.summary.rentalDays} day${
                    context.summary.rentalDays !== 1 ? 's' : ''
                }`}
                price={context.summary.fleetCategory.total}
            />
            {showAmendment && context.summary.amendments?.fleetCategory ? (
                <BookingSummaryPriceRow
                    data-cy="fleet-category-summary"
                    title={context.summary.amendments.fleetCategory.name}
                    subtitle={`${formatCurrency(context.summary.amendments.fleetCategory.dailyRate.value, context.summary.amendments.fleetCategory.dailyRate.currencyCode)}/day x ${
                        context.summary.amendments.rentalDays
                    } day${context.summary.amendments.rentalDays !== 1 ? 's' : ''}`}
                    price={context.summary.amendments.fleetCategory.total}
                />
            ) : null}
        </BookingSummaryGroupItem>
    ) : null;
});
FleetCategorySummary.displayName = 'FleetCategorySummary';
export default FleetCategorySummary;
