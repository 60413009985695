import { makeAutoObservable, reaction, runInAction } from 'mobx';
import ErrorReporter from '../lib/ErrorReporter';
import JucyAPI from '../lib/JucyAPI.js';
import { StoreState } from '../lib/StoreState';
import { HirePeriod } from '../lib/api/model/HirePeriod';
import { RentalSiteSettings } from '../lib/api/model/RentalSiteSettings';
import { Site } from '../lib/api/model/Site';
import { V2ApiResponse } from '../lib/api/model/V2ApiResponse';
import { resolveError } from '../lib/resolveError';
import { FleetType } from '../services';
import { CACHE_TTL, cache } from '../services/cache';

class SitesStore {
    sites: Site[] = [];
    settings: { sites: RentalSiteSettings[]; hirePeriods: HirePeriod[] } = {
        sites: [],
        hirePeriods: [],
    };
    sitesState: StoreState = 'pending';
    settingsState: StoreState = 'pending';
    message = '';
    error?: Error;
    activeSettings: RentalSiteSettings | null = null;
    status = {
        au: true,
        nz: true,
    };

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.state,
            (data) => {
                if (data === 'error') {
                    ErrorReporter.reportError({ error: this.error, tags: { store: 'site' } });
                } else {
                    this.error = undefined;
                    this.message = '';
                }
            }
        );
    }

    get state() {
        if (this.sitesState === this.settingsState) {
            return this.sitesState;
        } else if (this.sitesState === 'loading' || this.settingsState === 'loading') {
            return 'loading';
        } else if (this.sitesState === 'error' || this.settingsState === 'error') {
            return 'error';
        }
        return 'pending';
    }

    initialize(status: { au: boolean; nz: boolean }) {
        this.status = status;
        return Promise.all([this.fetchSites(), this.fetchSiteSettings()]);
    }

    getSitesByFleetType(fleetType?: FleetType) {
        return fleetType && this.sites.filter((s) => s.SiteSettings && s.SiteSettings.some((s) => s.FleetTypeId === fleetType.id));
    }

    getSiteByCode(code: string) {
        return this.sites.find((s) => s.SiteCode === code);
    }

    async fetchSites() {
        this.sites = [];
        this.sitesState = 'loading';
        this.message = '';
        const useCache = this.status.nz && this.status.au;
        const cacheResult = useCache && (await cache.get<Site[]>('sites-list'));
        if (cacheResult && cacheResult.length) {
            this.sites = cacheResult.map((s) => new Site(s));
            this.sitesState = 'done';
        } else {
            try {
                const siteRequests = [];
                if (this.status.nz) {
                    siteRequests.push(JucyAPI.getSites('nz').catch(() => console.error('Failed to load NZ sites')));
                }
                if (this.status.au) {
                    siteRequests.push(JucyAPI.getSites('au').catch(() => console.error('Failed to load AU sites')));
                }
                const siteResponse: V2ApiResponse<Site[]>[] = await Promise.all(siteRequests);

                runInAction(() => {
                    const errResponse = siteResponse.find((r) => r.ResponseType === JucyAPI.responseTypes.error);
                    if (errResponse) {
                        this.message = errResponse.Message;
                        this.sitesState = 'error';
                    } else {
                        this.sites = siteResponse.reduce((data, response) => [...response.Data, ...data], [] as Site[]).map((s) => new Site(s));
                        this.sitesState = 'done';
                        cache.set('sites-list', this.sites, CACHE_TTL.ONE_HOUR * 2);
                    }
                });
            } catch (e) {
                resolveError(e).then(({ error, message }) => {
                    runInAction(() => {
                        this.sitesState = 'error';
                        this.error = error;
                        this.message = message;
                    });
                });
            }
        }
        return this.sites;
    }

    async fetchSiteSettings() {
        this.settings = { sites: [], hirePeriods: [] };
        this.settingsState = 'loading';
        this.message = '';
        const cacheResult = await cache.get<{ sites: RentalSiteSettings[]; hirePeriods: HirePeriod[] }>('sites-settings');
        if (cacheResult && cacheResult?.sites?.length) {
            this.settings = cacheResult;
            this.settingsState = 'done';
        } else {
            try {
                const siteSettingsResponse: { sites: RentalSiteSettings[]; hirePeriods: HirePeriod[] } = await JucyAPI.getSitesSettings();
                runInAction(() => {
                    this.settings = siteSettingsResponse;
                    this.settingsState = 'done';
                    cache.set('sites-settings', this.settings, CACHE_TTL.ONE_HOUR * 2);
                });
            } catch (e) {
                resolveError(e).then(({ error, message }) => {
                    runInAction(() => {
                        this.settingsState = 'error';
                        this.error = error;
                        this.message = message;
                    });
                });
            }
        }
        return this.settings;
    }

    getSettingsForSite(site: Site) {
        const siteSettings = site && this.settings?.sites?.find((s) => s.siteCode === site.SiteCode);
        return (
            siteSettings || {
                defaultTimes: [],
                fleetTypeSettings: [],
                holidays: [],
                prohibitedDropOffSites: [],
                prohibitedPickupSites: [],
                siteCode: site?.SiteCode,
                siteName: site?.Name,
            }
        );
    }

    getSiteFleetTypeSettings(site: Site, fleetType: FleetType) {
        const siteSettings = this.getSettingsForSite(site);
        const fleetTypeSettings =
            siteSettings && fleetType && siteSettings && siteSettings.fleetTypeSettings && siteSettings.fleetTypeSettings.find((s) => s.fleetTypeSlug === fleetType.slug);
        return fleetTypeSettings || [];
    }

    setActiveSettings(activeSettings: RentalSiteSettings) {
        this.activeSettings = activeSettings;
    }
}

const siteStore = new SitesStore();
export default siteStore;
