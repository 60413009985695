import { URLSearchParamsInit, createSearchParams } from 'react-router-dom';
import { parse, parseISO } from 'date-fns';
import { SearchFormValues, searchFormValueKeys } from '../components/Forms/TripSearchForm/SearchFormValues';
import SitesStore from '../store/SitesStore';
import urlSearchParamsToPlain from './URLSearchParamsToPlain';

export const queryStringToSearchFormValues = (query?: URLSearchParamsInit | Partial<Record<string, string>>): Omit<SearchFormValues, 'fleetType'> | null => {
    if (query) {
        const queryValues = urlSearchParamsToPlain(createSearchParams(query as URLSearchParamsInit));
        const convertedValues: Partial<SearchFormValues> = searchFormValueKeys.reduce((res, prop) => {
            const value = queryValues[prop];
            if (value) {
                if (prop.endsWith('Location')) {
                    res[prop] = SitesStore.getSiteByCode(value) as never;
                } else if (prop.endsWith('Date')) {
                    res[prop] = parse(value, 'yyyy-MM-dd', new Date()) as never;
                } else if (prop.endsWith('Time')) {
                    res[prop] = parse(value, value.length === 4 ? 'HHmm' : 'HH:mm', new Date()) as never;
                } else {
                    res[prop] = value as never;
                }
            }
            return res;
        }, {} as Partial<SearchFormValues>);
        if (!convertedValues.pickUpDate && queryValues.pickUpDateTime) {
            convertedValues.pickUpDate = parseISO(queryValues.pickUpDateTime);
        }
        if (!convertedValues.pickUpTime && queryValues.pickUpDateTime) {
            convertedValues.pickUpTime = parseISO(queryValues.pickUpDateTime);
        }
        if (!convertedValues.dropOffDate && queryValues.dropOffDateTime) {
            convertedValues.dropOffDate = parseISO(queryValues.dropOffDateTime);
        }
        if (!convertedValues.dropOffTime && queryValues.dropOffDateTime) {
            convertedValues.dropOffTime = parseISO(queryValues.dropOffDateTime);
        }
        if (convertedValues?.pickUpLocation?.Country !== convertedValues?.dropOffLocation?.Country) {
            convertedValues.dropOffLocation = undefined;
        }
        return convertedValues as Omit<SearchFormValues, 'fleetType'>;
    }
    return null;
};
