'use client';
import { lighten, styled } from '@mui/material/styles';
import { comparisonTableCellSharedStyles, comparisonTablePinnedCellSharedStyles } from './shared';

export const ComparisonTableCellPinned = styled('th', {
    name: 'JucyComparisonTableCellPinned',
    slot: 'Root',
})(({ theme }) => ({
    ...comparisonTableCellSharedStyles,
    ...comparisonTablePinnedCellSharedStyles,
    left: '0',
    zIndex: '1',
    borderLeft: '0',
    background: lighten(theme.palette.secondary.main, 0.7),
}));
