import { TripDetails } from '@jucy/rentals-api-client/rentals-api-v3';
import { differenceInDays, format } from 'date-fns';
import appState from '../../../store/AppStateStore';
import BusinessUnitsStore from '../../../store/BusinessUnitsStore';
import FleetTypesStore from '../../../store/FleetTypesStore';
import { BookingCart } from '../../../types/BookingCart';
import { UserMode } from '../../../types/UserMode';
import Quote from '../../api/model/Quote';
import { SalesMonitorProvider } from './SalesMonitorProvider';
import { datadogRum } from '@datadog/browser-rum';
import type { ProductLine } from '@jucy/rentals-api-client/rentals-api-v3/models/ProductLine';
import Product from '#/lib/api/model/Product';
import { LineItem } from '#/lib/api/model/LineItem';
import siteStore from '#/store/SitesStore';
import AccountKeyStore from '#/store/AccountKeyStore';

export interface DDLineItem {
    name: string;
    code: string;
    price: number;
    quantity: number;
    brand: string;
    category: string;
    businessUnit?: string;
    availability?: string;
    dailyRate: number;
}

export const isQuote = (quote: unknown): quote is Quote => Boolean(quote instanceof Quote || (quote as Quote).PickUpSite !== undefined);

export const isTripDetails = (quote: unknown): quote is TripDetails => Boolean((quote as TripDetails).secondaryProducts !== undefined || (quote as TripDetails).fleetCategory !== undefined);
export const isProductLine = (lineItem: unknown): lineItem is ProductLine => Boolean((lineItem as ProductLine)?.name !== undefined);
export const isProduct = (lineItem: unknown): lineItem is Product => Boolean((lineItem as Product)?.Name !== undefined);

export class DataDogSalesMonitorProvider implements SalesMonitorProvider {
    static get brand(): string {
        return appState.brand === 'jucy' ? 'Star' : 'JUCY Rentals';
    }

    static mapRegion(quote?: Quote | TripDetails | BookingCart): string {
        if (quote && isTripDetails(quote)) {
            return siteStore.getSiteByCode(quote.pickUpLocation)?.CountryCode || 'unknown';
        }
        if (quote && isQuote(quote)) {
            return quote.PickUpSite?.CountryCode || 'unknown';
        }
        return 'unknown';
    }

    static mapBusinessUnit(quote?: Quote | TripDetails | BookingCart): string {
        if (quote && isTripDetails(quote)) {
            return BusinessUnitsStore.getBySiteCode(quote.pickUpLocation)?.name || 'unknown';
        }
        if (quote && isQuote(quote)) {
            return BusinessUnitsStore.getBusinessUnitById(quote.PickUpSite?.BusinessUnit)?.name || 'unknown';
        }
        return 'unknown';
    }

    static mapFleetCategory(quote: Quote | TripDetails | BookingCart): DDLineItem {
        if (quote && isTripDetails(quote)) {
            return {
                name: quote.fleetCategory.name,
                code: quote.fleetCategory.categoryCode,
                price: quote.fleetCategory.total.value,
                quantity: 1,
                brand: DataDogSalesMonitorProvider.brand,
                category: FleetTypesStore.getFleetTypeById(quote.fleetCategory.type)?.name || '',
                businessUnit: DataDogSalesMonitorProvider.mapBusinessUnit(quote),
                availability: quote.fleetCategory.availability,
                dailyRate: quote.fleetCategory.dailyRate.value,
            };
        }
        if (quote && isQuote(quote)) {
            return {
                name: quote.FleetCategory.Name,
                code: quote.FleetCategory.CategoryCode,
                price: quote.FleetCategory.Total.Value,
                quantity: 1,
                brand: DataDogSalesMonitorProvider.brand,
                category: FleetTypesStore.getFleetTypeById(quote.FleetCategory.Type)?.name || '',
                businessUnit: DataDogSalesMonitorProvider.mapBusinessUnit(quote),
                availability: quote.FleetCategory.Availability,
                dailyRate: quote.FleetCategory.DailyRate.Value,
            };
        }
        return {
            name: 'unknown',
            code: 'unknown',
            price: 0,
            quantity: 1,
            brand: 'unknown',
            category: 'unknown',
            businessUnit: DataDogSalesMonitorProvider.mapBusinessUnit(quote),
            availability: 'unknown',
            dailyRate: 0,
        };
    }

    static mapLineItem(lineItem: ProductLine | Product | LineItem, quote?: Quote | TripDetails | BookingCart): DDLineItem {
        if (isProductLine(lineItem)) {
            return {
                name: lineItem.name,
                code: lineItem.productCode || lineItem.productId,
                price: lineItem.total.value,
                quantity: lineItem.qty ? lineItem.qty : 1,
                brand: DataDogSalesMonitorProvider.brand,
                category: lineItem.category,
                businessUnit: DataDogSalesMonitorProvider.mapBusinessUnit(quote),
                dailyRate: lineItem.price && lineItem.price.value ? lineItem.price.value : lineItem.total.value,
            };
        }
        if (isProduct(quote)) {
            return {
                name: lineItem.Name,
                code: lineItem.ProductCode || lineItem.ProductId,
                price: lineItem.Total.Value,
                quantity: lineItem.Qty ? lineItem.Qty : 1,
                brand: DataDogSalesMonitorProvider.brand,
                category: lineItem.Category,
                businessUnit: DataDogSalesMonitorProvider.mapBusinessUnit(quote),
                dailyRate: lineItem.Price && lineItem.Price.Value ? lineItem.Price.Value : lineItem.Total.Value,
            };
        }
        return {
            name: 'unknown',
            code: 'unknown',
            price: 0,
            quantity: 1,
            brand: 'unknown',
            category: 'unknown',
            businessUnit: DataDogSalesMonitorProvider.mapBusinessUnit(quote),
            dailyRate: 0,
        };
    }

    static lineItemsFromQuote(quote: Quote | TripDetails | BookingCart): DDLineItem[] {
        let lines: (ProductLine | Product | LineItem)[] = [];
        if (isTripDetails(quote)) {
            lines = quote.lines || [];
        }
        if (isQuote(quote)) {
            lines = quote.Lines || [];
        }
        return [DataDogSalesMonitorProvider.mapFleetCategory(quote), ...lines.map((lineItem) => DataDogSalesMonitorProvider.mapLineItem(lineItem, quote))];
    }

    init(): void {}

    confirmPurchase(quote: Quote): void {
        datadogRum.addAction('purchase', {
            timeToPickup: differenceInDays(new Date(quote.PickUpDate), new Date()),
            paymentType: quote.paymentOptionId,
            bookingStatus: quote.BookingStatus,
            pickupLocation: quote.PickUpSite?.Name,
            pickupDate: format(quote.PickUpDateTime, 'dd/MM/yyy'),
            pickupTime: format(quote.PickUpDateTime, 'HH:mm'),
            dropoffLocation: quote.DropOffSite?.Name,
            dropoffDate: format(quote.DropOffDateDateTime, 'dd/MM/yyy'),
            dropoffTime: format(quote.DropOffDateDateTime, 'HH:mm'),
            licenseIssueCountry: quote.HirerDetails.DriversLicenceCountry,
            hireDays: quote.RentalDays,
            total: quote.TotalPrice.Value,
            campaignCode: quote.FleetCategory.campaignCode || '',
            businessUnit: DataDogSalesMonitorProvider.mapBusinessUnit(quote),
            lines: DataDogSalesMonitorProvider.lineItemsFromQuote(quote),
            accountType: AccountKeyStore?.user?.type || 'direct',
            region: DataDogSalesMonitorProvider.mapRegion(quote),
        });
    }

    enabled(quote: Quote, _accountType: UserMode): boolean {
        return Boolean(quote?.BookingStatus === 'Confirmed');
    }
}
