import camperThumbnail from '../../assets/fleet-category-campervan.webp';
import carThumbnail from '../../assets/fleet-category-car.webp';
import motorHomeThumbnail from '../../assets/fleet-category-motorhome.webp';
import { FleetType, V2FleetType } from '../models';

type WithSrc = {
    src: string;
};

const hasSrc = (src: unknown): src is WithSrc => typeof (src as Partial<WithSrc>).src === 'string';

const resolveImage = (src: unknown) => {
    if (hasSrc(src)) {
        return src.src;
    }
    return src as string;
};

const getThumbnail = (slug?: string) => {
    switch (slug) {
        case 'campervan':
            return resolveImage(camperThumbnail);
        case 'car':
            return resolveImage(carThumbnail);
        case 'motorhome':
            return resolveImage(motorHomeThumbnail);
        default:
            return resolveImage(carThumbnail);
    }
};

const getSortOrder = (slug?: string) => {
    switch (slug) {
        case 'campervan':
            return 1;
        case 'car':
            return 2;
        case 'motorhome':
            return 3;
        default:
            return 99;
    }
};

export const mapFleetTypes = async (fleetTypes: V2FleetType[]): Promise<FleetType[]> =>
    fleetTypes
        .map((ft) => ({
            ...ft,
            thumbnail: getThumbnail(ft.slug),
            sortOrder: getSortOrder(ft.slug),
        }))
        .filter(Boolean);
