import { CancellationTermsLink } from './CancellationTermsLink';
import { DialogContent, DialogTitle } from '@mui/material';
import Money from '../../../lib/api/model/Money';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import { PageTitle } from '../../Typograpgy/Title';
import { PaymentOption } from '../lib';
import GetPaymentMethodFn from './GetPaymentMethodFn';

const getFullPaymentOption: GetPaymentMethodFn = ({ summary, countryCode, options, paymentConfig }): PaymentOption => {
    const subTotal = new Money({
        value: summary.totalPrice?.value,
        currencyCode: summary.totalPrice?.currencyCode,
    });
    const merchantFee = paymentConfig?.merchantFeeRate
        ? new Money({
              value: subTotal.value * paymentConfig.merchantFeeRate,
              currencyCode: summary.totalPrice?.currencyCode,
          })
        : undefined;
    const total = new Money({
        value: merchantFee?.value ? subTotal.value + merchantFee?.value : subTotal.value,
        currencyCode: summary.totalPrice?.currencyCode,
    });
    return {
        id: 'full',
        name: 'Full Payment',
        total: total,
        subTotal: subTotal,
        merchantFee: merchantFee,
        amount: <CurrencyFormatter price={total} prefixCurrencyCode={true} />,
        merchantFeeRate: paymentConfig?.merchantFeeRate || undefined,
        modalBody: (
            <>
                <DialogTitle component="div">
                    <PageTitle>Full Payment</PageTitle>
                </DialogTitle>
                <DialogContent>
                    Pay your bookings total of <CurrencyFormatter price={total} prefixCurrencyCode={true} /> today to save time at pick-up
                    <br />
                    <br />
                    <ul className="fa-ul ms-4">
                        <li>
                            <span className="fa-li">
                                <i className="fas fa-check text-green" />
                            </span>{' '}
                            Free cancellation 22 days prior to travel
                        </li>
                        <li>
                            <CancellationTermsLink countryCode={countryCode} />
                        </li>
                    </ul>
                </DialogContent>
            </>
        ),
        gateway: paymentConfig.defaultGateway,
        isRedirect: paymentConfig.defaultGateway === 'dps',
        available: ({ enableFullPayment, forcePayOnPickupOnly, summary }) => Boolean(summary.isOnRequest || (enableFullPayment && !forcePayOnPickupOnly)),
        ...options,
    };
};

export default getFullPaymentOption;
