'use client';
import React, { useMemo } from 'react';
import { FieldValues, FormProvider, FormProviderProps } from 'react-hook-form';
import { FormMeta, FormSchema, FormValidationSchema } from './lib';
import { mapSchemaObjectDescription } from './utils';

const createFormSchemaContext = <SchemaShape extends FieldValues>() => React.createContext<FormSchema<SchemaShape> | null>(null);
export const FormSchemaContext = createFormSchemaContext();

export interface FormSchemaProvideProps<SchemaShape extends FieldValues> extends FormProviderProps<SchemaShape> {
    formMeta?: FormMeta;
    validationSchema: FormValidationSchema<SchemaShape>;
}

export const FormSchemaProvider = <SchemaShape extends FieldValues>({
    validationSchema,
    formMeta,
    children,
    ...props
}: FormSchemaProvideProps<SchemaShape>): React.ReactNode => {
    const formSchema = useMemo(
        () => ({
            validationSchema,
            initialValues: validationSchema.getDefault(),
            formMeta: formMeta || mapSchemaObjectDescription(validationSchema.describe()),
        }),
        [validationSchema, formMeta]
    ) as FormSchema<FieldValues>;
    return (
        <FormSchemaContext.Provider value={formSchema}>
            <FormProvider {...props}>{children}</FormProvider>
        </FormSchemaContext.Provider>
    );
};
