import { waitForElement } from './waitForElement';

export const waitForAndClick = (elementSelector?: string) => {
    if (!elementSelector) {
        return;
    }
    waitForElement(elementSelector).then((element) => {
        element?.click();
    });
};
