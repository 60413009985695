import { parse } from 'date-fns';
import type { ParsedQs } from 'qs';
import { isValidDate } from './isValidDate';
import { setTime } from './setTime';
import { setTimeFromString } from './setTimeFromString';

export const mergeDateTime = (date?: ParsedQs[0] | Date, time?: ParsedQs[0] | Date): Date | undefined => {
    let result: Date | undefined;
    if (date && typeof date === 'string') {
        const parsed = parse(date, 'yyyy-MM-dd', result || new Date());
        result = isValidDate(parsed) ? parsed : undefined;
    } else if (date && isValidDate(date)) {
        result = date;
    }

    if (time && typeof time === 'string') {
        const parsed = setTimeFromString(result || new Date(), time);
        result = isValidDate(parsed) ? parsed : result;
    } else if (time && isValidDate(time)) {
        result = setTime(result || new Date(), time);
    }
    return result;
};
