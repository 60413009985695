'use client';
import React, { useCallback } from 'react';
import { DialogProps, ModalProps, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

export const ModalDialog: React.FC<DialogProps> = ({ open, onClose, className, children, ...props }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const toggleModel: ModalProps['onClose'] = useCallback(
        (e: object, r: 'backdropClick' | 'escapeKeyDown') => {
            if (onClose) {
                onClose(e, r);
            }
        },
        [onClose]
    );
    return (
        <Dialog open={open} onClose={toggleModel} fullWidth={true} fullScreen={fullScreen} {...props}>
            <IconButton
                color="primary"
                sx={{
                    position: 'absolute',
                    top: theme.spacing(1),
                    right: theme.spacing(1),
                    height: 40,
                    width: 40,
                    zIndex: 1,
                }}
                aria-label="Close"
                onClick={(e) => toggleModel(e, 'backdropClick')}
                size="large"
            >
                <span aria-hidden="true">×</span>
            </IconButton>
            {children}
        </Dialog>
    );
};
