'use client';
import { useEffect, useState } from 'react';

export const useDelayedLoading = (isLoading?: boolean, delay: number = 0) => {
    const [delayedLoading, setDelayedLoading] = useState(delay ? false : Boolean(isLoading));

    useEffect(() => {
        let timerId:number | undefined;

        if (isLoading) {
            timerId = window.setTimeout(() => {
                setDelayedLoading(true);
            }, delay);
        } else {
            setDelayedLoading(false);
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [isLoading, delay]);

    return delayedLoading;
};
