'use client';
import React, { useCallback, useMemo } from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { useController, useFormContext } from '@jucy-ui/forms';
import { useBrand } from '../../context';
import { FleetTypeRadioGroup, FleetTypeRadioGroupProps } from '../../fields';
import { useSearchFormStore } from '../hooks';
import { getBranchSupportsFleetType, searchFormValuesToData } from '../lib';
import { searchFormRepoStore } from '../store';
import { SearchFormValues } from '../types';

export const SearchFormFleetTypeField = (props: FleetTypeRadioGroupProps) => {
    const store = useSearchFormStore();
    const variant = store.variant;
    const {
        field: { onChange, ...field },
    } = useController({ name: 'fleetType' });
    const { getValues, setValue, getFieldState } = useFormContext<SearchFormValues>();
    const fleetTypeRadioGroupSx = useMemo(
        () => ({
            mb: 2,
            gap: variant === 'small' ? 1 : 2,
        }),
        [variant]
    );
    const brand = useBrand();
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e);
            const fleetType = searchFormRepoStore.fleetTypes.find((fleetType) => fleetType.slug === e.target.value);
            if (!fleetType) {
                return;
            }
            const { pickUpBranch, dropOffBranch, driverAge } = searchFormValuesToData(getValues());
            if (!getFieldState('driverAge').isTouched && fleetType.minimumDriverAge) {
                setValue('driverAge', `${fleetType.minimumDriverAge}`, { shouldTouch: false });
            }
            if (driverAge && fleetType.minimumDriverAge && parseInt(driverAge) < fleetType.minimumDriverAge) {
                setValue('driverAge', '', { shouldTouch: false });
            }
            if (pickUpBranch && !getBranchSupportsFleetType({ branch: pickUpBranch, fleetType })) {
                setValue('pickUpBranch', '', { shouldTouch: false });
            }
            if (dropOffBranch && !getBranchSupportsFleetType({ branch: dropOffBranch, fleetType })) {
                setValue('dropOffBranch', '', { shouldTouch: false });
            }
        },
        [getFieldState, getValues, onChange, setValue]
    );
    return (
        <FleetTypeRadioGroup
            {...props}
            onChange={handleChange}
            shouldDisableFleetType={props.shouldDisableFleetType || ((fleetType) => (brand === 'star' && fleetType.slug !== 'motorhome') || false)}
            defaultValue={props.defaultValue || (brand === 'star' ? 'motorhome' : 'campervan')}
            direction={props.direction || (variant === 'large' ? 'row' : 'column')}
            sx={mergeSx(fleetTypeRadioGroupSx, props.sx)}
            {...field}
        />
    );
};
