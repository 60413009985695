import React from 'react';
import LoadingAnimation from '#/components/LoadingAnimation/LoadingAnimation';
import Spinner from '#/components/LoadingAnimation/Spinner';
import { useIsCallback } from '#/hooks/useIsCallback';
import { Brand } from '#/lib/brand';
import { Box } from '@mui/material';

const PageLoaderContainer: React.FC<{ hasContainer?: boolean; children: React.ReactNode }> = ({ hasContainer, children }) => {
    if (hasContainer) {
        return children;
    }
    return <Box sx={(t) => ({ paddingTop: t.settings?.appBar?.spacer() || '75px' })}>{children}</Box>;
};

const PageLoader: React.FC<{
    variant?: 'spinner' | 'loader';
    children?: React.ReactNode;
    hasContainer?: boolean;
    brand?: Brand;
    id?: string;
}> = ({ id, children, hasContainer, brand, ...props }) => {
    const isPaymentCallback = useIsCallback();
    const variant = props.variant || isPaymentCallback ? 'loader' : 'spinner';
    return (
        <PageLoaderContainer hasContainer={hasContainer}>
            {variant === 'loader' ? (
                <LoadingAnimation id={id} sx={{ pt: 3 }} variant="primary">
                    {children}
                </LoadingAnimation>
            ) : (
                <Spinner id={id} text={children} sx={{ pt: 3 }} center={true} color={brand ? (brand == 'jucy' ? 'green' : 'sea-blue') : undefined} />
            )}
        </PageLoaderContainer>
    );
};

export default PageLoader;
