'use client';

import React, { createContext } from 'react';
import { Brand } from '@jucy-ui/common';
import { DEFAULT_BRAND, DEFAULT_LOCALE } from '../constants';

export type JucyUiContextValue = {
    brand: Brand;
    locale: string;
};
export const JucyUiProviderContext = createContext<JucyUiContextValue>({
    brand: DEFAULT_BRAND,
    locale: DEFAULT_LOCALE,
});

export interface JucyUiProviderProviderProps extends Partial<JucyUiContextValue> {
    children?: React.ReactNode;
}

export const JucyUiProvider: React.FC<JucyUiProviderProviderProps> = ({ brand, locale, children }) => (
    <JucyUiProviderContext.Provider
        value={{
            brand: brand || DEFAULT_BRAND,
            locale: locale || DEFAULT_LOCALE,
        }}
    >
        {children}
    </JucyUiProviderContext.Provider>
);
