import React from 'react';
import { CatalogFloorPlan } from '@jucy/rentals-api-client/rentals-api-v3/models/CatalogFloorPlan';
import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { JucySlider, JucySliderProps } from '../../../../../JucySlider';
import { FloorplanImage } from './FloorplanImage';

export interface FloorplansCarouselProps extends JucySliderProps {
    floorplans: CatalogFloorPlan[];
    onFloorplanClicked?: (floorplan: CatalogFloorPlan, index: number) => void;
}

export const FloorplansCarousel: React.FC<FloorplansCarouselProps> = ({ floorplans, onFloorplanClicked }) => {
    const theme = useTheme();
    const isMd = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
    return (
        <JucySlider
            showDots={false}
            showNav={true}
            componentProps={{
                nav: {
                    sx: {
                        zIndex: 1,
                    },
                },
            }}
            sx={{
                maxWidth: 'calc(100vw - 48px)',
            }}
            slidesToShow={isMd ? 1 : 2}
            config={{
                all: {
                    loop: false,
                    slideGap: theme.spacing(1),
                },
                xs: { slidesToShow: 1 },
                sm: { slidesToShow: 2 },
                md: { slidesToShow: 3 },
                lg: { slidesToShow: 3 },
                xl: { slidesToShow: 3 },
                xxl: { slidesToShow: 3 },
            }}
        >
            {floorplans.map((floorplan, i) => (
                <ButtonBase
                    key={`${floorplan.url}`}
                    sx={{
                        display: 'block',
                        width: '100%',
                    }}
                    onClick={() => {
                        if (onFloorplanClicked) {
                            onFloorplanClicked(floorplan, i);
                        }
                    }}
                >
                    <Box
                        sx={{
                            py: 2,
                            px: 1,
                            background: theme.palette.action.hover,
                            borderRadius: theme.shape.borderRadius,
                        }}
                    >
                        <FloorplanImage floorplan={floorplan} />
                    </Box>
                    <Typography variant="caption">{floorplan.name}</Typography>
                </ButtonBase>
            ))}
        </JucySlider>
    );
};
