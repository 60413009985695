'use client';
import React, { MouseEvent } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Modal, ModalProps } from '../Modal';

export interface ConfirmDialogProps {
    onClose?: ModalProps['onClose'];
    open: ModalProps['open'];
    title?: ModalProps['title'];
    titleIcon?: ModalProps['titleIcon'];
    children: React.ReactNode;
    showCancel?: boolean;
    showOk?: boolean;
    okLabel?: React.ReactNode;
    cancelLabel?: React.ReactNode;
    onConfirm?: (e: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
    onCancel?: (e: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    onClose,
    open,
    okLabel = 'OK',
    cancelLabel = 'Cancel',
    onConfirm,
    title = 'Are you sure?',
    titleIcon = <HelpIcon />,
    children,
    onCancel,

    showCancel,
    showOk,
}) => (
    <Modal title={title} titleIcon={titleIcon} open={open} onClose={onClose}>
        <DialogContent>{children}</DialogContent>
        {showCancel !== false || showOk !== false ? (
            <DialogActions>
                {showCancel !== false ? (
                    <Button
                        variant="outlined"
                        onClick={async (e) => {
                            if (onCancel) {
                                await onCancel(e);
                            }
                            if (!e.isDefaultPrevented() && onClose) {
                                onClose(e, 'escapeKeyDown');
                            }
                        }}
                    >
                        {cancelLabel}
                    </Button>
                ) : null}
                {showOk !== false ? (
                    <Button
                        variant="contained"
                        onClick={async (e) => {
                            if (onConfirm) {
                                await onConfirm(e);
                            }
                        }}
                    >
                        {okLabel}
                    </Button>
                ) : null}
            </DialogActions>
        ) : null}
    </Modal>
);
