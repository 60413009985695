/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { FleetCategory } from './FleetCategory';
import { FleetCategoryFromJSON, FleetCategoryFromJSONTyped, FleetCategoryToJSON } from './FleetCategory';
import type { HirerDetails } from './HirerDetails';
import { HirerDetailsFromJSON, HirerDetailsFromJSONTyped, HirerDetailsToJSON } from './HirerDetails';
import type { QuoteAgentBalanceDue } from './QuoteAgentBalanceDue';
import { QuoteAgentBalanceDueFromJSON, QuoteAgentBalanceDueFromJSONTyped, QuoteAgentBalanceDueToJSON } from './QuoteAgentBalanceDue';
import type { QuoteAgentCollects } from './QuoteAgentCollects';
import { QuoteAgentCollectsFromJSON, QuoteAgentCollectsFromJSONTyped, QuoteAgentCollectsToJSON } from './QuoteAgentCollects';
import type { QuoteAgentCommission } from './QuoteAgentCommission';
import { QuoteAgentCommissionFromJSON, QuoteAgentCommissionFromJSONTyped, QuoteAgentCommissionToJSON } from './QuoteAgentCommission';
import type { QuoteBalanceDue } from './QuoteBalanceDue';
import { QuoteBalanceDueFromJSON, QuoteBalanceDueFromJSONTyped, QuoteBalanceDueToJSON } from './QuoteBalanceDue';
import type { QuoteBalanceDueWithCCFee } from './QuoteBalanceDueWithCCFee';
import { QuoteBalanceDueWithCCFeeFromJSON, QuoteBalanceDueWithCCFeeFromJSONTyped, QuoteBalanceDueWithCCFeeToJSON } from './QuoteBalanceDueWithCCFee';
import type { QuoteTotalPrice } from './QuoteTotalPrice';
import { QuoteTotalPriceFromJSON, QuoteTotalPriceFromJSONTyped, QuoteTotalPriceToJSON } from './QuoteTotalPrice';
import type { RentalLineItem } from './RentalLineItem';
import { RentalLineItemFromJSON, RentalLineItemFromJSONTyped, RentalLineItemToJSON } from './RentalLineItem';

/**
 *
 * @export
 * @interface QuoteWithHirer
 */
export interface QuoteWithHirer {
    /**
     * Quote unique identifier
     * @type {string}
     * @memberof QuoteWithHirer
     */
    quoteId?: string;
    /**
     * Quotes associated reservation Unique identifier
     * @type {number}
     * @memberof QuoteWithHirer
     */
    reservationId?: number;
    /**
     * Quotes reference number to be used when communicating with
     * Jucy
     * @type {string}
     * @memberof QuoteWithHirer
     */
    reservationReference?: string;
    /**
     * Generated passcode required for customers to amend or cancel
     * their reservation
     * @type {string}
     * @memberof QuoteWithHirer
     */
    onlineLogin?: string;
    /**
     * Quote display name
     * @type {string}
     * @memberof QuoteWithHirer
     */
    quoteName?: string;
    /**
     * Quotes pick up locations unique identifier
     * @type {string}
     * @memberof QuoteWithHirer
     */
    pickUpLocationId?: string;
    /**
     * Quotes pick up locations unique short code
     * @type {string}
     * @memberof QuoteWithHirer
     */
    pickUpLocationCode?: string;
    /**
     * Quotes drop off locations unique identifier
     * @type {string}
     * @memberof QuoteWithHirer
     */
    dropOffLocationId?: string;
    /**
     * Quotes drop off locations unique short code
     * @type {string}
     * @memberof QuoteWithHirer
     */
    dropOffLocationCode?: string;
    /**
     * Date time fleet category is to be picked up from pick up location
     * @type {Date}
     * @memberof QuoteWithHirer
     */
    pickUpDate?: Date;
    /**
     * Date time fleet category is to be dropped off at drop off location
     * @type {Date}
     * @memberof QuoteWithHirer
     */
    dropOffDate?: Date;
    /**
     * Number of rental days calculated for quote
     * @type {number}
     * @memberof QuoteWithHirer
     */
    rentalDays?: number;
    /**
     *
     * @type {FleetCategory}
     * @memberof QuoteWithHirer
     */
    fleetCategory?: FleetCategory;
    /**
     *
     * @type {QuoteTotalPrice}
     * @memberof QuoteWithHirer
     */
    totalPrice?: QuoteTotalPrice;
    /**
     *
     * @type {QuoteAgentCommission}
     * @memberof QuoteWithHirer
     */
    agentCommission?: QuoteAgentCommission;
    /**
     *
     * @type {QuoteAgentCollects}
     * @memberof QuoteWithHirer
     */
    agentCollects?: QuoteAgentCollects;
    /**
     *
     * @type {QuoteAgentBalanceDue}
     * @memberof QuoteWithHirer
     */
    agentBalanceDue?: QuoteAgentBalanceDue;
    /**
     * The name of the person creating the booking.
     * @type {string}
     * @memberof QuoteWithHirer
     */
    agentName?: string;
    /**
     *
     * @type {QuoteBalanceDue}
     * @memberof QuoteWithHirer
     */
    balanceDue?: QuoteBalanceDue;
    /**
     *
     * @type {QuoteBalanceDueWithCCFee}
     * @memberof QuoteWithHirer
     */
    balanceDueWithCCFee?: QuoteBalanceDueWithCCFee;
    /**
     * Merchant surcharges rate
     * @type {number}
     * @memberof QuoteWithHirer
     */
    cCSurchargePercentage?: number;
    /**
     * List of insurance, additional and mandatory fees assigned
     * @type {Array<RentalLineItem>}
     * @memberof QuoteWithHirer
     */
    lines?: Array<RentalLineItem>;
    /**
     *
     * @type {HirerDetails}
     * @memberof QuoteWithHirer
     */
    hirerDetails?: HirerDetails;
}

/**
 * Check if a given object implements the QuoteWithHirer interface.
 */
export function instanceOfQuoteWithHirer(value: object): value is QuoteWithHirer {
    return true;
}

export function QuoteWithHirerFromJSON(json: any): QuoteWithHirer {
    return QuoteWithHirerFromJSONTyped(json, false);
}

export function QuoteWithHirerFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteWithHirer {
    if (json == null) {
        return json;
    }
    return {
        quoteId: json['QuoteId'] == null ? undefined : json['QuoteId'],
        reservationId: json['ReservationId'] == null ? undefined : json['ReservationId'],
        reservationReference: json['ReservationReference'] == null ? undefined : json['ReservationReference'],
        onlineLogin: json['OnlineLogin'] == null ? undefined : json['OnlineLogin'],
        quoteName: json['QuoteName'] == null ? undefined : json['QuoteName'],
        pickUpLocationId: json['PickUpLocationId'] == null ? undefined : json['PickUpLocationId'],
        pickUpLocationCode: json['PickUpLocationCode'] == null ? undefined : json['PickUpLocationCode'],
        dropOffLocationId: json['DropOffLocationId'] == null ? undefined : json['DropOffLocationId'],
        dropOffLocationCode: json['DropOffLocationCode'] == null ? undefined : json['DropOffLocationCode'],
        pickUpDate: json['PickUpDate'] == null ? undefined : stringToDate(json['PickUpDate']),
        dropOffDate: json['DropOffDate'] == null ? undefined : stringToDate(json['DropOffDate']),
        rentalDays: json['RentalDays'] == null ? undefined : json['RentalDays'],
        fleetCategory: json['FleetCategory'] == null ? undefined : FleetCategoryFromJSON(json['FleetCategory']),
        totalPrice: json['TotalPrice'] == null ? undefined : QuoteTotalPriceFromJSON(json['TotalPrice']),
        agentCommission: json['AgentCommission'] == null ? undefined : QuoteAgentCommissionFromJSON(json['AgentCommission']),
        agentCollects: json['AgentCollects'] == null ? undefined : QuoteAgentCollectsFromJSON(json['AgentCollects']),
        agentBalanceDue: json['AgentBalanceDue'] == null ? undefined : QuoteAgentBalanceDueFromJSON(json['AgentBalanceDue']),
        agentName: json['AgentName'] == null ? undefined : json['AgentName'],
        balanceDue: json['BalanceDue'] == null ? undefined : QuoteBalanceDueFromJSON(json['BalanceDue']),
        balanceDueWithCCFee: json['BalanceDueWithCCFee'] == null ? undefined : QuoteBalanceDueWithCCFeeFromJSON(json['BalanceDueWithCCFee']),
        cCSurchargePercentage: json['CCSurchargePercentage'] == null ? undefined : json['CCSurchargePercentage'],
        lines: json['Lines'] == null ? undefined : (json['Lines'] as Array<any>).map(RentalLineItemFromJSON),
        hirerDetails: json['HirerDetails'] == null ? undefined : HirerDetailsFromJSON(json['HirerDetails']),
    };
}

export function QuoteWithHirerToJSON(value?: QuoteWithHirer | null): any {
    if (value == null) {
        return value;
    }
    return {
        QuoteId: value['quoteId'],
        ReservationId: value['reservationId'],
        ReservationReference: value['reservationReference'],
        OnlineLogin: value['onlineLogin'],
        QuoteName: value['quoteName'],
        PickUpLocationId: value['pickUpLocationId'],
        PickUpLocationCode: value['pickUpLocationCode'],
        DropOffLocationId: value['dropOffLocationId'],
        DropOffLocationCode: value['dropOffLocationCode'],
        PickUpDate: value['pickUpDate'] == null ? undefined : dateToString(value['pickUpDate']),
        DropOffDate: value['dropOffDate'] == null ? undefined : dateToString(value['dropOffDate']),
        RentalDays: value['rentalDays'],
        FleetCategory: FleetCategoryToJSON(value['fleetCategory']),
        TotalPrice: QuoteTotalPriceToJSON(value['totalPrice']),
        AgentCommission: QuoteAgentCommissionToJSON(value['agentCommission']),
        AgentCollects: QuoteAgentCollectsToJSON(value['agentCollects']),
        AgentBalanceDue: QuoteAgentBalanceDueToJSON(value['agentBalanceDue']),
        AgentName: value['agentName'],
        BalanceDue: QuoteBalanceDueToJSON(value['balanceDue']),
        BalanceDueWithCCFee: QuoteBalanceDueWithCCFeeToJSON(value['balanceDueWithCCFee']),
        CCSurchargePercentage: value['cCSurchargePercentage'],
        Lines: value['lines'] == null ? undefined : (value['lines'] as Array<any>).map(RentalLineItemToJSON),
        HirerDetails: HirerDetailsToJSON(value['hirerDetails']),
    };
}
