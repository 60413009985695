/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';

/**
 *
 * @export
 * @interface CompanyDetails
 */
export interface CompanyDetails {
    /**
     *
     * @type {string}
     * @memberof CompanyDetails
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyDetails
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyDetails
     */
    phone?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyDetails
     */
    logo?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyDetails
     */
    terms?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyDetails
     */
    website?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyDetails
     */
    taxId?: string;
}

/**
 * Check if a given object implements the CompanyDetails interface.
 */
export function instanceOfCompanyDetails(value: object): value is CompanyDetails {
    return true;
}

export function CompanyDetailsFromJSON(json: any): CompanyDetails {
    return CompanyDetailsFromJSONTyped(json, false);
}

export function CompanyDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDetails {
    if (json == null) {
        return json;
    }
    return {
        email: json['email'] == null ? undefined : json['email'],
        name: json['name'] == null ? undefined : json['name'],
        phone: json['phone'] == null ? undefined : json['phone'],
        logo: json['logo'] == null ? undefined : json['logo'],
        terms: json['terms'] == null ? undefined : json['terms'],
        website: json['website'] == null ? undefined : json['website'],
        taxId: json['taxId'] == null ? undefined : json['taxId'],
    };
}

export function CompanyDetailsToJSON(value?: CompanyDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        email: value['email'],
        name: value['name'],
        phone: value['phone'],
        logo: value['logo'],
        terms: value['terms'],
        website: value['website'],
        taxId: value['taxId'],
    };
}
