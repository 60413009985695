'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { cn } from '@jucy-ui/common/lib/cn';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';


export const JucySliderTrack = React.forwardRef<HTMLDivElement, BoxProps>(({ children, className, sx, ...props }, ref) => (
    <Box
        ref={ref}
        className={cn('blaze-track', className)}
        sx={mergeSx(
            {
                willChange: 'transform',
                touchAction: 'pan-y',
                display: 'flex',
                gap: 'var(--slide-gap)',
                boxSizing: 'border-box',
                alignItems:'center',
                '--slide-width': 'calc((100% - (var(--slides-to-show) - 1) * var(--slide-gap)) / var(--slides-to-show))',
                '&.blaze-track > *': {
                    boxSizing: 'border-box',
                    width: 'var(--slide-width)',
                    flexShrink: 0,
                },
            },
            sx
        )}
        {...props}
    >
        {children}
    </Box>
));
JucySliderTrack.displayName = 'JucySliderTrack';
