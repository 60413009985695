import ErrorReporter from '#/lib/ErrorReporter';
import { format } from 'date-fns';
import round from 'lodash/round';
import { PartialSearchFormValues } from '../components/Forms/TripSearchForm/SearchFormValues';
import config from '../config';
import AccountKeyStore from '../store/AccountKeyStore';
import BusinessUnitsStore from '../store/BusinessUnitsStore';
import FleetTypesStore from '../store/FleetTypesStore';
import { BookingCart } from '../types/BookingCart';
import * as SalesMonitor from './SalesMonitoring';
import { GAEcommerce } from './SalesMonitoring/Providers/GAEcommerce';
import FleetCategoryAvailability from './api/model/FleetCategoryAvailability';
import InsuranceProduct from './api/model/InsuranceProduct';
import { LineItem } from './api/model/LineItem';
import Product from './api/model/Product';
import Quote from './api/model/Quote';
import { datadogRum } from '@datadog/browser-rum';
import { DataDogSalesMonitorProvider } from '#/lib/SalesMonitoring/Providers/DataDogSalesMonitorProvider';

if (typeof window !== 'undefined' && !window.dataLayer) {
    window.dataLayer = [];
}

export function logTracking(...args: unknown[]) {
    if (config.logTracking) {
        // eslint-disable-next-line prefer-spread,no-console
        console.log.apply(console, args);
    }
}

export const isLineItem = (item: unknown): item is LineItem => (item as LineItem).ProductCode !== undefined;

export default class Events {
    // ReservationStore.totalIncludingCreditCardFee does not seem to be reliable at the points
    // where the events are triggered - hack credit card fee here and wait for refactoring of
    // ReservationStore.
    static calculateTotal(quote: Quote | BookingCart): number {
        const total = (quote as Quote).TotalPrice?.Value || (quote as BookingCart).totalPrice?.value || (quote as BookingCart).totalPrice?.value || 0;
        return round(total * 1.02, 2);
    }

    static trackAddInsuranceOption(quote?: Quote | BookingCart) {
        const data = (quote as Quote)?.InsuranceLine || (quote as BookingCart)?.selectedExcess;
        if (!data) {
            return;
        }
        const excessItem = isLineItem(data) ? data.asV3ProductLine() : data;

        ErrorReporter.addBreadcrumb({
            category: 'insurance',
            message: `Insurance selected: ${excessItem.name}`,
            data: {
                quoteId: (quote as Quote)?.id,
                productName: excessItem.name,
                productId: excessItem.productId,
            },
        });
        if (!AccountKeyStore.trackingEnabled) {
            return;
        }
        const businessUnitId = (quote as Quote)?.PickUpSite?.BusinessUnit || (quote as BookingCart)?.pickUpLocation?.BusinessUnit || '';
        const businessUnit = BusinessUnitsStore.getBusinessUnitById(businessUnitId);
        try {
            window.dataLayer.push({
                event: 'addToCart',
                step: 4,
                ecommerce: {
                    event: 'addToCart',
                    currencyCode: excessItem.price.currencyCode,
                    add: {
                        products: [
                            {
                                name: excessItem.name,
                                id: excessItem.productId,
                                price: excessItem.total.value,
                                quantity: 1,
                                brand: businessUnit?.code,
                                category: excessItem.category,
                                dimension3: businessUnit?.name,
                                metric1: excessItem.price.value,
                            },
                        ],
                    },
                },
                bookingCost: quote ? this.calculateTotal(quote) : 0,
                timeSpent: 0,
            });
            datadogRum.addAction('add-to-cart', {
                ...DataDogSalesMonitorProvider.mapLineItem(excessItem, quote),
                region: DataDogSalesMonitorProvider.mapRegion(quote),
                accountType: AccountKeyStore?.user?.type || 'direct',
            });
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackFleetCategorySelected(data: Quote | BookingCart) {
        const quoteId = (data as Quote).id || '';
        const fleetCategory = (data as Quote).FleetCategory?.Name || (data as BookingCart).fleetCategory?.name || 'unknown';
        const categoryId = (data as Quote).FleetCategory?.Id || (data as BookingCart).fleetCategory?.id || '';
        const currencyCode = (data as Quote).TotalPrice?.CurrencyCode || (data as BookingCart).totalPrice?.currencyCode || 'unknown';
        const bookingCost = (data as Quote).TotalPrice?.Value || (data as BookingCart).totalPrice?.value || 0;
        ErrorReporter.addBreadcrumb({
            category: 'vehicle',
            message: `Vehicle selected: ${fleetCategory}`,
            data: {
                quoteId: quoteId,
                categoryName: fleetCategory,
                categoryId: categoryId,
            },
        });
        if (!AccountKeyStore.trackingEnabled) {
            return;
        }
        try {
            window.dataLayer.push({
                event: 'addToCart',
                step: 3,
                ecommerce: {
                    currencyCode: currencyCode,
                    add: {
                        products: GAEcommerce.lineItemsFromQuote(data),
                    },
                },
                bookingCost: bookingCost,
                timeSpent: 0,
            });
            datadogRum.addAction('add-to-cart', {
                ...DataDogSalesMonitorProvider.mapFleetCategory(data),
                region: DataDogSalesMonitorProvider.mapRegion(data),
                accountType: AccountKeyStore?.user?.type || 'direct',
            });
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackBookingInitiated(searchParams: PartialSearchFormValues) {
        if (!AccountKeyStore.trackingEnabled) {
            return;
        }
        const businessUnitName = BusinessUnitsStore.getBusinessUnitById(searchParams?.pickUpLocation?.BusinessUnit)?.name;
        try {
            window.dataLayer.push({
                event: 'bookingInitiated',
                step: 1,
                category: searchParams.fleetType?.name,
                pickupLocation: searchParams.pickUpLocation?.Name,
                pickupDate: format(searchParams.pickUpDate as Date, 'dd/MM/yyyy'),
                pickupTime: format(searchParams.pickUpTime as Date, 'HH:mm'),
                dropoffLocation: searchParams.dropOffLocation?.Name,
                dropoffDate: format(searchParams.dropOffDate as Date, 'dd/MM/yyyy'),
                dropoffTime: format(searchParams.dropOffTime as Date, 'HH:mm'),
                coupon: searchParams.promoCode,
                dimension3: businessUnitName,
            });
            datadogRum.addAction('search', {
                brand: DataDogSalesMonitorProvider.brand,
                region: searchParams.pickUpLocation?.CountryCode,
                category: searchParams.fleetType?.name,
                pickupLocation: searchParams.pickUpLocation?.Name,
                pickupDate: format(searchParams.pickUpDate as Date, 'yyyy-MM-dd'),
                pickupTime: format(searchParams.pickUpTime as Date, 'HH:mm'),
                dropoffLocation: searchParams.dropOffLocation?.Name,
                dropoffDate: format(searchParams.dropOffDate as Date, 'yyyy-MM-dd'),
                dropoffTime: format(searchParams.dropOffTime as Date, 'HH:mm'),
                campaignCode: searchParams.promoCode,
                businessUnit: businessUnitName,
                accountType: AccountKeyStore?.user?.type || 'direct',
            });
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackProductImpressions(businessUnitId: string | undefined, availableFleetCategories: FleetCategoryAvailability[]) {
        if (!AccountKeyStore.trackingEnabled) {
            return;
        }
        if (!availableFleetCategories.length) {
            return;
        }
        try {
            const businessUnit = BusinessUnitsStore.getBusinessUnitById(businessUnitId);
            window.dataLayer.push({
                event: 'productImpressions',
                step: 2,
                ecommerce: {
                    currencyCode: availableFleetCategories[0].Total.CurrencyCode,
                    impressions: availableFleetCategories.map((category, i) => {
                        const fleetType = FleetTypesStore.getFleetTypeById(category.Type);
                        return {
                            name: category.Name,
                            id: category.Id,
                            price: category.Total.Value,
                            brand: businessUnit?.code,
                            category: fleetType ? fleetType.name : '',
                            list: 'Search Results',
                            position: i,
                            dimension3: businessUnit?.name,
                            dimension13: category.Availability,
                            metric1: category.DailyRate.Value,
                        };
                    }),
                },
                timeSpent: 0,
            });
            datadogRum.addAction('product-impression', {
                fleetCategories: availableFleetCategories.map((category) => {
                    const fleetType = FleetTypesStore.getFleetTypeById(category.Type);
                    return {
                        name: category.Name,
                        code: category.Id,
                        price: category.Total.Value,
                        brand: businessUnit?.code,
                        category: fleetType?.name || '',
                        businessUnit: businessUnit?.name,
                        availability: category.Availability,
                        dailyRate: category.DailyRate.Value,
                        region: businessUnit?.countryCode || 'unknown',
                        accountType: AccountKeyStore?.user?.type || 'direct',
                    };
                }),
            });
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackExtrasCartEvent(action: string, product: Product | InsuranceProduct, delta: number, quote?: Quote | BookingCart) {
        ErrorReporter.addBreadcrumb({
            category: 'extras',
            message: `Extra added: ${product.Name}`,
            data: {
                productName: product.Name,
                productId: (product as Product).ProductId || (product as unknown as { Id: string }).Id,
                action,
                delta,
                quoteId: (quote as Quote)?.id,
            },
        });
        if (!AccountKeyStore.trackingEnabled) {
            return;
        }
        try {
            const event = action === 'add' ? 'addToCart' : 'removeFromCart';
            const days = (quote as Quote)?.RentalDays || (quote as BookingCart)?.rentalDays;
            const pickUpLocationCode = (quote as Quote)?.PickUpSite?.code || (quote as BookingCart)?.pickUpLocation?.code;
            const businessUnit = BusinessUnitsStore.getBySiteCode(pickUpLocationCode);
            window.dataLayer.push({
                event,
                step: 5,
                ecommerce: {
                    currencyCode: product.Price.CurrencyCode,
                    [action]: {
                        products: [
                            {
                                name: product.Name,
                                id: product.ProductId,
                                price: product.ProductType === 'Daily' && days ? product.Price.Value * days : product.Price.Value,
                                quantity: Math.abs(delta),
                                brand: businessUnit?.code,
                                category: product.Category,
                                dimension3: businessUnit?.name,
                                metric1: product.Price.Value,
                            },
                        ],
                    },
                },
                bookingCost: quote ? this.calculateTotal(quote) : 0,
                timeSpent: 0,
            });
            datadogRum.addAction(`${action === 'add' ? 'add-to-cart' : 'remove-from-cart'}`, {
                ...DataDogSalesMonitorProvider.mapLineItem(product, quote),
                region: DataDogSalesMonitorProvider.mapRegion(quote),
                accountType: AccountKeyStore?.user?.type || 'direct',
            });
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackCheckout(quote: Quote | BookingCart, quoteEmailed = false) {
        if (!AccountKeyStore.trackingEnabled) {
            return;
        }
        try {
            window.dataLayer.push({
                event: 'checkout',
                step: 6,
                ecommerce: {
                    currencyCode: (quote as Quote).TotalPrice?.CurrencyCode || (quote as BookingCart).totalPrice?.currencyCode,
                    checkout: {
                        products: GAEcommerce.lineItemsFromQuote(quote),
                    },
                },
                bookingCost: this.calculateTotal(quote),
                timeSpent: 0,
                licenseIssueCountry: (quote as Quote).HirerDetails?.DriversLicenceCountry || (quote as BookingCart).hirerDetails.driversLicenceCountry,
                passengers: (quote as Quote).HirerDetails.NumberOfPeople || (quote as BookingCart).hirerDetails.numberOfPeople,
                quoteEmailed,
            });
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackPurchase(quote: Quote, _accountId?: string) {
        logTracking('send purchase event');

        if (localStorage.getItem(`purchase-tracked-${quote.ReservationReference}`) === 'true') {
            logTracking('purchase event already fired');
            return;
        }
        localStorage.setItem(`purchase-tracked-${quote.ReservationReference}`, 'true');
        if (!AccountKeyStore.trackingEnabled) {
            logTracking('Events disabled for account');
            return;
        }

        SalesMonitor.confirmPurchase(quote, AccountKeyStore?.user?.type || 'direct');
    }
}
