/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';

/**
 *
 * @export
 * @interface DealSummary
 */
export interface DealSummary {
    /**
     * Deal unique identifier.
     * @type {string}
     * @memberof DealSummary
     */
    id?: string;
    /**
     * promo code (non-unique).
     * @type {string}
     * @memberof DealSummary
     */
    code?: string;
    /**
     * Preferred display order.
     * @type {number}
     * @memberof DealSummary
     */
    priority?: number;
    /**
     * Deal type.
     * @type {string}
     * @memberof DealSummary
     */
    type?: DealSummaryTypeEnum;
    /**
     *
     * @type {string}
     * @memberof DealSummary
     */
    badgeText?: string;
    /**
     *
     * @type {string}
     * @memberof DealSummary
     */
    title?: string;
    /**
     * Free text description of the deal location.
     * @type {string}
     * @memberof DealSummary
     */
    location?: string;
    /**
     *
     * @type {string}
     * @memberof DealSummary
     */
    summary?: string;
    /**
     * Remaining available count.
     * @type {number}
     * @memberof DealSummary
     */
    availableCount?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof DealSummary
     */
    tags?: Array<string>;
    /**
     * Country the deal is applicable to.
     * @type {string}
     * @memberof DealSummary
     */
    region?: string;
    /**
     * Web link for deal info.
     * @type {string}
     * @memberof DealSummary
     */
    marketingUrl?: string;
    /**
     * Web link for deal image.
     * @type {string}
     * @memberof DealSummary
     */
    imgUrl?: string;
    /**
     * Earliest purchase date deal can be used.
     * @type {Date}
     * @memberof DealSummary
     */
    validFrom?: Date;
    /**
     * Latest purchase date deal can be used.
     * @type {Date}
     * @memberof DealSummary
     */
    validTo?: Date;
    /**
     * Go live start date
     * @type {Date}
     * @memberof DealSummary
     */
    marketingStartDate?: Date;
    /**
     * Go live end date
     * @type {Date}
     * @memberof DealSummary
     */
    marketingEndDate?: Date;
}

/**
 * @export
 */
export const DealSummaryTypeEnum = {
    VehicleRelocation: 'vehicle-relocation',
    Promotion: 'promotion',
} as const;
export type DealSummaryTypeEnum = (typeof DealSummaryTypeEnum)[keyof typeof DealSummaryTypeEnum];

/**
 * Check if a given object implements the DealSummary interface.
 */
export function instanceOfDealSummary(value: object): value is DealSummary {
    return true;
}

export function DealSummaryFromJSON(json: any): DealSummary {
    return DealSummaryFromJSONTyped(json, false);
}

export function DealSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealSummary {
    if (json == null) {
        return json;
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        code: json['code'] == null ? undefined : json['code'],
        priority: json['priority'] == null ? undefined : json['priority'],
        type: json['type'] == null ? undefined : json['type'],
        badgeText: json['badgeText'] == null ? undefined : json['badgeText'],
        title: json['title'] == null ? undefined : json['title'],
        location: json['location'] == null ? undefined : json['location'],
        summary: json['summary'] == null ? undefined : json['summary'],
        availableCount: json['availableCount'] == null ? undefined : json['availableCount'],
        tags: json['tags'] == null ? undefined : json['tags'],
        region: json['region'] == null ? undefined : json['region'],
        marketingUrl: json['marketingUrl'] == null ? undefined : json['marketingUrl'],
        imgUrl: json['imgUrl'] == null ? undefined : json['imgUrl'],
        validFrom: json['validFrom'] == null ? undefined : stringToDate(json['validFrom']),
        validTo: json['validTo'] == null ? undefined : stringToDate(json['validTo']),
        marketingStartDate: json['marketingStartDate'] == null ? undefined : stringToDate(json['marketingStartDate']),
        marketingEndDate: json['marketingEndDate'] == null ? undefined : stringToDate(json['marketingEndDate']),
    };
}

export function DealSummaryToJSON(value?: DealSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        id: value['id'],
        code: value['code'],
        priority: value['priority'],
        type: value['type'],
        badgeText: value['badgeText'],
        title: value['title'],
        location: value['location'],
        summary: value['summary'],
        availableCount: value['availableCount'],
        tags: value['tags'],
        region: value['region'],
        marketingUrl: value['marketingUrl'],
        imgUrl: value['imgUrl'],
        validFrom: value['validFrom'] == null ? undefined : dateToString(value['validFrom']),
        validTo: value['validTo'] == null ? undefined : dateToString(value['validTo']),
        marketingStartDate: value['marketingStartDate'] == null ? undefined : dateToString(value['marketingStartDate']),
        marketingEndDate: value['marketingEndDate'] == null ? undefined : dateToString(value['marketingEndDate']),
    };
}
