'use client';
import React, { useMemo } from 'react';
import ReactMarkdown, { Components, Options } from 'react-markdown';
import { mergeSx } from '@jucy-ui/appearance';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Link, { linkClasses } from '@mui/material/Link';
import { svgIconClasses } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

const defaultComponents: Components = {
    a: (props) => (
        <Link href={props.href} target="_blank" rel="noopener noreferrer">
            {props.children} <OpenInNewIcon />
        </Link>
    ),
    p: ({ ref, node, children, ...props }) => (
        <Typography component="p" variant="body2" {...props}>
            {children}
        </Typography>
    ),
};
export type MarkdownProps = Options & {
    sx?: SxProps<Theme>;
};
export const Markdown: React.FC<MarkdownProps> = ({ children, sx, components, ...props }) => (
    <Box
        component={ReactMarkdown}
        {...props}
        sx={mergeSx(
            {
                [`& .${linkClasses.root}`]: {
                    display: 'inline-flex',
                    alignItems: 'start',
                    [`& .${svgIconClasses.root}`]: {
                        fontSize: 'inherit',
                        ml: 0.2,
                    },
                },
            },
            sx
        )}
        components={useMemo(
            () => ({
                ...components,
                a: components?.a || defaultComponents.a,
                p: components?.p || defaultComponents.p,
            }),
            [components]
        )}
    >
        {children}
    </Box>
);
