import { TripDetails } from '@jucy/rentals-api-client/rentals-api-v3';
import { differenceInDays, format } from 'date-fns';
import appState from '../../../store/AppStateStore';
import BusinessUnitsStore from '../../../store/BusinessUnitsStore';
import FleetTypesStore from '../../../store/FleetTypesStore';
import { BookingCart } from '../../../types/BookingCart';
import { UserMode } from '../../../types/UserMode';
import { BusinessUnitGSTCalculator } from '../../BusinessUnitGSTCalculator';
import Quote from '../../api/model/Quote';
import ga from '../../ga';
import { SalesMonitorProvider } from './SalesMonitorProvider';

export interface GALineItem {
    name: string;
    id: string;
    price: number;
    quantity: number;
    brand: string;
    category: string;
    dimension3?: string;
    dimension13?: string;
    metric1: number;
}

export const isQuote = (quote: unknown): quote is Quote => Boolean(quote instanceof Quote || (quote as Quote).PickUpSite !== undefined);

export const isTripDetails = (quote: unknown): quote is TripDetails => Boolean((quote as TripDetails).secondaryProducts !== undefined || (quote as TripDetails).fleetCategory !== undefined);

export class GAEcommerce implements SalesMonitorProvider {
    private cId?: string;

    static get brand(): string {
        return appState.brand === 'jucy' ? 'Star' : 'JUCY Rentals';
    }

    static lineItemsFromQuote(quote: Quote | TripDetails | BookingCart): GALineItem[] {
        if (isTripDetails(quote)) {
            const businessUnitName = BusinessUnitsStore.getBySiteCode(quote.pickUpLocation)?.name || 'unknown';
            return [
                {
                    name: quote.fleetCategory.name,
                    id: quote.fleetCategory.id,
                    price: quote.fleetCategory.total.value,
                    quantity: 1,
                    brand: GAEcommerce.brand,
                    category: FleetTypesStore.getFleetTypeById(quote.fleetCategory.type)?.name || '',
                    dimension3: businessUnitName,
                    dimension13: quote.fleetCategory.availability,
                    metric1: quote.fleetCategory.dailyRate.value,
                } as GALineItem,
            ].concat(
                (quote.lines || []).map((lineItem) => ({
                    name: lineItem.name,
                    id: lineItem.productId,
                    price: lineItem.total.value,
                    quantity: lineItem.qty ? lineItem.qty : 1,
                    brand: GAEcommerce.brand,
                    category: lineItem.category,
                    dimension3: businessUnitName,
                    metric1: lineItem.price && lineItem.price.value ? lineItem.price.value : lineItem.total.value,
                }))
            );
        }
        if (isQuote(quote)) {
            const businessUnitName = BusinessUnitsStore.getBusinessUnitById(quote.PickUpSite?.BusinessUnit)?.name || 'unknown';
            return [
                {
                    name: quote.FleetCategory.Name,
                    id: quote.FleetCategory.Id,
                    price: quote.FleetCategory.Total.Value,
                    quantity: 1,
                    brand: GAEcommerce.brand,
                    category: FleetTypesStore.getFleetTypeById(quote.FleetCategory.Type)?.name || '',
                    dimension3: businessUnitName,
                    dimension13: quote.FleetCategory.Availability,
                    metric1: quote.FleetCategory.DailyRate.Value,
                } as GALineItem,
            ].concat(
                quote.ProductLines.map((lineItem) => ({
                    name: lineItem.Name,
                    id: lineItem.ProductId,
                    price: lineItem.Total.Value,
                    quantity: lineItem.Qty ? lineItem.Qty : 1,
                    brand: GAEcommerce.brand,
                    category: lineItem.Category,
                    dimension3: businessUnitName,
                    metric1: lineItem.Price && lineItem.Price.Value ? lineItem.Price.Value : lineItem.Total.Value,
                }))
            );
        }
        return [];
    }

    init(): void {
        ga.getClientId().then((id) => {
            if (id) {
                this.cId = id;
            }
        });
    }

    confirmPurchase(quote: Quote): void {
        window.dataLayer.push({
            event: 'purchase',
            step: 7,
            ecommerce: {
                currencyCode: quote.TotalPrice.CurrencyCode,
                purchase: {
                    actionField: {
                        id: quote.ReservationReference,
                        affiliation: 'Online Store',
                        revenue: quote.TotalPrice.Value,
                        tax: quote.PickUpSite ? BusinessUnitGSTCalculator.create(quote.PickUpSite.BusinessUnit).getGSTFromGross(quote.TotalPrice.Value) : quote.TotalPrice.Value,
                        // TODO
                        coupon: quote.FleetCategory.campaignCode || '',
                    },
                    products: GAEcommerce.lineItemsFromQuote(quote),
                },
            },
            timeSpent: 0,
            timeToPickup: differenceInDays(new Date(quote.PickUpDate), new Date()),
            paymentType: quote.paymentOptionId,
            bookingStatus: quote.BookingStatus,
            pickupLocation: quote.PickUpSite?.Name,
            pickupDate: format(quote.PickUpDateTime, 'dd/MM/yyy'),
            pickupTime: format(quote.PickUpDateTime, 'HH:mm'),
            dropoffLocation: quote.DropOffSite?.Name,
            dropoffDate: format(quote.DropOffDateDateTime, 'dd/MM/yyy'),
            dropoffTime: format(quote.DropOffDateDateTime, 'HH:mm'),
            licenseIssueCountry: quote.HirerDetails.DriversLicenceCountry,
            hireDays: quote.RentalDays,
        });
    }

    enabled(quote: Quote, _accountType: UserMode): boolean {
        return Boolean(quote);
    }

    createQuoteQueryParams(): Record<string, string> {
        if (this.cId) {
            return { cId: this.cId };
        }
        return {};
    }
}
