'use client';
import React from 'react';
import { CountryData } from '@jucy-ui/common';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { CountryFlagOrCode } from '../../../CountryFlagOrCode';

export interface CountryListItemButtonProps extends React.HTMLAttributes<HTMLLIElement> {
    countryData: CountryData;
}

export const CountryListItemButton = React.memo(
    React.forwardRef<HTMLLIElement, CountryListItemButtonProps>(({ countryData, ...props }, ref) => (
        <ListItemButton selected={Boolean(props['aria-selected'])} component="li" ref={ref} {...props}>
            <CountryFlagOrCode code={countryData.code} sx={{ marginRight: 1, fontSize: 18 }} />
            <span>
                {countryData.name}{' '}
                <Typography component="span" variant="caption">
                    ({countryData.code}) +{countryData.phonePrefix}
                </Typography>
            </span>
        </ListItemButton>
    ))
);
