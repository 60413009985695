import generateUtilityClass from '@mui/utils/generateUtilityClass';
import generateUtilityClasses from '@mui/utils/generateUtilityClasses';

export interface JucySearchFormClasses {
    root: string;
    vertical: string;
    large: string;
    horizontal: string;
    small: string;
    branchSelect: string;
    dateTimeContainer: string;
    dateTimeArrow: string;
    pickUpBranch: string;
    dropOffBranch: string;
    dateSelect: string;
    driveAgePromoCodeContainer: string;
    pickUpDate: string;
    dropOffDate: string;
    timeSelect: string;
    pickUpTime: string;
    dropOffTime: string;
    driverAge: string;
    promoCode: string;
    submitButton: string;
    actionsContainer: string;
}

export type JucySearchFormClassKey = keyof JucySearchFormClasses;

export const getJucySearchFormUtilityClass = (slot: string): string => generateUtilityClass('JucySearchForm', slot);

export const jucySearchFormClasses: JucySearchFormClasses = generateUtilityClasses('JucySearchForm', [
    'root',
    'vertical',
    'large',
    'horizontal',
    'small',
    'branchSelect',
    'dateTimeContainer',
    'dateTimeArrow',
    'pickUpBranch',
    'driveAgePromoCodeContainer',
    'dropOffBranch',
    'dateSelect',
    'pickUpDate',
    'dropOffDate',
    'timeSelect',
    'pickUpTime',
    'dropOffTime',
    'driverAge',
    'promoCode',
    'submitButton',
    'actionsContainer'
]);
