import { UserMode } from '../../../types/UserMode';
import Quote from '../../api/model/Quote';
import { SalesMonitorProvider } from './SalesMonitorProvider';

export class ChatbotSourceProvider implements SalesMonitorProvider {
    sourceIsChatbot = false;
    chatbotSource?: string;

    init(): void {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const utmSource = urlSearchParams?.get('utm_source');
        const utmMedium = urlSearchParams?.get('utm_medium');
        const utmId = urlSearchParams?.get('utm_id');

        if (utmSource === 'obe' && utmMedium === 'intercom') {
            this.sourceIsChatbot = true;
            this.chatbotSource = utmId || undefined;
        }
    }

    confirmPurchase(_quote: Quote): void {
        return undefined;
    }

    enabled(_quote: Quote, accountType: UserMode): boolean {
        return Boolean(accountType === 'direct');
    }

    createQuoteQueryParams(): Record<string, string> {
        if (this.sourceIsChatbot) {
            return { from_chatbot: 'true', ...(this.chatbotSource ? { chatbot_source: this.chatbotSource } : undefined) };
        }
        return {};
    }
}
