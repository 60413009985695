/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { CustomerAddress } from './CustomerAddress';
import { CustomerAddressFromJSON, CustomerAddressFromJSONTyped, CustomerAddressToJSON } from './CustomerAddress';
import type { CustomerDriversLicense } from './CustomerDriversLicense';
import { CustomerDriversLicenseFromJSON, CustomerDriversLicenseFromJSONTyped, CustomerDriversLicenseToJSON } from './CustomerDriversLicense';

/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     *
     * @type {number}
     * @memberof Customer
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Customer
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof Customer
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof Customer
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof Customer
     */
    mobilePhone?: string;
    /**
     *
     * @type {string}
     * @memberof Customer
     */
    optIn?: string;
    /**
     *
     * @type {Date}
     * @memberof Customer
     */
    dateOfBirth: Date;
    /**
     *
     * @type {string}
     * @memberof Customer
     */
    lucyClubNumber?: string;
    /**
     *
     * @type {CustomerDriversLicense}
     * @memberof Customer
     */
    driversLicense: CustomerDriversLicense;
    /**
     *
     * @type {CustomerAddress}
     * @memberof Customer
     */
    address: CustomerAddress;
}

/**
 * Check if a given object implements the Customer interface.
 */
export function instanceOfCustomer(value: object): value is Customer {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('dateOfBirth' in value) || value['dateOfBirth'] === undefined) return false;
    if (!('driversLicense' in value) || value['driversLicense'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    return true;
}

export function CustomerFromJSON(json: any): Customer {
    return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Customer {
    if (json == null) {
        return json;
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        firstName: json['firstName'],
        lastName: json['lastName'],
        email: json['email'],
        mobilePhone: json['mobilePhone'] == null ? undefined : json['mobilePhone'],
        optIn: json['optIn'] == null ? undefined : json['optIn'],
        dateOfBirth: stringToDate(json['dateOfBirth']),
        lucyClubNumber: json['lucyClubNumber'] == null ? undefined : json['lucyClubNumber'],
        driversLicense: CustomerDriversLicenseFromJSON(json['driversLicense']),
        address: CustomerAddressFromJSON(json['address']),
    };
}

export function CustomerToJSON(value?: Customer | null): any {
    if (value == null) {
        return value;
    }
    return {
        id: value['id'],
        firstName: value['firstName'],
        lastName: value['lastName'],
        email: value['email'],
        mobilePhone: value['mobilePhone'],
        optIn: value['optIn'],
        dateOfBirth: dateToString(value['dateOfBirth'])?.substring(0, 10),
        lucyClubNumber: value['lucyClubNumber'],
        driversLicense: CustomerDriversLicenseToJSON(value['driversLicense']),
        address: CustomerAddressToJSON(value['address']),
    };
}
