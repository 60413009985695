import { AnyObject } from 'yup';

export type RecursivelyReplaceNullWithUndefined<T> = T extends null
    ? undefined // Note: Add interfaces here of all GraphQL scalars that will be transformed into an object
    : T extends Date
      ? T
      : {
            [K in keyof T]: T[K] extends (infer U)[] ? RecursivelyReplaceNullWithUndefined<U>[] : RecursivelyReplaceNullWithUndefined<T[K]>;
        };

/**
 * https://github.com/apollographql/apollo-client/issues/2412#issuecomment-755449680
 */
export const nullToUndefined = <T extends AnyObject>(obj: T): RecursivelyReplaceNullWithUndefined<T> => {
    const newObj = {} as RecursivelyReplaceNullWithUndefined<T>;
    for (const [key, value] of Object.entries(obj)) {
        newObj[key as keyof RecursivelyReplaceNullWithUndefined<T>] =
            value === null
                ? undefined
                : value && typeof value === 'object' && value.__proto__.constructor === Object
                  ? nullToUndefined(value)
                  : value;
    }
    return newObj;
};
