'use client';
import React, { ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SxProps, type Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

interface ScrollbarProps {
    className?: string;
    children?: ReactNode;
    sx?: SxProps<Theme>;
}

export const Scrollbar = React.forwardRef<Scrollbars, ScrollbarProps>(({ className, sx, children, ...rest }, ref) => {
    const theme = useTheme();
    return (
        <Box sx={sx}>
            <Scrollbars
                ref={ref}
                autoHide
                universal
                renderThumbVertical={() => (
                    <Box
                        sx={{
                            width: 5,
                            background: `${theme.palette.charcoal.light}`,
                            borderRadius: `${theme.shape.borderRadius}`,
                            transition: `${theme.transitions.create(['background'])}`,
                            '&:hover': {
                                background: `${theme.palette.charcoal.light}`,
                            },
                        }}
                    />
                )}
                {...rest}
            >
                {children}
            </Scrollbars>
        </Box>
    );
});
Scrollbar.displayName = 'Scrollbar';
