import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Field, useField, useFormikContext } from 'formik';
import { CheckboxWithLabel, CheckboxWithLabelProps } from 'formik-mui';

export interface CheckboxProps extends Omit<CheckboxWithLabelProps, 'Label' | 'field' | 'meta' | 'form'> {
    name: string;
    label?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => {
    const [, meta] = useField(props.name);
    const { submitCount } = useFormikContext();
    const invalid = Boolean(meta.error && (meta.touched || submitCount > 0));

    return (
        <FormControl required error={invalid} variant="standard">
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                Label={{
                    sx: {
                        color: invalid ? 'error.main' : undefined,
                    },
                    label,
                }}
                {...props}
            />
            {invalid ? <FormHelperText>{meta.error}</FormHelperText> : null}
        </FormControl>
    );
};

export default Checkbox;
