import { UserMode, userModes } from '#/types/UserMode';
import { useLocation, useParams } from 'react-router';

const directPaths = ['/widget'];

const parsePathToMode = (path?: string): UserMode | undefined => {
    if (directPaths.includes(path || '')) {
        return 'direct';
    }
    const locationParts = path?.split('/').filter(Boolean) || [];
    for (const part of locationParts) {
        if (userModes.includes(part as UserMode)) {
            return part as UserMode;
        }
    }
    return undefined;
};

export const useRouteMode = (): UserMode => {
    const { mode } = useParams<{ mode?: UserMode }>();
    const location = useLocation();
    const modeFromPath = parsePathToMode(typeof window !== 'undefined' ? window.location.pathname : undefined);
    const modeFromLocation = parsePathToMode(location.pathname || '');

    if (mode && userModes.includes(mode)) {
        return mode;
    }
    if (modeFromPath && userModes.includes(modeFromPath)) {
        return modeFromPath;
    }
    if (modeFromLocation && userModes.includes(modeFromLocation)) {
        return modeFromLocation;
    }
    return 'agent';
};
