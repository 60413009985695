import { useEffect } from 'react';
import config from '#/config';
import { UserMode } from '#/types/UserMode';
import sha256 from 'crypto-js/md5';
import Cookies from 'universal-cookie';
import Quote from '../../api/model/Quote';
import { SalesMonitorProvider } from './SalesMonitorProvider';
import { getCommissionableTotal } from '#/lib/SalesMonitoring/getCommissionableTotal';
import ErrorReporter from '#/lib/ErrorReporter';
import { asError } from '#/lib/asError';

const cookies = new Cookies();

export class Tradedoubler implements SalesMonitorProvider {
    organizationId: number;

    constructor(organizationId: number) {
        this.organizationId = organizationId;
    }

    static loadWrap(id: number) {
        if (config.tradedoublerOrganizationId) {
            const a = document.createElement('script'),
                m = document.getElementsByTagName('script')[0];
            a.async = true;
            a.src = `https://swrap.tradedoubler.com/wrap?id=${id}`;
            m?.parentNode?.insertBefore(a, m);
        }
    }

    static setConfig(wrapId: number, data: unknown) {
        window.TDConfig = window.TDConfig || {};
        window.TDConfig.Config = data;
        Tradedoubler.loadWrap(wrapId);
    }

    init(): void {
        if (config.tradedoublerOrganizationId) {
            window['TDConversionObject'] = 'tdconv';
            window['tdconv'] =
                window['tdconv'] ||
                function () {
                    (window['tdconv'].q = window['tdconv'].q || []).push({
                        0: window,
                        1: document,
                        2: 'script',
                        3: 'https://svht.tradedoubler.com/tr_sdk.js',
                        4: 'tdconv',
                    });
                };
            window['tdconv'].l = new Date().getTime();
            const a = document.createElement('script'),
                m = document.getElementsByTagName('script')[0];
            a.async = true;
            a.src = 'https://svht.tradedoubler.com/tr_sdk.js';
            m?.parentNode?.insertBefore(a, m);
        }
    }

    confirmPurchase(quote: Quote): void {
        try {
            const order = mapTdOrder(quote);
            window.td = { ...window.td, order: order };

            window.tdconv('init', `${this.organizationId}`, { orgId: this.organizationId });
            window.tdconv('track', 'sale', order);
            window.TDOrderNumber = order.transactionId;
            window.TDOrderValue = order.ordervalue;
            window.TDCurrency = quote.TotalPrice.CurrencyCode;
            window.TDVoucher = quote.FleetCategory.CampaignCode;

            const pixelUrl = new URL('https://img-statics.com/report');
            pixelUrl.searchParams.append('o', this.organizationId.toString());
            pixelUrl.searchParams.append('e', '433493');
            pixelUrl.searchParams.append('ordnum', `${order.transactionId}`);
            pixelUrl.searchParams.append('ordval', `${order.ordervalue}`);
            pixelUrl.searchParams.append('curr', `${order.currency}`);
            pixelUrl.searchParams.append('tduid', `${order.tduid}`);
            pixelUrl.searchParams.append('voucher', `${order.voucher}`);
            const ab = document.createElement('img');
            ab.src = pixelUrl.href;
            window.document.body.appendChild(ab);
        } catch (e) {
            ErrorReporter.reportError({ error: asError(e), level: 'error', tags: { source: 'TradeDoubler', reservationReference: quote.ReservationReference } });
        }
    }

    enabled(quote: Quote, accountType: UserMode): boolean {
        return Boolean(accountType === 'direct' && quote?.BookingStatus === 'Confirmed' && config.tradedoublerOrganizationId);
    }
}

interface TDOrder {
    transactionId: string;
    ordervalue: string;
    currency: string;
    tduid: string;
    event: number;
    voucher?: string;
    validOn: string;
    cdt?: {
        extType?: string;
        extId?: string;
    };
    reportInfo?: TDReportInfo;
}

export interface TDReportInfo {
    f1: string;
    f2: string;
    f3: number;
    f4: string;
}

export const mapTdOrder = (quote: Quote): TDOrder => {
    const { total } = getCommissionableTotal(quote);
    return {
        transactionId: quote.ReservationReference,
        ordervalue: total.toString(),
        currency: quote.TotalPrice.CurrencyCode,
        tduid: cookies.get<string>('tduid'),
        event: 433493,
        voucher: quote.FleetCategory.CampaignCode || '',
        validOn: quote.PickUpDate.toString(),
        cdt: {
            extType: '0',
            extId: quote.HirerDetails?.Email ? sha256(quote.HirerDetails.Email).toString() : undefined,
        },
        reportInfo: {
            f1: quote.FleetCategory.Id,
            f2: quote.FleetCategory.Name,
            f3: total,
            f4: '1',
        },
    };
};
type TDConversion = {
    (action: string, arg: string, data: unknown): void;
    q?: unknown[];
    l?: number;
};
declare global {
    interface Window {
        td?: { order?: TDOrder };
        TDConversionObject: string;
        tdconv: TDConversion;
        TDConfig?: { Config?: unknown };
        TDOrderNumber: unknown;
        TDOrderValue: unknown;
        TDCurrency: unknown;
        TDVoucher: unknown;
    }
}

export const useTradedoublerSetConfig = (wrapId: number, data: unknown) => {
    useEffect(() => {
        Tradedoubler.setConfig(wrapId, data);
    }, [data, wrapId]);
};
