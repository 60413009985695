import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { HirePeriod, getHirePeriods, hirePeriodQueryKeys } from '../services';

type UseGetHirePeriodsOptions = UseQueryOptions<HirePeriod[], Error, HirePeriod[], string[]>;
export const useGetHirePeriods = (options?: Omit<UseGetHirePeriodsOptions, 'queryKey' | 'queryFn'>) =>
    useQuery({
        queryKey: hirePeriodQueryKeys.list(),
        queryFn: getHirePeriods,
        ...options,
    });
