import { FieldPath, FieldValues } from 'react-hook-form';
import { FormMeta } from '../lib';
import { FormValidationSchema } from '../lib/FormValidationSchema';
import { descriptionToFieldMeta } from './descriptionToFieldMeta';
import { isSchemaDescription } from './isSchemaDescription';
import { isSchemaObjectDescription } from './isSchemaObjectDescription';

export const mapSchemaObjectDescription = <SchemaShape extends FieldValues>(
    fields: ReturnType<FormValidationSchema<SchemaShape>['describe']>
): FormMeta<SchemaShape> => {
    const result = {} as FormMeta;
    for (const [name, description] of Object.entries(fields.fields)) {
        const field = name as FieldPath<SchemaShape>;
        if (isSchemaDescription(description)) {
            result[field] = descriptionToFieldMeta(field, description);
        }
        if (isSchemaObjectDescription(description)) {
            const subSchemaDescription = mapSchemaObjectDescription(description);
            for (const [subName, subDescription] of Object.entries(subSchemaDescription)) {
                const subField = `${field}.${subName}`;
                if (isSchemaDescription(subDescription)) {
                    result[subField] = descriptionToFieldMeta(subField as FieldPath<SchemaShape>, subDescription);
                }
            }
        }
    }

    return result as FormMeta<SchemaShape>;
};
