import React from 'react';
import ReactMarkdown from 'react-markdown';
import InfoIcon from '@mui/icons-material/Info';
import { Card, CardProps } from '@mui/material';
import { mergeSx } from '#/lib/mergeSx';

export interface InfoTextProps extends CardProps {
    info?: string;
}

const InfoText: React.FC<InfoTextProps> = ({ info, children, sx, ...props }) =>
    info || children ? (
        <Card
            variant="outlined"
            sx={mergeSx((t) => ({ background: t.palette.grey['50'], p: 2, display: 'flex', fontSize: 12, borderRadius: 1, '& p': { mb: 1, '&:last-of-type': { mb: 0 } } }), sx)}
            {...props}
        >
            <InfoIcon sx={{ fontSize: 16, mr: 1 }} />
            <div>
                {info ? (
                    <ReactMarkdown
                        components={{
                            a: (props) => (
                                <a href={props.href} target="_blank" rel="noopener noreferrer">
                                    {props.children} <i className="fas fa-external-link-alt" />
                                </a>
                            ),
                        }}
                    >
                        {info}
                    </ReactMarkdown>
                ) : null}
                {children}
            </div>
        </Card>
    ) : null;
export default InfoText;
