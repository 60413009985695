import { ProductFromJSON, ProductLineFromJSON, SecondaryProduct, SecondaryProductFromJSON, Product as V3Product } from '@jucy/rentals-api-client/rentals-api-v3';
import { ObeProductLine } from '../../../types/ObeProductLine';
import { getTotalLineValue } from '../../sumLineTotal';
import Money from './Money';

export default class Product {
    ProductId = '';
    ProductCode = '';
    Name = '';
    Category = '';
    IsMandatory = false;
    Description = '';
    Price = new Money();
    Total = new Money();
    MaxPrice = new Money();
    ProductType = '';
    AllowMultiple = false;
    Qty = 1;
    RentalDays = 0;
    NumberOfDaysCalculated = 0;
    Commissionable = false;
    IsExcluded?: boolean;
    SortOrder = 0;
    AssociatedProductCode?: string;

    constructor(props: Partial<Product>) {
        Object.assign(this, props);
    }

    static fromV3Product<P extends Product>(v3Product: ObeProductLine): P {
        return new Product({
            ProductId: v3Product.productId,
            ProductCode: v3Product.productCode,
            Name: v3Product.name,
            Category: v3Product.category,
            IsMandatory: v3Product.isMandatory,
            Description: v3Product.description,
            Price: new Money(v3Product.price),
            Total: new Money(v3Product.price),
            MaxPrice: new Money(v3Product.maxPrice),
            ProductType: v3Product.productType,
            AllowMultiple: v3Product.allowMultiple,
            Qty: v3Product.qty || 0,
            RentalDays: v3Product.rentalDays,
            NumberOfDaysCalculated: v3Product.numberOfDaysCalculated,
            Commissionable: v3Product.commissionable,
            IsExcluded: v3Product.isExcluded || false,
            SortOrder: 0,
        }) as unknown as P;
    }

    asV3Product(): V3Product {
        const line = ProductFromJSON({
            productId: this.ProductId,
            sortOrder: this.SortOrder,
            productCode: this.ProductCode,
            name: this.Name,
            category: this.Category,
            isMandatory: this.IsMandatory,
            description: this.Description,
            price: this.Price,
            productType: this.ProductType,
            isPercentageOnTotalCost: this.ProductType === 'Percentage',
            allowMultiple: this.AllowMultiple,
            commissionable: this.Commissionable,
            rentalDays: this.RentalDays,
            numberOfDaysCalculated: this.NumberOfDaysCalculated,
            maxPrice: this.MaxPrice,
        });
        return line;
    }
    asV3ProductLine(): ObeProductLine {
        const line: ObeProductLine = ProductLineFromJSON({
            productId: this.ProductId,
            productCode: this.ProductCode,
            name: this.Name,
            category: this.Category,
            isMandatory: this.IsMandatory,
            description: this.Description,
            price: Money.fromApi(this.Price),
            productType: this.ProductType,
            allowMultiple: this.AllowMultiple,
            commissionable: this.Commissionable,
            maxPrice: Money.fromApi(this.MaxPrice),
            sortOrder: this.SortOrder,
            qty: this.Qty,
            total: Money.fromApi(this.Total),
            rentalDays: this.RentalDays,
            numberOfDaysCalculated: this.NumberOfDaysCalculated,
        });
        line.isExcluded = this.IsExcluded;
        line.total.value = getTotalLineValue(line);
        return line;
    }

    asV3SecondaryProduct(): SecondaryProduct {
        return SecondaryProductFromJSON({
            productId: this.ProductId,
            sortOrder: this.SortOrder,
            productCode: this.ProductCode,
            name: this.Name,
            category: this.Category,
            isMandatory: this.IsMandatory,
            description: this.Description,
            price: Money.fromApi(this.Price),
            productType: this.ProductType,
            isPercentageOnTotalCost: this.ProductType === 'Percentage',
            allowMultiple: this.AllowMultiple,
            commissionable: this.Commissionable,
            rentalDays: this.RentalDays,
            numberOfDaysCalculated: this.NumberOfDaysCalculated,
            maxPrice: Money.fromApi(this.MaxPrice),
        });
    }
}
