'use client';
import React from 'react';
import { getPaletteColor } from '@jucy-ui/appearance';
import { ButtonProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, rgbToHex, useTheme } from '@mui/material/styles';

interface CardIconProps {
    className?: string;
    color?: ButtonProps['color'];
    children?: React.ReactNode;
}

export const CardIcon: React.FC<CardIconProps> = (props) => {
    const { className, children, color, ...rest } = props;
    const theme = useTheme<Theme>();
    const palette = theme.palette.augmentColor({
        color: { main: getPaletteColor(color, theme).main },
    });
    return (
        <>
            <Box
                sx={{
                    borderRadius: '3px',
                    padding: '15px',
                    marginTop: '-20px',
                    marginRight: '15px',
                    background: `linear-gradient(60deg, ${rgbToHex(palette.light)}, ${palette.main})`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: `0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px ${alpha(
                        palette.main,
                        typeof theme.palette.tonalOffset === 'number' ? theme.palette.tonalOffset : theme.palette.tonalOffset.light
                    )}`,
                    '& .jucy-icon': {
                        margin: '5px 4px 0px',
                    },
                }}
                className={className}
                {...rest}
            >
                {children}
            </Box>
        </>
    );
};
