import { JucyCountryCode } from '@jucy/rentals-common';
import { FleetType } from '../../../services';
import { SiteSetting } from './SiteSetting';

export interface SiteAddress {
    City?: string;
    Country?: string;
    County?: string;
    Latitude?: number;
    Line1?: string;
    Line2?: string;
    Line3?: string;
    Longitude?: number;
    Name?: string;
    Postcode?: string;
    State?: string;
}

export class Site {
    AgentsOnly = false;
    Country = '';
    Holidays = [];
    Id = '';
    Name = '';
    Settings: SiteSetting[] = [];
    MinAges: string[] = [];
    SiteSettings?: SiteSetting[];
    SiteCode = '';
    CountryCode: JucyCountryCode = JucyCountryCode.nz;
    BusinessUnit = '';
    Address1?: SiteAddress;

    constructor(props?: Partial<Site>) {
        Object.assign(this, props);
    }

    get code() {
        return this.SiteCode;
    }

    static isSite(value: unknown): value is Site {
        if (value instanceof Site) {
            return true;
        }
        if ((value as Site).SiteCode) {
            return true;
        }

        return true;
    }

    getSettingsForFleetType(fleetType?: FleetType) {
        return fleetType && this.Settings.find(({ FleetTypeId }) => FleetTypeId === fleetType.id);
    }
}
