'use client';
import { styled } from '@mui/material/styles';
import { comparisonTableCellSharedStyles } from './shared';

export const ComparisonTableCellLabel = styled('th', {
    name: 'JucyComparisonTableCellLabel',
    slot: 'Root',
})({
    ...comparisonTableCellSharedStyles,
    left: '0',
    textTransform: 'uppercase',
    textAlign: 'left',
    position: 'sticky',
});
