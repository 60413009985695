'use client';
import { useCallback, useContext } from 'react';
import { FieldPath, FieldValues, RegisterOptions, UseFormRegisterReturn, UseFormReturn, useFormContext } from 'react-hook-form';
import { FormSchemaContext } from '../FormSchemaProvider';

interface UseFormSchemaRegisterReturn extends UseFormRegisterReturn {
    required: boolean;
    label: string;
    name: string;
}

export declare type UseFormSchemaRegister<SchemaShape extends FieldValues> = <TFieldName extends FieldPath<SchemaShape> = FieldPath<SchemaShape>>(
    name: TFieldName,
    options?: RegisterOptions<SchemaShape, TFieldName>
) => UseFormSchemaRegisterReturn;

interface UseFormSchemaContextReturn<SchemaShape extends FieldValues> extends Omit<UseFormReturn<SchemaShape>, 'register'> {
    register: UseFormSchemaRegister<SchemaShape>;
}

export const useFormSchema = <SchemaShape extends FieldValues>(): UseFormSchemaContextReturn<SchemaShape> => {
    const { register: origRegister, ...rest } = useFormContext<SchemaShape>();
    const schema = useContext(FormSchemaContext);
    const register: UseFormSchemaRegister<SchemaShape> = useCallback(
        (name, options) => {
            const fieldName = name as FieldPath<SchemaShape>;
            const required = Boolean(typeof options?.required === 'undefined' ? schema?.formMeta[fieldName]?.required : options.required);
            const result = origRegister(name, {
                ...options,
                required,
            });
            const error = Boolean(rest.formState.errors[fieldName]?.message);
            const helperText = rest.formState.errors[fieldName]?.message;
            return {
                required,
                label: schema?.formMeta[fieldName].label || '',
                ...(error && { error, helperText }),
                ...result,
            };
        },
        [origRegister, rest.formState.errors, schema?.formMeta]
    );
    return { register, ...rest };
};
