import { ProductLine } from '@jucy/rentals-api-client/rentals-api-v3';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import BookingSummaryPriceRow from './BookingSummaryPriceRow';

export interface BookingSummaryPriceRowProps {
    item?: ProductLine;
    amendedItem?: ProductLine;
    className?: string;
    sx?: SxProps<Theme>;
}

const BookingSummaryAddOnRow: React.FC<BookingSummaryPriceRowProps> = observer(({ item, amendedItem: amendedItem, sx, ...props }) => {
    if (!item) {
        return null;
    }
    let title = '';
    let subtitle: React.ReactNode = '';

    if (item.allowMultiple) {
        title = item.qty + ' x ';
    }
    title += item.name;
    if (item.productType !== 'Percentage' && item.total.value >= 0 && item.price?.value >= 0) {
        const unit = item.productType === 'Daily' ? `day x ${item.rentalDays} day${item.rentalDays !== 1 ? 's' : ''}` : 'each';
        subtitle = (
            <>
                <CurrencyFormatter price={item.price} />/{unit}
            </>
        );
    }
    if (item.price?.value < 0 && (title.toLowerCase().includes('adjustment') || title.toLowerCase().includes('discount') || title.toLowerCase().includes('offset'))) {
        title = item.name;
        subtitle = '';
    }

    const showAmendedLine = amendedItem && (amendedItem.total.value !== item.total.value || item.productId !== amendedItem.productId);
    if (!showAmendedLine && item.qty === 0) {
        return;
    }
    return (
        <>
            <BookingSummaryPriceRow sx={{ textDecoration: showAmendedLine ? 'line-through' : undefined }} title={title} subtitle={subtitle} price={item.total} {...props} />
            {showAmendedLine && amendedItem?.qty ? <BookingSummaryAddOnRow item={amendedItem} /> : null}
        </>
    );
});
BookingSummaryAddOnRow.displayName = 'BookingSummaryAddOnRow';
export default BookingSummaryAddOnRow;
