import { action, makeAutoObservable } from 'mobx';
import ga from '../lib/ga';

class AnalyticsStore {
    linkerParam: string | null = null;
    clientId: string | null = null;

    constructor() {
        makeAutoObservable(this);
        ga.getUaTracker().then(
            action((t) => {
                this.linkerParam = t?.get('linkerParam')?.replace('_ga=', '') || null;
                this.clientId = t?.get('clientId') || null;
            })
        );
    }
}

export const analyticsStore = new AnalyticsStore();
export default analyticsStore;
