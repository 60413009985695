'use client';
import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const StarRvLogoComponent = createSvgIcon(
    <path
        d="M1192.18 503.03c-23.43-9.49-32.33-17.8-32.33-28.77 0-8.01 8.01-15.13 18.69-15.13 18.09 0 39.74 16.31 42.41 17.8 2.67 2.08 8.01-.59 10.08-3.86l15.13-22.84c1.19-2.08 1.78-7.42-1.78-9.49-8.9-6.53-32.62-22.24-63.47-22.24-45.68 0-68.51 29.66-68.51 59.02 0 35.59 32.03 55.46 57.54 65.55 20.17 8.01 30.55 17.5 30.55 29.96 0 10.38-8.6 17.8-20.47 17.8-19.57 0-40.93-15.72-42.12-16.31-2.08-1.48-7.71-2.08-10.08 1.78l-13.94 24.62c-2.37 4.15-1.48 5.34 1.48 8.3 6.82 6.82 28.18 22.84 66.73 22.84 43.6 0 70-30.85 70-61.4 0-40.34-36.78-58.43-59.91-67.62ZM563.03 239.45 0 629.65h702.44l77.15-236.73-216.56-153.47ZM907.38 0 563.03 238.65h266.58L907.38 0Zm327.64 238.65H985.65l48.31 147.3 201.05-147.3Zm-532.58 391h411.19L904.61 481.52 702.43 629.65Zm703.24-169.69v-32.63c0-2.99-2.37-5.69-5.64-5.69h-131.73c-3.26 0-5.64 2.69-5.64 5.69v32.63c0 2.99 2.37 5.69 5.64 5.69h42.36v158.36c0 3.1 2.54 5.64 5.64 5.64h35.73c3.1 0 5.64-2.54 5.64-5.64V465.65h42.36c3.26 0 5.64-2.69 5.64-5.69Zm349.27 25.87c0-35.36-29.12-64.18-64.78-64.18h-88.84c-3.27 0-5.65 2.67-5.65 5.65v196.74c0 2.96 2.39 5.62 5.69 5.62h35.68c3.1 0 5.64-2.54 5.64-5.64v-75.36h20.66l39.81 78.34c.6 1.18 2.39 2.66 4.79 2.66h38.69c5.05 0 7.13-4.75 5.05-8.62l-40.71-75.47c25.85-10.4 43.98-32.39 43.98-59.73Zm-68.63 23.82h-43.64v-46h43.64c12.09 0 22.41 10.03 22.41 22.41s-10.32 23.59-22.41 23.59Zm-192.99-87.73c-.89-1.78-2.97-3.27-5.05-3.27h-2.97c-2.08 0-4.16 1.49-5.05 3.27l-93.02 200c-1.78 3.86.59 7.73 5.05 7.73h32.51c5.96 0 9.24-3.58 10.73-7.15l10.73-23.85h81.07l10.73 23.55c2.68 5.66 5.07 7.45 10.43 7.45h32.8c4.46 0 6.84-3.86 5.05-7.73l-93.02-200Zm-29.11 135.73 22.27-49h.3l22.57 49h-45.14Z"
        style={{ fill: '#112745' }}
    />,
    'StarRvLogo'
);
export const StarRvLogo: React.FC<React.ComponentProps<typeof StarRvLogoComponent>> = ({ viewBox, ...props }) => (
    <StarRvLogoComponent viewBox={viewBox || '0 0 1754.94 632.05'} {...props} />
);
