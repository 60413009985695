import React, { useMemo } from 'react';
import { useTeaserContext } from '../TeaserContext';
import ImageGallery from './ImageGallery';

const FleetCategoryImageGallery: React.FC = () => {
    const { fleetCategory } = useTeaserContext();
    const items = useMemo(
        () =>
            fleetCategory.product?.extended?.gallery
                ?.filter((item) => Boolean(item?.original))
                .map((item, i) => ({
                    ...item,
                    originalAlt: item.originalAlt || `${i} ${fleetCategory.product.name}`,
                    originalTitle: item.originalTitle || `${i} ${fleetCategory.product.name}`,
                })) || [],
        [fleetCategory]
    );
    return items?.length ? <ImageGallery items={items} /> : null;
};
export default FleetCategoryImageGallery;
