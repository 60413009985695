import { Money } from '@jucy/rentals-api-client/rentals-api';
import { JucyCountryCode } from '@jucy/rentals-common';
import isEmpty from 'lodash/isEmpty';
import { action, makeAutoObservable, reaction } from 'mobx';
import ErrorReporter from '../lib/ErrorReporter';
import { CurrencyRates } from '../lib/api/model/CurrencyRates';
import jucyRentalsApiClient from '../services/jucyRentalsApi';

class CurrencyStore {
    rates: CurrencyRates = {};
    sourceCurrencyCode = 'NZD';
    selectedCurrencyCode = typeof window === 'undefined' ? 'NZD' : localStorage.getItem('get-currency-code') || 'NZD';
    state: 'loading' | 'idle' | 'error' | 'loaded' = 'idle';
    error?: Error;
    message = '';

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.state,
            (data) => {
                if (data === 'error') {
                    ErrorReporter.reportError({ error: this.error, tags: { store: 'product' } });
                } else {
                    this.error = undefined;
                    this.message = '';
                }
            }
        );
    }

    get activeRates() {
        return this.rates?.[this.sourceCurrencyCode];
    }

    get labels() {
        if (this.activeRates) {
            return Object.keys(this.activeRates);
        }
        return [];
    }

    getRate(code: string) {
        return this.rates?.activeRates?.[code] || 1;
    }

    async initialize() {
        if (isEmpty(this.rates)) {
            this.state = 'loading';
            await jucyRentalsApiClient
                .getCurrencyRates()
                .then((r) => {
                    this.rates = r;
                    this.state = 'loaded';
                })
                .catch(
                    action((e: Error) => {
                        ErrorReporter.captureError(e);
                        this.state = 'error';
                        this.error = e;
                        this.message = 'Failed to load currency rate';
                    })
                );
        }
    }

    setSelectedCode(code: string) {
        this.selectedCurrencyCode = code;
        if (code) {
            localStorage.setItem('get-currency-code', code);
        } else {
            localStorage.removeItem('get-currency-code');
        }
    }

    convertCurrency(value: Required<Money>): Required<Money> {
        const rate = this.selectedCurrencyCode && this.selectedCurrencyCode !== value.currencyCode && this.rates?.[value.currencyCode]?.[this.selectedCurrencyCode];
        if (rate && rate !== 1) {
            return {
                currencyCode: this.selectedCurrencyCode,
                value: Math.round((value.value * rate + Number.EPSILON) * 100) / 100,
            };
        }
        return value;
    }

    currencyCodeToCountryCode(code: string): JucyCountryCode | undefined {
        if (code === 'NZD') {
            return JucyCountryCode.nz;
        }
        if (code === 'AUD') {
            return JucyCountryCode.au;
        }
        return undefined;
    }
}

export const currencyStore = new CurrencyStore();
export default currencyStore;
