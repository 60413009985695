'use client';
import { lighten, styled } from '@mui/material/styles';
import { comparisonTableCellSharedStyles, comparisonTablePinnedCellSharedStyles } from './shared';

export const ComparisonTableCellHeadLabel = styled('th', {
    name: 'JucyComparisonTableCellHeadLabel',
    slot: 'Root',
})(({ theme }) => ({
    ...comparisonTableCellSharedStyles,
    ...comparisonTablePinnedCellSharedStyles,
    fontSize: '1.2em',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    background: lighten(theme.palette.secondary.main, 0.7),
    '& small': {
        fontSize: '.6em',
    },
}));
