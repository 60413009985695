import { OperatorInfo } from '#/hooks';
import { AgentInfo } from '@jucy/rentals-api-client/rentals-api-v3';
import { UserMode } from './UserMode';

type User = {
    email: string;
    type: UserMode;
    isAuthenticated: boolean;
};

export interface AnonymousUser extends User {
    type: 'direct';
}

export interface AffiliateUser extends User {
    type: 'affiliate';
    nickname?: string;
    referrerId: string;
    referrerCode: string;
    idToken?: string;
}

export interface AgentUser extends AgentInfo, User {
    accountKey: string;
    type: 'agent';
}

export interface OperatorUser extends OperatorInfo, User {
    type: 'staff';
    agentUser?: AgentUser;
}

export const isDirect = (user?: OBEUser): user is AnonymousUser => user?.type === 'direct';
export const isAgent = (user?: OBEUser): user is AgentUser => user?.type === 'agent';
export const isStaff = (user?: OBEUser): user is OperatorUser => user?.type === 'staff';
export const isAffiliate = (user?: OBEUser): user is AffiliateUser => user?.type === 'affiliate';

export type OBEUser = AgentUser | OperatorUser | AnonymousUser | AffiliateUser;
