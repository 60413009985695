import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import AppStateStore from '../../../store/AppStateStore';
import { BookingCart } from '../../../types/BookingCart';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import AgentSummaryPriceRow from './AgentSummaryPriceRow';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';
import BookingSummaryPriceRow from './BookingSummaryPriceRow';
import useAgentCommissionSettings from './hooks';
import { getPaymentUniqueKey } from '#/lib/getPaymentUniqueKey';

const AgentSummary: React.FC = observer(() => {
    const context = useBookingSummaryContext();
    if (!context.summary || AppStateStore.mode !== 'agent') {
        return null;
    }
    const hasAmendment = context.summary.action === 'edit' && context.summary.amendments;
    const commissionSettings = useAgentCommissionSettings(context.summary);
    const amendmentCommissionSettingsRes = useAgentCommissionSettings(hasAmendment ? (context.summary.amendments as BookingCart) : undefined);
    const amendmentCommissionSettings = hasAmendment ? amendmentCommissionSettingsRes : undefined;
    const customerPayments = useMemo(() => context.summary.payments.filter((p) => p.type !== 'Agent Collected'), [context.summary.payments]);
    const customerPaymentsTotal = useMemo(() => customerPayments.reduce((total, p) => total + p.amount.value, 0), [customerPayments]);
    return (
        <BookingSummaryGroupItem sx={{ pb: 0, mt: 2 }} data-cy="agent-summary">
            {commissionSettings?.commissionValue > 0 ? (
                <AgentSummaryPriceRow
                    data-cy="agent-commission"
                    title="Commission inc."
                    amendment={
                        hasAmendment && amendmentCommissionSettings && amendmentCommissionSettings?.commissionValue !== commissionSettings.commissionValue
                            ? {
                                  value: amendmentCommissionSettings?.commissionValue,
                                  currencyCode: context.summary.totalPrice.currencyCode,
                              }
                            : undefined
                    }
                    price={{
                        value: commissionSettings.commissionValue,
                        currencyCode: context.summary.totalPrice.currencyCode,
                    }}
                />
            ) : null}
            {commissionSettings?.agentCollects > 0 ? (
                <AgentSummaryPriceRow
                    data-cy="agent-collects"
                    title="Agent collects"
                    amendment={
                        hasAmendment && amendmentCommissionSettings && amendmentCommissionSettings?.agentCollects !== commissionSettings?.agentCollects
                            ? {
                                  value: amendmentCommissionSettings?.agentCollects,
                                  currencyCode: context.summary.totalPrice.currencyCode,
                              }
                            : undefined
                    }
                    price={{
                        value: commissionSettings.agentCollects,
                        currencyCode: context.summary.totalPrice.currencyCode,
                    }}
                />
            ) : null}
            {commissionSettings?.customerBalanceDue > 0 ? (
                <>
                    {customerPaymentsTotal > 0 ? (
                        <>
                            <Typography variant="caption" lineHeight={0.8}>
                                Customer payments to JUCY
                            </Typography>
                            {customerPayments.map((p) => (
                                <BookingSummaryPriceRow
                                    key={`customer-${getPaymentUniqueKey(p)})}`}
                                    data-cy="customer-payments"
                                    title="Customer payment received"
                                    subtitle={`by JUCY - ${format(p.date, 'dd MMM yyyy')}`}
                                    sx={{ mb: 0.5 }}
                                    price={{
                                        value: -p.amount.value,
                                        currencyCode: context.summary.totalPrice.currencyCode,
                                    }}
                                />
                            ))}
                            {commissionSettings.customerBalanceDue}
                            <AgentSummaryPriceRow
                                data-cy="customer-payments"
                                title="Customer balance due"
                                sx={{
                                    mb: 1,
                                    '& .MuiGrid-root': {
                                        justifyContent: 'right',
                                    },
                                }}
                                price={{
                                    value: commissionSettings.customerBalanceDue - customerPaymentsTotal,
                                    currencyCode: context.summary.totalPrice.currencyCode,
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <AgentSummaryPriceRow
                                data-cy="pay-at-branch"
                                title="Customer to pay"
                                subtitle="at branch"
                                amendment={
                                    hasAmendment && amendmentCommissionSettings && amendmentCommissionSettings?.customerBalanceDue !== commissionSettings?.customerBalanceDue
                                        ? {
                                              value: amendmentCommissionSettings?.customerBalanceDue,
                                              currencyCode: context.summary.totalPrice.currencyCode,
                                          }
                                        : undefined
                                }
                                price={{
                                    value: commissionSettings.customerBalanceDue,
                                    currencyCode: context.summary.totalPrice.currencyCode,
                                }}
                            />
                        </>
                    )}
                </>
            ) : null}
        </BookingSummaryGroupItem>
    );
});
AgentSummary.displayName = 'AgentSummary';
export default AgentSummary;
