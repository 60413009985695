import { InsuranceProductFromJSON, InsuranceType, ProductLineFromJSON, InsuranceProduct as V3InsuranceProduct } from '@jucy/rentals-api-client/rentals-api-v3';
import { ObeProductLine } from '../../../types/ObeProductLine';
import { getTotalLineValue } from '../../sumLineTotal';
import Money from './Money';
import Product from './Product';

export default class InsuranceProduct extends Product {
    ExcessType: InsuranceType = InsuranceType.Basic;
    Excess = new Money();
    Bond = new Money();

    constructor(props: Partial<InsuranceProduct>) {
        super(props);
        Object.assign(this, props);
    }

    static fromV3InsuranceProduct(v3InsuranceProduct: V3InsuranceProduct): InsuranceProduct {
        return new InsuranceProduct({
            ProductId: v3InsuranceProduct.productId,
            ProductCode: v3InsuranceProduct.productCode,
            Name: v3InsuranceProduct.name,
            Category: v3InsuranceProduct.category,
            IsMandatory: v3InsuranceProduct.isMandatory,
            Description: v3InsuranceProduct.description,
            Price: new Money(v3InsuranceProduct.price),
            Total: new Money(v3InsuranceProduct.price),
            MaxPrice: new Money(v3InsuranceProduct.maxPrice),
            ProductType: v3InsuranceProduct.productType,
            AllowMultiple: v3InsuranceProduct.allowMultiple,
            Qty: 1,
            RentalDays: v3InsuranceProduct.rentalDays,
            NumberOfDaysCalculated: v3InsuranceProduct.numberOfDaysCalculated,
            Commissionable: v3InsuranceProduct.commissionable,
            IsExcluded: false,
            SortOrder: 0,
            ExcessType: v3InsuranceProduct.excessType,
            Excess: new Money(v3InsuranceProduct.excess),
            Bond: new Money(v3InsuranceProduct.bond),
        });
    }
    asV3ProductLine(): ObeProductLine {
        const line: ObeProductLine = ProductLineFromJSON({
            productId: this.ProductId,
            productCode: this.ProductCode,
            name: this.Name,
            category: this.Category,
            isMandatory: this.IsMandatory,
            description: this.Description,
            price: this.Price,
            productType: this.ProductType,
            allowMultiple: this.AllowMultiple,
            commissionable: this.Commissionable,
            maxPrice: this.MaxPrice,
            sortOrder: this.SortOrder,
            qty: 1,
            total: this.Price,
            rentalDays: this.RentalDays,
            numberOfDaysCalculated: this.NumberOfDaysCalculated,
        });
        line.isExcluded = this.IsExcluded;
        line.total.value = getTotalLineValue(line);
        return line;
    }

    asV3InsuranceProduct(): V3InsuranceProduct {
        return InsuranceProductFromJSON({
            productId: this.ProductId,
            sortOrder: this.SortOrder,
            productCode: this.ProductCode,
            name: this.Name,
            category: this.Category,
            isMandatory: this.IsMandatory,
            description: this.Description,
            price: Money.fromApi(this.Price),
            productType: this.ProductType,
            isPercentageOnTotalCost: this.ProductType === 'Percentage',
            allowMultiple: this.AllowMultiple,
            commissionable: this.Commissionable,
            rentalDays: this.RentalDays,
            numberOfDaysCalculated: this.NumberOfDaysCalculated,
            maxPrice: Money.fromApi(this.MaxPrice),
            excessType: this.ExcessType,
            excess: Money.fromApi(this.Excess),
            bond: Money.fromApi(this.Bond),
        });
    }
}
