import React from 'react';
import Image from 'next/image';
import { IncludedWith } from '../../../../../lib/api/model/ExtendedFleetCategory';

const FeatureIcon: React.FC<{ feature: IncludedWith }> = ({ feature }) => (
    <>
        {feature.type === 'icon' && feature.iconClass ? <i className={`font-icon ${feature.iconClass}`} /> : null}
        {feature.type === 'image' && feature.image ? <Image alt="" className="image-icon" src={feature.image} /> : null}
        {feature.title}
    </>
);

export default FeatureIcon;
