import { date, object, ref, string } from 'yup';
import { searchFormValuesToData } from '../lib';
import { validateDriverAge } from './validateDriverAge';
import { validateDropOffBranch } from './validateDropOffBranch';
import { validateDropOffDate } from './validateDropOffDate';
import { validateDropOffTime } from './validateDropOffTime';
import { validateFleetType } from './validateFleetType';
import { validatePickUpBranch } from './validatePickUpBranch';
import { validatePickUpDate } from './validatePickUpDate';
import { validatePickUpTime } from './validatePickUpTime';

export const searchFormValidationSchema = object({
    fleetType: string()
        .required('Select a vehicle type')
        .label('Vehicle type')
        .default('campervan')
        .test('fleetType', async (fleetType, context) => {
            const error = validateFleetType(
                searchFormValuesToData({
                    fleetType,
                    ...context.parent,
                })
            );
            if (error) {
                return context.createError({ message: error, path: 'fleetType' });
            }
            return true;
        }),
    fleetCategory: string().optional().label('Vehicle category').default(''),
    pickUpBranch: string()
        .nullable()
        .required('Select a pick up branch')
        .label('Pick up branch')
        .test('pickUpBranch', async (pickUpBranch, context) => {
            const error = validatePickUpBranch(
                searchFormValuesToData({
                    pickUpBranch,
                    ...context.parent,
                })
            );
            if (error) {
                return context.createError({ message: error, path: 'pickUpBranch' });
            }
            return true;
        }),
    pickUpDate: date()
        .nullable()
        .required('Select a pick up date')
        .label('Pick up date')
        .default(null)
        .test('pickUpDate', async (pickUpDate, context) => {
            const error = validatePickUpDate(
                searchFormValuesToData({
                    pickUpDate,
                    ...context.parent,
                })
            );
            if (error) {
                return context.createError({ message: error, path: 'pickUpDate' });
            }
            return true;
        }),
    pickUpTime: date()
        .nullable()
        .required('Select a pick up time')
        .label('Pick up time')
        .default(null)
        .test('pickUpTime', async (pickUpTime, context) => {
            const error = validatePickUpTime(
                searchFormValuesToData({
                    pickUpTime,
                    ...context.parent,
                })
            );
            if (error) {
                return context.createError({ message: error, path: 'pickUpTime' });
            }
            return true;
        }),
    dropOffBranch: string()
        .nullable()
        .required('Select a drop off branch')
        .label('Drop off branch')
        .test('dropOffBranch', async (dropOffBranch, context) => {
            const error = validateDropOffBranch(
                searchFormValuesToData({
                    dropOffBranch,
                    ...context.parent,
                })
            );
            if (error) {
                return context.createError({ message: error, path: 'dropOffBranch' });
            }
            return true;
        }),
    dropOffDate: date()
        .nullable()
        .required('Select a drop off date')
        .label('Drop off date')
        .default(null)
        .min(ref('pickUpDate'), 'Drop off date must be after pick up date')
        .test('dropOffDate', async (dropOffDate, context) => {
            const error = validateDropOffDate(
                searchFormValuesToData({
                    dropOffDate,
                    ...context.parent,
                })
            );
            if (error) {
                return context.createError({ message: error, path: 'dropOffDate' });
            }
            return true;
        }),

    dropOffTime: date()
        .nullable()
        .required('Select a drop off time')
        .label('Drop off time')
        .default(null)
        .test('dropOffTime', async (dropOffTime, context) => {
            const error = validateDropOffTime(
                searchFormValuesToData({
                    dropOffTime,
                    ...context.parent,
                })
            );
            if (error) {
                return context.createError({ message: error, path: 'dropOffTime' });
            }
            return true;
        }),
    promoCode: string().label('Promo code').default(''),
    driverAge: string()
        .label('Driver age')
        .test(async (driverAge, context) => {
            const error = validateDriverAge(
                searchFormValuesToData({
                    driverAge,
                    ...context.parent,
                })
            );
            if (error) {
                return context.createError({ message: error, path: 'driverAge' });
            }
            return true;
        }),
});
