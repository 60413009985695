import React, { useCallback, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, CircularProgress, IconButton, Stack, StackProps } from '@mui/material';
import useIsMounted from '../../../hooks/useIsMounted';

export interface QtyInputProps extends Omit<StackProps, 'onChange'> {
    value: number;
    min?: number;
    max?: number;
    bundledQty: number;
    onChange: (val: number, delta: -1 | 1) => void | Promise<void>;
    readonly?: boolean;
    disabled?: boolean;
    loading?: boolean;
}

const QtyInput: React.FC<QtyInputProps> = ({ value, loading: passedLoading, min = 0, max = 10, onChange, bundledQty, readonly, disabled, ...rest }) => {
    const [qty, setQty] = useState(Number(`${value}`));
    const [loading, setLoading] = useState(passedLoading);
    const [action, setAction] = useState<'increase' | 'decrease' | null>();
    const isMounted = useIsMounted();
    const updateQty = useCallback(
        async (val: number, delta: -1 | 1) => {
            setLoading(true);
            setQty(val);
            await onChange(val, delta);
            if (isMounted()) {
                setLoading(false);
            }
        },
        [isMounted, onChange]
    );
    const decrease = useCallback(() => {
        if (qty - 1 < min) {
            return;
        }
        setAction('increase');
        return updateQty(qty - 1, -1);
    }, [min, qty, updateQty]);

    const increase = useCallback(() => {
        if (qty + 1 > max) {
            return;
        }
        setAction('decrease');
        return updateQty(qty + 1, 1);
    }, [max, qty, updateQty]);

    return (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} {...rest}>
            <IconButton
                data-cy="decrease-btn"
                aria-label="Decrease"
                color="primary"
                onClick={decrease}
                disabled={qty <= min || readonly || disabled}
                sx={{ border: 1, width: 42, height: 42 }}
            >
                {(loading || passedLoading) && action === 'increase' ? <CircularProgress sx={{ color: 'inherit' }} size={18} /> : <RemoveIcon fontSize="inherit" />}
            </IconButton>
            <Box data-cy="qty-field" className={'value'} minWidth={20} textAlign="center">
                {qty + bundledQty}
            </Box>
            <IconButton
                data-cy="increase-btn"
                aria-label="Increase"
                color="primary"
                onClick={increase}
                disabled={qty + bundledQty >= max || readonly || disabled}
                sx={{ border: 1, width: 42, height: 42 }}
            >
                {(loading || passedLoading) && action === 'decrease' ? <CircularProgress sx={{ color: 'inherit' }} size={18} /> : <AddIcon fontSize="inherit" />}
            </IconButton>
        </Stack>
    );
};
export default QtyInput;
