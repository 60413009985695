import { SearchFormData } from '../types';

export const validateDriverAge = ({ driverAge, pickUpBranch, fleetType }: Partial<SearchFormData>): string | undefined => {
    const parsedDriverAge = Number(driverAge);

    if (!driverAge || isNaN(parsedDriverAge)) {
        return 'Select a driver age';
    }

    if (fleetType?.minimumDriverAge && parsedDriverAge < fleetType.minimumDriverAge) {
        return `Minimum driver age is ${fleetType.minimumDriverAge}`;
    }

    const branchDriverAges = pickUpBranch?.minAges.map((age) => parseInt(age));
    const minBranchDriverAge = branchDriverAges && Math.min(...branchDriverAges);
    if (minBranchDriverAge && parsedDriverAge < minBranchDriverAge) {
        return `Minimum driver age for this branch is ${minBranchDriverAge}`;
    }
};
