'use client';
import React, { useMemo } from 'react';
import { createThemeOptionsForBrand } from '@jucy-ui/appearance';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useBrand } from './useBrand';

export interface BrandThemeProviderProps {
    children?: React.ReactNode;
}

export const BrandThemeProvider: React.FC<BrandThemeProviderProps> = ({ children }) => {
    const brand = useBrand();
    const theme = useMemo(() => createTheme(createThemeOptionsForBrand(brand)), [brand]);
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
