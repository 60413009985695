'use client';
import React, { forwardRef } from 'react';
import { cn } from '@jucy-ui/common';
import { RadioProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { type PaymentType } from '../lib';

export interface PaymentTypeRadioProps extends RadioProps {
    paymentType: PaymentType;
}

export const PaymentTypeRadio = forwardRef<HTMLButtonElement,PaymentTypeRadioProps>(({ paymentType, className, ...props }, ref) => (
    <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Radio className={cn('payment-type-radio', className)} disableRipple={true} ref={ref} {...props} />
        {paymentType.thumbnail ? (
            <Avatar
                variant="square"
                alt={paymentType.label}
                src={paymentType.thumbnail}
                imgProps={{
                    sx: { maxWidth: '100%', flex: '1 1', objectFit: 'contain' },
                }}
                className="payment-type-thumb"
                sx={(theme) => ({
                    flex: '1 1',
                    opacity: props.disabled ? 0.26 : 1,
                    transition: theme.transitions.create(['opacity'], {
                        duration: theme.transitions.duration.short,
                    }),
                })}
            />
        ) : (
            <Typography variant="h4" sx={{ fontSize: '1.571em' }}>
                {paymentType.label}
            </Typography>
        )}
    </Stack>
));
PaymentTypeRadio.displayName = 'PaymentTypeRadio';
