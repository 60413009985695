class ResponsiveHelper {
    breakpoints: Record<string, number> = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1320,
    };

    containerWidths: Record<string, number> = {
        xs: 0,
        sm: 540,
        md: 720,
        lg: 960,
        xl: 1140,
        xxl: 1140,
    };

    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (typeof window !== 'undefined' && window.getComputedStyle(document.body, '::after')) {
            const style = window.getComputedStyle(document.body, '::after');
            const content = style.content
                .replace(/^['"]+|\s+|\\|(;\s?})+|['"]$/g, '')
                .replace(/px/g, '')
                .replace(/""/g, '"0"')
                .replace(/"([0-9]+)"/g, '$1')
                .trim();
            if (content && content.charAt(0) === '{') {
                try {
                    const cssSizes = JSON.parse(content) as { breakpoints?: Record<string, number>; containerWidths?: Record<string, number> };
                    if (cssSizes.breakpoints) {
                        this.breakpoints = cssSizes.breakpoints;
                    }
                    if (cssSizes.containerWidths) {
                        this.containerWidths = cssSizes.containerWidths;
                    }
                } catch (e) {
                    console.warn(`Unable to parse responsive content ${content}`);
                }
            }
        }
    }

    get viewPortSize(): { width: number; height: number } {
        return {
            width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
        };
    }

    get currentSize(): number {
        const viewport = this.viewPortSize;
        if (viewport.width <= this.breakpoints.xs) {
            return this.breakpoints.xs;
        } else if (viewport.width <= this.breakpoints.sm) {
            return this.breakpoints.sm;
        } else if (viewport.width <= this.breakpoints.md) {
            return this.breakpoints.md;
        } else if (viewport.width <= this.breakpoints.lg) {
            return this.breakpoints.lg;
        } else {
            return this.breakpoints.xl;
        }
    }

    get isXL() {
        return this.currentSize === this.breakpoints.xl;
    }

    get isLG() {
        return this.currentSize === this.breakpoints.lg;
    }

    get isMD() {
        return this.currentSize === this.breakpoints.md;
    }

    get isSM() {
        return this.currentSize === this.breakpoints.sm;
    }

    get isXS() {
        return this.currentSize === this.breakpoints.xs;
    }

    get isMobile() {
        return this.currentSize < this.breakpoints.lg;
    }
}

export const responsiveHelper = new ResponsiveHelper();
