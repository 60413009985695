import React from 'react';
import { DialogProps, ModalProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ModalDialog from '../../ModalDialog';
import LaybuyLightbox from './LaybuyLightbox';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '38em',
            height: 490,
            overflow: 'hidden',
        },
    },
}));

interface PayWithLaybuyProps extends DialogProps {
    onClose: ModalProps['onClose'];
}

const WhatsThisModal: React.FC<PayWithLaybuyProps> = ({ ...props }) => {
    const classes = useStyles();
    return (
        <ModalDialog {...props} classes={{ paper: classes.paper }}>
            <LaybuyLightbox />
        </ModalDialog>
    );
};

export default WhatsThisModal;
