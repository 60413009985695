'use client';
import React from 'react';
import { styled } from '@mui/material';
import { FleetCategoryTeaserCardPlaceHolder } from '../../TeaserCard/FleetCategoryTeaserCardPlaceHolder';
import { FleetCategoryTeaserCardOwnerState } from '../../TeaserCard/types';
import { AvailabilityGridProps } from '../AvailabilityGrid';

const FleetCategoryTeaserCardDescriptionRoot = styled('div', {
    name: 'JucyAvailabilityGrid',
    slot: 'placeholder',
})<{ ownerState?: FleetCategoryTeaserCardOwnerState }>(() => ({
    marginTop: 2,
}));

export const AvailabilityGridPlaceHolder: React.FC<Partial<AvailabilityGridProps>> = () => (
    <FleetCategoryTeaserCardDescriptionRoot>
        <FleetCategoryTeaserCardPlaceHolder />
        <FleetCategoryTeaserCardPlaceHolder />
        <FleetCategoryTeaserCardPlaceHolder />
        <FleetCategoryTeaserCardPlaceHolder />
    </FleetCategoryTeaserCardDescriptionRoot>
);
