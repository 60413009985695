'use client';
import React, { ReactNode } from 'react';
import { Control, FieldError, FieldPath, FieldValues, useController } from 'react-hook-form';
import { type UseControllerProps, useFormError } from 'react-hook-form-mui';
import { CountryData, useGetCountries } from '@jucy-ui/common';
import { CountryAutocomplete, CountryAutocompleteProps } from '@jucy-ui/components';
import { useForkRef } from '@mui/material';
import { TextFieldVariants } from '@mui/material/TextField/TextField';

export type CountryAutocompleteElementProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    Variant extends TextFieldVariants = TextFieldVariants,
> = Omit<CountryAutocompleteProps<Variant>, 'name' | 'renderOption' | 'onChange' | 'value' | 'ref'> & {
    validation?: UseControllerProps<TFieldValues, TName>['rules'];
    name: TName;
    parseError?: (error: FieldError) => ReactNode;
    control?: Control<TFieldValues>;
    defaultValue?: CountryData;
    required?: boolean;
};
export const CountryAutocompleteElement = React.forwardRef(
    <
        TFieldValues extends FieldValues = FieldValues,
        TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
        Variant extends TextFieldVariants = TextFieldVariants,
    >(
        props: CountryAutocompleteElementProps<TFieldValues, TName, Variant>,
        ref: React.Ref<unknown> | undefined
    ) => {
        const { data: countries } = useGetCountries();
        const { parseError, required, name, control, ...rest } = props;
        const errorMsgFn = useFormError();
        const customErrorFn = parseError || errorMsgFn;
        const {
            field: { ref: internalRef, onChange, ...field },
            fieldState: { error },
        } = useController<TFieldValues, TName>({
            name,
            control,
            disabled: rest.disabled,
        });
        const forkedRef = useForkRef(ref, internalRef);
        return (
            <div>
                <CountryAutocomplete
                    {...field}
                    {...rest}
                    name={field.name}
                    value={countries?.find((c) => c.name === field.value) ?? null}
                    onChange={(event, value) => {
                        onChange((typeof value === 'string' ? value : value?.name) || '');
                    }}
                    required={required}
                    error={!!error}
                    helperText={error ? (typeof customErrorFn === 'function' ? customErrorFn(error) : error.message) : rest.helperText}
                    ref={forkedRef}
                />
            </div>
        );
    }
);
CountryAutocompleteElement.displayName = 'CountryAutocompleteElement';
