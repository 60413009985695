import { JucyMuiTextButtonProps } from '#/components/Button/Button';
import { Brand, defaultBrand } from '#/lib/brand';
import { OBEUser } from '#/types/OBEUser';
import { UserMode } from '#/types/UserMode';
import { JucyCountryCode } from '@jucy/rentals-common';
import { ButtonProps } from '@mui/material';
import { action, makeAutoObservable, reaction, runInAction } from 'mobx';
import { Writable } from 'type-fest';
import config from '../config';
import JucyAPI from '../lib/JucyAPI.js';
import accountKeyStore from './AccountKeyStore';
import currencyStore from './CurrencyStore';
import fleetTypesStore from './FleetTypesStore';
import paymentStore from './PaymentStore';
import ProductStore from './ProductStore';
import reservationStore from './ReservationStore';
import { reverseTripStore } from './ReverseTripStore';
import SitesStore from './SitesStore';
import translationStore from './TranslationStore';
import createTripStore from './TripStore';

const isUrlReferrerWhitelisted = (referrer: string): boolean => {
    try {
        const hostnameWhitelist = ['test.jucy.com', 'www.jucy.com', 'localhost:8200'];
        const parsedReferrer = referrer && new URL(referrer);
        return Boolean(parsedReferrer && parsedReferrer.host && hostnameWhitelist.includes(parsedReferrer.host));
    } catch (e) {
        return false;
    }
};

class StickyFooterState {
    nextBtnProps: Partial<JucyMuiTextButtonProps> = {};
    showNextBtn = false;
    showTripSummary = false;
    showFooter = true;
    toggleSummary = action(() => {
        this.showTripSummary = !this.showTripSummary;
    });
    setShowFooter = action((visible: boolean) => {
        this.showFooter = visible;
    });

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.showNextBtn,
            () => {
                if (!this.showNextBtn) {
                    this.nextBtnProps = {};
                }
            }
        );

        reaction(
            () => this.showTripSummary,
            () => {
                if (this.showTripSummary) {
                    window.document.querySelector('body')?.classList.add('overflow-hidden');
                } else {
                    window.document.querySelector('body')?.classList.remove('overflow-hidden');
                }
            }
        );
    }

    enableNextBtn = ({ onClick, ...nextBtnProps }: Partial<JucyMuiTextButtonProps>) => {
        const onClickAction: ButtonProps['onClick'] = (e) => {
            this.showNextBtn = false;

            (onClick as ButtonProps['onClick'])?.(e);
        };
        runInAction(() => {
            this.showNextBtn = true;
            this.nextBtnProps = {
                ...nextBtnProps,
                onClick: onClickAction,
            };
        });
    };
}

class AppStateStore {
    state: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    message = '';
    brand: Brand = defaultBrand;
    error?: Error;
    user: OBEUser = {
        email: '',
        type: 'direct',
        isAuthenticated: false,
    };
    initialised = false;
    isAuthCallback = false;
    isPaymentCallback = false;
    showEmailQuoteModal = false;
    showDNRModal = false;
    showExcessReductionModal = false;
    ui = { showFooter: false, showHeader: false, showChat: true };
    isEditMode = false;
    routeMode: UserMode = 'direct';

    status: Record<JucyCountryCode, boolean> = {
        au: true,
        nz: true,
    };

    orderSummaryFooter = new StickyFooterState();

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.mode,
            () => {
                reservationStore.userMode = this.mode;
                reverseTripStore.userMode = this.mode;
                createTripStore.userMode = this.mode;
            }
        );
        reaction(
            () => this.isReady,
            () => {
                createTripStore.appReady = this.isReady;
            }
        );

        reaction(
            () => createTripStore.action,
            () => {
                this.isEditMode = createTripStore.action === 'edit';
            }
        );
    }

    get brandWebsite(): string {
        if (this.brand === 'jucy') {
            return 'https://www.jucy.com';
        }
        return 'https://www.starrv.com';
    }

    get websiteUrl() {
        if (this.brand === 'star') {
            return 'https://www.starrv.com';
        }
        let result = sessionStorage.getItem('return-url') ? sessionStorage.getItem('return-url') : new URL('/nz/en', config.websiteBaseUrl).href;

        if (isUrlReferrerWhitelisted(document.referrer)) {
            sessionStorage.setItem('return-url', document.referrer);
            result = document.referrer;
        }
        if (result?.endsWith('en') && translationStore.currentLanguage?.toLowerCase().includes('de')) {
            result = result.replace(/en$/, 'de');
        }
        return result || this.brandWebsite;
    }

    get isReady() {
        return this.state === 'done' && this.initialised;
    }

    get mode(): UserMode {
        return this.user?.type || 'direct';
    }

    setValues = (values: Partial<AppStateStore>) => {
        Object.entries(values).forEach(([name, value]) => {
            const self = this as Writable<AppStateStore>;
            const prop = name as keyof Writable<AppStateStore>;
            const currentValue = self[prop];
            if (currentValue !== value) {
                runInAction(() => {
                    self[prop] = value as never;
                });
            }
        });
    };

    async init({ isAuthCallback, isPaymentCallback, user }: { isAuthCallback?: boolean; isPaymentCallback?: boolean; user?: OBEUser } = {}) {
        if (this.state === 'loading' || this.initialised) {
            return;
        }
        reservationStore.init();
        this.setValues({
            user,
            mode: user?.type || 'direct',
            state: 'loading',
            isAuthCallback,
            isPaymentCallback,
        });
        JucyAPI.status()
            .then((s) =>
                runInAction(() => {
                    if (s?.obe) {
                        this.status = s.obe;
                    } else {
                        this.status = {
                            au: false,
                            nz: false,
                        };
                    }
                })
            )
            .catch(() =>
                runInAction(() => {
                    this.status = {
                        au: false,
                        nz: false,
                    };
                })
            );

        accountKeyStore.initialize(user);
        reaction(
            () => accountKeyStore.user,
            () => {
                if (accountKeyStore.user) {
                    this.user = accountKeyStore.user;
                }
            }
        );
        await translationStore.initialize({ brand: this.brand });
        await Promise.all([fleetTypesStore.initialize(), SitesStore.initialize(this.status), ProductStore.initialize(), currencyStore.initialize(), paymentStore.initialize()]);

        this.setValues({
            ui: {
                ...this.ui,
                showHeader: !isPaymentCallback && !isAuthCallback,
                showFooter: !isPaymentCallback && !isAuthCallback,
                showChat: true,
            },
            mode: user?.type || 'direct',
            initialised: true,
            state: 'done',
        });
        reservationStore.userMode = this.mode;
        reverseTripStore.userMode = this.mode;
        createTripStore.userMode = this.mode;
    }

    toggleEmailQuoteModal = () => {
        this.showEmailQuoteModal = !this.showEmailQuoteModal;
    };

    toggleDNRModal = () => {
        this.showDNRModal = !this.showDNRModal;
    };

    //Flag to show upsell message to agent
    toggleExcessReductionModal() {
        this.showExcessReductionModal = !this.showExcessReductionModal;
    }
}

const appState = new AppStateStore();

export default appState;
