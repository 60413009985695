import { JucyUiProvider } from '@jucy-ui/components';
import { ReactNode } from 'react';
import { Brand } from '../../lib/brand';
import { BrandStore } from '../../store/BrandStore';
import { BrandConsumer, BrandProvider } from './BrandProvider';
import { BrandThemeProvider } from './BrandThemeProvider';
import { GrowthBookProvider } from './GrowthBookProvider';
import { JitsuProvider } from './JitsuProvider';
import { LocalizationProvider } from './LocalizationProvider';
import { QueryClientProvider } from './QueryClientProvider';

export const AppProviders = ({ children, brand }: { children: (value: BrandStore) => ReactNode; brand?: Brand }) => (
    <GrowthBookProvider>
        <JucyUiProvider brand={brand}>
            <JitsuProvider>
                <BrandProvider brand={brand}>
                    <BrandThemeProvider>
                        <LocalizationProvider>
                            <QueryClientProvider>
                                <BrandConsumer>{children}</BrandConsumer>
                            </QueryClientProvider>
                        </LocalizationProvider>
                    </BrandThemeProvider>
                </BrandProvider>
            </JitsuProvider>
        </JucyUiProvider>
    </GrowthBookProvider>
);
