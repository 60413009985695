import { ThemeOptions } from '@mui/material';
import { ThemeColorOptions } from '@mui/material/styles';
import { defaultBreakPoints } from './defaultBreakPoints';
import { defaultComponents } from './defaultComponents';
import { createDefaultThemeSettings } from './defaultSettings';
import { defaultTypography } from './defaultTypography';

export const createDefaultThemeOptions = (colors: ThemeColorOptions): ThemeOptions => ({
    components: defaultComponents,
    settings: createDefaultThemeSettings(colors),
    typography: defaultTypography,
    breakpoints: defaultBreakPoints,
});
