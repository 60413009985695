'use client';
import { useCallback, useRef } from 'react';

export const useResizeObserver = (callback: ResizeObserverCallback) => {
    const ref = useRef<Element | null>();
    const resizeObserver = useRef( typeof ResizeObserver ==='undefined'? null: new ResizeObserver(callback)).current;
    return useCallback(
        (node: Element | null) => {
            if (ref.current) {
                resizeObserver?.unobserve(ref.current);
            }

            if (node) {
                resizeObserver?.observe(node);
            }

            ref.current = node;
        },
        [resizeObserver]
    );
};
