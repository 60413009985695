/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { RentalSignatureCustomer } from './RentalSignatureCustomer';
import { RentalSignatureCustomerFromJSON, RentalSignatureCustomerFromJSONTyped, RentalSignatureCustomerToJSON } from './RentalSignatureCustomer';
import type { RentalSignatureSignature } from './RentalSignatureSignature';
import { RentalSignatureSignatureFromJSON, RentalSignatureSignatureFromJSONTyped, RentalSignatureSignatureToJSON } from './RentalSignatureSignature';
import type { RentalSignatureSignatureTemplate } from './RentalSignatureSignatureTemplate';
import {
    RentalSignatureSignatureTemplateFromJSON,
    RentalSignatureSignatureTemplateFromJSONTyped,
    RentalSignatureSignatureTemplateToJSON,
} from './RentalSignatureSignatureTemplate';

/**
 *
 * @export
 * @interface RentalSignature
 */
export interface RentalSignature {
    /**
     *
     * @type {RentalSignatureCustomer}
     * @memberof RentalSignature
     */
    customer?: RentalSignatureCustomer;
    /**
     *
     * @type {boolean}
     * @memberof RentalSignature
     */
    isSigned?: boolean;
    /**
     *
     * @type {RentalSignatureSignature}
     * @memberof RentalSignature
     */
    signature?: RentalSignatureSignature;
    /**
     *
     * @type {string}
     * @memberof RentalSignature
     */
    forRego?: string;
    /**
     *
     * @type {Date}
     * @memberof RentalSignature
     */
    signedDate?: Date;
    /**
     *
     * @type {string}
     * @memberof RentalSignature
     */
    signedIp?: string;
    /**
     *
     * @type {RentalSignatureSignatureTemplate}
     * @memberof RentalSignature
     */
    signatureTemplate?: RentalSignatureSignatureTemplate;
    /**
     *
     * @type {boolean}
     * @memberof RentalSignature
     */
    isCcAuth?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RentalSignature
     */
    isOverCounterOnly?: boolean;
    /**
     *
     * @type {number}
     * @memberof RentalSignature
     */
    order?: number;
}

/**
 * Check if a given object implements the RentalSignature interface.
 */
export function instanceOfRentalSignature(value: object): value is RentalSignature {
    return true;
}

export function RentalSignatureFromJSON(json: any): RentalSignature {
    return RentalSignatureFromJSONTyped(json, false);
}

export function RentalSignatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): RentalSignature {
    if (json == null) {
        return json;
    }
    return {
        customer: json['customer'] == null ? undefined : RentalSignatureCustomerFromJSON(json['customer']),
        isSigned: json['isSigned'] == null ? undefined : json['isSigned'],
        signature: json['signature'] == null ? undefined : RentalSignatureSignatureFromJSON(json['signature']),
        forRego: json['forRego'] == null ? undefined : json['forRego'],
        signedDate: json['signedDate'] == null ? undefined : stringToDate(json['signedDate']),
        signedIp: json['signedIp'] == null ? undefined : json['signedIp'],
        signatureTemplate: json['signatureTemplate'] == null ? undefined : RentalSignatureSignatureTemplateFromJSON(json['signatureTemplate']),
        isCcAuth: json['isCcAuth'] == null ? undefined : json['isCcAuth'],
        isOverCounterOnly: json['isOverCounterOnly'] == null ? undefined : json['isOverCounterOnly'],
        order: json['order'] == null ? undefined : json['order'],
    };
}

export function RentalSignatureToJSON(value?: RentalSignature | null): any {
    if (value == null) {
        return value;
    }
    return {
        customer: RentalSignatureCustomerToJSON(value['customer']),
        isSigned: value['isSigned'],
        signature: RentalSignatureSignatureToJSON(value['signature']),
        forRego: value['forRego'],
        signedDate: value['signedDate'] == null ? undefined : dateToString(value['signedDate']),
        signedIp: value['signedIp'],
        signatureTemplate: RentalSignatureSignatureTemplateToJSON(value['signatureTemplate']),
        isCcAuth: value['isCcAuth'],
        isOverCounterOnly: value['isOverCounterOnly'],
        order: value['order'],
    };
}
