/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';

/**
 * Locations address
 * @export
 * @interface SiteAddress1
 */
export interface SiteAddress1 {
    /**
     * Location postal name
     * @type {string}
     * @memberof SiteAddress1
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof SiteAddress1
     */
    line1?: string;
    /**
     *
     * @type {string}
     * @memberof SiteAddress1
     */
    line2?: string;
    /**
     *
     * @type {string}
     * @memberof SiteAddress1
     */
    line3?: string;
    /**
     *
     * @type {number}
     * @memberof SiteAddress1
     */
    latitude?: number;
    /**
     *
     * @type {number}
     * @memberof SiteAddress1
     */
    longitude?: number;
    /**
     *
     * @type {string}
     * @memberof SiteAddress1
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof SiteAddress1
     */
    city?: string;
    /**
     *
     * @type {string}
     * @memberof SiteAddress1
     */
    postcode?: string;
    /**
     *
     * @type {string}
     * @memberof SiteAddress1
     */
    county?: string;
    /**
     *
     * @type {string}
     * @memberof SiteAddress1
     */
    country?: string;
}

/**
 * Check if a given object implements the SiteAddress1 interface.
 */
export function instanceOfSiteAddress1(value: object): value is SiteAddress1 {
    return true;
}

export function SiteAddress1FromJSON(json: any): SiteAddress1 {
    return SiteAddress1FromJSONTyped(json, false);
}

export function SiteAddress1FromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteAddress1 {
    if (json == null) {
        return json;
    }
    return {
        name: json['Name'] == null ? undefined : json['Name'],
        line1: json['Line1'] == null ? undefined : json['Line1'],
        line2: json['Line2'] == null ? undefined : json['Line2'],
        line3: json['Line3'] == null ? undefined : json['Line3'],
        latitude: json['Latitude'] == null ? undefined : json['Latitude'],
        longitude: json['Longitude'] == null ? undefined : json['Longitude'],
        state: json['State'] == null ? undefined : json['State'],
        city: json['City'] == null ? undefined : json['City'],
        postcode: json['Postcode'] == null ? undefined : json['Postcode'],
        county: json['County'] == null ? undefined : json['County'],
        country: json['Country'] == null ? undefined : json['Country'],
    };
}

export function SiteAddress1ToJSON(value?: SiteAddress1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        Name: value['name'],
        Line1: value['line1'],
        Line2: value['line2'],
        Line3: value['line3'],
        Latitude: value['latitude'],
        Longitude: value['longitude'],
        State: value['state'],
        City: value['city'],
        Postcode: value['postcode'],
        County: value['county'],
        Country: value['country'],
    };
}
