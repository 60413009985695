'use client';

import React from 'react';
import { CatalogItem, Deal, FleetCategory, cn } from '@jucy-ui/common';
import { SlotComponentProps, useSlotProps } from '@mui/base';
import { CardProps, styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { ComponentsOverrides, ComponentsVariants, Theme as MuiTheme, useThemeProps } from '@mui/material/styles';
import { FleetCategoryTeaserCardActionsPanel, FleetCategoryTeaserCardDescription, FleetCategoryTeaserCardDetails } from './components';
import { fleetCategoryTeaserClasses, mapDeal } from './lib';
import { FleetCategoryTeaserCardOwnerState, FleetCategoryTeaserCardPropsOverrides, FleetCategoryTeaserCardSlots } from './types';

export interface FleetCategoryTeaserCardProps extends Omit<CardProps, 'onSelect'> {
    fleetCategory: FleetCategory;
    catalogItem: CatalogItem;
    onSelect?: (fleetCategory: FleetCategory) => void;
    isActive?: boolean;
    isHighlighted?: boolean;
    isLoading?: boolean;
    currencyCode?: string;
    action?: 'create' | 'edit';
    className?: string;
    deal?: Partial<Deal>;
    slots?: FleetCategoryTeaserCardSlots;
    slotProps?: {
        root?: SlotComponentProps<typeof Card, FleetCategoryTeaserCardPropsOverrides, FleetCategoryTeaserCardOwnerState>;
        descriptionPanel?: SlotComponentProps<
            typeof FleetCategoryTeaserCardDescription,
            FleetCategoryTeaserCardPropsOverrides,
            FleetCategoryTeaserCardOwnerState
        >;
        detailsPanel?: SlotComponentProps<
            typeof FleetCategoryTeaserCardDetails,
            FleetCategoryTeaserCardPropsOverrides,
            FleetCategoryTeaserCardOwnerState
        >;
        actionsPanel?: SlotComponentProps<
            typeof FleetCategoryTeaserCardActionsPanel,
            FleetCategoryTeaserCardPropsOverrides,
            FleetCategoryTeaserCardOwnerState
        >;
    };
    children?: React.ReactNode;
}

export const FleetCategoryTeaserCard = React.forwardRef<HTMLDivElement, FleetCategoryTeaserCardProps>((inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: 'JucyFleetCategoryTeaserCard' });
    const {
        slotProps,
        className,
        component,
        variant,
        fleetCategory,
        catalogItem,
        isActive,
        isHighlighted,
        currencyCode,
        action,
        deal,
        isLoading,
        children,
        ...other
    } = props;
    const ownerState = {
        ...props,
        variant,
        fleetCategory,
        catalogItem,
        isLoading,
        isActive,
        isHighlighted,
        currencyCode,
        action,
        deal: mapDeal({
            fleetCategory,
            action,
            deal,
        }),
        isBookable:
            !fleetCategory.minimumHirePeriod &&
            fleetCategory.availability !== 'StopSell' &&
            fleetCategory.dailyRate &&
            fleetCategory.dailyRate.value > 0,
        isOnRequest: fleetCategory.availability === 'OnRequest',
    };
    const RootSlot = props.slots?.root || FleetCategoryTeaserCardRoot;
    const DescriptionSlot = props.slots?.descriptionPanel || FleetCategoryTeaserCardDescription;
    const DetailsSlot = props.slots?.detailsPanel || FleetCategoryTeaserCardDetails;
    const ActionsSlot = props.slots?.actionsPanel || FleetCategoryTeaserCardActionsPanel;

    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            ref,
            as: component,
            variant: 'outlined' as const,
        },
        ownerState,
        className: className,
    });
    const descriptionProps = useSlotProps({
        elementType: DescriptionSlot,
        externalSlotProps: slotProps?.descriptionPanel,
        ownerState,
    });
    const detailsProps = useSlotProps({
        elementType: DetailsSlot,
        externalSlotProps: slotProps?.detailsPanel,
        ownerState,
    });
    const actionProps = useSlotProps({
        elementType: ActionsSlot,
        externalSlotProps: slotProps?.actionsPanel,
        ownerState,
    });
    return (
        <RootSlot {...rootProps} className={cn(fleetCategoryTeaserClasses.root, rootProps.className)}>
            <CardContent sx={{ '&:last-child': { paddingBottom: 2 } }} className={fleetCategoryTeaserClasses.content}>
                <Grid container spacing={3} className={fleetCategoryTeaserClasses.grid}>
                    <Grid xs={12} sm={3.9} sx={{ display: 'flex' }} className={fleetCategoryTeaserClasses.descriptionCol}>
                        <DescriptionSlot {...descriptionProps} />
                    </Grid>
                    <Grid xs={12} sm={4.7} sx={{ display: 'flex' }} className={fleetCategoryTeaserClasses.detailsCol}>
                        <DetailsSlot {...detailsProps} />
                    </Grid>
                    <Grid xs={12} sm={3.4} sx={{ display: 'flex' }} className={fleetCategoryTeaserClasses.actionsCol}>
                        <ActionsSlot {...actionProps} />
                    </Grid>
                </Grid>
                {children}
            </CardContent>
        </RootSlot>
    );
});
FleetCategoryTeaserCard.displayName = 'FleetCategoryTeaserCard';

const FleetCategoryTeaserCardRoot = styled(Card, {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'root',
    shouldForwardProp: (prop) => prop !== 'ownerState' && prop !== 'slots',
})<{ ownerState: FleetCategoryTeaserCardOwnerState }>(({ theme, ownerState }) => ({
    borderStyle: 'solid',
    transition: theme.transitions.create(['border-color'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
        borderColor: theme.palette.secondary.main,
    },
    ...(ownerState.isHighlighted ? { boxShadow: theme.colors.shadows.card } : undefined),
    ...(ownerState.isActive ? { borderColor: theme.palette.secondary.main } : undefined),
    ...(ownerState.deal.actions && (ownerState.fleetCategory.availability !== 'OnRequest' || ownerState.action !== 'edit')
        ? {
              borderTopWidth: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
          }
        : undefined),
}));
declare module '@mui/material/styles' {
    interface ComponentNameToClassKey {
        FleetCategoryTeaserCard: 'root' | 'details' | 'actions';
    }

    interface ComponentsPropsList {
        FleetCategoryTeaserCard: Partial<FleetCategoryTeaserCardProps>;
    }

    interface Components {
        FleetCategoryTeaserCard?: {
            defaultProps?: ComponentsPropsList['FleetCategoryTeaserCard'];
            styleOverrides?: ComponentsOverrides<Omit<MuiTheme, 'components'>>['FleetCategoryTeaserCard'];
            variants?: ComponentsVariants['FleetCategoryTeaserCard'];
        };
    }
}
