'use client';

import React, { PropsWithoutRef, useId, useMemo } from 'react';
import { cn } from '@jucy-ui/common';
import { FormControlProps, SelectProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectInputLabel } from './SelectInputLabel';

export interface AgeSelectProps extends Omit<PropsWithoutRef<SelectProps<string>>, 'children' | 'onChange'> {
    ages?: string[];
    formGroupOptions?: Partial<FormControlProps>;
    onChange?: (value: string) => void;
    helperText?: React.ReactNode;
}

export const AgeSelect = React.forwardRef<HTMLSelectElement, AgeSelectProps>(
    ({ ages, formGroupOptions,sx, id: passedId, value, onChange, helperText, error, className, name, disabled, ...props }, ref) => {
        const children = useAgesToMenuMenuItem(ages || []);
        const randId = useId();
        const id = passedId || randId;
        const labelId = `${id}-label`;
        return (
            <FormControl fullWidth={true} {...formGroupOptions} className={cn(className,formGroupOptions?.className)} error={formGroupOptions?.error || error}>
                <SelectInputLabel id={labelId} htmlFor={id}>
                    {props.label}
                </SelectInputLabel>
                <Select
                    onChange={(e) => onChange?.(e.target.value || '')}
                    ref={ref}
                    name={name}
                    disabled={disabled}
                    value={value || ''}
                    {...props}
                >
                    {children}
                </Select>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </FormControl>
        );
    }
);
AgeSelect.displayName = 'AgeSelect';
const useAgesToMenuMenuItem = (ages: string[]) =>
    useMemo(
        () =>
            ages.map((age, i, { length }) => (
                <MenuItem key={age} value={age}>
                    {age}
                    {i + 1 === length ? '+' : ''}
                </MenuItem>
            )),
        [ages]
    );
