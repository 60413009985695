/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { SiteServiceHours } from './SiteServiceHours';
import { SiteServiceHoursFromJSON, SiteServiceHoursFromJSONTyped, SiteServiceHoursToJSON } from './SiteServiceHours';

/**
 *
 * @export
 * @interface SiteSiteSettings
 */
export interface SiteSiteSettings {
    /**
     * Fleet type unique identifier
     * @type {string}
     * @memberof SiteSiteSettings
     */
    fleetTypeId?: string;
    /**
     * Fleet type display name
     * @type {string}
     * @memberof SiteSiteSettings
     */
    fleetTypeName?: string;
    /**
     *
     * @type {SiteServiceHours}
     * @memberof SiteSiteSettings
     */
    serviceHours?: SiteServiceHours;
    /**
     * Indicates if this location offers picking up this fleet type
     * after hours
     * @type {boolean}
     * @memberof SiteSiteSettings
     */
    isAfterHoursPickUpAvailable?: boolean;
    /**
     * Indicates if this location offers dropping off this fleet type
     * after hours
     * @type {boolean}
     * @memberof SiteSiteSettings
     */
    isAfterHoursDropOffAvailable?: boolean;
}

/**
 * Check if a given object implements the SiteSiteSettings interface.
 */
export function instanceOfSiteSiteSettings(value: object): value is SiteSiteSettings {
    return true;
}

export function SiteSiteSettingsFromJSON(json: any): SiteSiteSettings {
    return SiteSiteSettingsFromJSONTyped(json, false);
}

export function SiteSiteSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteSiteSettings {
    if (json == null) {
        return json;
    }
    return {
        fleetTypeId: json['FleetTypeId'] == null ? undefined : json['FleetTypeId'],
        fleetTypeName: json['FleetTypeName'] == null ? undefined : json['FleetTypeName'],
        serviceHours: json['ServiceHours'] == null ? undefined : SiteServiceHoursFromJSON(json['ServiceHours']),
        isAfterHoursPickUpAvailable: json['IsAfterHoursPickUpAvailable'] == null ? undefined : json['IsAfterHoursPickUpAvailable'],
        isAfterHoursDropOffAvailable: json['IsAfterHoursDropOffAvailable'] == null ? undefined : json['IsAfterHoursDropOffAvailable'],
    };
}

export function SiteSiteSettingsToJSON(value?: SiteSiteSettings | null): any {
    if (value == null) {
        return value;
    }
    return {
        FleetTypeId: value['fleetTypeId'],
        FleetTypeName: value['fleetTypeName'],
        ServiceHours: SiteServiceHoursToJSON(value['serviceHours']),
        IsAfterHoursPickUpAvailable: value['isAfterHoursPickUpAvailable'],
        IsAfterHoursDropOffAvailable: value['isAfterHoursDropOffAvailable'],
    };
}
