'use client';
import {
    FieldPath,
    FieldValues,
    Message,
    ControllerRenderProps as RhfControllerRenderProps,
    UseControllerProps as UseRhfControllerProps,
    UseControllerReturn as UseRhfControllerReturn,
    useController as useRhfController,
} from 'react-hook-form';
import { useFieldMeta } from './useFieldMeta';

interface UseControllerProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
    extends UseRhfControllerProps<TFieldValues, TName> {}

interface UseControllerReturn<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
    extends Omit<UseRhfControllerReturn<TFieldValues, TName>, 'field'> {
    field: ControllerRenderProps<TFieldValues, TName>;
}

interface ControllerRenderProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
    extends RhfControllerRenderProps<TFieldValues, TName> {
    required: boolean;
    label: string;
    error?: boolean;
    helperText?: Message;
}

export const useController = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: UseControllerProps<TFieldValues, TName>
): UseControllerReturn<TFieldValues, TName> => {
    const { field: origField, ...rest } = useRhfController(props);
    const meta = useFieldMeta(props);
    const required = Boolean(meta.required);
    const error = Boolean(rest.fieldState.error?.message);
    const helperText = rest.fieldState.error?.message || undefined;
    const field = {
        required,
        label: meta.label || '',
        ...(error && { error, helperText }),
        ...origField,
    };
    return { field, ...rest };
};
