import ErrorReporter from '#/lib/ErrorReporter';
import { UserMode } from '../../types/UserMode';
import providers from './Providers';
import { jucyAttribution } from '#/lib/SalesMonitoring/jucyAttribution';

export const createQuoteQueryParams = (accountType?: UserMode): Record<string, string> => {
    const params = providers.reduce((res, p) => {
        try {
            if (p.createQuoteQueryParams) {
                return {
                    ...res,
                    ...p.createQuoteQueryParams(accountType),
                };
            }
        } catch (err) {
            ErrorReporter.captureError(err);
        }
        return res;
    }, Object.fromEntries(jucyAttribution.getFromStorage()));

    return params;
};
