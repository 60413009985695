import { UserMode } from '../../../types/UserMode';
import Quote from '../../api/model/Quote';
import { SalesMonitorProvider } from './SalesMonitorProvider';

const urlSearchParamKey = 'kayakclickid';
const localStorageKey = 'jucy_kayakclickid';
export class Kayak implements SalesMonitorProvider {
    init(): void {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const clickId = urlSearchParams?.get(urlSearchParamKey);

        if (clickId) {
            localStorage.setItem(localStorageKey, clickId);
        }
    }

    confirmPurchase(): void {
        return;
    }

    enabled(quote: Quote, accountType: UserMode): boolean {
        return Boolean(accountType === 'direct' && quote?.BookingStatus === 'Confirmed');
    }

    createQuoteQueryParams(accountType?: UserMode): Record<string, string> {
        const clickId = localStorage?.getItem(localStorageKey);
        if (accountType === 'direct' && clickId) {
            return { [urlSearchParamKey]: clickId };
        }
        return {};
    }
}
