import { SiteSettings } from '@jucy-ui/common';
import { getServiceHours } from './getServiceHours';

export const shouldDisableTime = ({ time, siteSettings }: { time?: Date | null; siteSettings?: SiteSettings }) => {
    const serviceHours = getServiceHours({ date: time, siteSettings });
    if (!time || !serviceHours || siteSettings?.isAfterHoursPickUpAvailable) {
        return false;
    }

    return time < serviceHours.open || time > serviceHours.close;
};
