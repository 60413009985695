import { isValidDate } from '@jucy-ui/common';
import { getServiceHours } from '../lib';
import { useBranchSettings } from './useBranchSettings';
import { useSearchFormFieldValue } from './useSearchFormFieldValue';

export const useGetServiceHours = () => {
    const pickUpDate = useSearchFormFieldValue('pickUpDate');
    const dropOffDate = useSearchFormFieldValue('dropOffDate');
    const { pickUpBranchSettings, dropOffBranchSettings } = useBranchSettings();

    const pickUpBranchServiceHours =
        pickUpBranchSettings && isValidDate(pickUpDate) ? getServiceHours({ date: pickUpDate, siteSettings: pickUpBranchSettings }) : undefined;
    const dropOffBranchServiceHours =
        dropOffBranchSettings && isValidDate(dropOffDate) ? getServiceHours({ date: dropOffDate, siteSettings: dropOffBranchSettings }) : undefined;

    return {
        isAfterHoursPickUpAvailable: pickUpBranchSettings?.isAfterHoursPickUpAvailable,
        isAfterHoursDropOffAvailable: dropOffBranchSettings?.isAfterHoursDropOffAvailable,
        pickUpBranchServiceHours,
        dropOffBranchServiceHours,
    };
};
