import { ComponentPreview, Previews } from '@react-buddy/ide-toolbox';
import React from 'react';
import SearchBar from '#/components/Tables/SearchBar';
import Page from '#/obePages/direct/DirectCreateQuotePage';
import ProjectPalette from './palette';

const ComponentPreviews = () => (
    <Previews palette={<ProjectPalette />}>
        <ComponentPreview path="/HomePage">
            <Page />
        </ComponentPreview>
        <ComponentPreview path="/SearchBar">
            <SearchBar />
        </ComponentPreview>
    </Previews>
);

export default ComponentPreviews;
