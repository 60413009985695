import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import CurrencyStore from '../../../../../store/CurrencyStore';
import TranslationStore from '../../../../../store/TranslationStore';
import { JucyButton } from '../../../../Button/Button';
import CurrencyFormatter, { getCurrencyComponents } from '../../../../CurrencyFormatter/CurrencyFormatter';
import JucyTooltip from '../../../../JucyToolTip';
import { useTeaserContext } from '../TeaserContext';
import BuyNowPayLater from './BuyNowPayLater';

const useAvailabilityTooltipMessage = (): { message: React.ReactNode; tooltip: NonNullable<React.ReactNode> } | null => {
    const { fleetCategory, action } = useTeaserContext();
    const { t } = useTranslation(TranslationStore.currentNamespace);

    if (fleetCategory.isUnderAge) {
        return {
            message: 'Unavailable for selected age',
            tooltip: fleetCategory.availabilityMessage,
        };
    }

    if (fleetCategory.isOnRequest) {
        if (action === 'edit') {
            return {
                message: t('fleet_result_list.group.sold_out_message_1') as string,
                tooltip: 'This vehicle is on request and unavailable at this time',
            };
        }
        return {
            message: t('fleet_result_list.group.limited_availability_message_1') as string,
            tooltip: t('fleet_result_list.group.limited_availability_message_2') as string,
        };
    }
    if (fleetCategory.isMinHirePeriod) {
        return {
            message: t('fleet_result_list.group.min_period_message_1', { minDays: fleetCategory.MinimumHirePeriod }) as string,
            tooltip: t('fleet_result_list.group.min_period_message_2', { minDays: fleetCategory.MinimumHirePeriod }) as string,
        };
    }
    if (fleetCategory.isStopSell) {
        return {
            message: t('fleet_result_list.group.sold_out_message_1') as string,
            tooltip: t('fleet_result_list.group.sold_out_message_2') as string,
        };
    }

    return null;
};

const useBookButtonText = (): React.ReactNode | null => {
    const { fleetCategory, isActive } = useTeaserContext();
    const { t } = useTranslation(TranslationStore.currentNamespace);

    if (fleetCategory.isUnderAge) {
        return 'Unavailable';
    }

    if (fleetCategory.isFreeSell) {
        return t(isActive ? 'fleet_result_list.group.selected' : 'fleet_result_list.group.select');
    }
    if (fleetCategory.isOnRequest) {
        return t('fleet_result_list.group.request');
    }
    if (fleetCategory.isMinHirePeriod) {
        return t('fleet_result_list.group.min_period', { minDays: fleetCategory?.MinimumHirePeriod });
    }
    if (fleetCategory.isStopSell) {
        return t('fleet_result_list.group.sold_out');
    }

    return '';
};

const AvailabilityTooltips: React.FC = () => {
    const message = useAvailabilityTooltipMessage();
    if (message) {
        return (
            <JucyTooltip title={message.tooltip} arrow>
                <Typography variant="caption" sx={{ color: 'unset' }} fontSize="12px" component="div">
                    {message.message} <i className="fa fa-info-circle" aria-hidden="true" />
                </Typography>
            </JucyTooltip>
        );
    }

    return null;
};

const FleetCategoryActions: React.FC = () => {
    const { fleetCategory, onSelect, isActive, isLoading, currencyCode, action } = useTeaserContext();
    const theme = useTheme();
    const buttonLabel = useBookButtonText();
    const dailyRateComponents = getCurrencyComponents(CurrencyStore.convertCurrency(fleetCategory.dailyRate));
    return (
        <Box
            sx={{
                ...(action === 'edit'
                    ? {
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                      }
                    : undefined),
            }}
        >
            {fleetCategory.isBookable && (action === 'create' || !fleetCategory.isOnRequest) ? (
                <Box className="price">
                    {fleetCategory.dailyRate.value < fleetCategory.originalDailyRate.value && (
                        <Typography
                            data-cy="original-daily-rate"
                            variant="caption"
                            color="text.secondary"
                            fontSize="12px"
                            fontWeight="700"
                            sx={{ textDecoration: 'line-through' }}
                            component="div"
                        >
                            <CurrencyFormatter prefixCurrencyCode={true} price={fleetCategory.OriginalDailyRate} />
                            /day
                        </Typography>
                    )}
                    <Typography data-cy="daily-rate" component="div" fontSize="18px" fontWeight="700">
                        {currencyCode}{' '}
                        {dailyRateComponents.map(({ type, value }, i) => {
                            switch (type) {
                                case 'currency':
                                    return value.replace(/^(-?)[A-Z]+/, '$1');
                                case 'decimal':
                                case 'fraction':
                                    return (
                                        <small key={`${value}-${i}`} className="fw-bold">
                                            {value}
                                        </small>
                                    );
                                default:
                                    return value;
                            }
                        })}
                        <small className="fw-bold">/day</small>
                    </Typography>
                    <Typography variant="caption" color="rgba(0, 0, 0, 0.95)" fontSize="12px" component="div" lineHeight={1.5}>
                        <CurrencyFormatter price={fleetCategory.Total} /> total
                        <BuyNowPayLater />
                    </Typography>
                </Box>
            ) : null}
            <Box
                sx={{
                    ...(action === 'edit'
                        ? undefined
                        : {
                              mt: 'auto',
                              mb: fleetCategory.isBookable ? 'unset' : 'auto',
                          }),
                }}
            >
                {action !== 'edit' || !fleetCategory.isOnRequest ? (
                    <JucyButton
                        variant="contained"
                        color={fleetCategory.isOnRequest ? 'light' : 'primary'}
                        showLoader={isLoading && isActive}
                        disabled={!fleetCategory.isBookable || isLoading}
                        onClick={onSelect}
                        data-cy="select-button"
                        sx={{
                            width: {
                                xs: '100%',
                                sm: 'auto',
                            },
                            mt: {
                                xs: 1,
                            },
                            background: fleetCategory.isOnRequest ? theme.palette.grey[100] : undefined,
                            color: fleetCategory.isOnRequest ? theme.palette.primary.main : undefined,
                        }}
                    >
                        {buttonLabel}
                    </JucyButton>
                ) : null}
                <AvailabilityTooltips />
            </Box>
        </Box>
    );
};
export default FleetCategoryActions;
