import { PartialSearchFormValues } from '../components/Forms/TripSearchForm/SearchFormValues';
import Quote from './api/model/Quote';

export const quoteToValues = (quote: Quote): PartialSearchFormValues => ({
    fleetType: quote ? quote.FleetType : undefined,
    pickUpLocation: quote ? quote.PickUpSite : undefined,
    pickUpDate: quote ? quote.PickUpDateTime : undefined,
    pickUpTime: quote ? quote.PickUpDateTime : undefined,
    dropOffLocation: quote ? quote.DropOffSite : undefined,
    dropOffDate: quote ? quote.DropOffDateDateTime : undefined,
    dropOffTime: quote ? quote.DropOffDateDateTime : undefined,
    fleetCategoryCode: quote ? quote.FleetCategory.CategoryCode : undefined,
});
