import * as React from 'react';
import { useRef } from 'react';
import ReactPhoneInput, { CountryData, PhoneInputInstance, PhoneInputProps } from 'react-phone-input-2';
import { InputBaseComponentProps } from '@mui/material';
import { Field, FieldAttributes } from 'formik';
import { TextField, TextFieldProps } from 'formik-mui';

const isCountryData = (data: unknown): data is CountryData => Boolean(data && (data as CountryData).name !== undefined);

type MuiPhoneNumberInputProps = InputBaseComponentProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement> & { phoneInputProps?: PhoneInputProps };
const MuiPhoneNumberInput = React.forwardRef<HTMLInputElement, MuiPhoneNumberInputProps>(({ phoneInputProps, value, onBlur, onChange, onFocus, ...inputProps }, inputRef) => {
    const ref = useRef<PhoneInputInstance>(null);
    return (
        <ReactPhoneInput
            ref={ref}
            autoFormat={true}
            alwaysDefaultMask={true}
            defaultMask="..............."
            specialLabel={inputProps.label}
            inputProps={{
                ref: inputRef,
                autoComplete: 'tel',
                'data-detect-autofill': 'true',
                ...phoneInputProps?.inputProps,
                ...inputProps,
            }}
            value={value}
            preferredCountries={['nz', 'au']}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={(phoneNumber, country, e) => {
                if (e.target.name === inputProps.name) {
                    if (phoneNumber.startsWith('0') && isCountryData(country) && ref.current) {
                        phoneNumber = ref.current.formatNumber(`${country.dialCode}${phoneNumber.replace(/^0/, '')}`, country);
                        e.target.value = phoneNumber;
                    }
                    onChange?.(e);
                }
            }}
            {...inputProps}
            {...phoneInputProps}
        />
    );
});
MuiPhoneNumberInput.displayName = 'MuiPhoneNumberInput';

const PhoneNumberFormikInput: React.FC<TextFieldProps & { phoneInputProps?: PhoneInputProps }> = ({ phoneInputProps, ...props }) => (
    <TextField
        {...props}
        InputLabelProps={{ shrink: true }}
        sx={(theme) => ({
            '.react-tel-input .MuiOutlinedInput-input': {
                paddingLeft: props.size === 'small' ? 0 : undefined,
                paddingRight: props.size === 'small' ? 0 : undefined,
                boxSizing: 'content-box',
            },
            '.react-tel-input': {
                overflow: 'visible',
                padding: props.size === 'small' ? theme.spacing(0, 0, 0, 6.5) : undefined,
                height: props.size === 'small' ? 'auto' : undefined,
            },
            '& .react-tel-input .special-label': {
                display: 'none',
            },
            '& .flag-dropdown': {
                left: -1,
            },
        })}
        inputProps={{ phoneInputProps }}
        InputProps={{
            inputComponent: MuiPhoneNumberInput,
        }}
    />
);

const PhoneNumberInput: React.FC<FieldAttributes<Omit<TextFieldProps, 'field' | 'form' | 'meta'> & { phoneInputProps?: PhoneInputProps }>> = (props) => (
    <Field {...props} fullWidth size="small" component={PhoneNumberFormikInput} />
);

export default PhoneNumberInput;
