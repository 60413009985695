import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import { useSearchParams } from '../../../../hooks';
import FleetCategoryAvailability from '../../../../lib/api/model/FleetCategoryAvailability';
import AvailabilityStore from '../../../../store/AvailabilityStore';
import RentalTripSearchStore from '../../../../store/RentalTripSearchStore';
import AvailabilityGrid, { AvailabilityGridProps } from './AvailabilityGrid';

const useActiveFleetCategory = () => {
    const [activeParams] = useSearchParams<{ fleetCategory: string }>();
    const [activeFleetCategory, setActiveFleetCategory] = useState<FleetCategoryAvailability | null>(null);
    useEffect(
        () =>
            autorun(() => {
                setActiveFleetCategory(activeParams.fleetCategory ? AvailabilityStore.availableFleetCategories.find((f) => f.CategoryCode === activeParams.fleetCategory) || null : null);
            }),
        [activeParams.fleetCategory]
    );
    return activeFleetCategory;
};

const AvailabilityCaption: React.FC = observer(() => {
    const activeCategory = useActiveFleetCategory();
    if (activeCategory?.isFreeSell) {
        return <>{activeCategory?.Name} is available</>;
    }
    if (activeCategory?.isOnRequest) {
        return <>{activeCategory?.Name} is available on request</>;
    }
    if (activeCategory?.Availability) {
        return <>{activeCategory?.Name} is not available for your dates, see other options below</>;
    }
    return (
        <>
            <span data-cy="available-count">{activeCategory ? AvailabilityStore.freeSellCount - 1 : AvailabilityStore.freeSellCount}</span> {activeCategory ? ' other ' : ''}{' '}
            {AvailabilityStore.fleetType?.name || 'vehicle'}
            {AvailabilityStore.freeSellCount !== 1 ? 's' : ''} available
            {AvailabilityStore.onRequestCount > 0 ? <>, {AvailabilityStore.onRequestCount} on request</> : '.'}
        </>
    );
});
AvailabilityCaption.displayName = 'AvailabilityCaption';

const MoreOptionsCaption: React.FC = observer(() => {
    const activeCategory = useActiveFleetCategory();
    return (
        <>
            <span data-cy="available-count">{activeCategory ? AvailabilityStore.freeSellCount - 1 : AvailabilityStore.freeSellCount}</span> {activeCategory ? ' other ' : ''}{' '}
            {AvailabilityStore.fleetType?.name || 'vehicle'}
            {AvailabilityStore.freeSellCount !== 1 ? 's' : ''} available
            {AvailabilityStore.onRequestCount > 0 ? <>, {AvailabilityStore.onRequestCount} on request</> : '.'}
        </>
    );
});
MoreOptionsCaption.displayName = 'MoreOptionsCaption';

export type DirectAvailabilityGridProps = Omit<AvailabilityGridProps, 'title' | 'caption' | 'moreOptionsTitle' | 'moreOptionsCaption'>;

const DirectAvailabilityGrid = React.forwardRef<HTMLDivElement, DirectAvailabilityGridProps>((props, ref) => (
    <AvailabilityGrid
        ref={ref}
        action="create"
        title={!RentalTripSearchStore.isRelocation ? 'Results' : undefined}
        caption={<AvailabilityCaption />}
        moreOptionsTitle="More options"
        moreOptionsCaption={<MoreOptionsCaption />}
        {...props}
    />
));
DirectAvailabilityGrid.displayName = 'DirectAvailabilityGrid';

export default DirectAvailabilityGrid;
