import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TranslationStore from '../../../store/TranslationStore';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import BookingSummaryAddOnRow from './BookingSummaryAddOnRow';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';

const InsuranceSummary: React.FC = observer(() => {
    const context = useBookingSummaryContext();
    const { t } = useTranslation(TranslationStore.currentNamespace);
    return context.summary?.selectedExcess ? (
        <BookingSummaryGroupItem
            title={t('trip_summary.group.excess_reduction')}
            className="animate__animated animate__fadeIn"
            isEditable={true}
            editRouteParams={{
                activeTab: 'excess-reduction',
            }}
        >
            {context.summary?.selectedExcess && (
                <BookingSummaryAddOnRow
                    data-cy="insurance-summary"
                    amendedItem={context.summary.action === 'edit' ? context.summary?.amendments?.selectedExcess : undefined}
                    key={context.summary?.selectedExcess.productId}
                    item={context.summary?.selectedExcess}
                />
            )}
        </BookingSummaryGroupItem>
    ) : null;
});
InsuranceSummary.displayName = 'InsuranceSummary';
export default InsuranceSummary;
