'use client';
import React from 'react';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import { JucyAlert, JucyAlertProps } from './JucyAlert';

export interface ErrorPanelProps extends Omit<JucyAlertProps, 'severity' | 'color' | 'title'> {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    message?: React.ReactNode;
}

export const ErrorPanel: React.FC<ErrorPanelProps> = ({ title = 'WHOOPS!', subTitle, message, children, ...props }) => (
    <JucyAlert
        color="primary"
        variant="outlined"
        severity="warning"
        sx={{
            '& .MuiAlert-icon': {
                color: 'primary.main',
                fontSize: 40,
            },
        }}
        {...props}
    >
        {title ? (
            <AlertTitle
                sx={(theme) => ({
                    lineHeight: 1,
                    color: theme.palette.primary.main,
                    fontSize: theme.typography.h3.fontSize,
                })}
            >
                {title}
            </AlertTitle>
        ) : null}
        {subTitle ? (
            <Typography variant="h5" component="div">
                {subTitle}
            </Typography>
        ) : null}
        {children}
    </JucyAlert>
);
