'use client';
import React from 'react';
import { FleetCategory } from '@jucy-ui/common';
import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AvailabilityGridOwnerState } from './AvailabilityGrid';

const AvailabilityGridFooterRoot = styled(Box, {
    name: 'JucyAvailabilityGrid',
    slot: 'footer',
})<{ ownerState?: AvailabilityGridOwnerState }>(() => undefined);

export interface AvailabilityGridFooterProps extends BoxProps {
    fleetCategories: FleetCategory[];
}

export const AvailabilityGridFooter: React.FC<AvailabilityGridFooterProps> = ({ fleetCategories, ...props }) => {
    const fleetType = fleetCategories[0]?.fleetTypeCode;
    return fleetType === 'motorhome' ? (
        <AvailabilityGridFooterRoot {...props}>
            <Typography variant="caption" component="small" sx={{ display: 'block', color: 'text.secondary' }}>
                <sup>1</sup> Chassis / Vehicle type allocated subject to availability upon pick-up
            </Typography>
            <Typography variant="caption" component="small" sx={{ display: 'block', color: 'text.secondary' }}>
                <sup>2</sup> Vehicle decals vary
            </Typography>
        </AvailabilityGridFooterRoot>
    ) : null;
};
AvailabilityGridFooter.displayName = 'AvailabilityGridFooter';
