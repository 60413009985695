import { Type, plainToInstance } from 'class-transformer';
import { PartialDeep } from 'type-fest';
import { HirePeriod } from './HirePeriod';
import { SiteSettings } from './SiteSettings';

export class SiteSettingResponse {
    @Type(() => HirePeriod)
    hirePeriods: HirePeriod[] = [];

    @Type(() => SiteSettings)
    sites: SiteSettings[] = [];

    static fromPlain(props: PartialDeep<SiteSettingResponse>): SiteSettingResponse {
        return plainToInstance(SiteSettingResponse, props);
    }
}
