/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { GeoLocation } from './GeoLocation';
import { GeoLocationFromJSON, GeoLocationFromJSONTyped, GeoLocationToJSON } from './GeoLocation';
import type { SiteAddress1 } from './SiteAddress1';
import { SiteAddress1FromJSON, SiteAddress1FromJSONTyped, SiteAddress1ToJSON } from './SiteAddress1';
import type { SiteDefaultTimes } from './SiteDefaultTimes';
import { SiteDefaultTimesFromJSON, SiteDefaultTimesFromJSONTyped, SiteDefaultTimesToJSON } from './SiteDefaultTimes';
import type { SiteHolidays } from './SiteHolidays';
import { SiteHolidaysFromJSON, SiteHolidaysFromJSONTyped, SiteHolidaysToJSON } from './SiteHolidays';
import type { SiteSiteSettings } from './SiteSiteSettings';
import { SiteSiteSettingsFromJSON, SiteSiteSettingsFromJSONTyped, SiteSiteSettingsToJSON } from './SiteSiteSettings';

/**
 *
 * @export
 * @interface Site
 */
export interface Site {
    /**
     *
     * @type {GeoLocation}
     * @memberof Site
     */
    geoLocation?: GeoLocation;
    /**
     * Indicates if this location supports domestic shuttle pick ups
     * @type {boolean}
     * @memberof Site
     */
    domesticPickUp?: boolean;
    /**
     * Indicates if this location supports international shuttle pick ups
     * @type {boolean}
     * @memberof Site
     */
    internationalPickUp?: boolean;
    /**
     * Link to google maps with branch as destination
     * @type {string}
     * @memberof Site
     */
    directionsLink?: string;
    /**
     *
     * @type {SiteAddress1}
     * @memberof Site
     */
    address1?: SiteAddress1;
    /**
     * Location unique identifier
     * @type {string}
     * @memberof Site
     */
    id?: string;
    /**
     * Display name for location
     * @type {string}
     * @memberof Site
     */
    name?: string;
    /**
     * Location unique short identifier
     * @type {string}
     * @memberof Site
     */
    siteCode?: string;
    /**
     * Timezone for the location
     * @type {string}
     * @memberof Site
     */
    timeZone?: string;
    /**
     * Location country
     * @type {string}
     * @memberof Site
     */
    country?: string;
    /**
     * Location country short code
     * @type {string}
     * @memberof Site
     */
    countryCode?: string;
    /**
     * List of minimum age options for location
     * @type {Array<string>}
     * @memberof Site
     */
    minAges?: Array<string>;
    /**
     * List of fleet types available from location and their associated
     * service hours
     * @type {Array<SiteSiteSettings>}
     * @memberof Site
     */
    siteSettings?: Array<SiteSiteSettings>;
    /**
     * Dates and times this location will be closed for holidays.
     * Pick ups or drop offs will be unavailable for this location between holiday
     * start and end dates/times
     * @type {Array<SiteHolidays>}
     * @memberof Site
     */
    holidays?: Array<SiteHolidays>;
    /**
     *
     * @type {SiteDefaultTimes}
     * @memberof Site
     */
    defaultTimes?: SiteDefaultTimes;
    /**
     * Jucy Business Unit ID for location
     * @type {string}
     * @memberof Site
     */
    businessUnit?: string;
    /**
     * Local phone number for the branch
     * @type {string}
     * @memberof Site
     */
    phone?: string;
    /**
     * Phone number for reservations at this branch
     * @type {string}
     * @memberof Site
     */
    reservationsPhone?: string;
}

/**
 * Check if a given object implements the Site interface.
 */
export function instanceOfSite(value: object): value is Site {
    return true;
}

export function SiteFromJSON(json: any): Site {
    return SiteFromJSONTyped(json, false);
}

export function SiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Site {
    if (json == null) {
        return json;
    }
    return {
        geoLocation: json['GeoLocation'] == null ? undefined : GeoLocationFromJSON(json['GeoLocation']),
        domesticPickUp: json['DomesticPickUp'] == null ? undefined : json['DomesticPickUp'],
        internationalPickUp: json['InternationalPickUp'] == null ? undefined : json['InternationalPickUp'],
        directionsLink: json['DirectionsLink'] == null ? undefined : json['DirectionsLink'],
        address1: json['Address1'] == null ? undefined : SiteAddress1FromJSON(json['Address1']),
        id: json['Id'] == null ? undefined : json['Id'],
        name: json['Name'] == null ? undefined : json['Name'],
        siteCode: json['SiteCode'] == null ? undefined : json['SiteCode'],
        timeZone: json['TimeZone'] == null ? undefined : json['TimeZone'],
        country: json['Country'] == null ? undefined : json['Country'],
        countryCode: json['CountryCode'] == null ? undefined : json['CountryCode'],
        minAges: json['MinAges'] == null ? undefined : json['MinAges'],
        siteSettings: json['SiteSettings'] == null ? undefined : (json['SiteSettings'] as Array<any>).map(SiteSiteSettingsFromJSON),
        holidays: json['Holidays'] == null ? undefined : (json['Holidays'] as Array<any>).map(SiteHolidaysFromJSON),
        defaultTimes: json['DefaultTimes'] == null ? undefined : SiteDefaultTimesFromJSON(json['DefaultTimes']),
        businessUnit: json['BusinessUnit'] == null ? undefined : json['BusinessUnit'],
        phone: json['Phone'] == null ? undefined : json['Phone'],
        reservationsPhone: json['ReservationsPhone'] == null ? undefined : json['ReservationsPhone'],
    };
}

export function SiteToJSON(value?: Site | null): any {
    if (value == null) {
        return value;
    }
    return {
        GeoLocation: GeoLocationToJSON(value['geoLocation']),
        DomesticPickUp: value['domesticPickUp'],
        InternationalPickUp: value['internationalPickUp'],
        DirectionsLink: value['directionsLink'],
        Address1: SiteAddress1ToJSON(value['address1']),
        Id: value['id'],
        Name: value['name'],
        SiteCode: value['siteCode'],
        TimeZone: value['timeZone'],
        Country: value['country'],
        CountryCode: value['countryCode'],
        MinAges: value['minAges'],
        SiteSettings: value['siteSettings'] == null ? undefined : (value['siteSettings'] as Array<any>).map(SiteSiteSettingsToJSON),
        Holidays: value['holidays'] == null ? undefined : (value['holidays'] as Array<any>).map(SiteHolidaysToJSON),
        DefaultTimes: SiteDefaultTimesToJSON(value['defaultTimes']),
        BusinessUnit: value['businessUnit'],
        Phone: value['phone'],
        ReservationsPhone: value['reservationsPhone'],
    };
}
