'use client';
import React from 'react';
import { formatCurrency } from '@jucy-ui/common';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CurrencyComponents } from '../../../../CurrencyComponents';
import { FleetCategoryTeaserCardOwnerState } from '../types';

export interface FleetCategoryTeaserCardPriceProps {
    ownerState: FleetCategoryTeaserCardOwnerState;
}
export const FleetCategoryTeaserCardPrice = React.forwardRef<HTMLDivElement, FleetCategoryTeaserCardPriceProps>(
    ({ ownerState: { deal, fleetCategory } }, ref) => (
        <Box ref={ref} className="price" sx={{ textAlign: 'center' }}>
            {deal.originalDailyRate && fleetCategory.dailyRate.value < deal.originalDailyRate && (
                <Typography
                    data-cy="original-daily-rate"
                    variant="caption"
                    sx={{
                        color: 'text.secondary',
                        fontSize: 'small',
                        fontWeight: '700',
                        textDecoration: 'line-through',
                    }}
                    component="div"
                >
                    {fleetCategory.dailyRate.currencyCode}
                    {formatCurrency({ ...fleetCategory.dailyRate, value: deal.originalDailyRate })}
                    /day
                </Typography>
            )}
            <CurrencyComponents
                data-cy="daily-rate"
                component="div"
                showCurrencyCode={true}
                value={fleetCategory.dailyRate}
                unit="day"
                sx={{
                    fontSize: 'large',
                    fontWeight: '700',
                }}
            />
            <Typography
                variant="caption"
                component="div"
                sx={{
                    fontSize: 'smaller',
                    lineHeight: 1.5,
                    textTransform: 'none',
                }}
            >
                {formatCurrency(fleetCategory.total)} total
            </Typography>
        </Box>
    )
);
FleetCategoryTeaserCardPrice.displayName = 'FleetCategoryTeaserCardPrice';
