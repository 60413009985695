import React, { HTMLAttributes, useCallback, useState } from 'react';
import { CurrencyValue } from '@jucy/rentals-api-client/rentals-api-v3';
import { Link, LinkProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Image from 'next/image';
import * as Laybuy from '../../../lib/Laybuy';
import Money from '../../../lib/api/model/Money';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import WhatsThisModal from './WhatsThisModal';

interface PayWithLaybuyProps extends HTMLAttributes<HTMLDivElement> {
    amount: Money | CurrencyValue;
    showWhatsThis: boolean;
    showWhatsThisProps?: LinkProps;
}

const useStyles = makeStyles(() => ({
    logo: {
        width: 94,
        marginLeft: 10,
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    whatsThisLink: {
        marginLeft: 20,
        display: 'inline-block',
    },
}));
const PayWithLaybuy: React.FC<PayWithLaybuyProps> = ({ amount, className, showWhatsThis, showWhatsThisProps, ...props }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const toggleModel = useCallback(
        (event: React.SyntheticEvent) => {
            if (event?.preventDefault) {
                event.preventDefault();
            }
            setModalOpen(!isModalOpen);
        },
        [isModalOpen]
    );

    const classes = useStyles();
    const { className: whatsThisClassname, ...whatsThisProps } = showWhatsThisProps || {};
    return (
        <>
            <div className={clsx('laybuy', className)} {...props}>
                or 6 weekly interest-free payments from{' '}
                <strong>
                    <CurrencyFormatter price={Laybuy.weeklyPayments(amount)} />
                </strong>{' '}
                with <Image src={Laybuy.logo.src} alt="Laybuy" className={classes.logo} width={94} height={21} />
                {showWhatsThis ? (
                    <Link
                        href="packages/app/src/components/PaymentProviders/Laybuy#"
                        underline="hover"
                        className={clsx(classes.whatsThisLink, whatsThisClassname)}
                        onClick={toggleModel}
                        {...whatsThisProps}
                    >
                        what&apos;s this?
                    </Link>
                ) : null}
            </div>
            {showWhatsThis ? <WhatsThisModal open={isModalOpen} onClose={toggleModel} /> : null}
        </>
    );
};

export default PayWithLaybuy;
