'use client';
import React from 'react';
import { lighten, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';

export interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
    spinnerStyle: SpinnerStyle;
    color?: SvgIconProps['color'] | string;
    borderColor?: SvgIconProps['color'] | 'auto' | string;
}

export const Spinner = styled(Box, {
    shouldForwardProp: (prop) => !['spinnerStyle', 'inColor', 'outColor'].includes(prop as string),
    name: 'Spinner',
    slot: 'Root',
    overridesResolver: (props, styles) => [styles.root, props.color === 'primary' && styles.primary, props.color === 'secondary' && styles.secondary],
})<SpinnerProps>(({ theme, color = 'primary', borderColor = 'auto', spinnerStyle }) => {
    const findColor = (color: SpinnerProps['color']) =>
        (theme.palette[color as keyof typeof theme.palette] as Partial<SimplePaletteColorOptions>).main ??
        (
            {
                action: theme.palette.action.active,
                disabled: theme.palette.action.disabled,
                inherit: undefined,
            } as unknown as SvgIconProps['color']
        )?.[color as keyof SvgIconProps['color']];
    const resolvedColor = findColor(color) || color;
    const resolvedBorderColor = borderColor === 'auto' ? lighten(resolvedColor, 0.34) : findColor(borderColor) || borderColor;
    const shadowMap: Partial<Record<SpinnerStyle, string>> = {
        'wave-out': `-1.5625px -1.5625px 0 1.5625px ${resolvedColor}`,
        'wave-in': `inset -1.5625px -1.5625px 0 1.5625px ${resolvedColor}`,
        'double-wave-out': `-1.5625px -1.5625px 0 1.5625px ${resolvedColor}, 1.5625px 1.5625px 0 1.5625px ${resolvedColor}`,
        'double-wave-in': `inset -1.5625px -1.5625px 0 1.5625px ${resolvedColor}, inset 1.5625px 1.5625px 0 1.5625px ${resolvedColor}`,
        'huge-wave-out': `-1.5625px -1.5625px 0 6.25px ${resolvedColor}`,
        'huge-wave-in': `inset -1.5625px -1.5625px 0 6.25px ${resolvedColor}`,
    };
    return {
        position: 'relative',
        display: 'inline-block',
        width: '50px',
        height: '50px',
        border: `3.125px solid ${resolvedBorderColor}`,
        borderRadius: '50%',
        animation: 'spinner 1.2s linear infinite',
        boxShadow: shadowMap[spinnerStyle],
        ...(spinnerStyle.includes('dot')
            ? {
                  [`&:before ${spinnerStyle.includes('double') ? ', &:after' : ''}`]: {
                      position: 'absolute',
                      top: '6.25px',
                      left: '21.875px',
                      width: '6.25px',
                      height: '6.25px',
                      content: '""',
                      background: resolvedColor,
                      borderRadius: '50%',
                  },
                  ...(['double-dot-in', 'double-dot-stick'].includes(spinnerStyle)
                      ? {
                            '&:after': {
                                top: 'auto',
                                bottom: `${spinnerStyle.includes('stick') ? '-' : ''}6.25px`,
                            },
                        }
                      : undefined),
                  ...(['double-dot-stick', 'dot-stick'].includes(spinnerStyle)
                      ? {
                            '&:before': {
                                top: '-6.25px',
                            },
                        }
                      : undefined),
                  ...(['double-dot-out', 'dot-out'].includes(spinnerStyle)
                      ? {
                            '&:after': {
                                top: 'auto',
                                bottom: '-12.5px',
                            },
                            '&:before': {
                                top: '-12.5px',
                            },
                        }
                      : undefined),
              }
            : undefined),
        ...(spinnerStyle.includes('section')
            ? {
                  [`&:before ${spinnerStyle.includes('double') ? ', &:after' : ''}`]: {
                      position: 'absolute',
                      display: 'block',
                      content: '""',
                      border: '3.125px solid transparent',
                      borderRadius: '50%',
                      borderTopColor: resolvedColor,
                      ...(['double-section-far', 'section-far'].includes(spinnerStyle)
                          ? {
                                top: '-9.375px',
                                left: '-9.375px',
                                width: '62.5px',
                                height: '62.5px',
                            }
                          : {
                                top: '-3.125px',
                                left: '-3.125px',
                                width: '50px',
                                height: '50px',
                            }),
                  },
                  '&:after': {
                      borderTopColor: 'transparent',
                      borderBottomColor: resolvedColor,
                  },
                  ...(spinnerStyle.includes('in')
                      ? {
                            '&:before, &:after': {
                                top: '0',
                                left: '0',
                                width: '43.75px',
                                height: '43.75px',
                            },
                        }
                      : undefined),
                  ...(spinnerStyle.includes('out')
                      ? {
                            '&:before, &:after': {
                                top: '-6.25px',
                                left: '-6.25px',
                                width: '56.25px',
                                height: '56.25px',
                            },
                        }
                      : undefined),
              }
            : undefined),
        '@keyframes spinner': {
            '0%': {
                transform: 'rotate3d(0)',
            },
            '100%': {
                transform: 'rotate3d(0, 0, 1, 360deg)',
            },
        },
    };
});
export const SpinnerStyles = [
    'dot-in',
    'double-dot-in',
    'dot-stick',
    'double-dot-stick',
    'dot-out',
    'double-dot-out',
    'wave-in',
    'double-wave-in',
    'wave-out',
    'double-wave-out',
    'huge-wave-in',
    'huge-wave-out',
    'section-in',
    'double-section-in',
    'section',
    'double-section',
    'section-out',
    'double-section-out',
    'section-far',
    'double-section-far',
] as const;
export type SpinnerStyle =
    | 'dot-in'
    | 'double-dot-in'
    | 'dot-stick'
    | 'double-dot-stick'
    | 'dot-out'
    | 'double-dot-out'
    | 'wave-in'
    | 'double-wave-in'
    | 'wave-out'
    | 'double-wave-out'
    | 'huge-wave-in'
    | 'huge-wave-out'
    | 'section-in'
    | 'double-section-in'
    | 'section'
    | 'double-section'
    | 'section-out'
    | 'double-section-out'
    | 'section-far'
    | 'double-section-far';
