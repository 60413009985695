import { InsuranceProductGroup as V3InsuranceProductGroup, SecondaryProductGroup as V3SecondaryProductGroup } from '@jucy/rentals-api-client/rentals-api-v3';
import { ObeProductLine } from '../../../types/ObeProductLine';
import InsuranceProduct from './InsuranceProduct';
import Product from './Product';

export default class ProductGroup<T = Product> {
    Group = '';
    WebCategory = '';
    Required = false;
    AllowMultiple = false;
    Description = '';
    Items: T[] = [];

    constructor(props?: Partial<ProductGroup<T>>) {
        Object.assign(this, props);
    }

    static fromV3InsuranceProductGroup<P extends Product>(
        v3InsuranceProduct: V3SecondaryProductGroup,
        itemMapper: <P extends Product>(item: ObeProductLine) => P = Product.fromV3Product
    ): ProductGroup<P> {
        return new ProductGroup<P>({
            Group: v3InsuranceProduct.group,
            WebCategory: v3InsuranceProduct.webCategory,
            Required: v3InsuranceProduct.required,
            AllowMultiple: v3InsuranceProduct.allowMultiple,
            Description: v3InsuranceProduct.description,
            Items: (v3InsuranceProduct.items as ObeProductLine[]).map(itemMapper) as P[],
        });
    }

    asV3ProductGroup(): V3SecondaryProductGroup {
        return {
            group: this.Group,
            webCategory: this.WebCategory,
            required: this.Required,
            allowMultiple: this.AllowMultiple,
            description: this.Description,
            items: this.Items.map((item) => (item as unknown as Product).asV3SecondaryProduct()),
        };
    }

    asV3InsuranceGroup(): V3InsuranceProductGroup {
        return {
            group: this.Group,
            webCategory: this.WebCategory,
            required: this.Required,
            allowMultiple: this.AllowMultiple,
            description: this.Description,
            items: this.Items.map((item) => (item as unknown as InsuranceProduct).asV3InsuranceProduct()),
        };
    }
}
