'use client';
import { radioClasses, styled } from '@mui/material';
import Button, { type ButtonProps } from '@mui/material/Button';

export const JucyRadioControlLabelButton = styled(Button, {
    name: 'JucyRadioControlLabelButton',
    slot: 'root',
})<ButtonProps & { ownerState?: { checked?: boolean; error?: boolean } }>(({ theme, ownerState }) => {
    const borderWidth = ownerState?.checked ? '2px' : '1px';
    const borderColor = ownerState?.error
        ? theme.palette.error.main
        : ownerState?.checked
          ? theme.palette.primary.main
          : theme.settings.general.outlinedInputBorderColor;
    const borderHoverColor = ownerState?.error
        ? 'error.main'
        : ownerState?.checked
          ? 'primary.main'
          : theme.settings.general.outlinedInputBorderColorHover;
    return {
        color: 'inherit',
        backgroundColor: theme.palette.common.white,
        padding: ownerState?.checked ? 0 : '1px',
        margin: 0,
        overflow: 'hidden',
        borderWidth,
        borderColor,
        '&:hover': {
            borderWidth,
            borderColor: borderHoverColor,
        },
        [`&.${radioClasses.disabled}`]: {
            borderWidth,
        },
    };
});
