import { observer } from 'mobx-react-lite';
import React from 'react';
import { getPaymentInfoDisplayLabel } from '#/lib/getPaymentInfoDisplayLabel';
import { format } from 'date-fns';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';
import BookingSummaryPriceRow from './BookingSummaryPriceRow';
import { getPaymentUniqueKey } from '#/lib/getPaymentUniqueKey';

const PaymentSummary: React.FC = observer(() => {
    const context = useBookingSummaryContext();
    const payments = context.summary.payments?.length ? context.summary.payments : context.summary.amendments?.payments;

    if (!payments?.length) {
        return null;
    }

    return (
        <BookingSummaryGroupItem title={'Payments'} className="animate__animated animate__fadeIn">
            {payments?.map((payment) => (
                <BookingSummaryPriceRow title={getPaymentInfoDisplayLabel(payment)} subtitle={format(payment.date, 'dd MMM yyyy')} price={payment.total} key={getPaymentUniqueKey(payment)} />
            ))}
        </BookingSummaryGroupItem>
    );
});
PaymentSummary.displayName = 'PaymentSummary';
export default PaymentSummary;
