import PageLoader from '#/components/LoadingAnimation/PageLoader';
import config from '#/config';
import { useGetIdToken } from '#/hooks/getUserIdToken';
import { useAccountInfo } from '#/hooks/useAccountInfo';
import { useOperatorInfo } from '#/hooks/useOperatorInfo';
import { useRouteMode } from '#/hooks/useRouteMode';
import { msalInstance } from '#/layouts/ClientOnlyObeLayout/lib/init';
import ErrorReporter from '#/lib/ErrorReporter';
import { initSalesMonitoring } from '#/lib/SalesMonitoring';
import accountKeyStore from '#/store/AccountKeyStore';
import { useBrand } from '#/store/BrandStore';
import routeRegistry from '#/store/RouteRegistry';
import { AffiliateUser, AgentUser, isAffiliate, isAgent, OBEUser, OperatorUser } from '#/types/OBEUser';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { MsalProvider } from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';
import { autorun } from 'mobx';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

export class JucyAuthError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'JucyAuthError';
    }
}
export const loginFlowPaths = ['/auth0', '/login', '/logout', '/staff/login'];

export interface JucyAuthState {
    user: OBEUser;
    isLoading: boolean;
}

const defaultState = {
    user: {
        email: '',
        type: 'direct',
        isAuthenticated: false,
    } satisfies OBEUser,
    isLoading: true,
};
export const JucyAuthContext = React.createContext<JucyAuthState>(defaultState);
let loginTimoutId: number | undefined;

export const JucyAuthLoader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { authState, isLoginFlow } = useGetAuthState();

    if (authState.isLoading && !isLoginFlow) {
        return <PageLoader id="auth" />;
    }
    if (!authState.user.isAuthenticated || authState.user.type == 'direct') {
        initSalesMonitoring();
    }
    return <JucyAuthContext.Provider value={authState}>{children}</JucyAuthContext.Provider>;
};

const useGetAuthState = () => {
    const { isLoading: isAuthUserLoading, user: authUser } = useGetAuthUser();
    const { isLoading: isOperatorLoading, user: operatorUser } = useOperatorUser();
    const [authState, setAuthState] = useState<JucyAuthState>(() => resolveUserState({ authUser, operatorUser }));
    const [isLoginRedirecting, setIsLoginRedirecting] = useState(false);
    const isLoading = isOperatorLoading || isAuthUserLoading;
    const location = useLocation();
    const navigate = useNavigate();
    const isLoginFlow = isLoginRedirecting || loginFlowPaths.some((p) => location.pathname.startsWith(p));
    const mode = useRouteMode();
    const loginRequired = !isLoginFlow && !isLoading && !authState.user.isAuthenticated && (mode === 'agent' || mode === 'affiliate');
    useEffect(() => {
        const sessionUser = getSessionUser();
        if (sessionUser) {
            if (sessionUser?.email !== authState.user.email) {
                setAuthState({ user: sessionUser, isLoading: false });
            }
            return;
        }

        if (loginTimoutId) {
            clearTimeout(loginTimoutId);
            loginTimoutId = undefined;
        }
        if (authState.isLoading) {
            loginTimoutId = window.setTimeout(() => {
                if (authState.isLoading) {
                    ErrorReporter.captureError(new Error('Login timeout waiting for providers'));
                    setAuthState({ ...defaultState, isLoading: false });
                }
            }, 10000);
        }

        const resolvedUser = resolveUserState({ authUser, operatorUser });
        if (resolvedUser.user.type === 'staff') {
            if (operatorUser?.email !== authState.user.email || operatorUser?.agentUser?.id !== (authState.user as OperatorUser).agentUser?.id) {
                setAuthState(resolvedUser);
            }
            return;
        }
        if (resolvedUser.user.type === 'agent' && isAgent(authUser)) {
            if (authUser?.id !== (authState.user as AgentUser).id) {
                setAuthState(resolvedUser);
            }
            return;
        }
        if (resolvedUser.user.type === 'affiliate' && isAffiliate(authUser)) {
            if (authUser?.referrerId !== (authState.user as AffiliateUser)?.referrerId) {
                setAuthState(resolvedUser);
            }
            return;
        }

        if (loginRequired) {
            setIsLoginRedirecting(true);
            setAuthState({ ...defaultState, isLoading: false });
            const params = new URLSearchParams();
            const returnTo = `${location.pathname.replace(/^\/obe/, '')}${location.search}${location.hash}`;
            params.set('returnTo', returnTo);
            const to = `/login?${params.toString()}`;
            navigate(to);
            return;
        }
        if (!isLoading && (authState.user !== defaultState.user || authState.isLoading)) {
            setAuthState({ ...defaultState, isLoading: false });
        }

        return () => {
            if (loginTimoutId) {
                clearTimeout(loginTimoutId);
                loginTimoutId = undefined;
            }
        };
    }, [authUser, authState.isLoading, authState.user, isLoading, isLoginFlow, location.hash, location.pathname, location.search, loginRequired, mode, navigate, operatorUser]);

    return useMemo(() => ({ authState, isLoginFlow }), [authState, isLoginFlow]);
};

export const JucyAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { brand } = useBrand();
    const auth0RedirectUrl = new URL(routeRegistry.absoluteUrlFromPath(config.auth0RedirectPath));
    auth0RedirectUrl.searchParams.set('brand', brand);
    const navigate = useNavigate();
    return (
        <MsalProvider instance={msalInstance}>
            <Auth0Provider
                leeway={600}
                domain={config.authDomain}
                clientId={config.clientId}
                authorizationParams={{
                    redirect_uri: auth0RedirectUrl.href,
                }}
                onRedirectCallback={(appState) => {
                    navigate(appState?.returnTo || '/');
                }}
            >
                <JucyAuthLoader>{children}</JucyAuthLoader>
            </Auth0Provider>
        </MsalProvider>
    );
};

const resolveUserState = ({ authUser, operatorUser }: { authUser?: AgentUser | AffiliateUser; operatorUser?: OperatorUser }): JucyAuthState => {
    if (operatorUser) {
        return {
            user: {
                ...operatorUser,
                agentUser: isAgent(authUser) ? authUser : operatorUser.agentUser,
            },
            isLoading: false,
        };
    }

    if (authUser) {
        return {
            user: authUser,
            isLoading: false,
        };
    }
    return defaultState;
};

type UseGetAuthUserResult = { user: AgentUser | AffiliateUser | undefined; isLoading: boolean };
const useGetAuthUser = (): UseGetAuthUserResult => {
    const { isLoading: isIdTokenLoading, data: idToken } = useGetIdToken();
    const { isLoading: isAuth0Loading, user: auth0User, isAuthenticated } = useAuth0();
    const { isLoading: isAccountInfoLoading, accountInfo } = useAccountInfo(auth0User?.[`${config.claimsNamespace}/accountKey`]);
    const [result, setResult] = useState<UseGetAuthUserResult>(() => ({ user: undefined, isLoading: true }));
    const isLoading = isAuth0Loading || isAccountInfoLoading || isIdTokenLoading;

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const accountKey = auth0User?.[`${config.claimsNamespace}/accountKey`];
        const referrerId = auth0User?.[`${config.claimsNamespace}/referrerId`] || auth0User?.['http://test.jucy.cloud/claims/referrerId'];
        const referrerCode = auth0User?.[`${config.claimsNamespace}/referrerCode`] || auth0User?.['http://test.jucy.cloud/claims/referrerCode'];

        const isObeUser = Boolean(accountKey || referrerId);
        if (isAuthenticated && !isObeUser) {
            throw new JucyAuthError(
                `No account key associated with your login ${auth0User ? `${auth0User.email} (${auth0User.sub}). Please contact your account manager for assistance` : ''}`
            );
        }

        if (isAuthenticated && accountInfo && auth0User && accountKey) {
            if (accountInfo.id !== (result.user as AgentUser)?.id) {
                datadogRum.setUser({
                    email: auth0User.email || '',
                    type: 'agent',
                    accountKey: accountKey,
                });
                setResult({
                    user: {
                        ...auth0User,
                        ...accountInfo,
                        email: auth0User.email || '',
                        type: 'agent',
                        accountKey: accountKey,
                        isAuthenticated: true,
                    },
                    isLoading: false,
                });
            }
            return;
        }
        if (isAuthenticated && referrerId) {
            if (referrerId !== (result.user as AffiliateUser)?.referrerId || idToken?.__raw !== (result.user as AffiliateUser)?.idToken) {
                datadogRum.setUser({
                    email: auth0User.email || '',
                    type: 'affiliate',
                    referrerId,
                    referrerCode,
                });
                setResult({
                    user: {
                        ...auth0User,
                        email: auth0User.email || '',
                        type: 'affiliate',
                        referrerId,
                        referrerCode,
                        isAuthenticated: true,
                        idToken: idToken?.__raw,
                    },
                    isLoading: false,
                });
            }
            return;
        }

        if (!isLoading && (result.user || result.isLoading)) {
            setResult({ user: undefined, isLoading: false });
        }
    }, [accountInfo, auth0User, isAuthenticated, isLoading, result, result.isLoading, result.user, idToken]);

    return result;
};

type OperatorUserAuthResult = { user: OperatorUser | undefined; isLoading: boolean };

export const useOperatorUser = (): OperatorUserAuthResult => {
    const { isLoading: isOperatorLoading, operatorInfo } = useOperatorInfo();
    const [impersonatedAccount, setImpersonatedAccount] = useState<AgentUser | undefined>(undefined);
    const [result, setResult] = useState<OperatorUserAuthResult>(() => ({ user: undefined, isLoading: true }));
    const isLoading = isOperatorLoading;

    useEffect(() => {
        const dispose = autorun(() => {
            if (accountKeyStore.impersonatedAccount?.id !== impersonatedAccount?.id) {
                setImpersonatedAccount(accountKeyStore.impersonatedAccount);
            }
        });
        return () => dispose();
    }, [impersonatedAccount?.id]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (operatorInfo) {
            if (operatorInfo?.email !== result.user?.email || impersonatedAccount?.id !== result.user?.agentUser?.id) {
                datadogRum.setUser({
                    email: operatorInfo.email || '',
                    type: 'staff',
                });
                setResult({
                    user: {
                        ...operatorInfo,
                        type: 'staff',
                        agentUser: impersonatedAccount,
                    } as OperatorUser,
                    isLoading: false,
                });
            }
            return;
        }

        if (!isLoading && (result.user || result.isLoading)) {
            setResult({ user: undefined, isLoading: false });
        }
    }, [impersonatedAccount, isLoading, operatorInfo, result.isLoading, result.user]);
    return result;
};

// only used by cypress
const isClient = typeof window !== 'undefined';
const getSessionUser = () => {
    const sessionUserData = isClient && window.sessionStorage.getItem('user');
    if (sessionUserData) {
        return JSON.parse(sessionUserData) as AgentUser;
    }
    return undefined;
};
