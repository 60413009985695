/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { PricingResponse } from '../models/index';
import { PricingResponseFromJSON, PricingResponseToJSON } from '../models/index';
import * as runtime from '../runtime';

export interface QueryPricingRequest {
    productId: string;
}

/**
 *
 */
export class PricingApi extends runtime.BaseAPI {
    /**
     * Query dynamic pricing for products. Different products may require different query parameters depending how they calculate their pricing.
     * Query product pricing
     */
    async queryPricingRaw(
        requestParameters: QueryPricingRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<PricingResponse>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError('productId', 'Required parameter "productId" was null or undefined when calling queryPricing().');
        }

        const queryParameters: any = {};

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/pricing`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => PricingResponseFromJSON(jsonValue));
    }

    /**
     * Query dynamic pricing for products. Different products may require different query parameters depending how they calculate their pricing.
     * Query product pricing
     */
    async queryPricing(productId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PricingResponse> {
        const response = await this.queryPricingRaw({ productId: productId }, initOverrides);
        return await response.value();
    }
}
