import { isValidDate } from '@jucy-ui/common';
import { isAfter, isBefore } from 'date-fns';
import { getServiceHours } from '../lib';
import { SearchFormData } from '../types';

export const validatePickUpTime = ({ pickUpBranch, pickUpTime, fleetType }: Partial<SearchFormData>): string | undefined => {
    if (!isValidDate(pickUpTime)) {
        return 'Please select valid pick up time';
    }

    const siteSettings = pickUpBranch && pickUpBranch.siteSettings.find((siteSetting) => siteSetting.fleetTypeId === fleetType?.id);
    const serviceHours = siteSettings && getServiceHours({ date: pickUpTime, siteSettings });
    if (
        !siteSettings?.isAfterHoursPickUpAvailable &&
        serviceHours &&
        (isBefore(pickUpTime, serviceHours.open) || isAfter(pickUpTime, serviceHours.close))
    ) {
        return 'Pick up branch is closed at this time and after hours pick up is not available';
    }
};
