import { DevSupport } from '@react-buddy/ide-toolbox-next';
import { enableStaticRendering } from 'mobx-react-lite';
import Script from 'next/script';
import type { ReactElement } from 'react';
import { useInitial } from '#/components/dev';
import ComponentPreviews from '#/components/dev/previews';
import { SimplePaletteColorOptions } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import type { NextPage } from 'next';
import { AppContext } from 'next/dist/pages/_app';
import { AppInitialProps, AppPropsType, NextComponentType } from 'next/dist/shared/lib/utils';
import Head from 'next/head';
import { NextRouter } from 'next/router';
import 'tiny-slider/dist/tiny-slider.css';
import { AppProviders } from '../components/providers';
import { Clarity, GoogleOptimize, GTMScript } from '../components/tags';
import { useTryFindBrand } from '../hooks/useTryFindBrand';
import { Brand, BrandPageProps } from '../lib/brand';
import { poppins, poppinsRegular, poppinsBold } from '../styles/fonts';
import '../styles/scss/common.scss';
import '../styles/scss/image-gallery.scss';
import '../styles/scss/main.scss';
import config from '#/config';
import '@preact/signals-react/auto';

const isServer = typeof window === 'undefined';
globalThis.jucyConfig = {
    rentalsApiBaseUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}`.replace(/\/$/, ''),
};
LicenseInfo.setLicenseKey('2a88fde8251bd3fbf1e9329d1d7d67e6Tz04NjI0OSxFPTE3NDE4MzA4NDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
enableStaticRendering(isServer);

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactElement;
};

export interface MyAppProps<Router extends NextRouter = NextRouter> extends AppPropsType<Router, BrandPageProps> {
    Component: NextPageWithLayout;
    brand?: Brand;
}

export const MyApp: NextComponentType<AppContext, AppInitialProps, MyAppProps> = ({ Component, pageProps }) => {
    const brand = useTryFindBrand(pageProps.brand);
    const getLayout = Component.getLayout ?? ((page) => page);
    return getLayout(
        <AppProviders brand={brand}>
            {(brandStore) => (
                <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
                    <>
                        <Head>
                            <title>{`${brandStore.brand?.toUpperCase()} Rentals`}</title>
                            <link rel="apple-touch-icon" href={`/assets/manifest/${brandStore.brand}/apple-touch-icon.png`} />
                            <link rel="icon" href={`/assets/manifest/${brandStore.brand}/favicon.svg`} type="image/svg+xml" />
                            <link rel="manifest" href="/api/manifest" />
                            <link rel="shortcut icon" href={`/assets/manifest/${brandStore.brand}/favicon.ico`} />
                            <meta name="msapplication-TileColor" content={(brandStore.theme.palette.primary as SimplePaletteColorOptions).main} />
                            <meta name="msapplication-config" content="/api/browserconfig" />
                            <meta name="theme-color" content={(brandStore.theme.palette.primary as SimplePaletteColorOptions).main} />
                            <meta name="viewport" content="initial-scale=1, width=device-width" />
                            <style>
                                {`
                                    :root {
                                        --font-poppins: ${poppins.style.fontFamily};
                                        --font-poppins-regular: ${poppinsRegular.style.fontFamily};
                                        --font-poppins-bold: ${poppinsBold.style.fontFamily};
                                        --bs-font-sans-serif: var(--font-poppins);
                                        --bs-body-font-family: var(--font-poppins);
                                    }
                                `}
                            </style>
                        </Head>
                        <Script type="module" src="https://unpkg.com/@social-embed/wc?module" />
                        <GoogleOptimize />
                        <GTMScript config={brand === 'jucy' ? config?.gtm?.jucy : config?.gtm?.star} />
                        <Clarity />
                        <Component {...pageProps} brand={brandStore.brand} />
                    </>
                </DevSupport>
            )}
        </AppProviders>
    );
};
export default MyApp;
