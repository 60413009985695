import config from '#/config';
import { useGetAmendmentEmail } from '#/hooks/useGetAmendmentEmail';
import SimpleTemplateParser from '#/lib/SimpleTemplateParser';
import Quote from '#/lib/api/model/Quote';
import appState from '#/store/AppStateStore';
import { BookingCart } from '#/types/BookingCart';

const isQuote = (booking: Quote | BookingCart): booking is Quote => (booking as Quote).QuoteId !== undefined;
const isSummary = (booking: Quote | BookingCart): booking is BookingCart => (booking as BookingCart).reservationReference !== undefined;

export const useEditReservationHref = (booking: Quote | BookingCart): string => {
    const countryCode = isQuote(booking) ? booking.PickUpLocationCode : isSummary(booking) ? booking.pickUpLocation?.CountryCode : undefined;
    const amendmentsEmail = useGetAmendmentEmail();
    if (!amendmentsEmail || appState.mode === 'direct') {
        return appState.brand === 'jucy' ? `https://www.jucy.com/${countryCode}/about-us/help-and-contact/` : 'https://www.starrv.com/help-contact';
    }
    const reservationRef = isQuote(booking) ? booking.ReservationReference : isSummary(booking) ? booking.reservationReference : undefined;
    const subject = SimpleTemplateParser(config.editBookingSubjectTemplate || 'Request changes for booking ref #$ReservationReference', {
        ReservationReference: reservationRef || '',
    });
    return `mailto:${amendmentsEmail}?subject=${subject}`;
};
