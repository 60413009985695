import generateUtilityClass from '@mui/utils/generateUtilityClass';
import generateUtilityClasses from '@mui/utils/generateUtilityClasses';

export interface FleetCategoryTeaserCardClasses {
    root: string;
    content: string;
    grid: string;
    descriptionCol: string;
    descriptionPrimaryText: string;
    descriptionSecondaryText: string;
    detailsCol: string;
    actionsCol: string;
}

export type FleetCategoryTeaserCardClassKey = keyof FleetCategoryTeaserCardClasses;

export const getFleetCategoryTeaserCardClass = (slot: string): string => generateUtilityClass('JucyFleetCategoryTeaserCard', slot);

export const fleetCategoryTeaserClasses: FleetCategoryTeaserCardClasses = generateUtilityClasses('JucyFleetCategoryTeaserCard', [
    'root',
    'content',
    'grid',
    'descriptionCol',
    'descriptionPrimaryText',
    'descriptionSecondaryText',
    'detailsCol',
    'actionsCol',
]);
