'use client';

import React from 'react';
import { cn } from '@jucy-ui/common';
import { SlotComponentProps, useSlotProps } from '@mui/base';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ComponentsOverrides, ComponentsVariants, useThemeProps } from '@mui/material/styles';
import { Theme as MuiTheme } from '@mui/material/styles/createTheme';
import { fleetCategoryTeaserClasses } from '../lib';
import { FleetCategoryTeaserCardOwnerState } from '../types';
import { FleetCategoryTeaserCarousel } from './FleetCategoryTeaserCarousel';

export interface FleetCategoryTeaserCardDescriptionSlots {
    root?: React.ElementType;
    carousel?: React.ElementType;
    detailsPrimaryText?: React.ElementType;
    secondaryText?: React.ElementType;
    footer?: React.ElementType;
}

const FleetCategoryTeaserCardDescriptionRoot = styled('div', {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'description',
})<{ ownerState?: FleetCategoryTeaserCardOwnerState }>(() => ({
    maxWidth: '100%',
    display: 'flex',
    flex: '1 1',
    flexDirection: 'column',
    textAlign: 'center',
}));
const FleetCategoryTeaserCardDescriptionPrimaryText = styled(Typography, {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'primaryText',
})<{ ownerState: FleetCategoryTeaserCardOwnerState }>(({ theme }) => ({
    ...theme.typography.h4,
}));
const FleetCategoryTeaserCardDescriptionSecondaryText = styled(Typography, {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'secondaryText',
})<{ ownerState: FleetCategoryTeaserCardOwnerState }>(({ theme }) => ({
    ...theme.typography.subtitle2,
}));
const FleetCategoryTeaserCardDescriptionFooter = styled(Typography, {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'footer',
})<{ ownerState?: FleetCategoryTeaserCardOwnerState }>(() => ({}));

interface FleetCategoryTeaserCardDescriptionProps {
    ownerState: FleetCategoryTeaserCardOwnerState;
    slots?: FleetCategoryTeaserCardDescriptionSlots;
    className?: string;
    slotProps?: {
        root?: SlotComponentProps<typeof FleetCategoryTeaserCardDescriptionRoot, object, FleetCategoryTeaserCardOwnerState>;
        carousel?: SlotComponentProps<typeof FleetCategoryTeaserCarousel, object, FleetCategoryTeaserCardOwnerState>;
        detailsPrimaryText?: SlotComponentProps<typeof FleetCategoryTeaserCardDescriptionPrimaryText, object, FleetCategoryTeaserCardOwnerState>;
        detailsSecondaryText?: SlotComponentProps<typeof FleetCategoryTeaserCardDescriptionSecondaryText, object, FleetCategoryTeaserCardOwnerState>;
        footer?: SlotComponentProps<'div', object, FleetCategoryTeaserCardOwnerState>;
    };
}

export const FleetCategoryTeaserCardDescription = React.forwardRef<HTMLDivElement, FleetCategoryTeaserCardDescriptionProps>((inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: 'FleetCategoryTeaserCardDescription' });
    const { slotProps, className, ownerState, ...other } = props;
    const RootSlot = props.slots?.root || FleetCategoryTeaserCardDescriptionRoot;
    const CarouselSlot = props.slots?.carousel || FleetCategoryTeaserCarousel;
    const PrimaryTextSlot = props.slots?.detailsPrimaryText || FleetCategoryTeaserCardDescriptionPrimaryText;
    const SecondaryTextSlot = props.slots?.secondaryText || FleetCategoryTeaserCardDescriptionSecondaryText;
    const FooterSlot = props.slots?.footer;
    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            ref,
        },
        ownerState,
        className: className,
    });
    const carouselProps = useSlotProps({
        elementType: FleetCategoryTeaserCarousel,
        externalSlotProps: slotProps?.carousel,
        ownerState,
    });
    const primaryTextProps = useSlotProps({
        elementType: FleetCategoryTeaserCardDescriptionPrimaryText,
        externalSlotProps: slotProps?.detailsPrimaryText,
        ownerState,
    });
    const secondaryTextProps = useSlotProps({
        elementType: FleetCategoryTeaserCardDescriptionSecondaryText,
        externalSlotProps: slotProps?.detailsSecondaryText,
        ownerState,
    });
    const footerTextProps = useSlotProps({
        elementType: FleetCategoryTeaserCardDescriptionFooter,
        externalSlotProps: slotProps?.footer,
        ownerState,
    });
    const { catalogItem, fleetCategory } = ownerState;

    return (
        <RootSlot {...rootProps}>
            <CarouselSlot {...carouselProps} />
            <PrimaryTextSlot {...primaryTextProps} className={cn(fleetCategoryTeaserClasses.descriptionPrimaryText, primaryTextProps.className)}>
                {catalogItem.name || fleetCategory.name}
            </PrimaryTextSlot>
            <SecondaryTextSlot
                {...secondaryTextProps}
                className={cn(fleetCategoryTeaserClasses.descriptionSecondaryText, secondaryTextProps.className)}
            >
                {catalogItem.vehicleDescription}
            </SecondaryTextSlot>
            {FooterSlot ? <FooterSlot {...footerTextProps} /> : null}
        </RootSlot>
    );
});
FleetCategoryTeaserCardDescription.displayName = 'FleetCategoryTeaserCardDescription';

declare module '@mui/material/styles' {
    interface ComponentNameToClassKey {
        FleetCategoryTeaserCardDescription: 'root' | 'details' | 'actions';
    }

    interface ComponentsPropsList {
        FleetCategoryTeaserCardDescription: Partial<FleetCategoryTeaserCardDescriptionProps>;
    }

    interface Components {
        FleetCategoryTeaserCardDescription?: {
            defaultProps?: ComponentsPropsList['FleetCategoryTeaserCardDescription'];
            styleOverrides?: ComponentsOverrides<Omit<MuiTheme, 'components'>>['FleetCategoryTeaserCardDescription'];
            variants?: ComponentsVariants['FleetCategoryTeaserCardDescription'];
        };
    }
}
