import React from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxProps, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import appStateStore from '../../../store/AppStateStore';
import RouteRegistry from '../../../store/RouteRegistry';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import { mergeSx } from '#/lib/mergeSx';

interface BookingSummaryGroupItemProps extends Omit<BoxProps, 'title'> {
    isDirty?: boolean;
    isEditable?: boolean;
    editRouteParams?: Record<string, string | undefined | null>;
    title?: string | null;
    titleSmall?: boolean;
}

const BookingSummaryGroupItem: React.FC<BookingSummaryGroupItemProps> = ({ isDirty = false, isEditable = false, editRouteParams, title, children, titleSmall, className, sx, ...props }) => {
    const context = useBookingSummaryContext();
    const editLink =
        isEditable && context.isEditable && context.editRouteId && context.summary.reservationReference
            ? RouteRegistry.getPath(context.editRouteId, {
                  fleetType: context.summary.fleetType?.slug,
                  reservationReference: context.summary.reservationReference,
                  fleetTypeOrResRef: context.summary.reservationReference,
                  action: 'edit',
                  ...editRouteParams,
              })
            : null;

    return (
        <Box {...props} sx={mergeSx({ pt: 1.5, pb: 1 }, sx)} className={classNames(className, { edited: isDirty })}>
            {(title || editLink) && (
                <>
                    <Grid className="group-title" container spacing={0}>
                        <Grid item xs={8}>
                            <Typography variant={titleSmall ? 'subtitle2' : 'subtitle1'} component="span">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} display="flex" justifyContent="right">
                            {editLink && (
                                <Link to={editLink} color="link" className="edit-btn p-0 d-inline fw-bold small" onClick={appStateStore.orderSummaryFooter.toggleSummary}>
                                    Edit
                                    <i className="fas fa-edit ms-1" aria-hidden="true" />
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
            {children}
        </Box>
    );
};

export default BookingSummaryGroupItem;
