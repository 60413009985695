import { SearchFormValues } from '@jucy-ui/components/SearchForm';
import { UseFormReturn } from '@jucy-ui/forms';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { useSearchParams, useUpdateRouteParams } from '../../../hooks';
import AvailabilityStore from '../../../store/AvailabilityStore';
import FleetTypesStore from '../../../store/FleetTypesStore';
import RentalTripSearchStore from '../../../store/RentalTripSearchStore';
import ReservationStore from '../../../store/ReservationStore';
import { JucyErrorAlert } from '../../Alert/JucyErrorAlert';
import { SearchForm, validateSearchValues } from './SearchForm';
import { PartialSearchFormValues } from './SearchFormValues';
import { useHandleFleetTypeChanged } from './useHandleFleetTypeChanged';

export interface AgentTripSearchFormProps {
    className?: string;
}

const AgentTripSearchForm: React.FC<AgentTripSearchFormProps> = observer(() => {
    const updateRouteParams = useUpdateRouteParams();
    const [searchParams] = useSearchParams();
    const routeParams = useParams();
    const onSubmit = useCallback(
        async (values: PartialSearchFormValues, form: UseFormReturn<SearchFormValues>) => {
            if (validateSearchValues(values)) {
                RentalTripSearchStore.setValues(values);
                await RentalTripSearchStore.performSearch().then((response) => {
                    const tripSearchParams = createSearchParams({
                        ...searchParams,
                        ...(RentalTripSearchStore.asQueryString ? Object.fromEntries(createSearchParams(RentalTripSearchStore.asQueryString)) : undefined),
                    } as Record<string, string>);
                    if (tripSearchParams.has('action')) {
                        tripSearchParams.delete('action');
                    }
                    if (response?.Data) {
                        updateRouteParams(
                            {
                                activeTab: 'fleet-category',
                            },
                            { keepSearch: true, search: tripSearchParams }
                        );
                    } else {
                        form.setError('root.submit-error', { message: response?.Message || 'Search request failed' });
                    }
                });
            }
        },
        [searchParams, updateRouteParams]
    );

    const [initialValues] = useState(() =>
        RentalTripSearchStore.getInitialValues({
            defaults: {
                driverAge: '21',
                fleetType: FleetTypesStore.getActiveFleetType() || FleetTypesStore.getFleetTypeBySlug(routeParams.fleetType) || undefined,
            },
            queryString: searchParams as Record<string, string>,
            reservation: ReservationStore.activeQuote,
        })
    );
    const handleFleetTypeChanged = useHandleFleetTypeChanged();

    return (
        <>
            <SearchForm initialValues={initialValues} onSubmit={onSubmit} onFleetTypeChanged={handleFleetTypeChanged} className="booking-widget large pt-3" />
            {AvailabilityStore.state === 'error' ? <JucyErrorAlert title="Search error" message={AvailabilityStore.message} className="animate__animated animate__fadeIn mt-3" /> : null}
            {ReservationStore.state === 'error' ? (
                <JucyErrorAlert title="Error loading reservation" message={ReservationStore.message} className="animate__animated animate__fadeIn mt-3" />
            ) : null}
        </>
    );
});
AgentTripSearchForm.displayName = 'AgentTripSearchForm';

export default AgentTripSearchForm;
