'use client';
import React, { createContext } from 'react';
import { CountryData } from '@jucy-ui/common';

export type PhoneNumberInputState = {
    selectedCountry: CountryData | null;
    setSelectedCountry: (countryCode: CountryData | null) => void;
};
export const PhoneNumberInputContext = createContext<PhoneNumberInputState>({ selectedCountry: null, setSelectedCountry: () => {} });
export const PhoneNumberInputProvider: React.FC<{ children: React.ReactNode } & PhoneNumberInputState> = ({
    children,
    selectedCountry,
    setSelectedCountry,
}) => <PhoneNumberInputContext.Provider value={{ selectedCountry, setSelectedCountry }}>{children}</PhoneNumberInputContext.Provider>;
