import { Deal, FleetCategory, formatCurrency } from '@jucy-ui/common';
import { FleetCategoryTeaserCardDealSummary } from '../types';

export const mapDeal = ({
    fleetCategory,
    action,
    deal,
    reverseTotal,
}: {
    fleetCategory: FleetCategory;
    action?: 'create' | 'edit';
    deal?: Deal;
    reverseTotal?: number;
}): FleetCategoryTeaserCardDealSummary => {
    const currencyCode = fleetCategory.total?.currencyCode;
    const reverseDiff = 0;
    const dealText = `${fleetCategory.dealText || fleetCategory.campaignCode || ''}`.trim();
    const hasDealText = Boolean(dealText);
    const dailyRate = fleetCategory.dailyRate?.value;
    const discountPercentage = fleetCategory.discountPercentageApplied;
    const originalDailyRate = dailyRate && discountPercentage ? dailyRate / (1 - discountPercentage) : dailyRate;
    const hasDiscountedDailyRate = Boolean(fleetCategory.dailyRate && fleetCategory.dailyRate.value < originalDailyRate);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const hasCheaperReverseTrip = reverseDiff < 0;
    const text: string[] = [];
    const discountAmount = {
        value: originalDailyRate - dailyRate,
        currencyCode,
    };
    const isRelocation = deal?.type === 'vehicle-relocation';
    if (dealText) {
        text.push(dealText);
    }
    if (!isRelocation && discountPercentage) {
        text.push(`${discountPercentage * 100}% discount applied - Save ${currencyCode}${formatCurrency(discountAmount)}`);
    } else if (!discountPercentage && discountAmount.value) {
        text.push(`${currencyCode}${formatCurrency(discountAmount)} discount applied`);
    }
    const hasDeal = !(
        fleetCategory.availability === 'StopSell' ||
        !(hasDealText || hasDiscountedDailyRate || hasCheaperReverseTrip) ||
        (fleetCategory.availability !== 'OnRequest' && action === 'edit')
    );
    return {
        originalDailyRate: originalDailyRate,
        discountAmount: discountAmount.value,
        type: deal?.type,
        enabled: hasDeal,
        title: text.join(' - '),
        hasCheaperReverseTrip: Boolean(reverseTotal),
        reverseTotal: 0,
    };
};
