import { FleetTypeSlug } from '@jucy/rentals-common';
import config from '../config';
import { FleetType } from '../services';
import appState from '../store/AppStateStore';

export const isFleetTypeEnabled = (fleetType: FleetType): boolean => {
    if (!fleetType?.slug) {
        return false;
    }
    if (!config.enableMotorHomes && fleetType.slug === FleetTypeSlug.motorhome) {
        return false;
    }
    if (appState.brand === 'star' && fleetType.slug !== FleetTypeSlug.motorhome) {
        return false;
    }
    return Boolean(fleetType.thumbnail);
};
