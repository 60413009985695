import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Site } from '../../../lib/api/model/Site';
import TranslationStore from '../../../store/TranslationStore';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';

export interface TripInfoSideProps extends BoxProps {
    title: string;
    location: Site;
    date: Date;
    dataCyPrefix?: string;
}

const TripInfoSide: React.FC<TripInfoSideProps> = ({ title, location, date, dataCyPrefix, ...props }) => (
    <Box {...props}>
        <Typography variant="subtitle2" fontSize="12px">
            {title}
        </Typography>
        <Typography data-cy={`${dataCyPrefix}-date`} lineHeight={1} my={1} variant="subtitle1" fontSize="18px" textTransform="none" fontWeight={700}>
            {format(date, 'dd MMM yyyy')}
        </Typography>
        <Typography data-cy={`${dataCyPrefix}-location`} variant="body2" component="div">
            {location.Name}
        </Typography>
        <Typography data-cy={`${dataCyPrefix}-time`} variant="body2" component="div">
            {format(date, 'h:mm aaa')}
        </Typography>
    </Box>
);

const TripInfoSummary: React.FC = observer(() => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const context = useBookingSummaryContext();
    const title = context.summary.bookingStatus ? 'Search results' : t('trip_summary.group.trip_info');
    const pickUpLocation = context.summary.amendments?.pickUpLocation || context.summary.pickUpLocation;
    const dropOffLocation = context.summary.amendments?.dropOffLocation || context.summary.dropOffLocation;
    const pickUpDate = context.summary.amendments?.pickUpDate || context.summary.pickUpDate;
    const dropOffDate = context.summary.amendments?.dropOffDate || context.summary.dropOffDate;
    return pickUpLocation && dropOffLocation && pickUpDate && dropOffDate ? (
        <BookingSummaryGroupItem
            className="trip-info animate__animated animate__fadeIn smaller"
            title={title}
            isEditable={true}
            editRouteParams={{
                activeTab: context.summary.userMode === 'agent' ? 'search' : '',
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <TripInfoSide dataCyPrefix="pick-up" title={t('trip_summary.group.pick_up')} location={pickUpLocation} date={pickUpDate} />
                <Box display="flex" alignItems="center" justifyContent="center">
                    <ArrowRightIcon />
                </Box>
                <TripInfoSide dataCyPrefix="drop-off" title={t('trip_summary.group.drop_off')} location={dropOffLocation} date={dropOffDate} />
            </Stack>
        </BookingSummaryGroupItem>
    ) : null;
});
TripInfoSummary.displayName = 'TripInfoSummary';
export default TripInfoSummary;
