'use client';
import React from 'react';
import Box from '@mui/material/Box';

interface CardFooterProps {
    className?: string;
    plain?: boolean;
    children?: React.ReactNode;
}

export const CardFooter = (props: CardFooterProps) => {
    const { className, children, plain, ...rest } = props;
    return (
        <Box
            sx={{
                padding: '0',
                paddingTop: '10px',
                margin: '0 15px 10px',
                borderRadius: '0',
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex',
                backgroundColor: 'transparent',
                border: '0',
                ...(plain
                    ? {
                          paddingLeft: '5px',
                          paddingRight: '5px',
                          backgroundColor: 'transparent',
                      }
                    : undefined),
            }}
            className={className}
            {...rest}
        >
            {children}
        </Box>
    );
};
