import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useUpdateRouteParams } from '../../../hooks';
import fleetTypesStore from '../../../store/FleetTypesStore';
import { SearchFormContainerProps } from './SearchFormContainerProps';

export const useHandleFleetTypeChanged = (): Exclude<SearchFormContainerProps['onFleetTypeChanged'], undefined> => {
    const routeParams = useParams();
    const updateRouteParams = useUpdateRouteParams();
    return useCallback<Exclude<SearchFormContainerProps['onFleetTypeChanged'], undefined>>(
        (fleetType) => {
            if (fleetType?.slug) {
                fleetTypesStore.setActiveFleetTypeSlug(fleetType.slug);
                if (routeParams.fleetType && routeParams.fleetType !== fleetType.slug) {
                    updateRouteParams(
                        {
                            ...routeParams,
                            fleetType: fleetType.slug,
                        },
                        { keepSearch: true }
                    );
                }
            }
        },
        [routeParams, updateRouteParams]
    );
};
