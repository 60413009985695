import { FleetType as ApiFleetType, GetAllFleetTypesResponseLocationsAvailableAt } from '@jucy/rentals-api-client/rentals-api';
import { fleetTypes } from '@jucy/rentals-common';
import ExternalId from '@jucy/rentals-common/data/ExternalId';
import { FleetTypeMap, FleetTypeSlug } from '@jucy/rentals-common/data/fleetTypes';
import { plainToInstance } from 'class-transformer';
import { PartialDeep } from 'type-fest';
import camperThumbnail from '../../styles/images/fleet-category-campervan.webp';
import carThumbnail from '../../styles/images/fleet-category-car.webp';
import motorHomeThumbnail from '../../styles/images/fleet-category-motorhome.webp';

type ObeFleetType = ApiFleetType & FleetTypeMap;

export class FleetType implements ObeFleetType {
    static staticData = [
        {
            id: '7d3dece7-ab86-e611-80e8-c4346bc5977c',
            slug: 'campervan',
            name: 'Campervan',
            thumbnail: camperThumbnail,
            sortOrder: 1,
            youngestDriver: 18,
        },
        {
            id: '9972e1db-ab86-e611-80e8-c4346bc5977c',
            slug: 'car',
            name: 'Car',
            thumbnail: carThumbnail,
            sortOrder: 2,
            youngestDriver: 18,
        },
        {
            id: '7f6a1e49-d686-4e0b-8302-d58d26606420',
            slug: 'motorhome',
            name: 'Motorhome',
            thumbnail: motorHomeThumbnail,
            sortOrder: 3,
            youngestDriver: 21,
        },
    ];
    id: string;
    name: string;
    locationsAvailableAt?: Array<GetAllFleetTypesResponseLocationsAvailableAt>;
    minHireDays = 0;
    slug = '' as FleetTypeSlug;
    thumbnail = { src: '', alt: '' };
    sortOrder = 0;
    depositPercent = 0;
    leadTimeHours = 0;
    defaultMinimumLeadTimeHours = 0;
    rcmId: ExternalId;
    youngestDriver = 18;

    constructor(props?: Partial<FleetType>) {
        Object.assign(this, {
            ...FleetType.staticData.find((s) => s.id === props?.id),
            ...fleetTypes.find((ft) => ft.id === props?.id),
            ...props,
        });
    }

    static fromPlain(props: PartialDeep<FleetType>): FleetType {
        return plainToInstance(FleetType, {
            ...FleetType.staticData.find((s) => s.id === props?.id),
            ...fleetTypes.find((ft) => ft.id === props?.id),
            ...props,
        });
    }
}
