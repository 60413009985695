'use client';
import { useContext } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { FormSchemaContext } from '../FormSchemaProvider';

export const useFieldMeta = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(props: {
    name: TName;
}) => {
    const schema = useContext(FormSchemaContext);
    if (!schema) {
        throw new Error('useFieldMeta must be used within a FormSchemaContext');
    }
    return schema.formMeta[props.name];
};
