import config from '../config';
import appStateStore from '../store/AppStateStore';
import LaybuyLogo from '../styles/images/Laybuy-Logo-Black-grape.svg';
import { BookingCart } from '../types/BookingCart';

export const enabled = (summary: BookingCart): boolean => {
    if (!config.enableLaybuy) {
        return false;
    }
    if (appStateStore.mode !== 'direct') {
        return false;
    }
    if (appStateStore.brand !== 'jucy') {
        return false;
    }

    if (summary.fleetCategory?.minimumHirePeriod || summary.fleetCategory?.availability !== 'FreeSell') {
        return false;
    }
    return true;
};

export const weeklyPayments = (amount: { currencyCode?: string; CurrencyCode?: string; value?: number; Value?: number }): { currencyCode: string; value: number } => {
    const value = amount.value || amount.Value;
    return {
        value: value ? parseFloat((Math.ceil((value / 6) * 20) / 20).toFixed(2)) : 0,
        currencyCode: amount.currencyCode || amount.CurrencyCode || 'NZD',
    };
};

export const logo = LaybuyLogo;
