import { useSearchFormData } from './useSearchFormData';
import { useSearchFormFieldValue } from './useSearchFormFieldValue';

export const useGetDriverAges = (): string[] => {
    const pickUpBranchCode = useSearchFormFieldValue('pickUpBranch');
    const fleetTypeSlug = useSearchFormFieldValue('fleetType');
    const { getSite, getFleetType } = useSearchFormData();
    const pickUpBranch = getSite(pickUpBranchCode);
    const fleetType = getFleetType(fleetTypeSlug);
    if (fleetType?.minimumDriverAge && pickUpBranch?.minAges.length) {
        return pickUpBranch.minAges.filter((age) => parseInt(age) >= fleetType.minimumDriverAge);
    }
    if (fleetType?.minimumDriverAge && !pickUpBranch?.minAges.length) {
        return [`${fleetType.minimumDriverAge}`];
    }

    return pickUpBranch?.minAges || [];
};
