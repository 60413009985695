import React from 'react';
import { RentalExcessReduction } from '@jucy/rentals-api-client';
import clsx from 'clsx';
import { getExcessReductionCover, getExcessReductionName } from '../../ExcessMapper';

interface TitleProps {
    item: Required<RentalExcessReduction>;
    className?: string;
}

const Title: React.FC<TitleProps> = ({ item, className }) => {
    const name = getExcessReductionName(item);
    const cover = getExcessReductionCover(item);
    return (
        <div className={clsx(className)}>
            <h3 className="fw-bold h6 mb-0">{name}</h3>
            <div className="small text-muted fw-bold">{cover}</div>
        </div>
    );
};
export default Title;
