'use client';

import React from 'react';
import { FleetType, cn } from '@jucy-ui/common';
import { RadioProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Radio from '@mui/material/Radio';
import { useFleetTypeRadioGroupContext } from '../lib';


export interface FleetTypeRadioControlProps extends RadioProps {
    fleetType: FleetType;
}

export const FleetTypeRadioControl = React.forwardRef<HTMLButtonElement,FleetTypeRadioControlProps>(({ fleetType, className, ...props }, ref) => {
    const { direction } = useFleetTypeRadioGroupContext();
    return (
        <>
            <Avatar
                variant="square"
                alt={fleetType.name}
                src={fleetType.thumbnail}
                className="fleet-type-thumb"
                sx={{
                    opacity: props.disabled ? 0.5 : undefined,
                    order: 1,
                    backgroundColor: 'none',
                    width: 'auto',
                    height: 'auto',
                    px: 1,
                }}
            />
            <Radio className={cn('fleet-type-radio', className)} disableRipple={true} sx={{ order: 3, ml: 'auto' }} ref={ref} {...props} />
        </>
    );
});
FleetTypeRadioControl.displayName = 'FleetTypeRadioControl';
