'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { cn } from '@jucy-ui/common/lib/cn';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';


export const JucySliderNavTrackContainer = React.forwardRef<HTMLDivElement, BoxProps>(({ children, className, sx, ...props }, ref) => (
        <Box
                ref={ref}
                className={cn('track-nav-container', className)}
                sx={mergeSx((theme) => ({
                    position: 'relative',
                    transition: theme.transitions.create('height', {
                        duration: theme.transitions.duration.shortest,
                    }),
                }),sx)}
                {...props}
        >
        {children}
    </Box>
));
JucySliderNavTrackContainer.displayName = 'JucySliderNavTrackContainer';
