import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect } from 'react';
import { FormControl, FormControlProps, InputLabel, MenuItem, Select } from '@mui/material';
import { currencyStore } from '../../store/CurrencyStore';

export const CurrencySelector: React.FC<FormControlProps> = observer(({ size = 'small', ...props }) => {
    useEffect(() => {
        if (currencyStore.state === 'idle') {
            currencyStore.initialize();
        }
    }, []);
    return (
        <FormControl size={size} {...props}>
            <InputLabel id="currency-selector-label">Currency</InputLabel>
            <Select
                data-cy="currency-selector"
                labelId="currency-selector-label"
                id="currency-selector"
                value={currencyStore.state === 'loaded' ? currencyStore.selectedCurrencyCode : ''}
                label="Currency"
                onChange={(v) => {
                    currencyStore.selectedCurrencyCode = v.target.value;
                }}
            >
                {currencyStore.labels.map((label) => (
                    <MenuItem value={label} key={label}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});
CurrencySelector.displayName = 'CurrencySelector';
