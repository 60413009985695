'use client';
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DateRangePickerToolbar, DateRangePickerToolbarProps } from '@mui/x-date-pickers-pro';

export const SearchFormDateToolBar = (props: DateRangePickerToolbarProps<Date>) => (
    <Box className={props.className} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h5" gutterBottom component="div" sx={{ textAlign: 'center', pt: 1 }}>
            Select your
            {props.rangePosition === 'start' && ' pick up '}
            {props.rangePosition === 'end' && ' drop off '}
            date
        </Typography>
        <DateRangePickerToolbar {...props} />
    </Box>
);
