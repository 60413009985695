'use client';
import React from 'react';
import { PaperProps, PopperPlacementType } from '@mui/material';
import Paper from '@mui/material/Paper';
import type { PickerPopperProps, PickersModalDialogSlotProps } from '@mui/x-date-pickers/internals';
import { SearchFormDatePickerFooter } from './SearchFormDatePickerFooter';

interface PickersPopperOwnerState extends PickerPopperProps {
    placement: PopperPlacementType;
}

type DesktopPaperProps = PickersModalDialogSlotProps['mobilePaper'] & PaperProps;
export const SearchFormDatePickerPaperMobile = React.forwardRef<HTMLDivElement, DesktopPaperProps>(({ children, ...props }, ref) => (
    <Paper ref={ref} {...(props as DesktopPaperProps & { ownerState: PickersPopperOwnerState })}>
        {children}
        <SearchFormDatePickerFooter sx={{ inlineSize: '312px' }} />
    </Paper>
));
SearchFormDatePickerPaperMobile.displayName = 'SearchFormDatePickerPaperMobile';
