import { HirePeriodSettings } from '@jucy/rentals-api-client/rentals-api';
import { plainToInstance } from 'class-transformer';
import { isWithinInterval } from 'date-fns';
import { isValidDate } from '../../lib/dates';
import { FleetType } from './FleetType';
import { Site } from './Site';

export class HirePeriod implements HirePeriodSettings {
    fleetTypeSlug = '';
    pickupSiteCode = '';
    dropOffSiteCode = '';
    startDate = new Date('');
    endDate = new Date('');
    minHireDays = 0;
    allowLastDayDropOffs?: boolean;

    constructor(props: Partial<HirePeriod>) {
        Object.assign(this, props);
    }

    static fromPlain(props: Partial<HirePeriod>): HirePeriod {
        return plainToInstance(HirePeriod, props);
    }

    matches({ date, fleetType, pickUpSite, dropOffSite }: { date?: Date | null; fleetType?: FleetType | null; pickUpSite?: Site | null; dropOffSite?: Site | null }) {
        if (fleetType && isValidDate(date)) {
            const fleetTypeValid = this.fleetTypeSlug === fleetType.slug;
            const pickUpValid = !this.pickupSiteCode || this.pickupSiteCode === pickUpSite?.siteCode;
            const dropOffValid = !this.dropOffSiteCode || this.dropOffSiteCode === dropOffSite?.siteCode;
            const dateValid = isWithinInterval(date, { start: this.startDate, end: this.endDate });
            return fleetTypeValid && pickUpValid && dropOffValid && dateValid;
        }
        return false;
    }
}
