import { useCallback } from 'react';
import { RangePosition } from '@mui/x-date-pickers-pro';
import { getSiteSettings, shouldDisableTime } from '../lib';
import { useSearchFormData } from './useSearchFormData';
import { useSearchFormFieldValue } from './useSearchFormFieldValue';

export const useShouldDisableTime = (position: RangePosition) => {
    const branchCode = useSearchFormFieldValue(position === 'start' ? 'pickUpBranch' : 'dropOffBranch');
    const fleetTypeSlug = useSearchFormFieldValue('fleetType');
    const { getFleetType, getSite } = useSearchFormData();
    const branch = getSite(branchCode);
    const fleetType = getFleetType(fleetTypeSlug);
    return useCallback(
        (time: Date) => {
            const siteSettings = branch && fleetType && getSiteSettings({ branch, fleetType });
            if (!siteSettings) {
                return false;
            }
            return shouldDisableTime({ time, siteSettings });
        },
        [fleetType, branch]
    );
};
