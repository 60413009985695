export const asError = (thrown: unknown): Error => {
    if (thrown instanceof Error) {
        return thrown;
    }
    try {
        return new Error(JSON.stringify(thrown));
    } catch {
        return new Error(String(thrown));
    }
};
