import { isValidDate, mergeDateTime } from '@jucy-ui/common';
import { getUnixTime } from 'date-fns';
import { PartialDeep } from 'type-fest';
import { Maybe } from 'yup';
import { SearchFormValues } from '../types';

export const searchUidFromFormValues = (values?: Maybe<PartialDeep<SearchFormValues>>): string => {
    const pickUpDate = mergeDateTime(values?.pickUpDate, values?.pickUpTime);
    const dropOffDate = mergeDateTime(values?.dropOffDate, values?.dropOffTime);
    return [
        values?.pickUpBranch,
        pickUpDate && isValidDate(pickUpDate) ? getUnixTime(pickUpDate) : undefined,
        values?.dropOffBranch,
        dropOffDate && isValidDate(dropOffDate) ? getUnixTime(dropOffDate) : undefined,
        values?.fleetType,
        values?.driverAge,
        values?.promoCode,
        values?.fleetCategory,
    ]
        .map((v) => (v ? `${v}` : ''))
        .join('|');
};
