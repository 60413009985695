/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { FleetCategoryDailyRate } from './FleetCategoryDailyRate';
import { FleetCategoryDailyRateFromJSON, FleetCategoryDailyRateFromJSONTyped, FleetCategoryDailyRateToJSON } from './FleetCategoryDailyRate';
import type { FleetCategoryMandatoryFees } from './FleetCategoryMandatoryFees';
import {
    FleetCategoryMandatoryFeesFromJSON,
    FleetCategoryMandatoryFeesFromJSONTyped,
    FleetCategoryMandatoryFeesToJSON,
} from './FleetCategoryMandatoryFees';
import type { FleetCategoryTotal } from './FleetCategoryTotal';
import { FleetCategoryTotalFromJSON, FleetCategoryTotalFromJSONTyped, FleetCategoryTotalToJSON } from './FleetCategoryTotal';

/**
 *
 * @export
 * @interface FleetCategory
 */
export interface FleetCategory {
    /**
     * Price difference between todays price and price at time of
     * reservation
     * @type {string}
     * @memberof FleetCategory
     */
    priceDifference?: string;
    /**
     * Fleet category display name
     * @type {string}
     * @memberof FleetCategory
     */
    name: string;
    /**
     * Fleet category long description. May contain HTML
     * @type {string}
     * @memberof FleetCategory
     */
    description: string;
    /**
     * Fleet category ACRISS code. See https://www.acriss.org/car-codes/
     * @type {string}
     * @memberof FleetCategory
     */
    categoryCode: string;
    /**
     * Currently applied campaign code. If any
     * @type {string}
     * @memberof FleetCategory
     */
    campaignCode?: string;
    /**
     * ID for currently applied campaign code. If any
     * @type {string}
     * @memberof FleetCategory
     */
    campaignId?: string;
    /**
     * Promotional text
     * @type {string}
     * @memberof FleetCategory
     */
    dealText?: string;
    /**
     * Number of free days applied if any
     * @type {number}
     * @memberof FleetCategory
     */
    freeDays?: number;
    /**
     * Fleet category unique identifier
     * @type {string}
     * @memberof FleetCategory
     */
    id: string;
    /**
     * ID for fleet Category type (See [GetAllFleetTypes](/swagger/ui/index/#/Availability/GetAllFleetTypes))
     * @type {string}
     * @memberof FleetCategory
     */
    type: string;
    /**
     * Slug for fleet Category type (See [GetAllFleetTypes](/swagger/ui/index/#/Availability/GetAllFleetTypes))
     * @type {string}
     * @memberof FleetCategory
     */
    typeSlug: string;
    /**
     * Availability for given locations and dates<ul><li>FreeSell
     * - Fleet category is available for hire</li><li>OnRequest - Demand for
     * fleet category is high and may be available for hire, but requires confirmation</li><li>StopSell
     * - Fleet category is not currently available</li></ul>
     * @type {string}
     * @memberof FleetCategory
     */
    availability: FleetCategoryAvailabilityEnum;
    /**
     *
     * @type {FleetCategoryDailyRate}
     * @memberof FleetCategory
     */
    dailyRate: FleetCategoryDailyRate;
    /**
     *
     * @type {FleetCategoryTotal}
     * @memberof FleetCategory
     */
    total: FleetCategoryTotal;
    /**
     * Decimal percentage of discount applied to rates
     * @type {number}
     * @memberof FleetCategory
     */
    discountPercentageApplied?: number;
    /**
     * List of mandatory fees, if any, associated with fleet category,
     * locations and dates
     * @type {Array<FleetCategoryMandatoryFees>}
     * @memberof FleetCategory
     */
    mandatoryFees?: Array<FleetCategoryMandatoryFees>;
    /**
     * Number of days fleet category price is calculated for
     * @type {number}
     * @memberof FleetCategory
     */
    rentalDays: number;
    /**
     * Indicates minimum hire period applies to this fleet category
     * @type {string}
     * @memberof FleetCategory
     */
    minimumHirePeriod?: string;
    /**
     * Indicates if pick up time is out of normal operating hours
     * for givin date and pick up location
     * @type {boolean}
     * @memberof FleetCategory
     */
    isAfterHourPickUp?: boolean;
    /**
     *
     * @type {number}
     * @memberof FleetCategory
     */
    agentCommissionRate?: number;
    /**
     *
     * @type {number}
     * @memberof FleetCategory
     */
    agentPaymentType?: number;
}

/**
 * @export
 */
export const FleetCategoryAvailabilityEnum = {
    FreeSell: 'FreeSell',
    OnRequest: 'OnRequest',
    StopSell: 'StopSell',
} as const;
export type FleetCategoryAvailabilityEnum = (typeof FleetCategoryAvailabilityEnum)[keyof typeof FleetCategoryAvailabilityEnum];

/**
 * Check if a given object implements the FleetCategory interface.
 */
export function instanceOfFleetCategory(value: object): value is FleetCategory {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('categoryCode' in value) || value['categoryCode'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('typeSlug' in value) || value['typeSlug'] === undefined) return false;
    if (!('availability' in value) || value['availability'] === undefined) return false;
    if (!('dailyRate' in value) || value['dailyRate'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('rentalDays' in value) || value['rentalDays'] === undefined) return false;
    return true;
}

export function FleetCategoryFromJSON(json: any): FleetCategory {
    return FleetCategoryFromJSONTyped(json, false);
}

export function FleetCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetCategory {
    if (json == null) {
        return json;
    }
    return {
        priceDifference: json['PriceDifference'] == null ? undefined : json['PriceDifference'],
        name: json['Name'],
        description: json['Description'],
        categoryCode: json['CategoryCode'],
        campaignCode: json['CampaignCode'] == null ? undefined : json['CampaignCode'],
        campaignId: json['CampaignId'] == null ? undefined : json['CampaignId'],
        dealText: json['DealText'] == null ? undefined : json['DealText'],
        freeDays: json['FreeDays'] == null ? undefined : json['FreeDays'],
        id: json['Id'],
        type: json['Type'],
        typeSlug: json['TypeSlug'],
        availability: json['Availability'],
        dailyRate: FleetCategoryDailyRateFromJSON(json['DailyRate']),
        total: FleetCategoryTotalFromJSON(json['Total']),
        discountPercentageApplied: json['DiscountPercentageApplied'] == null ? undefined : json['DiscountPercentageApplied'],
        mandatoryFees: json['MandatoryFees'] == null ? undefined : (json['MandatoryFees'] as Array<any>).map(FleetCategoryMandatoryFeesFromJSON),
        rentalDays: json['RentalDays'],
        minimumHirePeriod: json['MinimumHirePeriod'] == null ? undefined : json['MinimumHirePeriod'],
        isAfterHourPickUp: json['IsAfterHourPickUp'] == null ? undefined : json['IsAfterHourPickUp'],
        agentCommissionRate: json['AgentCommissionRate'] == null ? undefined : json['AgentCommissionRate'],
        agentPaymentType: json['AgentPaymentType'] == null ? undefined : json['AgentPaymentType'],
    };
}

export function FleetCategoryToJSON(value?: FleetCategory | null): any {
    if (value == null) {
        return value;
    }
    return {
        PriceDifference: value['priceDifference'],
        Name: value['name'],
        Description: value['description'],
        CategoryCode: value['categoryCode'],
        CampaignCode: value['campaignCode'],
        CampaignId: value['campaignId'],
        DealText: value['dealText'],
        FreeDays: value['freeDays'],
        Id: value['id'],
        Type: value['type'],
        TypeSlug: value['typeSlug'],
        Availability: value['availability'],
        DailyRate: FleetCategoryDailyRateToJSON(value['dailyRate']),
        Total: FleetCategoryTotalToJSON(value['total']),
        DiscountPercentageApplied: value['discountPercentageApplied'],
        MandatoryFees: value['mandatoryFees'] == null ? undefined : (value['mandatoryFees'] as Array<any>).map(FleetCategoryMandatoryFeesToJSON),
        RentalDays: value['rentalDays'],
        MinimumHirePeriod: value['minimumHirePeriod'],
        IsAfterHourPickUp: value['isAfterHourPickUp'],
        AgentCommissionRate: value['agentCommissionRate'],
        AgentPaymentType: value['agentPaymentType'],
    };
}
