import React from 'react';

export const createSyntheticEvent = <T extends Element, E extends Event>(event: E, target?: T): React.SyntheticEvent<T, E> => {
    if (target) {
        Object.defineProperty(event, 'target', { writable: false, value: target });
    }

    let isDefaultPrevented = false;
    let isPropagationStopped = false;
    return {
        nativeEvent: event,
        currentTarget: event.currentTarget as EventTarget & T,
        target: event.target as EventTarget & T,
        bubbles: event.bubbles,
        cancelable: event.cancelable,
        defaultPrevented: event.defaultPrevented,
        eventPhase: event.eventPhase,
        isTrusted: event.isTrusted,
        preventDefault: () => {
            isDefaultPrevented = true;
            event.preventDefault();
        },
        isDefaultPrevented: () => isDefaultPrevented,
        stopPropagation: () => {
            isPropagationStopped = true;
            event.stopPropagation();
        },
        isPropagationStopped: () => isPropagationStopped,
        persist: () => {},
        timeStamp: event.timeStamp,
        type: event.type,
    };
};
