import { useMemo } from 'react';
import { useRouter } from 'next/router';

export const useIsCallback = () => {
    const router = useRouter();
    const { search } =
        typeof window !== 'undefined'
            ? window.location
            : {
                  search: router.asPath.replace(/.*\?/, ''),
              };
    return useMemo(() => search.includes('callback=true'), [search]);
};
