import { DialogContent, DialogTitle } from '@mui/material';
import Money from '../../../lib/api/model/Money';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import { PageTitle } from '../../Typograpgy/Title';
import { PaymentOption } from '../lib';
import GetPaymentMethodFn from './GetPaymentMethodFn';
import { CancellationTermsLink } from './CancellationTermsLink';

const getDepositPaymentOption: GetPaymentMethodFn = ({ summary, countryCode, options, paymentConfig }): PaymentOption => {
    const depositRate = summary.fleetType?.depositPercent || 1;
    const subTotal = new Money({
        value: summary.totalPrice?.value * depositRate,
        currencyCode: summary.totalPrice?.currencyCode,
    });
    const merchantFee = paymentConfig?.merchantFeeRate
        ? new Money({
              value: subTotal.value * paymentConfig?.merchantFeeRate,
              currencyCode: summary.totalPrice?.currencyCode,
          })
        : undefined;
    const total = new Money({
        value: merchantFee?.value ? subTotal.value + merchantFee?.value : subTotal.value,
        currencyCode: summary.totalPrice?.currencyCode,
    });

    const balanceRemaining = new Money({
        value: summary.totalPrice?.value - total.value + (merchantFee?.value || 0),
        currencyCode: summary.totalPrice?.currencyCode,
    });
    return {
        id: 'deposit',
        total: total,
        subTotal: total,
        balanceRemaining: balanceRemaining,
        merchantFee: merchantFee,
        name: `${depositRate * 100}% Deposit`,
        amount: <CurrencyFormatter price={total} prefixCurrencyCode={true} />,
        merchantFeeRate: paymentConfig?.merchantFeeRate || undefined,
        modalBody: (
            <>
                <DialogTitle component="div">
                    <PageTitle>{depositRate * 100}% Deposit</PageTitle>
                </DialogTitle>
                <DialogContent>
                    Pay <CurrencyFormatter price={total} prefixCurrencyCode={true} /> now and <CurrencyFormatter price={balanceRemaining} prefixCurrencyCode={true} /> on pickup.
                    <br />
                    <br />
                    <ul className="fa-ul ms-4">
                        <li>
                            <span className="fa-li">
                                <i className="fas fa-check text-green" />
                            </span>{' '}
                            Free cancellation 22 days prior to travel
                        </li>
                        <li>
                            <CancellationTermsLink countryCode={countryCode} />
                        </li>
                    </ul>
                    <small>* Pay on pick up excludes merchant fee charges that may apply if paying by credit card</small>
                </DialogContent>
            </>
        ),
        gateway: paymentConfig.defaultGateway,
        isRedirect: paymentConfig.defaultGateway === 'dps',
        available: ({ enableDepositPayment, summary, forcePayOnPickupOnly }) =>
            Boolean(summary.fleetType?.depositPercent && !summary.isOnRequest && !summary.isRelocationDeal && enableDepositPayment && !forcePayOnPickupOnly),
        ...options,
    };
};
export default getDepositPaymentOption;
