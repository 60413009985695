import businessUnitsStore from '../store/BusinessUnitsStore';
import { GSTCalculator } from './GSTCalculator';

export class BusinessUnitGSTCalculator {
    salesTaxPercentage: number;

    constructor(businessUnitId: string) {
        this.salesTaxPercentage = businessUnitsStore.getBusinessUnitById(businessUnitId)?.salesTaxPercentage as number;
    }

    static create(businessUnitId: string): BusinessUnitGSTCalculator {
        return new BusinessUnitGSTCalculator(businessUnitId);
    }

    addGST(net: number): number {
        return GSTCalculator.addGST(net, this.salesTaxPercentage);
    }

    removeGST(gross: number): number {
        return GSTCalculator.removeGST(gross, this.salesTaxPercentage);
    }

    getGSTFromNet(net: number): number {
        return GSTCalculator.getGSTFromNet(net, this.salesTaxPercentage);
    }

    getGSTFromGross(gross: number): number {
        return GSTCalculator.getGSTFromGross(gross, this.salesTaxPercentage);
    }
}
