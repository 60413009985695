import { countries } from '@jucy/rentals-common';
import md5 from 'crypto-js/md5';
import Cookies from 'universal-cookie';
import BusinessUnitsStore from '../../../store/BusinessUnitsStore';
import { UserMode } from '../../../types/UserMode';
import Quote from '../../api/model/Quote';
import { SalesMonitorProvider } from './SalesMonitorProvider';
import ErrorReporter from '#/lib/ErrorReporter';
import { asError } from '#/lib/asError';
import { getCommissionableTotal } from '#/lib/SalesMonitoring/getCommissionableTotal';

const cookies = new Cookies();

export class CommissionJunction implements SalesMonitorProvider {
    enterpriseId?: string;
    tagId?: string;
    cjApi?: CJApi;

    constructor(enterpriseId?: string, tagId?: string) {
        this.enterpriseId = enterpriseId;
        this.tagId = tagId;
    }

    static getEventQueryParamValue(): string | undefined {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams?.get('cjevent') || undefined;
    }

    static getEventValue(): string {
        return cookies.get<string>('cje');
    }

    static setEventValue(value: string): void {
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + 86400 * 395);
        cookies.set('cje', value, {
            domain: window.jucy.config.env === 'local' ? 'localhost' : '.jucy.com',
            expires: expires,
            path: '/',
            secure: window.jucy.config.env !== 'local',
            httpOnly: false,
            sameSite: false,
        });
    }

    init(): void {
        const cjEventParam = CommissionJunction.getEventQueryParamValue();
        const cjEventCookie = CommissionJunction.getEventValue();
        if (cjEventParam && cjEventParam !== cjEventCookie) {
            CommissionJunction.setEventValue(cjEventParam);
        }
        if (this.tagId && this.enterpriseId) {
            if (!window.cj) window.cj = {};
            ((_window: Window, doc: Document) => {
                const script = doc.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.id = 'cjapitag';
                script.src = `https://www.jucy.com/proxydirectory/tags/${this.tagId}/tag.js`;
                const sibling = doc.getElementsByTagName('script')[0];
                sibling?.parentNode?.insertBefore(script, sibling);
            })(window, document);

            const urlSearchParams = new URLSearchParams(window.location.search);
            const utmSource = urlSearchParams?.get('utm_source');

            if (utmSource) {
                if (utmSource?.toLowerCase() === 'commissionjunction') {
                    localStorage.setItem('cj_utm_source', utmSource);
                } else {
                    localStorage.removeItem('cj_utm_source');
                }
            }
        }
    }

    confirmPurchase(quote: Quote): void {
        if (window.cj) {
            try {
                window.cj.order = mapCJOrder(quote);
            } catch (e) {
                ErrorReporter.reportError({ error: asError(e), level: 'error', tags: { source: 'CommissionJunction', reservationReference: quote.ReservationReference } });
            }
        }
        if (window.cjApi?.sendOrder && window.cj?.order) {
            window.cjApi.sendOrder(window.cj.order);
        }
    }

    enabled(quote: Quote, accountType: UserMode): boolean {
        return Boolean(accountType === 'direct' && quote?.BookingStatus === 'Confirmed' && window.cjApi);
    }

    createQuoteQueryParams(accountType?: UserMode): Record<string, string> {
        const cjevent = CommissionJunction.getEventValue();
        if (accountType === 'direct' && cjevent) {
            return { cjevent, utm_source: 'commissionjunction' };
        }
        return {};
    }
}

interface CJOrder {
    enterpriseId: string;
    pageType?: string;
    userId?: string;
    emailHash?: string;
    orderId: string;
    actionTrackerId: string;
    currency: string;
    amount: number;
    pickupId: string;
    originCity: string;
    originCountry: string;
    dropoffId: string;
    destinationCity: string;
    destinationCountry: string;
    endDateTime: string;
    startDateTime: string;
    discount?: number;
    coupon?: string;
    cjeventOrder?: string;
    customerCountry?: string;
    customerStatus?: string;
    campaignId?: string;
    campaignName?: string;
    businessUnit?: string;
    category?: string;
    productDetail?: string;
    description?: string;
    paymentMethod?: string;
    items?: CJOrderItem[];
}

export interface CJOrderItem {
    unitPrice: number;
    itemId: string;
    quantity: number;
    discount?: number;
}

export const mapCJOrder = (quote: Quote): CJOrder => {
    if (!quote.PickUpSite || !quote.DropOffSite) {
        throw new Error('PickUpSite or DropOffSite is missing');
    }

    const customerCountry = quote.HirerDetails?.DriversLicenceCountry ? countries.find((c) => c.name === quote.HirerDetails?.DriversLicenceCountry) : null;
    const { total, discount } = getCommissionableTotal(quote);
    return {
        enterpriseId: '1564026',
        pageType: 'conversionConfirmation',
        emailHash: quote.HirerDetails?.Email ? md5(quote.HirerDetails.Email).toString() : undefined,
        orderId: quote.ReservationReference,
        actionTrackerId: '434760',
        currency: quote.TotalPrice.CurrencyCode,
        amount: total,
        pickupId: quote.PickUpLocationCode,
        originCity: quote.PickUpSite.Name || '',
        originCountry: quote.PickUpSite.CountryCode || '',
        dropoffId: quote.DropOffLocationCode,
        destinationCity: quote.DropOffSite.Name || '',
        destinationCountry: quote.DropOffSite.CountryCode || '',
        endDateTime: quote.DropOffDateDateTime.toISOString(),
        startDateTime: quote.PickUpDateTime.toISOString(),
        coupon: quote.FleetCategory.CampaignCode || '',
        cjeventOrder: CommissionJunction.getEventValue(),
        customerCountry: customerCountry?.code || undefined,
        businessUnit: BusinessUnitsStore.getByQuote(quote)?.name || '',
        category: quote.FleetCategory.fleetType?.slug || 'rentals',
        productDetail: quote.FleetCategory.CategoryCode,
        description: quote.FleetCategory.Name,
        items: [
            {
                unitPrice: total,
                itemId: quote.ReservationId,
                quantity: 1,
                discount: discount,
            },
        ],
    };
};

interface CJApi {
    sendOrder: (order: CJOrder) => void;
}

declare global {
    interface Window {
        cj?: { order?: CJOrder };
        cjApi: CJApi;
    }
}
