'use client';
import {Theme} from '@mui/material';
import {SxProps} from '@mui/system/styleFunctionSx';
import React from 'react';
import { CatalogItem } from '@jucy-ui/common';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { JucyMuiTabPanel } from '../../../../../Tabs';
import { FeatureIcon } from '../FeatureIcon';
import { FleetCategoryImageGallery } from '../FleetCategoryImageGallery';
import { getFleetCategoryDetailsDialogTabPanelProps } from './fleetCategoryDetailsDialogTabConsts';

export const DetailsTab: React.FC<{ activeTab: string; catalogItem: CatalogItem,hideImageGallery?:boolean,sx?: SxProps<Theme>; }> = ({ activeTab, catalogItem,hideImageGallery,sx }) => (
    <JucyMuiTabPanel activeValue={activeTab} {...getFleetCategoryDetailsDialogTabPanelProps('details')} sx={sx}>
        {hideImageGallery? null : (
        <FleetCategoryImageGallery catalogItem={catalogItem} />
                )}
        <Box sx={{ pt: 2, '& :first-child': { mt: 0 } }} dangerouslySetInnerHTML={{ __html: catalogItem.description || '' }} />
        {catalogItem.keyFeatures.length ? (
            <>
                <Typography className="fw-bold Typography mt-2">Key Features</Typography>
                <Grid container spacing={1} mt={1}>
                    {catalogItem.keyFeatures.map((feature, i) => (
                        <Grid key={`feature-${i}`} xs={6} sm={6} md={4}>
                            <FeatureIcon feature={feature} />
                        </Grid>
                    ))}
                </Grid>
            </>
        ) : null}
        {catalogItem.includedWith.length ? (
            <>
                <Typography fontWeight="700" fontSize="Typography" mt={2}>
                    Included with the vehicle
                </Typography>
                <Grid component={List} container spacing={1} mt={1}>
                    {catalogItem.includedWith.map((feature, i) => (
                        <Grid component={ListItem} alignItems="flex-start" key={`feature-${i}`} xs={6} sm={6} md={4}>
                            <ListItemIcon sx={{ minWidth: 31 }}>
                                <DoneIcon fontWeight="bold" color="secondary" />
                            </ListItemIcon>
                            <ListItemText disableTypography={true} sx={{ marginTop: 0, marginBottom: 0 }} primary={feature.title} />
                        </Grid>
                    ))}
                </Grid>
            </>
        ) : null}
    </JucyMuiTabPanel>
);
