import React from 'react';
import { Brand } from '../../lib/brand';
import { BrandContext, BrandStore } from '../../store/BrandStore';

export const BrandProvider = ({ children, brand }: { children: React.ReactElement; brand?: Brand }) => {
    const [brandStore] = React.useState<BrandStore>(() => new BrandStore({ brand }));
    return <BrandContext.Provider value={brandStore}>{children}</BrandContext.Provider>;
};

export const BrandConsumer = BrandContext.Consumer;
