'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { SvgIconProps } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

export const AvailableArrowIcon: React.FC<SvgIconProps> = ({ sx, viewBox, ...props }) => (
    <SvgIcon viewBox={viewBox || '0 0 45 15.743'} sx={mergeSx({ width: '2.8125em', height: '0.9839375em', fontSize: '13px' }, sx)} {...props}>
        <path
            className="st4"
            d="M.55 3.06c2.112 3.921 5.129 7.089 8.9 9.352 3.771 2.262 8.297 3.47 12.973 3.318 4.676-.15 9.202-1.659 12.822-4.073 3.62-2.413 6.335-5.43 8.296-9.352.152.15.302.905.453 1.508 1.056 2.867 1.66-1.659-.151-3.167 0-.151-.151-.151-.302-.151h-.15c-1.208-.604-3.169.15-3.772 1.056-.151.15-.151.452.151.603.453.302 1.056-.754 2.564-.754-.754 1.508-1.357 2.715-2.413 4.073-1.66 1.96-3.47 3.62-5.581 4.827-3.62 2.263-7.844 3.47-12.37 3.47a22.37 22.37 0 0 1-12.218-3.621C6.132 7.735 3.114 4.417 1.304.495.701-.712-.808.344.55 3.059"
            fill="#212121"
        />
    </SvgIcon>
);
