import { useConfirmDialog } from '@jucy-ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppStateStore from '../../store/AppStateStore';
import reservationStore from '../../store/ReservationStore';
import TranslationStore from '../../store/TranslationStore';
import tripStore from '../../store/TripStore';
import { JucyButton, JucyButtonProps } from '../Button/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
const EmailQuoteButton: React.FC<JucyButtonProps> = ({ disabled, showLoader, ...props }) => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const [openExcess, ExcessRequiredDialog] = useConfirmDialog({
        title: 'Excess reduction required',
        titleIcon: <WarningAmberIcon />,
        showCancel: false,
    });
    return (
        <>
            <ExcessRequiredDialog />

            <JucyButton
                color="light"
                variant="contained"
                disabled={disabled}
                showLoader={showLoader}
                onClick={() => {
                    const summary = reservationStore.activeQuote ? reservationStore.summary : tripStore.summary;

                    if (!summary?.selectedExcess) {
                        openExcess('Please select an excess reduction option before emailing your quote.');
                        return;
                    }
                    AppStateStore.toggleEmailQuoteModal();
                }}
                {...props}
            >
                {t('hirer_details.group.email_quote')}
            </JucyButton>
        </>
    );
};

export default EmailQuoteButton;
