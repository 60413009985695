import React from 'react';
import { Box, BoxProps, Tab, TabProps, Tabs } from '@mui/material';
import { styled } from '@mui/styles';

export const JucyMuiTabs = styled(Tabs)(
    {
        borderBottom: '1px solid rgb(235, 239, 241)',
        '& .MuiTabs-indicator': {
            height: 4,
        },
    },
    { name: 'jucy-tabs' }
);

interface JucyMuiTabProps extends TabProps {
    a11yName: string;
    value: string;
}

export const JucyMuiTab = styled(({ a11yName, value, ...props }: JucyMuiTabProps) => <Tab {...jucyMuiTabA11yProps({ a11yName, value })} value={value} {...props} />)(
    {
        color: '#111',
        fontSize: 14,
    },
    { name: 'jucy-tab' }
);

export const jucyMuiTabA11yProps = ({ a11yName, value }: { a11yName: string; value: string }) => ({
    id: `${a11yName}-tab-${value}`,
    'aria-controls': `${a11yName}-tabpanel-${value}`,
});

interface JucyTabPanelProps extends BoxProps {
    children?: React.ReactNode;
    a11yName: string;
    value: string | number;
    activeValue: string;
}

export const JucyMuiTabPanel = ({ children, value, a11yName, activeValue, ...props }: JucyTabPanelProps) => (
    <Box role="tabpanel" hidden={value !== activeValue} id={`${a11yName}-tab-${value}`} aria-labelledby={`${a11yName}-tabpanel-${value}`} {...props}>
        {value === activeValue ? children : null}
    </Box>
);
