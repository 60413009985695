'use client';
import React from 'react';
import { PaperProps } from '@mui/material';
import Box from '@mui/material/Box';
import { dialogClasses } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { CardHeader, CardIcon } from '../Card';

export interface ModalPaperProps extends PaperProps {
    title?: string;
    icon?: React.ReactNode;
    showClose?: boolean;
    onClose?: () => void;
}

export const ModalPaper: React.FC<ModalPaperProps> = ({ children, showClose, onClose, className, role, title, icon, ...props }) => {
    const isScrollPaper = `${className}`.includes(`${dialogClasses.paperScrollPaper}`);
    return (
        <div className={className} role={role} aria-labelledby={props['aria-labelledby']} aria-describedby={props['aria-describedby']}>
            <CardHeader color="primary" title={`${title}`} icon={Boolean(icon)} text={!icon} sx={{ display: 'flex' }}>
                {icon ? <CardIcon color="primary">{icon}</CardIcon> : null}
                {showClose ? (
                    <IconButton
                        color="primary"
                        sx={(theme) => ({
                            position: 'absolute',
                            top: icon ? theme.spacing(3) : theme.spacing(0.5),
                            right: theme.spacing(-1),
                            height: 40,
                            width: 40,
                            zIndex: 1,
                        })}
                        aria-label="Close"
                        onClick={onClose}
                        size="large"
                    >
                        <span aria-hidden="true">×</span>
                    </IconButton>
                ) : null}
            </CardHeader>
            <Paper
                sx={{
                    marginTop: -5,
                    paddingTop: 6,
                    width: '100%',
                    ...(isScrollPaper
                        ? {
                              display: 'flex',
                              overflow: 'hidden',
                          }
                        : undefined),
                }}
                {...props}
            >
                <Box
                    sx={{
                        ...(isScrollPaper
                            ? {
                                  overflow: 'auto',
                                  flex: '1 1',
                              }
                            : undefined),
                    }}
                >
                    {children}
                </Box>
            </Paper>
        </div>
    );
};
