import { observer } from 'mobx-react-lite';
import React from 'react';
import { RentalExcessReduction } from '@jucy/rentals-api-client';
import clsx from 'clsx';
import Money from '../../../../../lib/api/model/Money';
import CurrencyStore from '../../../../../store/CurrencyStore';
import { getCurrencyComponents } from '../../../../CurrencyFormatter/CurrencyFormatter';

interface PriceProps {
    item: Required<RentalExcessReduction>;
    className?: string;
}

const Price: React.FC<PriceProps> = observer(({ item, className }) => {
    const components = getCurrencyComponents(CurrencyStore.convertCurrency(item.price as Required<Money>));
    return (
        <div className={clsx('price d-flex align-items-center flex-column', className)}>
            <div className="fw-bold h6 mb-0" data-cy="daily-rate">
                {CurrencyStore.selectedCurrencyCode || item.price?.currencyCode || ''}{' '}
                {components.map(({ type, value }, i) => {
                    switch (type) {
                        case 'currency':
                            return value.replace(/^(-?)[A-Z]+/, '$1');
                        case 'decimal':
                        case 'fraction':
                            return (
                                <small key={`${value}-${i}`} className="fw-bold">
                                    {value}
                                </small>
                            );
                        default:
                            return value;
                    }
                })}
                {item.productType === 'Daily' ? <small className="fw-bold">/day</small> : null}
            </div>
        </div>
    );
});
Price.displayName = 'Price';
export default Price;
