import { Type, plainToInstance } from 'class-transformer';
import { PartialDeep } from 'type-fest';
import { Site } from './Site';

export class Region {
    countryCode = '';
    country = '';
    @Type(() => Site)
    sites: Site[] = [];

    static fromPlain(props: PartialDeep<Region>): Region {
        return plainToInstance(Region, props);
    }
}
