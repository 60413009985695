'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { BoxProps, SxProps, Theme } from '@mui/material';
import {
    JucySliderContainer,
    JucySliderNavButtonProps,
    JucySliderNavTrackContainer,
    JucySliderNavigation,
    JucySliderPagination,
    JucySliderRoot,
    JucySliderTrack,
    JucySliderTrackContainer,
} from './components';
import { JucySliderConfig, useBlazeSlider, useMapConfigToBlazeConfig, useMapConfigToSx, useWatchSlideHeight } from './lib';


export interface JucySliderProps extends React.ComponentProps<typeof JucySliderRoot> {
    config?: JucySliderConfig;
    adaptiveHeight?: boolean;
    showNav?: boolean;
    showDots?: boolean;
    sx?: SxProps<Theme>;
    componentProps?: {
        container?: Partial<BoxProps>;
        nav?: Partial<JucySliderNavButtonProps>;
        navNext?: Partial<JucySliderNavButtonProps>;
        navPrev?: Partial<JucySliderNavButtonProps>;
        pagination?: Partial<{ sx?: SxProps<Theme> }>;
    };
    children?: React.ReactNode;
}

export const JucySlider: React.FC<JucySliderProps> = ({
    componentProps,
    config,
    children,
    adaptiveHeight,
    showNav = true,
    showDots = true,
    sx,
    ...props
}) => {
    const blazeConfig = useMapConfigToBlazeConfig(config);
    const mappedSx = useMapConfigToSx(config);
    const [ref, slider] = useBlazeSlider(blazeConfig);
    useWatchSlideHeight({ slider, adaptiveHeight });
    return (
        <JucySliderRoot {...props} className="blaze-slider" ref={ref} sx={mergeSx(mappedSx, sx)}>
            <JucySliderContainer>
                <JucySliderNavTrackContainer>
                    <JucySliderTrackContainer>
                        <JucySliderTrack>{children}</JucySliderTrack>
                    </JucySliderTrackContainer>
                    {showNav ? <JucySliderNavigation componentProps={componentProps} /> : null}
                </JucySliderNavTrackContainer>
                {showDots ? <JucySliderPagination {...componentProps?.pagination} /> : null}
            </JucySliderContainer>
        </JucySliderRoot>
    );
};
