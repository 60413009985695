import React from 'react';
import { JucyCountryCode } from '@jucy/rentals-common';
import { DialogProps, ModalProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ModalDialog from '../../ModalDialog';
import AfterpayLightbox from './AfterpayLightbox';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: '#b2fce4',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '38em',
            overflow: 'hidden',
        },
    },
    close: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        height: 40,
        width: 40,
    },
}));

interface PayWithLaybuyProps extends DialogProps {
    onClose: ModalProps['onClose'];
    countryCode: JucyCountryCode;
}

const WhatsThisModal: React.FC<PayWithLaybuyProps> = ({ countryCode, ...props }) => {
    const classes = useStyles();

    return (
        <ModalDialog {...props} classes={{ paper: classes.paper }}>
            <AfterpayLightbox countryCode={countryCode} />
        </ModalDialog>
    );
};

export default WhatsThisModal;
