import { makeAutoObservable, observable } from 'mobx';

export default class Address {
    @observable Street = '';
    @observable State = '';
    @observable City = '';
    @observable Country = '';
    @observable PostCode = '';

    constructor(props?: Partial<Address>) {
        makeAutoObservable(this);
        Object.assign(this, props);
    }

    static fromApi(apiAddress?: Partial<Address>) {
        return new Address(apiAddress);
    }
}
