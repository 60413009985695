/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { SitesSettingsDefaultTimes } from './SitesSettingsDefaultTimes';
import {
    SitesSettingsDefaultTimesFromJSON,
    SitesSettingsDefaultTimesFromJSONTyped,
    SitesSettingsDefaultTimesToJSON,
} from './SitesSettingsDefaultTimes';
import type { SitesSettingsFleetTypeSettings } from './SitesSettingsFleetTypeSettings';
import {
    SitesSettingsFleetTypeSettingsFromJSON,
    SitesSettingsFleetTypeSettingsFromJSONTyped,
    SitesSettingsFleetTypeSettingsToJSON,
} from './SitesSettingsFleetTypeSettings';
import type { SitesSettingsHolidays } from './SitesSettingsHolidays';
import { SitesSettingsHolidaysFromJSON, SitesSettingsHolidaysFromJSONTyped, SitesSettingsHolidaysToJSON } from './SitesSettingsHolidays';
import type { SitesSettingsProhibitedPickUpSites } from './SitesSettingsProhibitedPickUpSites';
import {
    SitesSettingsProhibitedPickUpSitesFromJSON,
    SitesSettingsProhibitedPickUpSitesFromJSONTyped,
    SitesSettingsProhibitedPickUpSitesToJSON,
} from './SitesSettingsProhibitedPickUpSites';

/**
 *
 * @export
 * @interface SitesSettingsSites
 */
export interface SitesSettingsSites {
    /**
     *
     * @type {string}
     * @memberof SitesSettingsSites
     */
    siteName?: string;
    /**
     *
     * @type {string}
     * @memberof SitesSettingsSites
     */
    siteCode?: string;
    /**
     *
     * @type {Array<SitesSettingsFleetTypeSettings>}
     * @memberof SitesSettingsSites
     */
    fleetTypeSettings?: Array<SitesSettingsFleetTypeSettings>;
    /**
     *
     * @type {Array<SitesSettingsHolidays>}
     * @memberof SitesSettingsSites
     */
    holidays?: Array<SitesSettingsHolidays>;
    /**
     *
     * @type {Array<SitesSettingsDefaultTimes>}
     * @memberof SitesSettingsSites
     */
    defaultTimes?: Array<SitesSettingsDefaultTimes>;
    /**
     *
     * @type {Array<SitesSettingsProhibitedPickUpSites>}
     * @memberof SitesSettingsSites
     */
    prohibitedPickUpSites?: Array<SitesSettingsProhibitedPickUpSites>;
    /**
     *
     * @type {Array<SitesSettingsProhibitedPickUpSites>}
     * @memberof SitesSettingsSites
     */
    prohibitedDropOffSites?: Array<SitesSettingsProhibitedPickUpSites>;
}

/**
 * Check if a given object implements the SitesSettingsSites interface.
 */
export function instanceOfSitesSettingsSites(value: object): value is SitesSettingsSites {
    return true;
}

export function SitesSettingsSitesFromJSON(json: any): SitesSettingsSites {
    return SitesSettingsSitesFromJSONTyped(json, false);
}

export function SitesSettingsSitesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SitesSettingsSites {
    if (json == null) {
        return json;
    }
    return {
        siteName: json['siteName'] == null ? undefined : json['siteName'],
        siteCode: json['siteCode'] == null ? undefined : json['siteCode'],
        fleetTypeSettings:
            json['fleetTypeSettings'] == null ? undefined : (json['fleetTypeSettings'] as Array<any>).map(SitesSettingsFleetTypeSettingsFromJSON),
        holidays: json['holidays'] == null ? undefined : (json['holidays'] as Array<any>).map(SitesSettingsHolidaysFromJSON),
        defaultTimes: json['defaultTimes'] == null ? undefined : (json['defaultTimes'] as Array<any>).map(SitesSettingsDefaultTimesFromJSON),
        prohibitedPickUpSites:
            json['prohibitedPickUpSites'] == null
                ? undefined
                : (json['prohibitedPickUpSites'] as Array<any>).map(SitesSettingsProhibitedPickUpSitesFromJSON),
        prohibitedDropOffSites:
            json['prohibitedDropOffSites'] == null
                ? undefined
                : (json['prohibitedDropOffSites'] as Array<any>).map(SitesSettingsProhibitedPickUpSitesFromJSON),
    };
}

export function SitesSettingsSitesToJSON(value?: SitesSettingsSites | null): any {
    if (value == null) {
        return value;
    }
    return {
        siteName: value['siteName'],
        siteCode: value['siteCode'],
        fleetTypeSettings:
            value['fleetTypeSettings'] == null ? undefined : (value['fleetTypeSettings'] as Array<any>).map(SitesSettingsFleetTypeSettingsToJSON),
        holidays: value['holidays'] == null ? undefined : (value['holidays'] as Array<any>).map(SitesSettingsHolidaysToJSON),
        defaultTimes: value['defaultTimes'] == null ? undefined : (value['defaultTimes'] as Array<any>).map(SitesSettingsDefaultTimesToJSON),
        prohibitedPickUpSites:
            value['prohibitedPickUpSites'] == null
                ? undefined
                : (value['prohibitedPickUpSites'] as Array<any>).map(SitesSettingsProhibitedPickUpSitesToJSON),
        prohibitedDropOffSites:
            value['prohibitedDropOffSites'] == null
                ? undefined
                : (value['prohibitedDropOffSites'] as Array<any>).map(SitesSettingsProhibitedPickUpSitesToJSON),
    };
}
