'use client';
import React, { useId, useMemo } from 'react';
import { cn, generateTimeRange, getNow, isValidDate } from '@jucy-ui/common';
import { SelectProps, useMediaQuery } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import type { TimeValidationProps } from '@mui/x-date-pickers/internals';
import { format, parse } from 'date-fns';
import { SelectInputLabel } from '../SelectInputLabel';
import { DEFAULT_DESKTOP_MODE_MEDIA_QUERY, DEFAULT_TIME_FORMAT, DEFAULT_TIME_STEP } from './lib';

export interface TimeSelectProps
    extends Omit<SelectProps, 'children' | 'onChange' | 'onClose' | 'onOpen' | 'ref' | 'value'>,
        TimeValidationProps<Date> {
    desktopModeMediaQuery?: string;
    format?: string;
    value?: Date | null;
    onChange?: (date: Date | null) => void;
    onClose?: () => void;
    onOpen?: () => void;
    helperText?: React.ReactNode;
}

export const TimeSelect = React.forwardRef<HTMLSelectElement, TimeSelectProps>(({ className: passedClassName, error, helperText, ...props }, ref) => {
    const className = cn('time-select', passedClassName);
    const {
        minTime,
        maxTime,
        minutesStep = DEFAULT_TIME_STEP,
        shouldDisableTime,
        sx,
        onChange,
        value,
        format: timeFormat = DEFAULT_TIME_FORMAT,
        ...rest
    } = props;
    const useNative = !useMediaQuery(props.desktopModeMediaQuery || DEFAULT_DESKTOP_MODE_MEDIA_QUERY, { defaultMatches: true });
    const options = useGenerateTimeRange({
        minutesStep,
        minTime,
        maxTime,
    });
    const children = useTimeRangeToMenuItems(options, {
        shouldDisableTime,
        format: timeFormat,
        useNative,
    });
    const randId = useId();
    const id = props.id || randId;
    const labelId = `${id}-label`;
    return (
        <FormControl error={error} sx={sx} fullWidth={props.fullWidth} className={cn(className,error?'error':undefined)}>
            <SelectInputLabel id={labelId} htmlFor={id}>
                {props.label}
            </SelectInputLabel>
            <Select
                native={useNative}
                id={id}
                ref={ref}
                {...rest}
                placeholder={useNative ? undefined : props.placeholder}
                value={value && isValidDate(value) ? format(value, timeFormat) : ''}
                onChange={
                    onChange
                        ? (e) => {
                              onChange(parse(e.target.value as string, timeFormat, getNow()));
                          }
                        : undefined
                }
            >
                {children}
            </Select>
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>
    );
});
TimeSelect.displayName = 'TimeSelect';

const useGenerateTimeRange = ({ minutesStep, minTime, maxTime }: { minutesStep: number; minTime?: Date; maxTime?: Date }) =>
    useMemo(
        () =>
            generateTimeRange({
                step: minutesStep,
                startTime: minTime,
                endTime: maxTime,
            }),
        [minutesStep, minTime, maxTime] // }
    );
const useTimeRangeToMenuItems = (
    dates: Date[],
    {
        format: timeFormat,
        shouldDisableTime,
        useNative,
    }: {
        format: string;
        shouldDisableTime: TimeValidationProps<Date>['shouldDisableTime'];
        useNative?: boolean;
    }
) => {
    const filteredDates = useMemo(
        () => [
            ...(useNative ? [{ value: null, label: '' }] : []),
            ...(shouldDisableTime ? dates.filter((d) => !shouldDisableTime(d, 'hours')) : dates).map((date) => ({
                value: date,
                label: format(date, timeFormat),
            })),
        ],
        [useNative, shouldDisableTime, dates, timeFormat]
    );
    const Tag = useNative ? 'option' : MenuItem;
    return useMemo(
        () =>
            filteredDates.map((option) => [
                <Tag key={option.label} style={option.value ? undefined : { display: 'none' }} value={option.label}>
                    {option.label}
                </Tag>,
            ]),
        [Tag, filteredDates]
    );
};
