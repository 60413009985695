/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { GetFleetTypeCategoriesResponseData } from './GetFleetTypeCategoriesResponseData';
import {
    GetFleetTypeCategoriesResponseDataFromJSON,
    GetFleetTypeCategoriesResponseDataFromJSONTyped,
    GetFleetTypeCategoriesResponseDataToJSON,
} from './GetFleetTypeCategoriesResponseData';

/**
 *
 * @export
 * @interface GetFleetTypeCategoriesResponse
 */
export interface GetFleetTypeCategoriesResponse {
    /**
     *
     * @type {string}
     * @memberof GetFleetTypeCategoriesResponse
     */
    responseType?: GetFleetTypeCategoriesResponseResponseTypeEnum;
    /**
     *
     * @type {string}
     * @memberof GetFleetTypeCategoriesResponse
     */
    message?: string;
    /**
     *
     * @type {Array<GetFleetTypeCategoriesResponseData>}
     * @memberof GetFleetTypeCategoriesResponse
     */
    data?: Array<GetFleetTypeCategoriesResponseData>;
}

/**
 * @export
 */
export const GetFleetTypeCategoriesResponseResponseTypeEnum = {
    Success: 'Success',
    Error: 'Error',
    ValidationError: 'Validation Error',
    UnexpectedError: 'Unexpected Error',
} as const;
export type GetFleetTypeCategoriesResponseResponseTypeEnum =
    (typeof GetFleetTypeCategoriesResponseResponseTypeEnum)[keyof typeof GetFleetTypeCategoriesResponseResponseTypeEnum];

/**
 * Check if a given object implements the GetFleetTypeCategoriesResponse interface.
 */
export function instanceOfGetFleetTypeCategoriesResponse(value: object): value is GetFleetTypeCategoriesResponse {
    return true;
}

export function GetFleetTypeCategoriesResponseFromJSON(json: any): GetFleetTypeCategoriesResponse {
    return GetFleetTypeCategoriesResponseFromJSONTyped(json, false);
}

export function GetFleetTypeCategoriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFleetTypeCategoriesResponse {
    if (json == null) {
        return json;
    }
    return {
        responseType: json['ResponseType'] == null ? undefined : json['ResponseType'],
        message: json['Message'] == null ? undefined : json['Message'],
        data: json['Data'] == null ? undefined : (json['Data'] as Array<any>).map(GetFleetTypeCategoriesResponseDataFromJSON),
    };
}

export function GetFleetTypeCategoriesResponseToJSON(value?: GetFleetTypeCategoriesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        ResponseType: value['responseType'],
        Message: value['message'],
        Data: value['data'] == null ? undefined : (value['data'] as Array<any>).map(GetFleetTypeCategoriesResponseDataToJSON),
    };
}
