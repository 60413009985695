import React from 'react';
import { useTranslation } from 'react-i18next';
import { useExcessReductionFormContext } from '#/components/Forms/ExcessReductionForm/ExcessReductionForm';
import { RentalExcessReduction } from '@jucy/rentals-api-client';
import clsx from 'clsx';
import TranslationStore from '../../../../../store/TranslationStore';
import { JucyButton, JucyButtonProps } from '../../../../Button/Button';

interface PriceDetailsListProps {
    item: Required<RentalExcessReduction>;
    className?: string;
    buttonProps?: JucyButtonProps;
}

const PriceDetailsList: React.FC<PriceDetailsListProps> = ({ item, buttonProps, className }) => {
    const { isSelecting, readonly, activeCode } = useExcessReductionFormContext();
    const isActive = activeCode === item.productCode;
    const { t } = useTranslation(TranslationStore.currentNamespace);

    return (
        <div className={clsx('actions d-flex align-items-center flex-column mt-2', className)}>
            <JucyButton {...buttonProps} showLoader={(isSelecting && isActive) || buttonProps?.showLoader} disabled={readonly || (isActive && isSelecting) || buttonProps?.disabled}>
                {isActive ? 'Selected' : t('excess_reduction_page.group.select')}
            </JucyButton>
        </div>
    );
};
export default PriceDetailsList;
