'use client';
import {mergeSx} from '@jucy-ui/appearance';
import {Theme} from '@mui/material';
import {SxProps} from '@mui/system/styleFunctionSx';
import React from 'react';
import { CatalogItem, ExcessReductionFeature } from '@jucy-ui/common';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import {
    ComparisonTable,
    ComparisonTableBody,
    ComparisonTableCellHeadLabel,
    ComparisonTableCellLabel,
    ComparisonTableCellPinned,
    ComparisonTableCellValue,
    ComparisonTableContainer,
    ComparisonTableHead,
    ComparisonTableRow,
} from '../../../../../ComparisonTable';
import { JucyMuiTabPanel } from '../../../../../Tabs';
import { getFleetCategoryDetailsDialogTabPanelProps } from './fleetCategoryDetailsDialogTabConsts';

type ExcessNames = 'basic' | 'standard' | 'full';
type ExcessProduct = {
    name: ExcessNames;
    label: string;
};
const excessProducts: ExcessProduct[] = [
    { name: 'basic', label: 'Risk Taker' },
    { name: 'standard', label: 'Stress Free' },
    { name: 'full', label: 'Stress Free Plus' },
];
export const ExcessTab: React.FC<{ activeTab: string; catalogItem: CatalogItem,sx?: SxProps<Theme>; }> = ({ activeTab, catalogItem, sx }) => (
    <JucyMuiTabPanel activeValue={activeTab} {...getFleetCategoryDetailsDialogTabPanelProps('excess')} sx={mergeSx({pt:2},sx)}>
        {catalogItem.excessReductionFeatures.length > 0 ? (
            <ComparisonTableContainer role="region">
                <ComparisonTable role="table">
                    <ComparisonTableHead role="rowgroup">
                        <ComparisonTableRow role="row">
                            <ComparisonTableCellPinned role="columnheader"></ComparisonTableCellPinned>
                            {excessProducts.map((product) => (
                                <ComparisonTableCellHeadLabel role="columnheader" key={product.name}>
                                    {product.label}
                                </ComparisonTableCellHeadLabel>
                            ))}
                        </ComparisonTableRow>
                    </ComparisonTableHead>
                    <ComparisonTableBody role="rowgroup">
                        {catalogItem.excessReductionFeatures.map((feature, i) => (
                            <ComparisonTableRow key={feature.name} role="row" variant={i % 2 == 0 ? 'even' : 'odd'}>
                                <ComparisonTableCellLabel
                                    role="cell"
                                    dangerouslySetInnerHTML={{ __html: feature.name || '' }}
                                ></ComparisonTableCellLabel>
                                {excessProducts.map((product) => (
                                    <ComparisonTableCellValue role="cell" key={product.name}>
                                        <ExcessReductionComparisonFeature feature={feature} product={product.name} />
                                    </ComparisonTableCellValue>
                                ))}
                            </ComparisonTableRow>
                        ))}
                    </ComparisonTableBody>
                </ComparisonTable>
            </ComparisonTableContainer>
        ) : null}
        <Box
            dangerouslySetInnerHTML={{
                __html: catalogItem.excessReductionDescription || '',
            }}
        />
    </JucyMuiTabPanel>
);
const ExcessReductionComparisonFeature: React.FC<{
    feature: ExcessReductionFeature;
    product: ExcessNames;
}> = ({ feature, product }) => {
    const value = feature[product] as string | boolean;
    if (feature.type === 'text') {
        return value as string;
    }
    if (value) {
        return <DoneIcon fontWeight="bold" color="success" />;
    }
    return <CloseIcon fontWeight="bold" color="error" />;
};
