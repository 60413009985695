import React from 'react';
import { JucyCountryCode } from '@jucy/rentals-common';
import makeStyles from '@mui/styles/makeStyles';
import Image from 'next/image';
import AfterPayLogo from './asssets/Afterpay-Logo.svg';
import FourPaymentsIcon from './asssets/IconographySet_Four Payments.svg';
import LaptopIcon from './asssets/IconographySet_Laptop.svg';
import ShopIcon from './asssets/IconographySet_Shop.svg';
import SignUpLaptopIcon from './asssets/IconographySet_Sign Up Laptop.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        padding: theme.spacing(5, 2, 2, 2),
        backgroundColor: '#b2fce4',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    afterPayLogo: {
        width: 258,
    },
    icon: {
        width: 114,
    },
    title: {
        fontSize: 45,
        marginTop: 35,
        width: 405,
        lineHeight: 1,
    },
    processGrid: {
        display: 'flex',
        marginTop: 20,
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    processItem: {
        [theme.breakpoints.up('sm')]: {
            width: '25%',
            padding: '0 10px',
        },
        padding: '10px 0',
        lineHeight: 1,
    },
    caption: {
        textAlign: 'center',
    },
    terms: {
        lineHeight: 1,
        textAlign: 'center',
        marginTop: 66,
        fontSize: 12,
        '& a': {
            '&, &:visited': {
                color: '#111',
            },
        },
    },
}));

const AfterpayLightbox: React.FC<{ countryCode?: JucyCountryCode }> = ({ countryCode = 'nz' }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Image alt="Afterpay Logo" className={classes.afterPayLogo} src={AfterPayLogo} width={258} height={50} />
            <div className={classes.title}>Book now. Pay later. Always interest-free</div>
            <div className={classes.processGrid}>
                <div className={classes.processItem}>
                    <Image alt="" className={classes.icon} src={ShopIcon} />
                    <div className={classes.caption}>Add your favourites to cart</div>
                </div>
                <div className={classes.processItem}>
                    <Image alt="" className={classes.icon} src={LaptopIcon} />
                    <div className={classes.caption}> Select Afterpay at checkout</div>
                </div>
                <div className={classes.processItem}>
                    <Image alt="" className={classes.icon} src={SignUpLaptopIcon} />
                    <div className={classes.caption}> Log into or create your Afterpay account, with instant approval decision</div>
                </div>
                <div className={classes.processItem}>
                    <Image alt="" className={classes.icon} src={FourPaymentsIcon} />
                    <div className={classes.caption}> Your purchase will be split into 4 payments payable every 2 weeks</div>
                </div>
            </div>
            <div className={classes.terms}>
                <p>
                    All you need to apply is to have a debit or credit card, to be over 18 years of age, and to be a resident of
                    {countryCode === 'au' ? ' Australia' : ' New Zealand'}
                </p>
                <p>
                    Late fees and additional eligibility criteria apply. The first payment may be due at the time of purchase.
                    <br />
                    For complete terms visit{' '}
                    <a target="_blank" href={`https://www.afterpay.com/en-${countryCode?.toUpperCase()}/terms-of-service`} rel="noopener noreferrer">
                        afterpay.com/en-{countryCode?.toUpperCase()}/terms-of-service
                    </a>
                </p>
            </div>
        </div>
    );
};

export default AfterpayLightbox;
