import * as React from 'react';

export const augmentEventValue = <T extends React.SyntheticEvent>(event: T, value: string): T =>
    ({
        ...event,
        target: {
            ...event.target,
            value: value,
        },
    }) as T;
