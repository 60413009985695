import { DISPLAY_TIME_FORMAT_SHORT, RecursivelyReplaceNullWithUndefined, nullToUndefined } from '@jucy-ui/common';
import { format } from 'date-fns';
import { ConditionalPick } from 'type-fest';
import { Maybe } from 'yup';
import { SearchFormData, SearchFormQueryStringValues, SearchFormValues } from '../types';

export const searchFormValuesForQueryString = (
    values: Maybe<Partial<SearchFormValues>>
): Partial<RecursivelyReplaceNullWithUndefined<SearchFormQueryStringValues>> => {
    const result: Partial<RecursivelyReplaceNullWithUndefined<SearchFormQueryStringValues>> = {};
    if (!values) {
        return result;
    }
    for (const [key, value] of Object.entries(values)) {
        if (value instanceof Date) {
            result[key as keyof typeof result] = key.endsWith('Date') ? format(value, 'yyyy-MM-dd') : format(value, DISPLAY_TIME_FORMAT_SHORT);
        } else if (typeof value === 'string') {
            type stringKeys = keyof ConditionalPick<SearchFormData, string>;
            result[key as stringKeys] = value || '';
        }
    }

    return nullToUndefined(result);
};
