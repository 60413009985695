import { useFeatureIsOn } from './useFeatureIsOn';
import { useMemo } from 'react';
import { useAuth } from './useAuth';
import { useTripApi } from './useTripApi';

export const useIsEditEnabled = () => {
    const { user, isLoading } = useAuth();
    const tripEnabled = useTripApi();
    const editResEnabled = useFeatureIsOn('edit-reservation');
    const accountIsEligible = user.type === 'staff' || (user?.type === 'agent' && user?.features?.['edit-reservation']);

    return useMemo(
        () => ({
            isLoading,
            editEnabled: tripEnabled && editResEnabled && accountIsEligible,
        }),
        [editResEnabled, isLoading, tripEnabled, accountIsEligible]
    );
};
