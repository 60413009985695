/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { BaseResponse, GetSitesResponse, SiteSettingsResponse } from '../models/index';
import {
    BaseResponseFromJSON,
    BaseResponseToJSON,
    GetSitesResponseFromJSON,
    GetSitesResponseToJSON,
    SiteSettingsResponseFromJSON,
    SiteSettingsResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetCountriesRequest {
    country?: string;
    jucybusinessunit?: string;
}

export interface GetSitesRequest {
    country?: string;
    jucybusinessunit?: string;
}

/**
 *
 */
export class SitesApi extends runtime.BaseAPI {
    /**
     */
    async getCountriesRaw(
        requestParameters: GetCountriesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<BaseResponse>> {
        const queryParameters: any = {};

        if (requestParameters['country'] != null) {
            queryParameters['country'] = requestParameters['country'];
        }

        if (requestParameters['jucybusinessunit'] != null) {
            queryParameters['jucybusinessunit'] = requestParameters['jucybusinessunit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/Sites/2.0/GetCountries`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCountries(
        country?: string,
        jucybusinessunit?: string,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<BaseResponse> {
        const response = await this.getCountriesRaw({ country: country, jucybusinessunit: jucybusinessunit }, initOverrides);
        return await response.value();
    }

    /**
     * Get service constraints for all pick up/drop off sites
     * Get rental sites settings
     */
    async getRentalSitesSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiteSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/rental-sites/settings`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Get service constraints for all pick up/drop off sites
     * Get rental sites settings
     */
    async getRentalSitesSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiteSettingsResponse> {
        const response = await this.getRentalSitesSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of all locations where a vehicle may be picked up from or dropped off to
     * Get rental sites
     */
    async getSitesRaw(
        requestParameters: GetSitesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<GetSitesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['country'] != null) {
            queryParameters['country'] = requestParameters['country'];
        }

        if (requestParameters['jucybusinessunit'] != null) {
            queryParameters['jucybusinessunit'] = requestParameters['jucybusinessunit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/Sites/1.0/GetSites`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSitesResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of all locations where a vehicle may be picked up from or dropped off to
     * Get rental sites
     */
    async getSites(
        country?: string,
        jucybusinessunit?: string,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<GetSitesResponse> {
        const response = await this.getSitesRaw({ country: country, jucybusinessunit: jucybusinessunit }, initOverrides);
        return await response.value();
    }
}
