'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { DISPLAY_DATE_FORMAT_LONG, DISPLAY_TIME_FORMAT_LONG, Site } from '@jucy-ui/common';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { format } from 'date-fns';
import { Diff } from './Diff';

export interface TripInfoLocationDateSummaryProps {
    title?: React.ReactNode;
    date?: Date;
    site?: Site;
    editBtn?: React.ReactNode;
    amendedDate?: Date;
    amendedSite?: Site;
    componentProps?: {
        root?: BoxProps;
        title?: TypographyProps;
        date?: TypographyProps & { format?: string };
        site?: TypographyProps;
        time?: TypographyProps & { format?: string };
    };
}

export const TripInfoLocationDateSummary: React.FC<TripInfoLocationDateSummaryProps> = ({
    title,
    date,
    site,
    editBtn,
    amendedDate,
    amendedSite,
    componentProps,
}) => {
    const dateFormat = componentProps?.date?.format || DISPLAY_DATE_FORMAT_LONG;
    const timeFormat = componentProps?.time?.format || DISPLAY_TIME_FORMAT_LONG;

    return (
        <Box {...componentProps?.root}>
            {title ? (
                <Typography variant="subtitle2" component="h4" {...componentProps?.title}>
                    {title}
                </Typography>
            ) : null}
            {date ? (
                <Typography
                    key="date"
                    variant="button"
                    textTransform="none"
                    {...componentProps?.date}
                    sx={mergeSx({ display: 'flex', alignItems: 'center', gap: 2 }, componentProps?.date?.sx)}
                >
                    <Diff oldValue={format(date, dateFormat)} newValue={amendedDate && format(amendedDate, dateFormat)} />
                    {editBtn ? editBtn : null}
                </Typography>
            ) : null}
            {site?.name ? (
                <Typography
                    key="branch"
                    variant="body2"
                    {...componentProps?.site}
                    sx={mergeSx({ display: 'flex', alignItems: 'center', gap: 2 }, componentProps?.site?.sx)}
                >
                    <Diff oldValue={site.name} newValue={amendedSite?.name} />
                </Typography>
            ) : null}
            {date ? (
                <Typography
                    key="time"
                    variant="body2"
                    {...componentProps?.time}
                    sx={mergeSx({ display: 'flex', alignItems: 'center', gap: 2 }, componentProps?.time?.sx)}
                >
                    <Diff oldValue={format(date, timeFormat)} newValue={amendedDate && format(amendedDate, timeFormat)} />
                </Typography>
            ) : null}
        </Box>
    );
};
