import { Deal, FleetType, getNow, isValidDate, memoize } from '@jucy-ui/common';
import { max } from 'date-fns';
import { addLeadTime } from './addLeadTime';
import { roundTime } from './roundTime';

export const getMinDate = memoize(({ fleetType, deal }: { fleetType?: FleetType; deal?: Deal } = {}) => {
    const leadStart = addLeadTime({ date: getNow(), fleetType });

    const dealStart = deal?.conditions?.startDateOnOrAfter ? deal.conditions.startDateOnOrAfter : deal?.conditions?.startDateBetween?.from;
    const minDate = isValidDate(dealStart) ? max([dealStart, leadStart]) : leadStart;

    //round up to the next 30 minute interval
    const rounded = roundTime(minDate);
    return rounded;
});
