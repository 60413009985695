import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { PageContentModel } from '@jucy/content-api-client';
import config from '#/config';

export interface UseGetCMSPageOptions extends Omit<UseQueryOptions<PageContentModel | null, Error>, 'queryFn' | 'queryKey'> {
    path: string;
}

export type UseGetCMSPageResponse = Omit<UseQueryResult<PageContentModel | null, Error>, 'data'> & {
    page?: PageContentModel | null;
};
const getPage = async (path: string): Promise<PageContentModel | null> => {
    const cmsPath = ['app', 'pages', ...path.split('/')].filter(Boolean).join('/');
    const res = await fetch(`${config.dataApiBaseUrl}/${cmsPath}`);
    if (res.ok) {
        const page = await res.json();
        page.content = page.content?.replace('<oembed', '<o-embed').replace('</oembed', '</o-embed');
        return page;
    }
    return null;
};

const useGetCMSPage = ({ path, ...options }: UseGetCMSPageOptions): UseGetCMSPageResponse => {
    const { data, ...state } = useQuery<PageContentModel | null, Error>({
        queryKey: ['get-content', path],
        queryFn: () => getPage(path),
        refetchInterval: false,
        refetchOnWindowFocus: false,
        ...options,
    });

    return useMemo(
        () => ({
            page: data,
            ...state,
        }),
        [data, state]
    );
};

export default useGetCMSPage;
