'use client';
import React from 'react';
import { getPaletteColor } from '@jucy-ui/appearance';
import { ButtonProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, rgbToHex, useTheme } from '@mui/material/styles';

export interface CardProps {
    className?: string;
    plain?: boolean;
    color?: ButtonProps['color'];
    children?: React.ReactNode;
}

export const Card: React.FC<CardProps> = (props) => {
    const { className, children, plain, color, ...rest } = props;
    const theme = useTheme<Theme>();
    const palette = theme.palette.augmentColor({
        color: { main: getPaletteColor(color, theme).main },
    });
    return (
        <Box
            sx={{
                border: '0',
                minWidth: 0,
                marginBottom: '30px',
                marginTop: '30px',
                borderRadius: '6px',
                color: alpha(theme.palette.common.black, 0.87),
                background: theme.palette.common.white,
                width: '100%',
                boxShadow: `0 1px 4px 0 ${alpha(theme.palette.common.black, 0.14)}`,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                wordWrap: 'break-word',
                fontSize: '.875rem',
                ...(plain
                    ? {
                          background: 'transparent',
                          boxShadow: 'none',
                      }
                    : undefined),
                ...(color
                    ? {
                          background: `linear-gradient(60deg, ${rgbToHex(palette.light)}, ${palette.main})`,
                          '& h1 small': {
                              color: alpha(theme.palette.common.white, 0.8),
                          },
                          color: theme.palette.common.white,
                      }
                    : undefined),
            }}
            className={className}
            {...rest}
        >
            {children}
        </Box>
    );
};
