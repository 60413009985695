import React from 'react';
import { AlertTitle, Box } from '@mui/material';
import { JucyAlert, JucyAlertProps } from './JucyAlert';

const FatalError: React.FC<Omit<JucyAlertProps, 'color'> & { title?: string; message?: string }> = ({ children, message, title, ...props }) => (
    <Box>
        <Box pt={2} display="flex">
            <JucyAlert color="primary" variant="outlined" severity="error" sx={{ flexShrink: 1 }} {...props}>
                {title ? <AlertTitle>{title}</AlertTitle> : null}
                {message ? (
                    message
                ) : (
                    <>
                        Sorry we are unable to complete your request at this time
                        <br />
                        Please email <a href="mailto:res@jucyworld.com">res@jucyworld.com</a> to make a booking or check back later
                    </>
                )}
                {children}
            </JucyAlert>
        </Box>
    </Box>
);

export default FatalError;
