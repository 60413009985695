import { createThemeForBand } from '#/lib/brand/createObeThemeOptionsForBand';
import { useBrand } from '@jucy-ui/components';
import { CssBaseline, ThemeProvider } from '@mui/material';
import React, { useMemo } from 'react';

export interface BrandThemeProviderProps {
    children?: React.ReactNode;
}

export const BrandThemeProvider: React.FC<BrandThemeProviderProps> = ({ children }) => {
    const brand = useBrand();

    const theme = useMemo(() => createThemeForBand(brand), [brand]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};
