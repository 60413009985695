'use client';
import React from 'react';
import { StackProps, styled } from '@mui/material';
import { formControlClasses } from '@mui/material/FormControl';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';

const TripFieldsDateTimeContainerRoot = styled(Stack, {
    name: 'TripFieldsDateTimeContainer',
    slot: 'root',
})(() => ({
    [`.${formControlClasses.root}`]: {
        flex: '1 1 50%',
        position: 'relative',
        ['&:hover,&:focus']: {
            zIndex: 2,
        },
        [`&.${inputBaseClasses.focused},&.${inputBaseClasses.error}`]: {
            zIndex: 3,
        },
    },
    [`.${formControlClasses.root}:nth-of-type(2n + 1)`]: {
        [`.${inputBaseClasses.root}.${outlinedInputClasses.root}`]: {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            [`&:not(:hover):not(.${inputBaseClasses.focused}):not(.${inputBaseClasses.error})`]: {
                [`.${outlinedInputClasses.notchedOutline}`]: {
                    borderRightWidth: 0,
                },
            },
        },
    },
    [`.${formControlClasses.root}:nth-of-type(2n + 2)`]: {
        [`.${outlinedInputClasses.root}`]: {
            marginLeft: '-1px',
            [`&, & .${outlinedInputClasses.notchedOutline}, & .${outlinedInputClasses.input}`]: {
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
            },
        },
    },
}));
export const TripFieldsDateTimeContainer: React.FC<StackProps> = ({ children, ...props }) => (
    <TripFieldsDateTimeContainerRoot {...props} direction="row">
        {children}
    </TripFieldsDateTimeContainerRoot>
);
