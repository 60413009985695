import { URLSearchParamsInit, createSearchParams } from 'react-router-dom';

export const mergeSearchParams = (...params: (URLSearchParamsInit | Record<string, string | undefined> | null | undefined)[]): URLSearchParams => {
    const data = params.reduce<Record<string, string>>((res, params) => {
        if (!params) {
            return res;
        }
        return {
            ...res,
            ...Object.fromEntries(createSearchParams(params as URLSearchParamsInit)),
        };
    }, {} as Record<string, string>);
    return createSearchParams(data);
};
