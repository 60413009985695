export const loadImage = (url: string) =>
    new Promise<HTMLImageElement | undefined>((resolve, reject) => {
        if (typeof Image === 'undefined') {
            return resolve(undefined);
        }
        const img = new Image();
        img.addEventListener('load', () => resolve(img));
        img.addEventListener('error', (err) => reject(err));
        img.src = url;
    });
