import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { CountryInfo } from '../lib/CountryInfo';
import { CACHE_TTL, cache } from '../services/cache';

interface useFetchCountriesResult extends Omit<UseQueryResult<CountryInfo[]>, 'data'> {
    countries: CountryInfo[];
}

const useFetchCountries = (options?: UseQueryOptions<CountryInfo[], Error, CountryInfo[]>): useFetchCountriesResult => {
    const { data, ...result } = useQuery<CountryInfo[], Error, CountryInfo[]>({
        queryKey: ['fetch-countries'],
        queryFn: async () => {
            let info: CountryInfo[] | undefined = await cache.get('rentals-countries');
            if (!info) {
                info = await fetch('https://portal.jucy.com/api/rentals/countries').then((r) => r.json());
                cache.set('rentals-countries', info, CACHE_TTL.ONE_HOUR * 2);
            }
            return info || [];
        },
        staleTime: 30 * 60000,
        refetchOnWindowFocus: false,
        retry: 0,
        ...options,
    });
    return useMemo(() => ({ ...result, countries: data || [] }), [data, result]);
};
export default useFetchCountries;
