import React from 'react';
import { AlertTitle } from '@mui/material';
import { JucyAlert, JucyAlertProps } from './JucyAlert';

export interface JucyErrorAlertProps extends Omit<JucyAlertProps, 'severity' | 'color' | 'title'> {
    title?: React.ReactNode;
    message?: React.ReactNode;
}

export const JucyErrorAlert: React.FC<JucyErrorAlertProps> = ({ title, message, children, ...props }) => (
    <JucyAlert color="primary" variant="outlined" severity="error" {...props}>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {message ? message : null}
        {children}
    </JucyAlert>
);
