import { makeAutoObservable } from 'mobx';

class Money {
    currencyCode = '';
    value = 0;

    constructor(props?: Partial<Money>) {
        makeAutoObservable(this);
        Object.assign(this, props);
    }

    get CurrencyCode() {
        return this.currencyCode;
    }

    set CurrencyCode(currencyCode) {
        this.currencyCode = currencyCode;
    }

    get Value() {
        return this.value;
    }

    set Value(value) {
        this.value = value;
    }

    static fromApi(money?: { CurrencyCode?: string; currencyCode?: string; Value?: number; value?: number }) {
        const value = money?.Value || money?.value || 0;
        const currencyCode = money?.CurrencyCode || money?.currencyCode || '';
        return new Money({ value, currencyCode });
    }
}

export default Money;
