import React from 'react';
import { FleetCategory } from '@jucy/rentals-api-client/rentals-api-v3';
import { Box, BoxProps, CircularProgress, Typography } from '@mui/material';
import clsx from 'clsx';
import { format } from 'date-fns';
import { AvailabilitySearchResult } from '../../../../store/AlternateDatesStore';
import { AlternateDateSlideProvider, useAlternateDateSlideContext } from './AlternateDateSlideContext';
import AlternateDatesSlideButton from './AlternateDatesSlideButton';

export interface AlternateDatesSlideProps extends Omit<BoxProps, 'ref'> {
    showMarker?: boolean;
    availability?: AvailabilitySearchResult;
    fleetCategory?: FleetCategory;
    isAnimating?: boolean;
}

const AlternateAvailabilitySlideInner = React.forwardRef<HTMLDivElement, AlternateDatesSlideProps>(({ sx, className, ...props }, ref) => {
    const { availabilityLabel, isLoading, isFreeSell, availability, dailyRate } = useAlternateDateSlideContext();
    return (
        <Box
            className={clsx('keen-slider__slide', className)}
            sx={[
                {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'space-between',
                    ['&.keen-slider__slide']: {
                        overflow: 'visible',
                    },
                    height: '95px',
                },
            ]}
            {...props}
            ref={ref}
        >
            <AlternateDatesSlideButton>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    {availability?.pickUpDate ? format(availability.pickUpDate, 'eee dd') : ''}
                </Typography>
                <Typography
                    variant="body2"
                    className={isLoading ? undefined : 'animate__animated animate__fadeIn'}
                    sx={{
                        fontSize: availabilityLabel === 'Unavailable' ? '10px' : '12px',
                        color: isFreeSell ? 'primary.main' : undefined,
                        fontWeight: isFreeSell ? 'bold' : undefined,
                    }}
                >
                    {availabilityLabel}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px' }} className={isLoading ? undefined : 'animate__animated animate__fadeIn'}>
                    {dailyRate ? dailyRate : <>&nbsp;</>}
                </Typography>
            </AlternateDatesSlideButton>
            {isLoading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                    }}
                    className="animate__animated animate__fadeIn"
                >
                    <CircularProgress size={80} thickness={2} />
                </Box>
            ) : null}
        </Box>
    );
});

const AlternateAvailabilitySlide = React.forwardRef<HTMLDivElement, AlternateDatesSlideProps>(({ availability, fleetCategory, isAnimating, ...props }, ref) => (
    <AlternateDateSlideProvider availability={availability} fleetCategory={fleetCategory} isAnimating={isAnimating}>
        <AlternateAvailabilitySlideInner ref={ref} {...props} />
    </AlternateDateSlideProvider>
));
export default AlternateAvailabilitySlide;
