import React from 'react';
import { JitsuProvider as OGJitsuProvider, createClient } from '@jitsu/nextjs';
import config from '../../config';

export const jitsuClient = config.jitsuKey
    ? createClient({
          tracking_host: 'https://jitsu.insights.jucy.cloud/',
          key: config.jitsuKey,
      })
    : undefined;
export const JitsuProvider = ({ children }: { children: React.ReactElement }) => {
    if (jitsuClient) {
        return <OGJitsuProvider client={jitsuClient}>{children}</OGJitsuProvider>;
    }

    return children;
};
