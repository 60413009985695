'use client';
import React, { useCallback, useState } from 'react';
import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog';

export type UseConfirmDialogOptions = Omit<ConfirmDialogProps, 'onClose' | 'open' | 'children'>;
export const useConfirmDialog = (props?: UseConfirmDialogOptions) => {
    const [open, setOpen] = useState(false);
    const [resolver, setResolver] = useState<{ resolve?: Resolver<boolean> }>({ resolve: undefined });
    const [label, setLabel] = useState<React.ReactNode>('');
    const getConfirmation = async (text: React.ReactNode) => {
        setLabel(text);
        setOpen(true);
        const [promise, resolver] = createPromise<boolean>();
        setResolver({ resolve: resolver });
        return promise;
    };
    const onClick = useCallback(
        async (status: boolean) => {
            setOpen(false);
            resolver.resolve?.(status);
        },
        [resolver]
    );
    const Confirmation: React.FC = useCallback(
        () => (
            <ConfirmDialog open={open} onConfirm={() => onClick(true)} onCancel={() => onClick(false)} {...props}>
                {label}
            </ConfirmDialog>
        ),
        [label, onClick, open, props]
    );
    return [getConfirmation, Confirmation] as const;
};
type Resolver<ReturnType> = (value: ReturnType | PromiseLike<ReturnType>) => void;
const createPromise = <ReturnType,>() => {
    let resolver: Resolver<ReturnType> = () => undefined;
    return [
        new Promise<ReturnType>((resolve) => {
            resolver = resolve;
        }),
        resolver,
    ] as const;
};
