'use client';
import React from 'react';
import { ReactImageGalleryItem, ReactImageGalleryProps } from 'react-image-gallery';
import { GalleryItem } from '@jucy-ui/common';
import Box from '@mui/material/Box';
import { AspectRatio } from '../../../../AspectRatio';
import { JucyImageGallery } from '../../../../JucyImageGallery';

const noOp = () => undefined;
const renderImageGalleryItem = (item: ReactImageGalleryItem) => (
    <AspectRatio ratio="593/395" objectFit="contain" maxHeight="100vh">
        {['Image 360', '360 image'].includes((item as GalleryItem).type) ? (
            <Box
                component="iframe"
                sx={{ border: 'none' }}
                className="item-frame"
                src={item.original}
                allowFullScreen
                title={item.originalTitle}
                allow="vr"
            />
        ) : (
            <Box
                component="img"
                className="item-image"
                src={item.original}
                alt={item.originalAlt || ''}
                sizes={item.sizes}
                title={item.originalTitle}
                onError={noOp}
                width={593}
                height={395}
                sx={{ width: '100%', height: 'auto' }}
            />
        )}

        {item.description && <span className="image-gallery-description">{item.description}</span>}
    </AspectRatio>
);

export interface GalleryItemProps extends ReactImageGalleryProps {
    items: GalleryItem[];
}

export const ImageGallery: React.FC<GalleryItemProps> = ({ items, ...props }) => (
    <JucyImageGallery
        items={items}
        thumbnailPosition="right"
        renderItem={renderImageGalleryItem}
        infinite={false}
        showPlayButton={false}
        lazyLoad={true}
        {...props}
    />
);
