import { Theme, darken, lighten } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import deepmerge from '@mui/utils/deepmerge';
import { reactToastifyDefaultStyles } from './styles';

export const generateToastContainerStyles = (theme: Theme) => {
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    return deepmerge(reactToastifyDefaultStyles, {
        '--toastify-color-light': theme.palette.common.white,
        '--toastify-color-dark': theme.palette.grey[900],
        '--toastify-color-info': theme.palette.info.main,
        '--toastify-color-success': theme.palette.success.main,
        '--toastify-color-warning': theme.palette.warning.main,
        '--toastify-color-error': theme.palette.error.main,
        '--toastify-color-transparent': 'hsla(0, 0%, 100%, .7)',
        '--toastify-icon-color-info': 'var(--toastify-color-info)',
        '--toastify-icon-color-success': 'var(--toastify-color-success)',
        '--toastify-icon-color-warning': 'var(--toastify-color-warning)',
        '--toastify-icon-color-error': 'var(--toastify-color-error)',
        '--toastify-toast-width': '100%',
        '--toastify-toast-background': '#fff',
        '--toastify-toast-min-height': '64px',
        '--toastify-toast-max-height': '800px',
        '--toastify-font-family': theme.typography.fontFamily,
        '--toastify-z-index': '9999',
        '--toastify-text-color-light': '#757575',
        '--toastify-text-color-dark': theme.palette.grey[900],
        '--toastify-text-color-info': theme.palette.info.main,
        '--toastify-text-color-success': theme.palette.success.main,
        '--toastify-text-color-warning': theme.palette.warning.main,
        '--toastify-text-color-error': theme.palette.error.main,
        '--toastify-spinner-color': '#616161',
        '--toastify-spinner-color-empty-area': '#e0e0e0',
        '--toastify-color-progress-light': 'linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55)',
        '--toastify-color-progress-dark': '#bb86fc',
        '--toastify-color-progress-info': 'var(--toastify-color-info)',
        '--toastify-color-progress-success': 'var(--toastify-color-success)',
        '--toastify-color-progress-warning': 'var(--toastify-color-warning)',
        '--toastify-color-progress-error': 'var(--toastify-color-error)',
        ...['error', 'info', 'success', 'warning'].reduce((acc, color) => {
            acc[`& .Toastify__toast--${color}`] = {
                color: getColor(theme.palette[color as 'primary'].light, 0.6),
                backgroundColor: getBackgroundColor(theme.palette[color as 'primary'].light, 0.9),
                '.Toastify__toast-icon': {
                    color: theme.palette[color as 'primary'].dark,
                },
            };
            acc[`& .Toastify__progress-bar--${color}`] = {
                backgroundColor: theme.palette[color as 'primary'].main,
            };
            return acc;
        }, {} as CSSProperties),
        '& .Toastify__progress-bar': {
            animation: 'Toastify__trackProgress linear 1 forwards',
        },
        '& .Toastify__toast-container': {
            padding: theme.spacing(0, 1.25),
        },
        '& .Toastify__toast-body': {
            alignItems: 'flex-start',
        },
        '& .Toastify__toast-icon': {
            marginRight: theme.spacing(1.5),
            marginTop: '-3px',
            display: 'flex',
            fontSize: '22px',
            opacity: '0.9',
            width: 'unset',
        },
        fontSize: '0.875rem',
        lineHeight: '1.43',
    });
};
