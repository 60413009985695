'use client';
import { styled } from '@mui/material/styles';

export const ComparisonTable = styled('table', {
    name: 'JucyComparisonTableRoot',
    slot: 'Root',
})({
    borderCollapse: 'collapse',
    border: '0',
    width: '100%',
    '@media (max-width: 550px)': {},
    '@media (max-width: 400px)': {
        fontSize: 'smaller',
    },
});
