/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { Money } from './Money';
import { MoneyFromJSON, MoneyFromJSONTyped, MoneyToJSON } from './Money';
import type { RateInfoDaily } from './RateInfoDaily';
import { RateInfoDailyFromJSON, RateInfoDailyFromJSONTyped, RateInfoDailyToJSON } from './RateInfoDaily';
import type { RateInfoDiscount } from './RateInfoDiscount';
import { RateInfoDiscountFromJSON, RateInfoDiscountFromJSONTyped, RateInfoDiscountToJSON } from './RateInfoDiscount';
import type { RateInfoMonthly } from './RateInfoMonthly';
import { RateInfoMonthlyFromJSON, RateInfoMonthlyFromJSONTyped, RateInfoMonthlyToJSON } from './RateInfoMonthly';
import type { RateInfoSeason } from './RateInfoSeason';
import { RateInfoSeasonFromJSON, RateInfoSeasonFromJSONTyped, RateInfoSeasonToJSON } from './RateInfoSeason';

/**
 *
 * @export
 * @interface RateInfo
 */
export interface RateInfo {
    /**
     *
     * @type {RateInfoDaily}
     * @memberof RateInfo
     */
    daily?: RateInfoDaily;
    /**
     *
     * @type {RateInfoMonthly}
     * @memberof RateInfo
     */
    monthly?: RateInfoMonthly;
    /**
     *
     * @type {RateInfoDiscount}
     * @memberof RateInfo
     */
    discount?: RateInfoDiscount;
    /**
     *
     * @type {number}
     * @memberof RateInfo
     */
    numberOfDays?: number;
    /**
     *
     * @type {number}
     * @memberof RateInfo
     */
    numberOfHours?: number;
    /**
     *
     * @type {Money}
     * @memberof RateInfo
     */
    subTotal?: Money;
    /**
     *
     * @type {RateInfoSeason}
     * @memberof RateInfo
     */
    season?: RateInfoSeason;
}

/**
 * Check if a given object implements the RateInfo interface.
 */
export function instanceOfRateInfo(value: object): value is RateInfo {
    return true;
}

export function RateInfoFromJSON(json: any): RateInfo {
    return RateInfoFromJSONTyped(json, false);
}

export function RateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateInfo {
    if (json == null) {
        return json;
    }
    return {
        daily: json['daily'] == null ? undefined : RateInfoDailyFromJSON(json['daily']),
        monthly: json['monthly'] == null ? undefined : RateInfoMonthlyFromJSON(json['monthly']),
        discount: json['discount'] == null ? undefined : RateInfoDiscountFromJSON(json['discount']),
        numberOfDays: json['numberOfDays'] == null ? undefined : json['numberOfDays'],
        numberOfHours: json['numberOfHours'] == null ? undefined : json['numberOfHours'],
        subTotal: json['subTotal'] == null ? undefined : MoneyFromJSON(json['subTotal']),
        season: json['season'] == null ? undefined : RateInfoSeasonFromJSON(json['season']),
    };
}

export function RateInfoToJSON(value?: RateInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        daily: RateInfoDailyToJSON(value['daily']),
        monthly: RateInfoMonthlyToJSON(value['monthly']),
        discount: RateInfoDiscountToJSON(value['discount']),
        numberOfDays: value['numberOfDays'],
        numberOfHours: value['numberOfHours'],
        subTotal: MoneyToJSON(value['subTotal']),
        season: RateInfoSeasonToJSON(value['season']),
    };
}
