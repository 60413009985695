'use client';
import React from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';

interface CardBodyProps {
    className?: string;
    background?: boolean;
    plain?: boolean;
    formHorizontal?: boolean;
    color?: boolean;
    children?: React.ReactNode;
}

export const CardBody: React.FC<CardBodyProps> = (props) => {
    const { className, children, background, plain, formHorizontal, color, ...rest } = props;
    const theme = useTheme<Theme>();
    return (
        <Box
            sx={{
                padding: '0.9375rem 20px',
                flex: '1 1 auto',
                WebkitBoxFlex: '1',
                position: 'relative',
                ...(background
                    ? {
                          position: 'relative',
                          zIndex: '2',
                          minHeight: '280px',
                          paddingTop: '40px',
                          paddingBottom: '40px',
                          maxWidth: '440px',
                          margin: '0 auto',
                      }
                    : undefined),
                ...(plain
                    ? {
                          paddingLeft: '5px',
                          paddingRight: '5px',
                      }
                    : undefined),
                ...(formHorizontal
                    ? {
                          paddingLeft: '15px',
                          paddingRight: '15px',
                          '& form': {
                              margin: '0',
                          },
                      }
                    : undefined),
                ...(color
                    ? {
                          borderRadius: '6px',
                          '& h1,& h2,& h3': {
                              '& small': {
                                  color: alpha(theme.palette.common.white, 0.8),
                              },
                          },
                      }
                    : undefined),
            }}
            className={className}
            {...rest}
        >
            {children}
        </Box>
    );
};
