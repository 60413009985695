import { PartialSearchFormValues } from '#/components/Forms/TripSearchForm/SearchFormValues';
import type { DefaultError, UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { validateSearchValues } from '#/components/Forms/TripSearchForm/SearchForm';
import Events from '#/lib/Events';
import rentalTripSearchStore from '#/store/RentalTripSearchStore';
import { createSearchParams } from 'react-router-dom';
import availabilityStore from '#/store/AvailabilityStore';
import { runInAction } from 'mobx';
import reservationStore from '#/store/ReservationStore';
import { useRouteNavigate } from '#/hooks/useNavigateRoute';
import { useSearchParams } from '#/hooks/useSearchParams';

type UseDirectSubmitSearchData = unknown;
type UseDirectSubmitSearchError = DefaultError;
type UseDirectSubmitSearchVariables = {
    values: PartialSearchFormValues;
    resultsRoute: {
        routeId: string;
        params?: Record<string, string>;
    };
};
export type UseDirectSubmitSearchValuesOptions = Omit<
    UseMutationOptions<UseDirectSubmitSearchData, UseDirectSubmitSearchError, UseDirectSubmitSearchVariables>,
    'mutationKey' | 'mutationFn'
>;

export const useDirectSubmitSearchValues = (options?: UseDirectSubmitSearchValuesOptions) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useRouteNavigate();
    return useMutation<UseDirectSubmitSearchData, UseDirectSubmitSearchError, UseDirectSubmitSearchVariables>({
        ...options,
        mutationKey: ['availability'],
        mutationFn: async ({ values, resultsRoute }) => {
            if (!validateSearchValues(values)) {
                throw new Error('Invalid search values');
            }
            Events.trackBookingInitiated(values);
            rentalTripSearchStore.setValues(values);
            const queryString = rentalTripSearchStore.convertValuesToQueryString(values);
            const tripSearchParams = createSearchParams({
                ...searchParams,
                ...(queryString ? Object.fromEntries(createSearchParams(queryString)) : undefined),
            } as Record<string, string>);
            if (tripSearchParams.has('action')) {
                tripSearchParams.delete('action');
            }
            setSearchParams(tripSearchParams);
            const response = await rentalTripSearchStore.performSearch();
            if (!response?.Data) {
                throw new Error(response?.Message || 'Search request failed');
            }
            const isRelocationRedirect = availabilityStore.shouldAutoSelectRelocation();
            if (!isRelocationRedirect) {
                Events.trackProductImpressions(rentalTripSearchStore.pickUpLocation?.BusinessUnit, availabilityStore.availableFleetCategories);
                navigate(resultsRoute.routeId, {
                    params: {
                        ...resultsRoute.params,
                        fleetType: availabilityStore?.fleetType?.slug,
                    },
                    search: tripSearchParams,
                });
                rentalTripSearchStore.setValues(values);
                return response;
            }

            runInAction(() => {
                availabilityStore.state = 'loading';
            });
            availabilityStore.setSelectedFleetCategory(availabilityStore.availableFleetCategories[0]);
            const quote = await reservationStore.createQuote({
                tripInfo: availabilityStore.asTripInfo(),
                deal: availabilityStore.selectedDeal,
            });
            runInAction(() => {
                availabilityStore.state = 'done';
            });
            if (quote) {
                navigate('direct-edit-quote', {
                    params: {
                        fleetType: rentalTripSearchStore.fleetType?.slug,
                        reservationReference: quote.ReservationReference,
                        activeTab: 'excess-reduction',
                    },
                    search: tripSearchParams,
                });
            }
            return response;
        },
    });
};
