'use client';
import React from 'react';
import { DialogProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { ModalPaper } from './ModalPaper';
import { DIALOG_WIDTH, DIALOG_WIDTH_WIDER } from './consts';

export interface ModalProps extends DialogProps {
    wider?: boolean;
    title?: string;
    titleIcon?: React.ReactNode;
    showClose?: boolean;
}

export const Modal: React.FC<ModalProps> = ({ title, titleIcon, showClose, classes: dialogClasses, children, onClose, wider, ...props }) => (
    <Dialog
        role="dialog"
        onClose={onClose}
        sx={{
            '& .MuiDialog-paper': {
                overflow: titleIcon ? 'visible' : undefined,
                minWidth: wider ? DIALOG_WIDTH_WIDER : DIALOG_WIDTH,
            },
        }}
        PaperComponent={ModalPaper}
        PaperProps={{ title, icon: titleIcon, showClose, onClose }}
        {...props}
    >
        {children}
    </Dialog>
);
Modal.displayName = 'ModalDialog';
