import { ContentMenuItem } from '@jucy/content-api-client';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import config from '#/config';

export interface UseGetMenuItemsOptions extends Omit<UseQueryOptions<ContentMenuItem[] | undefined>, 'queryKey'> {
    path: string;
}

export type UseGetMenuItemsResponse = Omit<UseQueryResult, 'data'> & {
    items: ContentMenuItem[] | undefined;
};

const fetchMenuItems = async (path: string): Promise<ContentMenuItem[]> =>
    fetch(`${config.dataApiBaseUrl}/app/menu${path}`).then((res) => {
        if (res.ok) {
            return res.json();
        }
        return null;
    });

const useGetMenuItems = ({ path, ...options }: UseGetMenuItemsOptions): UseGetMenuItemsResponse => {
    const { data, ...state } = useQuery<ContentMenuItem[] | undefined, Error>({
        queryKey: [`get-menu-${path}`],
        queryFn: () => fetchMenuItems(path),
        refetchInterval: false,
        refetchOnWindowFocus: false,
        ...options,
    });

    return useMemo(
        () => ({
            items: data || undefined,
            ...state,
        }),
        [data, state]
    );
};

export default useGetMenuItems;
