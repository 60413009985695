import { FleetType, Site, getNow, memoize } from '@jucy-ui/common';
import { addDays, addHours, isAfter, isToday, isWithinInterval, startOfDay } from 'date-fns';
import { getLeadTimeHours } from './getLeadTimeHours';
import { getMinDate } from './getMinDate';
import { getServiceHours } from './getServiceHours';
import { roundTime } from './roundTime';

export const getMinPickUp = memoize(
    ({ date = getNow(), branch, fleetType }: { date?: Date; branch?: Site; fleetType?: FleetType }): Date | undefined => {
        const siteSettings = branch && branch.siteSettings.find((siteSetting) => siteSetting.fleetTypeId === fleetType?.id);
        const serviceHours = siteSettings && getServiceHours({ date, siteSettings });
        const leadTime = getLeadTimeHours({ fleetType });
        if (!branch || !fleetType || !siteSettings || !serviceHours) {
            return getMinDate();
        }

        let result: Date | undefined = serviceHours.open; //Set min pick up time to be the service start time.

        if (siteSettings.isAfterHoursPickUpAvailable) {
            // Only find the min pick up time if out of hours pick ups are unavailable
            if (isToday(date)) {
                // If booking is for today
                const now = getNow();
                if (isWithinInterval(now, { start: serviceHours.open, end: serviceHours.close })) {
                    // If current time is between service hours
                    //Min pick up should be now + fleet lead time, rounded up to the next 30 minutes
                    result = roundTime(startOfDay(addHours(now, leadTime)));
                    if (isAfter(result, serviceHours.close)) {
                        // If rounding takes us outside of pick up hours, set min as tomorrow's service hours start
                        result = getServiceHours({
                            date: addDays(date, 1),
                            siteSettings,
                        })?.open;
                    }
                } else {
                    // If current time is outside of office hours
                    if (isAfter(now, serviceHours.close)) {
                        // If it's past the office close time, min time will be tomorrow's service hours start
                        result = getServiceHours({
                            date: addDays(date, 1),
                            siteSettings,
                        })?.open;
                    } else {
                        // If current time is before open, min time will be today's service hours start
                        result = serviceHours.open;
                    }
                }
            } else {
                // If out of hours is available, and it's not today
                result = startOfDay(date);
            }
        } else if (isToday(date)) {
            // If today and out of hours is available, min pick up should be now + fleet lead time, rounded up to the next 30 minutes
            result = roundTime(addHours(getNow(), leadTime));
            if (isAfter(result, serviceHours.close)) {
                // If rounding takes us outside of pick up hours, set min as tomorrow's service hours start
                result = getServiceHours({
                    date: addDays(date, 1),
                    siteSettings,
                })?.open;
            }
        }
        return result;
    }
);
