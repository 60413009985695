import { observer } from 'mobx-react-lite';
import React, { ReactNode, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useTradedoublerSetConfig } from '../../../../lib/SalesMonitoring/Providers/tradedoubler';
import FleetCategoryAvailability from '../../../../lib/api/model/FleetCategoryAvailability';
import AvailabilityGridRow from './AvailabilityGridRow';
import StarPanel from './StarPanel';

export interface AvailabilityGridRowsProps {
    availableFleetCategories: FleetCategoryAvailability[];
    highlightedCode?: string;
    selecting?: boolean;
    activeCode?: string;
    onSelect?: (fleetCategory: FleetCategoryAvailability) => void;
    title?: ReactNode;
    caption?: ReactNode;
    moreOptionsTitle?: ReactNode;
    moreOptionsCaption?: ReactNode;
    action?: 'create' | 'edit';
}

const AvailabilityGridRows: React.FC<AvailabilityGridRowsProps> = observer(
    ({ onSelect, activeCode, selecting, availableFleetCategories, highlightedCode, title, caption, moreOptionsTitle, moreOptionsCaption, action }) => {
        const fleetCategories = useMemo(() => {
            if (highlightedCode && highlightedCode !== activeCode) {
                return availableFleetCategories.filter((f) => f.CategoryCode !== highlightedCode);
            }
            return availableFleetCategories;
        }, [activeCode, availableFleetCategories, highlightedCode]);
        const highlightedCategory = useMemo(() => {
            if (highlightedCode && highlightedCode !== activeCode) {
                return availableFleetCategories.find((f) => f.CategoryCode === highlightedCode);
            }
            return undefined;
        }, [activeCode, availableFleetCategories, highlightedCode]);
        useTradedoublerSetConfig(28074, {
            products: fleetCategories.map((f) => ({
                id: f.Id,
                price: f.total.value,
                currency: f.total.CurrencyCode,
                name: f.Name,
            })),
            Category_name: fleetCategories?.[0]?.fleetType?.name,
        });
        return (
            <Box mt={2}>
                {title ? (
                    <>
                        <div className="fw-bold h6">{title}</div>
                        <hr />
                    </>
                ) : null}
                {caption ? <Typography mb={2}>{caption}</Typography> : null}
                {highlightedCategory && (
                    <>
                        <AvailabilityGridRow
                            className="animate__animated animate__fadeIn"
                            fleetCategory={highlightedCategory}
                            isLoading={selecting}
                            onSelect={onSelect}
                            isActive={activeCode === highlightedCategory.CategoryCode}
                            isHighlighted={true}
                            action={action}
                        />
                        <Box mt={4}>
                            {moreOptionsTitle ? (
                                <>
                                    <div className="fw-bold h6">{moreOptionsTitle}</div>
                                    <hr />
                                </>
                            ) : null}
                            {moreOptionsCaption ? <Typography mb={2}>{moreOptionsCaption}</Typography> : null}
                        </Box>
                    </>
                )}
                {fleetCategories.map((fleetCategory) => (
                    <AvailabilityGridRow
                        className="animate__animated animate__fadeIn"
                        key={fleetCategory.CategoryCode}
                        fleetCategory={fleetCategory}
                        isLoading={selecting}
                        onSelect={onSelect}
                        isActive={activeCode === fleetCategory.CategoryCode}
                        action={action}
                    />
                ))}
                <StarPanel />
            </Box>
        );
    }
);
AvailabilityGridRows.displayName = 'AvailabilityGridRows';
export default AvailabilityGridRows;
