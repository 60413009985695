'use client';
import React, { useMemo } from 'react';
import { CatalogItem, GalleryItem } from '@jucy-ui/common';
import {GalleryItemProps, ImageGallery} from './ImageGallery';

export interface FleetCategoryImageGalleryProps extends Omit<GalleryItemProps, 'items'>{
    catalogItem: CatalogItem
}

export const FleetCategoryImageGallery: React.FC<FleetCategoryImageGalleryProps> = ({ catalogItem,...props }) => {
    const items: GalleryItem[] = useMemo(
        () =>
            catalogItem.gallery
                .filter((item) => Boolean(item.original))
                .map((item, i) => ({
                    ...item,
                    originalAlt: item.originalAlt || `${i} ${catalogItem.name}`,
                    originalTitle: item.originalTitle || `${i} ${catalogItem.name}`,
                })),
        [catalogItem.gallery, catalogItem.name]
    );
    return items.length ? <ImageGallery items={items} {...props} /> : null;
};
