import { Type, plainToInstance } from 'class-transformer';
import { PartialDeep } from 'type-fest';
import { FleetType } from './FleetType';
import { HirePeriod } from './HirePeriod';
import { JucyRentalsApiStatus } from './JucyRentalsApiStatus';
import { Region } from './Region';

export class SearchFormData {
    @Type(() => HirePeriod)
    hirePeriods?: HirePeriod[];
    @Type(() => Region)
    regions?: Region[];
    @Type(() => FleetType)
    fleetTypes?: FleetType[];
    apiStatus: JucyRentalsApiStatus = {};

    static fromPlain(props: PartialDeep<SearchFormData>): SearchFormData {
        return plainToInstance(SearchFormData, props);
    }
}
