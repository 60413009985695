import { initReactI18next } from 'react-i18next';
import i18n, { TFunction } from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { makeAutoObservable } from 'mobx';
import { Brand } from '../lib/brand';
import { getCookieDomain } from '../lib/getCookieDomain';
import RentalTripSearchStore from './RentalTripSearchStore';
import ReservationStore from './ReservationStore';
import config from '#/config';

const countryToNamespace = (c: string): string | void => {
    if (c === 'New Zealand') {
        return 'nz';
    } else if (c === 'Australia') {
        return 'au';
    }
};

class TranslationStore {
    state: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    t?: TFunction;
    message = '';
    currentLanguage = 'en';
    error?: Error = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    get currentNamespace() {
        let ns = null;
        if (ReservationStore.activeQuote && ReservationStore.activeQuote.PickUpSite) {
            ns = countryToNamespace(ReservationStore.activeQuote.PickUpSite.Country);
        }
        if (!ns && RentalTripSearchStore.pickUpLocation) {
            ns = countryToNamespace(RentalTripSearchStore.pickUpLocation.Country);
        }
        return ns || 'nz';
    }

    async initialize({ brand }: { brand: Brand }): Promise<void> {
        if (this.state === 'pending') {
            this.state = 'loading';
            await i18n
                .use(Backend)
                .use(detector)
                .use(initReactI18next)
                .init({
                    ns: ['au', 'nz'],
                    supportedLngs: ['en', 'de'],
                    defaultNS: 'nz',
                    fallbackLng: {
                        'de-AU': ['de'],
                        'de-DE': ['de'],
                        'de-NZ': ['de'],
                        de_AU: ['de'],
                        de_DE: ['de'],
                        de_NZ: ['de'],
                        default: ['en'],
                    },
                    backend: {
                        loadPath: `${config.dataApiBaseUrl}/app/locales/obe/${brand}/{{ns}}/{{lng}}.json?_=${process.env.NEXT_PUBLIC_APP_VERSION || Date.now()}`,
                    },
                    interpolation: {
                        escapeValue: false,
                    },
                    react: {
                        bindI18n: 'languageChanged loaded',
                        nsMode: 'default',
                    },
                    detection: {
                        caches: ['localStorage', 'cookie'],
                        lookupCookie: 'jucy_locale',
                        cookieMinutes: 10,
                        cookieDomain: getCookieDomain(window.jucy.config.env),
                    },
                })
                .then((t) => {
                    if (i18n.language) {
                        this.currentLanguage = i18n.language;
                    }
                    this.state = 'done';
                    this.t = t;

                    i18n.on('languageChanged', (lang) => {
                        this.currentLanguage = lang;
                    });
                })
                .catch((e) => {
                    this.state = 'error';
                    this.error = e;
                    this.message = 'Failed to load translations';
                });
        }
    }
}

const translationStore = new TranslationStore();
export default translationStore;
