'use client';
import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const JucyLogoComponent = createSvgIcon(
    <path
        d="M1344.61 40.57c5.44 13.93 126.64 322.49 126.64 322.49s-4.49 116.99-5.32 137.33c-.69 17.01 5.99 30.08 25.33 29.8 19.34-.28 113.41-1.95 132.04-1.94 20.14.01 30.11-8.41 28.6-29.08-1.5-20.66-10.19-139.92-10.19-139.92s118.91-306.94 123.54-318.69c4.35-11.04-5.84-27.43-20.75-28.03-14.91-.6-119.07-4.16-129.32-4.38-11.24-.23-19.02 9.13-21.3 18.4-3.63 14.8-28.49 115.17-30.49 123.04-3.92 15.43-9.98 15.43-14.58.37-3.64-11.93-32.52-109.32-37.71-123.36-3.76-10.16-11.18-17.74-22.09-17.68-10.9.06-112.15.35-123.91.7-11.77.35-27.57 12.85-20.5 30.95m-13.93 331.53c13.01 3.9 18.87 14.94 12.05 31.76-19.84 48.99-86.72 158-227.43 129.18-161.36-33.05-180.5-201.57-180.5-272.85S962.04 45.48 1095.26 8.94c154.7-42.43 221.73 78.67 231.47 99.25 8.68 18.34 15.7 37.99-5.55 46.67-21.24 8.68-77.74 31.81-90.52 37-15.33 6.23-24.14-.63-35.76-13.68-13.4-15.05-25.87-23.94-46.96-23.32-23.9.71-63.75 25.23-62.85 108.64.9 83.41 39.11 117.74 67.4 117.48 28.3-.27 41.59-10.59 49.68-24.39 8.09-13.8 17.78-19.02 29.47-15.38 11.68 3.65 88.68 27.79 99.02 30.89M707.85 31.07c-.32 13.24-3.8 220.49-4.79 274.48-1.53 83.87-62.77 84.85-65.64 0-1.83-54.28-.92-261-1.12-274.48-.18-12-10.82-20.77-22.03-20.76-11.22.01-139.36.12-152.21.01-12.85-.11-21.46 8.83-22.36 21.23-.9 12.39-8.73 112.91-6.96 186.89 1.77 73.98 21.86 228.06 110.36 282.2 79.03 48.34 173.12 50.22 252.02-6.65 70.96-51.14 100.62-167.72 100.76-279.44.14-111.73-6.91-173.94-8.04-186.19-1.13-12.25-12.35-20.95-22.69-20.95s-124.62 1.48-136.39 1.9c-11.77.42-20.63 9.77-20.92 21.77M349.19 6.46c15.25-.54 27.71 8.69 29.04 22.62 1.33 13.93 8.71 118.3 7.96 187.87-.75 69.57-4.12 209.85-69.63 272.16-79.57 75.69-267.21 26.7-293.67 19.84C6.33 504.66-1.79 488.2.32 474.02c2.11-14.18 14.23-92.74 16.19-108.76 1.96-16.02 16.28-23.67 27.35-19.86 11.07 3.8 43.92 17.71 72.58 21.57 28.43 3.83 64.84 6.58 87.3-21.25 22.46-27.83 25.95-90.32 22.49-158.04-3.46-67.72-17.28-125.19-18.98-141.16s9.47-30.4 24.78-31.86c15.32-1.46 101.96-7.65 117.15-8.19"
        style={{ fill: '#8dc63f' }}
    />,
    'JucyLogo'
);
export const JucyLogo: React.FC<React.ComponentProps<typeof JucyLogoComponent>> = ({ viewBox, ...props }) => (
    <JucyLogoComponent viewBox={viewBox || '0 0 1766.26 537.82'} {...props} />
);
