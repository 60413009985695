import { getSiteSettings, searchFormValuesToData } from '../lib';
import { useSearchFormFieldValue } from './useSearchFormFieldValue';

export const useBranchSettings = () => {
    const fleetTypeSlug = useSearchFormFieldValue('fleetType');
    const pickUpBranchCode = useSearchFormFieldValue('pickUpBranch');
    const dropOffBranchCode = useSearchFormFieldValue('dropOffBranch');
    const { pickUpBranch, fleetType, dropOffBranch } = searchFormValuesToData({
        fleetType: fleetTypeSlug,
        pickUpBranch: pickUpBranchCode,
        dropOffBranch: dropOffBranchCode,
    });
    return {
        pickUpBranchSettings: pickUpBranch && fleetType && getSiteSettings({ branch: pickUpBranch, fleetType }),
        dropOffBranchSettings: dropOffBranch && fleetType && getSiteSettings({ branch: dropOffBranch, fleetType }),
    };
};
