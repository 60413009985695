/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { SitesSettingsPickUpServiceHoursMessages } from './SitesSettingsPickUpServiceHoursMessages';
import {
    SitesSettingsPickUpServiceHoursMessagesFromJSON,
    SitesSettingsPickUpServiceHoursMessagesFromJSONTyped,
    SitesSettingsPickUpServiceHoursMessagesToJSON,
} from './SitesSettingsPickUpServiceHoursMessages';
import type { SitesSettingsServiceHours } from './SitesSettingsServiceHours';
import {
    SitesSettingsServiceHoursFromJSON,
    SitesSettingsServiceHoursFromJSONTyped,
    SitesSettingsServiceHoursToJSON,
} from './SitesSettingsServiceHours';

/**
 *
 * @export
 * @interface SiteFleetTypeSettings
 */
export interface SiteFleetTypeSettings {
    /**
     *
     * @type {string}
     * @memberof SiteFleetTypeSettings
     */
    fleetTypeSlug?: string;
    /**
     *
     * @type {boolean}
     * @memberof SiteFleetTypeSettings
     */
    afterHoursPickUp?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof SiteFleetTypeSettings
     */
    afterHoursDropOff?: boolean;
    /**
     *
     * @type {Array<SitesSettingsServiceHours>}
     * @memberof SiteFleetTypeSettings
     */
    serviceHours?: Array<SitesSettingsServiceHours>;
    /**
     *
     * @type {Array<SitesSettingsServiceHours>}
     * @memberof SiteFleetTypeSettings
     */
    pickUpServiceHours?: Array<SitesSettingsServiceHours>;
    /**
     *
     * @type {Array<SitesSettingsServiceHours>}
     * @memberof SiteFleetTypeSettings
     */
    dropOffServiceHours?: Array<SitesSettingsServiceHours>;
    /**
     *
     * @type {Array<SitesSettingsPickUpServiceHoursMessages>}
     * @memberof SiteFleetTypeSettings
     */
    pickUpServiceHoursMessages?: Array<SitesSettingsPickUpServiceHoursMessages>;
    /**
     *
     * @type {Array<SitesSettingsPickUpServiceHoursMessages>}
     * @memberof SiteFleetTypeSettings
     */
    dropOffServiceHoursMessages?: Array<SitesSettingsPickUpServiceHoursMessages>;
}

/**
 * Check if a given object implements the SiteFleetTypeSettings interface.
 */
export function instanceOfSiteFleetTypeSettings(value: object): value is SiteFleetTypeSettings {
    return true;
}

export function SiteFleetTypeSettingsFromJSON(json: any): SiteFleetTypeSettings {
    return SiteFleetTypeSettingsFromJSONTyped(json, false);
}

export function SiteFleetTypeSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteFleetTypeSettings {
    if (json == null) {
        return json;
    }
    return {
        fleetTypeSlug: json['fleetTypeSlug'] == null ? undefined : json['fleetTypeSlug'],
        afterHoursPickUp: json['afterHoursPickUp'] == null ? undefined : json['afterHoursPickUp'],
        afterHoursDropOff: json['afterHoursDropOff'] == null ? undefined : json['afterHoursDropOff'],
        serviceHours: json['serviceHours'] == null ? undefined : (json['serviceHours'] as Array<any>).map(SitesSettingsServiceHoursFromJSON),
        pickUpServiceHours:
            json['pickUpServiceHours'] == null ? undefined : (json['pickUpServiceHours'] as Array<any>).map(SitesSettingsServiceHoursFromJSON),
        dropOffServiceHours:
            json['dropOffServiceHours'] == null ? undefined : (json['dropOffServiceHours'] as Array<any>).map(SitesSettingsServiceHoursFromJSON),
        pickUpServiceHoursMessages:
            json['pickUpServiceHoursMessages'] == null
                ? undefined
                : (json['pickUpServiceHoursMessages'] as Array<any>).map(SitesSettingsPickUpServiceHoursMessagesFromJSON),
        dropOffServiceHoursMessages:
            json['dropOffServiceHoursMessages'] == null
                ? undefined
                : (json['dropOffServiceHoursMessages'] as Array<any>).map(SitesSettingsPickUpServiceHoursMessagesFromJSON),
    };
}

export function SiteFleetTypeSettingsToJSON(value?: SiteFleetTypeSettings | null): any {
    if (value == null) {
        return value;
    }
    return {
        fleetTypeSlug: value['fleetTypeSlug'],
        afterHoursPickUp: value['afterHoursPickUp'],
        afterHoursDropOff: value['afterHoursDropOff'],
        serviceHours: value['serviceHours'] == null ? undefined : (value['serviceHours'] as Array<any>).map(SitesSettingsServiceHoursToJSON),
        pickUpServiceHours:
            value['pickUpServiceHours'] == null ? undefined : (value['pickUpServiceHours'] as Array<any>).map(SitesSettingsServiceHoursToJSON),
        dropOffServiceHours:
            value['dropOffServiceHours'] == null ? undefined : (value['dropOffServiceHours'] as Array<any>).map(SitesSettingsServiceHoursToJSON),
        pickUpServiceHoursMessages:
            value['pickUpServiceHoursMessages'] == null
                ? undefined
                : (value['pickUpServiceHoursMessages'] as Array<any>).map(SitesSettingsPickUpServiceHoursMessagesToJSON),
        dropOffServiceHoursMessages:
            value['dropOffServiceHoursMessages'] == null
                ? undefined
                : (value['dropOffServiceHoursMessages'] as Array<any>).map(SitesSettingsPickUpServiceHoursMessagesToJSON),
    };
}
