'use client';
import { ThemeOptions, alpha } from '@mui/material';
import { blue } from '@mui/material/colors';
import createPalette from '@mui/material/styles/createPalette';
import { createThemeColors } from '../lib/createThemeColor';

export const jucyBrandPalette = {
    apple: {
        DEFAULT: '#85C430',
        50: '#DAEFBD',
        100: '#D0EBAC',
        200: '#BEE38C',
        300: '#ABDB6B',
        400: '#99D34A',
        500: '#85C430',
        600: '#669725',
        700: '#486A1A',
        800: '#293D0F',
        900: '#0B1004',
        950: '#000000',
    },
    passionFruit: {
        DEFAULT: '#860271',
        50: '#FC43DF',
        100: '#FC2FDB',
        200: '#FB07D4',
        300: '#D603B5',
        400: '#AE0393',
        500: '#860271',
        600: '#4F0142',
        700: '#170014',
        800: '#000000',
        900: '#000000',
        950: '#000000',
    },
    watermelon: {
        DEFAULT: '#E61E5C',
        50: '#F8C3D4',
        100: '#F6B1C6',
        200: '#F28CAC',
        300: '#EE6791',
        400: '#EA4377',
        500: '#E61E5C',
        600: '#B81447',
        700: '#850F33',
        800: '#530920',
        900: '#20040C',
        950: '#070103',
    },
    lime: {
        DEFAULT: '#CBE722',
        50: '#F2F9C8',
        100: '#EEF7B5',
        200: '#E5F390',
        300: '#DCEF6C',
        400: '#D4EB47',
        500: '#CBE722',
        600: '#A5BC14',
        700: '#788A0F',
        800: '#4C5709',
        900: '#202504',
        950: '#0A0B01',
    },
    orange: {
        DEFAULT: '#FB5A02',
        50: '#FED0B6',
        100: '#FEC3A2',
        200: '#FEA879',
        300: '#FE8E51',
        400: '#FD7429',
        500: '#FB5A02',
        600: '#C34602',
        700: '#8C3201',
        800: '#541E01',
        900: '#1C0A00',
        950: '#010000',
    },
    charcoal: {
        DEFAULT: '#333333',
        50: '#8F8F8F',
        100: '#858585',
        200: '#707070',
        300: '#5C5C5C',
        400: '#474747',
        500: '#333333',
        600: '#171717',
        700: '#000000',
        800: '#000000',
        900: '#000000',
        950: '#000000',
    },
};
export const jucyMuiPalette = createPalette({
    primary: {
        light: jucyBrandPalette.passionFruit[300],
        main: jucyBrandPalette.passionFruit[500],
        dark: jucyBrandPalette.passionFruit[600],
        contrastText: '#ffffff',
    },
    secondary: {
        light: jucyBrandPalette.apple[300],
        main: jucyBrandPalette.apple[500],
        dark: jucyBrandPalette.apple[600],
        contrastText: '#ffffff',
    },
    error: {
        light: jucyBrandPalette.orange[300],
        main: jucyBrandPalette.orange[500],
        dark: jucyBrandPalette.orange[600],
    },
    warning: {
        light: jucyBrandPalette.watermelon[300],
        main: jucyBrandPalette.watermelon[500],
        dark: jucyBrandPalette.watermelon[600],
    },
    info: {
        light: blue[300],
        main: blue[500],
        dark: blue[700],
    },
    success: {
        light: jucyBrandPalette.apple[300],
        main: jucyBrandPalette.apple[500],
        dark: jucyBrandPalette.apple[700],
    },
    charcoal: {
        light: jucyBrandPalette.charcoal[300],
        main: jucyBrandPalette.charcoal[500],
        dark: jucyBrandPalette.charcoal[700],
    },
    light: {
        light: '#ffffff',
        main: '#f7f9fa',
        dark: '#dfe7eb',
        contrastText: jucyBrandPalette.passionFruit[500],
    },
    action: {
        hover: alpha(jucyBrandPalette.apple[500], 0.1),
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
});

export const jucyMuiThemeOptions: ThemeOptions = {
    palette: jucyMuiPalette,
    colors: createThemeColors(jucyMuiPalette),
};
