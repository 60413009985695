import React from 'react';
import { Box } from '@mui/material';

const LaybuyLightbox: React.FC = () => (
    <Box
        component="iframe"
        sx={{
            display: 'block',
            width: '100%',
            height: {
                xs: '100%',
                md: 506,
            },
            overflow: 'hidden',
        }}
        scrolling="no"
        src="https://popup.laybuy.com/"
        title="Pay with Laybuy"
    />
);

export default LaybuyLightbox;
