import { AvailabilitySearchValues } from '@jucy-ui/common';
import { Simplify } from 'type-fest';

export type SearchFormValues = Simplify<AvailabilitySearchValues>;

export type ValidSearchFormValues = {
    driverAge: string;
    dropOffDate: Date;
    dropOffBranch: string;
    dropOffTime: Date;
    fleetType: string;
    pickUpDate: Date;
    pickUpBranch: string;
    pickUpTime: Date;
    promoCode?: string;
    reservationReference?: string;
};

export const searchFormKeys: (keyof ValidSearchFormValues)[] = [
    'pickUpBranch',
    'pickUpDate',
    'pickUpTime',
    'dropOffBranch',
    'dropOffDate',
    'dropOffTime',
    'driverAge',
    'promoCode',
    'fleetType',
];
