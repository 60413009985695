import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { FleetCategory } from '@jucy/rentals-api-client/rentals-api-v3';
import { format, isSameDay } from 'date-fns';
import { useActiveRoute, useSearchParams } from '../../../../hooks';
import { AvailabilitySearchResult, alternateDatesStore } from '../../../../store/AlternateDatesStore';
import availabilityStore from '../../../../store/AvailabilityStore';
import CurrencyStore from '../../../../store/CurrencyStore';
import RouteRegistry from '../../../../store/RouteRegistry';
import { formatCurrency } from '../../../CurrencyFormatter/CurrencyFormatter';

const getAvailabilityLabel = (availability?: FleetCategory['availability']) => {
    if (availability === 'FreeSell') {
        return 'Available';
    }
    if (availability === 'OnRequest') {
        return 'Request';
    }
    return 'Unavailable';
};

const getToolTip = ({ availability, isBookable, fleetCategory }: { availability?: AvailabilitySearchResult; fleetCategory?: FleetCategory; isBookable: boolean }) => {
    const longDate = availability ? format(availability.pickUpDate, 'dd MMM yyyy') : '';
    const isLoading = Boolean(!availability || availability.placeholder);
    const rate = isBookable && !isLoading && fleetCategory?.dailyRate?.value ? ` - ${formatCurrency(CurrencyStore.convertCurrency(fleetCategory.dailyRate))}/day` : '';
    let availabilityLabel = fleetCategory?.availability ? getAvailabilityLabel(fleetCategory.availability) : 'Unknown';
    if (availabilityLabel === 'Request') {
        availabilityLabel = 'maybe available';
    }
    return fleetCategory ? `${longDate} - ${fleetCategory?.name} is ${isLoading ? 'loading' : availabilityLabel} ${rate}` : '';
};

export interface AlternateDateSlideProviderProps {
    availability?: AvailabilitySearchResult;
    fleetCategory?: FleetCategory;
    children?: React.ReactNode;
    isAnimating?: boolean;
}

export interface AlternateDateSlideContext extends AlternateDateSlideProviderProps {
    isAnimating?: boolean;
    tooltip?: string;
    showMarker: boolean;
    availabilityLabel: string;
    isLoading: boolean;
    isBookable: boolean;
    isFreeSell: boolean;
    isInteractive: boolean;
    dailyRate?: string;
    continueLink?: string;
    handleClicked: () => void;
}

export const AlternateDateSlideContext = React.createContext<AlternateDateSlideContext | null>(null);

export const AlternateDateSlideProvider: React.FC<AlternateDateSlideProviderProps> = ({ availability, fleetCategory, children, isAnimating }) => {
    const showMarker = availability && isSameDay(availability.pickUpDate, alternateDatesStore.trip.pickUpDate as Date);
    const isLoading = availability?.placeholder === true;
    const availabilityLabel = isLoading ? 'Loading' : getAvailabilityLabel(fleetCategory?.availability);
    const isBookable = availabilityLabel !== 'Unavailable';
    const isFreeSell = availabilityLabel === 'Available';
    const isInteractive = availability && fleetCategory && !isLoading && !isAnimating;
    const tooltip = getToolTip({ isBookable, availability, fleetCategory });
    const dailyRate = isBookable && fleetCategory?.dailyRate?.value ? formatCurrency(CurrencyStore.convertCurrency(fleetCategory.dailyRate)) : undefined;
    const [searchParams] = useSearchParams();
    const activeRoute = useActiveRoute();
    const activeParams = useParams();
    const continueLink = useMemo(() => {
        if (!isBookable || !availability || !fleetCategory) {
            return undefined;
        }
        searchParams.pickUpDate = format(availability?.pickUpDate, 'yyyy-MM-dd');
        searchParams.dropOffDate = format(availability?.dropOffDate, 'yyyy-MM-dd');
        return RouteRegistry.getPath(activeRoute?.id as string, activeParams, {
            ...(searchParams as Record<string, string>),
        });
    }, [isBookable, availability, fleetCategory, searchParams, activeRoute?.id, activeParams]);

    const handleClicked = useCallback(() => {
        if (availability && fleetCategory) {
            availabilityStore.updateResults(availability);
        }
    }, [availability, fleetCategory]);

    return (
        <AlternateDateSlideContext.Provider
            value={{
                showMarker: Boolean(showMarker),
                availability,
                fleetCategory,
                children,
                isAnimating,
                tooltip,
                availabilityLabel,
                isLoading,
                isBookable,
                isFreeSell,
                isInteractive: Boolean(isInteractive),
                dailyRate,
                continueLink,
                handleClicked,
            }}
        >
            {children}
        </AlternateDateSlideContext.Provider>
    );
};

export const useAlternateDateSlideContext = (): AlternateDateSlideContext => {
    const values = React.useContext(AlternateDateSlideContext);
    if (!values) {
        throw new Error('useAlternateDateSlideContext must be used within a AlternateDateSlideProvider.');
    }
    return values;
};
