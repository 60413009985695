/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { ReservationSummaryHirer } from './ReservationSummaryHirer';
import { ReservationSummaryHirerFromJSON, ReservationSummaryHirerFromJSONTyped, ReservationSummaryHirerToJSON } from './ReservationSummaryHirer';

/**
 *
 * @export
 * @interface ReservationSummary
 */
export interface ReservationSummary {
    /**
     * Reservation unique identifier. This is the "quote id" used by legacy
     * quote APIs ([Create Quote](/swagger/ui/index/#/Reservations/CreateQuote),
     * [Update Quote](/swagger/ui/index/#/Reservations/UpdateQuote),
     * [Confirm Quote](/swagger/ui/index/#/Reservations/ConfirmQuote))
     *
     * @type {string}
     * @memberof ReservationSummary
     */
    id: string;
    /**
     * Booking number visible to hirer
     * @type {string}
     * @memberof ReservationSummary
     */
    bookingNo: string;
    /**
     * reference number to be used when communicating with Jucy
     * @type {string}
     * @memberof ReservationSummary
     */
    ref: string;
    /**
     * Date time reservation was created
     * @type {Date}
     * @memberof ReservationSummary
     */
    created: Date;
    /**
     * Date time reservation was created
     * @type {Date}
     * @memberof ReservationSummary
     */
    updated: Date;
    /**
     *
     * @type {string}
     * @memberof ReservationSummary
     */
    status: ReservationSummaryStatusEnum;
    /**
     * Reference number for agent orders
     * @type {string}
     * @memberof ReservationSummary
     */
    voucherRef?: string;
    /**
     *
     * @type {string}
     * @memberof ReservationSummary
     */
    availability: ReservationSummaryAvailabilityEnum;
    /**
     *
     * @type {Date}
     * @memberof ReservationSummary
     */
    pickUpDate: Date;
    /**
     *
     * @type {Date}
     * @memberof ReservationSummary
     */
    dropOffDate: Date;
    /**
     *
     * @type {string}
     * @memberof ReservationSummary
     */
    pickUpSiteCode: string;
    /**
     *
     * @type {string}
     * @memberof ReservationSummary
     */
    dropOffSiteCode: string;
    /**
     *
     * @type {ReservationSummaryHirer}
     * @memberof ReservationSummary
     */
    hirer?: ReservationSummaryHirer;
}

/**
 * @export
 */
export const ReservationSummaryStatusEnum = {
    Quote: 'quote',
    Confirmed: 'confirmed',
    Cancelled: 'cancelled',
} as const;
export type ReservationSummaryStatusEnum = (typeof ReservationSummaryStatusEnum)[keyof typeof ReservationSummaryStatusEnum];

/**
 * @export
 */
export const ReservationSummaryAvailabilityEnum = {
    Freesell: 'freesell',
    Onrequest: 'onrequest',
    Stopsell: 'stopsell',
} as const;
export type ReservationSummaryAvailabilityEnum = (typeof ReservationSummaryAvailabilityEnum)[keyof typeof ReservationSummaryAvailabilityEnum];

/**
 * Check if a given object implements the ReservationSummary interface.
 */
export function instanceOfReservationSummary(value: object): value is ReservationSummary {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('bookingNo' in value) || value['bookingNo'] === undefined) return false;
    if (!('ref' in value) || value['ref'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('updated' in value) || value['updated'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('availability' in value) || value['availability'] === undefined) return false;
    if (!('pickUpDate' in value) || value['pickUpDate'] === undefined) return false;
    if (!('dropOffDate' in value) || value['dropOffDate'] === undefined) return false;
    if (!('pickUpSiteCode' in value) || value['pickUpSiteCode'] === undefined) return false;
    if (!('dropOffSiteCode' in value) || value['dropOffSiteCode'] === undefined) return false;
    return true;
}

export function ReservationSummaryFromJSON(json: any): ReservationSummary {
    return ReservationSummaryFromJSONTyped(json, false);
}

export function ReservationSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationSummary {
    if (json == null) {
        return json;
    }
    return {
        id: json['id'],
        bookingNo: json['bookingNo'],
        ref: json['ref'],
        created: stringToDate(json['created']),
        updated: stringToDate(json['updated']),
        status: json['status'],
        voucherRef: json['voucherRef'] == null ? undefined : json['voucherRef'],
        availability: json['availability'],
        pickUpDate: stringToDate(json['pickUpDate']),
        dropOffDate: stringToDate(json['dropOffDate']),
        pickUpSiteCode: json['pickUpSiteCode'],
        dropOffSiteCode: json['dropOffSiteCode'],
        hirer: json['hirer'] == null ? undefined : ReservationSummaryHirerFromJSON(json['hirer']),
    };
}

export function ReservationSummaryToJSON(value?: ReservationSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        id: value['id'],
        bookingNo: value['bookingNo'],
        ref: value['ref'],
        created: dateToString(value['created']),
        updated: dateToString(value['updated']),
        status: value['status'],
        voucherRef: value['voucherRef'],
        availability: value['availability'],
        pickUpDate: dateToString(value['pickUpDate']),
        dropOffDate: dateToString(value['dropOffDate']),
        pickUpSiteCode: value['pickUpSiteCode'],
        dropOffSiteCode: value['dropOffSiteCode'],
        hirer: ReservationSummaryHirerToJSON(value['hirer']),
    };
}
