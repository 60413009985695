import { Booking, BookingOptions, ProductLine, TripDetails, TripInfoRequest } from '@jucy/rentals-api-client/rentals-api-v3';
import { fleetCategories } from '@jucy/rentals-common';
import { parseISO } from 'date-fns';
import { autorun, makeAutoObservable, reaction } from 'mobx';
import { clearPersistedStore } from 'mobx-persist-store';
import { PaymentOption } from '../components/PaymentOptions/lib';
import ErrorReporter from '../lib/ErrorReporter';
import { Deal } from '../lib/api/model/Deal';
import { HirerDetailsSummary } from '../lib/api/model/HirerDetailsSummary';
import { toSearchUid } from '../lib/toSearchUid';
import { BookingCart } from '../types/BookingCart';
import { UserMode } from '../types/UserMode';
import FleetTypesStore from './FleetTypesStore';
import rentalTripSearchStore from './RentalTripSearchStore';
import SitesStore from './SitesStore';

export type RentalTripSearchParams = Partial<{
    pickUpLocation: string;
    dropOffLocation: string;
    pickUpDateTime: string;
    dropOffDateTime: string;
    driverAge: string;
    fleetCategoryCode: string;
    campaignCode: string;
    dealId?: string;
    reservationReference?: string;
    reservationId?: string;
    extras: Record<string, string>;
}>;
export type TripStoreState = 'loading' | 'pending' | 'hydrated' | 'error' | 'done';

class TripStore {
    tripInfo?: Partial<TripInfoRequest> = undefined;
    tripDetails?: TripDetails = undefined;
    amendedTripDetails?: TripDetails = undefined;
    state: TripStoreState = 'pending';
    message = '';
    errorTitle = '';
    error?: Error;
    selectedExcess?: ProductLine = undefined;
    selectedExtras: ProductLine[] = [];
    hirerDetails: HirerDetailsSummary = {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        driversLicenceCountry: '',
        acceptedTerms: false,
        mailingList: false,
        numberOfPeople: 1,
        voucherReference: '',
    };
    paymentType?: PaymentOption = undefined;
    convertUrl?: string = undefined;
    reservationReference?: string = undefined;
    reservationId?: number = undefined;
    userMode?: UserMode = undefined;
    action?: 'create' | 'edit' = 'create';
    appReady = false;
    booking?: Booking = undefined;
    options?: BookingOptions = undefined;
    selectedDeal?: Deal = undefined;

    get ready() {
        if (!this.isHydrated || this.state === 'pending') {
            return false;
        }
        if (this.action === 'edit' && (!this.booking || !this.options)) {
            return false;
        }
        return this.appReady && this.state === 'done';
    }

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.state,
            (data) => {
                if (data === 'error') {
                    ErrorReporter.reportError({
                        error: this.error,
                        tags: { store: 'rental-trip-store' },
                    });
                } else {
                    this.error = undefined;
                    this.message = '';
                }
            }
        );

        autorun(() => {
            if (this.isHydrated && this.appReady && this.state === 'pending' && rentalTripSearchStore.state !== 'loading' && this.action === 'create') {
                const activeSearchUid = this.tripInfo?.pickUpLocation && toSearchUid(this.tripInfo);
                const currentSearchUid = rentalTripSearchStore.asSearchUid();
                if (this.tripInfo && activeSearchUid && activeSearchUid !== currentSearchUid) {
                    this.state = 'loading';
                    const tripFleetCategory = this.tripInfo.fleetCategory ? fleetCategories.find((category) => category.code === this.tripInfo?.fleetCategory) : undefined;
                    const fleetType = tripFleetCategory && FleetTypesStore.getFleetTypeById(tripFleetCategory.fleetTypeId);
                    rentalTripSearchStore.setValues({
                        driverAge: this.tripInfo.driverAge ? `${this.tripInfo.driverAge}` : undefined,
                        dropOffDate: this.tripInfo.dropOffDate ? parseISO(this.tripInfo.dropOffDate) : undefined,
                        dropOffLocation: this.tripInfo.dropOffLocation ? SitesStore.getSiteByCode(this.tripInfo.dropOffLocation) : undefined,
                        dropOffTime: this.tripInfo.dropOffDate ? parseISO(this.tripInfo.dropOffDate) : undefined,
                        fleetType: fleetType || undefined,
                        fleetCategory: tripFleetCategory?.code ? tripFleetCategory?.code : undefined,
                        pickUpDate: this.tripInfo.pickUpDate ? parseISO(this.tripInfo.pickUpDate) : undefined,
                        pickUpLocation: this.tripInfo.pickUpLocation ? SitesStore.getSiteByCode(this.tripInfo.pickUpLocation) : undefined,
                        pickUpTime: this.tripInfo.pickUpDate ? parseISO(this.tripInfo.pickUpDate) : undefined,
                        promoCode: this.tripInfo.campaignCode ? this.tripInfo.campaignCode : undefined,
                    });
                    rentalTripSearchStore.performSearch().catch();
                    this.state = 'done';
                }
            }
        });
    }

    summary?: BookingCart = undefined;

    get isHydrated() {
        return true;
    }

    async init({ state, action }: { state: TripStoreState; action?: 'create' | 'edit' } = { state: 'pending' }) {
        await clearPersistedStore(this);
        if (action) {
            this.action = action;
        }
        this.tripInfo = undefined;
        this.amendedTripDetails = undefined;
        this.tripDetails = undefined;
        this.state = state || 'pending';
        this.message = '';
        this.error = undefined;
        this.selectedExcess = undefined;
        this.selectedExtras = [];
        this.hirerDetails = {
            firstName: '',
            lastName: '',
            mobileNumber: '',
            email: '',
            driversLicenceCountry: '',
            acceptedTerms: false,
            mailingList: false,
            numberOfPeople: 1,
        };
        this.paymentType = undefined;
        this.convertUrl = undefined;
        this.reservationReference = undefined;
        this.reservationId = undefined;
    }
}

const createTripStore = new TripStore();

export default createTripStore;
