import { useMemo } from 'react';
import { NavigateOptions, URLSearchParamsInit, useNavigate } from 'react-router-dom';
import { Hash, To } from 'history';
import { mergeSearchParams } from '../lib/mergeSearchParams';
import RouteRegistry from '../store/RouteRegistry';
import { useSearchParams } from './useSearchParams';

export type UseRouteNavigateOptions = NavigateOptions & {
    params?: Record<string, string | undefined | null>;
    search?: URLSearchParamsInit;
    hash?: Hash;
    keepSearch?: boolean;
};
type UseRouteNavigateFunction = (id: string | number, options?: UseRouteNavigateOptions) => void;
export const useRouteNavigate = (): UseRouteNavigateFunction => {
    const navigate = useNavigate();
    const [activeSearchParams] = useSearchParams();
    return useMemo(
        () => (id: string | number, options?: UseRouteNavigateOptions) => {
            const { params, keepSearch, search, hash, ...navOptions } = options || {};
            const to =
                typeof id === 'number'
                    ? id
                    : {
                          pathname: RouteRegistry.getPath(id, params),
                          search: mergeSearchParams(keepSearch ? (activeSearchParams as URLSearchParamsInit) : undefined, search).toString(),
                          hash: hash,
                      };
            navigate(to as unknown as To, navOptions);
        },
        [activeSearchParams, navigate]
    );
};
