import Quote from '#/lib/api/model/Quote';
import { BusinessUnitGSTCalculator } from '#/lib/BusinessUnitGSTCalculator';

export const getCommissionableTotal = (
    quote: Quote
): {
    total: number;
    discount: number;
} => {
    if (!quote.PickUpSite || !quote.DropOffSite) {
        throw new Error('PickUpSite or DropOffSite is missing');
    }
    const ccFeeTotal =
        ((quote.Lines || []).filter((l) => l.ProductCode === 'CCFee').reduce((acc, l) => acc + l.Total.Value, 0) || 0) +
        (quote.payments || []).reduce((acc, l) => acc + (l?.merchantFeeAmount?.value || 0), 0);
    const total = (quote.TotalPrice.Value || quote.TotalPrice.value) + quote.totalDiscountAmount - ccFeeTotal;
    const discount = quote.totalDiscountAmount;
    const gstCalculator = BusinessUnitGSTCalculator.create(quote.PickUpSite.BusinessUnit);
    return {
        total: gstCalculator.removeGST(total),
        discount: gstCalculator.removeGST(discount),
    };
};
