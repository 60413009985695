'use client';
import React, { useCallback } from 'react';
import {cn, setTime} from '@jucy-ui/common';
import { useController, useFormContext } from '@jucy-ui/forms';
import { PaperProps } from '@mui/material';
import { TimeSelect, TimeSelectProps } from '../../fields';
import { SearchFromTimeSelectMenuPaper } from '../components';
import { useGetMinMaxTime } from '../hooks';
import {jucySearchFormClasses} from '../lib/jucySearchFormClasses';
import { SearchFormValues } from '../types';

export interface SearchFormTimeSelectProps extends Omit<TimeSelectProps, 'value' | 'onBlur' | 'onChange' | 'useShouldDisableTime'> {
    name: string;
}

export const SearchFormTimeSelect: React.FC<SearchFormTimeSelectProps> = ({ name,className, ...props }) => {
    const { getValues, setValue } = useFormContext<SearchFormValues>();
    const {
        field: { onChange, ...field },
    } = useController({ name: name });
    const handleChange = useCallback(
        (time: Date | null) => {
            const dateFieldName = name === 'pickUpTime' ? 'pickUpDate' : 'dropOffDate';
            const dateValue = getValues(dateFieldName);
            const timeValue = time ? setTime(dateValue, time) : time;
            onChange(timeValue);
            if (timeValue) {
                setValue(dateFieldName, timeValue, { shouldValidate: false, shouldTouch: false });
            }
        },
        [getValues, name, onChange, setValue]
    );
    const { minTime, maxTime } = useGetMinMaxTime(name === 'pickUpTime' ? 'start' : 'end');
    return (
        <TimeSelect
            minTime={minTime}
            maxTime={maxTime}
            onChange={handleChange}
            className={cn(jucySearchFormClasses.timeSelect, name === 'pickUpTime' ?jucySearchFormClasses.pickUpTime : jucySearchFormClasses.dropOffTime,className)}
            {...field}
            {...props}
            MenuProps={{
                slots: {
                    paper: SearchFromTimeSelectMenuPaper,
                },
                slotProps: {
                    paper: {
                        position: name === 'pickUpTime' ? 'start' : 'end',
                    } as PaperProps,
                },
            }}
        />
    );
};
