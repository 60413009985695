import React from 'react';
import { useBrand } from '#/store/BrandStore';
import { Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { mergeSx } from '#/lib/mergeSx';

export interface SpinnerProps {
    spinnerStyle?: string;
    color?: string;
    center?: boolean;
    className?: string;
    text?: React.ReactNode;
    sx?: SxProps<Theme>;
    id?: string;
}

const Spinner: React.FC<SpinnerProps> = React.forwardRef<HTMLDivElement, SpinnerProps>(({ id, sx, spinnerStyle = 'huge-wave-in', color: propsColor, center, className, text }, ref) => {
    const brand = useBrand();
    const color = propsColor ? propsColor : brand.primaryColorName;
    return (
        <Stack sx={mergeSx({ flex: '1 1' }, sx)} className={className} alignItems={center ? 'center' : undefined} ref={ref}>
            <div id={id} className={clsx('loader-spinner', color, spinnerStyle)} />
            {text ? text : null}
        </Stack>
    );
});
Spinner.displayName = 'Spinner';

export default Spinner;
