import { RecursivelyReplaceNullWithUndefined, includes, nullToUndefined } from '@jucy-ui/common';
import { ConditionalPick } from 'type-fest';
import { Maybe } from 'yup';
import { searchFormRepoStore } from '../store';
import { SearchFormData, SearchFormValues } from '../types';

export const searchFormValuesToData = (values: Maybe<Partial<SearchFormValues>>): Partial<RecursivelyReplaceNullWithUndefined<SearchFormData>> => {
    const result: Partial<RecursivelyReplaceNullWithUndefined<SearchFormData>> = {};
    if (!values) {
        return result;
    }

    for (const [key, value] of Object.entries(values)) {
        if (key === 'pickUpBranch' || key === 'dropOffBranch') {
            result[key] = searchFormRepoStore.branches.find((branch) => branch.siteCode === value);
        } else if (key === 'fleetType') {
            result[key] = searchFormRepoStore.fleetTypes.find((fleetType) => fleetType.slug === value);
        } else if (includes(['pickUpDate', 'pickUpTime', 'dropOffDate', 'dropOffTime'] as const, key) && value instanceof Date) {
            result[key] = value;
        } else if (typeof value === 'string') {
            type stringKeys = keyof ConditionalPick<SearchFormData, string>;
            result[key as stringKeys] = value;
        }
    }

    return nullToUndefined(result);
};
