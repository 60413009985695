import { runInAction } from 'mobx';
import JucyAPI from '../lib/JucyAPI.js';
import { Deal } from '../lib/api/model/Deal';

class DealStore {
    deals: Record<string, Deal> = {};
    state = 'pending';

    async fetchDeal(dealId: string) {
        const deal = this.isValidDealId(dealId) ? ((await JucyAPI.getDeal(dealId)) as unknown as Deal) : undefined;
        if (deal) {
            runInAction(() => {
                this.deals[dealId] = new Deal(deal);
                this.state = 'done';
            });
        }
        return this.deals[dealId];
    }

    isValidDealId(dealId?: string) {
        return Boolean(dealId && dealId !== '00000000-0000-0000-0000-000000000000');
    }
}

export default new DealStore();
