import { UpdateBookingValues, ValidateUpdateReservationRequest } from '../models';
import { mapSearchValuesToRequest } from './mapSearchValuesToRequest';

export const mapUpdateBookingValuesToRequest = (legacyFleetCategory: UpdateBookingValues): ValidateUpdateReservationRequest => ({
    reservationRef: legacyFleetCategory.reservationReference,
    updateReservationRequest: {
        ...legacyFleetCategory,
        ...mapSearchValuesToRequest(legacyFleetCategory),
        driverAge: legacyFleetCategory.driverAge ? Number(legacyFleetCategory.driverAge) : undefined,
    },
});
