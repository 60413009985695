import React from 'react';
import { Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { mergeSx } from '#/lib/mergeSx';

interface LoadingAnimationProps {
    variant?: 'primary' | 'secondary' | 'alt';
    size?: 'small' | 'normal' | 'large';
    className?: string;
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
    id?: string;
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ id, sx, variant = 'primary', size = 'normal', className, children }) => {
    const containerClass = 'loader-container';
    return (
        <Stack
            alignItems="center"
            sx={mergeSx({ width: '100%' }, sx)}
            className={clsx(className, variant, size, containerClass, {
                [`${containerClass}-small`]: size === 'small',
                [`${containerClass}-large`]: size === 'large',
            })}
        >
            <div id={id} className={clsx('loader', variant)}></div>
            {children}
        </Stack>
    );
};

export default LoadingAnimation;
