import { Brand } from './Brand';
import { brands } from './brands';

export const stringToBrand = (value: unknown): Brand | undefined => {
    if (typeof value === 'string') {
        for (const brand of brands) {
            if (value.toLowerCase().includes(brand)) {
                return brand;
            }
        }
    }
    return undefined;
};
