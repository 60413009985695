const SimpleTemplateParser = (template: string, data: Record<string, string>): string => {
    const tokens = template.match(/(\$([a-z]+))/gi);
    let result = template;
    if (tokens) {
        tokens.forEach((token) => {
            const prop = token.replace(/^\$/, '');
            result = result.replace(token, data[prop]);
        });
    }
    return result;
};

export default SimpleTemplateParser;
