import React from 'react';
import Select from './Select';

const LocationSelect = ({ options, ...props }) => {
    options = [
        {
            label: 'New Zealand',
            options: options.filter((l) => l.Country === 'New Zealand'),
        },
        {
            label: 'Australia',
            options: options.filter((l) => l.Country === 'Australia'),
        },
    ];

    return <Select getOptionValue={(site) => site.SiteCode} getOptionLabel={(site) => site.Name} options={options} {...props} />;
};

export default LocationSelect;
