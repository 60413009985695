'use client';
import { useContext } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormSchemaContext } from '../FormSchemaProvider';
import { FormSchema } from '../lib';

export const useFormSchemaContext = <SchemaShape extends FieldValues>() => {
    const schema = useContext(FormSchemaContext);
    return schema as FormSchema<SchemaShape>;
};
