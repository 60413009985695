'use client';
import { CurrencyValue } from '../services/models/CurrencyValue';

export const getCurrencyFormatter = (input: CurrencyValue) =>
    new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: input.currencyCode,
    });
export const formatCurrency = (input: CurrencyValue): string => {
    const formatter = getCurrencyFormatter(input);
    return formatter.format(input.value).replace(/^(-?)[A-Z]+/, '$1');
};
export const getCurrencyComponents = (input: CurrencyValue): Intl.NumberFormatPart[] => {
    const formatter = getCurrencyFormatter(input);
    return formatter.formatToParts(input.value);
};
