export const fleetCategoryColors: Record<string, string | undefined> = {
    '8a71c203-6c2f-44e1-8ba2-ce6d2ff11529': '#67af1e',
    '23f99f11-d120-44a6-a5be-892fc80a6e33': '#361eae',
    '9a634f4f-7638-42eb-aaef-e2a67b233d1d': '#860271',
    '8af45371-5d29-4d47-af2f-538803822369': '#e61e5c',
    'e88597a4-8afd-11e9-bc42-526a1b000000': '#fb5a02',
    '61b3e228-bb25-41af-9e97-f49ae1c4d32a': '#1b9e77',
    '69932050-6667-4d56-ac15-733f824ea168': '#7570b3',
    '198236bc-8c53-4c68-bed1-c22003e41a6f': '#e6ab02',
    'eebeeb0a-881b-4490-9aed-5772b5352715': '#a6761d',
    'ab64e8d1-1f13-4b72-b8f3-8c17e11727e7': '#666666',
    'ed180518-cad1-11e9-8cd6-ef6b9e9209cf': '#96cd4f',
    'd58d93fd-eb88-e611-80ed-c4346bc5779c': '#560042',
    'ac59dd1b-ec88-e611-80ed-c4346bc5779c': '#028818',
    '1df4858e-94ca-e811-8162-480fcff12ac1': '#1ee6a7',
    'ab1a650b-eb88-e611-80ed-c4346bc5779c': '#03a5fc',
    '8277c129-eb88-e611-80ed-c4346bc5779c': '#9d1b42',
    '4af6dec8-ea88-e611-80ed-c4346bc5779c': '#afb36f',
    '9f9f109d-eb88-e611-80ed-c4346bc5779c': '#023ee3',
    'ee8a6517-ec88-e611-80e7-c4346bc5b2d4': '#8650ce',
    'eb1bb697-ec88-e611-80e7-c4346bc5b2d4': '#005714',
    'fd59b647-ec88-e611-80e7-c4346bc5b2d4': '#710288',
    '0e0a6a09-df69-e811-8148-e0071b686a81': '#a5fc03',
    '359cef45-df69-e811-8148-e0071b686a81': '#b0951e',
    'f09bb2e2-a1bf-e911-a85f-000d3ae023c2': '#667656',
};
