import { PaymentConfig } from '@jucy/rentals-api-client/rentals-api-v3';
import { JucyCountryCode } from '@jucy/rentals-common';
import { isAfter, isSameDay, parseISO } from 'date-fns';
import { makeAutoObservable } from 'mobx';
import { PaymentOption, PaymentOptionAvailableOptions } from '../components/PaymentOptions/lib';
import * as paymentOptions from '../components/PaymentOptions/paymentMethods';
import config from '../config';
import { Site } from '../lib/api/model/Site';
import { getPaymentConfig } from '../lib/getJucyRentalsApiClient';
import { cache, CACHE_TTL } from '../services/cache';
import { BookingCart } from '../types/BookingCart';
import AccountKeyStore from './AccountKeyStore';
import analyticsStore from './AnalyticsStore';

const fetchPaymentConfig = async (region: string): Promise<PaymentConfig> => {
    let result = await cache.get<PaymentConfig>(`payment-config-${region}`);
    if (!result) {
        result = await getPaymentConfig(region);
        if (result) {
            cache.set(`payment-config-${region}`, result, CACHE_TTL.FIVE_MIN);
        }
    }
    return result;
};

const getDefaultGateway = () => (process.env.NEXT_PUBLIC_DEFAULT_PAYMENT_GATEWAY || 'stripe') as 'stripe' | 'dps';

class PaymentStore {
    state: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    paymentConfigs: Partial<Record<JucyCountryCode, PaymentConfig>> = {};
    defaultGateway: 'stripe' | 'dps' = 'stripe';
    constructor() {
        makeAutoObservable(this);
        this.defaultGateway = getDefaultGateway();
    }

    async initialize(): Promise<void> {
        if (this.state === 'pending') {
            this.defaultGateway = getDefaultGateway();
            await Promise.all(
                Object.values(JucyCountryCode).map(async (region) => {
                    this.paymentConfigs[region] = await fetchPaymentConfig(region);
                })
            );
        }
    }

    getConfig(region: JucyCountryCode): PaymentConfig | undefined {
        return this.paymentConfigs[region];
    }

    getPaymentOptions(summary?: BookingCart): PaymentOption[] {
        const apiPaymentConfig = this.paymentConfigs[(summary?.pickUpLocation as Site)?.CountryCode];
        if (!summary || !AccountKeyStore.paymentEnabled || !apiPaymentConfig) {
            return [];
        }
        const paymentConfig = {
            ...apiPaymentConfig,
            defaultGateway: this.defaultGateway,
        };
        const countryCode = summary?.pickUpLocation?.CountryCode;
        const trackingId = analyticsStore.linkerParam || undefined;
        const allOptions: PaymentOption[] = [
            paymentOptions.getDepositPaymentOption({ summary, trackingId, countryCode, paymentConfig }),
            paymentOptions.getFullPaymentOption({ summary, trackingId, countryCode, paymentConfig }),
            paymentOptions.getPayOnPickUpPaymentOption({ summary, trackingId, countryCode, paymentConfig }),
            paymentOptions.getLaybuyPaymentOption({ summary, trackingId, countryCode, paymentConfig }),
            paymentOptions.getAfterpayPaymentOption({ summary, trackingId, countryCode, paymentConfig }),
        ];
        const availableParameters: PaymentOptionAvailableOptions = {
            enableFullPayment: config.enableFullPayment || false,
            enableDepositPayment: config.enableDepositPayment || false,
            enablePayOnPickup: config.enablePayOnPickup || false,
            forcePayOnPickupOnly:
                config.payOnPickupOnlyStartDate && summary.pickUpDate
                    ? isSameDay(summary.pickUpDate, parseISO(config.payOnPickupOnlyStartDate)) || isAfter(summary.pickUpDate, parseISO(config.payOnPickupOnlyStartDate))
                    : false,
            paymentConfig: paymentConfig,
            summary,
        };
        return allOptions.filter((o) => o.available(availableParameters));
    }

    getActivePaymentOption(summary: BookingCart) {
        return this.getPaymentOptions(summary).find((o) => o.id === summary.paymentTypeId);
    }
}

const paymentStore = new PaymentStore();
export default paymentStore;
