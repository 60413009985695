import { isAbortError } from '#/lib/isAbortError';
import { JucyCountryCode } from '@jucy/rentals-common';
import { action, makeAutoObservable, reaction, runInAction } from 'mobx';
import { SuperAgentRequest } from 'superagent';
import { CartItem } from '../lib/CartItem';
import ErrorReporter from '../lib/ErrorReporter';
import JucyAPI from '../lib/JucyAPI.js';
import { ExtendedFleetCategory } from '../lib/api/model/ExtendedFleetCategory';
import type { CatalogItem } from '../lib/api/model/RentalCatalog';
import { resolveError } from '../lib/resolveError';
import { CACHE_TTL, cache } from '../services/cache';

class ProductStore {
    state: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    message = '';
    error?: Error;
    products = [];
    rentalCatalog: CatalogItem[] = [];
    extendedRentalCatalog: ExtendedFleetCategory[] = [];
    lastRequest?: SuperAgentRequest;
    lastProductMap?: CartItem[];

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.state,
            (data) => {
                if (data === 'error') {
                    ErrorReporter.reportError({ error: this.error, tags: { store: 'product' } });
                } else {
                    this.error = undefined;
                    this.message = '';
                }
            }
        );
    }

    async initialize() {
        await this.fetchRentalCatalog();
    }

    getByRegionalCode(region: string, code: string) {
        return this.rentalCatalog.find((product) => product.region === region && product.code === code);
    }

    getQuoteProductPricing(reservationReference: string, productMap: CartItem[]) {
        if (this.lastRequest) {
            this.lastRequest.abort();
        }
        this.state = 'loading';
        this.message = '';
        this.lastProductMap = productMap;
        this.lastRequest = JucyAPI.getQuoteProductPricing({
            ref: reservationReference,
            priceRequest: productMap,
        });
        return this.lastRequest
            .then((res) => res.body)
            .then(
                action('fetchSuccess', (data) => {
                    if (data.productPrices?.length) {
                        this.products = data.productPrices;
                    }
                    this.state = 'done';
                    this.lastProductMap = undefined;
                    return data;
                })
            )
            .catch((e) =>
                resolveError(e).then(({ error, message }) => {
                    if (isAbortError(error)) {
                        return;
                    }

                    runInAction(() => {
                        this.state = 'error';
                        this.error = error;
                        this.message = message;
                    });

                    return error;
                })
            );
    }

    async fetchRentalCatalog() {
        this.state = 'loading';
        this.message = '';
        this.rentalCatalog = [];
        this.extendedRentalCatalog = [];
        const catalogCacheKey = 'rental-catalog-2023-07-24';
        const cacheResult = await cache.get<{ catalog: CatalogItem[]; extendedCatalog: ExtendedFleetCategory[] }>(catalogCacheKey);
        if (cacheResult && cacheResult.catalog && cacheResult.extendedCatalog) {
            this.rentalCatalog = cacheResult.catalog;
            this.extendedRentalCatalog = cacheResult.extendedCatalog || [];
            this.state = 'done';
        } else {
            try {
                const [catalog, extendedCatalog] = await Promise.all([JucyAPI.getRentalCatalog(), JucyAPI.getRentalCatalog({ extended: true })]);
                runInAction(() => {
                    this.rentalCatalog = catalog.products;
                    this.extendedRentalCatalog = extendedCatalog.products;
                    this.state = 'done';
                    cache.set(
                        catalogCacheKey,
                        {
                            catalog: catalog.products,
                            extendedCatalog: extendedCatalog.products,
                        },
                        CACHE_TTL.ONE_HOUR * 2
                    );
                });
            } catch (e) {
                return resolveError(e).then(({ error, message }) => {
                    runInAction(() => {
                        this.state = 'error';
                        this.error = error;
                        this.message = message;
                    });
                });
            }
        }

        return this.rentalCatalog;
    }

    getProductById(id: string, countryCode: JucyCountryCode = JucyCountryCode.nz): CatalogItem {
        return this.rentalCatalog.find((c) => c.id === id && c.region === countryCode) || ({ highlightedFeatures: [] } as unknown as CatalogItem);
    }

    getProductByCode(code?: string, countryCode: JucyCountryCode = JucyCountryCode.nz): CatalogItem {
        return {
            ...(this.rentalCatalog.find((c) => c.code === code && c.region === countryCode) ||
                ({
                    highlightedFeatures: [],
                } as unknown as CatalogItem)),
            extended: this.extendedRentalCatalog.find((c) => c.code === code && c.region === countryCode),
        };
    }
}

export default new ProductStore();
