import React, { useEffect, useState } from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import { addMinutes, endOfDay, format, isBefore, isSameMinute, isValid, isWithinInterval, startOfDay } from 'date-fns';
import { setTime, setTimeFromString } from '../../../lib/dates';
import Select from './Select';

const generateTimeRange = ({ step, startTime, endTime }) => {
    const now = new Date();
    let start = startTime ? setTime(new Date(), startTime) : startOfDay(now);
    const end = endTime ? setTime(new Date(), endTime) : endOfDay(now);
    const times = [];
    while (isSameMinute(start, end) || isBefore(start, end)) {
        times.push(start);
        start = addMinutes(start, step);
    }
    return times;
};

const Menu = (props) => {
    const { renderInfoPanel, children, ...propsToPass } = props;
    const content = renderInfoPanel && renderInfoPanel(props);
    return (
        <components.Menu {...propsToPass}>
            {children}
            {content && <div className="info-panel">{content}</div>}
        </components.Menu>
    );
};

const TimeSelect = ({ step = 30, start, end, displayFormat = 'HH:mm', enableOutsideHours, renderInfoPanel, className, ...props }) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        setOptions(
            generateTimeRange({
                step,
                startTime: enableOutsideHours ? null : start,
                endTime: enableOutsideHours ? null : end,
            })
        );
    }, [step, start, end, enableOutsideHours]);

    return (
        <Select
            className={classNames(className, 'time-select')}
            getOptionValue={(time) => format(time, 'HHmm')}
            getOptionLabel={(time) => format(time, displayFormat)}
            components={{ Menu: (props) => <Menu {...props} renderInfoPanel={renderInfoPanel} /> }}
            getOptionComponentProps={(props) => {
                const currentTime = props.data;
                if (enableOutsideHours && start && end && isValid(currentTime)) {
                    if (
                        !isWithinInterval(currentTime, {
                            start: setTimeFromString(currentTime, start),
                            end: setTimeFromString(currentTime, end),
                        })
                    ) {
                        props = { ...props, className: 'outside-range' };
                    }
                }
                return props;
            }}
            {...props}
            options={options}
        />
    );
};

export default TimeSelect;
