import { useCallback } from 'react';
import { searchFormRepoStore } from '../store/searchFormRepoStore';

export const useSearchFormData = () => {
    const fleetTypes = searchFormRepoStore.fleetTypes;
    const regions = searchFormRepoStore.regions;
    return {
        getFleetType: useCallback((slug: string) => fleetTypes.find((fleetType) => fleetType.slug === slug), [fleetTypes]),
        getSite: useCallback((siteCode: string) => regions.flatMap((r) => r.sites).find((site) => site.siteCode === siteCode), [regions]),
    };
};
