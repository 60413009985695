'use client';
import React from 'react';
import { DialogProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CardHeader } from './CardHeader';

export interface TitleModalCardProps extends Omit<DialogProps, 'title'> {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
}

export const TitleModalCard: React.FC<TitleModalCardProps> = ({ title, subTitle, children, className, ...dialogProps }) => (
    <Dialog
        className={className}
        sx={{
            border: 'black',
            '& .MuiDialog-paper': {
                overflow: 'visible',
            },
        }}
        {...dialogProps}
    >
        <DialogTitle
            color="primary"
            sx={{
                marginTop: -20,
            }}
        >
            <CardHeader color="primary" title={title} subTitle={subTitle} />
        </DialogTitle>
        <DialogContent dividers={true}>{children}</DialogContent>
    </Dialog>
);
