/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';

/**
 *
 * @export
 * @interface CustomerAddress
 */
export interface CustomerAddress {
    /**
     *
     * @type {string}
     * @memberof CustomerAddress
     */
    street: string;
    /**
     *
     * @type {string}
     * @memberof CustomerAddress
     */
    city?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerAddress
     */
    postCode?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerAddress
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerAddress
     */
    country: string;
}

/**
 * Check if a given object implements the CustomerAddress interface.
 */
export function instanceOfCustomerAddress(value: object): value is CustomerAddress {
    if (!('street' in value) || value['street'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    return true;
}

export function CustomerAddressFromJSON(json: any): CustomerAddress {
    return CustomerAddressFromJSONTyped(json, false);
}

export function CustomerAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAddress {
    if (json == null) {
        return json;
    }
    return {
        street: json['street'],
        city: json['city'] == null ? undefined : json['city'],
        postCode: json['postCode'] == null ? undefined : json['postCode'],
        state: json['state'] == null ? undefined : json['state'],
        country: json['country'],
    };
}

export function CustomerAddressToJSON(value?: CustomerAddress | null): any {
    if (value == null) {
        return value;
    }
    return {
        street: value['street'],
        city: value['city'],
        postCode: value['postCode'],
        state: value['state'],
        country: value['country'],
    };
}
