import { getBranchSupportsFleetType } from '../lib';
import { SearchFormData } from '../types';

export const validatePickUpBranch = ({ pickUpBranch, dropOffBranch, fleetType }: Partial<SearchFormData>): string | undefined => {
    if (!pickUpBranch) {
        return 'Please select a pick up branch';
    }

    if (fleetType && !getBranchSupportsFleetType({ branch: pickUpBranch, fleetType })) {
        return 'Pick up branch does not support this vehicle type';
    }

    if (dropOffBranch && dropOffBranch.countryCode !== pickUpBranch.countryCode) {
        return 'Pick up branch and drop off branch must be in the same country';
    }
};
