/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';

/**
 *
 * @export
 * @interface RentalProduct
 */
export interface RentalProduct {
    /**
     *
     * @type {string}
     * @memberof RentalProduct
     */
    id?: string;
    /**
     * Product display name
     * @type {string}
     * @memberof RentalProduct
     */
    name?: string;
    /**
     * Product long description (html markup)
     * @type {string}
     * @memberof RentalProduct
     */
    description?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RentalProduct
     */
    highlightedFeatures?: Array<string>;
    /**
     * description of applicable vehicle models
     * @type {string}
     * @memberof RentalProduct
     */
    vehicleDescription?: string;
    /**
     * number of seats
     * @type {number}
     * @memberof RentalProduct
     */
    seatCount?: number;
    /**
     * number of people that can comfortably sleep in the vehicle
     * @type {number}
     * @memberof RentalProduct
     */
    sleepCount?: number;
    /**
     *
     * @type {string}
     * @memberof RentalProduct
     */
    sleepDescription?: string;
    /**
     *
     * @type {string}
     * @memberof RentalProduct
     */
    transmission?: string;
    /**
     * description of engine size
     * @type {string}
     * @memberof RentalProduct
     */
    engineSize?: string;
    /**
     *
     * @type {string}
     * @memberof RentalProduct
     */
    bodyStyle?: string;
    /**
     * vehicle weight in kilograms
     * @type {number}
     * @memberof RentalProduct
     */
    weight?: number;
    /**
     * vehicle length in metres
     * @type {number}
     * @memberof RentalProduct
     */
    length?: number;
    /**
     * vehicle width in metres
     * @type {number}
     * @memberof RentalProduct
     */
    width?: number;
    /**
     * Number of small luggage items vehicle may fit
     * @type {number}
     * @memberof RentalProduct
     */
    luggageSmall?: number;
    /**
     * Number of large luggage items vehicle may fit
     * @type {number}
     * @memberof RentalProduct
     */
    luggageLarge?: number;
    /**
     * Full URL to product image
     * @type {string}
     * @memberof RentalProduct
     */
    image?: string;
    /**
     * Full URL to product marketing page
     * @type {string}
     * @memberof RentalProduct
     */
    url?: string;
}

/**
 * Check if a given object implements the RentalProduct interface.
 */
export function instanceOfRentalProduct(value: object): value is RentalProduct {
    return true;
}

export function RentalProductFromJSON(json: any): RentalProduct {
    return RentalProductFromJSONTyped(json, false);
}

export function RentalProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): RentalProduct {
    if (json == null) {
        return json;
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        name: json['name'] == null ? undefined : json['name'],
        description: json['description'] == null ? undefined : json['description'],
        highlightedFeatures: json['highlightedFeatures'] == null ? undefined : json['highlightedFeatures'],
        vehicleDescription: json['vehicleDescription'] == null ? undefined : json['vehicleDescription'],
        seatCount: json['seatCount'] == null ? undefined : json['seatCount'],
        sleepCount: json['sleepCount'] == null ? undefined : json['sleepCount'],
        sleepDescription: json['sleepDescription'] == null ? undefined : json['sleepDescription'],
        transmission: json['transmission'] == null ? undefined : json['transmission'],
        engineSize: json['engineSize'] == null ? undefined : json['engineSize'],
        bodyStyle: json['bodyStyle'] == null ? undefined : json['bodyStyle'],
        weight: json['weight'] == null ? undefined : json['weight'],
        length: json['length'] == null ? undefined : json['length'],
        width: json['width'] == null ? undefined : json['width'],
        luggageSmall: json['luggageSmall'] == null ? undefined : json['luggageSmall'],
        luggageLarge: json['luggageLarge'] == null ? undefined : json['luggageLarge'],
        image: json['image'] == null ? undefined : json['image'],
        url: json['url'] == null ? undefined : json['url'],
    };
}

export function RentalProductToJSON(value?: RentalProduct | null): any {
    if (value == null) {
        return value;
    }
    return {
        id: value['id'],
        name: value['name'],
        description: value['description'],
        highlightedFeatures: value['highlightedFeatures'],
        vehicleDescription: value['vehicleDescription'],
        seatCount: value['seatCount'],
        sleepCount: value['sleepCount'],
        sleepDescription: value['sleepDescription'],
        transmission: value['transmission'],
        engineSize: value['engineSize'],
        bodyStyle: value['bodyStyle'],
        weight: value['weight'],
        length: value['length'],
        width: value['width'],
        luggageSmall: value['luggageSmall'],
        luggageLarge: value['luggageLarge'],
        image: value['image'],
        url: value['url'],
    };
}
