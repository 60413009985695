import { useMemo } from 'react';
import config from '../config';
import appStateStore from '../store/AppStateStore';

export const useRatesUrl = ({ accountKey, countryCode }: { accountKey?: string; countryCode?: string }): URL | null => {
    const valid = Boolean(countryCode && accountKey && appStateStore.mode === 'agent');
    const result = useMemo(() => {
        if (valid) {
            const url = new URL(`/api/v3/agent/${accountKey}/rates?region=${countryCode}`, config.apiBaseUrl);
            url.searchParams.append('_', Date.now().toString());
            return url;
        }
        return null;
    }, [accountKey, countryCode, valid]);
    return valid && result ? result : null;
};
