import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { STALE_TIME } from '../lib/ReactQueryUtils';
import { JucyCountryCode, PaymentConfig, getPaymentConfig, paymentConfig } from '../services';

type UseGetPaymentConfigOptions = UseQueryOptions<PaymentConfig | undefined, Error, PaymentConfig, string[]>;

export const useGetPaymentConfig = (countryCode?: JucyCountryCode, options?: Omit<UseGetPaymentConfigOptions, 'queryKey' | 'queryFn'>) => {
    const enabled = Boolean(countryCode);
    return useQuery({
        queryKey: paymentConfig.details(countryCode),
        queryFn: () => (countryCode ? getPaymentConfig({ countryCode }) : undefined),
        ...options,
        enabled: options?.enabled !== undefined ? options.enabled && enabled : enabled,
        staleTime: options?.staleTime !== undefined ? options.staleTime : STALE_TIME.ONE_HOUR,
    });
};
