'use client';

import React from 'react';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';


export interface JucyTabPanelProps<ValueType=string> extends BoxProps {
    children?: React.ReactNode;
    a11yName: string;
    value: ValueType;
    activeValue: ValueType;
}

const JucyMuiTabPanelRoot = styled(Box, { name: 'JucyTab', slot: 'panel' })(() => undefined);
export const JucyMuiTabPanel = <ValueType = string,>({children, value, a11yName, activeValue, ...props}: JucyTabPanelProps<ValueType>) => (
        <JucyMuiTabPanelRoot
        role="tabpanel"
        hidden={value !== activeValue}
        id={`${a11yName}-tabpanel`}
        aria-labelledby={`${a11yName}-tab-`}
        {...props}
    >
        {value === activeValue ? children : null}
    </JucyMuiTabPanelRoot>
);
