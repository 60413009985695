import { observer } from 'mobx-react-lite';
import React from 'react';
import PaymentSummary from '#/components/BookingSummary/components/PaymentSummary';
import { Box, Stack, useTheme } from '@mui/material';
import classNames from 'classnames';
import { BookingCart } from '../../types/BookingCart';
import { BookingSummaryProvider } from './BookingSummaryContext';
import { AgentSummary, ExtrasSummary, FleetCategorySummary, InsuranceSummary, SummaryActions, TotalsSummary, TripInfoSummary } from './components';
import BookingSummaryHeading from './components/BookingSummaryHeading';
import BuyNowPayLaterSummary from './components/BuyNowPayLaterSummary';

export interface BookingSummaryProps {
    routeId?: string;
    className?: string;
    summary?: BookingCart;
}

const BookingSummaryOld: React.FC<BookingSummaryProps> = observer(({ className = '', summary }) => {
    const theme = useTheme();
    return summary ? (
        <BookingSummaryProvider summary={summary}>
            <Box
                sx={() => ({
                    width: '100%',
                    height: { xs: '100%', md: 'unset' },
                    px: { xs: 2, md: 0 },
                    pb: 2,
                    pt: 1.5,
                    overflow: { xs: 'auto', md: 'unset' },
                    position: 'sticky',
                    top: theme.settings?.appBar.spacer(0),
                })}
                className={classNames('booking-summary', className)}
                data-cy="booking-summary"
            >
                <BookingSummaryHeading />
                <Box component="hr" sx={{ mt: 0.75, mb: 1 }} />
                <Stack>
                    <TripInfoSummary />
                    <FleetCategorySummary />
                    <InsuranceSummary />
                    <ExtrasSummary />
                    <PaymentSummary />
                    <TotalsSummary />
                    <AgentSummary />
                    <BuyNowPayLaterSummary />
                    <SummaryActions />
                </Stack>
            </Box>
        </BookingSummaryProvider>
    ) : null;
});
BookingSummaryOld.displayName = 'BookingSummary';

export default BookingSummaryOld;
