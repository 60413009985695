import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CatalogItem, FleetType, Site, bookingQueryKeys, getBookingEntities } from '../services';

export type BookingEntities = {
    catalogItem?: CatalogItem;
    pickUpBranch?: Site;
    dropOffBranch?: Site;
    fleetType?: FleetType;
};

export type UseGetBookingEntitiesOptions = Omit<UseQueryOptions<BookingEntities, Error, BookingEntities, string[]>, 'queryKey' | 'queryFn'> & {
    booking?: Parameters<typeof getBookingEntities>[0] | null;
};
export const useGetBookingEntities = ({ booking, ...options }: Omit<UseGetBookingEntitiesOptions, 'queryKey' | 'queryFn' | 'initialData'>) => {
    const enabled = Boolean(booking?.pickUpLocation && booking?.dropOffLocation && booking?.fleetCategory?.type && booking?.fleetCategory?.code);

    return useQuery({
        ...options,
        queryKey: bookingQueryKeys.entities(booking?.reservationReference || ''),
        queryFn: async () => {
            const data = booking ? await getBookingEntities(booking) : null;
            return (data || {}) as BookingEntities;
        },
        enabled: options.enabled !== undefined ? options.enabled : enabled,
    });
};
