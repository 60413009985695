import { useFeatureIsOn } from './useFeatureIsOn';
import config from '#/config';
import { useAuth } from '#/hooks/useAuth';

export const useTripApi = () => {
    const { user } = useAuth();
    const enabled = useFeatureIsOn('trip-api');
    if (!config.enableTrip) {
        return false;
    }
    return enabled || user.type === 'staff' || (user?.type === 'agent' && user?.features?.['edit-reservation']);
};
