'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import InfoIcon from '@mui/icons-material/Info';
import { CardProps } from '@mui/material';
import Card from '@mui/material/Card';
import { typographyClasses } from '@mui/material/Typography';
import { Markdown } from './Markdown';

export interface InfoTextProps extends CardProps {
    info?: string;
}

export const InfoText: React.FC<InfoTextProps> = ({ info, children, sx, ...props }) =>
    info || children ? (
        <Card
            variant="outlined"
            elevation={0}
            sx={mergeSx(
                (t) => ({
                    background: t.palette.light.main,
                    p: 2,
                    display: 'flex',
                    fontSize: 'smaller',
                    borderRadius: 1,
                    [`& .${typographyClasses.body2}`]: {
                        fontSize: 'smaller',
                        mb: 1,
                        '&:first-of-type': {
                            mt: '-1px',
                        },
                        '&:last-of-type': {
                            mb: 0,
                        },
                    },
                }),
                sx
            )}
            {...props}
        >
            <InfoIcon sx={{ fontSize: 16, mr: 1 }} />
            <div>
                {info ? <Markdown>{info}</Markdown> : null}
                {children}
            </div>
        </Card>
    ) : null;
