'use client';

import React from 'react';
import { FieldErrors, SubmitErrorHandler, SubmitHandler, UseFormProps, UseFormReturn, useForm } from '@jucy-ui/forms';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import { SearchFormFormComponent } from './components/SearchFormFormComponent';
import { SearchFormFields } from './fields';
import { SearchFormProvider } from './store';
import { SearchFormOptions, SearchFormValues, SearchFormVariants } from './types';
import { searchFormValidationSchema } from './validators';

export interface SearchFormProps
    extends Partial<UseFormProps<SearchFormValues>>,
        Omit<React.HTMLProps<HTMLFormElement>, 'data' | 'onSubmit' | 'onInvalid' | 'ref' | 'as'> {
    onSubmit?: (data: SearchFormValues, form: UseFormReturn<SearchFormValues>) => unknown | Promise<unknown>;
    onInvalid?: (errors: FieldErrors<SearchFormValues>, form: UseFormReturn<SearchFormValues>) => unknown | Promise<unknown>;
    options?: SearchFormOptions;
    variant?: SearchFormVariants;
    sx?: SxProps<Theme>;
}

export const SearchForm = React.forwardRef<HTMLFormElement, SearchFormProps>((props, ref) => {
    const {
        options,
        onInvalid,
        onSubmit,
        validationSchema,
        mode,
        reValidateMode,
        defaultValues,
        values,
        resetOptions,
        context,
        shouldFocusError,
        shouldUnregister,
        shouldUseNativeValidation,
        progressive,
        criteriaMode,
        delayError,
        children,
        variant,
        className,
        ...formProps
    } = props;
    const form = useForm<SearchFormValues>({
        validationSchema: searchFormValidationSchema as UseFormReturn<SearchFormValues>['validationSchema'],
        mode,
        reValidateMode,
        defaultValues,
        values,
        resetOptions,
        context,
        shouldFocusError,
        shouldUnregister,
        shouldUseNativeValidation,
        progressive,
        criteriaMode,
        delayError,
    });
    const handleSubmit: SubmitHandler<SearchFormValues> = (data: SearchFormValues) => onSubmit?.(data, form);
    const handleInvalid: SubmitErrorHandler<SearchFormValues> = (errors: FieldErrors<SearchFormValues>) => onInvalid?.(errors, form);
    return (
        <SearchFormProvider options={options} {...form}>
            <SearchFormFormComponent {...formProps} ref={ref} onSubmit={form.handleSubmit(handleSubmit, handleInvalid)} noValidate>
                <SearchFormFields />
                {children}
            </SearchFormFormComponent>
        </SearchFormProvider>
    );
});
SearchForm.displayName = 'SearchForm';
