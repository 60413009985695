/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { AdditionalDriver } from './AdditionalDriver';
import { AdditionalDriverFromJSON, AdditionalDriverFromJSONTyped, AdditionalDriverToJSON } from './AdditionalDriver';
import type { Customer } from './Customer';
import { CustomerFromJSON, CustomerFromJSONTyped, CustomerToJSON } from './Customer';

/**
 *
 * @export
 * @interface CheckInRequest
 */
export interface CheckInRequest {
    /**
     *
     * @type {string}
     * @memberof CheckInRequest
     */
    reservationReference: string;
    /**
     *
     * @type {string}
     * @memberof CheckInRequest
     */
    numberTravelling?: string;
    /**
     *
     * @type {string}
     * @memberof CheckInRequest
     */
    departureFlightNumber?: string;
    /**
     *
     * @type {string}
     * @memberof CheckInRequest
     */
    arrivalFlightNumber?: string;
    /**
     *
     * @type {Customer}
     * @memberof CheckInRequest
     */
    hirer: Customer;
    /**
     *
     * @type {Array<AdditionalDriver>}
     * @memberof CheckInRequest
     */
    additionalDrivers?: Array<AdditionalDriver>;
}

/**
 * Check if a given object implements the CheckInRequest interface.
 */
export function instanceOfCheckInRequest(value: object): value is CheckInRequest {
    if (!('reservationReference' in value) || value['reservationReference'] === undefined) return false;
    if (!('hirer' in value) || value['hirer'] === undefined) return false;
    return true;
}

export function CheckInRequestFromJSON(json: any): CheckInRequest {
    return CheckInRequestFromJSONTyped(json, false);
}

export function CheckInRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckInRequest {
    if (json == null) {
        return json;
    }
    return {
        reservationReference: json['reservationReference'],
        numberTravelling: json['numberTravelling'] == null ? undefined : json['numberTravelling'],
        departureFlightNumber: json['departureFlightNumber'] == null ? undefined : json['departureFlightNumber'],
        arrivalFlightNumber: json['arrivalFlightNumber'] == null ? undefined : json['arrivalFlightNumber'],
        hirer: CustomerFromJSON(json['hirer']),
        additionalDrivers: json['additionalDrivers'] == null ? undefined : (json['additionalDrivers'] as Array<any>).map(AdditionalDriverFromJSON),
    };
}

export function CheckInRequestToJSON(value?: CheckInRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        reservationReference: value['reservationReference'],
        numberTravelling: value['numberTravelling'],
        departureFlightNumber: value['departureFlightNumber'],
        arrivalFlightNumber: value['arrivalFlightNumber'],
        hirer: CustomerToJSON(value['hirer']),
        additionalDrivers: value['additionalDrivers'] == null ? undefined : (value['additionalDrivers'] as Array<any>).map(AdditionalDriverToJSON),
    };
}
