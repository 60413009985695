import React from 'react';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import Select from './Select';

const AgeSelect = ({ className, ...props }) => {
    const [field] = useField(props);
    const { setFieldValue } = useFormikContext();
    const options = (props.options || []).map((a, i) => ({
        value: a,
        label: `${a}${i + 1 === props.options.length ? '+' : ''}`,
    }));
    const value = options.find((o) => o.value === field.value);

    return <Select className={classNames(className, 'age-select')} {...props} value={value} onChange={(o) => setFieldValue(field.name, o.value)} options={options} />;
};

export default AgeSelect;
