'use client';
import React from 'react';
import { useDelayedLoading } from '@jucy-ui/common';
import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';

export interface LoadingContainerProps extends BoxProps {
    isLoading?: boolean;
    children: React.ReactNode;
    fallback?: React.ReactNode;
    delay?: number;
}

export const LoadingContainer: React.FC<LoadingContainerProps> = ({ isLoading, delay, children, fallback, sx, ...props }) => {
    const loading = useDelayedLoading(isLoading, delay);
    return (
        <LoadingContainerRoot {...props}>
            <Fade in={loading}>
                <LoadingContainerFallbackContainer>{fallback || <LinearProgress />}</LoadingContainerFallbackContainer>
            </Fade>
            <Fade in={!loading} appear={false}>
                <LoadingContainerComponentContainer>{children}</LoadingContainerComponentContainer>
            </Fade>
        </LoadingContainerRoot>
    );
};

const LoadingContainerRoot = styled(Box, {
    name: 'LoadingContainer',
    slot: 'root',
})(() => ({
    position: 'relative',
    minHeight: '20px',
    width: '100%',
    height: '100%',
}));

const LoadingContainerFallbackContainer = styled(Box, {
    name: 'LoadingContainer',
    slot: 'fallback-container',
})(() => ({
    position: 'absolute',
    width: '100%',
}));

const LoadingContainerComponentContainer = styled(Box, {
    name: 'LoadingContainer',
    slot: 'component-container',
})(() => ({
    position: 'relative',
    minHeight: '20px',
    width: '100%',
    height: '100%',
}));
