'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { JucySliderNavButton, JucySliderNavButtonProps } from './JucySliderNavButton';

export interface JucySliderNavigationProps {
    componentProps?: {
        nav?: Partial<JucySliderNavButtonProps>;
        navNext?: Partial<JucySliderNavButtonProps>;
        navPrev?: Partial<JucySliderNavButtonProps>;
    };
}

export const JucySliderNavigation = ({ componentProps }: JucySliderNavigationProps) => (
    <>
        <JucySliderNavButton
            {...componentProps?.nav}
            {...componentProps?.navPrev}
            variant="prev"
            sx={mergeSx(componentProps?.nav?.sx, componentProps?.navPrev?.sx)}
        />
        <JucySliderNavButton
            {...componentProps?.nav}
            {...componentProps?.navNext}
            variant="next"
            sx={mergeSx(componentProps?.nav?.sx, componentProps?.navPrev?.sx)}
        />
    </>
);
