import { getPaymentUniqueKey } from '#/lib/getPaymentUniqueKey';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TranslationStore from '../../../store/TranslationStore';
import { ObeProductLine } from '../../../types/ObeProductLine';
import { formatCurrency } from '../../CurrencyFormatter/CurrencyFormatter';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import BookingSummaryAddOnRow from './BookingSummaryAddOnRow';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';
import BookingSummaryPriceRow from './BookingSummaryPriceRow';

type SortedItem = {
    item: ObeProductLine;
    amendedItem?: ObeProductLine;
};
type SortedItems = {
    items: SortedItem[];
    remaining: ObeProductLine[];
    hasItems: boolean;
};
const useSortedItems = (items?: ObeProductLine[], amendedItems?: ObeProductLine[]) => {
    const sortedItems: SortedItems = {
        items: [],
        remaining: [],
        hasItems: false,
    };
    const seen = new Set<string>();
    if (items) {
        for (const item of items) {
            const amendedItem = amendedItems?.find((amendedItem) => amendedItem.productId === item.productId);
            sortedItems.items.push({
                item,
                amendedItem,
            });
            if (amendedItem) {
                seen.add(amendedItem.productId);
            }
        }
    }
    sortedItems.remaining = amendedItems?.filter((amendedItem) => !seen.has(amendedItem.productId)) || [];
    sortedItems.hasItems = sortedItems.items.length > 0 || sortedItems.remaining.length > 0;
    return sortedItems;
};

const ItemGroup: React.FC<{ items: SortedItems }> = observer(({ items }) =>
    items.hasItems ? (
        <>
            {items
                ? items.items.map(({ item, amendedItem }) => (
                      <BookingSummaryAddOnRow data-cy={`addon-summary-${item.productCode || item.productId}`} item={item} amendedItem={amendedItem} key={item.productId} className="mb-2" />
                  ))
                : null}
            {items
                ? items.remaining.map((item) => (
                      <BookingSummaryAddOnRow data-cy={`addon-amendment-summary-${item.productCode || item.productId}`} item={item} key={`amended-${item.productId}`} className="mb-2" />
                  ))
                : null}
        </>
    ) : null
);
ItemGroup.displayName = 'ItemGroup';

const ExtrasSummary: React.FC = observer(() => {
    const context = useBookingSummaryContext();
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const mandatoryItems = useSortedItems(context.summary.mandatoryItems, (context.summary?.action == 'edit' && context.summary.amendments?.mandatoryItems) || []);
    const selectedExtras = useSortedItems(context.summary.selectedExtras, (context.summary?.action == 'edit' && context.summary.amendments?.selectedExtras) || []);
    const bundledItems = useSortedItems(context.summary.bundledItems, (context.summary?.action == 'edit' && context.summary.amendments?.bundledItems) || []);
    const paymentType = useMemo(() => context.summary.paymentType, [context.summary.paymentType]);
    const payments = context.summary.payments?.length ? context.summary.payments : context.summary.amendments?.payments;
    return mandatoryItems.hasItems || selectedExtras.hasItems || bundledItems.hasItems || paymentType?.merchantFeeRate ? (
        <BookingSummaryGroupItem
            title={t('trip_summary.group.extras_fees') || ''}
            className="animate__animated animate__fadeIn"
            isEditable={Boolean(context.summary.selectedExcess)}
            editRouteParams={{
                activeTab: 'extras',
            }}
        >
            <ItemGroup items={mandatoryItems} />
            <ItemGroup items={selectedExtras} />
            <ItemGroup items={bundledItems} />
            {payments
                ?.filter((p) => p.merchantFeeAmount?.value)
                ?.map((payment) => <BookingSummaryPriceRow title={payment.merchantFeeName} price={payment.merchantFeeAmount} key={getPaymentUniqueKey(payment)} />)}
            {paymentType?.merchantFee ? (
                <BookingSummaryPriceRow
                    title={'Payment merchant fee'}
                    subtitle={paymentType.merchantFeeRate ? `${paymentType.merchantFeeRate * 100}% of ${formatCurrency(paymentType.subTotal)}` : ''}
                    price={paymentType.merchantFee}
                />
            ) : null}
        </BookingSummaryGroupItem>
    ) : null;
});
ExtrasSummary.displayName = 'ExtrasSummary';
export default ExtrasSummary;
