/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';

/**
 * Reservation confirmation body
 * @export
 * @interface IdConfirmBody
 */
export interface IdConfirmBody {
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    mobilePhone: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    country?: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    dateOfBirth?: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    licenseNumber?: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    licenseCountry?: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    licenseExpiry?: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    numberOfPeople?: string;
    /**
     *
     * @type {string}
     * @memberof IdConfirmBody
     */
    arrivalFlight?: string;
    /**
     *
     * @type {boolean}
     * @memberof IdConfirmBody
     */
    deposit?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof IdConfirmBody
     */
    marketingAllowed?: boolean;
}

/**
 * Check if a given object implements the IdConfirmBody interface.
 */
export function instanceOfIdConfirmBody(value: object): value is IdConfirmBody {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('mobilePhone' in value) || value['mobilePhone'] === undefined) return false;
    return true;
}

export function IdConfirmBodyFromJSON(json: any): IdConfirmBody {
    return IdConfirmBodyFromJSONTyped(json, false);
}

export function IdConfirmBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdConfirmBody {
    if (json == null) {
        return json;
    }
    return {
        firstName: json['firstName'],
        lastName: json['lastName'],
        email: json['email'],
        mobilePhone: json['mobilePhone'],
        country: json['country'] == null ? undefined : json['country'],
        dateOfBirth: json['dateOfBirth'] == null ? undefined : json['dateOfBirth'],
        licenseNumber: json['licenseNumber'] == null ? undefined : json['licenseNumber'],
        licenseCountry: json['licenseCountry'] == null ? undefined : json['licenseCountry'],
        licenseExpiry: json['licenseExpiry'] == null ? undefined : json['licenseExpiry'],
        numberOfPeople: json['numberOfPeople'] == null ? undefined : json['numberOfPeople'],
        arrivalFlight: json['arrivalFlight'] == null ? undefined : json['arrivalFlight'],
        deposit: json['deposit'] == null ? undefined : json['deposit'],
        marketingAllowed: json['marketingAllowed'] == null ? undefined : json['marketingAllowed'],
    };
}

export function IdConfirmBodyToJSON(value?: IdConfirmBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        firstName: value['firstName'],
        lastName: value['lastName'],
        email: value['email'],
        mobilePhone: value['mobilePhone'],
        country: value['country'],
        dateOfBirth: value['dateOfBirth'],
        licenseNumber: value['licenseNumber'],
        licenseCountry: value['licenseCountry'],
        licenseExpiry: value['licenseExpiry'],
        numberOfPeople: value['numberOfPeople'],
        arrivalFlight: value['arrivalFlight'],
        deposit: value['deposit'],
        marketingAllowed: value['marketingAllowed'],
    };
}
