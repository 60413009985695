import { GrowthBook, GrowthBookProvider as OGGrowthBookProvider } from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';
import config from '../../config';

export const growthbook = config.growthBookKey
    ? new GrowthBook({
          apiHost: 'https://api.insights.jucy.cloud',
          clientKey: config.growthBookKey,
          enableDevMode: config.env === 'dev',
          enabled: Boolean(config.growthBookKey),
      })
    : undefined;

export const GrowthBookProvider = ({ children }: { children: React.ReactElement }) => {
    useEffect(() => {
        if (growthbook) {
            growthbook.loadFeatures();
        }
    }, []);

    if (growthbook) {
        return <OGGrowthBookProvider growthbook={growthbook}>{children}</OGGrowthBookProvider>;
    }

    return children;
};
