import React from 'react';
import { Params, generatePath } from 'react-router';
import { RouteObject, URLSearchParamsInit, createSearchParams, matchPath } from 'react-router-dom';
import appState from '#/store/AppStateStore';
import { UserMode } from '#/types/UserMode';

const formatQueryString = (queryString: string | null) => {
    if (!queryString) {
        return '';
    }
    return queryString.startsWith('?') ? queryString : `?${queryString}`;
};
export const parsePath = (path: string, params: Params = {}, query?: URLSearchParamsInit) => {
    const queryString = formatQueryString(query ? (typeof query === 'string' ? query : createSearchParams(query).toString()) : null);
    const generatedPath = generatePath(path, params);
    return `${generatedPath}${queryString}`.replace(/\?$/, '');
};

export type RegistryRoute = RouteObject & {
    id: string;
    path: string;
    label?: string;
    showInMenu?: boolean;
    redirect?: string;
    element: React.ReactNode;
    menuRouteId?: string;
    public?: boolean;
    userTypes?: UserMode[];
};

class RouteRegistry {
    routes: RegistryRoute[] = [];
    activeRoute?: RegistryRoute;

    init({ routes }: { routes: RegistryRoute[] }) {
        this.routes = routes;
    }

    setActiveRoute(route: RegistryRoute) {
        this.activeRoute = route;
    }

    isRouteMenuActive(route: RegistryRoute) {
        return route.path === window.location.pathname || this.activeRoute?.id === route.id;
    }

    getRouteById(id: string) {
        return this.routes.find((r) => r.id === id);
    }

    getRoutesByMenuRouteId(id: string) {
        return this.routes.filter((r) => r.menuRouteId === id);
    }

    getRouteByPath(path: string) {
        return this.routes.find((r) => r.path && matchPath(r.path, path));
    }

    absoluteUrlFromPath(path: string) {
        if (!path.replace(/^\//, '').startsWith('obe')) {
            path = `/obe/${path.replace(/^\//, '')}`;
        }
        return new URL(path, window.location.origin).toString();
    }

    getPath(id: string, params: Record<string, string | undefined | null> = {}, query?: URLSearchParamsInit) {
        const routes = this.routes.filter((r) => r.id === id || r.id === `${id}-country`).sort((a, b) => b.path.length - a.path.length);
        if (!params?.mode) {
            params.mode = appState.user.type || 'direct';
        }
        for (const route of routes) {
            try {
                return parsePath(route.path, params as Params, query);
            } catch (e) {
                //
            }
        }
        return '';
    }
}

export default new RouteRegistry();
