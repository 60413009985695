'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { cn } from '@jucy-ui/common/lib/cn';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';


export const JucySliderContainer = React.forwardRef<HTMLDivElement, BoxProps>(({ children, className, sx, ...props }, ref) => (
    <Box
        ref={ref}
        className={cn('blaze-container', className)}
        sx={mergeSx(
            {
                position: 'relative',
            },
            sx
        )}
        {...props}
    >
        {children}
    </Box>
));
JucySliderContainer.displayName = 'JucySliderContainer';
