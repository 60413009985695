'use client';
import React, { useId, useMemo } from 'react';
import { Region, Site, cn, useDelayedLoading, useGetRegions } from '@jucy-ui/common';
import { SelectProps, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectInputLabel } from './SelectInputLabel';
import { DEFAULT_DESKTOP_MODE_MEDIA_QUERY } from './TimeSelect';

export interface BranchSelectProps extends Omit<SelectProps, 'children' | 'ref'> {
    shouldDisableBranch?: (branch: Site) => boolean;
    sortRegion?: (regions: Region[]) =>  Region[];
    desktopModeMediaQuery?: string;
    helperText?: React.ReactNode;
}

export const BranchSelect = React.forwardRef<HTMLSelectElement, BranchSelectProps>(
    ({ className, shouldDisableBranch, id: passedId, value, error, helperText,sortRegion, ...props }, ref) => {
        const { data, isLoading } = useGetRegions();
        const branches = sortRegion?.( data || [])|| data || [];
        const filteredBranches = shouldDisableBranch
            ? branches.map((region) => ({
                  ...region,
                  sites: region.sites.filter((site) => !shouldDisableBranch(site)),
              }))
            : branches;
        const useNative = !useMediaQuery(props.desktopModeMediaQuery || DEFAULT_DESKTOP_MODE_MEDIA_QUERY, { defaultMatches: true });
        const children = useRegionToMenuItems(filteredBranches, { useNative });
        const randId = useId();
        const id = passedId || randId;
        const labelId = `${id}-label`;
        const loading = useDelayedLoading(isLoading, 1000);
        return (
            <FormControl error={error} fullWidth={true} className={cn(className,error?'error':undefined)}>
                <SelectInputLabel id={labelId} htmlFor={id}>
                    {props.label}
                </SelectInputLabel>
                <Select
                    native={useNative}
                    id={id}
                    ref={ref}
                    className={cn('branch-select')}
                    value={value || ''}
                    error={error}
                    {...props}
                    endAdornment={
                        loading ? (
                            <InputAdornment position="end" sx={{ 'span ': { backgroundColor: 'white' } }}>
                                <CircularProgress size={20} />
                            </InputAdornment>
                        ) : undefined
                    }
                >
                    {children}
                </Select>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </FormControl>
        );
    }
);
BranchSelect.displayName = 'BranchSelect';

const useRegionToMenuItems = (regions: Region[], { useNative }: { useNative: boolean }) =>
    useMemo(
        () =>
            regions
                .filter((region) => region.sites.length)
                .map((region, index) =>
                    useNative
                        ? [
                              index === 0 ? <option key="ignored" style={{ display: 'none' }} disabled value=""></option> : null,
                              <optgroup key={region.country} label={region.country}>
                                  {region.sites.map((site) => (
                                      <option key={site.siteCode} value={site.siteCode}>
                                          {site.name}
                                      </option>
                                  ))}
                              </optgroup>,
                          ]
                        : [
                              <ListSubheader key={`sub-header-${region.countryCode}`}>{region.country}</ListSubheader>,
                              region.sites.map((site) => <MenuItem key={site.siteCode} value={site.siteCode}>{site.name}</MenuItem>),
                          ]
                ),
        [regions, useNative]
    );
