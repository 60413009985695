'use client';
import React, { Suspense } from 'react';
import { PopperProps } from '@mui/material';

const SearchFormPickersPopperComponent = React.lazy(() =>
    import('./SearchFormPickersPopperComponent').then((module) => ({ default: module.SearchFormPickersPopperComponent }))
);
export const SearchFormPickersPopper = ({ ...props }: PopperProps) => (
    <Suspense>{props.open ? <SearchFormPickersPopperComponent {...props} /> : null}</Suspense>
);
