import { createThemeOptionsForBrand } from '@jucy-ui/appearance';
import { fleetCategories } from '@jucy/rentals-common';
import { Grid, ThemeProvider, createTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import React from 'react';
import { useParams } from 'react-router';
import config from '../../../../config';
import { useActiveRoute, useSearchParams } from '../../../../hooks';
import FleetCategoryAvailability from '../../../../lib/api/model/FleetCategoryAvailability';
import { FleetType } from '../../../../services';
import AppStateStore from '../../../../store/AppStateStore';
import CurrencyStore from '../../../../store/CurrencyStore';
import fleetTypesStore from '../../../../store/FleetTypesStore';
import productStore from '../../../../store/ProductStore';
import RentalTripSearchStore from '../../../../store/RentalTripSearchStore';
import routeRegistry from '../../../../store/RouteRegistry';
import SitesStore from '../../../../store/SitesStore';
import { JucyButton } from '../../../Button/Button';
import FleetCategoryTeaser from '../Teaser/Teaser';

const validSites = SitesStore.sites.filter((s) => s.SiteSettings?.find((ss) => ss.FleetTypeName.toLowerCase().includes('motorhome'))).map((s) => s.SiteCode);
const polaris6 = FleetCategoryAvailability.fromApi({
    apiFleetCategory: fleetCategories.find((c) => c.code === 'PLS6'),
    catalogData: productStore.getProductByCode('PLS6'),
    fleetType: fleetTypesStore.fleetTypes.find((f) => f.slug === 'motorhome') as FleetType,
});

const useStarUrl = () => {
    const activeParams = useParams();
    const [searchParams] = useSearchParams();

    const activeRoute = useActiveRoute();
    const newPath = activeRoute
        ? `obe${routeRegistry.getPath(activeRoute.id, {
              ...activeParams,
              fleetType: 'motorhome',
          })}`
        : null;

    const redirectUrl = newPath ? new URL(newPath, config.baseUrl.star) : undefined;
    for (const [key, value] of Object.entries(searchParams)) {
        if (value) {
            redirectUrl?.searchParams.append(key, value);
        }
    }
    redirectUrl?.searchParams.append('utm_source', 'jucy-obe');
    redirectUrl?.searchParams.append('utm_content', 'star-panel');

    return redirectUrl;
};

const StarPanel: React.FC = observer(() => {
    //Display logic for the Star panel
    const isDirect = AppStateStore.mode === 'direct';
    const isCampervan = RentalTripSearchStore?.fleetType?.slug === 'campervan';
    const isValidAge = RentalTripSearchStore.driverAge >= '20';
    const isValidLocations =
        RentalTripSearchStore.pickUpLocation && RentalTripSearchStore.dropOffLocation
            ? validSites.includes(RentalTripSearchStore.pickUpLocation.SiteCode) && validSites.includes(RentalTripSearchStore.dropOffLocation.SiteCode)
            : false;
    const shouldShow = isDirect && isCampervan && isValidAge && isValidLocations;

    const redirectUrl = useStarUrl();

    if (productStore?.state === 'loading' || !polaris6 || !shouldShow) {
        return null;
    }

    return (
        <>
            <ThemeProvider theme={createTheme(createThemeOptionsForBrand('star'))}>
                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', padding: '5px' }}>
                    <Grid item xs={2}>
                        <Image src={'/assets/images/star/star-logo.svg'} alt={'Star RV Logo'} width={125} height={40} />
                    </Grid>
                    <Grid item xs={10} sx={{ fontSize: '12px', fontWeight: 'bold', alignItems: 'center' }}>
                        Looking for something bigger? Check out our mates over at Star RV
                    </Grid>
                </Grid>

                <FleetCategoryTeaser
                    fleetCategory={polaris6}
                    components={{
                        Actions: (
                            <a href={`${redirectUrl}`}>
                                <JucyButton
                                    variant="contained"
                                    color={'primary'}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: 'auto',
                                        },
                                        mt: {
                                            xs: 1,
                                        },
                                    }}
                                >
                                    Select
                                </JucyButton>
                            </a>
                        ),
                    }}
                    currencyCode={CurrencyStore.selectedCurrencyCode}
                />
            </ThemeProvider>
        </>
    );
});

export default StarPanel;
