import { ProductLineFromJSON } from '@jucy/rentals-api-client/rentals-api-v3';
import { runInAction } from 'mobx';
import { asMoney } from '../../../components/CurrencyFormatter/CurrencyFormatter';
import { ObeProductLine } from '../../../types/ObeProductLine';
import { getTotalLineValue } from '../../sumLineTotal';
import Money from './Money';

export class LineItem {
    AllowMultiple = false;
    Category = '';
    ID = '';
    ProductCode = '';
    IsMandatory = false;
    IsMaxPrice = false;
    IsExcluded = false;
    Commissionable = false;
    MaxPrice = new Money();
    Name = '';
    Price = new Money();
    ProductId = '';
    ProductType = '';
    Qty = 1;
    RentalDays = 0;
    SortOrder = 0;
    Total = new Money();
    NumberOfDaysCalculated = 0;
    AssociatedProductCode?: string;

    constructor(props?: Partial<LineItem>) {
        Object.assign(this, props);
    }

    CalculateTotal() {
        runInAction(() => {
            let subTotal = this.Price.value * this.Qty;
            this.IsMaxPrice = this.MaxPrice && this.MaxPrice.value > 0 && subTotal > this.MaxPrice.value;
            if (this.IsMaxPrice) {
                subTotal = this.MaxPrice.value;
            }
            const total = this.ProductType === 'Daily' ? subTotal * this.RentalDays : subTotal;

            this.Total.value = parseFloat(total.toFixed(2));
        });
        return this;
    }

    asV3ProductLine(): ObeProductLine {
        const line: ObeProductLine = ProductLineFromJSON({
            productId: this.ProductId,
            productCode: this.ProductCode,
            name: this.Name,
            category: this.Category,
            isMandatory: this.IsMandatory,
            description: '',
            price: asMoney(this.Price),
            productType: this.ProductType,
            allowMultiple: this.AllowMultiple,
            commissionable: this.Commissionable,
            maxPrice: asMoney(this.MaxPrice),
            sortOrder: this.SortOrder,
            qty: this.Qty,
            total: asMoney(this.CalculateTotal().Total),
            rentalDays: this.RentalDays,
            numberOfDaysCalculated: this.NumberOfDaysCalculated,
        });
        line.isExcluded = this.IsExcluded;
        line.total.value = getTotalLineValue(line);
        return line;
    }
}
