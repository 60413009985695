import React, { PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { mergeSx } from '#/lib/mergeSx';

export const PageTitle: React.FC<PropsWithChildren<TypographyProps>> = ({ variant, sx, children, ...props }) => (
    <Typography
        variant={variant || 'h1'}
        sx={mergeSx(
            {
                fontSize: 'calc(1.3125rem + 0.75vw)',
            },
            sx
        )}
        {...props}
    >
        {children}
    </Typography>
);
