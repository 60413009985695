import * as React from 'react';
import { useCallback } from 'react';
import { RadioGroup } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { FleetTypeFormControlLabel } from '../../formFields';

const FleetTypeSelector = ({ fleetTypes, name, onChange, disabled = false }) => {
    const [meta] = useField({ name });
    const { setFieldValue } = useFormikContext();
    const handleChange = useCallback(
        (e, value) => {
            const fleetType = fleetTypes.find((f) => f.slug === value);
            setFieldValue(name, fleetType);
            if (onChange && typeof onChange === 'function') {
                onChange(fleetType);
            }
        },
        [fleetTypes, name, onChange, setFieldValue]
    );
    return (
        <>
            <RadioGroup
                sx={{
                    flexDirection: { sx: 'column', sm: 'row' },
                    width: '100%',
                    gap: { xs: 1, sm: 2 },
                    flexWrap: 'nowrap',
                }}
                onChange={handleChange}
                disabled={disabled}
                value={meta.value}
            >
                {fleetTypes.map((fleetType) => (
                    <FleetTypeFormControlLabel
                        data-cy={`select-fleet-type-${fleetType.slug}`}
                        disabled={disabled}
                        name="fleetType"
                        key={fleetType.slug}
                        fleetType={fleetType}
                        radioProps={{ checked: meta.value?.slug === fleetType.slug }}
                        thumbnailSize={{ width: 'auto', height: { xs: 30, sm: 40 } }}
                        value={meta.value?.slug}
                    />
                ))}
            </RadioGroup>
        </>
    );
};
export default FleetTypeSelector;
