import { Site } from '../../../lib/api/model/Site';
import { FleetType } from '../../../services';

export type SearchFormValues = {
    driverAge: string;
    dropOffDate: Date;
    dropOffLocation: Site;
    dropOffTime: Date;
    fleetType: FleetType;
    fleetCategoryCode?: string;
    pickUpDate: Date;
    pickUpLocation: Site;
    pickUpTime: Date;
    promoCode: string;
    togglePromoCode?: boolean;
};

export const searchFormValueKeys: (keyof SearchFormValues)[] = [
    'driverAge',
    'dropOffDate',
    'dropOffLocation',
    'dropOffTime',
    'fleetType',
    'fleetCategoryCode',
    'pickUpDate',
    'pickUpLocation',
    'pickUpTime',
    'promoCode',
    'togglePromoCode',
];

export type PartialSearchFormValues = Partial<SearchFormValues>;
