import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import TranslationStore from '../../../store/TranslationStore';
import { BookingCart } from '../../../types/BookingCart';
import { useBookingSummaryContext } from '../BookingSummaryContext';

const bookingStatusLabel = (status: BookingCart['bookingStatus']) => {
    switch (status) {
        case 'confirmed':
        case 'returned':
        case 'on-road':
        case 'reservation-confirmed':
            return 'Booking';
        case 'reservation-request':
            return 'Request';
        default:
            return 'Quote';
    }
};
const BookingSummaryHeading: React.FC = () => {
    const context = useBookingSummaryContext();
    const { t } = useTranslation(TranslationStore.currentNamespace);
    return (
        <Typography
            component="h2"
            sx={{
                mb: 0,
                fontSize: 'h6.fontSize',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {t('trip_summary.group.trip_summary')}
            <small>
                {context.summary.reservationId && context.summary.pickUpLocation && context.summary.bookingStatus ? (
                    <>
                        {bookingStatusLabel(context.summary.bookingStatus)} #{context.summary.pickUpLocation.SiteCode}-{context.summary.reservationId}
                    </>
                ) : null}
            </small>
        </Typography>
    );
};
BookingSummaryHeading.displayName = 'BookingSummaryHeading';
export default BookingSummaryHeading;
