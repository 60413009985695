import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormControlLabelProps, RadioProps as MuiRadioProps, RadioGroup, RadioGroupProps } from '@mui/material';
import { FleetType } from '../../../services/models';
import { FieldWrapper, FieldWrapperProps } from '../FieldWrapper';
import { FleetTypeFormControlLabel } from './FleetTypeFormControlLabel';

export interface FleetTypeRadioGroupProps extends FieldWrapperProps {
    fleetTypes: FleetType[];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    thumbnailSize?: { width?: number | 'auto'; height?: number | 'auto' };
    formControlLabelProps?: Partial<Omit<FormControlLabelProps, 'ref'>>;
    radioProps?: Partial<Omit<MuiRadioProps, 'value' | 'onChange'>>;
    radioGroupProps?: Partial<Omit<RadioGroupProps, 'value' | 'onChange'>>;
}

export const FleetTypeRadioGroup = React.forwardRef<HTMLButtonElement, FleetTypeRadioGroupProps>(
    (
        {
            name,
            fleetTypes,
            label,
            helperText,
            formLabelProps,
            formControlLabelProps,
            radioGroupProps,
            radioProps,
            formHelperTextProps,
            thumbnailSize,
            onChange,
            disabled,
            ...formControlProps
        },
        ref
    ) => {
        const value = useWatch({ name });
        return (
            <FieldWrapper
                name={name}
                disabled={disabled}
                formLabelProps={formLabelProps}
                label={label}
                formHelperTextProps={formHelperTextProps}
                helperText={helperText}
                {...formControlProps}
            >
                <RadioGroup name={name} {...radioGroupProps} onChange={onChange} value={value}>
                    {fleetTypes?.map((fleetType) => (
                        <FleetTypeFormControlLabel
                            ref={ref}
                            name={name}
                            key={fleetType.slug}
                            fleetType={fleetType}
                            radioProps={radioProps}
                            thumbnailSize={thumbnailSize}
                            value={value}
                            disabled={disabled}
                            error={formControlProps.error}
                            {...formControlLabelProps}
                        />
                    ))}
                </RadioGroup>
            </FieldWrapper>
        );
    }
);
FleetTypeRadioGroup.displayName = 'FleetTypeRadioGroup';
