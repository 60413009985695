'use client';
import React from 'react';
import { FeatureItem } from '@jucy-ui/common';

export const FeatureIcon: React.FC<{ feature: FeatureItem }> = ({ feature }) => (
    <>
        {feature.type === 'icon' && feature.iconClass ? <i className={`font-icon ${feature.iconClass}`} /> : null}
        {
            // Prevent @next/next/no-img-element issue here... we cannot guarantee we are in a nextjs app
            // eslint-disable-next-line
            feature.type === 'image' && feature.image ? <img alt="" className="image-icon" src={feature.image} /> : null
        }
        {feature.title}
    </>
);
