import { createTheme, ThemeOptions } from '@mui/material';
import { createThemeOptionsForBrand, defaultAppBarSettings, mapBreakPoints } from '@jucy-ui/appearance';
import { boldenVan, poppins } from '#/styles/fonts';
import config from '#/config';
import { PartialDeep } from 'type-fest';

const isTest = config.env === 'test';
const testBannerHeight = 46;

export const createThemeForBand = (brand: 'star' | 'jucy' = 'jucy', themeOptions?: PartialDeep<ThemeOptions>): ThemeOptions =>
    createTheme(
        createThemeOptionsForBrand(brand, {
            ...themeOptions,
            typography: {
                fontFamily: `${poppins.style.fontFamily}, 'Verdana', 'sans-serif'`,
                alternate: {
                    fontFamily: `${boldenVan.style.fontFamily}, 'Verdana', 'sans-serif'`,
                },
            },
            settings: {
                appBar: {
                    testBannerHeight,
                    spacer: (padding = 8) => mapBreakPoints(defaultAppBarSettings.heights, (height) => `${height + padding + (isTest ? testBannerHeight : 0)}px`),
                },
            },
        })
    );
