import { CSSObject } from '@mui/material/styles';

export const comparisonTableCellSharedStyles: CSSObject = {
    boxSizing: 'border-box',
    backgroundClip: 'padding-box',
    scrollSnapAlign: 'start',
    padding: '.5em',
    height: '2.3em',
};

export const comparisonTablePinnedCellSharedStyles: CSSObject = {
    position: 'sticky',
    top: '0',
    borderTop: '0',
    backgroundClip: 'padding-box',
    fontWeight: '700',
};
