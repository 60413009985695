import { Grid, GridProps, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import CurrencyFormatter, { Price } from '../../CurrencyFormatter/CurrencyFormatter';

export interface BookingSummaryPriceRowProps extends GridProps {
    title?: string;
    subtitle?: React.ReactNode;
    price?: Price;
    className?: string;
}

const BookingSummaryPriceRow: React.FC<BookingSummaryPriceRowProps> = ({ title, subtitle, price, sx, className, ...props }) => (
    <Grid sx={sx} container spacing={0} marginBottom={1} className={clsx('trip-info animate__animated animate__fadeIn', className)} {...props}>
        <Grid item xs={7} lineHeight={1}>
            <Typography variant="caption" lineHeight={0.8}>
                {title}
                {subtitle && (
                    <>
                        <br />
                        {subtitle}
                    </>
                )}
            </Typography>
        </Grid>
        <Grid item xs={5} display="flex" justifyContent="right">
            <Typography fontWeight="bold" variant="caption">
                {price ? <CurrencyFormatter price={price} /> : null}
            </Typography>
        </Grid>
    </Grid>
);
BookingSummaryPriceRow.displayName = 'BookingSummaryPriceRow';
export default BookingSummaryPriceRow;
