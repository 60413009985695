'use client';

import {Theme} from '@mui/material';
import {SxProps} from '@mui/system/styleFunctionSx';
import React from 'react';
import { CatalogItem } from '@jucy-ui/common';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import chunk from 'lodash/chunk';
import { JucyMuiTabPanel } from '../../../../../Tabs';
import { getFleetCategoryDetailsDialogTabPanelProps } from './fleetCategoryDetailsDialogTabConsts';


export const FeaturesTab: React.FC<{ activeTab: string; catalogItem: CatalogItem ,sx?: SxProps<Theme>;}> = ({ activeTab, catalogItem,sx }) => (
    <JucyMuiTabPanel activeValue={activeTab} {...getFleetCategoryDetailsDialogTabPanelProps('features')} sx={sx}>
        <Box sx={{ pt: 2, '& :first-child': { mt: 0 } }} dangerouslySetInnerHTML={{ __html: catalogItem.featuresDescription || '' }} />
        {catalogItem.features.length
            ? catalogItem.features.map((feature) => (
                  <Box key={feature.title}>
                      <Typography fontWeight="700" fontSize="Typography" mt={1}>
                          {feature.title}
                      </Typography>
                      {feature.content ? <Typography dangerouslySetInnerHTML={{ __html: feature.content }} /> : null}
                      {feature.items.length ? (
                          <Grid container spacing={2} mt={1}>
                              {chunk(feature.items, feature.items.length / 2 + 1).map((featureChunk, i) => (
                                  <Grid key={`feature-${i}`} xs={12} md={6}>
                                      <List disablePadding={true} dense={true} sx={{ fontWeight: 400, mb: 2 }}>
                                          {featureChunk.map((feature, j) => (
                                              <ListItem disablePadding={true} key={`features-${i}-${j}`}>
                                                  <ListItemIcon sx={{ minWidth: 31 }}>
                                                      <DoneIcon fontWeight="bold" color="secondary" />
                                                  </ListItemIcon>
                                                  <ListItemText disableTypography={true} sx={{ marginTop: 0, marginBottom: 0 }} primary={feature} />
                                              </ListItem>
                                          ))}
                                      </List>
                                  </Grid>
                              ))}
                          </Grid>
                      ) : null}
                  </Box>
              ))
            : null}
    </JucyMuiTabPanel>
);
