import { mergeDateTime, nullToUndefined } from '@jucy-ui/common';
import type { ParsedQs } from 'qs';
import { ConditionalPick } from 'type-fest';
import { Maybe } from 'yup';
import { SearchFormData, SearchFormValues, searchFormKeys } from '../types';

const queryParams = [...searchFormKeys, 'fleetCategory'];
export const searchFormValuesFromQueryString = (searchParams?: ParsedQs): Maybe<Partial<SearchFormValues>> => {
    const result: Maybe<Partial<SearchFormValues>> = {};
    if (!searchParams) {
        return result;
    }

    type stringKeys = keyof ConditionalPick<SearchFormData, string>;
    for (const key of queryParams) {
        const value = searchParams[key];
        if (value && typeof value === 'string') {
            result[key as stringKeys] = value;
        }
    }

    result.pickUpDate = mergeDateTime(searchParams.pickUpDate, searchParams.pickUpTime);
    result.pickUpTime = result.pickUpDate;
    result.dropOffDate = mergeDateTime(searchParams.dropOffDate, searchParams.dropOffTime);
    result.dropOffTime = result.dropOffDate;
    return nullToUndefined(result);
};
