'use client';
import React from 'react';
import { TextFieldElement, type TextFieldElementProps } from 'react-hook-form-mui';
import { PhoneNumberInput, type PhoneNumberInputProps } from '@jucy-ui/components';

export type PhoneNumberElementProps = Omit<TextFieldElementProps, 'onChange'> & PhoneNumberInputProps;
export const PhoneNumberElement = React.forwardRef<HTMLInputElement, PhoneNumberElementProps>((props, ref) => (
    <TextFieldElement component={PhoneNumberInput as TextFieldElementProps['component']} ref={ref} {...(props as TextFieldElementProps)} />
));
PhoneNumberElement.displayName = 'PhoneNumberElement';
