import { ConfigurationParameters } from './rentals-api';
import {
    AgentApi,
    BusinessUnitsApi,
    CatalogApi,
    CommissionsApi,
    Configuration,
    CountryApi,
    DataApi,
    FleetCategoriesApi,
    MailListApi,
    PaymentApi,
    QuotesApi,
    ReservationsApi,
    SelfServiceApi,
    ShuttlesApi,
    SitesApi,
    TripApi,
} from './rentals-api-v3';

class RentalsApiClient {
    config: Configuration;
    agent: AgentApi;
    mailList: MailListApi;
    reservations: ReservationsApi;
    selfService: SelfServiceApi;
    paymentService: PaymentApi;
    data: DataApi;
    quotes: QuotesApi;
    tripApi: TripApi;
    sites: SitesApi;
    catalog: CatalogApi;
    fleetCategories: FleetCategoriesApi;
    businessUnits: BusinessUnitsApi;
    shuttles: ShuttlesApi;
    countries: CountryApi;
    commissions: CommissionsApi;
    constructor(config: ConfigurationParameters) {
        this.config = new Configuration({
            ...config,
            fetchApi: fetch,
        });
        this.config = new Configuration(config);
        this.agent = new AgentApi(this.config);
        this.mailList = new MailListApi(this.config);
        this.reservations = new ReservationsApi(this.config);
        this.selfService = new SelfServiceApi(this.config);
        this.paymentService = new PaymentApi(this.config);
        this.data = new DataApi(this.config);
        this.quotes = new QuotesApi(this.config);
        this.tripApi = new TripApi(this.config);
        this.sites = new SitesApi(this.config);
        this.catalog = new CatalogApi(this.config);
        this.fleetCategories = new FleetCategoriesApi(this.config);
        this.businessUnits = new BusinessUnitsApi(this.config);
        this.shuttles = new ShuttlesApi(this.config);
        this.countries = new CountryApi(this.config);
        this.commissions = new CommissionsApi(this.config);
    }
}

export default RentalsApiClient;
