import { Box, Card, CardContent, Grid, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';

const TeaserPlaceHolder = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <Card elevation={0} sx={{ marginBottom: 2, borderWidth: 1, borderColor: theme.palette.grey[200], borderStyle: 'solid' }}>
            <CardContent sx={{ '&:last-child': { paddingBottom: 2 } }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3.9} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                        <Skeleton width="100%" animation="wave" variant="rectangular" sx={{ paddingBottom: 'calc(9 / 16 * 100%)', borderRadius: 1 }} />
                        <Skeleton width={120} height={25} animation="wave" variant="text" sx={{ marginTop: 2, marginBottom: 1 }} />
                        <Skeleton width={90} height={15} animation="wave" variant="text" sx={{ marginBottom: 1 }} />
                    </Grid>
                    <Grid item xs={12} sm={4.7} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Skeleton width="90%" height={20} animation="wave" variant="text" />
                        <Skeleton width="90%" height={20} animation="wave" variant="text" />
                        <Skeleton width="90%" height={20} animation="wave" variant="text" />
                        <Skeleton width="90%" height={20} animation="wave" variant="text" />
                        <Skeleton width="60%" height={20} animation="wave" variant="text" sx={{ marginTop: 2, marginBottom: 2 }} />
                        <Stack spacing={1.5} direction="row" sx={{ mx: isXs ? 'auto' : undefined }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Skeleton width={20} height={20} animation="wave" variant="circular" sx={{ marginRight: 0.5, marginTop: 0.39 }} />
                                <Skeleton width={20} height={39} animation="wave" variant="text" />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Skeleton width={20} height={20} animation="wave" variant="circular" sx={{ marginRight: 0.5, marginTop: 0.39 }} />
                                <Skeleton width={20} height={39} animation="wave" variant="text" />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Skeleton width={20} height={20} animation="wave" variant="circular" sx={{ marginRight: 0.5, marginTop: 0.39 }} />
                                <Skeleton width={20} height={39} animation="wave" variant="text" />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3.4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Skeleton width={120} height={25} animation="wave" variant="text" />
                        <Skeleton width="60%" height={20} animation="wave" variant="text" />
                        <Skeleton width="60%" height={20} animation="wave" variant="text" />
                        <Skeleton width="60%" height={20} animation="wave" variant="rectangular" sx={{ marginTop: 3, borderRadius: 1 }} />
                        <Skeleton width={110} height={31} animation="wave" variant="rectangular" sx={{ marginTop: isXs ? 2 : 'auto', borderRadius: 1 }} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TeaserPlaceHolder;
