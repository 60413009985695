import { Breakpoint } from '@mui/material';
import { createBreakpoints } from '@mui/system';
import { responsiveHelper } from '../utils/ResponsiveHelper';

const breakPointOptions: { [key in Partial<Breakpoint>]: number } = {
    xs: responsiveHelper.breakpoints.xs,
    sm: responsiveHelper.breakpoints.sm - 1,
    md: responsiveHelper.breakpoints.md - 1,
    lg: responsiveHelper.breakpoints.lg - 1,
    xl: responsiveHelper.breakpoints.xl - 1,
    xxl: responsiveHelper.breakpoints.xxl + 1,
};
export const defaultBreakPoints = createBreakpoints({ values: breakPointOptions });
