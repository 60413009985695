import { businessUnits } from '@jucy/rentals-common';
import { BusinessUnit } from '@jucy/rentals-common/data/businessUnits';
import { Site } from '../lib/api/model/Site';
import SitesStore from './SitesStore';
import Quote from '#/lib/api/model/Quote';
import { isStarFleetType } from '#/lib/isStarFleetType';

class BusinessUnitsStore {
    businessUnits: BusinessUnit[] = businessUnits;

    getBusinessUnitByCode(code?: string): BusinessUnit | undefined {
        return code ? this.businessUnits.find((bu) => bu.code.toLowerCase() === code.toLowerCase()) : undefined;
    }

    getBusinessUnitById(id?: string): BusinessUnit | undefined {
        return id ? this.businessUnits.find((bu) => bu.id.toLowerCase() === id.toLowerCase()) : undefined;
    }

    getBySite(site?: Site): BusinessUnit | undefined {
        return site?.BusinessUnit ? this.getBusinessUnitById(site.BusinessUnit) : undefined;
    }

    getBySiteCode(siteCode?: string): BusinessUnit | undefined {
        return siteCode ? this.getBySite(SitesStore.getSiteByCode(siteCode)) : undefined;
    }

    getByQuote(quote: Quote) {
        const brandPrefix = isStarFleetType(quote.FleetCategory) ? 'STAR' : 'JUCY';
        const countryCodeSuffix = quote.PickUpSite?.CountryCode?.toUpperCase();
        return this.getBusinessUnitByCode(`${brandPrefix}${countryCodeSuffix}`);
    }
}

const businessUnitsStore = new BusinessUnitsStore();
export default businessUnitsStore;
