'use client';
import React, { useCallback, useState } from 'react';
import { formatCurrency } from '@jucy-ui/common';
import InfoIcon from '@mui/icons-material/Info';
import { buttonClasses } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { type PaymentType } from '../lib';

export interface PaymentTypeRadioSummaryProps {
    paymentType: PaymentType;
    disabled?: boolean;
}

export const PaymentTypeRadioSummary: React.FC<PaymentTypeRadioSummaryProps> = ({ paymentType, disabled }) => {
    const [isOpen, setOpen] = useState(false);
    const toggleModel = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            setOpen(!isOpen);
        },
        [isOpen]
    );
    return (
        <Box>
            {paymentType.summary ? (
                <Typography variant="caption" component="div" sx={{ textTransform: 'none', lineHeight: 1, mb: 1, fontSize: '0.9285em' }}>
                    {paymentType.summary}
                </Typography>
            ) : null}

            {paymentType.summaryPrice?.currencyCode ? (
                <Typography variant="h4" sx={{ fontSize: '1.285em' }}>
                    {paymentType.summaryPrice.currencyCode} {formatCurrency(paymentType.summaryPrice)}
                </Typography>
            ) : null}
            {paymentType.modalBody ? (
                <div>
                    <Button
                        disabled={disabled}
                        variant="text"
                        sx={{
                            fontWeight: 'bold',
                            px: 2,
                            whiteSpace: 'nowrap',
                            [`& .${buttonClasses.startIcon}, & .${buttonClasses.startIcon} svg`]: { transition: 'none' },
                        }}
                        startIcon={<InfoIcon sx={{ transition: 'none' }} />}
                        onClick={() => setOpen(true)}
                    >
                        More details
                    </Button>
                </div>
            ) : null}
            {paymentType.modalBody ? (
                <Dialog
                    open={isOpen}
                    onClose={toggleModel}
                    onClick={toggleModel}
                    disableAutoFocus={true}
                    disableRestoreFocus={true}
                    disableScrollLock={true}
                    keepMounted={true}
                    {...paymentType.modalProps}
                >
                    {paymentType.modalBody}
                </Dialog>
            ) : null}
        </Box>
    );
};
