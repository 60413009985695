import React, { useCallback, useState } from 'react';
import { countryWhiteList } from '@jucy/rentals-common';
import DriversLicenceWarningModal from '../../Modal/DriversLicenceWarningModal';
import CountrySelect, { CountrySelectProps } from './CountrySelect';

const LicenceCountrySelect: React.FC<CountrySelectProps> = ({ onChange, ...props }) => {
    const [showModal, setShowModal] = useState(false);
    const handleChange = useCallback<Exclude<CountrySelectProps['onChange'], undefined>>(
        (value) => {
            if (value?.label && !countryWhiteList.includes(value.label)) {
                setShowModal(true);
            }
            onChange?.(value);
        },
        [onChange]
    );
    return (
        <>
            <DriversLicenceWarningModal setShowModal={setShowModal} showModal={showModal} />
            <CountrySelect onChange={handleChange} {...props} />
        </>
    );
};

export default LicenceCountrySelect;
