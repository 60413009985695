/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
    BalanceDueResponse,
    BookingSummaryResponse,
    CheckInRequest,
    RentalDocument,
    RentalDocumentUpload,
    RentalDocumentUploadResponse,
    RentalSignature,
    ReservationPricingEnquiryResponse,
    SecondaryProductMap,
    SelfServiceReservation,
    SignRaRequest,
    SignRaResponse,
    SignUploadResponse,
    UpdateQuoteResponse,
} from '../models/index';
import {
    BalanceDueResponseFromJSON,
    BalanceDueResponseToJSON,
    BookingSummaryResponseFromJSON,
    BookingSummaryResponseToJSON,
    CheckInRequestFromJSON,
    CheckInRequestToJSON,
    RentalDocumentFromJSON,
    RentalDocumentToJSON,
    RentalDocumentUploadFromJSON,
    RentalDocumentUploadResponseFromJSON,
    RentalDocumentUploadResponseToJSON,
    RentalDocumentUploadToJSON,
    RentalSignatureFromJSON,
    RentalSignatureToJSON,
    ReservationPricingEnquiryResponseFromJSON,
    ReservationPricingEnquiryResponseToJSON,
    SecondaryProductMapFromJSON,
    SecondaryProductMapToJSON,
    SelfServiceReservationFromJSON,
    SelfServiceReservationToJSON,
    SignRaRequestFromJSON,
    SignRaRequestToJSON,
    SignRaResponseFromJSON,
    SignRaResponseToJSON,
    SignUploadResponseFromJSON,
    SignUploadResponseToJSON,
    UpdateQuoteResponseFromJSON,
    UpdateQuoteResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface CheckInOperationRequest {
    checkInRequest?: CheckInRequest;
}

export interface EditReservationRequest {
    ref: string;
    secondaryProductMap?: Array<SecondaryProductMap>;
}

export interface GetReservationPricingRequest {
    ref: string;
    secondaryProductMap?: Array<SecondaryProductMap>;
}

export interface ListReservationDocumentsRequest {
    ref: string;
}

export interface ListReservationSignaturesRequest {
    ref: string;
}

export interface SelfServiceGetBalanceDueRequest {
    ref: string;
}

export interface SelfServiceGetReservationRequest {
    ref: string;
}

export interface SignRentalAgreementRequest {
    ref: string;
    signRaRequest?: SignRaRequest;
}

export interface SignUploadRequest {
    ref: string;
    body?: object;
}

export interface UploadDocumentRequest {
    ref: string;
    rentalDocumentUpload?: RentalDocumentUpload;
}

/**
 *
 */
export class SelfServiceApi extends runtime.BaseAPI {
    /**
     * List booking summaries
     */
    async bookingSummariesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BookingSummaryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/booking-summaries`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingSummaryResponseFromJSON(jsonValue));
    }

    /**
     * List booking summaries
     */
    async bookingSummaries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BookingSummaryResponse> {
        const response = await this.bookingSummariesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update reservation
     */
    async checkInRaw(
        requestParameters: CheckInOperationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/check-in`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CheckInRequestToJSON(requestParameters['checkInRequest']),
            },
            initOverrides
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Update reservation
     */
    async checkIn(checkInRequest?: CheckInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.checkInRaw({ checkInRequest: checkInRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Edit reservation
     */
    async editReservationRaw(
        requestParameters: EditReservationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<UpdateQuoteResponse>> {
        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling editReservation().');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/reservation/ref/{ref}/edit`.replace(`{${'ref'}}`, encodeURIComponent(String(requestParameters['ref']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['secondaryProductMap']!.map(SecondaryProductMapToJSON),
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateQuoteResponseFromJSON(jsonValue));
    }

    /**
     * Edit reservation
     */
    async editReservation(
        ref: string,
        secondaryProductMap?: Array<SecondaryProductMap>,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<UpdateQuoteResponse> {
        const response = await this.editReservationRaw({ ref: ref, secondaryProductMap: secondaryProductMap }, initOverrides);
        return await response.value();
    }

    /**
     * Get reservation pricing
     */
    async getReservationPricingRaw(
        requestParameters: GetReservationPricingRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<ReservationPricingEnquiryResponse>> {
        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling getReservationPricing().');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/reservation/ref/{ref}/pricing-enquiries`.replace(
                    `{${'ref'}}`,
                    encodeURIComponent(String(requestParameters['ref']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['secondaryProductMap']!.map(SecondaryProductMapToJSON),
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationPricingEnquiryResponseFromJSON(jsonValue));
    }

    /**
     * Get reservation pricing
     */
    async getReservationPricing(
        ref: string,
        secondaryProductMap?: Array<SecondaryProductMap>,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<ReservationPricingEnquiryResponse> {
        const response = await this.getReservationPricingRaw({ ref: ref, secondaryProductMap: secondaryProductMap }, initOverrides);
        return await response.value();
    }

    /**
     * List documents for reservation
     */
    async listReservationDocumentsRaw(
        requestParameters: ListReservationDocumentsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<RentalDocument>>> {
        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling listReservationDocuments().');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/reservation/ref/{ref}/documents`.replace(`{${'ref'}}`, encodeURIComponent(String(requestParameters['ref']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RentalDocumentFromJSON));
    }

    /**
     * List documents for reservation
     */
    async listReservationDocuments(ref: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RentalDocument>> {
        const response = await this.listReservationDocumentsRaw({ ref: ref }, initOverrides);
        return await response.value();
    }

    /**
     * List signatures for reservation
     */
    async listReservationSignaturesRaw(
        requestParameters: ListReservationSignaturesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<RentalSignature>>> {
        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling listReservationSignatures().');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/reservation/ref/{ref}/signatures`.replace(
                    `{${'ref'}}`,
                    encodeURIComponent(String(requestParameters['ref']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RentalSignatureFromJSON));
    }

    /**
     * List signatures for reservation
     */
    async listReservationSignatures(ref: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RentalSignature>> {
        const response = await this.listReservationSignaturesRaw({ ref: ref }, initOverrides);
        return await response.value();
    }

    /**
     * Get reservation balance
     */
    async selfServiceGetBalanceDueRaw(
        requestParameters: SelfServiceGetBalanceDueRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<BalanceDueResponse>> {
        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling selfServiceGetBalanceDue().');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/self-service/reservation/ref/{ref}/balance-due`.replace(
                    `{${'ref'}}`,
                    encodeURIComponent(String(requestParameters['ref']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceDueResponseFromJSON(jsonValue));
    }

    /**
     * Get reservation balance
     */
    async selfServiceGetBalanceDue(ref: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BalanceDueResponse> {
        const response = await this.selfServiceGetBalanceDueRaw({ ref: ref }, initOverrides);
        return await response.value();
    }

    /**
     * Get reservation
     */
    async selfServiceGetReservationRaw(
        requestParameters: SelfServiceGetReservationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<SelfServiceReservation>> {
        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling selfServiceGetReservation().');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/reservation/ref/{ref}`.replace(`{${'ref'}}`, encodeURIComponent(String(requestParameters['ref']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => SelfServiceReservationFromJSON(jsonValue));
    }

    /**
     * Get reservation
     */
    async selfServiceGetReservation(ref: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SelfServiceReservation> {
        const response = await this.selfServiceGetReservationRaw({ ref: ref }, initOverrides);
        return await response.value();
    }

    /**
     * Sign rental agreement
     */
    async signRentalAgreementRaw(
        requestParameters: SignRentalAgreementRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<SignRaResponse>> {
        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling signRentalAgreement().');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/reservation/ref/{ref}/sign-rental-agreement`.replace(
                    `{${'ref'}}`,
                    encodeURIComponent(String(requestParameters['ref']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SignRaRequestToJSON(requestParameters['signRaRequest']),
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => SignRaResponseFromJSON(jsonValue));
    }

    /**
     * Sign rental agreement
     */
    async signRentalAgreement(
        ref: string,
        signRaRequest?: SignRaRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<SignRaResponse> {
        const response = await this.signRentalAgreementRaw({ ref: ref, signRaRequest: signRaRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Sign upload for reservation
     */
    async signUploadRaw(
        requestParameters: SignUploadRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<SignUploadResponse>> {
        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling signUpload().');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/reservation/ref/{ref}/sign-upload`.replace(
                    `{${'ref'}}`,
                    encodeURIComponent(String(requestParameters['ref']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['body'] as any,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => SignUploadResponseFromJSON(jsonValue));
    }

    /**
     * Sign upload for reservation
     */
    async signUpload(ref: string, body?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUploadResponse> {
        const response = await this.signUploadRaw({ ref: ref, body: body }, initOverrides);
        return await response.value();
    }

    /**
     * Upload document
     */
    async uploadDocumentRaw(
        requestParameters: UploadDocumentRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<RentalDocumentUploadResponse>> {
        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError('ref', 'Required parameter "ref" was null or undefined when calling uploadDocument().');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/self-service/reservation/ref/{ref}/documents`.replace(`{${'ref'}}`, encodeURIComponent(String(requestParameters['ref']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: RentalDocumentUploadToJSON(requestParameters['rentalDocumentUpload']),
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => RentalDocumentUploadResponseFromJSON(jsonValue));
    }

    /**
     * Upload document
     */
    async uploadDocument(
        ref: string,
        rentalDocumentUpload?: RentalDocumentUpload,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<RentalDocumentUploadResponse> {
        const response = await this.uploadDocumentRaw({ ref: ref, rentalDocumentUpload: rentalDocumentUpload }, initOverrides);
        return await response.value();
    }
}
