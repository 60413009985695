import { DISPLAY_TIME_FORMAT_SHORT, FleetType, Site, memoize, setTimeFromString } from '@jucy-ui/common';
import { format } from 'date-fns';
import { getDefaultTimes } from './getDefaultTimes';
import { getMinPickUp } from './getMinPickUp';

export const getDefaultPickUpTime = memoize(({ date, branch, fleetType }: { date: Date; branch?: Site; fleetType?: FleetType }) => {
    const minPickUp = fleetType && branch ? getMinPickUp({ branch, date, fleetType }) : undefined;
    const minPickUpTime = minPickUp ? format(minPickUp, DISPLAY_TIME_FORMAT_SHORT) : undefined;
    const time = minPickUpTime || getDefaultTimes({ date, branch }).pickUp || '10:00';
    return setTimeFromString(date, time);
});
