import React, { useCallback, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useDebounce } from 'usehooks-ts';

const SearchBar: React.FC<{ onChange?: (value: string) => void; placeholder?: string }> = ({
    onChange,
    placeholder = 'Filter By Entering Name or Booking Reference or Voucher Reference',
}) => {
    const [value, setValue] = useState('');
    const debouncedSearchTerm = useDebounce(value, 500);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value), []);

    React.useEffect(() => {
        onChange?.(debouncedSearchTerm);
    }, [onChange, debouncedSearchTerm]);

    return (
        <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="filter-reservations">Filter</InputLabel>
            <OutlinedInput
                id="filter-reservations"
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon sx={{ color: 'secondary.main' }} />
                    </InputAdornment>
                }
                label="Filter"
            />
        </FormControl>
    );
};

export default SearchBar;
