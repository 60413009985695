import React from 'react';
import { useExcessReductionFormContext } from '#/components/Forms/ExcessReductionForm/ExcessReductionForm';
import { RentalExcessReduction } from '@jucy/rentals-api-client';
import classNames from 'clsx';
import clsx from 'clsx';
import { getExcessReductionFeatures, getFeatures } from '../../ExcessMapper';

interface FeaturesListProps {
    item: Required<RentalExcessReduction>;
    className?: string;
}

const FeaturesList: React.FC<FeaturesListProps> = ({ item, className }) => {
    const excessReductionFormContext = useExcessReductionFormContext();
    const features = getExcessReductionFeatures(item, excessReductionFormContext);
    const allFeatures = getFeatures(excessReductionFormContext);
    return (
        <ul className={clsx('fa-ul ms-4', className)}>
            {allFeatures.map((feature, i) => {
                const isIncluded = features.includes(feature);
                return (
                    <li key={`features-${i}}`} className={classNames('ms-0', { 'text-muted': !isIncluded })}>
                        <span className="fa-li">
                            <i
                                className={classNames('fas text-green', {
                                    'text-muted': !isIncluded,
                                    'text-green': isIncluded,
                                    'fa-check': isIncluded,
                                    'fa-times': !isIncluded,
                                })}
                            />
                        </span>
                        {feature}
                    </li>
                );
            })}
        </ul>
    );
};
export default FeaturesList;
