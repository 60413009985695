'use client';
import React from 'react';
import { TabProps } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabTypeMap } from '@mui/material/Tab/Tab';
import { styled } from '@mui/material/styles';

export type JucyMuiTabProps<RootComponent extends React.ElementType = TabTypeMap['defaultComponent']> = TabProps<RootComponent> & {
    a11yName: string;
    value: string;
    component?: RootComponent;
};
export const JucyMuiTab = styled(
    <RootComponent extends React.ElementType = TabTypeMap['defaultComponent']>({ a11yName, value, ...props }: JucyMuiTabProps<RootComponent>) => (
        <Tab {...jucyMuiTabA11yProps({ a11yName, value })} value={value} {...props} />
    ),
    { name: 'JucyTab', slot: 'tab' }
)(({ theme }) => ({
    color: theme.palette.text.primary,
}));
const jucyMuiTabA11yProps = ({ a11yName }: { a11yName: string; value: string }) => ({
    id: `${a11yName}-tab`,
    'aria-controls': `${a11yName}-tabpanel`,
});
