import {
    addDays,
    addHours,
    differenceInMinutes,
    endOfDay,
    format,
    getISODay,
    isBefore,
    isSameDay,
    isSameMinute,
    isToday,
    isValid,
    isWithinInterval,
    parse,
    roundToNearestMinutes,
    startOfDay,
} from 'date-fns';
import { toJS } from 'mobx';
import { Holiday, RentalSiteSettings, ServiceHour, ServiceHours, ServiceHoursMessage } from './api/model/RentalSiteSettings';
import { Site } from './api/model/Site';
import { getEnWeekDay, setTimeFromString } from './dates';

export const getProhibitedSiteCodes = (type: 'pickUp' | 'dropOff', settings: RentalSiteSettings) => {
    const prohibitedSiteList = settings?.[type === 'dropOff' ? 'prohibitedDropOffSites' : 'prohibitedPickupSites'];
    return prohibitedSiteList && prohibitedSiteList.map((s) => s.code);
};

export const filterSites = ({ sites, fleetTypeId, prohibitedSiteCodes, country }: { sites: Site[]; fleetTypeId: string | null; prohibitedSiteCodes: string[]; country?: string }) =>
    sites.filter((site) => {
        let isValid = !site.Name.startsWith('Lucky');
        if (isValid && fleetTypeId) {
            isValid = Boolean(site.SiteSettings?.some(({ FleetTypeId }) => FleetTypeId.toLowerCase() === fleetTypeId));
        }

        if (isValid && prohibitedSiteCodes) {
            isValid = !prohibitedSiteCodes.includes(site.SiteCode);
        }

        if (isValid && country) {
            isValid = site.Country === country;
        }

        return isValid;
    });

export const isTimeBetweenExclusive = (toCheck: string, start: string, end: string) => {
    const checkDate = parse(toCheck, 'HH:mm', new Date()).valueOf();
    const startDate = parse(start, 'HH:mm', new Date()).valueOf();
    const endDate = parse(end, 'HH:mm', new Date()).valueOf();
    let between = false;
    if (startDate < endDate) {
        between = startDate < checkDate && endDate > checkDate;
    } else {
        between = checkDate < endDate || checkDate > startDate;
    }
    return between;
};

export const filterMessagesForDate = (date: Date, rules: ServiceHoursMessage[]) => {
    const dow = date.getDay();
    return rules.filter((m) => dow >= m.startDow && dow <= m.endDow && isTimeBetweenExclusive(format(date, 'HH:mm'), m.startTime, m.endTime));
};

export const isDayHoliday = (day: Date, holidays: Holiday[]) =>
    (isValid(day) &&
        holidays.find((holiday) => {
            const start = holiday.start;
            const end = holiday.end;
            const hasStartAndEnd = isValid(holiday.start) && isValid(holiday.end);
            const hasDow = holiday.dow && holiday.dow >= 1 && holiday.dow <= 7;
            const minutesDiff = hasStartAndEnd ? differenceInMinutes(end, start) : null;
            const isSingleDay = Boolean(minutesDiff && ((minutesDiff >= 1439 && minutesDiff <= 1440) || minutesDiff === 0) && !holiday.dow);
            const dateISODay = getISODay(day);
            if (isSingleDay && isSameDay(start, day)) {
                return holiday;
            } else if (hasStartAndEnd && holiday.dow && isWithinInterval(day, holiday) && isWithinInterval(day, holiday) && dateISODay === holiday.dow) {
                return holiday;
            } else if (!hasStartAndEnd && hasDow && dateISODay === holiday.dow) {
                return holiday;
            } else if (hasStartAndEnd && !hasDow && isSingleDay) {
                return isSameDay(day, start);
            } else if (hasStartAndEnd && !hasDow) {
                return isWithinInterval(day, holiday);
            }
            return null;
        })) ||
    null;

export const filterLocationHours = ({
    pickUpLocationSettings,
    dropOffLocationSettings,
    fleetTypeSlug,
    pickUpDate,
    dropOffDate,
    leadTime = 2,
}: {
    pickUpLocationSettings: RentalSiteSettings;
    dropOffLocationSettings: RentalSiteSettings;
    fleetTypeSlug: string;
    pickUpDate: Date;
    dropOffDate: Date;
    leadTime: number;
}): {
    pickUp: { start: Date; end: Date; allowAfterHours: boolean | undefined };
    dropOff: { start: Date; end: Date; allowAfterHours: boolean | undefined };
} => {
    const pickUpFleetTypeSettings = toJS(pickUpLocationSettings && pickUpLocationSettings.fleetTypeSettings.find((s) => s.fleetTypeSlug === fleetTypeSlug));
    const dropOffFleetTypeSettings = toJS(dropOffLocationSettings && dropOffLocationSettings.fleetTypeSettings.find((s) => s.fleetTypeSlug === fleetTypeSlug));
    const pickUpDateFallBack = new Date();
    const dropOffDateFallBack = addDays(pickUpDateFallBack, 1);
    const result = {
        pickUp: {
            start: startOfDay(pickUpDate || pickUpDateFallBack),
            end: endOfDay(pickUpDate || pickUpDateFallBack),
            allowAfterHours: pickUpFleetTypeSettings && pickUpFleetTypeSettings.afterHoursPickup,
        },
        dropOff: {
            start: startOfDay(dropOffDate || dropOffDateFallBack),
            end: endOfDay(dropOffDate || dropOffDateFallBack),
            allowAfterHours: dropOffFleetTypeSettings && dropOffFleetTypeSettings.afterHoursDropoff,
        },
    };
    const pickUpWeekday = getEnWeekDay(result.pickUp.start) as keyof ServiceHours;
    const pickUpIsoWeekday = getISODay(result.pickUp.start);
    let pickupServiceHours: ServiceHour | null = null;
    const pickUpHours =
        pickUpFleetTypeSettings?.pickupServiceHours?.[pickUpWeekday] || (pickUpFleetTypeSettings as { pickUpServiceHours?: ServiceHours })?.pickUpServiceHours?.[pickUpWeekday];
    if (pickUpHours) {
        pickupServiceHours = {
            dow: pickUpIsoWeekday,
            open: pickUpHours.Open,
            close: pickUpHours.Close,
        };
    }
    if (!pickupServiceHours && pickUpFleetTypeSettings?.serviceHours) {
        pickupServiceHours = pickUpFleetTypeSettings.serviceHours.find((h) => h.dow === pickUpIsoWeekday) || null;
    }

    const dropOffWeekday = getEnWeekDay(result.pickUp.start) as keyof ServiceHours;
    const dropOffIsoWeekday = getISODay(result.dropOff.start);
    let dropOffServiceHours: ServiceHour | null = null;
    const dropOffHours =
        pickUpFleetTypeSettings?.dropoffServiceHours?.[dropOffWeekday] || (pickUpFleetTypeSettings as { dropOffServiceHours?: ServiceHours })?.dropOffServiceHours?.[dropOffWeekday];
    if (dropOffHours) {
        dropOffServiceHours = {
            dow: dropOffIsoWeekday,
            open: dropOffHours.Open,
            close: dropOffHours.Close,
        };
    }
    if (!dropOffServiceHours && dropOffFleetTypeSettings?.serviceHours) {
        dropOffServiceHours = dropOffFleetTypeSettings.serviceHours.find((h) => h.dow === dropOffIsoWeekday) || null;
    }

    if (pickupServiceHours) {
        result.pickUp = {
            ...result.pickUp,
            start: setTimeFromString(result.pickUp.start, pickupServiceHours.open),
            end: setTimeFromString(result.pickUp.end, pickupServiceHours.close),
        };
    }

    if (dropOffServiceHours) {
        result.dropOff = {
            ...result.dropOff,
            start: setTimeFromString(result.dropOff.start, dropOffServiceHours.open),
            end: setTimeFromString(result.dropOff.end, dropOffServiceHours.close),
        };
    }

    if (isToday(result.pickUp.start)) {
        //round up to the next 30 minute interval
        const minPickUp = roundToNearestMinutes(addHours(new Date(), leadTime), {
            nearestTo: 30,
            roundingMethod: 'ceil',
        });
        if (isSameMinute(result.pickUp.start, minPickUp) || isBefore(result.pickUp.start, minPickUp)) {
            result.pickUp.start = minPickUp;
        }
    }

    return result;
};
