import { useCallback } from 'react';
import { Site } from '@jucy-ui/common';
import { getBranchSupportsFleetType } from '../lib/getBranchSupportsFleetType';
import { useSearchFormData } from './useSearchFormData';
import { useSearchFormFieldValue } from './useSearchFormFieldValue';
import { useSearchFormStore } from './useSearchFormStore';

export const useShouldDisableBranch = (position: 'start' | 'end') => {
    const pickUpBranchCode = useSearchFormFieldValue('pickUpBranch');
    const store = useSearchFormStore();
    const region = store.region;

    const fleetTypeSlug = useSearchFormFieldValue('fleetType');
    const { getSite, getFleetType } = useSearchFormData();
    const pickUpBranch = getSite(pickUpBranchCode);
    const fleetType = getFleetType(fleetTypeSlug);
    return useCallback(
        (branch: Site) => {
            if (region && region !== branch.countryCode) {
                return true;
            }
            if (fleetType && !getBranchSupportsFleetType({ branch, fleetType })) {
                return true;
            }
            if (position === 'end' && pickUpBranch && pickUpBranch.countryCode !== branch.countryCode) {
                return true;
            }
            return false;
        },
        [fleetType, pickUpBranch, position, region]
    );
};
