import { formatISO9075 } from 'date-fns';
import { setTime } from '../../lib';
import { AvailabilitySearchRequest, AvailabilitySearchValues, FleetTypeCode } from '../models';

export const mapSearchValuesToRequest = (searchValues: AvailabilitySearchValues): AvailabilitySearchRequest => ({
    pickUpLocation: searchValues.pickUpBranch,
    dropOffLocation: searchValues.dropOffBranch,
    pickUpDate: formatISO9075(setTime(searchValues.pickUpDate, searchValues.pickUpTime)),
    dropOffDate: formatISO9075(setTime(searchValues.dropOffDate, searchValues.dropOffTime)),
    fleetTypeCode: searchValues.fleetType as FleetTypeCode,
    driverAge: searchValues.driverAge ? Number(searchValues.driverAge) : undefined,
    campaignCode: searchValues.promoCode,
    mergeSurchargesFees: false,
    reservationReference: searchValues.reservationReference,
});
