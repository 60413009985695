'use client';
import React, { createContext } from 'react';

export type FleetTypeRadioGroupState = { direction: 'row' | 'column' };
export const FleetTypeRadioGroupContext = createContext<FleetTypeRadioGroupState>({ direction: 'row' });

export interface FleetTypeRadioGroupContextProviderProps {
    direction?: 'row' | 'column';
    children?: React.ReactNode;
}

export const FleetTypeRadioGroupContextProvider: React.FC<FleetTypeRadioGroupContextProviderProps> = ({ children, direction }) => (
    <FleetTypeRadioGroupContext.Provider value={{ direction: direction || 'row' }}>{children}</FleetTypeRadioGroupContext.Provider>
);
