'use client';

import React, { forwardRef } from 'react';
import { RadioProps, dividerClasses } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { type PaymentType } from '../lib';
import { PaymentTypeRadio } from './PaymentTypeRadio';
import { PaymentTypeRadioSummary } from './PaymentTypeRadioSummary';


export interface PaymentTypeRadioControlProps extends RadioProps {
    paymentType: PaymentType;
}

export const PaymentTypeRadioControl=forwardRef<HTMLButtonElement,PaymentTypeRadioControlProps>(({ paymentType, className, ...props }, ref) => (
    <Stack
        direction="row"
        divider={
            <Divider
                orientation="vertical"
                variant="middle"
                sx={(theme) => ({
                    ...(!props.disabled ? { bgcolor: 'secondary.main' } : undefined),
                    transition: theme.transitions.create(['background-color'], {
                        duration: theme.transitions.duration.short,
                    }),
                    width: 2,
                })}
                flexItem={true}
            />
        }
        spacing={2}
        sx={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            [` .${dividerClasses.root} `]: { my: 1 },
            '*': {
                minWidth: 0,
            },
            lineHeight: 1,
            textAlign: 'center',
            whiteSpace: 'normal',
        }}
    >
        <Box
            sx={{
                flex: {
                    xs: '1 1 220px',
                    sm: '1 1 220px',
                    md: '0 0 122px',
                    lg: '0 0 145px',
                    xl: '0 0 160px',
                },
                minWidth: {
                    md: '90px',
                },
                maxWidth: {
                    xs: '200px',
                    sm: '200px',
                    lg: '150px',
                    xl: '160px',
                },
            }}
        >
            <PaymentTypeRadio paymentType={paymentType} ref={ref} {...props} />
        </Box>
        <Box sx={{ flex: '1 1 100%' }}>
            <PaymentTypeRadioSummary paymentType={paymentType} disabled={props.disabled} />
        </Box>
    </Stack>
));
PaymentTypeRadioControl.displayName = 'PaymentTypeRadioControl';
