import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

export const useGetIdToken = () => {
    const { isLoading: isAuth0Loading, user: auth0User, isAuthenticated, getIdTokenClaims } = useAuth0();
    return useQuery({
        queryKey: ['getIdToken', auth0User?.sub],
        queryFn: () => getIdTokenClaims(),
        enabled: Boolean(!isAuth0Loading && isAuthenticated && auth0User?.sub),
    });
};
