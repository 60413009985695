/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { HirerDetailsHomeAddress } from './HirerDetailsHomeAddress';
import { HirerDetailsHomeAddressFromJSON, HirerDetailsHomeAddressFromJSONTyped, HirerDetailsHomeAddressToJSON } from './HirerDetailsHomeAddress';

/**
 *
 * @export
 * @interface HirerDetails
 */
export interface HirerDetails {
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    mobileNumber?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    dateOfBirth?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    driversLicenceExpiry?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    driversLicenceCountry?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    driversLicenceNumber?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    lucyClubNumber?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    arrivalFlightNumber?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    departureFlightNumber?: string;
    /**
     *
     * @type {string}
     * @memberof HirerDetails
     */
    optIn?: string;
    /**
     *
     * @type {number}
     * @memberof HirerDetails
     */
    numberOfPeople?: number;
    /**
     *
     * @type {HirerDetailsHomeAddress}
     * @memberof HirerDetails
     */
    homeAddress?: HirerDetailsHomeAddress;
}

/**
 * Check if a given object implements the HirerDetails interface.
 */
export function instanceOfHirerDetails(value: object): value is HirerDetails {
    return true;
}

export function HirerDetailsFromJSON(json: any): HirerDetails {
    return HirerDetailsFromJSONTyped(json, false);
}

export function HirerDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HirerDetails {
    if (json == null) {
        return json;
    }
    return {
        firstName: json['FirstName'] == null ? undefined : json['FirstName'],
        lastName: json['LastName'] == null ? undefined : json['LastName'],
        email: json['Email'] == null ? undefined : json['Email'],
        mobileNumber: json['MobileNumber'] == null ? undefined : json['MobileNumber'],
        dateOfBirth: json['DateOfBirth'] == null ? undefined : json['DateOfBirth'],
        driversLicenceExpiry: json['DriversLicenceExpiry'] == null ? undefined : json['DriversLicenceExpiry'],
        driversLicenceCountry: json['DriversLicenceCountry'] == null ? undefined : json['DriversLicenceCountry'],
        driversLicenceNumber: json['DriversLicenceNumber'] == null ? undefined : json['DriversLicenceNumber'],
        lucyClubNumber: json['LucyClubNumber'] == null ? undefined : json['LucyClubNumber'],
        arrivalFlightNumber: json['ArrivalFlightNumber'] == null ? undefined : json['ArrivalFlightNumber'],
        departureFlightNumber: json['DepartureFlightNumber'] == null ? undefined : json['DepartureFlightNumber'],
        optIn: json['OptIn'] == null ? undefined : json['OptIn'],
        numberOfPeople: json['NumberOfPeople'] == null ? undefined : json['NumberOfPeople'],
        homeAddress: json['HomeAddress'] == null ? undefined : HirerDetailsHomeAddressFromJSON(json['HomeAddress']),
    };
}

export function HirerDetailsToJSON(value?: HirerDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        FirstName: value['firstName'],
        LastName: value['lastName'],
        Email: value['email'],
        MobileNumber: value['mobileNumber'],
        DateOfBirth: value['dateOfBirth'],
        DriversLicenceExpiry: value['driversLicenceExpiry'],
        DriversLicenceCountry: value['driversLicenceCountry'],
        DriversLicenceNumber: value['driversLicenceNumber'],
        LucyClubNumber: value['lucyClubNumber'],
        ArrivalFlightNumber: value['arrivalFlightNumber'],
        DepartureFlightNumber: value['departureFlightNumber'],
        OptIn: value['optIn'],
        NumberOfPeople: value['numberOfPeople'],
        HomeAddress: HirerDetailsHomeAddressToJSON(value['homeAddress']),
    };
}
