import Product from '#/lib/api/model/Product';
import { ObeProductLine } from '#/types/ObeProductLine';

export const calculateBundledItemsQuantity = (bundledItems: ObeProductLine[]|undefined, itemLine: Product|undefined): number => {

    if (!itemLine || !bundledItems) {
        return 0;
    }

    let bundledQty = 0;
    for (const bundledItem of bundledItems) {
        if (bundledItem.associatedProductCode === itemLine.ProductCode) {
            bundledQty = bundledQty +  bundledItem.qty;
        }
    }
    return bundledQty
};
