import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common/dist/response/AuthenticationResult';

export type Operator = AccountInfo & {
    isAuthenticated: boolean;
    token?: AuthenticationResult;
};

export const useOperator = (): { operator?: Operator; isLoading: boolean } => {
    const context = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount(context.accounts[0]);
    const { isLoading, data: tokenData } = useQuery({
        queryKey: ['operator', account?.homeAccountId],
        queryFn: () =>
            account
                ? context.instance.acquireTokenSilent({
                      scopes: ['User.Read'],
                      account,
                      forceRefresh: false,
                  })
                : null,
        enabled: isAuthenticated,
    });
    const loading = context.inProgress !== 'none' || (isAuthenticated && isLoading);
    const valid = Boolean(account?.username?.endsWith('jucyworld.com') && tokenData);
    return useMemo(
        () => ({
            isLoading: loading,
            operator: valid
                ? {
                      isAuthenticated,
                      token: tokenData || undefined,
                      ...(account as AccountInfo),
                  }
                : undefined,
        }),
        [valid, loading, isAuthenticated, tokenData, account]
    );
};
