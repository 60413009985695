/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';

/**
 *
 * @export
 * @interface SitesSettingsDefaultTimes
 */
export interface SitesSettingsDefaultTimes {
    /**
     *
     * @type {string}
     * @memberof SitesSettingsDefaultTimes
     */
    pickUp?: string;
    /**
     *
     * @type {string}
     * @memberof SitesSettingsDefaultTimes
     */
    dropOff?: string;
    /**
     * day of week (1=monday, 7=sunday)
     * @type {number}
     * @memberof SitesSettingsDefaultTimes
     */
    dow?: number;
}

/**
 * Check if a given object implements the SitesSettingsDefaultTimes interface.
 */
export function instanceOfSitesSettingsDefaultTimes(value: object): value is SitesSettingsDefaultTimes {
    return true;
}

export function SitesSettingsDefaultTimesFromJSON(json: any): SitesSettingsDefaultTimes {
    return SitesSettingsDefaultTimesFromJSONTyped(json, false);
}

export function SitesSettingsDefaultTimesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SitesSettingsDefaultTimes {
    if (json == null) {
        return json;
    }
    return {
        pickUp: json['pickUp'] == null ? undefined : json['pickUp'],
        dropOff: json['dropOff'] == null ? undefined : json['dropOff'],
        dow: json['dow'] == null ? undefined : json['dow'],
    };
}

export function SitesSettingsDefaultTimesToJSON(value?: SitesSettingsDefaultTimes | null): any {
    if (value == null) {
        return value;
    }
    return {
        pickUp: value['pickUp'],
        dropOff: value['dropOff'],
        dow: value['dow'],
    };
}
