'use client';
import React from 'react';
import { getPaletteColor, mergeSx } from '@jucy-ui/appearance';
import { ButtonProps, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha, rgbToHex, useTheme } from '@mui/material/styles';

interface CardHeaderProps {
    className?: string;
    color?: ButtonProps['color'];
    sx?: SxProps<Theme>;
    plain?: boolean;
    image?: boolean;
    contact?: boolean;
    signup?: boolean;
    stats?: boolean;
    icon?: boolean;
    text?: boolean;
    children?: React.ReactNode;
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
}

export const CardHeader: React.FC<CardHeaderProps> = (props) => {
    const { className, children, color, sx, plain, image, contact, signup, stats, icon, text, title, subTitle, ...rest } = props;
    const theme = useTheme<Theme>();
    const palette = theme.palette.augmentColor({
        color: { main: getPaletteColor(color, theme).main },
    });
    return (
        <Box
            className={className}
            sx={mergeSx(
                {
                    padding: '0.75rem 1.25rem',
                    marginBottom: '0',
                    marginLeft: '15px',
                    marginRight: '15px',
                    borderBottom: 'none',
                    zIndex: '3 !important',
                    ...(plain || image || contact || signup || icon || stats || color
                        ? {
                              padding: '0',
                              position: 'relative',
                              color: theme.palette.common.white,
                          }
                        : undefined),
                    ...(!(plain || image || contact || signup || icon || stats || text)
                        ? {
                              padding: '15px',
                              marginTop: '-20px',
                              borderRadius: '3px',
                          }
                        : undefined),
                    ...(!text && !icon
                        ? {
                              background: `linear-gradient(60deg, ${rgbToHex(palette.light)}, ${palette.main})`,
                              boxShadow: `0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px ${alpha(
                                  palette.main,
                                  typeof theme.palette.tonalOffset === 'number' ? theme.palette.tonalOffset : theme.palette.tonalOffset.light
                              )}`,
                          }
                        : undefined),
                    ...(plain
                        ? {
                              marginLeft: '0px',
                              marginRight: '0px',
                              '& .cardHeaderImage': {
                                  margin: '0 !important',
                              },
                          }
                        : undefined),
                    ...(image
                        ? {
                              position: 'relative',
                              padding: '0',
                              zIndex: '1',
                              '& img': {
                                  width: '100%',
                                  borderRadius: '6px',
                                  pointerEvents: 'none',
                                  boxShadow: `0 5px 15px -8px ${alpha(
                                      theme.palette.common.black,
                                      0.24
                                  )}, 0 8px 10px -5px  alpha(theme.palette.common.black,0.2)`,
                              },
                              '& a': {
                                  display: 'block',
                              },
                          }
                        : undefined),
                    ...(contact
                        ? {
                              margin: '0 15px',
                              marginTop: '-20px',
                          }
                        : undefined),
                    ...(signup
                        ? {
                              marginLeft: '20px',
                              marginRight: '20px',
                              marginTop: '-40px',
                              padding: '20px 0',
                              width: '100%',
                              marginBottom: '15px',
                          }
                        : undefined),
                    ...(stats
                        ? {
                              ...(icon ? { textAlign: 'right' } : undefined),
                              '& h1,& h2,& h3,& h4,& h5,& h6': {
                                  margin: '0 !important',
                              },
                          }
                        : undefined),
                    ...(icon
                        ? {
                              paddingTop: '20px',
                              '& .fab,& .fas,& .far,& .fal,& .material-icons': {
                                  width: '33px',
                                  height: '33px',
                                  textAlign: 'center',
                                  lineHeight: '33px',
                              },
                              '& svg': {
                                  width: '24px',
                                  height: '24px',
                                  textAlign: 'center',
                                  lineHeight: '33px',
                                  margin: '5px 4px 0px',
                              },
                          }
                        : undefined),
                    ...(text
                        ? {
                              py:1,
                              position: 'relative',
                          }
                        : undefined),
                },
                sx
            )}
            {...rest}
        >
            {children}

            {title && (
                <Typography
                    variant="h4"
                    sx={{
                        color: 'rgba(255,255,255,.62)',
                        margin: '0',
                        marginTop: '0',
                        marginBottom: '0',
                        ...(text
                            ? {
                                  ...theme.typography.h4,
                                  color: theme.palette.grey[900],
                                  textDecoration: 'none',
                                  '& small': {
                                      color: theme.palette.grey[600],
                                      fontSize: '65%',
                                      fontWeight: '400',
                                      lineHeight: '1',
                                  },
                              }
                            : undefined),
                        ...(icon
                            ? {
                                  color: theme.palette.grey[900],
                                  textDecoration: 'none',
                                  fontWeight: '300',
                                  '& small': {
                                      color: theme.palette.grey[600],
                                      fontSize: '65%',
                                      fontWeight: '400',
                                      lineHeight: '1',
                                  },
                                  marginTop: '15px',
                                  marginBottom: '0px',
                                  minHeight: 'auto',
                                  '& a': {
                                      color: theme.palette.grey[900],
                                      textDecoration: 'none',
                                      fontWeight: '300',
                                      '& small': {
                                          color: theme.palette.grey[600],
                                          fontSize: '65%',
                                          fontWeight: '400',
                                          lineHeight: '1',
                                      },
                                      marginTop: '.625rem',
                                      marginBottom: '0.75rem',
                                      minHeight: 'auto',
                                  },
                                  fontSize: '1.3em',
                              }
                            : undefined),
                    }}
                >
                    {title}
                </Typography>
            )}
            {subTitle && (
                <Box
                    sx={{
                        color: '#FFFFFF',
                        marginTop: '0px',
                        minHeight: 'auto',
                        fontWeight: 300,
                        marginBottom: '3px',
                        textDecoration: 'none',
                    }}
                >
                    {subTitle}
                </Box>
            )}
        </Box>
    );
};
