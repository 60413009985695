'use client';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { CatalogItem, FleetCategory, useIsMounted } from '@jucy-ui/common';
import { SlotComponentProps, useSlotProps } from '@mui/base';
import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useThemeProps } from '@mui/material/styles';
import { FleetCategoryTeaserCarousel } from '../TeaserCard/components/FleetCategoryTeaserCarousel';
import { AvailabilityGridFooter } from './AvailabilityGridFooter';
import { AvailabilityGridRows } from './AvailabilityGridRows';
import { AvailabilityGridPlaceHolder } from './components/AvailabilityGridPlaceHolder';

export interface AvailabilityGridSlots {
    root?: typeof Box;
    placeholder?: typeof AvailabilityGridPlaceHolder;
    rows?: typeof AvailabilityGridRows;
    header?: React.ElementType;
    footer?: React.ElementType;
}

export interface AvailabilityGridOwnerState extends AvailabilityGridProps {}

const AvailabilityGridRoot = styled('div', {
    name: 'JucyAvailabilityGrid',
    slot: 'root',
})<{ ownerState?: AvailabilityGridOwnerState }>(() => ({
    marginTop: 2,
}));
export interface AvailabilityGridProps extends Omit<BoxProps, 'title' | 'onSelect' | 'ref'> {
    className?: string;
    onSelect?: (fleetCategory: FleetCategory) => void | Promise<void>;
    fleetCategories: FleetCategory[];
    productCatalog: CatalogItem[];
    loading?: boolean;
    highlightedCode?: string;
    activeCode?: string;
    isSelecting?: boolean;
    title?: ReactNode;
    caption?: ReactNode;
    moreOptionsTitle?: ReactNode;
    moreOptionsCaption?: ReactNode;
    action?: 'create' | 'edit';
    slots?: AvailabilityGridSlots;
    slotProps?: {
        root?: SlotComponentProps<'div', object, AvailabilityGridOwnerState>;
        placeholder?: SlotComponentProps<typeof AvailabilityGridPlaceHolder, object, AvailabilityGridOwnerState>;
        rows?: SlotComponentProps<typeof AvailabilityGridRows, object, AvailabilityGridOwnerState>;
        header?: SlotComponentProps<'div', object, AvailabilityGridOwnerState>;
        footer?: SlotComponentProps<'div', object, AvailabilityGridOwnerState>;
    };
}

export const AvailabilityGrid = React.forwardRef<HTMLDivElement, AvailabilityGridProps>((inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: 'JucyAvailabilityGrid' });
    const {
        fleetCategories,
        onSelect,
        activeCode: passedActiveCode,
        isSelecting: passedIsSelecting,
        productCatalog,
        loading,
        highlightedCode,
        title,
        caption,
        moreOptionsTitle,
        moreOptionsCaption,
        action,
        slotProps,
        className,
        ...other
    } = props;
    const ownerState = props;
    const [activeCode, setActiveCode] = useState(passedActiveCode);
    const [internalIsSelecting, setInternalIsSelecting] = useState(Boolean(passedIsSelecting));
    const isMounted = useIsMounted();
    const handleIsSelecting = typeof passedIsSelecting === 'undefined';
    const isSelecting = handleIsSelecting ? internalIsSelecting : passedIsSelecting;
    const handleOnSelect = useCallback(
        (fleetCategory: FleetCategory) => {
            setActiveCode(fleetCategory.code);
            setInternalIsSelecting(true);
            (async () => {
                await onSelect?.(fleetCategory);
                if (isMounted()) {
                    setInternalIsSelecting(false);
                }
            })();
        },
        [isMounted, onSelect]
    );

    useEffect(() => {
        if (!handleIsSelecting) {
            setActiveCode(passedActiveCode);
        }
    }, [handleIsSelecting, passedActiveCode]);
    const RootSlot = props.slots?.root || AvailabilityGridRoot;
    const PlaceholderSlot = props.slots?.placeholder || AvailabilityGridPlaceHolder;
    const RowsSlot = props.slots?.rows || AvailabilityGridRows;
    const HeaderSlot = props.slots?.header;
    const FooterSlot = props.slots?.header || AvailabilityGridFooter;
    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            ref,
        },
        ownerState,
        className: className,
    });
    const placeholderProps = useSlotProps({
        elementType: FleetCategoryTeaserCarousel,
        externalSlotProps: slotProps?.placeholder,
        additionalProps: {
            title: title,
            caption: caption,
        },
        ownerState,
    });
    const rowsProps = useSlotProps({
        elementType: FleetCategoryTeaserCarousel,
        externalSlotProps: slotProps?.rows,
        additionalProps: {
            fleetCategories: fleetCategories,
            productCatalog: productCatalog,
            highlightedCode: highlightedCode,
            activeCode: activeCode,
            selecting: isSelecting,
            onSelect: handleOnSelect,
            moreOptionsTitle: moreOptionsTitle,
            moreOptionsCaption: moreOptionsCaption,
            action: action,
        },
        ownerState,
    });
    const headerProps = useSlotProps({
        elementType: Box,
        externalSlotProps: slotProps?.header,
        additionalProps: {
            title: title,
            caption: caption,
        },
        ownerState,
    });
    const footerProps = useSlotProps({
        elementType: AvailabilityGridFooter,
        externalSlotProps: slotProps?.header,
        additionalProps: {
            fleetCategories: fleetCategories,
        },
        ownerState,
    });
    return (
        <RootSlot {...rootProps}>
            {HeaderSlot ? <HeaderSlot {...headerProps} /> : null}
            <>
                {title ? (
                    <>
                        <Typography variant="button" textTransform="none">
                            {title}
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                    </>
                ) : null}
                {caption ? <Typography mb={2}>{caption}</Typography> : null}
            </>
            {loading ? <PlaceholderSlot {...placeholderProps} /> : <RowsSlot {...rowsProps} />}
            <FooterSlot {...footerProps} />
        </RootSlot>
    );
});
AvailabilityGrid.displayName = 'AvailabilityGrid';
