'use client';
import React, { type RefObject } from 'react';
import { CountryData } from '@jucy-ui/common';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { unstable_useId as useId } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import { CountryFlagOrCode } from '../../../CountryFlagOrCode';
import { CountrySelectList } from '../CountrySelectList';

export interface PhoneInputAdornmentProps {
    menuAnchorEl: RefObject<HTMLDivElement> | null;
    value?: CountryData | null;
    onChange?: (value: CountryData | null) => void;
    disabled?: boolean;
}

export const PhoneNumberInputComponentAdornment: React.FC<PhoneInputAdornmentProps> = ({ menuAnchorEl, disabled, value, onChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const randId = useId(anchorEl?.id);
    const handleClick = () => {
        if (!disabled && menuAnchorEl?.current) {
            setAnchorEl(menuAnchorEl.current);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? randId : undefined;
    return (
        <>
            <InputAdornment
                position="start"
                onClick={handleClick}
                sx={(theme) => ({
                    cursor: disabled ? 'auto' : 'pointer',
                    flex: '1 1',
                    maxHeight: '100%',
                    height: 'auto',
                    ml: -1.5,
                    opacity: disabled ? 0.26 : 1,
                    transition: theme.transitions.create(['opacity'], {
                        duration: theme.transitions.duration.short,
                    }),
                })}
            >
                <ButtonBase sx={{ pl: 1.5, height: '100%' }} disabled={disabled}>
                    <Box sx={{ width: '23px' }}>{value?.code ? <CountryFlagOrCode sx={{ color: 'black' }} code={value.code} /> : null}</Box>
                    <ArrowDropDownIcon />
                </ButtonBase>
            </InputAdornment>
            <Popover
                id={id}
                open={open}
                anchorEl={menuAnchorEl?.current}
                onClose={handleClose}
                hideBackdrop={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <CountrySelectList
                    value={value}
                    id={`${id}-listbox`}
                    onChange={(e, country) => {
                        handleClose();
                        onChange?.(country);
                    }}
                />
            </Popover>
        </>
    );
};
