import { observer } from 'mobx-react-lite';
import React from 'react';
import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { RadioGroup } from 'formik-mui';
import paymentStore from '../../store/PaymentStore';
import { BookingCart } from '../../types/BookingCart';
import { PaymentOptionRadioCard } from './PaymentOptionRadioCard';

const PaymentOptions: React.FC<{ summary: BookingCart }> = observer(({ summary }) => {
    const paymentOptions = paymentStore.getPaymentOptions(summary);
    const form = useFormikContext();
    const { isSubmitting } = form;
    return (
        <Field component={RadioGroup} name="paymentType" onBlur={() => undefined}>
            <Grid container spacing={3} className="payment-options">
                {paymentOptions.map((p) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} key={p.id}>
                        <PaymentOptionRadioCard paymentOption={p} disabled={isSubmitting} />
                    </Grid>
                ))}
            </Grid>
        </Field>
    );
});

export default PaymentOptions;
