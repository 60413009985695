'use client';
import { styled } from '@mui/material/styles';

export const ComparisonTableBody = styled('tbody', {
    name: 'JucyComparisonTableBody',
    slot: 'Root',
})({
    zIndex: '10',
    position: 'relative',
});
