'use client';
import React from 'react';
import { styled } from '@mui/material/styles';

export type ComparisonTableRowProps = { variant?: 'odd' | 'even' } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
>;
export const ComparisonTableRow = styled('tr', {
    shouldForwardProp: (prop) => prop !== 'variant',
    name: 'JucyComparisonTableRow',
    slot: 'Root',
})<ComparisonTableRowProps>(({ theme, variant }) => {
    let background: string | undefined = undefined;
    if (variant === 'odd') {
        background = theme.palette.grey[100];
    }
    if (variant === 'even') {
        background = theme.palette.common.white;
    }
    return {
        background,
        '& th': {
            background,
        },
    };
});
