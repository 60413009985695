'use client';
import { styled } from '@mui/material/styles';
import { comparisonTableCellSharedStyles } from './shared';

export const ComparisonTableCellValue = styled('td', {
    name: 'JucyComparisonTableCellValue',
    slot: 'Root',
})({
    ...comparisonTableCellSharedStyles,
    textAlign: 'center',
    fontWeight: '700',
});
