import { alpha, darken, lighten } from '@mui/material';
import { Palette } from '@mui/material/styles/createPalette';
import { defaultThemeColors } from './defaultThemeColors';

export const createThemeColors = (palette: Palette) => ({
    gradients: {
        blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
        blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
        blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
        blue4: 'linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)',
        blue5: 'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',
        orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
        orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
        orange3: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
        purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
        purple3: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
        pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
        green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
        green2: 'linear-gradient(to bottom, #00b09b, #96c93d)',
        black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
        black2: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
    },
    shadows: {
        success: '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
        error: '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
        info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
        primary: '0px 1px 4px rgba(28, 52, 140, 0.25), 0px 3px 12px 2px rgba(28, 52, 140, 0.35)',
        warning: '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
        card: '0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
        cardSm: '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
        cardLg: '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
    },
    layout: {
        general: {
            bodyBg: '#F8F8F8',
        },
        sidebar: {
            background: palette.background.paper,
            textColor: palette.secondary.main,
            dividerBg: palette.divider,
            menuItemColor: palette.secondary.main,
            menuItemColorActive: palette.primary.main,
            menuItemBg: defaultThemeColors.white,
            menuItemBgActive: palette.light.main,
            menuItemIconColor: lighten(palette.secondary.main, 0.3),
            menuItemIconColorActive: palette.primary.main,
            menuItemHeadingColor: darken(palette.secondary.main, 0.3),
        },
    },
    alpha: {
        white: {
            5: alpha(defaultThemeColors.white, 0.02),
            10: alpha(defaultThemeColors.white, 0.1),
            30: alpha(defaultThemeColors.white, 0.3),
            50: alpha(defaultThemeColors.white, 0.5),
            70: alpha(defaultThemeColors.white, 0.7),
            100: defaultThemeColors.white,
        },
        trueWhite: {
            5: alpha(defaultThemeColors.white, 0.02),
            10: alpha(defaultThemeColors.white, 0.1),
            30: alpha(defaultThemeColors.white, 0.3),
            50: alpha(defaultThemeColors.white, 0.5),
            70: alpha(defaultThemeColors.white, 0.7),
            100: defaultThemeColors.white,
        },
        black: {
            5: alpha(defaultThemeColors.black, 0.02),
            10: alpha(defaultThemeColors.black, 0.1),
            30: alpha(defaultThemeColors.black, 0.3),
            50: alpha(defaultThemeColors.black, 0.5),
            70: alpha(defaultThemeColors.black, 0.7),
            100: defaultThemeColors.black,
        },
    },
    secondary: {
        lighter: lighten(palette.secondary.main, 0.85),
        light: lighten(palette.secondary.main, 0.25),
        main: palette.secondary.main,
        dark: darken(palette.secondary.main, 0.2),
    },
    primary: {
        lighter: lighten(palette.primary.main, 0.85),
        light: lighten(palette.primary.main, 0.3),
        main: palette.primary.main,
        dark: darken(palette.primary.main, 0.2),
    },
    success: {
        lighter: lighten(palette.success.main, 0.85),
        light: lighten(palette.success.main, 0.3),
        main: palette.success.main,
        dark: darken(palette.success.main, 0.2),
    },
    warning: {
        lighter: lighten(palette.warning.main, 0.85),
        light: lighten(palette.warning.main, 0.3),
        main: palette.warning.main,
        dark: darken(palette.warning.main, 0.2),
    },
    error: {
        lighter: lighten(palette.error.main, 0.85),
        light: lighten(palette.error.main, 0.3),
        main: palette.error.main,
        dark: darken(palette.error.main, 0.2),
    },
    info: {
        lighter: lighten(palette.info.main, 0.85),
        light: lighten(palette.info.main, 0.3),
        main: palette.info.main,
        dark: darken(palette.info.main, 0.2),
    },
});
