'use client';
import React from 'react';
import { cn } from '@jucy-ui/common';
import { useController } from '@jucy-ui/forms';
import { PromoCodeField, PromoCodeFieldProps } from '../../fields';
import { jucySearchFormClasses } from '../lib';

export type SearchFormPromoCodeProps = Omit<
    PromoCodeFieldProps,
    'onChange' | 'onBlur' | 'value' | 'name' | 'ref' | 'required' | 'label' | 'error' | 'helperText'
>;

export const SearchFormPromoCode: React.FC<SearchFormPromoCodeProps> = ({ className, ...props }) => {
    const { field } = useController({ name: 'promoCode' });
    return <PromoCodeField className={cn(jucySearchFormClasses.promoCode, className)} {...field} {...props} />;
};
