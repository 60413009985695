import { RangePosition } from '@mui/x-date-pickers-pro';
import { getMinPickUp, getServiceHours, searchFormValuesToData } from '../lib';
import { useBranchSettings } from './useBranchSettings';
import { useSearchFormFieldValue } from './useSearchFormFieldValue';

export const useGetMinMaxTime = (position: RangePosition) => {
    const pickUpDate = useSearchFormFieldValue('pickUpDate');
    const dropOffDate = useSearchFormFieldValue('dropOffDate');
    const fleetTypeSlug = useSearchFormFieldValue('fleetType');
    const pickUpBranchCode = useSearchFormFieldValue('pickUpBranch');
    const dropOffBranchCode = useSearchFormFieldValue('dropOffBranch');
    const { pickUpBranch, fleetType, dropOffBranch } = searchFormValuesToData({
        fleetType: fleetTypeSlug,
        pickUpBranch: pickUpBranchCode,
        dropOffBranch: dropOffBranchCode,
    });
    const { pickUpBranchSettings, dropOffBranchSettings } = useBranchSettings();
    if (position === 'start' && pickUpBranch && fleetType) {
        const serviceHours = getServiceHours({ date: pickUpDate, siteSettings: pickUpBranchSettings });
        return {
            minTime: getMinPickUp({ date: pickUpDate, branch: pickUpBranch, fleetType }),
            maxTime: serviceHours?.close,
        };
    }
    if (position === 'end' && dropOffBranch && fleetType) {
        const serviceHours = getServiceHours({ date: dropOffDate, siteSettings: dropOffBranchSettings });
        return {
            minTime: serviceHours?.open,
            maxTime: serviceHours?.close,
        };
    }
    return {
        minTime: undefined,
        maxTime: undefined,
    };
};
