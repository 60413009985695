'use client';
import { styled } from '@mui/material/styles';

export const ComparisonTableHead = styled('thead', {
    name: 'JucyComparisonTableHead',
    slot: 'Root',
})({
    boxSizing: 'border-box',
    backgroundClip: 'padding-box',
    scrollSnapAlign: 'start',
    padding: '.5em',
    height: '2.3em',
});
