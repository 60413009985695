import RentalsApiClientV3 from './RentalsApiClientV3';
import {
    AvailabilityApi,
    CatalogApi,
    Configuration,
    ConfigurationParameters,
    DealsApi,
    PricingApi,
    ReservationsApi,
    SelfServiceApi,
    SitesApi,
} from './rentals-api';

class RentalsApiClient {
    config: Configuration;
    availability: AvailabilityApi;
    catalog: CatalogApi;
    deals: DealsApi;
    pricing: PricingApi;
    reservations: ReservationsApi;
    selfService: SelfServiceApi;
    sites: SitesApi;
    v3: RentalsApiClientV3;

    constructor(config: ConfigurationParameters) {
        if (!config.fetchApi) {
            config.fetchApi = fetch;
        }
        this.config = new Configuration(config);
        this.availability = new AvailabilityApi(this.config);
        this.catalog = new CatalogApi(this.config);
        this.deals = new DealsApi(this.config);
        this.pricing = new PricingApi(this.config);
        this.reservations = new ReservationsApi(this.config);
        this.selfService = new SelfServiceApi(this.config);
        this.sites = new SitesApi(this.config);
        this.v3 = new RentalsApiClientV3(config);
    }

    getReservationStoreCardURL(ref: string, returnUrl?: string): string {
        const result = new URL(`/api/self-service/reservation/ref/${ref}/store-card`, this.config.basePath);
        if (returnUrl) {
            result.searchParams.append('returnUrl', returnUrl);
        }
        return result.toString();
    }

    getReservationPaymentURL(ref: string, amount?: number, returnUrl?: string): string {
        const result = new URL(`/api/self-service/reservation/ref/${ref}/payment`, this.config.basePath);
        if (returnUrl) {
            result.searchParams.append('returnUrl', returnUrl);
        }
        if (amount) {
            result.searchParams.append('amount', `${amount}`);
        }
        return result.toString();
    }
}

export default RentalsApiClient;
