import React, { useCallback, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Box, BoxProps, Card, CardProps, FormControlLabel, FormControlLabelProps, Radio, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { useField } from 'formik';
import { JucyMuiTextButton } from '../Button/Button';
import ModalDialog from '../ModalDialog';
import { PaymentOption } from './lib';

export interface PaymentOptionRadioCardProps extends Omit<FormControlLabelProps, 'value' | 'control' | 'label'> {
    paymentOption: PaymentOption;
}

const PaymentOptionCard: React.FC<CardProps & StackProps & { selected: boolean }> = ({ selected, children, ...props }) => {
    const { spacing, transitions } = useTheme();
    return (
        <Card
            {...props}
            component={Stack}
            variant="outlined"
            spacing={{
                xs: 2,
                sm: 1,
                md: 2,
                lg: 1,
                xl: 2,
            }}
            sx={{
                height: {
                    xs: 120,
                    sm: 120,
                    md: 100,
                    lg: 120,
                    xl: 120,
                },
                display: 'flex',
                flex: '1 1',
                color: 'grey.800',
                ...(selected
                    ? {
                          borderColor: 'primary.main',
                          borderWidth: '2px',
                          p: {
                              xs: `${parseInt(spacing(2)) - 1}px`,
                              sm: `${parseInt(spacing(1)) - 1}px`,
                              md: `${parseInt(spacing(2)) - 1}px`,
                              lg: `${parseInt(spacing(1)) - 1}px`,
                              xl: `${parseInt(spacing(2)) - 1}px`,
                          },
                      }
                    : {
                          p: {
                              xs: spacing(2),
                              sm: spacing(1),
                              md: spacing(2),
                              lg: spacing(1),
                              xl: spacing(2),
                          },
                      }),
                transition: transitions.create(['border-color']),
            }}
        >
            {children}
        </Card>
    );
};

const PaymentOptionTitle: React.FC<BoxProps> = ({ children, ...props }) => (
    <Box
        {...props}
        sx={{
            alignItems: 'center',
            display: 'flex',
            fontWeight: 'bold',
            fontSize: {
                xs: 22,
                sm: 20,
                md: 16,
                lg: 20,
                xl: 22,
            },
            textAlign: 'center',
            flex: {
                xs: '1 1 150px',
                sm: '1 1 122px',
                md: '1 1 122px',
                lg: '1 1 150px',
                xl: '1 1 160px',
            },
            minWidth: {
                md: '90px',
            },
            maxWidth: {
                xs: '160px',
                sm: '130px',
                lg: '150px',
                xl: '160px',
            },
            '& img': {
                maxWidth: '100%',
                height: 'auto',
            },
        }}
    >
        {children}
    </Box>
);

const PaymentOptionAmount: React.FC<BoxProps & { amountInfo?: React.ReactNode; amount: React.ReactNode }> = ({ amountInfo, amount, children, ...props }) => (
    <Box {...props} textAlign={{ textAlign: 'center' }} component="div">
        {amountInfo ? (
            <Typography component="div" variant="caption" sx={{ lineHeight: 1.2 }}>
                {amountInfo}
            </Typography>
        ) : null}
        <Typography
            sx={{
                fontSize: {
                    xs: 18,
                    sm: 18,
                    md: 14,
                    lg: 18,
                    xl: 18,
                },
            }}
            component="div"
            variant="body2"
            fontWeight={700}
            whiteSpace={'nowrap'}
        >
            {amount}
        </Typography>
        {children}
    </Box>
);

export const PaymentOptionRadioCard: React.FC<PaymentOptionRadioCardProps> = ({ paymentOption, ...props }) => {
    const [{ value }] = useField('paymentType');
    const selected = value === paymentOption.id;
    const [isOpen, setOpen] = useState(false);
    const toggleModel = useCallback(
        (event: React.SyntheticEvent) => {
            event?.preventDefault();
            setOpen(!isOpen);
        },
        [isOpen]
    );
    return (
        <>
            <FormControlLabel
                sx={{ marginRight: 0, marginLeft: 0, display: 'flex' }}
                value={paymentOption.id}
                checked={selected}
                control={
                    <PaymentOptionCard selected={selected} direction="row">
                        <PaymentOptionTitle>
                            <Radio value={paymentOption.id} disabled={props.disabled} sx={{ p: 0, mr: 1 }} />
                            <span>{paymentOption.name}</span>
                        </PaymentOptionTitle>
                        <Box
                            sx={{
                                borderColor: 'secondary.main',
                                borderLeftWidth: 2,
                                borderLeftStyle: 'solid',
                            }}
                        />
                        <Box sx={{ display: 'flex', flex: '1 1', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <PaymentOptionAmount amount={paymentOption.amount} amountInfo={paymentOption.amountInfo} />
                            <JucyMuiTextButton
                                sx={{ p: 0, '& .MuiButton-startIcon': { mr: '2px' }, whiteSpace: 'nowrap' }}
                                onClick={() => setOpen(true)}
                                startIcon={<InfoIcon fontSize="small" />}
                            >
                                More details
                            </JucyMuiTextButton>
                            <ModalDialog open={isOpen} onClose={toggleModel} {...paymentOption.modalProps}>
                                {paymentOption.modalBody}
                            </ModalDialog>
                        </Box>
                    </PaymentOptionCard>
                }
                disableTypography={true}
                label={null}
                {...props}
            />
        </>
    );
};
