/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { GetAllFleetTypesResponse, GetFleetTypeCategoriesResponse } from '../models/index';
import {
    GetAllFleetTypesResponseFromJSON,
    GetAllFleetTypesResponseToJSON,
    GetFleetTypeCategoriesResponseFromJSON,
    GetFleetTypeCategoriesResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetAllFleetTypesRequest {
    languageCode?: string;
}

export interface GetFleetTypeCategoriesRequest {
    accountKey: string;
    typeId: string;
    pickUpLocation: string;
    dropOffLocation: string;
    pickUpDateTime: Date;
    dropOffDateTime: Date;
    driverAge?: string;
    campaignCode?: string;
    mergeSurchargesFees?: GetFleetTypeCategoriesMergeSurchargesFeesEnum;
}

/**
 *
 */
export class AvailabilityApi extends runtime.BaseAPI {
    /**
     * Returns a list of fleet types and locations the fleet types is available from
     * List available fleet types
     */
    async getAllFleetTypesRaw(
        requestParameters: GetAllFleetTypesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<GetAllFleetTypesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['languageCode'] != null) {
            queryParameters['languageCode'] = requestParameters['languageCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/Fleet/1.0/GetAllFleetTypes`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllFleetTypesResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of fleet types and locations the fleet types is available from
     * List available fleet types
     */
    async getAllFleetTypes(languageCode?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllFleetTypesResponse> {
        const response = await this.getAllFleetTypesRaw({ languageCode: languageCode }, initOverrides);
        return await response.value();
    }

    /**
     * real time lookup on current pricing and availability for given locations and dates
     * Get availability and pricing
     */
    async getFleetTypeCategoriesRaw(
        requestParameters: GetFleetTypeCategoriesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<GetFleetTypeCategoriesResponse>> {
        if (requestParameters['accountKey'] == null) {
            throw new runtime.RequiredError(
                'accountKey',
                'Required parameter "accountKey" was null or undefined when calling getFleetTypeCategories().'
            );
        }

        if (requestParameters['typeId'] == null) {
            throw new runtime.RequiredError('typeId', 'Required parameter "typeId" was null or undefined when calling getFleetTypeCategories().');
        }

        if (requestParameters['pickUpLocation'] == null) {
            throw new runtime.RequiredError(
                'pickUpLocation',
                'Required parameter "pickUpLocation" was null or undefined when calling getFleetTypeCategories().'
            );
        }

        if (requestParameters['dropOffLocation'] == null) {
            throw new runtime.RequiredError(
                'dropOffLocation',
                'Required parameter "dropOffLocation" was null or undefined when calling getFleetTypeCategories().'
            );
        }

        if (requestParameters['pickUpDateTime'] == null) {
            throw new runtime.RequiredError(
                'pickUpDateTime',
                'Required parameter "pickUpDateTime" was null or undefined when calling getFleetTypeCategories().'
            );
        }

        if (requestParameters['dropOffDateTime'] == null) {
            throw new runtime.RequiredError(
                'dropOffDateTime',
                'Required parameter "dropOffDateTime" was null or undefined when calling getFleetTypeCategories().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['typeId'] != null) {
            queryParameters['typeId'] = requestParameters['typeId'];
        }

        if (requestParameters['pickUpLocation'] != null) {
            queryParameters['pickUpLocation'] = requestParameters['pickUpLocation'];
        }

        if (requestParameters['dropOffLocation'] != null) {
            queryParameters['dropOffLocation'] = requestParameters['dropOffLocation'];
        }

        if (requestParameters['pickUpDateTime'] != null) {
            queryParameters['pickUpDateTime'] = (requestParameters['pickUpDateTime'] as any).toISOString();
        }

        if (requestParameters['dropOffDateTime'] != null) {
            queryParameters['dropOffDateTime'] = (requestParameters['dropOffDateTime'] as any).toISOString();
        }

        if (requestParameters['driverAge'] != null) {
            queryParameters['driverAge'] = requestParameters['driverAge'];
        }

        if (requestParameters['campaignCode'] != null) {
            queryParameters['campaignCode'] = requestParameters['campaignCode'];
        }

        if (requestParameters['mergeSurchargesFees'] != null) {
            queryParameters['mergeSurchargesFees'] = requestParameters['mergeSurchargesFees'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/Account/{accountKey}/2.0/GetFleetTypeCategories`.replace(
                    `{${'accountKey'}}`,
                    encodeURIComponent(String(requestParameters['accountKey']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFleetTypeCategoriesResponseFromJSON(jsonValue));
    }

    /**
     * real time lookup on current pricing and availability for given locations and dates
     * Get availability and pricing
     */
    async getFleetTypeCategories(
        accountKey: string,
        typeId: string,
        pickUpLocation: string,
        dropOffLocation: string,
        pickUpDateTime: Date,
        dropOffDateTime: Date,
        driverAge?: string,
        campaignCode?: string,
        mergeSurchargesFees?: GetFleetTypeCategoriesMergeSurchargesFeesEnum,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<GetFleetTypeCategoriesResponse> {
        const response = await this.getFleetTypeCategoriesRaw(
            {
                accountKey: accountKey,
                typeId: typeId,
                pickUpLocation: pickUpLocation,
                dropOffLocation: dropOffLocation,
                pickUpDateTime: pickUpDateTime,
                dropOffDateTime: dropOffDateTime,
                driverAge: driverAge,
                campaignCode: campaignCode,
                mergeSurchargesFees: mergeSurchargesFees,
            },
            initOverrides
        );
        return await response.value();
    }
}

/**
 * @export
 */
export const GetFleetTypeCategoriesMergeSurchargesFeesEnum = {
    True: 'true',
    False: 'false',
} as const;
export type GetFleetTypeCategoriesMergeSurchargesFeesEnum =
    (typeof GetFleetTypeCategoriesMergeSurchargesFeesEnum)[keyof typeof GetFleetTypeCategoriesMergeSurchargesFeesEnum];
