import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { ButtonBase, ButtonBaseProps, ButtonProps, Palette, PaletteColor, useTheme } from '@mui/material';
import { mergeSx } from '#/lib/mergeSx';

export type SlideBubbleProps = ButtonBaseProps & {
    showMarker?: boolean;
    width?: number;
    height?: number;
    color?: ButtonProps['color'];
    href?: string;
    LinkComponent?: React.ElementType;
};
export const SlideBubble = forwardRef<HTMLButtonElement | HTMLAnchorElement, SlideBubbleProps>(
    (
        {
            href,
            width,
            height,
            color,
            showMarker,
            sx,

            children,
            ...props
        },
        ref
    ) => {
        const t = useTheme();
        const fill = (t.palette[color as keyof Palette] as PaletteColor)?.main || color || t.palette.primary.main;
        const extraProps = href ? { component: Link as unknown as undefined, to: href } : { component: 'div' };
        return (
            <ButtonBase
                ref={ref as unknown as undefined}
                sx={mergeSx(
                    {
                        width: width || 75,
                        height: height || 75,
                        borderRadius: '50%',
                        position: 'relative',
                        '& svg': {
                            zIndex: -1,
                            fill: fill,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                        },
                        '& .marker': {
                            ...(!showMarker ? { display: 'none' } : undefined),
                        },
                    },
                    sx
                )}
                {...props}
                {...extraProps}
            >
                {children}
                <svg viewBox="0 0 80 92.5" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <clipPath clipPathUnits="userSpaceOnUse" id="a">
                            <path className="powerclip" d="M22.6 62.7h34.8v34.8H22.6Zm22.9 22.6a5.5 3.7 0 0 0-5.5-3.7 5.5 3.7 0 0 0-5.5 3.7A5.5 3.7 0 0 0 40 89a5.5 3.7 0 0 0 5.5-3.7z" />
                        </clipPath>
                    </defs>
                    <path
                        className="background marker"
                        clipPath="url(#a)"
                        d="M52.4 80.1A12.4 12.4 0 0 1 40 92.5a12.4 12.4 0 0 1-12.4-12.4A12.4 12.4 0 0 1 40 67.7a12.4 12.4 0 0 1 12.4 12.4Z"
                    />
                    <circle className="background" cx="40" cy="40" r="40" />
                </svg>
            </ButtonBase>
        );
    }
);
export default SlideBubble;
