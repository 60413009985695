'use client';
import React from 'react';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { CSSObject, styled, svgIconClasses } from '@mui/material';
import Box from '@mui/material/Box';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';

export interface HourGlassProps {
    color?: SvgIconProps['color'] | string;
    sandColor?: SvgIconProps['color'] | 'auto' | string;
    animate?: boolean;
    size?: CSSObject['fontSize'] | number;
}

const sandColorMap = {
    primary: 'secondary',
    secondary: 'primary',
    error: 'success',
    success: 'error',
    warning: 'info',
    info: 'warning',
};
const HourGlassRoot = styled(Box, {
    shouldForwardProp: (prop) => !['color', 'sandColor', 'animate', 'size'].includes(prop as string),
    name: 'HourGlass',
    slot: 'Root',
    overridesResolver: (props, styles) => [styles.root, props.color === 'primary' && styles.primary, props.color === 'secondary' && styles.secondary],
})<HourGlassProps>(({ theme, color = 'primary', sandColor = 'auto', size = 'inherit', animate = true }) => {
    const findColor = (color: HourGlassProps['color']) =>
        (theme.palette[color as keyof typeof theme.palette] as Partial<SimplePaletteColorOptions>).main ??
        (
            {
                action: theme.palette.action.active,
                disabled: theme.palette.action.disabled,
                inherit: undefined,
            } as unknown as SvgIconProps['color']
        )?.[color as keyof SvgIconProps['color']];
    const resolvedColor = findColor(color) || color;
    let resolvedSandColor = findColor(sandColor) || sandColor;
    if (sandColor === 'auto' && color in sandColorMap) {
        resolvedSandColor = findColor(sandColorMap[color as keyof typeof sandColorMap]) || '';
    }
    return {
        '--hour-glass-width': 'calc(1em * 0.5)',
        '--hour-glass-height': 'calc(calc(1em * 0.833333))',
        '--hour-cup-width': 'calc(var(--hour-glass-width) - 1px)',
        '--hour-cup-height': 'calc(var(--hour-glass-height) / 2 )',
        '--hour-cup-offset': 'calc(1em * 0.09)',
        fontSize: size,
        color: resolvedColor,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        transform: 'rotate3d(0)',
        position: 'relative',
        animation: animate ? 'hour-glass-rotate 5s ease-in-out infinite' : undefined,
        [` .${svgIconClasses.root}`]: {
            zIndex: '2',
            position: 'relative',
        },
        '& .cup': {
            position: 'absolute',
            zIndex: '1',
            height: 'var(--hour-cup-height)',
            width: 'var(--hour-cup-width)',
            borderRadius: '50% 50% 0 0/100% 100% 0 0',
            overflow: 'hidden',
            '&::before': {
                content: '" "',
                display: 'block',
                background: resolvedSandColor,
                height: '150%',
                width: '150%',
                transformOrigin: '0% 0%',
                transform: 'translate(35%, 20%) rotate3d(0, 0, 1, 45deg)',
                marginLeft: '-1px',
                animation: animate ? 'hour-glass-sand 5s linear infinite -2.5s' : undefined,
            },
            '&.top': {
                transform: 'rotate(180deg)',
                top: 'var(--hour-cup-offset)',
                display: animate ? undefined : 'none',
            },
            '&.bottom': {
                bottom: 'var(--hour-cup-offset)',
                '&::before': {
                    animationDelay: '0s',
                },
            },
        },
        '@keyframes hour-glass-rotate': {
            '0%': {
                transform: 'rotate3d(0)',
            },
            '25%, 50%': {
                transform: 'rotate3d(0, 0, 1, 180deg)',
            },
            '75%': {
                transform: 'rotate3d(0, 0, 1, 360deg)',
            },
            '100%': {
                transform: 'rotate3d(0, 0, 1, 360deg)',
            },
        },
        '@keyframes hour-glass-sand': {
            '0%, 100%': {
                borderRadius: '5%',
                transform: 'translate(35%, 20%) rotate3d(0, 0, 1, 45deg)',
            },
            '15%': {
                borderRadius: '40%',
                transform: 'translate(0%, 35%) rotate3d(0, 0, 1, -65deg) scale(1.5, 1.5)',
            },
            '25%': {
                borderRadius: '20%',
                transform: 'translate(-15%, 35%) rotate3d(0, 0, 1, -90deg) scale(1.2, 1.2)',
            },
            '50%': {
                borderRadius: '5%',
                opacity: '1',
                transform: 'translate(0%, 0%) rotate3d(0, 0, 1, -90deg) scale(1, 1)',
            },
            '50.01%, 64.99%': {
                opacity: '0',
            },
            '65%': {
                borderRadius: '35%',
                opacity: '1',
                transform: 'translate(20%, 50%) rotate3d(0, 0, 1, 30deg)',
            },
            '75%': {
                borderRadius: '10%',
                transform: 'translate(35%, 40%) rotate3d(0, 0, 1, 45deg)',
            },
        },
    };
});
export const HourGlass: React.FC<HourGlassProps> = (props) => (
    <HourGlassRoot {...props} size={props.size || 45}>
        <HourglassEmptyIcon fontSize="inherit" />
        <div className="cup top" />
        <div className="cup bottom" />
    </HourGlassRoot>
);
