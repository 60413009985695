import { isValidDate } from '@jucy-ui/common';
import { fromUnixTime } from 'date-fns';
import { Maybe } from 'yup';
import { SearchFormValues } from '../types';

const PICK_UP_BRANCH_INDEX = 0;
const PICK_UP_DATE_INDEX = 1;
const DROP_OFF_BRANCH_INDEX = 2;
const DROP_OFF_DATE_INDEX = 3;
const FLEET_TYPE_INDEX = 4;
const DRIVER_AGE_INDEX = 5;
const PROMO_CODE_INDEX = 6;
const FLEET_CATEGORY_INDEX = 7;

export const searchUidToFormValues = (values: string): Maybe<Partial<SearchFormValues>> => {
    const parts = values.split('|');
    if (parts.length < 4) {
        return undefined;
    }
    const pickUpDate = parts[PICK_UP_DATE_INDEX] && fromUnixTime(Number(parts[PICK_UP_DATE_INDEX]));
    const dropOffDate = parts[DROP_OFF_DATE_INDEX] && fromUnixTime(Number(parts[DROP_OFF_DATE_INDEX]));
    return {
        pickUpBranch: parts[PICK_UP_BRANCH_INDEX] || undefined,
        pickUpDate: isValidDate(pickUpDate) ? pickUpDate : undefined,
        pickUpTime: isValidDate(pickUpDate) ? pickUpDate : undefined,
        dropOffBranch: parts[DROP_OFF_BRANCH_INDEX] || undefined,
        dropOffTime: isValidDate(dropOffDate) ? dropOffDate : undefined,
        dropOffDate: isValidDate(dropOffDate) ? dropOffDate : undefined,
        fleetType: parts[FLEET_TYPE_INDEX] || undefined,
        driverAge: parts[DRIVER_AGE_INDEX] || undefined,
        promoCode: parts[PROMO_CODE_INDEX] || undefined,
        fleetCategory: parts[FLEET_CATEGORY_INDEX] || undefined,
    };
};
