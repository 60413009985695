import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Operator, useOperator } from '#/hooks/useOperator';
import getJucyRentalsApiClient from '#/lib/getJucyRentalsApiClient';

export type OperatorInfo = Operator & {
    email: string;
    firstName: string;
    lastName: string;
    rcmId: Record<string, number>;
    rcmOperatorId: Record<string, string>;
    rcmUserName: Record<string, string>;
    rcmAccessLevel: Record<string, string>;
    rcmEmail: Record<string, string>;
    availableAccounts: { label: string; key: string }[];
};
const getCurrentOperatorInfo = async ({ operator }: { operator?: Operator }): Promise<OperatorInfo | undefined> => {
    const data = await getJucyRentalsApiClient({ operator }).v3.reservations.request({
        path: '/api/v3/operator/current',
        method: 'GET',
        headers: {},
    });
    return (await data.json()) as OperatorInfo;
};
export const useOperatorInfo = (): { isLoading?: boolean; operatorInfo?: OperatorInfo } => {
    const { operator, isLoading: isOperatorLoading } = useOperator();
    const { inProgress } = useMsal();
    const {
        data: operatorInfo,
        isLoading: isInfoLoading,
        isError,
    } = useQuery({
        queryKey: ['operatorInfo', operator?.token],
        queryFn: () => getCurrentOperatorInfo({ operator }),
        enabled: Boolean(!isOperatorLoading && operator?.isAuthenticated && operator?.token),
        retry: 0,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
    const isLoading = isError ? false : inProgress !== 'none' || isOperatorLoading || (Boolean(operator?.token) && isInfoLoading);

    return useMemo(
        () => ({
            isLoading: isLoading,
            operatorInfo:
                operator && operatorInfo
                    ? {
                          ...operator,
                          ...operatorInfo,
                      }
                    : undefined,
        }),
        [isLoading, operator, operatorInfo]
    );
};
