import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import AppStateStore from '../../store/AppStateStore';
import { JucyAlert } from './JucyAlert';

const OfflineNotice: React.FC<{ title?: string; children?: React.ReactNode }> = observer(({ title, children }) => {
    const apiStatus = AppStateStore.status;
    const errorTitle = title || 'Scheduled maintenance';
    const content = useMemo(() => {
        if (!apiStatus?.au && !apiStatus?.nz) {
            return (
                <>
                    <h1 className="h2 mb-0">{errorTitle}</h1>
                    Online bookings are offline for scheduled maintenance.
                    <br />
                    Please email <a href="mailto:res@jucyworld.com">res@jucyworld.com</a> to make a booking or check back later
                </>
            );
        }

        if (!apiStatus?.nz) {
            return (
                <>
                    <h1 className="h2 mb-0">${errorTitle}</h1>
                    Online bookings for Australia are currently offline for scheduled maintenance.
                    <br />
                    Please email <a href="mailto:res@jucyworld.com">res@jucyworld.com</a> to make a booking in Australia or check back later
                </>
            );
        }
        if (!apiStatus?.nz) {
            return (
                <>
                    <h1 className="h2 mb-0">${errorTitle}</h1>
                    Online bookings for New Zealand are currently offline for scheduled maintenance.
                    <br />
                    Please email <a href="mailto:res@jucyworld.com">res@jucyworld.com</a> to make a booking in Australia or check back later
                </>
            );
        }
        return null;
    }, [apiStatus?.au, apiStatus?.nz, errorTitle]);

    return content ? (
        <JucyAlert color="primary" variant="outlined" severity="error" sx={{ mb: 2, display: 'inline-flex' }}>
            {content}
            {children}
        </JucyAlert>
    ) : null;
});
OfflineNotice.displayName = 'OfflineNotice';
export default OfflineNotice;
