/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { RentalDocumentDocumentLink } from './RentalDocumentDocumentLink';
import {
    RentalDocumentDocumentLinkFromJSON,
    RentalDocumentDocumentLinkFromJSONTyped,
    RentalDocumentDocumentLinkToJSON,
} from './RentalDocumentDocumentLink';
import type { RentalSignatureCustomer } from './RentalSignatureCustomer';
import { RentalSignatureCustomerFromJSON, RentalSignatureCustomerFromJSONTyped, RentalSignatureCustomerToJSON } from './RentalSignatureCustomer';

/**
 *
 * @export
 * @interface RentalDocument
 */
export interface RentalDocument {
    /**
     *
     * @type {RentalDocumentDocumentLink}
     * @memberof RentalDocument
     */
    documentLink?: RentalDocumentDocumentLink;
    /**
     *
     * @type {number}
     * @memberof RentalDocument
     */
    seqNo?: number;
    /**
     *
     * @type {string}
     * @memberof RentalDocument
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof RentalDocument
     */
    text?: string;
    /**
     *
     * @type {boolean}
     * @memberof RentalDocument
     */
    isPrimary?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RentalDocument
     */
    isUploaded?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RentalDocument
     */
    allDrivers?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RentalDocument
     */
    isOverCounterOnly?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RentalDocument
     */
    isOptional?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RentalDocument
     */
    allowMultiple?: boolean;
    /**
     *
     * @type {string}
     * @memberof RentalDocument
     */
    order?: string;
    /**
     *
     * @type {string}
     * @memberof RentalDocument
     */
    docType?: string;
    /**
     *
     * @type {number}
     * @memberof RentalDocument
     */
    maxImageWidth?: number;
    /**
     *
     * @type {string}
     * @memberof RentalDocument
     */
    url?: string;
    /**
     *
     * @type {number}
     * @memberof RentalDocument
     */
    workflowStepId?: number;
    /**
     *
     * @type {boolean}
     * @memberof RentalDocument
     */
    containsSensitiveData?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RentalDocument
     */
    isVehicleUpload?: boolean;
    /**
     *
     * @type {number}
     * @memberof RentalDocument
     */
    vehicleId?: number;
    /**
     *
     * @type {string}
     * @memberof RentalDocument
     */
    notes?: string;
    /**
     *
     * @type {string}
     * @memberof RentalDocument
     */
    storageProvider?: string;
    /**
     *
     * @type {RentalSignatureCustomer}
     * @memberof RentalDocument
     */
    customer?: RentalSignatureCustomer;
}

/**
 * Check if a given object implements the RentalDocument interface.
 */
export function instanceOfRentalDocument(value: object): value is RentalDocument {
    return true;
}

export function RentalDocumentFromJSON(json: any): RentalDocument {
    return RentalDocumentFromJSONTyped(json, false);
}

export function RentalDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): RentalDocument {
    if (json == null) {
        return json;
    }
    return {
        documentLink: json['documentLink'] == null ? undefined : RentalDocumentDocumentLinkFromJSON(json['documentLink']),
        seqNo: json['seqNo'] == null ? undefined : json['seqNo'],
        title: json['title'] == null ? undefined : json['title'],
        text: json['text'] == null ? undefined : json['text'],
        isPrimary: json['isPrimary'] == null ? undefined : json['isPrimary'],
        isUploaded: json['isUploaded'] == null ? undefined : json['isUploaded'],
        allDrivers: json['allDrivers'] == null ? undefined : json['allDrivers'],
        isOverCounterOnly: json['isOverCounterOnly'] == null ? undefined : json['isOverCounterOnly'],
        isOptional: json['isOptional'] == null ? undefined : json['isOptional'],
        allowMultiple: json['allowMultiple'] == null ? undefined : json['allowMultiple'],
        order: json['order'] == null ? undefined : json['order'],
        docType: json['docType'] == null ? undefined : json['docType'],
        maxImageWidth: json['maxImageWidth'] == null ? undefined : json['maxImageWidth'],
        url: json['url'] == null ? undefined : json['url'],
        workflowStepId: json['workflowStepId'] == null ? undefined : json['workflowStepId'],
        containsSensitiveData: json['containsSensitiveData'] == null ? undefined : json['containsSensitiveData'],
        isVehicleUpload: json['isVehicleUpload'] == null ? undefined : json['isVehicleUpload'],
        vehicleId: json['vehicleId'] == null ? undefined : json['vehicleId'],
        notes: json['notes'] == null ? undefined : json['notes'],
        storageProvider: json['storageProvider'] == null ? undefined : json['storageProvider'],
        customer: json['customer'] == null ? undefined : RentalSignatureCustomerFromJSON(json['customer']),
    };
}

export function RentalDocumentToJSON(value?: RentalDocument | null): any {
    if (value == null) {
        return value;
    }
    return {
        documentLink: RentalDocumentDocumentLinkToJSON(value['documentLink']),
        seqNo: value['seqNo'],
        title: value['title'],
        text: value['text'],
        isPrimary: value['isPrimary'],
        isUploaded: value['isUploaded'],
        allDrivers: value['allDrivers'],
        isOverCounterOnly: value['isOverCounterOnly'],
        isOptional: value['isOptional'],
        allowMultiple: value['allowMultiple'],
        order: value['order'],
        docType: value['docType'],
        maxImageWidth: value['maxImageWidth'],
        url: value['url'],
        workflowStepId: value['workflowStepId'],
        containsSensitiveData: value['containsSensitiveData'],
        isVehicleUpload: value['isVehicleUpload'],
        vehicleId: value['vehicleId'],
        notes: value['notes'],
        storageProvider: value['storageProvider'],
        customer: RentalSignatureCustomerToJSON(value['customer']),
    };
}
