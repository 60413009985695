'use client';
import React, { useMemo } from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { AlertColor, AlertProps } from '@mui/material';
import Alert from '@mui/material/Alert';

export type JucyAlertColors = AlertProps['color'] | 'primary' | 'secondary';

export interface JucyAlertProps extends Omit<AlertProps, 'color'> {
    color?: JucyAlertColors;
}

export const JucyAlert = React.forwardRef<HTMLDivElement, JucyAlertProps>(
    ({ color: propsColor, children, sx: propsSx, ...props }, ref) => {
        const { sx, color } = useMemo(() => {
            if (['primary', 'secondary'].includes(propsColor as string)) {
                return {
                    sx: {
                        color: `${propsColor}.main`,
                        borderColor: `${propsColor}.main`,
                        '& .MuiAlert-icon': {
                            color: `${propsColor}.main`,
                        },
                    },
                    color: undefined,
                };
            }
            return {
                sx: undefined,
                color: propsColor as AlertColor,
            };
        }, [propsColor]);
        return (
            <Alert ref={ref} sx={mergeSx(sx, propsSx)} color={color} {...props}>
                {children}
            </Alert>
        );
    }
);
JucyAlert.displayName = 'JucyAlert';
