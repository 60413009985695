/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { AdditionalDriver } from './AdditionalDriver';
import { AdditionalDriverFromJSON, AdditionalDriverFromJSONTyped, AdditionalDriverToJSON } from './AdditionalDriver';
import type { BookedWith } from './BookedWith';
import { BookedWithFromJSON, BookedWithFromJSONTyped, BookedWithToJSON } from './BookedWith';
import type { BranchDetails } from './BranchDetails';
import { BranchDetailsFromJSON, BranchDetailsFromJSONTyped, BranchDetailsToJSON } from './BranchDetails';
import type { CardOnFile } from './CardOnFile';
import { CardOnFileFromJSON, CardOnFileFromJSONTyped, CardOnFileToJSON } from './CardOnFile';
import type { CompanyDetails } from './CompanyDetails';
import { CompanyDetailsFromJSON, CompanyDetailsFromJSONTyped, CompanyDetailsToJSON } from './CompanyDetails';
import type { Customer } from './Customer';
import { CustomerFromJSON, CustomerFromJSONTyped, CustomerToJSON } from './Customer';
import type { FleetCategory } from './FleetCategory';
import { FleetCategoryFromJSON, FleetCategoryFromJSONTyped, FleetCategoryToJSON } from './FleetCategory';
import type { HirerDetails } from './HirerDetails';
import { HirerDetailsFromJSON, HirerDetailsFromJSONTyped, HirerDetailsToJSON } from './HirerDetails';
import type { InsuranceProducts } from './InsuranceProducts';
import { InsuranceProductsFromJSON, InsuranceProductsFromJSONTyped, InsuranceProductsToJSON } from './InsuranceProducts';
import type { Payment } from './Payment';
import { PaymentFromJSON, PaymentFromJSONTyped, PaymentToJSON } from './Payment';
import type { QuoteAgentBalanceDue } from './QuoteAgentBalanceDue';
import { QuoteAgentBalanceDueFromJSON, QuoteAgentBalanceDueFromJSONTyped, QuoteAgentBalanceDueToJSON } from './QuoteAgentBalanceDue';
import type { QuoteAgentCollects } from './QuoteAgentCollects';
import { QuoteAgentCollectsFromJSON, QuoteAgentCollectsFromJSONTyped, QuoteAgentCollectsToJSON } from './QuoteAgentCollects';
import type { QuoteAgentCommission } from './QuoteAgentCommission';
import { QuoteAgentCommissionFromJSON, QuoteAgentCommissionFromJSONTyped, QuoteAgentCommissionToJSON } from './QuoteAgentCommission';
import type { QuoteBalanceDue } from './QuoteBalanceDue';
import { QuoteBalanceDueFromJSON, QuoteBalanceDueFromJSONTyped, QuoteBalanceDueToJSON } from './QuoteBalanceDue';
import type { QuoteBalanceDueWithCCFee } from './QuoteBalanceDueWithCCFee';
import { QuoteBalanceDueWithCCFeeFromJSON, QuoteBalanceDueWithCCFeeFromJSONTyped, QuoteBalanceDueWithCCFeeToJSON } from './QuoteBalanceDueWithCCFee';
import type { QuoteTotalPrice } from './QuoteTotalPrice';
import { QuoteTotalPriceFromJSON, QuoteTotalPriceFromJSONTyped, QuoteTotalPriceToJSON } from './QuoteTotalPrice';
import type { RateInfo } from './RateInfo';
import { RateInfoFromJSON, RateInfoFromJSONTyped, RateInfoToJSON } from './RateInfo';
import type { RentalLineItem } from './RentalLineItem';
import { RentalLineItemFromJSON, RentalLineItemFromJSONTyped, RentalLineItemToJSON } from './RentalLineItem';
import type { ReservationOptions } from './ReservationOptions';
import { ReservationOptionsFromJSON, ReservationOptionsFromJSONTyped, ReservationOptionsToJSON } from './ReservationOptions';
import type { SecondaryProducts } from './SecondaryProducts';
import { SecondaryProductsFromJSON, SecondaryProductsFromJSONTyped, SecondaryProductsToJSON } from './SecondaryProducts';

/**
 *
 * @export
 * @interface SelfServiceReservation
 */
export interface SelfServiceReservation {
    /**
     * Quote unique identifier
     * @type {string}
     * @memberof SelfServiceReservation
     */
    quoteId?: string;
    /**
     * Quotes associated reservation Unique identifier
     * @type {number}
     * @memberof SelfServiceReservation
     */
    reservationId?: number;
    /**
     * Quotes reference number to be used when communicating with
     * Jucy
     * @type {string}
     * @memberof SelfServiceReservation
     */
    reservationReference?: string;
    /**
     * Generated passcode required for customers to amend or cancel
     * their reservation
     * @type {string}
     * @memberof SelfServiceReservation
     */
    onlineLogin?: string;
    /**
     * Quote display name
     * @type {string}
     * @memberof SelfServiceReservation
     */
    quoteName?: string;
    /**
     * Quotes pick up locations unique identifier
     * @type {string}
     * @memberof SelfServiceReservation
     */
    pickUpLocationId?: string;
    /**
     * Quotes pick up locations unique short code
     * @type {string}
     * @memberof SelfServiceReservation
     */
    pickUpLocationCode?: string;
    /**
     * Quotes drop off locations unique identifier
     * @type {string}
     * @memberof SelfServiceReservation
     */
    dropOffLocationId?: string;
    /**
     * Quotes drop off locations unique short code
     * @type {string}
     * @memberof SelfServiceReservation
     */
    dropOffLocationCode?: string;
    /**
     * Date time fleet category is to be picked up from pick up location
     * @type {Date}
     * @memberof SelfServiceReservation
     */
    pickUpDate?: Date;
    /**
     * Date time fleet category is to be dropped off at drop off location
     * @type {Date}
     * @memberof SelfServiceReservation
     */
    dropOffDate?: Date;
    /**
     * Number of rental days calculated for quote
     * @type {number}
     * @memberof SelfServiceReservation
     */
    rentalDays?: number;
    /**
     *
     * @type {FleetCategory}
     * @memberof SelfServiceReservation
     */
    fleetCategory?: FleetCategory;
    /**
     *
     * @type {QuoteTotalPrice}
     * @memberof SelfServiceReservation
     */
    totalPrice?: QuoteTotalPrice;
    /**
     *
     * @type {QuoteAgentCommission}
     * @memberof SelfServiceReservation
     */
    agentCommission?: QuoteAgentCommission;
    /**
     *
     * @type {QuoteAgentCollects}
     * @memberof SelfServiceReservation
     */
    agentCollects?: QuoteAgentCollects;
    /**
     *
     * @type {QuoteAgentBalanceDue}
     * @memberof SelfServiceReservation
     */
    agentBalanceDue?: QuoteAgentBalanceDue;
    /**
     * The name of the person creating the booking.
     * @type {string}
     * @memberof SelfServiceReservation
     */
    agentName?: string;
    /**
     *
     * @type {QuoteBalanceDue}
     * @memberof SelfServiceReservation
     */
    balanceDue?: QuoteBalanceDue;
    /**
     *
     * @type {QuoteBalanceDueWithCCFee}
     * @memberof SelfServiceReservation
     */
    balanceDueWithCCFee?: QuoteBalanceDueWithCCFee;
    /**
     * Merchant surcharges rate
     * @type {number}
     * @memberof SelfServiceReservation
     */
    cCSurchargePercentage?: number;
    /**
     * List of insurance, additional and mandatory fees assigned
     * @type {Array<RentalLineItem>}
     * @memberof SelfServiceReservation
     */
    lines?: Array<RentalLineItem>;
    /**
     *
     * @type {HirerDetails}
     * @memberof SelfServiceReservation
     */
    hirerDetails?: HirerDetails;
    /**
     *
     * @type {Array<InsuranceProducts>}
     * @memberof SelfServiceReservation
     */
    insuranceProducts?: Array<InsuranceProducts>;
    /**
     *
     * @type {Array<SecondaryProducts>}
     * @memberof SelfServiceReservation
     */
    secondaryProducts?: Array<SecondaryProducts>;
    /**
     *
     * @type {Customer}
     * @memberof SelfServiceReservation
     */
    hirer?: Customer;
    /**
     *
     * @type {Array<AdditionalDriver>}
     * @memberof SelfServiceReservation
     */
    additionalDrivers?: Array<AdditionalDriver>;
    /**
     *
     * @type {boolean}
     * @memberof SelfServiceReservation
     */
    isVehicleAllocated?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof SelfServiceReservation
     */
    disableCheckIn?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof SelfServiceReservation
     */
    isWebAccount?: boolean;
    /**
     *
     * @type {string}
     * @memberof SelfServiceReservation
     */
    rentalAgreementUrl?: string;
    /**
     *
     * @type {CardOnFile}
     * @memberof SelfServiceReservation
     */
    cardOnFile?: CardOnFile;
    /**
     *
     * @type {ReservationOptions}
     * @memberof SelfServiceReservation
     */
    options?: ReservationOptions;
    /**
     *
     * @type {{ [key: string]: Date; }}
     * @memberof SelfServiceReservation
     */
    checkInState?: { [key: string]: Date };
    /**
     *
     * @type {BranchDetails}
     * @memberof SelfServiceReservation
     */
    branchDetails?: BranchDetails;
    /**
     *
     * @type {CompanyDetails}
     * @memberof SelfServiceReservation
     */
    companyDetails?: CompanyDetails;
    /**
     *
     * @type {Array<RateInfo>}
     * @memberof SelfServiceReservation
     */
    rateInfo?: Array<RateInfo>;
    /**
     *
     * @type {string}
     * @memberof SelfServiceReservation
     */
    rego?: string;
    /**
     *
     * @type {Array<Payment>}
     * @memberof SelfServiceReservation
     */
    payments?: Array<Payment>;
    /**
     *
     * @type {string}
     * @memberof SelfServiceReservation
     */
    arrivalFlightNumber?: string;
    /**
     *
     * @type {string}
     * @memberof SelfServiceReservation
     */
    departureFlightNumber?: string;
    /**
     *
     * @type {number}
     * @memberof SelfServiceReservation
     */
    numberOfPeople?: number;
    /**
     *
     * @type {boolean}
     * @memberof SelfServiceReservation
     */
    hideBalanceDue?: boolean;
    /**
     *
     * @type {BookedWith}
     * @memberof SelfServiceReservation
     */
    bookedWith?: BookedWith;
}

/**
 * Check if a given object implements the SelfServiceReservation interface.
 */
export function instanceOfSelfServiceReservation(value: object): value is SelfServiceReservation {
    return true;
}

export function SelfServiceReservationFromJSON(json: any): SelfServiceReservation {
    return SelfServiceReservationFromJSONTyped(json, false);
}

export function SelfServiceReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelfServiceReservation {
    if (json == null) {
        return json;
    }
    return {
        quoteId: json['QuoteId'] == null ? undefined : json['QuoteId'],
        reservationId: json['ReservationId'] == null ? undefined : json['ReservationId'],
        reservationReference: json['ReservationReference'] == null ? undefined : json['ReservationReference'],
        onlineLogin: json['OnlineLogin'] == null ? undefined : json['OnlineLogin'],
        quoteName: json['QuoteName'] == null ? undefined : json['QuoteName'],
        pickUpLocationId: json['PickUpLocationId'] == null ? undefined : json['PickUpLocationId'],
        pickUpLocationCode: json['PickUpLocationCode'] == null ? undefined : json['PickUpLocationCode'],
        dropOffLocationId: json['DropOffLocationId'] == null ? undefined : json['DropOffLocationId'],
        dropOffLocationCode: json['DropOffLocationCode'] == null ? undefined : json['DropOffLocationCode'],
        pickUpDate: json['PickUpDate'] == null ? undefined : stringToDate(json['PickUpDate']),
        dropOffDate: json['DropOffDate'] == null ? undefined : stringToDate(json['DropOffDate']),
        rentalDays: json['RentalDays'] == null ? undefined : json['RentalDays'],
        fleetCategory: json['FleetCategory'] == null ? undefined : FleetCategoryFromJSON(json['FleetCategory']),
        totalPrice: json['TotalPrice'] == null ? undefined : QuoteTotalPriceFromJSON(json['TotalPrice']),
        agentCommission: json['AgentCommission'] == null ? undefined : QuoteAgentCommissionFromJSON(json['AgentCommission']),
        agentCollects: json['AgentCollects'] == null ? undefined : QuoteAgentCollectsFromJSON(json['AgentCollects']),
        agentBalanceDue: json['AgentBalanceDue'] == null ? undefined : QuoteAgentBalanceDueFromJSON(json['AgentBalanceDue']),
        agentName: json['AgentName'] == null ? undefined : json['AgentName'],
        balanceDue: json['BalanceDue'] == null ? undefined : QuoteBalanceDueFromJSON(json['BalanceDue']),
        balanceDueWithCCFee: json['BalanceDueWithCCFee'] == null ? undefined : QuoteBalanceDueWithCCFeeFromJSON(json['BalanceDueWithCCFee']),
        cCSurchargePercentage: json['CCSurchargePercentage'] == null ? undefined : json['CCSurchargePercentage'],
        lines: json['Lines'] == null ? undefined : (json['Lines'] as Array<any>).map(RentalLineItemFromJSON),
        hirerDetails: json['HirerDetails'] == null ? undefined : HirerDetailsFromJSON(json['HirerDetails']),
        insuranceProducts: json['InsuranceProducts'] == null ? undefined : (json['InsuranceProducts'] as Array<any>).map(InsuranceProductsFromJSON),
        secondaryProducts: json['SecondaryProducts'] == null ? undefined : (json['SecondaryProducts'] as Array<any>).map(SecondaryProductsFromJSON),
        hirer: json['hirer'] == null ? undefined : CustomerFromJSON(json['hirer']),
        additionalDrivers: json['additionalDrivers'] == null ? undefined : (json['additionalDrivers'] as Array<any>).map(AdditionalDriverFromJSON),
        isVehicleAllocated: json['isVehicleAllocated'] == null ? undefined : json['isVehicleAllocated'],
        disableCheckIn: json['DisableCheckIn'] == null ? undefined : json['DisableCheckIn'],
        isWebAccount: json['isWebAccount'] == null ? undefined : json['isWebAccount'],
        rentalAgreementUrl: json['rentalAgreementUrl'] == null ? undefined : json['rentalAgreementUrl'],
        cardOnFile: json['cardOnFile'] == null ? undefined : CardOnFileFromJSON(json['cardOnFile']),
        options: json['options'] == null ? undefined : ReservationOptionsFromJSON(json['options']),
        checkInState: json['checkInState'] == null ? undefined : json['checkInState'],
        branchDetails: json['branchDetails'] == null ? undefined : BranchDetailsFromJSON(json['branchDetails']),
        companyDetails: json['companyDetails'] == null ? undefined : CompanyDetailsFromJSON(json['companyDetails']),
        rateInfo: json['rateInfo'] == null ? undefined : (json['rateInfo'] as Array<any>).map(RateInfoFromJSON),
        rego: json['rego'] == null ? undefined : json['rego'],
        payments: json['payments'] == null ? undefined : (json['payments'] as Array<any>).map(PaymentFromJSON),
        arrivalFlightNumber: json['arrivalFlightNumber'] == null ? undefined : json['arrivalFlightNumber'],
        departureFlightNumber: json['departureFlightNumber'] == null ? undefined : json['departureFlightNumber'],
        numberOfPeople: json['numberOfPeople'] == null ? undefined : json['numberOfPeople'],
        hideBalanceDue: json['hideBalanceDue'] == null ? undefined : json['hideBalanceDue'],
        bookedWith: json['bookedWith'] == null ? undefined : BookedWithFromJSON(json['bookedWith']),
    };
}

export function SelfServiceReservationToJSON(value?: SelfServiceReservation | null): any {
    if (value == null) {
        return value;
    }
    return {
        QuoteId: value['quoteId'],
        ReservationId: value['reservationId'],
        ReservationReference: value['reservationReference'],
        OnlineLogin: value['onlineLogin'],
        QuoteName: value['quoteName'],
        PickUpLocationId: value['pickUpLocationId'],
        PickUpLocationCode: value['pickUpLocationCode'],
        DropOffLocationId: value['dropOffLocationId'],
        DropOffLocationCode: value['dropOffLocationCode'],
        PickUpDate: value['pickUpDate'] == null ? undefined : dateToString(value['pickUpDate']),
        DropOffDate: value['dropOffDate'] == null ? undefined : dateToString(value['dropOffDate']),
        RentalDays: value['rentalDays'],
        FleetCategory: FleetCategoryToJSON(value['fleetCategory']),
        TotalPrice: QuoteTotalPriceToJSON(value['totalPrice']),
        AgentCommission: QuoteAgentCommissionToJSON(value['agentCommission']),
        AgentCollects: QuoteAgentCollectsToJSON(value['agentCollects']),
        AgentBalanceDue: QuoteAgentBalanceDueToJSON(value['agentBalanceDue']),
        AgentName: value['agentName'],
        BalanceDue: QuoteBalanceDueToJSON(value['balanceDue']),
        BalanceDueWithCCFee: QuoteBalanceDueWithCCFeeToJSON(value['balanceDueWithCCFee']),
        CCSurchargePercentage: value['cCSurchargePercentage'],
        Lines: value['lines'] == null ? undefined : (value['lines'] as Array<any>).map(RentalLineItemToJSON),
        HirerDetails: HirerDetailsToJSON(value['hirerDetails']),
        InsuranceProducts: value['insuranceProducts'] == null ? undefined : (value['insuranceProducts'] as Array<any>).map(InsuranceProductsToJSON),
        SecondaryProducts: value['secondaryProducts'] == null ? undefined : (value['secondaryProducts'] as Array<any>).map(SecondaryProductsToJSON),
        hirer: CustomerToJSON(value['hirer']),
        additionalDrivers: value['additionalDrivers'] == null ? undefined : (value['additionalDrivers'] as Array<any>).map(AdditionalDriverToJSON),
        isVehicleAllocated: value['isVehicleAllocated'],
        DisableCheckIn: value['disableCheckIn'],
        isWebAccount: value['isWebAccount'],
        rentalAgreementUrl: value['rentalAgreementUrl'],
        cardOnFile: CardOnFileToJSON(value['cardOnFile']),
        options: ReservationOptionsToJSON(value['options']),
        checkInState: value['checkInState'],
        branchDetails: BranchDetailsToJSON(value['branchDetails']),
        companyDetails: CompanyDetailsToJSON(value['companyDetails']),
        rateInfo: value['rateInfo'] == null ? undefined : (value['rateInfo'] as Array<any>).map(RateInfoToJSON),
        rego: value['rego'],
        payments: value['payments'] == null ? undefined : (value['payments'] as Array<any>).map(PaymentToJSON),
        arrivalFlightNumber: value['arrivalFlightNumber'],
        departureFlightNumber: value['departureFlightNumber'],
        numberOfPeople: value['numberOfPeople'],
        hideBalanceDue: value['hideBalanceDue'],
        bookedWith: BookedWithToJSON(value['bookedWith']),
    };
}
