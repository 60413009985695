/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { License } from './License';
import { LicenseFromJSON, LicenseFromJSONTyped, LicenseToJSON } from './License';
import type { LiteAddress } from './LiteAddress';
import { LiteAddressFromJSON, LiteAddressFromJSONTyped, LiteAddressToJSON } from './LiteAddress';

/**
 *
 * @export
 * @interface LiteAdditionalDriver
 */
export interface LiteAdditionalDriver {
    /**
     *
     * @type {string}
     * @memberof LiteAdditionalDriver
     */
    additionalDriverId?: string;
    /**
     *
     * @type {boolean}
     * @memberof LiteAdditionalDriver
     */
    isDelete?: boolean;
    /**
     *
     * @type {string}
     * @memberof LiteAdditionalDriver
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof LiteAdditionalDriver
     */
    lastName?: string;
    /**
     *
     * @type {Date}
     * @memberof LiteAdditionalDriver
     */
    dateOfBirth?: Date;
    /**
     *
     * @type {License}
     * @memberof LiteAdditionalDriver
     */
    license?: License;
    /**
     *
     * @type {LiteAddress}
     * @memberof LiteAdditionalDriver
     */
    address?: LiteAddress;
    /**
     *
     * @type {string}
     * @memberof LiteAdditionalDriver
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof LiteAdditionalDriver
     */
    mobilePhone?: string;
    /**
     *
     * @type {boolean}
     * @memberof LiteAdditionalDriver
     */
    isMarketingAllowed?: boolean;
}

/**
 * Check if a given object implements the LiteAdditionalDriver interface.
 */
export function instanceOfLiteAdditionalDriver(value: object): value is LiteAdditionalDriver {
    return true;
}

export function LiteAdditionalDriverFromJSON(json: any): LiteAdditionalDriver {
    return LiteAdditionalDriverFromJSONTyped(json, false);
}

export function LiteAdditionalDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiteAdditionalDriver {
    if (json == null) {
        return json;
    }
    return {
        additionalDriverId: json['AdditionalDriverId'] == null ? undefined : json['AdditionalDriverId'],
        isDelete: json['IsDelete'] == null ? undefined : json['IsDelete'],
        firstName: json['FirstName'] == null ? undefined : json['FirstName'],
        lastName: json['LastName'] == null ? undefined : json['LastName'],
        dateOfBirth: json['DateOfBirth'] == null ? undefined : stringToDate(json['DateOfBirth']),
        license: json['License'] == null ? undefined : LicenseFromJSON(json['License']),
        address: json['Address'] == null ? undefined : LiteAddressFromJSON(json['Address']),
        email: json['Email'] == null ? undefined : json['Email'],
        mobilePhone: json['MobilePhone'] == null ? undefined : json['MobilePhone'],
        isMarketingAllowed: json['IsMarketingAllowed'] == null ? undefined : json['IsMarketingAllowed'],
    };
}

export function LiteAdditionalDriverToJSON(value?: LiteAdditionalDriver | null): any {
    if (value == null) {
        return value;
    }
    return {
        AdditionalDriverId: value['additionalDriverId'],
        IsDelete: value['isDelete'],
        FirstName: value['firstName'],
        LastName: value['lastName'],
        DateOfBirth: value['dateOfBirth'] == null ? undefined : dateToString(value['dateOfBirth']),
        License: LicenseToJSON(value['license']),
        Address: LiteAddressToJSON(value['address']),
        Email: value['email'],
        MobilePhone: value['mobilePhone'],
        IsMarketingAllowed: value['isMarketingAllowed'],
    };
}
