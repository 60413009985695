import { AvailabilityApi, Configuration, FleetCategory } from '@jucy/rentals-api-client';
import { fleetTypes } from '@jucy/rentals-common';
import { format } from 'date-fns';
import { action, autorun, runInAction } from 'mobx';
import config from '../config';
import { UserMode } from '../types/UserMode';

const ignoredPickUpBranches = ['CNS', 'ZQN'];
const ignoredDropOffBranches = ['AAP', 'ZQN'];
const ignoredOneWays: { pickUpBranch: string; dropOffBranch: string }[] = [];

export type GetAvailabilityRequest = {
    apiKey?: string;
    pickUpLocation: string;
    dropOffLocation: string;
    pickUpDate: Date;
    dropOffDate: Date;
    fleetType: string;
    driverAge?: number;
    campaignCode?: string;
};

const getFleetCategoryTotal = (fleetCategory?: FleetCategory): number =>
    (fleetCategory?.mandatoryFees || []).reduce((total, fee) => total + (fee.price?.value || 0), fleetCategory?.total?.value || 0);

class ReverseTripStore {
    trip: Partial<GetAvailabilityRequest> = {};
    reverseTrip: Partial<GetAvailabilityRequest> = {};
    forwardResults: FleetCategory[] = [];
    reverseResults: FleetCategory[] = [];
    reversePriceDiff: Record<string, number> = {};
    userMode: UserMode = 'direct';

    constructor() {
        autorun(() => {
            const ignore =
                !this.trip.pickUpLocation ||
                !this.reverseTrip.pickUpLocation ||
                this.trip?.pickUpLocation === this.reverseTrip.pickUpLocation ||
                this.trip?.dropOffLocation === 'AAP' ||
                this.trip?.pickUpLocation === 'CNS';
            if (!ignore) {
                const accountKey = this.trip.apiKey || config.webAccountKey;
                const fleetTypeId = fleetTypes.find((ft) => ft.slug === this.trip.fleetType)?.id || '';
                if (accountKey && fleetTypeId && this.reverseTrip.pickUpLocation && this.reverseTrip.dropOffLocation && this.reverseTrip.pickUpDate && this.reverseTrip.dropOffDate) {
                    const tripAPi = new AvailabilityApi(
                        new Configuration({
                            basePath: `${config.apiBaseUrl}`.replace(/\/$/, ''),
                            apiKey: accountKey,
                        })
                    );
                    tripAPi
                        .getFleetTypeCategories(
                            accountKey,
                            fleetTypeId,
                            this.reverseTrip.pickUpLocation,
                            this.reverseTrip.dropOffLocation,
                            this.reverseTrip.pickUpDate,
                            this.reverseTrip.dropOffDate,
                            this.reverseTrip.driverAge?.toString(),
                            this.reverseTrip.campaignCode
                        )
                        .then((r) => r.data?.[1]?.categories || [])
                        .then((r) => {
                            runInAction(() => {
                                this.reverseResults = r;
                            });
                        });
                }
            }
        });

        autorun(() => {
            if (this.forwardResults?.length && this.reverseResults?.length) {
                runInAction(() => {
                    this.reversePriceDiff = this.reverseResults.reduce((res, reverseResult) => {
                        const forwardResult = this.forwardResults.find((fr) => fr.id === reverseResult.id);
                        const forwardPrice = getFleetCategoryTotal(forwardResult);
                        const reversePrice = getFleetCategoryTotal(reverseResult);
                        res[reverseResult.categoryCode as string] = reversePrice - forwardPrice;
                        return res;
                    }, {} as Record<string, number>);
                });
            }
        });
    }

    get reverseTripLink() {
        if (this.reverseTrip.fleetType && this.reverseTrip.pickUpLocation && this.reverseTrip.dropOffLocation && this.reverseTrip.pickUpDate && this.reverseTrip.dropOffDate) {
            return `/${this.userMode}/create-quote/${this.trip.fleetType}?${new URLSearchParams({
                pickUpLocation: this.reverseTrip.pickUpLocation,
                pickUpDate: format(this.reverseTrip.pickUpDate, 'yyyy-MM-dd'),
                pickUpTime: format(this.reverseTrip.pickUpDate, 'HHmm'),
                dropOffLocation: this.reverseTrip.dropOffLocation,
                dropOffDate: format(this.reverseTrip.dropOffDate, 'yyyy-MM-dd'),
                dropOffTime: format(this.reverseTrip.dropOffDate, 'HHmm'),
                action: 'search',
            }).toString()}`;
        }
        return null;
    }

    @action setTrip(trip: GetAvailabilityRequest) {
        this.reset();
        this.trip = trip;
        this.reverseTrip = {
            ...trip,
            pickUpLocation: trip.dropOffLocation,
            dropOffLocation: trip.pickUpLocation,
        };
    }

    reset() {
        this.trip = {};
        this.forwardResults = [];
        this.reverseResults = [];
        this.reversePriceDiff = {};
    }

    get ignore() {
        return (
            !this.trip ||
            !this.trip.pickUpLocation ||
            !this.trip.dropOffLocation ||
            !this.reverseTrip ||
            this.trip?.pickUpLocation === this.reverseTrip?.pickUpLocation ||
            ignoredDropOffBranches.includes(this.trip.dropOffLocation || '') ||
            ignoredPickUpBranches.includes(this.trip.pickUpLocation || '') ||
            ignoredOneWays.some((oneWay) => oneWay.pickUpBranch === this.trip.pickUpLocation && oneWay.dropOffBranch === this.trip.dropOffLocation)
        );
    }
}

export const reverseTripStore = new ReverseTripStore();
