'use client';
import React from 'react';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { AspectRatio } from '../../../AspectRatio';
import { FLEET_CAT_IMG_RATIO } from './lib';

export const TeaserCarouselPlaceHolder = () => (
    <Box sx={{ mb: '20px' }}>
        <AspectRatio ratio={FLEET_CAT_IMG_RATIO}>
            <Skeleton animation="wave" variant="rectangular" sx={{ width: '100%', height: '100%', borderRadius: 1 }} />
        </AspectRatio>
    </Box>
);
export const FleetCategoryTeaserCardPlaceHolder = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <Card elevation={0} sx={{ marginBottom: 2, borderWidth: 1, borderColor: theme.palette.grey[200], borderStyle: 'solid' }}>
            <CardContent sx={{ '&:last-child': { paddingBottom: 2 } }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3.9} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                        <TeaserCarouselPlaceHolder />
                        <Skeleton width={120} height={25} animation="wave" variant="text" sx={{ marginTop: 2, marginBottom: 1 }} />
                        <Skeleton width={90} height={15} animation="wave" variant="text" sx={{ marginBottom: 1 }} />
                    </Grid>
                    <Grid item xs={12} sm={4.7} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Skeleton width="90%" height={20} animation="wave" variant="text" />
                        <Skeleton width="90%" height={20} animation="wave" variant="text" />
                        <Skeleton width="90%" height={20} animation="wave" variant="text" />
                        <Skeleton width="90%" height={20} animation="wave" variant="text" />
                        <Skeleton width="60%" height={20} animation="wave" variant="text" sx={{ marginTop: 2, marginBottom: 2 }} />
                        <Stack spacing={1.5} direction="row" sx={{ mx: isXs ? 'auto' : undefined }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Skeleton width={20} height={20} animation="wave" variant="circular" sx={{ marginRight: 0.5, marginTop: 0.39 }} />
                                <Skeleton width={20} height={39} animation="wave" variant="text" />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Skeleton width={20} height={20} animation="wave" variant="circular" sx={{ marginRight: 0.5, marginTop: 0.39 }} />
                                <Skeleton width={20} height={39} animation="wave" variant="text" />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Skeleton width={20} height={20} animation="wave" variant="circular" sx={{ marginRight: 0.5, marginTop: 0.39 }} />
                                <Skeleton width={20} height={39} animation="wave" variant="text" />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3.4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Skeleton width={120} height={25} animation="wave" variant="text" />
                        <Skeleton width="60%" height={20} animation="wave" variant="text" />
                        <Skeleton width="60%" height={20} animation="wave" variant="text" />
                        <Skeleton width="60%" height={20} animation="wave" variant="rectangular" sx={{ marginTop: 3, borderRadius: 1 }} />
                        <Skeleton
                            width={110}
                            height={31}
                            animation="wave"
                            variant="rectangular"
                            sx={{ marginTop: isXs ? 2 : 'auto', borderRadius: 1 }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
