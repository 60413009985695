import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router';
import { Container, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import AppStateStore from '../../store/AppStateStore';
import { BookingCart } from '../../types/BookingCart';
import { JucyMuiTextButton, JucyMuiTextButtonProps } from '../Button/Button';
import CurrencyFormatter from '../CurrencyFormatter/CurrencyFormatter';
import { mergeSx } from '#/lib/mergeSx';

export interface StickyFooterProps {
    summary?: BookingCart;
}

const FooterButton: React.FC<JucyMuiTextButtonProps> = ({ children, sx, ...props }) => (
    <JucyMuiTextButton
        sx={mergeSx(
            {
                lineHeight: 1,
                p: 0,
                m: 0,
                fontSize: { xs: '12px', sm: '14px' },
            },
            sx
        )}
        {...props}
    >
        {children}
    </JucyMuiTextButton>
);

const OrderSummaryFooter: React.FC<StickyFooterProps> = observer(({ summary }) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('md'));

    if (!summary?.fleetCategory || !isSm || !AppStateStore.orderSummaryFooter.showFooter) {
        return null;
    }
    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 2,
                minHeight: theme.settings?.footer.height,
                py: 2,
                display: 'flex',
                flexDirection: 'column',
            }}
            elevation={3}
        >
            <Container className="foot-container" maxWidth="xl" sx={{ height: '100%', flexDirection: 'column', display: 'flex' }}>
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                    <Stack gap={1}>
                        <Typography sx={{ p: 0, lineHeight: 1, fontSize: { xs: '12px', sm: 'unset' } }}>
                            {summary.totalPriceWithMerchantFee.value !== 0 ? (
                                <>
                                    Total <CurrencyFormatter price={summary.totalPriceWithMerchantFee} />
                                </>
                            ) : null}
                        </Typography>
                        <OrderSummaryFooterBackButton />
                    </Stack>
                    <Stack gap={1}>
                        <FooterButton onClick={AppStateStore.orderSummaryFooter.toggleSummary} sx={{ justifyContent: 'flex-end', textAlign: 'right' }} data-cy="footer-summary-toggle-btn">
                            {AppStateStore.orderSummaryFooter.showTripSummary ? 'Hide' : 'Show'} trip summary
                        </FooterButton>
                        <OrderSummaryFooterNextButton />
                    </Stack>
                </Stack>
            </Container>
        </Paper>
    );
});
OrderSummaryFooter.displayName = 'OrderSummaryFooter';

const OrderSummaryFooterBackButton = observer(() => {
    const navigate = useNavigate();
    return (
        <FooterButton
            onClick={() => {
                if (AppStateStore.orderSummaryFooter.showTripSummary) {
                    AppStateStore.orderSummaryFooter.toggleSummary();
                } else {
                    AppStateStore.orderSummaryFooter.showNextBtn = false;
                    AppStateStore.orderSummaryFooter.nextBtnProps = {};
                    navigate(-1);
                }
            }}
            data-cy="footer-back-btn"
            sx={{ justifyContent: 'flex-start', marginTop: 'auto' }}
        >
            Back
        </FooterButton>
    );
});
const OrderSummaryFooterNextButton = observer(() => {
    const nextBtnProps = AppStateStore.orderSummaryFooter.nextBtnProps;
    if (!AppStateStore.orderSummaryFooter.showNextBtn) {
        return null;
    }
    return (
        <FooterButton {...nextBtnProps} sx={{ justifyContent: 'flex-end', textAlign: 'right' }} data-cy="footer-next-btn">
            Continue
        </FooterButton>
    );
});

export default OrderSummaryFooter;
