import { Deal, getNow, isValidDate, memoize } from '@jucy-ui/common';
import { addYears, min } from 'date-fns';
import { roundTime } from './roundTime';

export const getMaxDate = memoize(({ deal }: { deal?: Deal } = {}) => {
    const absMax = addYears(getNow(), 2);

    const dealStart = deal?.conditions?.endDateOnOrBefore ? deal.conditions.endDateOnOrBefore : deal?.conditions?.endDateBetween?.from;
    const minDate = isValidDate(dealStart) ? min([dealStart, absMax]) : absMax;
    return roundTime(minDate);
});
