import { useMemo } from 'react';
import { useParams } from 'react-router';
import { URLSearchParamsInit, createSearchParams } from 'react-router-dom';
import { useActiveRoute } from './useActiveRoute';
import { UseRouteNavigateOptions, useRouteNavigate } from './useNavigateRoute';
import { useSearchParams } from './useSearchParams';

const mergeSearchParams = (...params: (URLSearchParamsInit | Record<string, string | undefined> | null | undefined)[]): URLSearchParams => {
    const data = params.reduce<Record<string, string>>((res, params) => {
        if (!params) {
            return res;
        }
        return {
            ...res,
            ...Object.fromEntries(createSearchParams(params as URLSearchParamsInit)),
        };
    }, {} as Record<string, string>);
    return createSearchParams(data);
};
type UseUpdateRouteParamsOptions = Omit<UseRouteNavigateOptions, 'params'> & { keepSearch?: boolean };
type UseUpdateRouteParamsFunction = (params: Record<string, string | undefined | null>, options?: UseUpdateRouteParamsOptions) => void;
export const useUpdateRouteParams = (): UseUpdateRouteParamsFunction => {
    const activeRoute = useActiveRoute();
    const activeParams = useParams();
    const [activeSearchParams] = useSearchParams();
    const navigate = useRouteNavigate();
    return useMemo(
        () => (params: Record<string, string | undefined | null>, options?: UseUpdateRouteParamsOptions) => {
            if (activeRoute) {
                const { keepSearch, ...ops } = options || {};
                navigate(activeRoute.id, {
                    params: {
                        ...activeParams,
                        ...params,
                    },
                    ...ops,
                    search: mergeSearchParams(keepSearch ? (activeSearchParams as URLSearchParamsInit) : undefined, ops.search),
                });
            }
        },
        [activeRoute, navigate, activeParams, activeSearchParams]
    );
};
