export const jucyInsights = {
    domainMap: {
        'jucy.com': 'insights.jucy.com',
        'jucy.cloud': 'insights.jucy.cloud',
        'starrv.com': 'insights.starrv.com',
        'jucy.rentals': 'insights.jucy.rentals',
        'star.rentals': 'insights.star.rentals',
    },
    ignorePaths: ['/proxydirectory/'],
    ignoreDomains: ['login.jucy.com'],
    traceDomains: ['jucy.com', 'jucy.cloud', 'starrv.com', 'jucy.rentals', 'star.rentals'],
    shouldTraceUrl: (inUrl: string | URL) => {
        try {
            const url = new URL(inUrl);
            if (jucyInsights.ignoreDomains.some((domain) => url.hostname.includes(domain))) {
                return false;
            }
            if (jucyInsights.ignorePaths.some((path) => url.pathname.includes(path))) {
                return false;
            }
            if (url.href.includes('localhost:3002')) {
                return true;
            }
            return jucyInsights.traceDomains.some((domain) => url.hostname.includes(domain));
        } catch (e) {
            return false;
        }
    },
    resolveInsightsDomain: (inUrl: string | URL) => {
        try {
            const url = new URL(inUrl);
            for (const [domain, value] of Object.entries(jucyInsights.domainMap)) {
                if (url.hostname.includes(domain)) {
                    return value;
                }
            }
        } catch (e) {
            //
        }
        return 'insights.jucy.com';
    },
};
