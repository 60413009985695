import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';

export const useRouteMatch = (patterns: readonly string[]): string | null => {
    const { pathname } = useLocation();
    const alternatePath = pathname.replace(/(trip\/create|trip\/edit).*/, 'create-quote');
    return useMemo(() => {
        for (const pattern of patterns) {
            const possibleMatch = matchPath(pattern, pathname) || matchPath(pattern, alternatePath);
            if (possibleMatch !== null) {
                return pattern;
            }
        }
        for (const pattern of patterns) {
            if (pathname.startsWith(pattern) || pathname.startsWith(pattern.replace('create', 'edit').replace('quotes', 'quote'))) {
                return pattern;
            }
            if (pathname.startsWith(pattern) || pathname.startsWith(pattern.replace('quote', 'trip'))) {
                return pattern;
            }
        }
        return patterns[0];
    }, [alternatePath, pathname, patterns]);
};
