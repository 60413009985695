'use client';
import React from 'react';
import {SxProps, styled, Theme} from '@mui/material';

export type AspectRatioProps = {
    flex?: boolean;
    minHeight?: number | string;
    maxHeight?: number | string;
    objectFit?: React.CSSProperties['objectFit'];
    ratio?: number | string;
    sx?: SxProps<Theme>;
    children?: React.ReactNode;
    className?: string;
};
type AspectRatioRootOwnerState = {
    flex?: boolean;
    minHeight?: number | string;
    maxHeight?: number | string;
    objectFit?: React.CSSProperties['objectFit'];
    ratio?: number | string;
};
const AspectRatioRoot = styled('div', {
    name: 'JucyAspectRatioRoot',
    slot: 'root',
    skipSx: false,
})<{ ownerState: AspectRatioRootOwnerState }>(({ ownerState: { maxHeight, ratio, minHeight, flex } }) => ({
    '--AspectRatio-paddingBottom': `clamp(var(--AspectRatio-minHeight), calc(100% / (${ratio})), var(--AspectRatio-maxHeight))`,
    '--AspectRatio-maxHeight': maxHeight || '9999px',
    '--AspectRatio-minHeight': minHeight || '0px',
    margin: 'var(--AspectRatio-margin)',
    display: flex ? 'flex' : 'block',
    flex: flex ? 1 : 'initial',
    ...(flex
        ? {
              flexDirection: 'column',
          }
        : undefined),
}));
const AspectRatioInner = styled('div', {
    name: 'JucyAspectRatioRoot',
    slot: 'innner',
})<{ ownerState: AspectRatioRootOwnerState }>(({ ownerState: { objectFit } }) => ({
    flex: 1,
    position: 'relative',
    borderRadius: 'inherit',
    height: 0,
    paddingBottom: 'calc(var(--AspectRatio-paddingBottom) - 2 * var(--variant-borderWidth, 0px))',
    overflow: 'hidden',
    transition: 'inherit',
    '& [data-first-child]': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: objectFit,
        margin: 0,
        padding: 0,
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: objectFit,
        },
    },
}));
export const AspectRatio = React.forwardRef<HTMLDivElement, AspectRatioProps>(
    ({ children, ratio = '16 / 9', flex = false, objectFit = 'cover', minHeight: propsMinHeight, maxHeight: propsMaxHeight, ...props }, ref) => {
        const minHeight = typeof propsMinHeight === 'number' ? `${propsMinHeight}px` : propsMinHeight;
        const maxHeight = typeof propsMaxHeight === 'number' ? `${propsMaxHeight}px` : propsMaxHeight;
        const ownerState = { flex, minHeight, maxHeight, objectFit, ratio };
        return (
            <AspectRatioRoot ref={ref} ownerState={ownerState} {...props}>
                <AspectRatioInner ownerState={ownerState}>
                    {React.Children.map(children, (child, index) =>
                        index === 0 && React.isValidElement(child)
                            ? React.cloneElement(child, { 'data-first-child': '' } as Record<string, string>)
                            : child
                    )}
                </AspectRatioInner>
            </AspectRatioRoot>
        );
    }
);
AspectRatio.displayName = 'AspectRatio';
