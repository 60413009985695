'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { TypographyProps } from '@mui/material';
import Typography from '@mui/material/Typography';

export const MainTitle: React.FC<TypographyProps> = ({ variant, sx, children, ...props }) => (
    <Typography
        variant={variant || 'h1'}
        sx={mergeSx(
            {
                fontSize: 'calc(1.3125rem + 0.75vw)',
            },
            sx
        )}
        {...props}
    >
        {children}
    </Typography>
);
