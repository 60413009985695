'use client';
import React, { useCallback, useMemo, useState } from 'react';
import { CatalogItem } from '@jucy-ui/common';
import { ModalProps } from '@mui/material';
import { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MainTitle } from '../../../../MainTitle';
import { ModalDialog } from '../../../../ModalDialog';
import { JucyMuiTab, JucyMuiTabs } from '../../../../Tabs';
import { useLocale } from '../../../../context';
import {
    DetailsTab,
    ExcessTab,
    FeaturesTab,
    SpecsTab,
    fleetCategoryDetailsDialogTabNames,
    getFleetCategoryDetailsDialogTabPanelProps,
} from './FleetCategoryDetailsDialogTabs';

const useGetFleetCategoryCmsPage = (catalogItem: CatalogItem) => {
    const locale = useLocale();
    return useMemo(() => {
        const pages = catalogItem.pages;
        let page = pages.find((p) => p.locale === locale);
        if (!page) {
            page = pages[0];
        }
        return page;
    }, [locale, catalogItem]);
};
export const FleetCategoryDetailsDialog: React.FC<{ onClose?: ModalProps['onClose']; open: ModalProps['open']; catalogItem: CatalogItem }> = ({
    onClose,
    open,
    catalogItem,
}) => {
    const [activeTab, setActiveTab] = useState('details');
    const toggle = useCallback((_event: React.SyntheticEvent, newValue: string) => setActiveTab(newValue), []);
    const cmsPage = useGetFleetCategoryCmsPage(catalogItem);
    return (
        <ModalDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            sx={{
                [`& .${dialogClasses.scrollPaper}`]: { alignItems: 'flex-start' },
            }}
        >
            <DialogTitle component="div">
                <MainTitle>{cmsPage.title || catalogItem.name}</MainTitle>
                <JucyMuiTabs value={activeTab} onChange={toggle}>
                    {fleetCategoryDetailsDialogTabNames.map((value) => (
                        <JucyMuiTab key={value} {...getFleetCategoryDetailsDialogTabPanelProps(value)} />
                    ))}
                </JucyMuiTabs>
            </DialogTitle>
            <DialogContent>
                <DetailsTab activeTab={activeTab} catalogItem={catalogItem} />
                <FeaturesTab activeTab={activeTab} catalogItem={catalogItem} />
                <SpecsTab activeTab={activeTab} catalogItem={catalogItem} />
                <ExcessTab activeTab={activeTab} catalogItem={catalogItem} />
            </DialogContent>
        </ModalDialog>
    );
};
