'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButtonProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export interface JucyNavButtonProps extends Omit<IconButtonProps, 'size'> {
    variant?: 'prev' | 'next';
    size?: number;
}

export const JucyNavButton = React.forwardRef<HTMLButtonElement, JucyNavButtonProps>(({ children, size = 30, variant, sx, ...props }, ref) => (
    <IconButton
        ref={ref}
        {...props}
        sx={mergeSx(
            (t) => ({
                height: size,
                width: size,
                ...(variant === 'prev' ? { left: -(size / 2) } : undefined),
                ...(variant === 'next' ? { right: -(size / 2) } : undefined),
                background: t.palette.background.paper,
                borderRadius: '50%',
                boxShadow: t.shadows['2'],
                color: t.palette.action.active,
                border: 'unset',
                padding: 0,
                marginTop: 0,
                transition: t.transitions.create('boxShadow', {
                    duration: t.transitions.duration.shortest,
                }),
                '&:hover, &:focus': {
                    background: t.palette.common.white,
                    boxShadow: t.shadows['3'],
                },
                '&:active': {
                    boxShadow: t.shadows['5'],
                },
                '&:disabled': {
                    opacity: 0,
                    pointerEvents: 'none',
                },
                '& svg': {
                    height: size,
                    width: size,
                },
            }),
            sx
        )}
    >
        {children}
        {variant === 'prev' ? <ChevronLeftIcon /> : null}
        {variant === 'next' ? <ChevronRightIcon /> : null}
    </IconButton>
));
JucyNavButton.displayName = 'JucyNavButton';
