import config from '#/config';
import { stringToBrand } from '#/lib/brand';
import { UserMode } from '#/types/UserMode';
import { format } from 'date-fns';
import Quote from '../../api/model/Quote';
import { SalesMonitorProvider } from './SalesMonitorProvider';
import { getCommissionableTotal } from '#/lib/SalesMonitoring/getCommissionableTotal';
import ErrorReporter from '#/lib/ErrorReporter';
import { asError } from '#/lib/asError';
import BusinessUnitsStore from '#/store/BusinessUnitsStore';

export class CommissionFactory implements SalesMonitorProvider {
    cfId?: string;

    constructor(cfId?: string) {
        this.cfId = cfId;
    }

    init(): void {
        ((window: Window, doc: Document) => {
            const addQuery = (method: string, key?: string, val?: string) => {
                window.CommissionFactory.q.push([method, key, val]);
            };
            window.cf = window.CommissionFactory = window.CommissionFactory || addQuery;
            window.CommissionFactory.q = window.CommissionFactory.q || [];
            if (this.cfId) {
                const script = doc.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.src = `https://t.cfjump.com/tag/${this.cfId}`;
                const sibling = doc.getElementsByTagName('script')[0];
                sibling?.parentNode?.insertBefore(script, sibling);
            }
        })(window, document);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const utmSource = urlSearchParams?.get('utm_source');

        if (utmSource) {
            if (utmSource?.toLowerCase() === 'commissionfactory') {
                localStorage.setItem('cf_utm_source', utmSource);
            } else {
                localStorage.removeItem('cf_utm_source');
            }
        }
    }

    confirmPurchase(quote: Quote): void {
        try {
            const businessUnit = BusinessUnitsStore.getByQuote(quote)?.name;
            const { total } = getCommissionableTotal(quote);
            window.CommissionFactory('set', 'order', quote.ReservationReference);
            window.CommissionFactory('set', 'amount', `${total}`);
            window.CommissionFactory('set', 'currency', quote.TotalPrice.CurrencyCode);
            window.CommissionFactory('set', 'date', format(quote.PickUpDateTime, 'yyyy-MM-dd'));
            window.CommissionFactory('set', 'reference', quote.FleetCategory.CategoryCode);

            if (quote.FleetCategory.fleetType) {
                window.CommissionFactory('set', 'reference2', quote.FleetCategory.fleetType.slug);
            }
            if (businessUnit) {
                window.CommissionFactory('set', 'reference3', businessUnit);
            }
            if (quote.FleetCategory.CampaignCode) {
                window.CommissionFactory('set', 'coupon', quote.FleetCategory.CampaignCode);
            }

            window.CommissionFactory('track');
        } catch (e) {
            ErrorReporter.reportError({ error: asError(e), level: 'error', tags: { source: 'CommissionJunction', reservationReference: quote.ReservationReference } });
        }
    }

    enabled(quote: Quote, accountType: UserMode): boolean {
        return Boolean(accountType === 'direct' && quote?.BookingStatus === 'Confirmed' && window.CommissionFactory);
    }

    createQuoteQueryParams(accountType?: UserMode): Record<string, string> {
        const utmSource = localStorage?.getItem('cf_utm_source');
        if (accountType === 'direct' && utmSource?.toLowerCase() === 'commissionfactory') {
            return { utm_source: utmSource };
        }
        return {};
    }
}

type CommissionFactoryTag = { (method: string, key?: string, val?: string): void; q: [string, string | undefined, string | undefined][] };

declare global {
    interface Window {
        CommissionFactory: CommissionFactoryTag;
        cf: CommissionFactoryTag;
    }
}

export const getCommissionFactory = (): CommissionFactory | undefined => {
    const brand = typeof window !== 'undefined' ? stringToBrand(window.location.host) : undefined;
    const commissionFactoryId = brand === 'star' ? config.starCommissionFactoryId : config.commissionFactoryId;
    return commissionFactoryId ? new CommissionFactory(commissionFactoryId as string) : undefined;
};
