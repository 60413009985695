import type * as React from 'react';

export const mergeRefs =
    <Instance>(...refs: Array<React.Ref<Instance> | undefined>): React.RefCallback<Instance> | null =>
    (value) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref != null) {
                (ref as React.MutableRefObject<Instance | null>).current = value;
            }
        });
    };
