import ErrorReporter from '#/lib/ErrorReporter';
import { BookingCart } from '../../types/BookingCart';
import { UserMode } from '../../types/UserMode';
import { logTracking } from '../Events';
import Quote from '../api/model/Quote';
import providers from './Providers';

export const confirmPurchase = (quote: Quote | BookingCart, accountType: UserMode): void => {
    providers.forEach((p) => {
        try {
            logTracking(`confirm ${p.constructor.name}`, p.enabled(quote, accountType));
            if (p.enabled(quote, accountType)) {
                p.confirmPurchase(quote);
            }
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    });
};
