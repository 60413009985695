import { DialogContent, DialogTitle } from '@mui/material';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import { PageTitle } from '../../Typograpgy/Title';
import { PaymentOption } from '../lib';
import GetPaymentMethodFn from './GetPaymentMethodFn';

const getPayOnPickUpPaymentOption: GetPaymentMethodFn = ({ summary, options }): PaymentOption => ({
    id: 'pay-on-pickup',
    name: 'Pay on Pickup',
    amount: <CurrencyFormatter price={0} prefixCurrencyCode={true} />,
    total: { value: 0, currencyCode: summary.totalPrice?.currencyCode },
    subTotal: { value: 0, currencyCode: summary.totalPrice?.currencyCode },
    balanceRemaining: { value: summary.totalPrice?.value, currencyCode: summary.totalPrice?.currencyCode },
    modalBody: (
        <>
            <DialogTitle component="div">
                <PageTitle>Pay on pick up</PageTitle>
            </DialogTitle>
            <DialogContent>
                Pay <CurrencyFormatter price={summary.totalPrice} prefixCurrencyCode={true} /> on pickup
            </DialogContent>
        </>
    ),
    gateway: 'none',
    available: ({ enablePayOnPickup, forcePayOnPickupOnly, summary }) => Boolean(!summary.isOnRequest && (enablePayOnPickup || forcePayOnPickupOnly)),
    ...options,
});
export default getPayOnPickUpPaymentOption;
