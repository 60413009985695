import { FleetCategoryAvailabilityEnum } from '@jucy/rentals-api-client/rentals-api/models/FleetCategory';
import type { FleetCategoryMandatoryFees } from '@jucy/rentals-api-client/rentals-api/models/FleetCategoryMandatoryFees';
import { FleetCategoryFromJSON, FleetCategory as V3FleetCategory } from '@jucy/rentals-api-client/rentals-api-v3/models/FleetCategory';
import { computed, makeAutoObservable, observable } from 'mobx';
import { FleetType } from '../../../services';
import FleetCategory from './FleetCategory';
import FleetCategoryMandatoryFee from './FleetCategoryMandatoryFee';
import Money from './Money';
import type { CatalogItem } from './RentalCatalog';

interface FleetCategoryConstraints {
    bookingMin?: Date;
    bookingMax?: Date;
    hireMinDate?: Date;
    hireMaxDate?: Date;
    allowedPickUpLocationCodes?: string[];
    allowedDropOffLocationCodes?: string[];
}

class FleetCategoryAvailability {
    constraints?: FleetCategoryConstraints;
    @observable availability: FleetCategoryAvailabilityEnum = FleetCategoryAvailabilityEnum.FreeSell;
    @observable availabilityMessage = '';
    @observable dailyRate = new Money();
    @observable total = new Money();
    @observable discountPercentageApplied = 0;
    @observable mandatoryFees: FleetCategoryMandatoryFees[] = [];
    @observable isAfterHoursPickUp = false;
    @observable minimumHirePeriod = 0;
    @observable campaignCode = '';
    @observable campaignId = '';
    @observable paymentEnabled = true;
    @observable quoteEnabled = true;
    @observable rentalDays = 0;
    @observable dealText = '';
    fleetType?: FleetType = {} as FleetType;
    /**
     * @type {FleetCategory}
     */
    product = new FleetCategory();

    constructor(props: Partial<FleetCategoryAvailability>) {
        makeAutoObservable(this);
        Object.assign(this, props);
    }

    @computed get Name(): string {
        return this.product.name;
    }

    @computed get Description(): string {
        return this.product.description;
    }

    @computed get CategoryCode(): string {
        return this.product.code;
    }

    @computed get CampaignCode(): string {
        return this.campaignCode;
    }

    @computed get CampaignId(): string {
        return this.campaignId;
    }

    @computed get Id(): string {
        return this.product.id;
    }

    @computed get Type(): string {
        return this.product.typeId;
    }

    @computed get DailyRate(): Money {
        return this.dailyRate;
    }

    @computed get Total(): Money {
        return this.total;
    }

    @computed get DiscountPercentageApplied(): number {
        return this.discountPercentageApplied;
    }

    @computed get MandatoryFees(): FleetCategoryMandatoryFees[] {
        return this.mandatoryFees;
    }

    @computed get MinimumHirePeriod(): number {
        return this.minimumHirePeriod;
    }

    @computed get IsAfterHourPickUp(): boolean {
        return this.isAfterHoursPickUp;
    }

    @computed get Availability(): string {
        return this.availability;
    }

    @computed get RentalDays(): number {
        const { dailyRate, total } = this;
        return this.hasRates ? Math.round(total.value / dailyRate.value) : 0;
    }

    @computed get OriginalDailyRate(): Money {
        return this.originalDailyRate;
    }

    @computed get DealText(): string {
        return this.dealText;
    }

    @computed get originalDailyRate(): Money {
        const { hasRates, hasDiscount, discountPercentageApplied, dailyRate } = this;
        return hasRates && hasDiscount
            ? new Money({
                  ...dailyRate,
                  value: dailyRate.value / (1 - discountPercentageApplied),
              })
            : dailyRate;
    }

    @computed get OriginalTotal(): Money {
        return this.originalTotal;
    }

    @computed get originalTotal(): Money {
        const { hasRates, hasDiscount, discountPercentageApplied, total } = this;
        return hasRates && hasDiscount
            ? new Money({
                  ...total,
                  value: total.value / (1 - discountPercentageApplied),
              })
            : total;
    }

    @computed get DailyRateDiscountAmount(): Money {
        return this.dailyRateDiscountAmount;
    }

    @computed get dailyRateDiscountAmount(): Money {
        const { hasRates, hasDiscount, originalDailyRate, dailyRate } = this;
        return hasRates && hasDiscount
            ? new Money({
                  ...dailyRate,
                  value: originalDailyRate.value - dailyRate.value,
              })
            : new Money({ ...dailyRate, Value: 0 });
    }

    @computed get TotalDiscountAmount(): Money {
        return this.totalDiscountAmount;
    }

    @computed get totalDiscountAmount(): Money {
        const { hasRates, hasDiscount, originalTotal, total } = this;
        return hasRates && hasDiscount
            ? new Money({
                  ...total,
                  value: originalTotal.value - total.value,
              })
            : new Money({ ...total, Value: 0 });
    }

    @computed get hasRates(): boolean {
        const { dailyRate, total } = this;
        return dailyRate.value > 0 && total.value > 0;
    }

    @computed get hasDiscount(): boolean {
        return this.discountPercentageApplied > 0;
    }

    @computed get isMinHirePeriod(): boolean {
        return Boolean(this.minimumHirePeriod);
    }

    @computed get isUnderAge(): boolean {
        return this.availabilityMessage?.includes('under') && this.availabilityMessage?.includes('age');
    }

    @computed get isFreeSell(): boolean {
        return !this.isMinHirePeriod && this.Availability === 'FreeSell';
    }

    @computed get isOnRequest(): boolean {
        return !this.isMinHirePeriod && this.Availability === 'OnRequest';
    }

    @computed get isStopSell(): boolean {
        return !this.isMinHirePeriod && this.Availability === 'StopSell';
    }

    @computed get isBookable(): boolean {
        return !this.isMinHirePeriod && !this.isStopSell && (this.DailyRate.value > 0 || (Boolean(this.hasDeal) && this.DailyRate.value === 0));
    }

    @computed get currencyCode() {
        return this.DailyRate?.currencyCode || this.DailyRate?.CurrencyCode || '';
    }

    @computed get hasDeal() {
        return this.dealText || this.dailyRate.value < this.originalDailyRate.value;
    }

    static fromApi({
        apiFleetCategory,
        fleetType,
        catalogData,
    }: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        apiFleetCategory: any;
        fleetType: FleetType;
        catalogData: CatalogItem;
    }): FleetCategoryAvailability {
        return new FleetCategoryAvailability({
            fleetType,
            availability: apiFleetCategory.Availability,
            availabilityMessage: apiFleetCategory.AvailabilityMessage,
            dailyRate: Money.fromApi(apiFleetCategory.DailyRate),
            total: Money.fromApi(apiFleetCategory.Total),
            discountPercentageApplied: apiFleetCategory.DiscountPercentageApplied,
            mandatoryFees: apiFleetCategory.MandatoryFees ? apiFleetCategory.MandatoryFees.map(FleetCategoryMandatoryFee.fromApi) : [],
            isAfterHoursPickUp: apiFleetCategory.IsAfterHourPickUp,
            minimumHirePeriod: apiFleetCategory.MinimumHirePeriod,
            campaignCode: apiFleetCategory.CampaignCode,
            campaignId: apiFleetCategory.CampaignId,
            rentalDays: apiFleetCategory.RentalDays,
            dealText: apiFleetCategory.DealText,
            product: new FleetCategory({
                name: apiFleetCategory.Name,
                description: apiFleetCategory.Description,
                code: apiFleetCategory.CategoryCode,
                id: apiFleetCategory.Id,
                typeId: apiFleetCategory.Type,
                seatCount: catalogData?.seatCount,
                sleepDescription: catalogData?.sleepDescription,
                sleepCount: catalogData?.sleepCount,
                luggageLarge: catalogData?.luggageLarge,
                luggageSmall: catalogData?.luggageSmall,
                image: catalogData?.image,
                vehicleDescription: catalogData?.vehicleDescription,
                highlightedFeatures: catalogData?.highlightedFeatures || [],
                url: catalogData?.url,
                extended: catalogData?.extended,
            }),
        });
    }

    static formV3Api({ apiFleetCategory, fleetType, catalogData }: { apiFleetCategory: V3FleetCategory; fleetType: FleetType; catalogData: CatalogItem }): FleetCategoryAvailability {
        return new FleetCategoryAvailability({
            fleetType,
            availability: apiFleetCategory.availability as FleetCategoryAvailabilityEnum,
            dailyRate: Money.fromApi(apiFleetCategory.dailyRate),
            total: Money.fromApi(apiFleetCategory.total),
            discountPercentageApplied: apiFleetCategory.discountPercentageApplied,
            mandatoryFees: apiFleetCategory.mandatoryFees ? apiFleetCategory.mandatoryFees.map(FleetCategoryMandatoryFee.fromV3MandatoryFeeApi) : [],
            isAfterHoursPickUp: apiFleetCategory.isAfterHourPickUp,
            minimumHirePeriod: apiFleetCategory.minimumHirePeriod,
            campaignCode: apiFleetCategory.campaignCode,
            campaignId: apiFleetCategory.campaignId,
            rentalDays: apiFleetCategory.rentalDays,
            dealText: apiFleetCategory.dealText,
            product: new FleetCategory({
                name: apiFleetCategory.name,
                description: apiFleetCategory.description,
                code: apiFleetCategory.categoryCode,
                id: apiFleetCategory.id,
                typeId: apiFleetCategory.type,
                seatCount: catalogData?.seatCount,
                sleepDescription: catalogData?.sleepDescription,
                sleepCount: catalogData?.sleepCount,
                luggageLarge: catalogData?.luggageLarge,
                luggageSmall: catalogData?.luggageSmall,
                image: catalogData?.image,
                vehicleDescription: catalogData?.vehicleDescription,
                highlightedFeatures: catalogData?.highlightedFeatures || [],
                url: catalogData?.url,
                extended: catalogData?.extended,
            }),
        });
    }

    static fromPlain(plainObject: Record<string, unknown>): FleetCategoryAvailability {
        const { dailyRate, total, mandatoryFees, product, ...fleetCategoryAvailability } = plainObject;
        return new FleetCategoryAvailability({
            ...fleetCategoryAvailability,
            dailyRate: Money.fromApi(dailyRate as Money),
            total: Money.fromApi(total as Money),
            mandatoryFees: mandatoryFees ? (mandatoryFees as FleetCategoryMandatoryFee[]).map((f) => new FleetCategoryMandatoryFee(f)) : [],
            product: new FleetCategory(product as FleetCategory),
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateFromApiQuoteResponse({ FleetCategory }: { FleetCategory: any }): void {
        this.availability = FleetCategory.Availability;
        this.dailyRate = Money.fromApi(FleetCategory.DailyRate);
        this.total = Money.fromApi(FleetCategory.Total);
        if (FleetCategory.DiscountPercentageApplied) {
            this.discountPercentageApplied = FleetCategory.DiscountPercentageApplied;
        }
        this.mandatoryFees = FleetCategory.MandatoryFees ? FleetCategory.MandatoryFees.map(FleetCategoryMandatoryFee.fromApi) : [];
        this.isAfterHoursPickUp = FleetCategory.IsAfterHourPickUp;
        this.minimumHirePeriod = FleetCategory.MinimumHirePeriod;
    }

    asV3FleetCategory(): V3FleetCategory {
        return FleetCategoryFromJSON({
            name: this.product.name,
            description: this.product.description,
            code: this.product.code,
            categoryCode: this.product.code,
            campaignCode: this.campaignCode,
            campaignId: this.campaignCode,
            id: this.Id,
            type: this.fleetType?.id,
            rentalDays: this.rentalDays,
            dealText: this.dealText,
            fleetTypeCode: this.fleetType?.slug,
            availability: this.availability,
            availabilityMessage: this.availabilityMessage,
            dailyRate: this.dailyRate,
            total: this.total,
            discountPercentageApplied: this.discountPercentageApplied,
            mandatoryFees: this.mandatoryFees,
            minimumHirePeriod: this.minimumHirePeriod,
            isAfterHourPickUp: this.isAfterHoursPickUp,
        });
    }
}

export default FleetCategoryAvailability;
