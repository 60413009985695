import React from 'react';
import BlazeSlider, { BlazeConfig } from 'blaze-slider';

type SliderEl = HTMLDivElement & { blazeSlider?: BlazeSlider };

export const useBlazeSlider = (config?: BlazeConfig) => {
    const [sliderRef, setSliderRef] = React.useState<BlazeSlider | undefined>();
    const elRef = React.useRef<SliderEl>(null);
    React.useEffect(() => {
        if (!sliderRef && elRef.current && !elRef.current?.blazeSlider) {
            setSliderRef(new BlazeSlider(elRef.current, config));
        }
    }, [config, sliderRef]);
    return [elRef, sliderRef] as const;
};
