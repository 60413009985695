import React, { useState } from 'react';
import { useId } from 'react';
import ReactSelect, { components, createFilter } from 'react-select';
import { FormHelperText } from '@mui/material';
import classNames from 'classnames';
import FormField from './FormField';

const noOp = () => undefined;
const styleReset = {
    option: noOp,
    control: noOp,
    singleValue: noOp,
    menu: noOp,
    menuList: noOp,
    input: noOp,
    placeholder: noOp,
    valueContainer: noOp,
    indicatorSeparator: noOp,
    container: noOp,
};
const DropdownIndicatorComponent = (props) => (
    <components.DropdownIndicator {...props}>
        <i className="fa fa-chevron-down open-indicator" data-cy="select-indicator" />
    </components.DropdownIndicator>
);
const OptionComponent = React.forwardRef(({ getOptionComponentProps, ...props }, ref) => {
    const passedProps = getOptionComponentProps ? getOptionComponentProps(props) : props;
    return (
        <div ref={ref}>
            <components.Option {...passedProps}>{props.children}</components.Option>
        </div>
    );
});
OptionComponent.displayName = 'OptionComponent';

const ReactSelectFormikInput = ({
    options,
    field,
    className,
    components,
    form: { touched, errors, setFieldValue, submitCount },
    filterOption,
    onFocus,
    onBlur,
    onChange,
    onMenuOpen,
    label,
    placeholder,
    floatLabel,
    ...props
}) => {
    const [focused, setFocused] = useState(false);
    const invalid = Boolean(errors[field.name] && (touched[field.name] || submitCount > 0));
    const labelComponent = label && <label htmlFor={props.id}>{label}</label>;
    const defaultFilterOption = createFilter();
    const componentClassNames = classNames(className, `select-${field.name}`, 'jucy-select form-control', {
        'is-invalid': invalid,
        'has-value': field.value,
        'has-focus': focused,
    });
    const selectedRef = React.useRef();
    const id = useId();
    return (
        <>
            {!floatLabel && labelComponent && labelComponent}
            <ReactSelect
                {...props}
                instanceId={props.id || id}
                styles={styleReset}
                isDisabled={props.disabled}
                className={componentClassNames}
                classNamePrefix={'jucy-select'}
                filterOption={(option, input) => (filterOption ? filterOption(option, input) : defaultFilterOption(option, input))}
                placeholder={floatLabel ? placeholder || label : placeholder}
                options={options}
                name={field.name}
                value={props.value || field.value || ''}
                onChange={(option, actionMeta) => {
                    if (onChange) {
                        onChange(option, actionMeta);
                    } else {
                        setFieldValue(field.name, option);
                    }
                }}
                onBlur={(e) => {
                    setFocused(false);
                    field.onBlur(e);
                    if (onBlur) {
                        onBlur(e);
                    }
                }}
                onFocus={(e) => {
                    setFocused(true);
                    field.onBlur(e);
                    if (onFocus) {
                        onFocus(e);
                    }
                }}
                components={{
                    DropdownIndicator: DropdownIndicatorComponent,
                    Option: (props) => (
                        <OptionComponent
                            ref={(ref) => {
                                if (props.isSelected && ref && selectedRef.current !== ref) {
                                    selectedRef.current = ref;
                                }
                            }}
                            {...props}
                        />
                    ),
                    ...components,
                }}
                onMenuOpen={(...args) => {
                    setTimeout(() => {
                        const menuList = selectedRef.current && selectedRef.current.closest && selectedRef.current.closest('.jucy-select__menu-list');
                        if (menuList) {
                            menuList.scrollTop = selectedRef.current.offsetTop - menuList.offsetTop;
                        }
                    }, 10);
                    if (onMenuOpen) {
                        onMenuOpen(...args);
                    }
                }}
            />
            {floatLabel && labelComponent && labelComponent}
            {invalid ? <FormHelperText error={true}>{errors[field.name]}</FormHelperText> : null}
        </>
    );
};
ReactSelectFormikInput.displayName = 'ReactSelectFormikInput';

const Select = (props) => <FormField {...props} component={ReactSelectFormikInput} />;
Select.displayName = 'Select';
export default Select;
