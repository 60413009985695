'use client';
import {cn} from '@jucy-ui/common';
import React, { useCallback } from 'react';
import { useController, useFormContext } from '@jucy-ui/forms';
import { BranchSelect, BranchSelectProps } from '../../fields';
import { useShouldDisableBranch, useSortRegions } from '../hooks';
import { searchFormValuesToData } from '../lib';
import {jucySearchFormClasses} from '../lib/jucySearchFormClasses';
import { searchFormRepoStore } from '../store';
import { SearchFormValues } from '../types';

export interface SearchFormBranchSelectProps extends Omit<BranchSelectProps, 'name' | 'value' | 'onBlur' | 'onChange' | 'shouldDisableBranch'> {
    name: 'pickUpBranch' | 'dropOffBranch';
}

export const SearchFormBranchSelect = ({ name,className, ...props }: SearchFormBranchSelectProps) => {
    const {
        field: { onChange, ...field },
    } = useController({ name: name });
    const { getValues, setValue, getFieldState, clearErrors } = useFormContext<SearchFormValues>();
    const handleChange = useCallback(
        (e: Parameters<Required<BranchSelectProps>['onChange']>[0]) => {
            onChange(e);
            clearErrors(name);
            const branch = searchFormRepoStore.branches.find((site) => site.siteCode === e.target.value);
            if (!branch) {
                return;
            }
            const { driverAge, dropOffBranch } = searchFormValuesToData(getValues());
            if (name === 'pickUpBranch' && !driverAge && branch.minAges[0] && !getFieldState('driverAge').isTouched) {
                setValue('driverAge', `${branch.minAges[0]}`, { shouldTouch: false });
                clearErrors('driverAge');
            } else if (
                branch.minAges.length &&
                driverAge &&
                !branch.minAges.includes(driverAge) &&
                !branch.minAges.map(parseInt).every((age) => parseInt(driverAge) > age)
            ) {
                setValue('driverAge', `${branch.minAges[branch.minAges.length - 1]}`, { shouldTouch: false });
                clearErrors('driverAge');
            }
            const dropOffFieldState = getFieldState('dropOffBranch');
            if (!dropOffFieldState.isTouched && !dropOffBranch) {
                setValue('dropOffBranch', branch.siteCode, { shouldTouch: false });
                clearErrors('dropOffBranch');
            }
            if (dropOffBranch && dropOffBranch.countryCode !== branch.countryCode) {
                setValue('dropOffBranch', '', { shouldTouch: false });
                clearErrors('dropOffBranch');
            }
        },
        [clearErrors, getFieldState, getValues, name, onChange, setValue]
    );
    return (
        <BranchSelect
            shouldDisableBranch={useShouldDisableBranch(name === 'pickUpBranch' ? 'start' : 'end')}
            onChange={handleChange}
            sortRegion={useSortRegions()}
            className={cn(className, jucySearchFormClasses.branchSelect, name === 'pickUpBranch' ? jucySearchFormClasses.pickUpBranch : jucySearchFormClasses.dropOffBranch)}
            {...field}
            {...props}
        />
    );
};
