import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CountryData, countryQueryKeys, getCountries } from '../services';

type UseGetBranchesOptions = UseQueryOptions<CountryData[], Error, CountryData[], string[]>;

export const useGetCountries = ({ staleTime = Infinity, ...options }: Omit<UseGetBranchesOptions, 'queryKey' | 'queryFn'> = {}) =>
    useQuery({
        queryKey: countryQueryKeys.list(),
        queryFn: getCountries,
        staleTime,
        ...options,
    });
