import { UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Region, mapSitesToRegion } from '../services';
import { useGetBranches } from './useGetBranches';

type UseGetRegionsOptions = UseQueryOptions<Region[], unknown, Region[], string[]>;

export const useGetRegions = (options?: Omit<UseGetRegionsOptions, 'queryKey' | 'queryFn'>) => {
    const { data, ...response } = useGetBranches(options as never);

    return useMemo(
        () => ({
            data: data ? mapSitesToRegion({ sites: data }) : data,
            ...response,
        }),
        [data, response]
    );
};
