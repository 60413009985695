'use client';
import {cn} from '@jucy-ui/common';
import React, { useCallback, useState } from 'react';
import { StackProps, styled } from '@mui/material';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { StackOwnProps } from '@mui/material/Stack/Stack';
import {jucySearchFormClasses} from '../lib';
import { AgeSelectProps, PromoCodeFieldProps } from '../../fields';
import { useGetDriverAges, useSearchFormStore } from '../hooks';
import { SearchFormDriveAge } from './SearchFormDriveAge';
import { SearchFormPromoCode } from './SearchFormPromoCode';

export interface SearchFormDriveAgePromoCodeFieldProps extends StackProps {
    ageSelectProps?: AgeSelectProps;
    promoCodeFieldProps?: PromoCodeFieldProps;
    disabled?: boolean;
}

export const SearchFormDriveAgePromoCodeField: React.FC<SearchFormDriveAgePromoCodeFieldProps> = ({
    direction,
    disabled,
    ageSelectProps,
    promoCodeFieldProps,
    ...props
}) => {
    const [promoCodeVisible, setPromoCodeVisible] = useState(() => Boolean(promoCodeFieldProps?.value || false));
    const handlePromoCheckChanged = useCallback(
        (visible: boolean) => {
            promoCodeFieldProps?.onVisibilityChanged?.(visible);
            setPromoCodeVisible(visible);
        },
        [promoCodeFieldProps]
    );
    const ages = useGetDriverAges();
    const store = useSearchFormStore();
    const driverAgeVisible = store.hiddenFields?.driverAge !== true && Boolean(ages?.length);

    return (
        <SearchFormDriveAgePromoCodeFieldRoot  {...props} className={cn(jucySearchFormClasses.driveAgePromoCodeContainer,props.className)} ownerState={{ direction, promoCodeVisible, driverAgeVisible }} direction={direction}>
            {driverAgeVisible ? <SearchFormDriveAge disabled={disabled || ageSelectProps?.disabled} /> : null}
            {store.hiddenFields?.promoCode ? null : (
                <SearchFormPromoCode onVisibilityChanged={handlePromoCheckChanged} disabled={disabled || promoCodeFieldProps?.disabled} />
            )}
        </SearchFormDriveAgePromoCodeFieldRoot>
    );
};

interface SearchFormDriveAgePromoCodeFieldOwnerState {
    driverAgeVisible: boolean;
    direction: StackOwnProps['direction'];
    promoCodeVisible: boolean;
}

const SearchFormDriveAgePromoCodeFieldRoot = styled(Stack, {
    name: 'SearchFormDriveAgePromoCodeField',
    slot: 'root',
})<{ ownerState?: SearchFormDriveAgePromoCodeFieldOwnerState }>(({ theme, ownerState }) => {
    const { direction, driverAgeVisible, promoCodeVisible } = ownerState || {};
    const driveAgeSelector = '& > div:nth-of-type(2n + 1)';
    const promoCodeSelector = '& > div:nth-of-type(2n + 2)';
    const adjustBorderRadius = Boolean(direction === 'row' && promoCodeVisible && driverAgeVisible);
    return {
        gap: direction === 'column' ? theme.spacing(1.5) : 0,
        ...{
            [`${driveAgeSelector},${promoCodeSelector}`]: {
                flex: '1 1 50%',
                position: 'relative',
                ['&:hover,&:focus']: {
                    zIndex: 2,
                },
                [`&.${inputBaseClasses.focused},&.${inputBaseClasses.error}`]: {
                    zIndex: 3,
                },
            },
        },
        [driveAgeSelector]: {
            ...(adjustBorderRadius
                ? {
                      [`.${inputBaseClasses.root}.${outlinedInputClasses.root}`]: {
                          borderBottomRightRadius: 0,
                          borderTopRightRadius: 0,
                          [`&:not(:hover):not(.${inputBaseClasses.focused}):not(.${inputBaseClasses.error})`]: {
                              [`.${outlinedInputClasses.notchedOutline}`]: {
                                  borderRightWidth: 0,
                              },
                          },
                      },
                  }
                : undefined),
        },
        '& .JucyPromoToggle': {
            paddingLeft: theme.spacing(1.5),
            ...(direction === 'column'
                ? {
                      marginTop: theme.spacing(-1.5),
                  }
                : undefined),
        },
        [promoCodeSelector]: {
            marginLeft: '-1px',
            ...(adjustBorderRadius
                ? {
                      [`.${outlinedInputClasses.root}`]: {
                          [`&, & .${outlinedInputClasses.notchedOutline}`]: {
                              borderBottomLeftRadius: 0,
                              borderTopLeftRadius: 0,
                          },
                      },
                  }
                : undefined),
        },
    };
});
