import { FieldPath, FieldValues } from 'react-hook-form';
import { FieldMeta, SchemaDescription } from '../lib';

export const descriptionToFieldMeta = <SchemaShape extends FieldValues>(
    name: FieldPath<SchemaShape>,
    description: SchemaDescription<SchemaShape>
): FieldMeta => ({
    required: !description.optional,
    label: description.label || '',
    name: name,
});
