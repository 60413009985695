import { JucyCache, LocalStorageStore, MemoryStore } from '@jucy/cache';

export * from '@jucy/cache';

const getCacheStore = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
        return new LocalStorageStore();
    }

    return new MemoryStore();
};
const jucyCache: Record<string, JucyCache> = {};

export const getJucyCacheInstance = (name = '') => {
    const service = `demo${name ? `-${name}` : ''}`;
    if (!jucyCache[service]) {
        jucyCache[service] = new JucyCache({
            service: service,
            store: getCacheStore(),
        });
    }
    return jucyCache[service];
};

export const cache = getJucyCacheInstance();
