import type { NextRequest } from 'next/server';
import { NextResponse } from 'next/server';
import { getBrandForRequest } from './getBrandForRequest';

const PUBLIC_FILE = /\.(.*)$/;

const brandedPages = ['/widget', '/obe'];

export const brandMiddleware = (req: NextRequest): NextResponse | undefined => {
    if (
        req.nextUrl.pathname.startsWith('/_next') ||
        req.nextUrl.pathname.includes('/api/') ||
        PUBLIC_FILE.test(req.nextUrl.pathname) ||
        !brandedPages.some((p) => req.nextUrl.pathname.includes(p))
    ) {
        return;
    }

    const brand = getBrandForRequest(req);
    const newUrl = req.nextUrl.clone();
    const pathParts = newUrl.pathname.split('/').filter(Boolean);
    const brandPathPart = pathParts[0];

    if (brand && brandPathPart !== brand) {
        newUrl.searchParams.set('brand', brand);
        newUrl.pathname = [brand, ...pathParts].join('/');
        return NextResponse.rewrite(newUrl, {
            headers: {
                'X-Brand': brand,
            },
        });
    }
    return;
};
