import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ButtonTypeMap, CircularProgress, Button as MuiButton, ButtonProps as MuiButtonProps, Theme } from '@mui/material';
import { mergeSx } from '#/lib/mergeSx';
import { SxProps } from '@mui/system';

export type JucyButtonProps<Component extends React.ElementType = ButtonTypeMap['defaultComponent'], P = Record<string, unknown>> = MuiButtonProps<
    Component,
    P & {
        showLoader?: boolean;
    }
>;

export const JucyButton = <C extends React.ElementType = ButtonTypeMap['defaultComponent'], P = Record<string, unknown>>({
    disableElevation = true,
    showLoader,
    endIcon,
    sx,
    ...props
}: JucyButtonProps<C, P>) => (
    <MuiButton
        disableElevation={disableElevation}
        endIcon={showLoader ? <CircularProgress sx={{ color: 'inherit' }} size={18} /> : endIcon}
        sx={mergeSx(
            {
                ...(props?.color === 'charcoal' ? { color: 'white' } : undefined),
            },
            sx
        )}
        {...props}
    />
);

export type JucyMuiTextButtonProps = Omit<JucyButtonProps, 'variant'> & { children: React.ReactNode; sx: SxProps<Theme> };
export const JucyMuiTextButton: React.FC<JucyMuiTextButtonProps> = ({ sx, children, ...props }) => (
    <JucyButton
        variant="text"
        size="small"
        sx={mergeSx(
            {
                fontWeight: 'bold',
                fontSize: '14px',
                minWidth: 0,
                ...(props?.color === 'charcoal' ? { color: 'white' } : undefined),
            },
            sx
        )}
        {...props}
    >
        {children}
    </JucyButton>
);

export type JucyMuiTextLinkProps = Omit<
    MuiButtonProps<
        'a',
        {
            external?: boolean;
        }
    >,
    'component' | 'variant'
>;

export const JucyMuiTextLink: React.FC<JucyMuiTextLinkProps> = ({ external, children, sx, ...props }) => (
    <JucyMuiTextButton
        component="a"
        sx={{
            '&:hover': {
                textDecoration: 'underline',
            },
            ...sx,
        }}
        {...props}
    >
        {children}
        {external ? <OpenInNewIcon sx={{ fontSize: '1.1em', ml: 0.5 }} /> : null}
    </JucyMuiTextButton>
);
