/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { Money } from './Money';
import { MoneyFromJSON, MoneyFromJSONTyped, MoneyToJSON } from './Money';

/**
 *
 * @export
 * @interface SecondaryProductItem
 */
export interface SecondaryProductItem {
    /**
     * Product unique identifier
     * @type {string}
     * @memberof SecondaryProductItem
     */
    id?: string;
    /**
     * Product unique short code
     * @type {string}
     * @memberof SecondaryProductItem
     */
    code?: string;
    /**
     * Product unique short code
     * @type {string}
     * @memberof SecondaryProductItem
     */
    associatedProductCode?: string;
    /**
     * Product display name
     * @type {string}
     * @memberof SecondaryProductItem
     */
    name?: string;
    /**
     *
     * @type {Money}
     * @memberof SecondaryProductItem
     */
    price?: Money;
    /**
     *
     * @type {number}
     * @memberof SecondaryProductItem
     */
    quantity?: number;
    /**
     *
     * @type {number}
     * @memberof SecondaryProductItem
     */
    rentalDays?: number;
    /**
     *
     * @type {Money}
     * @memberof SecondaryProductItem
     */
    maxPrice?: Money;
    /**
     *
     * @type {Money}
     * @memberof SecondaryProductItem
     */
    total?: Money;
    /**
     *
     * @type {boolean}
     * @memberof SecondaryProductItem
     */
    isExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof SecondaryProductItem
     */
    sortOrder?: number;
}

/**
 * Check if a given object implements the SecondaryProductItem interface.
 */
export function instanceOfSecondaryProductItem(value: object): value is SecondaryProductItem {
    return true;
}

export function SecondaryProductItemFromJSON(json: any): SecondaryProductItem {
    return SecondaryProductItemFromJSONTyped(json, false);
}

export function SecondaryProductItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecondaryProductItem {
    if (json == null) {
        return json;
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        code: json['code'] == null ? undefined : json['code'],
        associatedProductCode: json['associatedProductCode'] == null ? undefined : json['associatedProductCode'],
        name: json['name'] == null ? undefined : json['name'],
        price: json['price'] == null ? undefined : MoneyFromJSON(json['price']),
        quantity: json['quantity'] == null ? undefined : json['quantity'],
        rentalDays: json['rentalDays'] == null ? undefined : json['rentalDays'],
        maxPrice: json['maxPrice'] == null ? undefined : MoneyFromJSON(json['maxPrice']),
        total: json['total'] == null ? undefined : MoneyFromJSON(json['total']),
        isExcluded: json['isExcluded'] == null ? undefined : json['isExcluded'],
        sortOrder: json['sortOrder'] == null ? undefined : json['sortOrder'],
    };
}

export function SecondaryProductItemToJSON(value?: SecondaryProductItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        id: value['id'],
        code: value['code'],
        associatedProductCode: value['associatedProductCode'],
        name: value['name'],
        price: MoneyToJSON(value['price']),
        quantity: value['quantity'],
        rentalDays: value['rentalDays'],
        maxPrice: MoneyToJSON(value['maxPrice']),
        total: MoneyToJSON(value['total']),
        isExcluded: value['isExcluded'],
        sortOrder: value['sortOrder'],
    };
}
