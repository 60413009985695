import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { reverseTripStore } from '../../../../../store/ReverseTripStore';
import { formatCurrency } from '../../../../CurrencyFormatter/CurrencyFormatter';
import { useTeaserContext } from '../TeaserContext';

const DealBannerInner: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();
    return (
        <Box
            className="deal-banner"
            sx={{
                backgroundColor: theme.palette.secondary.main,
                width: '100%',
                color: '#fff',
                padding: '0 1rem',
                fontWeight: 'bold',
                fontSize: '14px',
                display: 'flex',
                paddingTop: '3px',
                paddingBottom: '3px',
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                '& i': {
                    fontSize: '18px',
                    marginTop: '-2px',
                    marginRight: '5px',
                },
                ['& + .fleet-category-teaser']: {
                    borderTopWidth: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
            }}
        >
            <i className="jucy-icon manualpayment" />
            <Box sx={{ marginTop: '2px' }}>{children}</Box>
        </Box>
    );
};
DealBannerInner.displayName = 'DealBannerInner';

const DealBanner: React.FC = observer(() => {
    const { deal } = useTeaserContext();
    if (!deal || !deal?.enabled) {
        return null;
    }

    return (
        <DealBannerInner>
            {deal.title || null}
            {deal.title && deal.hasCheaperReverseTrip ? <br /> : null}
            {deal.hasCheaperReverseTrip && deal.reverseTotal ? (
                <Link className="text-white" to={reverseTripStore.reverseTripLink || ''}>
                    Travel the opposite direction and save {formatCurrency(deal.reverseTotal)}
                </Link>
            ) : null}
        </DealBannerInner>
    );
});
DealBanner.displayName = 'DealBanner';

export default DealBanner;
