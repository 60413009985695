import { Site, SiteHoliday, isValidDate } from '@jucy-ui/common';
import { getISODay, isSameDay, isWithinInterval } from 'date-fns';

const isSingleDay = (holiday: SiteHoliday): boolean => Boolean(isSameDay(holiday.startDateTime, holiday.endDateTime));

const hasStartAndEnd = (holiday: SiteHoliday): boolean => isValidDate(holiday.startDateTime) && isValidDate(holiday.endDateTime);

const hasDow = (
    holiday: SiteHoliday
): holiday is SiteHoliday & {
    dow: number;
} => Boolean(holiday.dow && holiday.dow >= 1 && holiday.dow <= 7);

const isDateHoliday = ({ holiday, date }: { holiday: SiteHoliday; date: Date }): boolean => {
    if (isSingleDay(holiday) && isSameDay(holiday.startDateTime, date)) {
        return true;
    } else if (
        hasStartAndEnd(holiday) &&
        hasDow(holiday) &&
        getISODay(date) === holiday.dow &&
        isWithinInterval(date, { start: holiday.startDateTime, end: holiday.endDateTime })
    ) {
        return true;
    } else if (!hasStartAndEnd(holiday) && hasDow(holiday) && getISODay(date) === holiday.dow) {
        return true;
    } else if (hasStartAndEnd(holiday) && !hasDow(holiday) && isSingleDay(holiday) && isSameDay(date, holiday.startDateTime)) {
        return true;
    } else if (
        hasStartAndEnd(holiday) &&
        !hasDow(holiday) &&
        isWithinInterval(date, {
            start: holiday.startDateTime,
            end: holiday.endDateTime,
        })
    ) {
        return true;
    }
    return false;
};

export const getIsDateHolidayForBranch = ({ branch, date }: { branch: Site; date: Date }): boolean => {
    if (!branch.holidays.length) {
        return false;
    }
    for (const holiday of branch.holidays) {
        if (isDateHoliday({ holiday, date })) {
            return true;
        }
    }
    return false;
};
