import React from 'react';

export type GTMFrameProps = {
    config?: {
        auth?: string;
        id?: string;
        preview?: string;
    };
};

export const GTMFrame: React.FC<GTMFrameProps> = ({ config }) =>
    config?.id ? (
        <noscript>
            <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${config.id}&gtm_auth=${config.auth}&gtm_preview=${config.preview}&gtm_cookies_win=x`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
        </noscript>
    ) : null;
