/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { RentalCatalogResponse } from '../models/index';
import { RentalCatalogResponseFromJSON, RentalCatalogResponseToJSON } from '../models/index';
import * as runtime from '../runtime';

export interface GetRentalCatalogRequest {
    extended?: boolean;
}

/**
 *
 */
export class CatalogApi extends runtime.BaseAPI {
    /**
     * Get rental vehicle products
     */
    async getRentalCatalogRaw(
        requestParameters: GetRentalCatalogRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<RentalCatalogResponse>> {
        const queryParameters: any = {};

        if (requestParameters['extended'] != null) {
            queryParameters['extended'] = requestParameters['extended'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/rental-catalog`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => RentalCatalogResponseFromJSON(jsonValue));
    }

    /**
     * Get rental vehicle products
     */
    async getRentalCatalog(extended?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RentalCatalogResponse> {
        const response = await this.getRentalCatalogRaw({ extended: extended }, initOverrides);
        return await response.value();
    }
}
