/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { DynamicPricingResponseInnerProduct } from './DynamicPricingResponseInnerProduct';
import {
    DynamicPricingResponseInnerProductFromJSON,
    DynamicPricingResponseInnerProductFromJSONTyped,
    DynamicPricingResponseInnerProductToJSON,
} from './DynamicPricingResponseInnerProduct';
import type { Money } from './Money';
import { MoneyFromJSON, MoneyFromJSONTyped, MoneyToJSON } from './Money';

/**
 *
 * @export
 * @interface DynamicPricingResponseInner
 */
export interface DynamicPricingResponseInner {
    /**
     *
     * @type {Money}
     * @memberof DynamicPricingResponseInner
     */
    price?: Money;
    /**
     *
     * @type {DynamicPricingResponseInnerProduct}
     * @memberof DynamicPricingResponseInner
     */
    product?: DynamicPricingResponseInnerProduct;
}

/**
 * Check if a given object implements the DynamicPricingResponseInner interface.
 */
export function instanceOfDynamicPricingResponseInner(value: object): value is DynamicPricingResponseInner {
    return true;
}

export function DynamicPricingResponseInnerFromJSON(json: any): DynamicPricingResponseInner {
    return DynamicPricingResponseInnerFromJSONTyped(json, false);
}

export function DynamicPricingResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicPricingResponseInner {
    if (json == null) {
        return json;
    }
    return {
        price: json['price'] == null ? undefined : MoneyFromJSON(json['price']),
        product: json['product'] == null ? undefined : DynamicPricingResponseInnerProductFromJSON(json['product']),
    };
}

export function DynamicPricingResponseInnerToJSON(value?: DynamicPricingResponseInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        price: MoneyToJSON(value['price']),
        product: DynamicPricingResponseInnerProductToJSON(value['product']),
    };
}
