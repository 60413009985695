import { useFeatureIsOn } from './useFeatureIsOn';
import { useTripApi } from '#/hooks/useTripApi';
import appState from '../store/AppStateStore';

const useEditRouteId = () => {
    const tripEnabled = useTripApi();
    const editResEnabled = useFeatureIsOn('edit-reservation');
    if (tripEnabled && editResEnabled) {
        return 'create-trip';
    }
    return appState.mode === 'direct' ? 'direct-edit-quote' : 'edit-quote';
};

export default useEditRouteId;
