'use client';

import {mergeSx} from '@jucy-ui/appearance';
import {cn} from '@jucy-ui/common';
import {BoxProps} from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';

export const JucySliderPagination: React.FC<BoxProps> = ({className,sx,...props}) => (
    <Box
            {...props}
        className={cn('blaze-pagination', className)}
        sx={mergeSx( (theme) => ({
            height: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            '& button': {
                backgroundColor: theme.palette.common.white,
                borderColor: theme.palette.primary.main,
                borderRadius: '50%',
                borderStyle: 'solid',
                borderWidth: 1,
                boxSizing: 'border-box',
                flex: '0 0 10px',
                height: '10px',
                opacity: 1,
                boxShadow: 'none',
                minWidth: '10px',
                lineHeight: 0,
                padding: 0,
                mx: 0.25,
                cursor: 'pointer',
                fontSize: 0,
                transition: theme.transitions.create(['background-color', 'border-color']),
                width: '10px',
                '&.active': {
                    backgroundColor: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                },
            },
        }),sx)}
    ></Box>
);
