import { useMemo } from 'react';
import { URLSearchParamsInit, useSearchParams as useRRSearchParams } from 'react-router-dom';
import { parseNestedSearchParams } from '../lib/parseNestedSearchParams';

export const useSearchParams = <ParamsOrKey extends Record<string, string>>(): readonly [
    Partial<ParamsOrKey>,
    (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined;
                  state?: unknown;
              }
            | undefined
    ) => void
] => {
    const [params, setSearchParams] = useRRSearchParams();
    const searchPrams = useMemo(() => Object.fromEntries(params), [params]) as unknown as Partial<ParamsOrKey>;
    return [searchPrams, setSearchParams];
};
export const useNestedSearchParams = <ParamsOrKey extends Record<string, string | Record<string, string>>>(): readonly [
    Partial<ParamsOrKey>,
    (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined;
                  state?: unknown;
              }
            | undefined
    ) => void
] => {
    const [params, setSearchParams] = useSearchParams();
    const searchPrams = useMemo(() => parseNestedSearchParams(params), [params]) as unknown as Partial<ParamsOrKey>;
    return [searchPrams, setSearchParams];
};
