import { PaymentConfig } from '@jucy/rentals-api-client/rentals-api-v3';
import appStateStore from '../store/AppStateStore';
import AfterPayLogo from '../styles/images/Afterpay-Badge-BlackonMint.svg';
import { BookingCart } from '../types/BookingCart';

export const available = (amount: { currencyCode?: string; CurrencyCode?: string; value?: number; Value?: number }, paymentConfig?: PaymentConfig | null): boolean => {
    const value = amount.value || amount.Value || 0;
    if (!paymentConfig?.afterpay) {
        return false;
    }
    const isOver = typeof paymentConfig?.afterpay?.maximumAmount !== 'undefined' && value > paymentConfig?.afterpay.maximumAmount.value;
    const isUnder = typeof paymentConfig?.afterpay?.minimumAmount !== 'undefined' && value < paymentConfig?.afterpay.minimumAmount.value;
    return !(isOver || isUnder);
};

export const enabled = (summary: BookingCart): boolean => {
    if (appStateStore.mode !== 'direct') {
        return false;
    }
    if (appStateStore.brand !== 'jucy') {
        return false;
    }
    if (summary.fleetCategory?.minimumHirePeriod || summary.fleetCategory?.availability !== 'FreeSell') {
        return false;
    }
    return true;
};
export const weeklyPayments = (amount: { currencyCode?: string; CurrencyCode?: string; value?: number; Value?: number }): { currencyCode: string; value: number } => {
    const value = amount.value || amount.Value;
    return {
        value: value ? Math.round((value / 4 + Number.EPSILON) * 100) / 100 : 0,
        currencyCode: amount.currencyCode || amount.CurrencyCode || 'NZD',
    };
};

export const logo = AfterPayLogo;
