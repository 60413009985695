import { Region, Site } from '../models';

export const mapSitesToRegion = ({ sites }: { sites: Site[] }) => {
    const regions: Region[] = [
        {
            country: 'New Zealand',
            countryCode: 'nz',
            sites: [],
        },
        {
            country: 'Australia',
            countryCode: 'au',
            sites: [],
        },
    ];

    return regions.map((region) => {
        region.sites = (sites as Required<Site>[])
            .filter((site) => site.countryCode === region.countryCode)
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        return region;
    });
};
