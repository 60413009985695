import { useQueries } from '@tanstack/react-query';
import { getFleetTypesWithPreloadedImages } from '@jucy-ui/common/hooks/useGetFleetTypes';
import { type GenericUseQueryOptions, STALE_TIME } from '@jucy-ui/common/lib/ReactQueryUtils';
import {
    bookingQueryKeys,
    branchQueryKeys,
    fleetTypeQueryKeys,
    getBooking,
    getBookingOptions,
    getProductCatalog,
    getSites,
    productCatalogQueryKeys,
} from '@jucy-ui/common/services';
import type { BookedWith, Deal, HirerDetails } from '@jucy-ui/common/services/models';
import type { Simplify } from 'type-fest';
import { Maybe } from 'yup';
import { getTripInfoQueryFn, tripInfoQueryKeys } from './useGetTripInfo';

export type UseTripOptions = Simplify<
    {
        request?: Maybe<
            Partial<{
                driverAge: string;
                dropOffDate: Date;
                dropOffBranch: string;
                dropOffTime: Date;
                fleetCategory: string;
                pickUpDate: Date;
                pickUpBranch: string;
                pickUpTime: Date;
                promoCode: string;
                deal: Partial<Deal>;
                hirerDetails: Partial<HirerDetails>;
                reservationReference?: string;
                fleetType: string;
            }>
        >;
    } & Omit<GenericUseQueryOptions, 'queryKey' | 'queryFn'>
>;

export const useTrip = ({
    request,
    enabled,
    refetchOnWindowFocus = false,
    refetchOnMount = false,
    refetchInterval = false,
    staleTime = STALE_TIME.ONE_HOUR,
    ...queryOptions
}: UseTripOptions) => {
    const isBooking = Boolean(request?.reservationReference && request.hirerDetails?.lastName && request.pickUpBranch);

    const bookingReq = {
        reservationReference: request?.reservationReference || '',
        lastName: request?.hirerDetails?.lastName || '',
        pickUpBranchCode: request?.pickUpBranch || '',
    };

    const pickUpLocationCode = request?.pickUpBranch;
    const dropOffLocationLocationCode = request?.dropOffBranch;
    const fleetCategoryCode = request?.fleetCategory;
    const fleetTypeSlug = request?.fleetType;

    const options = {
        refetchOnWindowFocus,
        refetchOnMount,
        refetchInterval,
        staleTime,
        ...queryOptions,
    };
    return useQueries({
        queries: [
            {
                queryKey: tripInfoQueryKeys.get(request),
                queryFn: getTripInfoQueryFn,
                enabled: Boolean(enabled && !isBooking && Boolean(request)),
                ...options,
            },
            {
                queryKey: bookingQueryKeys.get(bookingReq),
                queryFn: () => getBooking(bookingReq),
                enabled: isBooking,
                ...options,
            },
            {
                queryKey: bookingQueryKeys.options(bookingReq.reservationReference),
                queryFn: () => getBookingOptions(bookingReq),
                enabled: isBooking,
                ...options,
            },
            {
                queryKey: branchQueryKeys.list(),
                queryFn: getSites,
                enabled: Boolean(enabled && (pickUpLocationCode || dropOffLocationLocationCode)),
                ...options,
            },
            {
                queryKey: productCatalogQueryKeys.list(),
                queryFn: getProductCatalog,
                enabled: Boolean(enabled && fleetCategoryCode),
                ...options,
            },
            {
                queryKey: fleetTypeQueryKeys.list(),
                queryFn: getFleetTypesWithPreloadedImages,
                enabled: Boolean(enabled && fleetTypeSlug),
                ...options,
            },
        ],
        combine: ([tripQuery, bookingQuery, bookingInfoQuery, sitesQuery, productCatalogQuery, fleetTypesQuery]) => {
            const trip = {
                ...tripQuery.data,
                ...bookingQuery.data,
                ...bookingInfoQuery.data,
            };
            return {
                trip: {
                    ...trip,
                    bookingNumber:
                        bookingQuery.data?.reservationId && bookingQuery.data.pickUpLocation
                            ? `#${bookingQuery.data.pickUpLocation}-${bookingQuery.data.reservationId}`
                            : undefined,
                    excessAndSecondaryProducts: [
                        ...(trip.secondaryProducts ? trip.secondaryProducts.flatMap((i) => i.items) : []),
                        ...(trip.insuranceProducts?.items ? trip.insuranceProducts.items : []),
                    ],
                    pickUpBranch: sitesQuery.data?.find((s) => s.siteCode === pickUpLocationCode),
                    dropOffBranch: sitesQuery.data?.find((s) => s.siteCode === dropOffLocationLocationCode),
                    catalogItem: productCatalogQuery.data?.find((catalogItem) => catalogItem.code === fleetCategoryCode),
                    fleetType: fleetTypesQuery.data?.find((ft) => ft.slug === fleetTypeSlug),
                    bookedWith: undefined as BookedWith | undefined,
                },
                isLoading:
                    tripQuery.isLoading ||
                    bookingQuery.isLoading ||
                    bookingInfoQuery.isLoading ||
                    sitesQuery.isLoading ||
                    fleetTypesQuery.isLoading ||
                    productCatalogQuery.isLoading,
                error:
                    tripQuery.error ||
                    bookingQuery.error ||
                    bookingInfoQuery.error ||
                    sitesQuery.error ||
                    fleetTypesQuery.error ||
                    productCatalogQuery.error,
            };
        },
    });
};

export type UseTripResponse = ReturnType<typeof useTrip>;
export type TripData = Simplify<UseTripResponse['trip']>;
