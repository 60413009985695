'use client';
export const fleetCategoryDetailsDialogTabs = {
    details: 'Details',
    features: 'Features & Extras',
    specs: 'Specs & plans',
    excess: 'Excess Reduction',
};
export const fleetCategoryDetailsDialogTabNames = Object.keys(fleetCategoryDetailsDialogTabs) as (keyof typeof fleetCategoryDetailsDialogTabs)[];
export const getFleetCategoryDetailsDialogTabPanelProps = (tabName: keyof typeof fleetCategoryDetailsDialogTabs) => ({
    value: tabName,
    label: fleetCategoryDetailsDialogTabs[tabName],
    a11yName: tabName,
});
