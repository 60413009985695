import type { DefaultError, QueryFunction, QueryKey, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

export type ExtractTypes<T> = T extends
    | UseQueryOptions<infer TQueryFnData, infer TError, infer TData, infer TQueryKey>
    | Omit<UseQueryOptions<infer TQueryFnData, infer TError, infer TData, infer TQueryKey>, 'queryKey' | 'queryFn'>
    ? { queryFnData: TQueryFnData; error: TError; data: TData; qKey: TQueryKey }
    : never;

export type ExtractQueryFnData<Type> = ExtractTypes<Type> extends never ? never : ExtractTypes<Type>['queryFnData'];
export type ExtractQueryError<Type> = ExtractTypes<Type> extends never ? never : ExtractTypes<Type>['error'];
export type ExtractQueryData<Type> = ExtractTypes<Type> extends never ? never : ExtractTypes<Type>['data'];
export type ExtractQueryKey<Type> = ExtractTypes<Type> extends never ? never : ExtractTypes<Type>['qKey'];
export type ExtractQueryFn<Type> = QueryFunction<ExtractQueryFnData<Type>, ExtractQueryKey<Type>>;
export type ExtractRequestParams<Type> = Type extends { request: infer TRequest } ? TRequest : never;

export type UseQueryParams<TRequest, TResult, TQueryKey extends QueryKey = QueryKey> = Omit<
    UseQueryOptions<TResult, DefaultError, TResult, TQueryKey>,
    'queryKey' | 'queryFn'
> & { request: TRequest };

export type UseMutationParams<QueryParams> = Omit<
    UseMutationOptions<ExtractQueryData<QueryParams>, DefaultError, ExtractRequestParams<QueryParams>>,
    'mutationFn' | 'mutationKey'
>;

export const STALE_TIME = {
    ONE_MINUTE: 60000,
    ONE_HOUR: 3600000,
    ONE_DAY: 86400000,
    INFINITY: Infinity,
};

export type GenericUseQueryOptions = Omit<
    UseQueryOptions,
    | 'query'
    | 'refetchInterval'
    | 'refetchOnWindowFocus'
    | 'refetchOnReconnect'
    | 'refetchOnMount'
    | 'throwOnError'
    | 'retry'
    | 'retryDelay'
    | 'placeholderData'
    | 'persister'
    | 'initialData'
    | 'context'
    | 'behavior'
> & {
    refetchInterval?: number | false;
    refetchOnWindowFocus?: boolean | 'always';
    refetchOnReconnect?: boolean | 'always';
    refetchOnMount?: boolean | 'always';
    throwOnError?: boolean;
    retry?: boolean | number;
    retryDelay?: number;
};
