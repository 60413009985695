import { debounce, useResizeObserver } from '@jucy-ui/common';
import { avatarClasses } from '@mui/material/Avatar';

export const useFleetTypeThumbnailWatcher = () => useResizeObserver(onResize);

const onResize = debounce((entries: ResizeObserverEntry[]) => {
    const group = entries[0]?.target as HTMLElement | undefined;
    if (!group || group.dataset.loading === 'true') {
        return;
    }
    const parentStyle = window.getComputedStyle(group);
    if (parentStyle.width && parentStyle.width.includes('539.')) {
        // hacky fix to stop constant resizing glitch in safari for widths around 578-588px
        return;
    }
    const direction = parentStyle.flexDirection;

    for (const fleetTypeBtn of Array.from(group.children)) {
        const avatar = fleetTypeBtn.querySelector<HTMLImageElement>(`.${avatarClasses.root} img`);
        if (!avatar || !avatar.parentElement) {
            return;
        }
        if (direction === 'column') {
            if (avatar.style.width) {
                avatar.style.width = '';
            }
            continue;
        }
        const avatarWidth = getIntrinsicWidth(avatar);
        const buttonRoom = avatar.getBoundingClientRect().left - fleetTypeBtn.getBoundingClientRect().left;
        const hidden = avatar.getBoundingClientRect().width === 0;
        const remainingSpace = calculateRemainingWidth(group);
        if (buttonRoom <= 3 && !hidden) {
            avatar.style.width = '0';
        } else if (remainingSpace > avatarWidth + 2 && hidden) {
            avatar.style.width = '';
        }
    }
}, 10);

const getIntrinsicWidth = (el: HTMLElement) => {
    const tmpWidth = el.style.width;
    el.style.width = 'auto';
    const width = el.getBoundingClientRect().width;
    el.style.width = tmpWidth;
    return width;
};

const calculateRemainingWidth = (container: HTMLElement) => {
    const parentStyle = window.getComputedStyle(container);
    const gap = Math.ceil(parentStyle.gap ? parseInt(parentStyle.gap) : 0);
    const totalChildWidth = Array.from(container.children).reduce((totalChildWidth, child, index) => {
        const childWidth = Math.ceil(child.getBoundingClientRect().width);
        if (index < container.children.length - 1) {
            return totalChildWidth + childWidth + gap;
        }
        return totalChildWidth + childWidth;
    }, 0);
    return container.clientWidth - totalChildWidth;
};
