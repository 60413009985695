import { Breakpoint, useTheme } from '@mui/material';
import { MediaConfig } from 'blaze-slider';
import { JucySliderConfig } from './JucySliderConfig';
import { StandardCSSProperties } from '@mui/system';

const mediaConfigToCssVars = (config: Partial<MediaConfig>) => {
    const result: Record<string,number|string> = {};
    if (config.slidesToShow) {
        result['--slides-to-show'] = config.slidesToShow;
    }
    if (config.slideGap) {
        result['--slide-gap'] = config.slideGap;
    }
    return result;
};

export const useMapConfigToSx = (config?: JucySliderConfig) => {
    const theme = useTheme();
    return config
        ? Object.entries(config).reduce(
              (res, [muiBreakpoint, value]) => {
                  const sxBreakPoint = muiBreakpoint === 'all' ? 'all' : theme.breakpoints.only(muiBreakpoint as Breakpoint);
                  const cssVars = mediaConfigToCssVars(value);

                  if (sxBreakPoint === 'all') {
                      return {
                          ...res,
                          ...cssVars,
                      };
                  }

                  return {
                      ...res,
                      [sxBreakPoint]: {
                          ...cssVars,
                      },
                  };
              },
              {
                  '--slides-to-show': 1,
                  '--slide-gap': theme.spacing(3),
              } as StandardCSSProperties
          )
        : undefined;
};
