/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { FleetCategoryMandatoryFeesPrice } from './FleetCategoryMandatoryFeesPrice';
import {
    FleetCategoryMandatoryFeesPriceFromJSON,
    FleetCategoryMandatoryFeesPriceFromJSONTyped,
    FleetCategoryMandatoryFeesPriceToJSON,
} from './FleetCategoryMandatoryFeesPrice';

/**
 *
 * @export
 * @interface FleetCategoryMandatoryFees
 */
export interface FleetCategoryMandatoryFees {
    /**
     * Display name
     * @type {string}
     * @memberof FleetCategoryMandatoryFees
     */
    name?: string;
    /**
     *
     * @type {FleetCategoryMandatoryFeesPrice}
     * @memberof FleetCategoryMandatoryFees
     */
    price?: FleetCategoryMandatoryFeesPrice;
}

/**
 * Check if a given object implements the FleetCategoryMandatoryFees interface.
 */
export function instanceOfFleetCategoryMandatoryFees(value: object): value is FleetCategoryMandatoryFees {
    return true;
}

export function FleetCategoryMandatoryFeesFromJSON(json: any): FleetCategoryMandatoryFees {
    return FleetCategoryMandatoryFeesFromJSONTyped(json, false);
}

export function FleetCategoryMandatoryFeesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetCategoryMandatoryFees {
    if (json == null) {
        return json;
    }
    return {
        name: json['Name'] == null ? undefined : json['Name'],
        price: json['Price'] == null ? undefined : FleetCategoryMandatoryFeesPriceFromJSON(json['Price']),
    };
}

export function FleetCategoryMandatoryFeesToJSON(value?: FleetCategoryMandatoryFees | null): any {
    if (value == null) {
        return value;
    }
    return {
        Name: value['name'],
        Price: FleetCategoryMandatoryFeesPriceToJSON(value['price']),
    };
}
