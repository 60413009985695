'use client';

import React, { ForwardedRef } from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { cn } from '@jucy-ui/common';
import { PopperProps } from '@mui/material';
import { StackProps } from '@mui/material/Stack';
import { DateRange, DateRangePicker, RangePosition, UseDateRangeFieldProps } from '@mui/x-date-pickers-pro';
import { DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker/DateRangePicker.types';
import { RangePickerFieldSlotProps } from '@mui/x-date-pickers-pro/internals/hooks/useEnrichedRangePickerFieldProps';
import { PickerValidDate } from '@mui/x-date-pickers/models';
import { TripFieldRoot } from './components';

export type TripDateRangePickerProps<TDate  extends PickerValidDate> = DateRangePickerProps<TDate,true> & {
    value?: DateRange<TDate>;
    children: (props: { startField: React.ReactNode; endField: React.ReactNode }) => React.ReactNode;
};
const TripDateRangePickerComponent = <TDate extends PickerValidDate>(
    { slotProps, slots, children, ...props }: TripDateRangePickerProps<TDate>,
    ref: ForwardedRef<HTMLDivElement>
) => (
    <DateRangePicker
        ref={ref}
        {...props}
        slots={{
            ...slots,
            fieldRoot: TripFieldRoot as unknown as React.ElementType<StackProps>,
        }}
        slotProps={
            {
                ...slotProps,
                textField: (position: UseDateRangeFieldProps<TDate, true> & { position?: RangePosition | undefined }) => {
                    const passedProps = typeof slotProps?.textField === 'function' ? slotProps.textField(position) : slotProps?.textField;
                    return {
                        ...passedProps,
                        className: cn('date-picker', passedProps?.className),
                    };
                },
                fieldRoot: {
                    renderer: children,
                    ...slotProps?.fieldRoot,
                } as unknown as RangePickerFieldSlotProps<TDate, true>['fieldRoot'],
                popper: {
                    ...slotProps?.popper,
                    sx: mergeSx(
                        (t) => ({
                            zIndex: t.zIndex.modal,
                        }),
                        (slotProps?.popper as PopperProps).sx
                    ),
                },
            }
        }
    />
);

interface WithForwardRefType<TDate extends PickerValidDate> extends React.FC<TripDateRangePickerProps<TDate>> {
    (props: TripDateRangePickerProps<TDate>): ReturnType<React.FC<TripDateRangePickerProps<TDate>>>;
}

export const TripDateRangePicker = React.memo(
    React.forwardRef<HTMLDivElement, TripDateRangePickerProps<Date>>(TripDateRangePickerComponent)
) as WithForwardRefType<Date>;
