'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { cn } from '@jucy-ui/common/lib/cn';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { BlazeConfig } from 'blaze-slider';


export interface JucySliderRootProps extends BoxProps {
    slidesToShow?: number;
    slideGap?: string;
    ref?: React.Ref<HTMLDivElement>;
    config?: BlazeConfig;
}

export const JucySliderRoot = React.forwardRef<HTMLDivElement, JucySliderRootProps>(
    ({ config, slideGap, slidesToShow, children, className, sx, ...props }, ref) => (
            <Box
                ref={ref}
                className={cn('blaze-slider', className)}
                sx={mergeSx(
                    {
                        direction: 'ltr',
                    },
                    sx
                )}
                {...props}
            >
                {children}
            </Box>
        )
);

JucySliderRoot.displayName = 'JucySliderRoot';
