'use client';
import React from 'react';
import { useGetHirePeriods } from '@jucy-ui/common';
import { useSearchFormFieldValues, useSearchFormStore } from '../hooks';
import { getLeadTimeHours, getMinDate, getMinDropOff, getMinHireDays, getMinPickUp, searchFormValuesToData } from '../lib';

export const SearchFormDebugPanel: React.FC<{ logIt?: boolean; showJson?: boolean }> = ({ logIt, showJson }) => {
    const { data: hirePeriods } = useGetHirePeriods();
    const { pickUpBranch, fleetType, dropOffBranch, pickUpDate, pickUpTime, dropOffDate, dropOffTime, driverAge, promoCode } =
        searchFormValuesToData(useSearchFormFieldValues());
    const leadTime = getLeadTimeHours({ fleetType });
    const store = useSearchFormStore();
    const variant = store.variant;
    const minDate = getMinDate({ fleetType });
    const minPickUp = getMinPickUp({
        fleetType,
        date: pickUpDate,
        branch: pickUpBranch,
    });
    if (logIt) {
        // eslint-disable-next-line no-console
        console.log({
            fleetType,
            pickUpBranch,
            dropOffBranch,
            pickUpDate,
            pickUpTime,
            dropOffDate,
            dropOffTime,
            driverAge,
            promoCode,
        });
    }
    const minHireDays = getMinHireDays({
        date: pickUpDate,
        pickUpBranch,
        dropOffBranch,
        fleetType,
        hirePeriods,
    });
    const minDropOff = getMinDropOff({
        pickUpDate,
        pickUpBranch,
        dropOffBranch,
        fleetType,
        hirePeriods,
    });
    return (
        <pre style={{ overflow: 'auto' }}>
            variant: {variant}
            {'\n'}
            fleetType: {fleetType?.slug} {'\n'}
            pickUpBranch: {pickUpBranch?.siteCode} {'\n'}
            dropOffBranch: {dropOffBranch?.siteCode} {'\n'}
            pickUpDate: {pickUpDate?.toLocaleString()} {'\n'}
            pickUpTime: {pickUpTime?.toLocaleString()} {'\n'}
            dropOffDate: {dropOffDate?.toLocaleString()} {'\n'}
            dropOffTime: {dropOffTime?.toLocaleString()} {'\n'}
            driverAge: {driverAge} {'\n'}
            promoCode: {promoCode} {'\n'}
            {'\n'}
            leadTime: {leadTime} {'\n'}
            minDate: {minDate.toLocaleString()} {'\n'}
            minPickUp: {minPickUp?.toLocaleString()} {'\n'}
            minDropOff: {minDropOff.toLocaleString()} {'\n'}
            minHireDays: {minHireDays}
            {showJson ? (
                <>
                    {'\n'}
                    {JSON.stringify(
                        {
                            fleetType,
                            pickUpBranch,
                            dropOffBranch,
                            pickUpDate,
                            pickUpTime,
                            dropOffDate,
                            dropOffTime,
                            driverAge,
                            promoCode,
                        },
                        null,
                        2
                    )}
                </>
            ) : null}
        </pre>
    );
};
