import { resolveError } from './resolveError';
import { asError } from './asError';
import { datadogRum } from '@datadog/browser-rum';
export type LogLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';

export interface Breadcrumb {
    type?: string;
    level?: LogLevel;
    event_id?: string;
    category?: string;
    message?: string;
    data?: {
        [key: string]: unknown;
    };
    timestamp?: number;
}

const breadCrumbs: Breadcrumb[] = [];
export default class ErrorReporter {
    static reportError({ error: passedError, level = 'error', tags, extraInfo }: { error?: Error; level?: LogLevel; tags?: Record<string, string>; extraInfo?: Record<string, unknown> }) {
        try {
            resolveError(passedError).then((resolvedError) => {
                datadogRum.addError(resolvedError, {
                    level,
                    tags,
                    extraInfo,
                    breadCrumbs,
                });
            });
        } catch (err) {
            datadogRum.addError(err, {
                passedError,
                level,
                tags,
                extraInfo,
                breadCrumbs,
            });
        }
    }

    static captureError(err: unknown) {
        datadogRum.addError(asError(err), { breadCrumbs });
    }

    static addBreadcrumb(breadcrumb: Breadcrumb) {
        breadCrumbs.push(breadcrumb);
    }
}
