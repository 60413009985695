import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import getJucyRentalsApiClient from '#/lib/getJucyRentalsApiClient';
import { AgentInfo } from '@jucy/rentals-api-client/rentals-api-v3';

export const useAccountInfo = (accountKey?: string): { isLoading: boolean; accountInfo?: AgentInfo } => {
    const { data, isLoading: isAccountDetailsLoading } = useQuery({
        queryKey: [`account-details-${accountKey}`],
        queryFn: () => getJucyRentalsApiClient({ accountKey }).getAgentUser(accountKey as string),
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        retryOnMount: false,
        enabled: Boolean(accountKey),
    });
    const isLoading = Boolean(accountKey) && isAccountDetailsLoading;
    return useMemo(
        () => ({
            accountInfo: data,
            isLoading: isLoading,
        }),
        [data, isLoading]
    );
};
