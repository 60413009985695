import { useMemo } from 'react';
import { isValidDate } from '@jucy-ui/common';
import { max } from 'date-fns';
import { getMinDate, getMinPickUp, searchFormValuesToData } from '../lib';
import { useSearchFormData } from './useSearchFormData';
import { useSearchFormFieldValue } from './useSearchFormFieldValue';

export const useGetMinDate = () => {
    const fleetTypeSlug = useSearchFormFieldValue('fleetType');
    const deal = useSearchFormFieldValue('deal') || undefined;
    const pickUpBranchCode = useSearchFormFieldValue('pickUpBranch') || undefined;
    const { pickUpBranch } = searchFormValuesToData({ pickUpBranch: pickUpBranchCode });
    const fleetType = useSearchFormData().getFleetType(fleetTypeSlug);
    const minDate = useMemo(() => getMinDate({ fleetType, deal }), [fleetType, deal]);

    if (pickUpBranch) {
        const locationMinDate = getMinPickUp({ date: minDate, branch: pickUpBranch, fleetType: fleetType });
        return isValidDate(locationMinDate) ? max([locationMinDate, minDate]) : minDate;
    }

    return minDate;
};
