import { isValidDate } from '@jucy-ui/common';
import { isAfter, isBefore } from 'date-fns';
import { getServiceHours } from '../lib';
import { SearchFormData } from '../types';

export const validateDropOffTime = ({ dropOffBranch, dropOffTime, fleetType }: Partial<SearchFormData>): string | undefined => {
    if (!isValidDate(dropOffTime)) {
        return 'Please select valid drop off time';
    }
    const siteSettings = dropOffBranch && dropOffBranch.siteSettings.find((siteSetting) => siteSetting.fleetTypeId === fleetType?.id);
    const serviceHours = siteSettings && getServiceHours({ date: dropOffTime, siteSettings });

    if (
        !siteSettings?.isAfterHoursPickUpAvailable &&
        serviceHours &&
        (isBefore(dropOffTime, serviceHours.open) || isAfter(dropOffTime, serviceHours.close))
    ) {
        return 'Drop off branch is closed on at this time and after hours drop off is not available';
    }
};
