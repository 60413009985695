import { Modifier } from 'react-day-picker/types/Modifiers';
import { differenceInMinutes, format, getISODay, isSameDay, isWithinInterval, parse } from 'date-fns';
import { isValidDate } from '../../lib/dates';

export class Holiday {
    name = '';
    start?: Date;
    end?: Date;
    dow?: number;

    hasStartAndEnd(): this is Holiday & { start: Date; end: Date } {
        return isValidDate(this.start) && isValidDate(this.end);
    }

    hasDow(): this is Holiday & { dow: number } {
        return Boolean(this.dow && this.dow >= 1 && this.dow <= 7);
    }

    isSingleDay(): this is Holiday & { start: Date } {
        if (this.hasDow()) {
            return false;
        }
        if (isValidDate(this.start) && isValidDate(this.end)) {
            const minutesDiff = differenceInMinutes(this.end, this.start);
            return Boolean(minutesDiff === 0 || (minutesDiff >= 1439 && minutesDiff <= 1440));
        } else if (isValidDate(this.start) && !isValidDate(this.end)) {
            return true;
        }
        return false;
    }

    isForDate(date: Date): boolean {
        if (this.isSingleDay() && isSameDay(this.start, date)) {
            return true;
        } else if (this.hasStartAndEnd() && this.hasDow() && isWithinInterval(date, this) && getISODay(date) === this.dow) {
            return true;
        } else if (!this.hasStartAndEnd() && this.hasDow() && getISODay(date) === this.dow) {
            return true;
        } else if (this.hasStartAndEnd() && !this.hasDow() && this.isSingleDay()) {
            return isSameDay(date, this.start);
        } else if (this.hasStartAndEnd() && !this.hasDow()) {
            return isWithinInterval(date, this);
        }
        return false;
    }

    asModifier(): Modifier {
        if (this.hasDow()) {
            return {
                daysOfWeek: [Number(format(parse(`${this.dow}`, 'i', new Date()), 'c'))],
            };
        } else if (this.isSingleDay()) {
            return this.start;
        } else if (this.hasStartAndEnd()) {
            return {
                from: this.start,
                to: this.end,
            };
        }
        return undefined;
    }
}
