import React from 'react';
import { SearchFormContext } from '../store/SearchFormContext';
import { useSignals } from '@preact/signals-react/runtime';

export const useSearchFormStore = () => {
    useSignals();
    const context = React.useContext(SearchFormContext);
    if (!context) {
        throw new Error('useSearchFormContext must be used within a SearchFormContext');
    }
    return context;
};
