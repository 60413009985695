import { KeenSliderOptions, KeenSliderPlugin, useKeenSlider } from 'keen-slider/react';
import * as React from 'react';
import { useCallback, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, IconButton, IconButtonProps, SxProps, Theme } from '@mui/material';
import { KeenSliderInstance } from 'keen-slider';
import 'keen-slider/keen-slider.min.css';
import { mergeSx } from '#/lib/mergeSx';

type SliderWithNavProps = {
    options?: KeenSliderOptions;
    showNav?: boolean;
    showDots?: boolean;
    sx?: SxProps<Theme>;
    navSx?: SxProps<Theme>;
    navNextSx?: SxProps<Theme>;
    navPrevSx?: SxProps<Theme>;
    plugins?: KeenSliderPlugin[];
    children: React.ReactNode;
};
const NavButton: React.FC<IconButtonProps> = ({ children, sx, ...props }) => (
    <IconButton
        {...props}
        sx={mergeSx(
            (t) => ({
                background: t.palette.common.white,
                borderRadius: '50%',
                boxShadow: '0 1px 1px rgb(0 0 0 / 16%)',
                color: '#636e73',
                height: '30px',
                border: 'unset',
                width: '30px',
                padding: 0,
                marginTop: 0,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                transition: t.transitions.create(['box-shadow', 'transform']),
                '&:hover': {
                    background: t.palette.common.white,
                    boxShadow: 'unset',
                },
                '&:disabled': {
                    opacity: 0,
                    pointerEvents: 'none',
                },
            }),
            sx
        )}
    >
        {children}
    </IconButton>
);

const JucySlider: React.FC<SliderWithNavProps> = ({ sx, children, options, showNav, navSx, navNextSx, navPrevSx, plugins, showDots }) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [loaded, setLoaded] = useState(false);

    const slideChanged: KeenSliderOptions['slideChanged'] = useCallback(
        (slider: KeenSliderInstance) => {
            setCurrentSlide(slider.track.details.rel);
            if (options?.slideChanged) {
                options.slideChanged(slider);
            }
        },
        [options]
    );

    const created: KeenSliderOptions['created'] = useCallback(
        (slider: KeenSliderInstance) => {
            setLoaded(true);
            if (options?.created) {
                options.created(slider);
            }
        },
        [options]
    );

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
        {
            ...options,
            slideChanged,
            created,
        },
        plugins || []
    );
    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Box sx={sx} className="keen-slider" ref={sliderRef}>
                    {children}
                </Box>
                {showNav && loaded && instanceRef?.current?.prev && (
                    <>
                        <NavButton
                            sx={mergeSx({ left: -15 }, navSx, navPrevSx)}
                            onClick={(e) => {
                                e.stopPropagation();
                                instanceRef?.current?.prev();
                            }}
                            disabled={!options?.loop && currentSlide === 0}
                        >
                            <ChevronLeftIcon />
                        </NavButton>
                        <NavButton
                            sx={mergeSx({ right: -15 }, navSx, navNextSx)}
                            onClick={(e) => {
                                e.stopPropagation();
                                instanceRef?.current?.next();
                            }}
                            disabled={!options?.loop && currentSlide === instanceRef.current.track.details.slides.length - 1}
                        >
                            <ChevronRightIcon />
                        </NavButton>
                    </>
                )}
            </Box>
            {showDots && loaded && instanceRef.current && (
                <Box>
                    {[...Array(instanceRef.current.track.details.slides.length).keys()].map((idx) => (
                        <Button
                            variant="contained"
                            key={idx}
                            onClick={() => {
                                instanceRef.current?.moveToIdx(idx);
                            }}
                            sx={(t) => ({
                                backgroundColor: t.palette.common.white,
                                borderColor: t.palette.primary.main,
                                borderRadius: '50%',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                boxSizing: 'border-box',
                                flex: '0 0 10px',
                                height: '10px',
                                opacity: 1,
                                boxShadow: 'none',
                                minWidth: '10px',
                                lineHeight: 0,
                                padding: 0,
                                mx: 0.25,
                                transition: t.transitions.create(['background-color', 'border-color']),
                                width: '10px',
                                ...(currentSlide === idx
                                    ? {
                                          backgroundColor: t.palette.secondary.main,
                                          borderColor: t.palette.secondary.main,
                                      }
                                    : undefined),
                            })}
                        ></Button>
                    ))}
                </Box>
            )}
        </>
    );
};
export default JucySlider;
