import { observer } from 'mobx-react-lite';
import React from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { JucyButton, JucyMuiTextLink } from '../Button/Button';
import ModalDialog from '../ModalDialog';
import { PageTitle } from '../Typograpgy/Title';

export const DriversLicenceWarningModal: React.FC<{ showModal?: boolean; setShowModal?: (showModal: boolean) => void }> = observer(({ showModal, setShowModal }) => (
    <ModalDialog open={showModal || false} onClose={() => setShowModal?.(false)} maxWidth="sm">
        <DialogTitle component="div">
            <PageTitle>Drivers Licence Warning</PageTitle>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: 0 }}>
            <p>
                If all fields of your license are not written in English it is a legal requirement in NZ that you carry an <strong>International Drivers Permit</strong> or a{' '}
                <strong>Certified Translation</strong> of your Drivers Licence.
                <br />
                <strong>This must accompany the original home country Driver Licence.</strong>
            </p>
            <p>
                <JucyMuiTextLink
                    href="https://www.nzta.govt.nz/driver-licences/new-residents-and-visitors/approved-translators/"
                    sx={{ padding: 0 }}
                    title="Approved Driver License Translators NZ"
                    target="_blank"
                    rel="noopener noreferrer"
                    external={true}
                >
                    Find an approved license translator
                </JucyMuiTextLink>
                .
            </p>
        </DialogContent>
        <DialogActions>
            <JucyButton data-cy="email-dismiss-license-modal" type="submit" variant="contained" onClick={() => setShowModal?.(false)}>
                OK
            </JucyButton>
        </DialogActions>
    </ModalDialog>
));
export default DriversLicenceWarningModal;
