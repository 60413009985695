'use client';
import React from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import { styled } from '@mui/material';
import { JucyToastCloseIcon } from './JucyToastCloseIcon';
import { JucyToastIcon } from './JucyToastIcon';
import { generateToastContainerStyles } from './lib';

export const JucyToastContainer: React.FC<ToastContainerProps> = ({ icon, closeButton, position, ...props }) => (
    <JucyToastContainerRoot>
        <ToastContainer
            icon={icon || JucyToastIcon}
            closeButton={closeButton || JucyToastCloseIcon}
            position={position || 'bottom-center'}
            {...props}
        />
    </JucyToastContainerRoot>
);
export const JucyToastContainerRoot = styled('div', {
    name: 'JucyToastContainer',
    slot: 'root',
})(({ theme }) => generateToastContainerStyles(theme));
