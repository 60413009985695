import { makeAutoObservable } from 'mobx';

type Many<T> = T | ReadonlyArray<T>;
type PropertyName = string | number | symbol;
type PropertyPath = Many<PropertyName>;
const ldSet = <T, R>(obj: T, path: PropertyPath, value: R) => {
    if (Object(obj) !== obj) return obj; // When obj is not an object
    // If not yet an array, get the keys from the string-path
    if (!Array.isArray(path)) {
        path = path.toString().match(/[^.[\]]+/g) || [];
    }
    path.slice(0, -1).reduce(
        (
            a,
            c,
            i // Iterate all of them except the last one
        ) => {
            if (Object(a[c]) === a[c]) {
                return a[c];
            }
            a[c] =
                Math.abs(path[(i + 1) as unknown as keyof PropertyPath] as unknown as number) >> 0 === +path[(i + 1) as unknown as keyof PropertyPath]
                    ? [] // Yes: assign a new array object
                    : {}; // No: assign a new plain object

            return a[c];
        },
        obj
    )[path[path.length - 1]] = value as never; // Finally assign the value to the last key

    return obj; // Return the top-level object to allow chaining
};

const ldGet = <T, R>(obj: T, path: PropertyPath, defaultValue?: R) => {
    const travel = (regexp: RegExp) =>
        String.prototype.split
            .call(path, regexp)
            .filter(Boolean)
            .reduce((res, key) => (res !== null && res !== undefined ? (res[key as keyof typeof res] as T) : res), obj);
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
};

const isObject = (val: unknown): val is Record<string, unknown> => typeof val === 'object' && !Array.isArray(val);

const addDelimiter = (a: string, b: string) => (a ? `${a}.${b}` : b);

const getDotPaths = (obj: Record<string, unknown>, head = ''): string[] =>
    Object.entries(obj || {}).reduce((product, [key, value]) => {
        const fullPath = addDelimiter(head, key);
        return isObject(value) ? product.concat(getDotPaths(value, fullPath)) : product.concat(fullPath);
    }, [] as string[]);

export type RuntimeType = 'string' | 'boolean' | 'number' | string[];

export interface ConfigOptions<TConfig> {
    namespace: string;
    /**
     * Config default values
     */
    defaultConfig?: Partial<TConfig>;
    /**
     * Storage adapter
     *
     * @default window.localStorage
     */
    storage?: Storage;
    /**
     * Permit to override any config values in storage
     *
     * @default true
     */
    localOverride?: boolean;
    /**
     * Runtime types mapping (metadata for AdminConfig)
     */
    types?: { [key in keyof TConfig]?: RuntimeType };
}

class ConfigStore<TConfig> {
    constructor(props: TConfig) {
        Object.assign(this, props);
        makeAutoObservable(this);
    }

    set(path: string, value: unknown) {
        ldSet(this, path, value);
    }
}

const getGlobal = () => {
    if (typeof window !== 'undefined') {
        return window;
    }
    if (typeof global !== 'undefined') {
        return global;
    }
    if (typeof self !== 'undefined') {
        return self;
    }
    return { localStorage: null };
};

const createConfig = <TConfig extends Record<string, unknown>>(options: ConfigOptions<TConfig>): TConfig & ConfigStore<TConfig> => {
    const namespace = options.namespace.slice(-1) === '.' ? options.namespace : `${options.namespace}.`;
    const injected = {
        storage: getGlobal().localStorage,
        localOverride: true,
        defaultConfig: {},
        types: {},
        ...options,
        namespace: namespace,
        storageKey: namespace.slice(0, -1),
    };

    const getStorageObject = (): Partial<TConfig> | undefined => {
        const value = injected.storage && injected.storage.getItem(`${injected.storageKey}`);
        return value ? JSON.parse(value) : undefined;
    };
    /**
     * Get a config value from the storage (localstorage by default)
     */
    const getStorageValue = <K extends keyof TConfig>(path: string): TConfig[K] | undefined => {
        if (injected.storage && injected.localOverride) {
            const value = getStorageObject();
            return value ? (ldGet(value, path) as TConfig[K]) : undefined;
        } else {
            return undefined;
        }
    };
    const getGlobalValue = <K extends keyof TConfig>(path: string) => ldGet(getGlobal(), `${injected.namespace}${path}`, null) as TConfig[K] | undefined;
    const get = <K extends keyof TConfig = keyof TConfig>(path: string): TConfig[K] => {
        const defaultValue = ldGet(options.defaultConfig, path);
        const storageValue = getStorageValue(path);
        const globalValue = getGlobalValue(path);
        if (globalValue === undefined && defaultValue === undefined) {
            throw new Error(`INVALID CONFIG: ${path} must be present inside config map, under global.${injected.namespace}`);
        }
        return (storageValue !== undefined ? storageValue : globalValue !== null ? globalValue : defaultValue) as TConfig[K];
    };

    const getConfig = (): ConfigStore<TConfig> => {
        const config = {} as TConfig;
        for (const key of getConfigKeys()) {
            const value = get(key);
            if (value !== undefined) {
                ldSet(config, key, value);
            }
        }

        return new ConfigStore(config);
    };

    const getConfigKeys = (): string[] => {
        const data = { ...options.defaultConfig, ...ldGet(getGlobal(), injected.storageKey, {}) };
        if ((data as { set: unknown })?.set) {
            delete (data as { set: unknown })?.set;
        }
        return getDotPaths(data);
    };

    const store = getConfig();
    const set = <K extends keyof TConfig = keyof TConfig>(path: K, value: TConfig[K]) => {
        if (injected.storage) {
            if (getGlobalValue(path as string) === value) {
                injected.storage.removeItem(`${injected.namespace}${path as string}`);
            } else {
                const storageConf = getStorageObject() || {};
                ldSet(storageConf, path, value);
                injected.storage.setItem(`${injected.storageKey}`, JSON.stringify(storageConf));
            }
        }
        ldSet(store, path, value);
    };

    ldSet(getGlobal(), `${injected.namespace}set`, set);
    return store as TConfig & ConfigStore<TConfig>;
};

export default createConfig;
