import React, { useCallback, useMemo } from 'react';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import config from '../../../../config';
import Product from '../../../../lib/api/model/Product';
import CurrencyFormatter from '../../../CurrencyFormatter/CurrencyFormatter';
import QtyInput from '../../../form/fields/QtyInput';
import { calculateBundledItemsQuantity } from '#/lib/calculateBundledItemsQuantity';
import { ObeProductLine } from '#/types/ObeProductLine';

export interface ExtrasRowProps {
    item: Product;
    readonly?: boolean;
    selectedItems: Product[];
    bundledItems?: ObeProductLine[];
    isSelecting?: boolean;
    onChange: (item: Product, delta: number) => void | Promise<void>;
    active: boolean;
}

const ExtrasRow: React.FC<ExtrasRowProps> = ({ item, selectedItems, bundledItems, readonly, onChange, active, isSelecting }) => {
    const itemLine = useMemo(() => selectedItems.find((l) => !l.IsExcluded && l.ProductCode === item.ProductCode), [item.ProductCode, selectedItems]);
    const enableExtraItemsDescription = config?.enableExtraItemsDescription || false;
    const handleChange = useCallback(
        (qty: number, delta: number) => {
            if (!readonly) {
                const updatedItem = new Product({ ...item, Qty: qty });
                return onChange(updatedItem, delta);
            }
        },
        [item, onChange, readonly]
    );
    const theme = useTheme();
    return (
        <Stack
            data-cy={`addon-item-row-${item.ProductCode}`}
            direction="row"
            spacing={2}
            padding={theme.spacing(1.5, 2.5)}
            position={'relative'}
            borderTop={1}
            borderRight={0}
            borderBottom={1}
            borderLeft={0}
            marginBottom={'-1px'}
            borderColor={active ? theme.palette.primary.main : theme.palette.grey[300]}
            zIndex={active ? 1 : 0}
            sx={{
                transition: theme.transitions.create('border-color'),
            }}
        >
            <Grid container spacing={0}>
                <Grid item xs={12} sm={6} display="flex" justifyContent="center" flexDirection="column">
                    <Typography fontWeight={700} fontSize={14}>
                        {item.Name}
                    </Typography>
                    {enableExtraItemsDescription && item.Description ? (
                        <Typography variant="body2" sx={{ wordBreak: 'break-word', ml: 2 }}>
                            {item.Description}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item xs={12} sm={6} display="flex" alignItems="center">
                    <CurrencyFormatter price={item.Price} prefixCurrencyCode={true} />/{item.ProductType}
                </Grid>
            </Grid>
            <QtyInput
                readonly={readonly}
                sx={{ opacity: isSelecting ? 0.7 : 5 }}
                loading={isSelecting && active}
                value={itemLine ? itemLine.Qty : 0}
                bundledQty={calculateBundledItemsQuantity(bundledItems, item)}
                max={item.AllowMultiple ? 10 : 1}
                onChange={handleChange}
            />
        </Stack>
    );
};
export default ExtrasRow;
