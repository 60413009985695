/* tslint:disable */

/* eslint-disable */

/**
 * Jucy Rentals Rest API
 * ## Intro The Jucy Rentals V2 API has been deprecated. [Please view the Jucy Rentals V3 API here](/docs)  ## Interactive Test Client  Experiment with live APIs using the **[Interactive Test Client](/swagger/ui/index)**.   ## Create Reservation Flow  In order to create a reservation a quote must first be generated. This section details what endpoints to call and in what order.  1. **[GetSites](/swagger/ui/index/#/Sites/GetSites)**    Get sites returns all sites, their short codes and their office hours    for each fleet type. Using the information returned from GetSites, you    can create a request to **GetFleetTypeCategories**.  1. **[GetFleetTypeCategories](/swagger/ui/index/#/Availability/GetFleetTypeCategories)**    Get all vehicle categories that are possibly hirable from the given    location at the given dates.  1. **[CreateQuote](/swagger/ui/index/#/Reservations/CreateQuote)**    Using the codes and ids from **GetSites** and **GetFleetTypeCategories**    a quote can be generated. Once a quote has been generated, insurance and    additional products may then be added.  1. **[UpdateQuote](/swagger/ui/index/#/Reservations/UpdateQuote)**    Using the details from the **CreateQuote**, a quote can be updated to    add the required insurance and additional items as needed.  1. **[ConfirmQuote](/swagger/ui/index/#/Reservations/ConfirmQuote)**     Confirm the quote once insurance and additional items have been added,     and it is ready for confirmation
 *
 * The version of the OpenAPI document: 2.6.1-oas3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { dateToString, stringToDate } from '../runtime';
import type { ExcessType } from './ExcessType';
import { ExcessTypeFromJSON, ExcessTypeFromJSONTyped, ExcessTypeToJSON } from './ExcessType';
import type { ProductType } from './ProductType';
import { ProductTypeFromJSON, ProductTypeFromJSONTyped, ProductTypeToJSON } from './ProductType';
import type { RentalAdditionalProductPrice } from './RentalAdditionalProductPrice';
import {
    RentalAdditionalProductPriceFromJSON,
    RentalAdditionalProductPriceFromJSONTyped,
    RentalAdditionalProductPriceToJSON,
} from './RentalAdditionalProductPrice';
import type { RentalExcessReductionAllOfBond } from './RentalExcessReductionAllOfBond';
import {
    RentalExcessReductionAllOfBondFromJSON,
    RentalExcessReductionAllOfBondFromJSONTyped,
    RentalExcessReductionAllOfBondToJSON,
} from './RentalExcessReductionAllOfBond';
import type { RentalExcessReductionAllOfExcess } from './RentalExcessReductionAllOfExcess';
import {
    RentalExcessReductionAllOfExcessFromJSON,
    RentalExcessReductionAllOfExcessFromJSONTyped,
    RentalExcessReductionAllOfExcessToJSON,
} from './RentalExcessReductionAllOfExcess';

/**
 *
 * @export
 * @interface RentalExcessReduction
 */
export interface RentalExcessReduction {
    /**
     * Product unique identifier
     * @type {string}
     * @memberof RentalExcessReduction
     */
    productId?: string;
    /**
     * Product unique short code
     * @type {string}
     * @memberof RentalExcessReduction
     */
    productCode?: string;
    /**
     * Product display name
     * @type {string}
     * @memberof RentalExcessReduction
     */
    name?: string;
    /**
     * Product category code
     * @type {string}
     * @memberof RentalExcessReduction
     */
    category?: string;
    /**
     * Indicates if this item is mandatory
     * @type {boolean}
     * @memberof RentalExcessReduction
     */
    isMandatory?: boolean;
    /**
     * Product long description
     * @type {string}
     * @memberof RentalExcessReduction
     */
    description?: string;
    /**
     *
     * @type {RentalAdditionalProductPrice}
     * @memberof RentalExcessReduction
     */
    price?: RentalAdditionalProductPrice;
    /**
     *
     * @type {ProductType}
     * @memberof RentalExcessReduction
     */
    productType?: ProductType;
    /**
     *
     * @type {boolean}
     * @memberof RentalExcessReduction
     */
    allowMultiple?: boolean;
    /**
     *
     * @type {ExcessType}
     * @memberof RentalExcessReduction
     */
    excessType: ExcessType;
    /**
     *
     * @type {RentalExcessReductionAllOfExcess}
     * @memberof RentalExcessReduction
     */
    excess: RentalExcessReductionAllOfExcess;
    /**
     *
     * @type {RentalExcessReductionAllOfBond}
     * @memberof RentalExcessReduction
     */
    bond: RentalExcessReductionAllOfBond;
}

/**
 * Check if a given object implements the RentalExcessReduction interface.
 */
export function instanceOfRentalExcessReduction(value: object): value is RentalExcessReduction {
    if (!('excessType' in value) || value['excessType'] === undefined) return false;
    if (!('excess' in value) || value['excess'] === undefined) return false;
    if (!('bond' in value) || value['bond'] === undefined) return false;
    return true;
}

export function RentalExcessReductionFromJSON(json: any): RentalExcessReduction {
    return RentalExcessReductionFromJSONTyped(json, false);
}

export function RentalExcessReductionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RentalExcessReduction {
    if (json == null) {
        return json;
    }
    return {
        productId: json['ProductId'] == null ? undefined : json['ProductId'],
        productCode: json['ProductCode'] == null ? undefined : json['ProductCode'],
        name: json['Name'] == null ? undefined : json['Name'],
        category: json['Category'] == null ? undefined : json['Category'],
        isMandatory: json['IsMandatory'] == null ? undefined : json['IsMandatory'],
        description: json['Description'] == null ? undefined : json['Description'],
        price: json['Price'] == null ? undefined : RentalAdditionalProductPriceFromJSON(json['Price']),
        productType: json['ProductType'] == null ? undefined : ProductTypeFromJSON(json['ProductType']),
        allowMultiple: json['AllowMultiple'] == null ? undefined : json['AllowMultiple'],
        excessType: ExcessTypeFromJSON(json['ExcessType']),
        excess: RentalExcessReductionAllOfExcessFromJSON(json['Excess']),
        bond: RentalExcessReductionAllOfBondFromJSON(json['Bond']),
    };
}

export function RentalExcessReductionToJSON(value?: RentalExcessReduction | null): any {
    if (value == null) {
        return value;
    }
    return {
        ProductId: value['productId'],
        ProductCode: value['productCode'],
        Name: value['name'],
        Category: value['category'],
        IsMandatory: value['isMandatory'],
        Description: value['description'],
        Price: RentalAdditionalProductPriceToJSON(value['price']),
        ProductType: ProductTypeToJSON(value['productType']),
        AllowMultiple: value['allowMultiple'],
        ExcessType: ExcessTypeToJSON(value['excessType']),
        Excess: RentalExcessReductionAllOfExcessToJSON(value['excess']),
        Bond: RentalExcessReductionAllOfBondToJSON(value['bond']),
    };
}
