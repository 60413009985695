'use client';
import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { Theme, styled } from '@mui/material';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Card, { CardProps } from '@mui/material/Card';

export type ButtonCardProps = Omit<CardProps & ButtonBaseProps, 'variant' | 'component'> & {
    isActive?: boolean;
    isHighlighted?: boolean;
    isLoading?: boolean;
};

const createTransition = (theme: Theme) =>
    theme.transitions.create(['box-shadow', 'border-color', 'background-color'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.standard,
    });

const ButtonCardRoot = styled(ButtonBase, {
    name: 'JucyButtonCard',
    slot: 'root',
    shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isHighlighted' && prop !== 'isLoading' && prop !== 'disabled',
})<ButtonCardProps>(({ theme, isActive, isLoading, disabled }) => ({
    textAlign: 'left',
    borderColor: isActive ? theme.palette.secondary.main : theme.palette.grey[200],
    borderStyle: 'solid',
    borderWidth: 1,
    transition: createTransition(theme),
    cursor: (isActive && isLoading) || disabled ? 'default' : 'pointer',
    '&.active, &:hover': {
        backgroundColor: theme.palette.action.hover,
        borderColor: theme.palette.secondary.dark,
    },
    ...(isActive
        ? {
              background: theme.palette.action.hover,
              borderColor: theme.palette.secondary.dark,
          }
        : undefined),
}));
export const ButtonCard: React.FC<ButtonCardProps> = ({ children, sx, ...props }) => (
        <Card
            component={ButtonCardRoot}
            sx={mergeSx(
                (theme) => ({
                    transition: createTransition(theme),
                }),
                sx
            )}
            elevation={props.isHighlighted ? 1 : 0}
            {...props}
        >
            {children}
        </Card>
    );
