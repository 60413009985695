import { useEffect, useState } from 'react';
import config from '#/config';
import AccountDetailsStore from '#/store/AccountDetailsStore';

export const useGetAmendmentEmail = () => {
    const [amendmentsEmail, setAmendmentsEmail] = useState(config.editBookingEmailAddress);
    useEffect(() => {
        AccountDetailsStore.getAccountDetails().then((details) => {
            if (details?.AmendmentsEmail) {
                setAmendmentsEmail(details.AmendmentsEmail);
            }
        });
    }, []);
    return amendmentsEmail;
};
