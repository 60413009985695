import { AgentUser, isAffiliate, OBEUser } from '#/types/OBEUser';
import JucyApiClient from '@jucy/rentals-api-client';
import { ConfigurationParameters, ListReservationsRequest, ListReservationsResponse } from '@jucy/rentals-api-client/rentals-api';
import * as runtime from '@jucy/rentals-api-client/rentals-api-v3';
import {
    AlternateDatesSearchRequest,
    AvailabilitySearchRequest,
    AvailabilitySearchResponse,
    Booking,
    BookingOptions,
    CreateBookingResponse,
    PaymentConfig,
    SubscribeRequest,
    SubscribeResponse,
    TripDetails,
    TripInfoRequest,
    UpdateReservationRequest,
    ValidateUpdateResponse,
} from '@jucy/rentals-api-client/rentals-api-v3';
import config from '../config';
import { Operator } from '../hooks';
import accountKeyStore from '../store/AccountKeyStore';
import { BookingCart } from '../types/BookingCart';
import { ObeProductLine } from '../types/ObeProductLine';
import { createQuoteQueryParams } from './SalesMonitoring';

export class JucyRentalsApiClient extends JucyApiClient {
    constructor(config: ConfigurationParameters) {
        config.middleware = [
            {
                pre: async (context) => {
                    const url = new URL(context.url);
                    const params = createQuoteQueryParams(accountKeyStore.mode || 'direct');
                    for (const [key, value] of Object.entries(params)) {
                        if (!url.searchParams.has(key)) {
                            url.searchParams.set(key, value);
                        }
                    }
                    return {
                        url: url.toString(),
                        init: context.init,
                    };
                },
            },
            ...(config.middleware || []),
        ];
        super(config);
    }

    async fetchBookingAndOptions(reservationReference: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ booking: Booking; options: BookingOptions }> {
        const [booking, options] = await Promise.all([
            this.v3.reservations.fetchBooking(reservationReference, initOverrides),
            this.v3.reservations.getOptions(reservationReference, initOverrides),
        ]);
        return {
            booking,
            options,
        };
    }

    async getTripDetails(tripInfo: TripInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TripDetails> {
        const tripDetails = await this.v3.tripApi.tripInfo(
            tripInfo.pickUpLocation,
            tripInfo.dropOffLocation,
            tripInfo.pickUpDate,
            tripInfo.dropOffDate,
            tripInfo.fleetCategory,
            tripInfo.driverAge,
            tripInfo.numberOfPeople,
            tripInfo.campaignCode,
            false,
            initOverrides
        );
        return tripDetails;
    }

    async validateUpdateReservation(
        {
            reservationRef,
            ...requestParameters
        }: Omit<UpdateReservationRequest, 'secondaryProducts' | 'insurance'> & {
            reservationRef: string;
            secondaryProducts?: ObeProductLine[];
            insurance?: ObeProductLine;
        },
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<ValidateUpdateResponse> {
        const response = await this.v3.reservations.validateUpdateReservation(
            reservationRef,
            {
                ...requestParameters,
                insurance: requestParameters.insurance?.productCode || '',
                secondaryProducts: requestParameters.secondaryProducts
                    ? Object.values(requestParameters.secondaryProducts).reduce(
                          (map, product) => {
                              if (product.qty > 0) {
                                  map[product.productCode] = product.qty;
                              }
                              return map;
                          },
                          {} as Record<string, number>
                      )
                    : undefined,
            },
            initOverrides
        );
        return response;
    }

    async availabilitySearch(request: AvailabilitySearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailabilitySearchResponse> {
        return await this.v3.tripApi.availabilitySearch(
            request.pickUpLocation,
            request.dropOffLocation,
            request.pickUpDate,
            request.dropOffDate,
            request.fleetTypeCode,
            request.driverAge,
            request.campaignCode,
            request.mergeSurchargesFees,
            request.reservationReference,
            request.pascal,
            initOverrides
        );
    }

    async convertQuote(summary: BookingCart): Promise<CreateBookingResponse | null> {
        const createRequest = summary.amendments?.asCreateRequest?.() || summary.asCreateRequest();
        if (!createRequest) {
            return null;
        }
        let response: CreateBookingResponse | null = null;
        if (summary?.bookingStatus === 'quote' && summary.reservationReference) {
            response = await this.v3.quotes.updateQuote(summary.reservationReference, createRequest);
            if (response.status === 'quote') {
                response.status = 'payment-required';
            }
        } else {
            response = await this.v3.reservations.createBooking({
                ...createRequest,
                type: 'booking',
            });
        }

        return response;
    }

    searchAlternateDatesCache: Record<string, AvailabilitySearchResponse[]> = {};

    async searchAlternateDates(request: AlternateDatesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailabilitySearchResponse[]> {
        const cacheKey = JSON.stringify(request);
        if (!this.searchAlternateDatesCache[cacheKey]) {
            this.searchAlternateDatesCache[cacheKey] = await this.v3.tripApi.alternateDatesSearch(
                request.pickUpLocation,
                request.dropOffLocation,
                request.pickUpDate,
                request.dropOffDate,
                request.fleetTypeCode,
                request.days,
                request.campaignCode,
                initOverrides
            );
        }
        return this.searchAlternateDatesCache[cacheKey];
    }

    async searchReservations(request?: Partial<ListReservationsRequest>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListReservationsResponse> {
        return this.reservations.listReservations(request?.accountId || (await this.defaultApiKey()), request?.query, request?.page, request?.itemsPerPage, request?.sort, initOverrides);
    }

    async defaultApiKey(): Promise<string> {
        const apiKey = typeof this.config.apiKey === 'function' ? this.config.apiKey('') : this.config.apiKey;
        return apiKey || config.webAccountKey;
    }

    async getAgentUser(accountKey: string) {
        const data = await getJucyRentalsApiClient({ accountKey }).v3.agent.getAgentDetails(accountKey);
        const user: AgentUser = {
            ...data,
            email: '',
            type: 'agent',
            accountKey: accountKey,
            isAuthenticated: true,
        };
        return user;
    }
}

const getOperatorToken = ({ user, operator }: { user?: OBEUser; operator?: Operator } = {}): string | undefined => {
    if (user?.type === 'staff' && user?.token?.accessToken) {
        return user.token.accessToken;
    }
    return operator?.token?.accessToken;
};
const getAccountKey = ({ user }: { user?: OBEUser } = {}): string => {
    if (user?.type === 'staff' && user?.agentUser?.accountKey) {
        return user.agentUser.accountKey;
    }
    if (user?.type === 'agent' && user?.accountKey) {
        return user.accountKey;
    }
    return config.webAccountKey;
};

const getJucyRentalsApiClient = ({ user, operator, accountKey: key }: { user?: OBEUser; operator?: Operator; accountKey?: string } = {}): JucyRentalsApiClient => {
    const accountKey = key || getAccountKey({ user });
    const operatorToken = getOperatorToken({ user, operator });
    const idToken = isAffiliate(user) ? user.idToken : undefined;
    return new JucyRentalsApiClient({
        basePath: `${config.apiBaseUrl}`.replace(/\/$/, ''),
        apiKey: accountKey,
        headers: {
            ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
            ...(operatorToken ? { 'x-operator-access-token': operatorToken } : {}),
            'X-API-KEY': accountKey,
        },
    });
};
export const subscribeToList = (request: SubscribeRequest): Promise<SubscribeResponse> => getJucyRentalsApiClient().v3.mailList.subscribeToList(request);

export const getPaymentConfig = (countryCode: string): Promise<PaymentConfig> =>
    getJucyRentalsApiClient()
        .v3.reservations.request({
            path: '/api/v3/payment/config',
            headers: {},
            method: 'GET',
            query: { countryCode },
        })
        .then((response) => response.json());

export default getJucyRentalsApiClient;
