import React, { HTMLAttributes, useCallback, useState } from 'react';
import { CurrencyValue } from '@jucy/rentals-api-client/rentals-api-v3';
import { JucyCountryCode } from '@jucy/rentals-common';
import { Link, LinkProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Image from 'next/image';
import * as Afterpay from '../../../lib/Afterpay';
import Money from '../../../lib/api/model/Money';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import WhatsThisModal from './WhatsThisModal';

interface PayWithAfterpayProps extends HTMLAttributes<HTMLDivElement> {
    amount: Money | CurrencyValue;
    showWhatsThis: boolean;
    showWhatsThisProps?: LinkProps;
    countryCode: JucyCountryCode;
}

const useStyles = makeStyles(() => ({
    logo: {
        width: 94,
        height: 33,
        marginLeft: 10,
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    whatsThisLink: {
        marginLeft: 20,
        display: 'inline-block',
    },
}));
const PayWithAfterpay: React.FC<PayWithAfterpayProps> = ({ amount, className, showWhatsThis, showWhatsThisProps, countryCode, ...props }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const toggleModel = useCallback(
        (event: React.SyntheticEvent) => {
            if (event?.preventDefault) {
                event.preventDefault();
            }
            setModalOpen(!isModalOpen);
        },
        [isModalOpen]
    );
    const classes = useStyles();
    const { className: whatsThisClassname, ...whatsThisProps } = showWhatsThisProps || {};
    return (
        <>
            <div className={clsx('Afterpay', className)} {...props}>
                or 4 weekly interest-free payments from{' '}
                <strong>
                    <CurrencyFormatter price={Afterpay.weeklyPayments(amount)} />
                </strong>{' '}
                with <Image src={Afterpay.logo} alt="Afterpay logo" className={classes.logo} />{' '}
                {showWhatsThis ? (
                    <Link
                        underline="hover"
                        href="components/PaymentProviders/Afterpay/index#"
                        className={clsx(classes.whatsThisLink, whatsThisClassname)}
                        onClick={toggleModel}
                        {...whatsThisProps}
                    >
                        what&apos;s this?
                    </Link>
                ) : null}
            </div>
            {showWhatsThis ? <WhatsThisModal countryCode={countryCode} open={isModalOpen} onClose={toggleModel} /> : null}
        </>
    );
};

export default PayWithAfterpay;
