import { ThemeOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const defaultTypography: ThemeOptions['typography'] = (palette) => ({
    fontSize: 14,
    lineHeight: '1.5',
    fontWeightRegular: 300,
    fontFamily: "'Poppins', 'Verdana', 'sans-serif'",
    alternate: {
        fontFamily: 'BoldenVan',
    },
    h1: {
        fontWeight: 700,
        fontSize: 35,
    },
    h2: {
        fontWeight: 700,
        fontSize: 30,
    },
    h3: {
        fontWeight: 700,
        fontSize: 25,
        lineHeight: 1.4,
    },
    h4: {
        fontSize: 'large',
        fontWeight: 700,
    },
    h5: {
        fontWeight: 700,
        fontSize: 14,
    },
    h6: {
        fontSize: 15,
    },
    body1: {
        fontSize: 14,
        fontWeight: 300,
    },
    body2: {
        fontSize: 14,
        fontWeight: 300,
    },
    button: {
        fontWeight: 600,
    },
    caption: {
        fontSize: 13,
        textTransform: 'uppercase',
    },
    subtitle1: {
        fontSize: 14,
    },
    subtitle2: {
        color: palette.text.secondary,
        fontWeight: 700,
        fontSize: 'smaller',
        lineHeight: 1,
        textTransform: 'none',
    },
    overline: {
        fontSize: 13,
        fontWeight: 700,
        textTransform: 'uppercase',
    },
}) as unknown as TypographyOptions;
