'use client';

import React from 'react';
import { cn } from '@jucy-ui/common';
import { useController } from '@jucy-ui/forms';
import { AgeSelect, AgeSelectProps } from '../../fields';
import { useGetDriverAges } from '../hooks';
import { jucySearchFormClasses } from '../lib';


export type SearchFormDriveAgeProps = Omit<
        AgeSelectProps,
    'onChange' | 'onBlur' | 'value'  | 'name' | 'ref' | 'required' | 'label' | 'error' | 'helperText' | 'ages'
>;

export const SearchFormDriveAge: React.FC<SearchFormDriveAgeProps> = ({ className, ...props }) => {
    const { field } = useController({ name: 'driverAge' });
    return <AgeSelect className={cn(jucySearchFormClasses.driverAge, className)} ages={useGetDriverAges()} {...field} {...props} />;
};
