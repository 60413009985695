import type { BookingPaymentInfo } from '@jucy-ui/common';

export const getPaymentInfoDisplayLabel = (paymentInfo: BookingPaymentInfo) => {
    const type = paymentInfo.type.toLowerCase();

    if (type === 'dps') {
        return 'Online payment';
    }

    return paymentInfo.type;
};
