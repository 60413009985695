'use client';
import React from 'react';
import { Card } from './Card';
import { CardHeader } from './CardHeader';

export interface TitleCardProps {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
}

export const TitleCard: React.FC<TitleCardProps> = ({ title, subTitle, children, className }: TitleCardProps) => (
    <Card className={className}>
        <CardHeader color="primary" title={title} subTitle={subTitle} />
        {children}
    </Card>
);
